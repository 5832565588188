import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import RecordRTC from 'recordrtc';
import fixWebmDuration from 'webm-duration-fix';
import RecordClientModule from '../recordClientModule.js';
import { SettingsService } from './settings.service.js';
@Injectable({
    providedIn: 'root'
})
export class RecordClientService {

    private isRecordingClient: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private audioContext;
    private dest;
    private mediaRecorder: RecordRTC;
    private countAudio = 0;
    private url = 'assets/audio/start.mp3';
    constructor(
        private settingService: SettingsService
    ) {
    }

    setupAudioContext() {
        this.audioContext = new AudioContext();
        this.dest = this.audioContext.createMediaStreamDestination();

        const mp3Audio = new Audio(this.url);
        const mp3AudioSource = this.audioContext.createMediaElementSource(mp3Audio)
        // const audioIn = this.audioContext.createMediaStreamSource();
        mp3AudioSource.connect(this.dest);
    }

    mergeAudio(audioStream: MediaStream) {
        const audioIn = this.audioContext.createMediaStreamSource(audioStream);
        audioIn.connect(this.dest);
        this.countAudio++;
        // if(this.countAudio == 1 && this.mediaRecorder && !this.mediaRecorder.camera.getAudioTracks().length) {
        //     const audioStrack = this.dest.stream.getAudioTracks()[0];
        //     this.mediaRecorder.camera.addTrack(audioStrack);
        // }
        return audioIn;
    }

    splitAudio(audioIn: MediaStreamAudioSourceNode): void {
        audioIn.disconnect(this.dest);
        this.countAudio--;
    }

    async recordStart() {
        return await RecordClientModule.RecordClientModule().then
            // navigator.mediaDevices.getDisplayMedia(constraints).then
            (async (stream) => {
                if (this.dest.stream.active == false)
                    this.dest.stream.play();
                const audioStrack = this.dest.stream.getAudioTracks()[0];
                const videoTrack = stream.getVideoTracks()[0];
                let streamRecord = new MediaStream();

                // if(this.countAudio)
                streamRecord.addTrack(audioStrack);

                streamRecord.addTrack(videoTrack);
                let option = {
                    mimeType: 'video/webm;codecs=h264'
                };
                this.mediaRecorder = new RecordRTC(streamRecord, option);

                await this.mediaRecorder.startRecording();
                stream.getVideoTracks()[0].addEventListener('ended', (ev) => {
                    this.setIsRecordingClient(false);
                });
                this.mediaRecorder.camera = stream;
                return true;
            }).catch((error) => {
                console.log(error);
                this.setIsRecordingClient(false);
                return false;
            });
    }

    checkRecordIsRun(intv) {
        if (!this.mediaRecorder.bufferSize) {
            this.mediaRecorder.pauseRecording();
            this.mediaRecorder.resumeRecording();
        }
        else
            clearInterval(intv)
    }

    async recordStop(roomName) {
        if (!this.mediaRecorder) return;
        await this.mediaRecorder.stopRecording(async () => {
            let videoStrack = this.mediaRecorder.camera.getVideoTracks();
            // eslint-disable-next-line react/destructuring-assignment
            const seekableBLob = await this.mediaRecorder.getBlob();
            // eslint-disable-next-line no-trailing-spaces
            console.log(seekableBLob);

            const fixBlob = await fixWebmDuration(seekableBLob);
            // eslint-disable-next-line indent
            const url = URL.createObjectURL(fixBlob);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.style.display = 'none';
            a.href = url;
            if (roomName.length > 80) {
                roomName = roomName.substring(0, 79);
            }
            a.download = `record-${roomName}... .webm`;
            a.click();
            window.URL.revokeObjectURL(url);
            videoStrack[0].stop();
            this.mediaRecorder.destroy();
            this.mediaRecorder = null;
        });
        return;

    }

    public setIsRecordingClient(flag: boolean): void {
        this.isRecordingClient.next(flag);
    }

    public onIsRecordingClient(): Observable<boolean> {
        return this.isRecordingClient.asObservable();
    }

    public getIsRecordingClient(): boolean {
        return this.isRecordingClient.value;
    }
}
