<vnpt-modal [(vVisible)]="isVisible"
            [vTitle]="title"
            (vOnOk)="handleClose()"
            (vOnCancel)="handleClose()"
            [vCancelText]="null" vOkText="{{'roles-manager.modalClose' | vI18n:{}:'Đóng'}}">
    <ng-container *vModalContent>
        <!-- <div *ngFor="let role of adminRoles">
            <div class="role-opt" *ngIf="role.promotable && (role.level <= highestRoleLevel)"
                vnpt-row vAlign="middle" vJustify="space-between">
                <span>{{constants.ROLES_CONSTANTS[role.label]}}</span>
                <vnpt-switch [ngModel]="isExistRole(role.id)" (click)="changeRole(role.id)" 
                             [vCheckedChildren]="checkedTemplate" [vUnCheckedChildren]="unCheckedTemplate">
                </vnpt-switch>
            </div>
        </div> -->

        <!-- <vnpt-divider vText="{{'roles-manager.moderator' | vI18n:{}:'Điều hành'}}"></vnpt-divider>
        <div class="role-opt" *ngFor="let role of moderatorRoles">
            <div *ngIf="role.promotable && (role.level <= highestRoleLevel)"
                vnpt-row vAlign="middle" vJustify="space-between" >
                <span>{{'roles-manager.role.'+role.label | vI18n:{}:''}}</span>
                <vnpt-switch  [ngModel]="isExistRole(role.id)" (click)="isModeratorDisabled ?  '' : changeRole(role.id)" 
                             [vCheckedChildren]="checkedTemplate" [vUnCheckedChildren]="unCheckedTemplate" 
                             [vDisabled]="isModeratorDisabled ?  true : false" ></vnpt-switch>
            </div>
        </div>

        <vnpt-divider vText="{{'roles-manager.participation' | vI18n:{}:'Tham gia'}}"></vnpt-divider> -->
        <div class="role-opt" *ngFor="let role of moderatorRoles">
            <div *ngIf="role.promotable && (role.level <= highestRoleLevel)"
                vnpt-row vAlign="middle" vJustify="space-between" >
                <span>{{'roles-manager.role.'+role.label | vI18n:{}:''}}</span>
                <vnpt-switch  [ngModel]="role.active" (click)="isModeratorDisabled ?  '' : changeRole(role.id)" 
                             [vCheckedChildren]="checkedTemplate" [vUnCheckedChildren]="unCheckedTemplate" 
                             [vDisabled]="(isModeratorDisabled ?  true : false) || checkRoleEvent" ></vnpt-switch>
            </div>
        </div>
        <div class="role-opt" *ngFor="let role of normalRoles">
            <div *ngIf="role.promotable && (role.level < highestRoleLevel)"
                 vnpt-row vAlign="middle" vJustify="space-between">
                 <span>{{'roles-manager.role.'+role.label | vI18n:{}:''}}</span>
                 <vnpt-switch [ngModel]="role.active" (click)="isNormalDisable ? '' : changeRole(role.id)" 
                              [vCheckedChildren]="checkedTemplate" [vUnCheckedChildren]="unCheckedTemplate" 
                              [vDisabled]="(isNormalDisable ? true : false) || checkRoleEvent"></vnpt-switch>
            </div>
        </div>
    </ng-container>
</vnpt-modal>

<ng-template #checkedTemplate><i vnpt-icon vType="CheckMark"></i></ng-template>
<ng-template #unCheckedTemplate><i vnpt-icon vType="Cancel"></i></ng-template>
