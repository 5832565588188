import { Component, ChangeDetectorRef, ElementRef, EventEmitter, OnInit, ViewChild, Output, HostListener, OnDestroy } from '@angular/core';
import { NotificationService } from '../../../services/notification-service';
import { TextMessage, MediaMessage, FileMessage } from '../../../models/chatting.model';
import { MediasoupService, ChattingService, LocalParticipantService } from '../../../services';
import { Subject, Subscription } from 'rxjs';
import { HttpEventType } from '@angular/common/http';
import { LocalParticipant } from '../../../models/local-participant.model';
import { COMMON_CONSTANTS } from 'projects/main/src/app/constants';
import { config } from 'projects/realtime-communication/src/config';
import { OneuiI18nService } from '@vnpt/oneui-i18n';
import urlRegex from 'url-regex';
import browser from "browser-detect";
import { PoolService } from '../../../services/mediasoup/pool.service';

@Component({
  selector: 'lib-chat-input',
  templateUrl: './chat-input.component.html',
  styleUrls: ['./chat-input.component.less']
})
export class ChatInputComponent implements OnInit, OnDestroy {
  public isEmojiPickerVisible: boolean;
  backgroundImageFn = (() => '/assets/emoji.png');
  inputValue = '';
  textAreaRows = 1;
  localParticipant: LocalParticipant;
  topic: string;
  currentActiveTitle: string;
  // deleteErrorMessageSubject: any = new Subject<void>();
  fileTitle = "Thêm tập tin";
  sendTitle = "Gửi tin nhắn";
  listFileUploads: any[] = [];
  listFileIcons: any [] = [];
  listSizeFiles: any [] = [];
  errorMsg = {
    error: false,
    msg: ''
  };
  BROWSER_RESULTS = browser();
  extensionLists = [
    "jpg", "jpeg", "png", "bmp", "webp",
    "mp3", "mp4", "avi", "weba", "webm",
    "pdf", "doc", "docx", "odt", "rtf", "xls", "xlsx", "ods", "ppt", "pptx", "odp",
    "epub", "mobi", "prc", "azw", "txt",
    "tar", "gz", "zip", "rar", "bz", "bz2"
  ];
  i18n = this.getEmojiTrans();
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('textInput') textInput: ElementRef;
  @Output() pushLocalChat = new EventEmitter();
  @Output() pushError = new EventEmitter();
  subscription : Subscription
  private wasInside = false;
  hideChatInput = false;
  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      this.isEmojiPickerVisible = false;
    }
    this.wasInside = false;
  }

  constructor(
    private mediasoupService: MediasoupService,
    private notification: NotificationService,
    private localParticipantService: LocalParticipantService,
    private chattingService: ChattingService,
    private poolService: PoolService,
    private i18nService: OneuiI18nService,
    private readonly changeDetector: ChangeDetectorRef
  ) {
    this.localParticipant = new LocalParticipant();
    this.subscription = this.i18nService.langChanges$.subscribe(evt=>{
      if(evt.type == "Success"){
        this.i18n =this.getEmojiTrans();
        // this.langChanges = this.i18nService.getActiveLang();
        this.currentActiveTitle = (this.topic == "PUBLIC_CHAT"
        ? this.i18nService.translate('chatting.publicChat',{},"Thảo luận chung")
        : this.poolService.getLatestDisplayName(this.topic));
      }
    })
  }

  ngOnInit(): void {
    this.chattingService.topicChangedBehavior.subscribe(topic => {
      this.topic = topic;
      this.currentActiveTitle = (topic == "PUBLIC_CHAT"
          ? this.i18nService.translate('chatting.publicChat',{},"Thảo luận chung")
          : this.poolService.getLatestDisplayName(topic));
    });
    
    this.localParticipantService.onPeerID().subscribe((peerID: string) => {
      this.localParticipant.id = peerID;
    });

    this.localParticipantService.onDisplayName().subscribe((displayName: string) => {
      this.localParticipant.displayName = displayName;
    });

    this.chattingService.onFilterChat().subscribe(async (key: string) => {
      if(key != 'all') this.hideChatInput = true
      else this.hideChatInput = false;
    });

    this.chattingService.onChangeTopicChat().subscribe((topic: string) => {
      if(this.inputValue.length > 0 || this.listFileUploads.length > 0){
        this.textInput.nativeElement.value = '';
        this.fileInput.nativeElement.value = '';
        this.listFileUploads = [];
        this.listFileIcons = [];
        this.listSizeFiles = [];
      }
    });
    
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  triggerKeyboard(event: any): void {
    event.preventDefault();
    // if(this.inputValue.length > 200){
    //     this.errorMsg = {
    //         error: true,
    //         msg: COMMON_CONSTANTS.checkMessage
    //     };
    // } else {
    //     this.errorMsg = {
    //         error: false,
    //         msg: ''
    //     };
    // }
    if ((event.ctrlKey || event.shiftKey) && event.key === 'Enter' && this.inputValue != '') {
      // this.inputValue += '\n';
      // this.textAreaRows++;
    } else if (event.key === 'Enter') {
      this.inputValue = this.inputValue.trim();
      this.sendAction();
      event.preventDefault();
      if (this.validateMessage(this.inputValue)) {

        event.target.value = "";
      }
      if (this.inputValue == '') {
        event.target.value = "";
      }
      return;
    }
    this.inputValue = event.target.value;
  }

  handlePaste(event: ClipboardEvent): void {
    // const os = this.BROWSER_RESULTS.os;
    // if (os.indexOf('Linux') == -1) {
    //   var item = event.clipboardData.items[0];
    //   if (item.kind === "file") {
    //     var blob = item.getAsFile();
    //     const arrayFiles = Array.from(this.listFileUploads);
    //     arrayFiles.push(blob);
    //     this.listFileUploads = arrayFiles
    //   }
    // }
    // else {
    var listItem = event.clipboardData.items;
    for (let index = 0; index < listItem.length; index++) {
      const item = listItem[index];
      if (item.kind === "file") {
        event.preventDefault();
        var blob = item.getAsFile();
        const fileName = blob.name;
        if (!this.isValidFileType(fileName.toLowerCase())) {
          this.notification.error(this.i18nService.translate('notification.chat-input.typeFileFalse'), fileName);
        } else {
          this.listFileUploads.push(blob);
          this.listFileIcons.push(this.showIcon(blob.name));
          this.listSizeFiles.push(this.showSizeFile(blob.size));
        }
      }
      if (item.kind === "string"  && item.type === "text/plain") {
        item.getAsString((text) => {
          this.inputValue = this.inputValue + text
        });
        
      }
      
    }

  }

  sendAction(): void {
    this.inputValue = this.inputValue.trim(); //check space

    if (this.inputValue && this.inputValue != '') {
      this.sendTextMessage();
    } else {
      this.textInput.nativeElement.value = '';
    }

    if (this.listFileUploads.length > 0) {
      this.sendFileMessage();
    }
  }

  async sendTextMessage(): Promise<void> {
    if (this.inputValue.length > 200) {
      this.errorMsg = {
        error: true,
        msg: this.i18nService.translate("chat-input.checkMessage")
      };

    } else {
      this.errorMsg = {
        error: false,
        msg: ''
      };

      // const chatMessage = this.processChatContent(this.compressMessage('message', this.inputValue));
      const chatMessage = new TextMessage(this.localParticipant, this.inputValue);
      const message = await this.chattingService.sendChatMessage(chatMessage);
      if (!message) {
        this.notification.error(this.i18nService.translate('notification.chat-input.chat'),
          this.i18nService.translate('notification.chat-input.sendFail'));
        return;
      }
      this.pushLocalChat.emit(message);
      this.changeDetector.detectChanges();
      this.inputValue = '';
      this.textInput.nativeElement.value = '';
    }
  }

  sendFileMessage(): void {
    this.fileInput.nativeElement.value = '';
    const fileList = this.listFileUploads;
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      if (file.size <= config.fileUpload.maxSizeFile) {
        let isMedia = false;
        let st = file.type.split('/');
        if (st[0] == ('image')) {
          isMedia = true;
        }
        // let fileChatMessage = this.compressMessage(typeFile, {});

        this.chattingService.sendFileChatToServer(file).subscribe(
          (response: any) => this.handleUploadProgress(response,
            (response) => {/* const percent = Math.round(100 * (response.loaded / response.total)); */},
            async (response) => {
              /* fileChatMessage = this.updateFileMessageContent(fileChatMessage, {
                name: file.name,
                size: file.size,
                downloadUrl: response.body.location
              }); */
              let fileMessage = isMedia
                ? new MediaMessage(this.localParticipant, response.body.originalname, response.body.location)
                : new FileMessage(this.localParticipant, response.body.originalname, response.body.location);
              // let fileMess = this.compressMessage(type, response.body.location);
              const message = await this.chattingService.sendChatMessage(fileMessage);
              if (!message) {
                this.notification.error(this.i18nService.translate('notification.chat-input.chat'), this.i18nService.translate('notification.chat-input.sendFail'));
                return;
              }
              this.pushLocalChat.emit(message);
              this.fileInput.nativeElement.value = '';
            }),
          /* (response) => {
            let fileMessage = createFileMessage(this.localParticipant.id, this.localParticipant.displayName, response.body.originname, response.body.location);
            this.handleUploadProgress(file, fileMessage, response);
          }, */
          (error) => {
            console.error(error)
            this.notification.error(this.i18nService.translate('notification.chat-input.unsentFile'), '');
            this.pushError.emit();
            this.fileInput.nativeElement.value = '';
          });
      }
      else {
        this.notification.error(this.i18nService.translate('notification.chat-input.unsentFileBig'), '');
        this.fileInput.nativeElement.value = '';
      }
    }
    this.listFileUploads = [];
    this.listFileIcons = [];
    this.listSizeFiles = [];
  }

  validateMessage(msg: string): boolean {
    let msgTemp: string = msg;
    msgTemp = msgTemp.replace(/\s/g, '');
    return msgTemp.length > 0;
  }

  /* compressMessage(type: 'message' | 'file' | 'media', message: any): Chatting {
    const chatMessage = new Chatting();
    const isString = typeof message === 'string';
    return {
      ...chatMessage,
      type,
      sender: this.localParticipant.id,
      name: this.localParticipant.displayName,
      content: message,
      fileName: (type == "file" || type == "media") && isString ? this.showFileName(message) : "", //bổ sung key fileName check điều kiện có phải là file không
      fileIcon: (type == "file" || type == "media") && isString ? this.showIcon(message) : "" //bổ sung key fileIcon check điều kiện có phải là file không
    };
  } */

  /* processChatContent(msg: Chatting): Chatting {
    let newMessage: Chatting = { ...msg };
    if (newMessage.type == "message") {
      const urlList = newMessage.content.match(urlRegex());
      if (urlList && urlList.length > 0) {
        newMessage.hasLink = true;
      }
    }
    return newMessage;
  } */

  async handleUploadProgress(response: any, updateProgress: Function, onResponse: Function) {
    switch(response.type) {
      case HttpEventType.UploadProgress:
        updateProgress(response);
        break;
      case HttpEventType.Response:
        onResponse(response);
        break;
      default:
        break;
    }
  }

  /* async _handleUploadProgress(file: File, fileChatMessage: Chatting, response: any): Promise<Chatting> {
    let type = fileChatMessage.type;
    if (response.type === HttpEventType.UploadProgress) {
      const uploadProgress = Math.round(100 * (response.loaded / response.total));
      fileChatMessage = this.updateFileMessageContent(fileChatMessage, {
        name: file.name,
        progress: uploadProgress
      });
    }

    if (response.type === HttpEventType.Response) {
      fileChatMessage = this.updateFileMessageContent(fileChatMessage, {
        name: file.name,
        size: file.size,
        downloadUrl: response.body.location
      });
      let fileMess = this.compressMessage(type, response.body.location);
      const message = await this.chattingService.sendChatMessage(fileMess);
      if (!message) {
        this.notification.error(this.i18nService.translate('notification.chat-input.chat'),
          this.i18nService.translate('notification.chat-input.sendFail'));
        return;
      }
      this.pushLocalChat.emit(message);
      this.fileInput.nativeElement.value = '';
    }

    return fileChatMessage;
  } */

  /* updateFileMessageContent(currentMessage: BaseMessage, message: any): BaseMessage {
    return {
      ...currentMessage,
      content: message
    };
  } */

  beforeUploadFile($event: any): void {
    let arrayCorrectFiles = [];
    const sizeFiles = $event.target.files.length;

    for (let size = 0; size < sizeFiles; size++) {
      const fileName = $event.target.files[size].name;
      if (!this.isValidFileType(fileName.toLowerCase())) {
        this.notification.error(this.i18nService.translate('notification.chat-input.typeFileFalse'), fileName);
      } else {
        arrayCorrectFiles.push($event.target.files[size]);
      }
    }

    if (this.listFileUploads.length > 0) {
      let t = arrayCorrectFiles;
      const arrayFiles = Array.from(this.listFileUploads);
      for (let index = 0; index < t.length; index++) {
        arrayFiles.push(t[index]);
      }
      this.listFileUploads = arrayFiles;
    } else {
      this.listFileUploads = arrayCorrectFiles;
    }
    this.listFileIcons = [];
    this.listSizeFiles = [];
    this.listFileUploads.forEach((ele)=> {
      this.listFileIcons.push(this.showIcon(ele.name));
      this.listSizeFiles.push(this.showSizeFile(ele.size))
    })
    $event.preventDefault();
    this.textInput.nativeElement.focus();
  }

  isValidFileType(fileName: any): boolean {
    return this.extensionLists.indexOf(fileName.split('.').pop()) > -1;
  }

  removeFile(file: File) {
    this.fileInput.nativeElement.value = '';
    let index = this.listFileUploads.indexOf(file);
    this.listFileUploads.splice(index, 1);
    this.listFileIcons.splice(index, 1);
    this.listSizeFiles.splice(index, 1);
  }

  showIcon(item: string): string {
    if (!item) return null;
    const ext = item.match(/\.[0-9a-z]+$/i)[0];
    switch (ext) {
      case '.pdf':
        return 'PDF';
      case '.doc':
      case '.docx':
      case '.odt':
      case '.rtf':
        return 'WordDocument';
      case '.xls':
      case '.xlsx':
      case '.ods':
        return 'ExcelDocument';
      case '.ppt':
      case '.pptx':
      case '.odp':
        return 'PowerPointDocument';
      default:
        return 'Document';
    }
  }

  //Lấy tên file đã qua cắt tỉa
  showFileName(url: string): string {
    if (!url) return '';
    const getName = url.split('/');
    const name = decodeURI(getName[getName.length - 1]);
    if (name.length > 22) {
        return `${name.slice(0, 13)}...${name.slice(name.lastIndexOf('.') - 1)}`;
    }
    return name;
}

  getEmojiTrans(): object{
    return {
      search: this.i18nService.translate('chat-input.search'),
      emojilist: this.i18nService.translate('chat-input.emojilist'),
      notfound: this.i18nService.translate('chat-input.notfound'),
      clear: this.i18nService.translate('chat-input.clear'),
      categories: {
        search: this.i18nService.translate('chat-input.categories.search'),
        recent: this.i18nService.translate('chat-input.categories.recent'),
        people: this.i18nService.translate('chat-input.categories.people'),
        nature: this.i18nService.translate('chat-input.categories.nature'),
        foods: this.i18nService.translate('chat-input.categories.foods'),
        activity: this.i18nService.translate('chat-input.categories.activity'),
        places: this.i18nService.translate('chat-input.categories.places'),
        objects: this.i18nService.translate('chat-input.categories.objects'),
        symbols: this.i18nService.translate('chat-input.categories.symbols'),
        flags: this.i18nService.translate('chat-input.categories.flags'),
        custom: this.i18nService.translate('chat-input.categories.custom'),
      },
    }
  }

  public addEmoji(event) {
    this.inputValue +=  `${event.emoji.native}`;
    this.textInput.nativeElement.value += `${event.emoji.native}`;
    this.textInput.nativeElement.focus();
    this.isEmojiPickerVisible = false;
 }

 showSizeFile(size) {
  return Math.round(size/1024);
 }
}
