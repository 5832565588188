<div  vJustify="space-between" (mouseenter)="triggerVisibleQuickActionChange(true)" (mouseleave)="triggerVisibleQuickActionChange(false)" vAlign="middle" class="parent">
    <div class="order">
        <p class="user-name custom-order">{{ numberOrder }}.</p>
    </div>
    <div class="display-name user-name child child-one">
        <p class="user-name" title="{{participant.displayName}}">{{participant.displayName}} </p>
    </div>
    <div class="display-name child child-two" *ngIf="hasPermissionModerator else elseBlock">
        <p class="role"> ({{hasPermissionAdmin ? ("admin" | vI18n:{}:"Người quản trị")+', ': ("moderator" |
            vI18n:{}:"Người điều hành")+', '}}{{'list-participant.me' | vI18n:{}:'Tôi'}})</p>
    </div>
    <ng-template #elseBlock>
        <div class="display-name child child-two">
            <p class="role">({{'list-participant.me' | vI18n:{}:'Tôi'}})</p>
        </div>
    </ng-template>
    <div class="icon-holder child child-three">
        <div vnpt-row vJustify="end" vAlign="middle" class="no-w">
            <div *ngIf="participant.isRaisedHand" class="icon-container">
                <a vnpt-button vType="text" disabled>
                    <i class="icon-gomeet-raise-hand icon-yellow" vSize="xs"></i>
                </a>
            </div>
            <div *ngIf="isSpeaking && isJoinedAudio && participant.audio.state" class="icon-container">
                <a vnpt-button vType="text" disabled>
                    <i  class="icon-gomeet-talking icon-success"></i>
                </a>
            </div>
            <div *ngIf="participant.isSharing" class="icon-container">
                <a vnpt-button vType="text" disabled>
                    <i class="icon-gomeet-sharescreen icon-success"></i>
                </a>
            </div>
            <div *ngIf="participant.isPin && forceLayoutStatus" class="icon-container" style="cursor: pointer; justify-content: flex-end; align-self: center;">
                <a vType="text">
                    <i class= "icon-gomeet-pin icon-primary iconPin"></i>
                </a>
            </div>
            <ng-template [ngIf]="!isBlockAllAudio && isJoinedAudio">
                <div class="icon-container">
                    <!-- <a vnpt-button vType="text" [disabled]="!isJoinedAudio"(click)="toggleMic()" vnpt-tooltip [vTooltipTitle]="participant.audio.state ? 'Tắt mic' : 'Mở mic'">
                        -->
                    <a vnpt-button vType="text" disabled>
                    <i [ngClass]="participant.audio.state ? 'icon-success icon-gomeet-state-mic': 'icon-error icon-gomeet-state-micoff'"></i>
                    </a>
                </div>
            </ng-template>

            <ng-template [ngIf]="isBlockAllAudio && isJoinedAudio">
                <div class="icon-container">
                    <a vnpt-button vType="text" disabled>
                        <i class="icon-error icon-gomeet-state-micban"></i>
                    </a>
                </div>
            </ng-template>

            <ng-template [ngIf]="!isBlockAllWebcam">
                <div class="icon-container">
                    <!-- <a vnpt-button vType="text" (click)="toggleVideo()" vnpt-tooltip [vTooltipTitle]="participant.webcam.state ? 'Tắt webcam' : 'Mở webcam'"> -->
                        <a vnpt-button vType="text" disabled>
                        <i vnpt-icon [ngClass]="participant.webcam.state ? 'icon-success icon-gomeet-state-video': 'icon-error icon-gomeet-state-videooff'"></i>
                    </a>
                </div>
            </ng-template>

            <ng-template [ngIf]="isBlockAllWebcam">
                <div class="icon-container">
                    <a vnpt-button vType="text" disabled>
                        <i ngClass="icon-error icon-gomeet-state-videoban"></i>
                    </a>
                </div>
            </ng-template>

            <!-- <div class="icon-container">
                <a vnpt-button vType="text" 
                    vnpt-dropdown [vDropdownMenu]="moreMenu" vTrigger="click" [vPlacement]="'bottomRight'" vnpt-tooltip vTooltipTitle="{{'list-participant.more' | vI18n:{}:'Thêm'}}"> 
                    <i vnpt-icon [vType]="'MoreVertical'" vSize="sm" class="icon-white"></i>
                </a>
            </div> -->
        </div>
    </div>
    
    <div vnpt-row *ngIf="showUp || moreMenuVisible" class="quick-action custom">
        <button vnpt-button *ngIf="!isBlockAllAudio" [disabled]='checkToggleMic' VSize="small"  vDanger style="background-color: white;" (click)="toggleMic()" class="quick-button {{participant.audio.state ? '' : 'green-button'}}">
            <i style="margin-right: 5px;" [ngClass]="participant.audio.state ? 'icon-gomeet-state-micoff' : 'icon-gomeet-state-mic'" ></i>
            {{participant.audio.state ? ('list-participant.muteMic' | vI18n:{}:'Tắt mic') : ('list-participant.onMic' | vI18n:{}:'Mở mic')}}</button>
        
        <button vnpt-button *ngIf="!isBlockAllWebcam"  [disabled]="checkToggleVideo" VSize="small"  vDanger style="background-color: white;" (click)="toggleVideo()" class="quick-button {{participant.webcam.state ? '' : 'green-button'}}">
            <i style="margin-right: 5px;" [ngClass]="participant.webcam.state ? 'icon-gomeet-state-videooff' : 'icon-gomeet-state-video'" ></i>
            {{participant.webcam.state ? ('list-participant.stopVideo' | vI18n:{}:'Tắt video') : ('list-participant.onVideo' | vI18n:{}:'Mở video')}}</button>
    
        <button vnpt-button VSize="small" vType="primary" class="quick-button"  [disabled]="!hasPermissionModerator || !forceLayoutStatus || isWebcamsOnlyForModerator"
        (click)="pinCameraHandler()">
            <i [ngClass]="participant.isPin ? 'icon-gomeet-pin' : 'icon-gomeet-pin'"></i>
            {{participant.isPin ? ('unpin' | vI18n:{}:'Bỏ ghim') : ('pin' | vI18n:{}:'Ghim')}}</button>
        
        <button vnpt-button VSize="small" vType="default" style="background-color: white; color: black; height: 24.45px;"
        vnpt-dropdown [vDropdownMenu]="moreMenu" vTrigger="click" [vPlacement]="'bottomRight'" (vVisibleChange)="openMoreMenu($event)"
        vnpt-tooltip vTooltipTitle="{{'list-participant.more' | vI18n:{}:'Thêm'}}" class="quick-button"
        >
            <i class="icon-gomeet-more"></i>
        </button>
    </div>
</div>

<vnpt-dropdown-menu #moreMenu>
    <ul class="menu-background" vnpt-menu>
        <li vnpt-menu-item (click)="changeDisplayName()">
            <i vnpt-icon vType="Rename" [vSize]="'sm'"></i>
            <span>{{'list-participant.changeName' | vI18n:{}:'Đổi tên'}}</span>
        </li>
        <ng-template [ngIf]="!isBlockAllAudio && isJoinedAudio">
            <li vnpt-menu-item (click)="toggleMic()">
                <i vnpt-icon [class]="participant.audio.state ? 'icon-gomeet-state-micoff' : 'icon-gomeet-state-mic'" [vSize]="'sm'"></i>
                <span>{{ participant.audio.state ? ('list-participant.muteMic' | vI18n:{}:'Tắt mic') :
                    ('list-participant.onMic' | vI18n:{}:'Mở mic')}}</span>
            </li>
        </ng-template>
        <ng-template [ngIf]="!isBlockAllWebcam">
            <li vnpt-menu-item (click)="toggleVideo()">
                <i vnpt-icon [class]="participant.webcam.state ? 'icon-gomeet-state-videooff': 'icon-gomeet-state-video'" [vSize]="'sm'"></i>
                <span>{{ participant.webcam.state ? ('list-participant.stopVideo' | vI18n:{}:'Tắt video') :
                    ('list-participant.onVideo' | vI18n:{}:'Mở video')}}</span>
            </li>
        </ng-template>
    </ul>
</vnpt-dropdown-menu>
