
<ng-template [ngIf]="!changeTemplate && listVideo.length > 0">
<div>
    <div [class]="'list-item'" (click)="openSetting()">
        <i vnpt-icon [vType]="'Settings'" [vSize]="'sm'"></i>
        <span>{{ "video-device.setting" | vI18n:{}:"Cài đặt video và phông nền ảo"}}</span>
    </div>
    <vnpt-divider class="divider" style="margin-bottom: 8px;" vText="Video" vOrientation="left"></vnpt-divider>
    <div *ngFor="let video of listVideo">
        <div [class]="selectedVideo && video.deviceId == selectedVideo.deviceId ? 'list-item-pick' : 'list-item'" (click)="choseVideo(video)">
            <i vnpt-icon [vType]="video.deviceId == selectedVideo.deviceId ? 'Accept' : ''" [vSize]="'sm'"></i>
            <span> {{video.label == '' ? ("device.label.unidentified" | vI18n:{}:"") : video.label}}</span>
        </div>
    </div>
    <vnpt-divider class="divider" style="margin-bottom: 8px;" vText='{{"video-device.quality" | vI18n:{}:""}}' vOrientation="left"></vnpt-divider>
    <div *ngFor="let quality of listQuality">
        <div [class]="quality.isTrue ? 'list-item-pick' : 'list-item'" (click)="choseQuality(quality)">
            <i vnpt-icon [vType]="quality.isTrue ? 'Accept' : ''" [vSize]="'sm'"></i>
            <span> {{quality.label}}</span>
        </div>
    </div>
</div>
</ng-template>
<ng-template [ngIf]="changeTemplate && listVideo.length > 0">
<div>
    <div *ngIf="!qualityTemplate">
        <vnpt-divider class="divider" style="margin-bottom: 8px;" vText="Video" vOrientation="left"></vnpt-divider>
        <div *ngFor="let video of listVideo">
        <div [class]="selectedLocalVideo && video.deviceId == selectedLocalVideo.deviceId ? 'list-item-pick' : 'list-item'" (click)="choseVideoLocal(video)">
            <i vnpt-icon [vType]="video.deviceId == selectedLocalVideo.deviceId ? 'Accept' : ''" [vSize]="'sm'"></i>
            <span >{{video.label == '' ? defaultSystem : video.label}}</span>
        </div>
        </div>
    </div>
    <div *ngIf="qualityTemplate">
        <vnpt-divider class="divider" style="margin-bottom: 8px;" vText='{{"video-device.quality" | vI18n:{}:""}}' vOrientation="left"></vnpt-divider>
        <div *ngFor="let quality of listQuality">
        <div [class]="quality.isTrue ? 'list-item-pick' : 'list-item'" (click)="choseLocalQuality(quality)">
            <i vnpt-icon [vType]="quality.isTrue ? 'Accept' : ''" [vSize]="'sm'"></i>
            <span> {{quality.label}}</span>
        </div>
        </div>
    </div>
</div>
</ng-template>

<ng-template [ngIf]="!(listVideo.length > 0)">
    <div>
        <vnpt-divider class="divider" style="margin-bottom: 8px;" vText="Video" vOrientation="left"></vnpt-divider>
        <div class="list-item-pick" [style]="{'color': noDevice ? 'red' : 'white'}">
            <i vnpt-icon [vType]="noDevice ? '': 'loading'" [vSize]="'sm'" ></i>
            <span >{{noDevice ? ("device.label.unidentified" | vI18n:{}:"") : ("device.label.findDevice" | vI18n:{}:"")}}</span>
        </div>
    </div>
</ng-template>