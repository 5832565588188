import {Action} from '@ngrx/store';

export const ADD_MESSAGE = 'add message';
export const ADD_CHAT_HISTORY = 'add chat history';
export const CLEAR_CHAT = 'clear chat';
export const SORT_CHAT = 'sort chat';
export const SET_IS_SCROLL_END = 'set is scroll end';
export const SET_IS_MESSAGE_READ = 'set is message read';

export class AddMessage implements Action{
    readonly type = ADD_MESSAGE;
    public payload: any;
    constructor(message: string) {
        this.payload = {message};
    }
}

export class AddChatHistory implements Action{
    readonly type = ADD_CHAT_HISTORY;
    public payload: any;
    constructor(chatHistory: any) {
        this.payload = {chatHistory};
    }
}

export class ClearChat implements Action{
    readonly type = CLEAR_CHAT;
    constructor() {
    }
}

export class SortChat implements Action{
    readonly type = SORT_CHAT;
    public payload: any;
    constructor(order: any) {
        this.payload = {order};
    }
}
export class SetIsScrollEnd implements Action{
    readonly type = SET_IS_SCROLL_END;
    public payload: any;
    constructor(flag: any) {
        this.payload = {flag};
    }
}

export class SetIsMessageRead implements Action{
    readonly type = SET_IS_MESSAGE_READ;
    public payload: any;
    constructor(id: any, isRead: any) {
        this.payload = { id, isRead };
    }
}

export type All =
    AddMessage |
    AddChatHistory |
    ClearChat |
    SortChat |
    SetIsScrollEnd |
    SetIsMessageRead;
