<div [ngClass]="{'main-frame': true, 'fullScreen': isFullScreen}" [class]="layoutMode == 'polycom-71'? 'polycom-71' : 'polycom-121'">
    <ng-template [ngIf]="layoutMode == 'polycom-71'" [ngIfElse]="layout121">
        <lib-activating-speaker class="active-video item" ></lib-activating-speaker>
        <div class="gutter-row item " *ngIf="!isRecordUser" [ngClass]='{"d-none": isRecordUser}'>
            <lib-local-streaming [miniView]="miniView" [forceLayoutStatus]="forceLayoutStatus"></lib-local-streaming>
        </div>
        <ng-template [ngIf]="listRemoteParticipant && listRemoteParticipant[0]">
            <div class="gutter-row item" *ngFor="let remote of arrayLayout71; let itemIndex = index">
                <lib-remote-streaming [participantId]="listRemoteParticipant[itemIndex].id" [miniView]="miniView"
                [forceLayoutStatus]="forceLayoutStatus"
                    *ngIf="(itemIndex < listRemoteParticipant.length) && listRemoteParticipant[itemIndex]">
                </lib-remote-streaming>
                
            </div>
        </ng-template>
    </ng-template>

    <ng-template #layout121>
        <div class="gutter-row item" *ngIf="!isRecordUser" [ngClass]='{"d-none": isRecordUser}'>
            <lib-local-streaming [miniView]="miniView" [forceLayoutStatus]="forceLayoutStatus"></lib-local-streaming>
        </div>
        <ng-template [ngIf]="listRemoteParticipant && listRemoteParticipant[0]" [ngIfElse]="activeSpeaker">
            <ng-template ngFor let-remote [ngForOf]="arrayLayout121" let-itemIndex="index">
                <div class="gutter-row item">
                    <lib-remote-streaming [participantId]="listRemoteParticipant[itemIndex].id" [miniView]="miniView"
                    [forceLayoutStatus]="forceLayoutStatus"
                        *ngIf="(itemIndex < listRemoteParticipant.length) && listRemoteParticipant[itemIndex]">
                    </lib-remote-streaming>
                </div>
                <lib-activating-speaker *ngIf="itemIndex == 3" class="active-video item"
                    style="margin: auto"></lib-activating-speaker>
            </ng-template>
        </ng-template>
        <ng-template #activeSpeaker>
            <lib-activating-speaker class="active-video item" style="margin: auto;"></lib-activating-speaker>
        </ng-template>
    </ng-template>
</div>
