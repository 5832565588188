import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseMessage, Conversation, TOPIC_PUBLIC_CHAT } from '../models/chatting.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../main/src/environments/environment';
import { ActivatedRoute } from "@angular/router";

import { valueFunctionProp } from '@vnpt/oneui-ui/core/util';
import { SocketService } from './socket.service';
import { RightPaneViewType } from './mediasoup/room.service';
import { LocalParticipantService } from './local-participant.service';
import { OneuiI18nService } from '@vnpt/oneui-i18n';
import { PoolService } from './mediasoup/pool.service';
import { SettingsService } from './settings.service';
import { element } from 'protractor';
import { RemoteParticipant } from '../models/remote-participant.model';

interface FileChatApiResponse {
    code: string;
    data: { url: string; };
    message: string;
}

const FILE_UPLOAD_API = environment.domain.api + '/api/edumeet-management/v1/chat/upload';

@Injectable({
    providedIn: 'root'
})
export class ChattingService {
    constructor(private http: HttpClient,
        private settingService: SettingsService,
        private socketService: SocketService,
        private activatedRoute: ActivatedRoute,
        private localParticipantSerivce: LocalParticipantService,
        private poolService: PoolService,
        private i18nService: OneuiI18nService
    ) {
        this._mapMessages.set(TOPIC_PUBLIC_CHAT, {
            topicName: this.i18nService.translate('chatting.publicChat',{},"Thảo luận chung"),
            status: true,
            unreadMessageCount: 0,
            history: null
        })
    }
    isReadCounting = 0;
    isRightContainerShown = false;
    containerRightType: RightPaneViewType = 'listParticipant';
    private _activeChat: string = TOPIC_PUBLIC_CHAT;
    private _mapMessages: Map<string, Conversation> = new Map();
    private _activeChatList: Map<string, string> = new Map();
    private _topicChangedBehavior: BehaviorSubject<string> = new BehaviorSubject(TOPIC_PUBLIC_CHAT);
    private _newMessageBehavior: BehaviorSubject<BaseMessage> = new BehaviorSubject(null);
    private _messageReadedBehavior: BehaviorSubject<string> = new BehaviorSubject("");
    private _deleteMessageBehavior: BehaviorSubject<string> = new BehaviorSubject("");
    private chattingBehaviorSubject: BehaviorSubject<BaseMessage> =  new BehaviorSubject<BaseMessage>(null);
    private isReadCountingSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private updateConversationSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

    private onContainerShownSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private filterChat: BehaviorSubject<string> = new BehaviorSubject<string>("all");
    private changeTopic: BehaviorSubject<string> = new BehaviorSubject<string>("");
    private updateMapMessage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); 

    public setFilterChat(key: string){
        this.filterChat.next(key);
    }

    public get getFilterChat():string{
        return this.filterChat.getValue();
    }
    public onFilterChat() : Observable<string> {
        return this.filterChat.asObservable();
    }

    public setChangeTopic(key: string){
        this.changeTopic.next(key);
    }

    public onChangeTopicChat() : Observable<string> {
        return this.changeTopic.asObservable();
    }
    
    public get onUpdateMapMessage(){
        return this.updateMapMessage.asObservable();
    }

    public get topicChangedBehavior() {
        return this._topicChangedBehavior.asObservable();
    }

    public get topicChanged() {
        return this._topicChangedBehavior.getValue();
    }

    public get activeChat(): string {
        return this._activeChat;
    }

    public async setActiveChat(topic: string): Promise<any> {
        this._activeChat = topic;
        /* if (!this._mapMessages.has(topic)) {
            let data = (topic === TOPIC_PUBLIC_CHAT
                ? await this.socketService.sendRequest('chatHistory', { topic })
                : await this.socketService.sendRequest('privateChatHistory', { receiver: topic }));
            if (data && data.length) {
                let lastMessage: Chatting = data[data.length - 1];
                this._mapMessages.set(topic, {
                    topicName: (topic === TOPIC_PUBLIC_CHAT ? this.i18nService.translate('chatting.publicChat',{},"Thảo luận chung") : (lastMessage ? lastMessage.name : "")),
                    unreadMessageCount: 0,
                    history: [...data.messages]
                });
            }
        } */
        this.makeAllReaded(topic);
        this.setChangeTopic(topic);
        this._topicChangedBehavior.next(topic);
    }

    public getActiveList(): any{
        var activeList =  Array.from(this._mapMessages, ([id, data]) => ({id, data})).filter(element=>
            (element.id != this._activeChat) && (element.data.status)

        );
        return activeList;
    }
    //Cap nhat lai thong tin khi co thay doi ve thong tin user. Vi du: displayName
    public updateActiveChat(listActiveChat: string[]){
        const chatList = Array.from(this._mapMessages, ([id, data]) => ({id, data})).filter(element=>(element.data.status) );
        if(listActiveChat && chatList ){
            chatList.forEach(element => {
                if(listActiveChat.includes(element.id)){
                    let remoteParticipant = this.poolService.getPeer(element.id);
                    const data = this._mapMessages.get(element.id);
                    this._mapMessages.set(element.id, {
                        topicName: remoteParticipant ? remoteParticipant.displayName : '' ,
                        status: !!(remoteParticipant),
                        unreadMessageCount: data.unreadMessageCount,
                        history: data.history
                    });
                    
                }
            });
            this.updateMapMessage.next(true);
        }
    }

    // public getTopic(displayName : string): String {
    //     // var listTopic  = Array.from(this._mapMessages.keys());
    //     var infoPeers = this.poolService.getInfoFullPeers();
    //     var remoteParticipant = infoPeers.filter((element : RemoteParticipant)=> element.displayName == displayName);
    //     return remoteParticipant
    // }

    public initActiveChat(listActiveChat: string[]): void {
        if (listActiveChat) {
            listActiveChat.forEach(topic => {
                let remoteParticipant = this.poolService.getPeer(topic);
                    this._mapMessages.set(topic, {
                        topicName: remoteParticipant ? remoteParticipant.displayName : '' ,
                        status: !!(remoteParticipant),
                        unreadMessageCount: 0,
                        history: null
                    });
            });
            this.updateMapMessage.next(true);
        }
    }

    public updateStatusMapMessages(peerId: string, status: boolean): void {
        if (this._mapMessages.has(peerId)) {
            let remoteParticipant = this.poolService.getPeer(peerId);
            if(remoteParticipant){
                this._mapMessages.get(peerId).topicName = remoteParticipant.displayName;
            }
            this._mapMessages.get(peerId).status = status;
        } 
        this.updateMapMessage.next(true);
    }

    public get newMessageBehavior(): Observable<BaseMessage> {
        return this._newMessageBehavior.asObservable();
    }

    public get messageReadedBehavior(): Observable<string> {
        return this._messageReadedBehavior.asObservable();
    }

    public async newMessage(topic: string, msg: BaseMessage) {
        if (!this._mapMessages.has(topic)) {
            let remoteParticipant =  this.poolService.getPeer(topic);
            this._mapMessages.set(topic, {
                topicName: (topic === TOPIC_PUBLIC_CHAT ? this.i18nService.translate('chatting.publicChat',{},"Thảo luận chung") : remoteParticipant.displayName),
                status: true,
                unreadMessageCount: 0,
                history: [msg]
            });
            this.updateMapMessage.next(true);
        } else {
            if (!this._mapMessages.get(topic).history) {
                this._mapMessages.get(topic).history = (await this.getHistory(topic)).history;
            }
            else this._mapMessages.get(topic).history.push(msg);
        }
        if (this.containerRightType != "chatting" || this._activeChat != topic) {
            this._mapMessages.get(topic).unreadMessageCount++;
            this.poolService.getPeer(topic)?.setWaitingMessage(this._mapMessages.get(topic).unreadMessageCount);
        }
        msg.topic = topic;
        this._newMessageBehavior.next(msg);
    }

    public makeAllReaded(topic: string): void {
        if (!this._mapMessages.has(topic)) return;
        this._mapMessages.get(topic).unreadMessageCount = 0;
        this.poolService.getPeer(topic)?.setWaitingMessage(0);
        this._messageReadedBehavior.next(topic);
        this.updateMapMessage.next(true);
    }

    public publishChatting(msg: BaseMessage): void {
        this.chattingBehaviorSubject.next(msg);
    }

    public receiveChatting(): Observable<BaseMessage> {
        return this.chattingBehaviorSubject.asObservable();
    }

    public initChatHistory(chatHistory: BaseMessage[]): void {
        this._mapMessages.set(TOPIC_PUBLIC_CHAT, {
            topicName: this.i18nService.translate('chatting.publicChat',{},"Thảo luận chung"),
            status: true,
            unreadMessageCount: 0,
            history: chatHistory
        });
        this.updateMapMessage.next(true)
    }

    public async getHistory(topic?: string): Promise<Conversation> {
        if (!topic) topic = this._activeChat;
        if (!this._mapMessages.has(topic) || !this._mapMessages.get(topic).history) {
            let data = (topic === TOPIC_PUBLIC_CHAT
                ? await this.socketService.sendRequest('chatHistory', { topic })
                : await this.socketService.sendRequest('privateChatHistory', { receiver: topic }));
            if (data && data.messages && data.messages.length) {
                // let lastMessage: BaseMessage = data.messages[data.messages.length - 1];
                let remoteParticipant =  this.poolService.getPeer(topic);
                this._mapMessages.set(topic, {
                    // topicName: (topic === TOPIC_PUBLIC_CHAT ? this.i18nService.translate('chatting.publicChat',{},"Thảo luận chung") : (lastMessage ? lastMessage.name : "")),
                    topicName: (topic === TOPIC_PUBLIC_CHAT ? this.i18nService.translate('chatting.publicChat',{},"Thảo luận chung") : (remoteParticipant ? remoteParticipant.displayName : "")),
                    status: true,
                    unreadMessageCount: 0,
                    history: [...data.messages]
                });
                this.updateMapMessage.next(true);
            } else {
                return {topicName: topic, history: []} as Conversation;
            }
        }
        return this._mapMessages.get(topic);
    }

    public deleteChat(msgId: string, sender: string, receiver: string, isPrivate: boolean): void {
        const peerId = this.localParticipantSerivce.getPeerId();
        let topic = receiver ? (peerId == sender ? receiver : sender) : TOPIC_PUBLIC_CHAT;

        if (!this._mapMessages.has(topic)) return;
        let conversation = this._mapMessages.get(topic);
        let index = conversation.history?.findIndex(msg => msg.id == msgId);
        if (index == -1) return;
        conversation.history?.splice(index, 1);
        this._deleteMessageBehavior.next(msgId);
    }

    public get deleteMessageBehavior(): Observable<string> {
        return this._deleteMessageBehavior.asObservable();
    }

    async deleteMessage(chatId: string): Promise<void> {
        try {
            let result = await this.socketService.sendRequest('deleteChat', { chatId });
            if (!result) return;
            let conversation = this._mapMessages.get(this._activeChat);
            let index = conversation.history.findIndex(msg => msg.id == chatId);
            if (index == -1) return;
            conversation.history.splice(index, 1);
            this._deleteMessageBehavior.next(chatId);
        } catch (error) {
            console.log('deleteMessage() [error:"%o"]', error);
        }
    }

    async sendChatMessage<T extends BaseMessage>(chatMessage: T): Promise<any> {
        try {
            let receiver = this._activeChat != TOPIC_PUBLIC_CHAT ? this._activeChat : null;
            let cmdPattern = /\\request\s(.+(\s.+)*)/;
            let appConfig = await this.settingService.getAppConfig();
            if (appConfig.test.enable && chatMessage.type == "message" && cmdPattern.test(chatMessage.content)) {
                let params = chatMessage.content
                    .match(cmdPattern)[1]
                    .split(/\s+(\{.*?\})?/g)
                    .filter((s: string) => s)
                    .map((s: string) => {
                        let str = decodeURI(s);
                        try { return JSON.parse(str) } catch(err) { return str; }
                    });
                this.socketService.sendRequest.apply(this.socketService, params);
                return;
            }
            const message = await this.socketService.sendRequest(
                this._activeChat == TOPIC_PUBLIC_CHAT ? 'chatMessage' : 'privateChat',
                { chatMessage, receiver }
            );
            this.newMessage(this._activeChat, message);
            return message;
        } catch (error) {
            console.log('sendChatMessage() [error:"%o"]', error);
            return false;
        }
    }

    async sendPrivateChatMessage(chatMessage: BaseMessage, receiver: string): Promise<any> {
        try {
            return await this.socketService.sendRequest('privateChat', { receiver, chatMessage });
        } catch (error) {
            console.log('sendPrivateChat() [error:"%o"]', error);
            return false;
        }
    }

    async getPrivateChatMessage(receiver) {
        try {
            const messages = await this.socketService.sendRequest('privateChatHistory', { receiver });
            // this.privateChattingService.initChatHistory(messages.messages, receiver);
        }
        catch (error) {
            console.log('getPrivateChatMessage() [error:"%o"]', error);
        }
    }

    async sendFileMessage(fileChatMessage: BaseMessage): Promise<void> {
        try {
            await this.socketService.sendRequest('sendFile', fileChatMessage);
        } catch (error) {
            console.log('sendFileMessage() [error:"%o"]', error);
        }
    }

    public incUnseenMessageCounting(): void {
        if (this.containerRightType !== 'chatting' || !this.isRightContainerShown) {
            this.isReadCounting++;
            this.isReadCountingSubject.next(this.isReadCounting);
        }
    }

    public resetUnseenMessageCounting(type: RightPaneViewType, isShown: boolean): void {
        // this.containerRightType = type;
        this.isRightContainerShown = isShown;
        if (type === 'chatting' && isShown) {
            this.isReadCounting = 0;
            this.isReadCountingSubject.next(this.isReadCounting);
        }
        this.onContainerShownSubject.next(type === 'chatting');
    }

    public getUnseenMessageCounting(): Observable<number> {
        return this.isReadCountingSubject.asObservable();
    }

    public sendFileChatToServer(fileChat: File): Observable<any> {
        const formData = new FormData();
        const headers = { sessionToken: this.activatedRoute.snapshot.queryParams.sessionToken};
        formData.append('file', fileChat, fileChat.name);
        const uploadurl = environment.domain.api + '/api/edumeet-management/v1/chat/upload';
        return this.http.post<any>(uploadurl, formData,
            {
            headers: headers,
            reportProgress: true,
            observe: 'events',
        },);
    }

    public updateConversation(peerId?: string): void {
        this.updateConversationSubject.next(peerId);
    }

    public onUpdateConversation(): Observable<string> {
        return this.updateConversationSubject.asObservable();
    }

    public onContainerShown(): Observable<boolean> {
        return this.onContainerShownSubject.asObservable();
    }

    public onSocket(): void {
        this.socketService.onSocket('connect', async () => {
            console.log("socket is established");
            
        });
        this.socketService.onSocket('notification', async (msg: { method: string, data: any }) => {
            switch (msg.method) {
                case 'chatMessage': {
                    const { chatMessage } = msg.data;
                    this.newMessage(TOPIC_PUBLIC_CHAT, chatMessage as BaseMessage);
                    break;
                }

                case 'privateChat': {
                    const { chatMessage } = msg.data;                
                    this.newMessage(chatMessage.sender, chatMessage as BaseMessage);
                    break;
                }

                case 'chatDeleted': {
                    const { chatId, sender, receiver, isPrivate } = msg.data;
                    this.deleteChat(chatId, sender, receiver, isPrivate);
                }
            }
        });
    }
}
