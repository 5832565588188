import {Action} from '@ngrx/store';

export const CHANGE_AUDIO_DEVICE = 'change audio device';
export const CHANGE_AUDIO_OUTPUT_DEVICE = 'change audio output device';
export const CHANGE_WEBCAM = 'change webcam';
export const SET_VIDEO_RESOLUTION = 'set video resolution';
export const SET_VIDEO_FRAME_RATE = 'set video frame rate';
export const SET_SCREEN_SHARING_RESOLUTION = 'set screen sharing resolution';
export const SET_SCREEN_SHARING_FRAME_RATE = 'set screen sharing frame rate';
export const SET_ASPECT_RATIO = 'set aspect ratio';
export const SET_DISPLAY_NAME = 'st display name';
export const SET_IS_NEED_HANDLE_JOIN_LINK = 'set is need handle join link';
export const TOGGLE_ADVANCED_MODE = 'toggle advanced mode';
export const TOGGLE_PERMANENT_TOPBAR = 'toggle permanent topbar';
export const TOGGLE_BUTTON_CONTROL_BAR = 'toggle button control bar';
export const TOGGLE_DRAWER_OVERLAYED = 'toggle drawer overlayed';
export const TOGGLE_SHOW_NOTIFICATIONS = 'toggle show notifications';
export const SET_AUDIO_PRESET = 'set audio preset';
export const SET_ECHO_CANCELLATION = 'set echo cancellation';
export const SET_AUTO_GAIN_CONTROL = 'set auto gain control';
export const SET_NOISE_SUPPRESSION = 'set noise suppression';
export const SET_VOICE_ACTIVATED_UNMUTE = 'set void activated unmute';
export const SET_NOISE_THRESHOLD = 'set noise threshold';
export const SET_SAMPLE_RATE = 'set sample rate';
export const SET_CHANNEL_COUNT = 'set channel count';
export const SET_SAMPLE_SIZE = 'set sample size';
export const SET_OPUS_STEREO = 'set opus stereo';
export const SET_OPUS_DTX = 'set opus dtx';
export const SET_OPUS_FEC = 'set opus fec';
export const SET_OPUS_PTIME = 'set opus ptime';
export const SET_OPUS_MAX_PLAYBACK_RATE = 'set opus max playback rate';
export const SET_ENABLE_OPUS_DETAILS = 'set enable opus details';
export const SET_DEFAULT_AUDIO = 'set default audio';
export const TOGGLE_HIDDEN_CONTROLS = 'toggle hidden controls';
export const TOGGLE_NOTIFICATION_SOUNDS = 'toggle notification sounds';
export const SET_LAST_N = 'set last n';
export const TOGGLE_MIRROR_OWN_VIDEO = 'toggle mirror own video';
export const TOGGLE_HIDE_NO_VIDEO_PARTICIPANTS = 'toggle hide no video participants';
export const SET_MEDIA_PERMS = 'set media perms';
export const SET_AUDIO_MUTED = 'set audio muted';
export const SET_VIDEO_MUTED = 'set video muted';
export const SET_LOCAL_PICTURE = 'set local picture';
export const SET_RECORDER_SUPPORTED_MIME_TYPES = 'set recorder supported mime types';
export const SET_RECORDER_PREFERRED_MIME_TYPE = 'set recorder preferred mime type';

export class SetSelectedAudioDevice implements Action{
    readonly type = CHANGE_AUDIO_DEVICE;
    public payload: any;
    constructor( deviceId ) {
        this.payload = { deviceId };
    }
}
export class SetSelectedAudioOutputDevice implements Action{
    readonly type = CHANGE_AUDIO_OUTPUT_DEVICE;
    public payload: any;
    constructor( deviceId ) {
        this.payload = { deviceId };
    }
}
export class SetSelectedWebcamDevice implements Action{
    readonly type = CHANGE_WEBCAM;
    public payload: any;
    constructor( deviceId ) {
        this.payload = { deviceId };
    }
}
export class SetVideoResolution implements Action{
    readonly type = SET_VIDEO_RESOLUTION;
    public payload: any;
    constructor( resolution ) {
        this.payload = { resolution };
    }
}
export class SetVideoFrameRate implements Action{
    readonly type = SET_VIDEO_FRAME_RATE;
    public payload: any;
    constructor( frameRate ) {
        this.payload = { frameRate };
    }
}
export class SetScreenSharingResolution implements Action{
    readonly type = SET_SCREEN_SHARING_RESOLUTION;
    public payload: any;
    constructor( screenSharingResolution ) {
        this.payload = { screenSharingResolution };
    }
}
export class SetScreenSharingFrameRate implements Action{
    readonly type = SET_SCREEN_SHARING_FRAME_RATE;
    public payload: any;
    constructor( screenSharingFrameRate ) {
        this.payload = { screenSharingFrameRate };
    }
}
export class SetAspectRatio implements Action{
    readonly type = SET_ASPECT_RATIO;
    public payload: any;
    constructor( aspectRatio ) {
        this.payload = { aspectRatio };
    }
}
export class SetDisplayName implements Action{
    readonly type = SET_DISPLAY_NAME;
    public payload: any;
    constructor( displayName ) {
        this.payload = { displayName };
    }
}
export class SetIsNeedHandleJoinLink implements Action{
    readonly type = SET_IS_NEED_HANDLE_JOIN_LINK;
    public payload: any;
    constructor( val ) {
        this.payload = { val };
    }
}
export class ToggleAdvancedMode implements Action{
    readonly type = TOGGLE_ADVANCED_MODE;
    public payload: any;
    constructor() {
    }
}

export class TogglePermanentTopBar implements Action{
    readonly type = TOGGLE_PERMANENT_TOPBAR;
    public payload: any;
    constructor() {}
}

export class ToggleButtonControlBar implements Action{
    readonly type = TOGGLE_BUTTON_CONTROL_BAR;
    public payload: any;
    constructor() {}
}

export class ToggleDrawerOverlayed implements Action{
    readonly type = TOGGLE_DRAWER_OVERLAYED;
    public payload: any;
    constructor() {}
}

export class ToggleShowNotifications implements Action{
    readonly type = TOGGLE_SHOW_NOTIFICATIONS;
    public payload: any;
    constructor() {}
}

export class SetAudioPreset implements Action{
    readonly type = SET_AUDIO_PRESET;
    public payload: any;
    constructor( audioPreset ) {
        this.payload = { audioPreset };
    }
}
export class SetEchoCancellation implements Action{
    readonly type = SET_ECHO_CANCELLATION;
    public payload: any;
    constructor( echoCancellation ) {
        this.payload = { echoCancellation };
    }
}
export class SetAutoGainControl implements Action{
    readonly type = SET_AUTO_GAIN_CONTROL;
    public payload: any;
    constructor( autoGainControl ) {
        this.payload = { autoGainControl };
    }
}
export class SetNoiseSuppression implements Action{
    readonly type = SET_NOISE_SUPPRESSION;
    public payload: any;
    constructor( noiseSuppression ) {
        this.payload = { noiseSuppression };
    }
}
export class SetVoiceActivatedUnmute implements Action{
    readonly type = SET_VOICE_ACTIVATED_UNMUTE;
    public payload: any;
    constructor( voiceActivatedUnmute ) {
        this.payload = { voiceActivatedUnmute };
    }
}
export class SetNoiseThreshold implements Action{
    readonly type = SET_NOISE_THRESHOLD;
    public payload: any;
    constructor( noiseThreshold ) {
        this.payload = { noiseThreshold };
    }
}
// Advanced audio settings
export class SetSampleRate implements Action{
    readonly type = SET_SAMPLE_RATE;
    public payload: any;
    constructor( sampleRate ) {
        this.payload = { sampleRate };
    }
}
export class SetChannelCount implements Action{
    readonly type = SET_CHANNEL_COUNT;
    public payload: any;
    constructor( channelCount ) {
        this.payload = { channelCount };
    }
}
export class SetSampleSize implements Action{
    readonly type = SET_SAMPLE_SIZE;
    public payload: any;
    constructor( sampleSize ) {
        this.payload = { sampleSize };
    }
}
export class SetOpusStereo implements Action{
    readonly type = SET_OPUS_STEREO;
    public payload: any;
    constructor( opusStereo ) {
        this.payload = { opusStereo };
    }
}
export class SetOpusDtx implements Action{
    readonly type = SET_OPUS_DTX;
    public payload: any;
    constructor( opusDtx ) {
        this.payload = { opusDtx };
    }
}
export class SetOpusFec implements Action{
    readonly type = SET_OPUS_FEC;
    public payload: any;
    constructor( opusFec ) {
        this.payload = { opusFec };
    }
}
export class SetOpusPtime implements Action{
    readonly type = SET_OPUS_PTIME;
    public payload: any;
    constructor( opusPtime ) {
        this.payload = { opusPtime };
    }
}
export class SetOpusMaxPlaybackRate implements Action{
    readonly type = SET_OPUS_MAX_PLAYBACK_RATE;
    public payload: any;
    constructor( opusMaxPlaybackRate ) {
        this.payload = { opusMaxPlaybackRate };
    }
}
export class SetEnableOpusDetails implements Action{
    readonly type = SET_ENABLE_OPUS_DETAILS;
    public payload: any;
    constructor( enableOpusDetails ) {
        this.payload = { enableOpusDetails };
    }
}
// Default audio device
export class SetDefaultAudio implements Action{
    readonly type = SET_DEFAULT_AUDIO;
    public payload: any;
    constructor( audio ) {
        this.payload = { audio };
    }
}
export class ToggleHiddenControls implements Action{
    readonly type = TOGGLE_HIDDEN_CONTROLS;
    public payload: any;
    constructor() {}
}

export class ToggleNotificationSounds implements Action{
    readonly type = TOGGLE_NOTIFICATION_SOUNDS;
    public payload: any;
    constructor() {}
}

export class SetLastN implements Action{
    readonly type = SET_LAST_N;
    public payload: any;
    constructor(lastN) {
        this.payload = {lastN};
    }
}

export class ToggleMirrorOwnVideo implements Action{
    readonly type = TOGGLE_MIRROR_OWN_VIDEO;
    public payload: any;
    constructor() {}
}

export class ToggleHideNoVideoParticipants implements Action{
    readonly type = TOGGLE_HIDE_NO_VIDEO_PARTICIPANTS;
    public payload: any;
    constructor() {}
}

export class SetMediaPerms implements Action{
    readonly type = SET_MEDIA_PERMS;
    public payload: any;
    constructor( mediaPerms ) {
        this.payload = { mediaPerms };
    }
}
export class SetAudioMuted implements Action{
    readonly type = SET_AUDIO_MUTED;
    public payload: any;
    constructor( audioMuted ) {
        this.payload = { audioMuted };
    }
}
export class SetVideoMuted implements Action{
    readonly type = SET_VIDEO_MUTED;
    public payload: any;
    constructor( videoMuted ) {
        this.payload = { videoMuted };
    }
}
export class SetLocalPicture implements Action{
    readonly type = SET_LOCAL_PICTURE;
    public payload: any;
    constructor( localPicture ) {
        this.payload = { localPicture };
    }
}
export class SetRecorderSupportedMimeTypes implements Action{
    readonly type = SET_RECORDER_SUPPORTED_MIME_TYPES;
    public payload: any;
    constructor( recorderSupportedMimeTypes ) {
        this.payload = { recorderSupportedMimeTypes };
    }
}
export class SetRecorderPreferredMimeType implements Action{
    readonly type = SET_RECORDER_PREFERRED_MIME_TYPE;
    public payload: any;
    constructor( recorderPreferredMimeType ) {
        this.payload = { recorderPreferredMimeType };
    }
}

export type All =
    SetSelectedAudioDevice |
    SetSelectedAudioOutputDevice |
    SetSelectedWebcamDevice |
    SetVideoResolution |
    SetVideoFrameRate |
    SetScreenSharingResolution |
    SetScreenSharingFrameRate |
    SetAspectRatio |
    SetDisplayName |
    SetIsNeedHandleJoinLink |
    ToggleAdvancedMode |
    TogglePermanentTopBar |
    ToggleButtonControlBar |
    ToggleDrawerOverlayed |
    ToggleShowNotifications |
    SetAudioPreset |
    SetEchoCancellation |
    SetAutoGainControl |
    SetNoiseSuppression |
    SetVoiceActivatedUnmute |
    SetNoiseThreshold |
    SetSampleRate |
    SetChannelCount |
    SetSampleSize |
    SetOpusStereo |
    SetOpusDtx |
    SetOpusFec |
    SetOpusPtime |
    SetOpusMaxPlaybackRate |
    SetEnableOpusDetails |
    SetDefaultAudio |
    ToggleHiddenControls |
    ToggleNotificationSounds |
    SetLastN |
    ToggleMirrorOwnVideo |
    ToggleHideNoVideoParticipants |
    SetMediaPerms |
    SetAudioMuted |
    SetVideoMuted |
    SetLocalPicture |
    SetRecorderSupportedMimeTypes |
    SetRecorderPreferredMimeType;
