import {Action} from '@ngrx/store';

export const TOGGLE_TOOL_AREA = 'toggle tool area';
export const OPEN_TOOL_AREA = 'open tool area';
export const CLOSE_TOOL_AREA = 'close tool area';
export const SET_TOOL_TAB = 'set tool tab';

export class ToggleToolArea implements Action{
    readonly type = TOGGLE_TOOL_AREA;
    public payload: any;
    constructor(message: string) {
        this.payload = {message};
    }

}

export class OpenToolArea implements Action{
    readonly type = OPEN_TOOL_AREA;
    public payload: any;
    constructor(message: string) {
        this.payload = {message};
    }

}

export class CloseToolArea implements Action{
    readonly type = CLOSE_TOOL_AREA;
    public payload: any;
    constructor() {
    }

}
export class SetToolTab implements Action{
    readonly type = SET_TOOL_TAB;
    public payload: any;
    constructor(toolTab) {
        this.payload = {toolTab};
    }
}

export type All =
    ToggleToolArea |
    OpenToolArea |
    CloseToolArea |
    SetToolTab;
