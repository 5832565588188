/* tslint:disable:variable-name */
import {Injectable, Optional} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Consumer} from 'mediasoup-client/lib/Consumer';

@Injectable({
    providedIn: 'root'
})
export class ShareDataService {

    private initDataSubject = new BehaviorSubject<boolean>(null);
    private isBreakRoomSubject = new BehaviorSubject<boolean>(null);
    private callbackAfterNewPeer = new BehaviorSubject<any>(null);
    private callbackAfterPeerClosed = new BehaviorSubject<string>(null);
    private callbackAfterNewConsumer = new BehaviorSubject<Consumer>(null);
    private updateStreamingParticipant = new BehaviorSubject<string>(null);

    public setIsBreakRoom(status: boolean): void {
        this.isBreakRoomSubject.next(status);
    }

    public initJoinData(): void {
        this.initDataSubject.next(true);
    }

    public onInitJoinData(): Observable<boolean> {
        return this.initDataSubject.asObservable();
    }

    public onIsBreakRoom(): Observable<boolean> {
        return this.isBreakRoomSubject.asObservable();
    }

    public getIsBreakRoom(): boolean{
        return this.isBreakRoomSubject.value;
    }

    public setCallbackAfterNewPeer(value): void {
        this.callbackAfterNewPeer.next(value);
    }

    public onCallbackAfterNewPeer(): Observable<any> {
        return this.callbackAfterNewPeer.asObservable();
    }

    public setCallbackAfterPeerClosed(value): void{
        this.callbackAfterPeerClosed.next(value);
    }

    public onCallbackAfterPeerClosed(): Observable<string>{
        return this.callbackAfterPeerClosed.asObservable();
    }

    public setCallbackAfterNewConsumer(consumer: Consumer): void {
        this.callbackAfterNewConsumer.next(consumer);
    }

    public onCallbackAfterNewConsumer(): Observable<Consumer> {
        return this.callbackAfterNewConsumer.asObservable();
    }

    public onUpdateStreamingParticipant(): Observable<string> {
        return this.updateStreamingParticipant.asObservable();
    }

    public setUpdateStreamingParticipant(peerId): void {
        this.updateStreamingParticipant.next(peerId);
    }
}
