import * as NoiseModule from './noise';
// import NoiseModule from './noise.js'
// const NoiseModule = require("./noise.js");
const TYPE_BIQUAD = "notch";
/**
 * BIQUAD GAIN: only used for lowshelf, highshelf, and peaking filters.
 * Default: 0, Min: -3.4E38, Max: 1541
 */
const GAIN = 0.2;
/**
 * Voice Frequency
 * Values: M: [100Hz...900Hz], F: [350Hz...3KHz]
 */
const FREQUENCY = 850;
// Q Factor values: [0...1] * 30
const QUANLITY = 15;

let Module;

var inputBuffer:any=[];
var outputBuffer:any= [];
var bufferSize = 2048;
var src;
var dst;
var scriptProcessorNode
var noiseNode;
var frameBuffer:any = [];
var sizeArray = 480;
var destinationAnalyserNode;
var sourceAnalyserNode; 
function initializeNoiseSuppressionModule() {
    if (Module) {
        return;
    }
    Module = {
        noExitRuntime: true,
        noInitialRun: true,
        preInit: [],
        preRun: [],
        postRun: [function () {
            // console.log(`Loaded Javascript Module OK`);
            }],
        memoryInitializerPrefixURL: "bin/",
        arguments: ['input.ivf', 'output.raw']
    };
    NoiseModule(Module);
    Module.st = Module._rnnoise_create();
    Module.ptr = Module._malloc(sizeArray * 4);
}


function removeNoise(buffer) {
    let ptr = Module.ptr;
    let st = Module.st;

    for (let i = 0; i < sizeArray; i++) {
      Module.HEAPF32[(ptr >> 2) + i] = buffer[i] * 16384 * 2;
    }
    Module._rnnoise_process_frame(st, ptr, ptr);
    for (let i = 0; i < sizeArray; i++) {
      buffer[i] = Module.HEAPF32[(ptr >> 2) + i] / (16384 * 2);
    }
    return buffer;
}


function stopMediaFilterStream() {
    if (src) {
        src.disconnect();
        dst.disconnect();
        scriptProcessorNode.disconnect();
        noiseNode.disconnect();
        sourceAnalyserNode.disconnect()
        destinationAnalyserNode.disconnect()
    }
}


function createMediaFilterStream(stream: MediaStream, useFilterNoise: boolean = false, bufferSizeFilterNoise? : number  , sizeArrayFilterNoise?: number): MediaStream {
    try {
        // const stream = await navigator.mediaDevices.getUserMedia(constraints);
        
        if (useFilterNoise) {
            if (bufferSizeFilterNoise) bufferSize = bufferSizeFilterNoise;
            if (sizeArray) sizeArray = sizeArrayFilterNoise;
            // console.log("useFilterNoise", useFilterNoise);
            const ctx = new AudioContext();
            src = ctx.createMediaStreamSource(stream);

            sourceAnalyserNode = new AnalyserNode(ctx, {
                fftSize: 2048,
                maxDecibels: -25,
                minDecibels: -60,
                smoothingTimeConstant: 0.5,
            });
            
            destinationAnalyserNode = ctx.createAnalyser();
            // sourceAnalyserNode.smoothingTimeConstant = 0;
            destinationAnalyserNode.smoothingTimeConstant = 0;
            // sourceAnalyserNode.fftSize = 1024;
            destinationAnalyserNode.fftSize = 2048;
            dst = ctx.createMediaStreamDestination();

            initializeNoiseSuppressionModule();
            scriptProcessorNode = ctx.createScriptProcessor(bufferSize, 1, 1);
            noiseNode = ctx.createScriptProcessor(bufferSize, 1, 1);

            noiseNode.onaudioprocess = function (e) {
                var input = e.inputBuffer.getChannelData(0);
                var output = e.outputBuffer.getChannelData(0);
                for (let i = 0; i < input.length; i++) {
                    output[i] = input[i];// + (Math.random() / 100);  
                    
            }
            
            };
            scriptProcessorNode.onaudioprocess = (event) => {
                const inputData = event.inputBuffer.getChannelData(0);
                var outputData= event.outputBuffer.getChannelData(0);
                //refactor
                for (let i = 0; i < bufferSize; i++) {
                    inputBuffer.push(inputData[i]);
                }
                
                while (inputBuffer.length >= sizeArray) {

                    // Sử dụng 480
                    frameBuffer = removeNoise(inputBuffer.slice(0, sizeArray));
                    inputBuffer = inputBuffer.slice(sizeArray, inputBuffer.length);
                    for (let i = 0; i < sizeArray; i++) {
                        outputBuffer.push(frameBuffer[i]);
                    }

                }
                if (outputBuffer.length < bufferSize) {
                    return;
                }
                else{
                    for (let i = 0; i < bufferSize; i++) {
                        outputData[i] = outputBuffer[i];
                    }
                    // outputData = new Float32Array(outputBuffer.slice(0, bufferSize));
                    outputBuffer = outputBuffer.slice(bufferSize, outputBuffer.length);
                }

            };

            src.connect(noiseNode);
            noiseNode.connect(sourceAnalyserNode);
            sourceAnalyserNode.connect(scriptProcessorNode);
            scriptProcessorNode.connect(destinationAnalyserNode);

            destinationAnalyserNode.connect(dst);

            // Create a new MediaStream with the processed audio data
            const filteredStream = dst.stream;
            return filteredStream;

        } else {
            return stream;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export {
    createMediaFilterStream,
    stopMediaFilterStream
}