<div class="background-popover">
    <!-- <vnpt-divider vText="{{'security.audio' | vI18n:{}:'Âm thanh'}}"></vnpt-divider> -->
    <div class="list-item security-opt" (click)="changeDisplayName()" >
        <i vnpt-icon vType="Rename" [vSize]="'sm'" class="noci"></i>
        <!-- <i vnpt-icon [vSize]="'sm'"  class="noci cilor icon-gomeet-state-micban"></i> -->
        <span>{{'more-options.changeName' | vI18n:{}:'Đổi tên'}}</span>
    </div>

    <div class="list-item security-opt" (click)="changeRoomName()" *ngIf="hasPermissionModerator">
        <i vnpt-icon vType="Edit" [vSize]="'sm'" class="noci"></i>
        <!-- <i vnpt-icon [vSize]="'sm'"  class="noci cilor icon-gomeet-state-micban"></i> -->
        <span>{{'room-name.renameTitle' | vI18n:{}:'Đổi tên phòng họp'}}</span>
    </div>

    <div class="list-item security-opt"  (click)="reconnectAudio()">
        <i vnpt-icon vType="Refresh" [vSize]="'sm'" class="noci"></i>
        <!-- <i vnpt-icon [vSize]="'sm'"  class="noci cilor icon-gomeet-state-micban"></i> -->
        <span>{{'more-options.reconnectAudio' | vI18n:{}:'Kết nối lại âm thanh'}}</span>
    </div>

    <div class="list-item security-opt" (click)="openInfo()">
        <!-- <i vnpt-icon vType="" [vSize]="'sm'" class="noci"></i> -->
        <i vnpt-icon vType="Info" [vSize]="'sm'" class="noci"></i>
        <span>{{'more-options.roomInfo' | vI18n:{}:'Xem thông tin cuộc họp'}}</span>
    </div>

    <div *ngIf="hasPermissionModerator" class="list-item security-opt" (click)="exportPeers()">
        <i vnpt-icon vType="DownloadDocument" [vSize]="'sm'" class="noci"></i>
        <span>{{'more-options.exportPeers' | vI18n:{}:'Xuất danh sách người tham gia'}}</span>
    </div>

    <div class="list-item security-opt" (click)="durationOpen()"  *ngIf="isConfigDuration">
        <!-- <i vnpt-icon vType="" [vSize]="'sm'" class="noci"></i> -->
        <i vnpt-icon [vSize]="'sm'"  class="noci icon-gomeet-add icon-white"></i>
        <span>{{ "more-options.duration" | vI18n:{}:"Gia hạn" }}</span>
    </div>

    <vnpt-divider class="divider" style="margin-bottom: 8px; font-size: 16px !important;" vText="{{'room-config.gomeetSup' | vI18n:{}:'Gomeet hỗ trợ'}}"></vnpt-divider>

    <div class="list-item security-opt" (click)="openSupport()" >
        <i vnpt-icon vType="Telemarketer" [vSize]="'sm'" class="noci icon-white"></i>
        <!-- <i vnpt-icon [vSize]="'sm'" AlertSolid class="noci cilor icon-gomeet-add icon-white"></i> -->
        <span>{{'more-options.support' | vI18n:{}:'Hỗ trợ'}}</span>
    </div>

    <div class="list-item security-opt" (click)="openFeedback()" >
        <i vnpt-icon vType="ReportAlert" [vSize]="'sm'" class="noci icon-white"></i>
        <!-- <i vnpt-icon [vSize]="'sm'" AlertSolid class="noci cilor icon-gomeet-add icon-white"></i> -->
        <span>{{'more-options.feedback' | vI18n:{}:'Gửi phản hồi'}}</span>
    </div>

    <div class="list-item security-opt" (click)="openReport()" >
        <i vnpt-icon vType="ReportHacked" [vSize]="'sm'" class="noci icon-white"></i>
        <!-- <i vnpt-icon [vSize]="'sm'" AlertSolid class="noci cilor icon-gomeet-add icon-white"></i> -->
        <span>{{'report.title' | vI18n:{}:'Báo cáo sự cố'}}</span>
    </div>
</div>
