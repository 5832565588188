<div class="background-popover">
    <!-- <vnpt-divider vText="{{'security.audio' | vI18n:{}:'Âm thanh'}}"></vnpt-divider> -->
    <div class="list-item security-opt" (click)="checkmuteAll ? '' : muteAll()">
        <i vnpt-icon [vSize]="'sm'" [vColor]="'danger'" class="noci cilor icon-gomeet-state-micban"></i>
        <span>{{'security.muteAll' | vI18n:{}:'Tắt tất cả mic'}}</span>
    </div>

    <div class="list-item security-opt" (click)="checkmuteAllExceptPresenterEvent ? '' : muteAllExceptPresenter()">
        <i vnpt-icon [vSize]="'sm'" [vColor]="'danger'" class="noci cilor icon-gomeet-state-micban"></i>
        <span>{{'security.muteAllExceptPresenter' | vI18n:{}:'Kích hoạt im lặng trừ người trình bày'}}</span>
    </div>

    <div class="list-item security-opt" (click)="checkstopAllWebcamEvent ? '' : stopAllWebcam()">
        <i vnpt-icon [vSize]="'sm'" [vColor]="'danger'" class="noci cilor icon-gomeet-state-videoban"></i>
        <span>{{'security.stopAllWebcam' | vI18n:{}:'Chặn camera'}}</span>
    </div>
    
    <div class="list-item security-opt" (click)="checkstopAllScreenSharingEvent ? '' : stopAllScreenSharing()">
        <i vnpt-icon [vSize]="'sm'" [vColor]="'danger'" class="noci cilor icon-gomeet-circle-close"></i>
        <span>{{'security.stopAllScreenSharing' | vI18n:{}:'Tắt chia sẻ màn hình'}}</span>
    </div>

    <!-- <vnpt-divider vText="{{'security.display' | vI18n:{}:'Hiển thị'}}"></vnpt-divider>
    
    <div class="list-item security-opt">
        <i vnpt-icon [vType]="isLock ? 'Accept' : ''" class= "icon-white" class="noci"></i>
        <span>{{('security.forceLayout' | vI18n:{}:'Bắt buộc người tham gia hiển thị giống hiển thị')}}</span>
    </div> -->

    <vnpt-divider vText="{{'security.room' | vI18n:{}:'Phòng chờ'}}" class="text-color"></vnpt-divider>
    
    <div class="list-item security-opt" (click)="checkLockRoomEvent ? '' : clockRoom()"   >
        <i vnpt-icon [vType]="isLock ? '' : 'Accept'" class= "icon-white" class="noci"></i>
        <span>{{('security.selfCensor' | vI18n:{}:'Tự duyệt người tham gia')}}</span>
    </div>

    <vnpt-divider vText="{{'security.permission' | vI18n:{}:'Người tham gia có quyền'}}" class="text-color"></vnpt-divider>

    <div class="list-item security-opt" (click)="checkBlockAudioEvent ? '' : blockAllAudio()">
        <i vnpt-icon [vType]="audioUnblocked ? 'Accept' : ''" [vSize]="'sm'" class="noci"></i>
        <span>{{('security.audioShare' | vI18n:{}:'Bật mic của họ')}}</span>
    </div>

    <div class="list-item security-opt" (click)="checkBlockVideoEvent ? '' : blockAllWebcam()">
        <i vnpt-icon [vType]="webcamUnblocked ? 'Accept' : ''" [vSize]="'sm'" class="noci"></i>
        <span>{{('security.webcamUnblocked' | vI18n:{}:'Bật video của họ')}}</span>
    </div>

    <div class="list-item security-opt" (click)="checkBlockShareScreenEvent ? '' : grantScreenSharingPerrmission()">
        <i vnpt-icon [vType]="grantAllPeerScreenSharing ? 'Accept' : ''" [vSize]="'sm'" class="noci"></i>
        <span>{{('security.grantScreenSharingPermission' | vI18n:{}:'Chia sẻ màn hình của họ')}}</span>
        <!-- <span>{{grantAllPeerScreenSharing ? ('security.grantScreenSharingPermission' |  vI18n:{}:Cấp quyền chia sẻ màn hình') : 'Thu hồi quyền chia sẻ màn hình'}}</span> -->
    </div>

    <div class="list-item security-opt" (click)="checkWebcamForModeratorEvent ? '' : webcamsForModerator()">
        <i vnpt-icon [vType]="webcamsOnlyForModerator ? 'Accept' : ''" [vSize]="'sm'" class="noci"></i>
        <span>{{('security.webcamsForModeratorEnable' | vI18n:{}:'Chỉ xem video người trình bày')}}</span>
    </div>

    <!-- <vnpt-divider vText="{{'security.whiteboard' | vI18n:{}:'Bảng trắng'}}"></vnpt-divider>
    <div class="list-item security-opt" (click)="lockAllWhiteboard()">
        <i vnpt-icon [vType]="whiteboardLocked ? 'PageBlock' : 'PageEdit'" [vSize]="'sm'"></i>
        <span>{{whiteboardLocked ? ('security.whiteboardUnlock' | vI18n:{}:'Mở quyền vẽ bảng trắng') : ('security.whiteboardLock' | vI18n:{}:'Tắt quyền vẽ bảng trắng')}}</span>
    </div>
    <div class="list-item security-opt" (click)="triggerWhiteboards()">
        <i vnpt-icon vType="WhiteBoardApp32" [vSize]="'sm'"></i>
        <span [vI18n]="isWhiteboardOn ? 'joining.reaction.whiteboard.stop' : 'joining.reaction.whiteboard.start'"></span>
    </div> -->

    <!-- <div class="list-item security-opt" (click)="closeMeeting()">
        <i vnpt-icon vType="PowerButton" [vSize]="'sm'"></i>
        <span>{{'security.closeMeeting' | vI18n:{}:'Đóng cuộc họp'}}</span>
    </div> -->
</div>

<!-- <vnpt-modal [(vVisible)]="isVisible" vTitle="Kết thúc cuộc họp" (vOnCancel)="handleCancel()" (vOnOk)="handleOk()">
    <ng-container *vModalContent>
        <span>Bạn có thực sự muốn đóng cuộc họp không ?</span>
    </ng-container>
  </vnpt-modal> -->



