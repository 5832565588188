import { Participant } from './participant.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConsumerService, MediasoupBaseService } from '../services';
import { FORMAT_ACTION_REMOTE_PARTICIPANT } from '../constants';

/* interface StatusRemote {
    isViewing: boolean;
    isWebcamActivating: boolean;
}

interface FunctionsRemote {
    startWebcamViewing: () => void;
    stopWebcamViewing: () => void;
    setRaisedHand: (raisedHand: boolean, timestamp: number) => void;
}

export interface RemoteParticipantModel extends StatusRemote, FunctionsRemote {
    isRemoteSpeaking: boolean;
    isRemoteSharing: boolean;
    isActivatingSpeaker: boolean;
    webcamConsumerId: string;
    micConsumerId: string;
    screenConsumerID: string;
    startWebcamConsumer: () => void;
    stopWebcamConsumer: () => void;

}

export class RemoteParticipantDefault implements RemoteParticipantModel {
    id: string;
    displayName: string;
    kind = 'remote';
    roles: number[];
    webcamConsumerId = '';
    micConsumerId = '';
    screenConsumerID = '';
    isAudioMuted = true;
    isVideoMuted = true;
    isScreenMuted = true;
    isRemoteSpeaking = false;
    isRemoteSharing = false;
    isActivatingSpeaker = false;
    isWebcamActivating = false; // start/stop
    isMicActivating = true; // default mic consumer always receive from server
    isViewing = false;
    isRaisedHand: boolean;
    raisedHandTimestamp: number;
    isSearching = false;
    isPauseLocallyAudio = false;
    isPauseLocallyWebcam = false;
    isBreakRoomMic = false;
    groupId = '';
    myGroupId = '';
    statusStreamingSubject: BehaviorSubject<number> = new BehaviorSubject(-1);
    statusStreamingMicSubject: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
    TIMING_NUMBER = 1000;
    intervalStatusStreaming: any;
    self = this;

    constructor(
        peerInfo,
        private socketService: SocketService,
        private consumerService: ConsumerService
    ) {
        this.id = peerInfo.id || '';
        this.displayName = peerInfo.displayName || '';
        this.myGroupId = peerInfo.myGroupId || '';
        this.isRaisedHand = peerInfo.raisedHand;
        this.raisedHandTimestamp = peerInfo.raisedHandTimestamp || 0;
        this.roles = peerInfo.roles || [];
        this.stateVideoStreaming();
    }

    startWebcamConsumer(): void {
        if (!this.isWebcamActivating && (this.isActivatingSpeaker || this.isViewing || this.isSearching)) {
            this.socketService.createNewVideoConsumer(this.id);
            this.isWebcamActivating = true;
        }
    }

    stopWebcamConsumer(): void {
        if (this.webcamConsumerId && this.isWebcamActivating && !this.isActivatingSpeaker && !this.isViewing && !this.isSearching) {
            this.socketService.emitServerCloseConsumer(this.webcamConsumerId);
            this.isWebcamActivating = false;
        }
    }

    startMicConsumer(): void {
        if (!this.isMicActivating) {
            this.socketService.createNewAudioConsumer(this.id);
            this.isMicActivating = true;
        }
    }

    forceStopMicConsumer(): void {
        if ( !this.isMicActivating ){ return; }
        this.socketService.emitServerCloseConsumer(this.micConsumerId);
        this.isMicActivating = false;
    }

    forceStartWebcamConsumer(): void {
        this.socketService.createNewVideoConsumer(this.id);
        this.isWebcamActivating = true;
    }


    forceStopWebcamConsumer(): void {
        if ( !this.isWebcamActivating ){ return; }
        this.socketService.emitServerCloseConsumer(this.webcamConsumerId);
        this.isWebcamActivating = false;
    }

    public startWebcamViewing(): void {
        this.isViewing = true;
        this.startWebcamConsumer();
    }

    public stopWebcamViewing(): void {
        this.isViewing = false;
        this.stopWebcamConsumer();
    }

    public startWebcamSearching(): void {
        this.isSearching = true;
        this.startWebcamConsumer();
    }

    public stopWebcamSearching(): void {
        this.isSearching = false;
        this.stopWebcamConsumer();
    }

    public getCameraTrack(): MediaStream {
        const webcamConsumer = this.consumerService.getConsumer(this.webcamConsumerId);

        if (webcamConsumer && webcamConsumer.track) {
            webcamConsumer.track.enabled = true;
            const webcamStream = new MediaStream();
            webcamStream.addTrack(webcamConsumer.track);
            return webcamStream;
        }
        return null;
    }

    private getConsumerTrack(id: string): any {
        const consumer = this.consumerService.getConsumer(id);

        if (!consumer || !consumer.track) {
            return null;
        }
        return consumer.track;
    }

    public getWebcamTrack(): MediaStreamTrack {
        if (!this.webcamConsumerId) {
            return null;
        }

        return this.getConsumerTrack(this.webcamConsumerId);
    }

    public getScreenTrack(): MediaStreamTrack {
        if (!this.screenConsumerID) {
            return null;
        }

        return this.getConsumerTrack(this.screenConsumerID);
    }

    public setRaisedHand(isRaisedHand: boolean, timestamp: number): void {
        this.isRaisedHand = isRaisedHand;
        this.raisedHandTimestamp = timestamp || 0;
    }

    public setGroupId(groupId: string): void {
        this.myGroupId = groupId;
    }

    public getGroupId(): string {
        return this.myGroupId;
    }

    public getLocallyPause(source: 'webcam' | 'mic' | 'screen'): boolean{
        return source === 'webcam' ? this.isPauseLocallyWebcam : this.isPauseLocallyAudio;
    }

    public setLocallyPause(source: 'webcam' | 'mic' | 'screen', status): void{
        switch (source){
            case 'webcam':
                this.isPauseLocallyWebcam = status;
                break;
            case 'mic':
                this.isPauseLocallyAudio = status;
                break;
            case 'screen':
                break;
        }
    }

    public onStatusStreamingVideo(): Observable<number> {
        return this.statusStreamingSubject.asObservable();
    }

    private setStatusStreamingVideo(numberStatus: number): void {
        this.statusStreamingSubject.next(numberStatus);
    }

    public onStatusStreamingMic(): Observable<number> {
        return this.statusStreamingMicSubject.asObservable();
    }

    private setStatusStreamingMic(numberStatus: number): void {
        this.statusStreamingMicSubject.next(numberStatus);
    }

    public destroyIntervalStatusStreaming(): void {
        if (this.intervalStatusStreaming) { clearInterval(this.intervalStatusStreaming); }
    }

    private stateVideoStreaming(): void {
        const BytesReceived = -1; // init to -1 so reconnect does not get triggered on launch
        if (this.intervalStatusStreaming) {
            return;
        }
        this.intervalStatusStreaming = setInterval(() => {
            // tslint:disable-next-line:triple-equals
            if (!this.webcamConsumerId && this.webcamConsumerId === '') { return; }
            const webcamConsumer = this.consumerService.getConsumer(this.webcamConsumerId);
            if (webcamConsumer) {

                webcamConsumer.track.enabled = true;
                webcamConsumer?.rtpReceiver.getStats().then((data) => {
                    const br = BytesReceived;
                    let videoStatus = false;
                    data.forEach(k => {
                        if (k.type === 'inbound-rtp' && k.kind === 'video') {
                            videoStatus = true;
                            // console.log(" k.bytesReceived", k.bytesReceived)
                            if (br === k.bytesReceived) {
                                this.setStatusStreamingVideo(-1);
                            } else {
                                this.setStatusStreamingVideo(1);
                            }
                        }
                    });
                    if (!videoStatus) { this.setStatusStreamingVideo(-1); }
                });
            }

        }, this.TIMING_NUMBER);
    }

    private stateMicStreaming(): void {
        const BytesReceived = -1; // init to -1 so reconnect does not get triggered on launch
        if (this.intervalStatusStreaming) {
            return;
        }
        this.intervalStatusStreaming = setInterval(() => {
            // tslint:disable-next-line:triple-equals
            if (!this.micConsumerId && this.micConsumerId == '') { return; }
            const micConsumer = this.consumerService.getConsumer(this.micConsumerId);
            if (micConsumer) {

                micConsumer.track.enabled = true;
                micConsumer?.rtpReceiver.getStats().then((data) => {
                    const br = BytesReceived;
                    let micStatus = false;
                    data.forEach(k => {
                        if (k.type === 'inbound-rtp' && k.kind === 'audio') {
                            micStatus = true;

                            if (br === k.bytesReceived) {
                                this.setStatusStreamingMic(-1);
                            } else {
                                this.setStatusStreamingMic(1);
                            }
                        }
                    });
                    if (!micStatus) { this.setStatusStreamingMic(-1); }
                });
            }

        }, this.TIMING_NUMBER);
    }
} */

export class RemoteParticipant extends Participant {
    kind = 'remote';
    audio = {
        state: false, // on: true, false: off
        producerId: '',
        consumerId: '',
        isLocallyPaused: false
    };
    webcam = {
        state: false, // on: true, false: off
        producerId: '',
        consumerId: '',
        isLocallyPaused: false,
        isViewing: false
    };
    screen = {
        state: false, // on: true, false: off
        producerId: '',
        consumerId: ''
    };
    waitingMessage: number = 0;
    myGroupId: string; // only for breakout room
    statusStreamingSubject: BehaviorSubject<number> = new BehaviorSubject(-1);
    statusStreamingMicSubject: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
    remoteSpeaking: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    TIMING_NUMBER = 1000;
    intervalStatusStreaming: any;

    constructor(peerInfo: any,
        private consumer: ConsumerService,
        private mediasoupBaseSerivce: MediasoupBaseService)
    {
        super();
        this.id                  = peerInfo.id || '';
        this.displayName         = peerInfo.displayName || '';
        this.myGroupId           = peerInfo.myGroupId || '';
        this.isRaisedHand        = peerInfo.raisedHand;
        this.raisedHandTimestamp = peerInfo.raisedHandTimestamp || 0;
        this.roles               = peerInfo.roles || [];
        this.joinedTimestamp     = peerInfo.joinedTimestamp;
    }

    public startAudio(): void {
        const { id } = this;
        this.mediasoupBaseSerivce.createNewAudioConsumer(id);
    }

    public stopAudio(): void {
        const { consumerId } = this.audio;
        if (consumerId) {
            this.mediasoupBaseSerivce.emitServerCloseConsumer(consumerId);
        }
    }

    public startWebcam(): void {
        const { id, webcam } = this;
        const { producerId, isViewing } = webcam;
        if (!isViewing && producerId) {
            this.mediasoupBaseSerivce.createNewVideoConsumer(id, producerId);
            this.webcam.isViewing = true;
        }
    }

    public stopWebcam(): void {
        const { consumerId, isViewing } = this.webcam;
        if (isViewing && consumerId) {
            this.mediasoupBaseSerivce.emitServerCloseConsumer(consumerId);
            this.webcam.isViewing = false;
            this.webcam.consumerId = "";
        }
    }

    public getLocallyPaused(kind: 'audio' | 'webcam'): boolean {
        return this[kind].isLocallyPaused;
    }

    public setLocallyPaused(kind: 'audio' | 'webcam', state: boolean): void {
        this[kind].isLocallyPaused = state;
    }

    public setSpeaking(isSpeaking: boolean): void {
        this.isSpeaking = isSpeaking;
        this.setRemoteSpeaking(isSpeaking);
    }

    public setSharing(isSharing: boolean): void {
        this.isSharing = isSharing;
    }

    public setRaisedHand(isRaisedHand: boolean, timestamp: number): void {
        this.isRaisedHand = isRaisedHand;
        this.raisedHandTimestamp = timestamp || 0;
    }

    public setWaitingMessage(waitingMessage: number): void {
        if(waitingMessage == 0) {
            this.waitingMessage = 0;
        }
        else {
            this.waitingMessage++;
        }
    }

    public setGroupId(groupId: string): void {
        this.myGroupId = groupId;
    }

    public getGroupId(): string {
        return this.myGroupId;
    }

    public onStatusStreamingVideo(): Observable<number> {
        return this.statusStreamingSubject.asObservable();
    }

    private setStatusStreamingVideo(numberStatus: number): void {
        this.statusStreamingSubject.next(numberStatus);
    }

    public onRemoteSpeaking(): Observable<boolean> {
        return this.remoteSpeaking.asObservable();
    }

    private setRemoteSpeaking(flag: boolean): void {
        this.remoteSpeaking.next(flag);
    }

    public destroyIntervalStatusStreaming(): void {
        if (this.intervalStatusStreaming) { clearInterval(this.intervalStatusStreaming); }
    }
    

    private stateVideoStreaming(): void {
        const BytesReceived = -1; // init to -1 so reconnect does not get triggered on launch
        if (this.intervalStatusStreaming) {
            return;
        }
        this.intervalStatusStreaming = setInterval(() => {
            // tslint:disable-next-line:triple-equals
            const { consumerId } = this.webcam;
            if (!consumerId && consumerId === '') { return; }
            const webcamConsumer = this.consumer.getConsumer(consumerId);
            if (webcamConsumer) {

                webcamConsumer.track.enabled = true;
                webcamConsumer?.rtpReceiver.getStats().then((data) => {
                    const br = BytesReceived;
                    let videoStatus = false;
                    data.forEach(k => {
                        if (k.type === 'inbound-rtp' && k.kind === 'video') {
                            videoStatus = true;
                            // console.log(" k.bytesReceived", k.bytesReceived)
                            if (br === k.bytesReceived) {
                                this.setStatusStreamingVideo(-1);
                            } else {
                                this.setStatusStreamingVideo(1);
                            }
                        }
                    });
                    if (!videoStatus) { this.setStatusStreamingVideo(-1); }
                });
            }

        }, this.TIMING_NUMBER);
    }
}
export class ActionRemoteParticipant {
    action: FORMAT_ACTION_REMOTE_PARTICIPANT;
    data: any;
}
