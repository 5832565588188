import * as TransPortsAction from './transports.actions';

export type Action = TransPortsAction.All;

const initialState = {};

export const TransportsReducer = (state = initialState, action: Action) =>
{
    switch (action.type)
    {
        case TransPortsAction.ADD_TRANSPORT_STATS:
        {
            const { transport, type } = action.payload;

            return { ...state, [type]: transport[0] };
        }

        default:
            return state;
    }
};
