/* tslint:disable:no-debugger variable-name prefer-const no-console */
import * as mediasoupClient from 'mediasoup-client';
import { config } from '../../../config';
import { Consumer } from 'mediasoup-client/lib/Consumer';
import { JoinResponseModel, ROOM_TYPE } from '../../models/join-response.model';
import { ConsumerModel } from '../../models/consumer.model';
import { RemoteParticipant } from '../../models/remote-participant.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { DeviceCountingService } from '../device-counting.service';
import { UserRoleModel } from '../../models/user-role.model';
import { APPSOURCE, ROLES_CONSTANTS, PERMISSIONS, REPORT_TYPE } from '../../constants';
import { LocalParticipant } from '../../models/local-participant.model';


// @ts-ignore
import { Blur } from '../../tfliteBackgroundBlur';
import { RoomExecution } from '../../_utils/excution/room-excution';

import { VNotificationService } from '@vnpt/oneui-ui/notification';
// import { ListRemoteParticipantService } from '../list-remote-participant.service';
import { LocalParticipantService } from '../local-participant.service';
import { ConsumerService } from '../consumer.service';
import { SettingsService } from '../settings.service';
import { ChattingService } from '../chatting.service';
import { ProducerService } from '../producer.service';
import { ActiveSpeakerService } from '../active-speaker.service';
import { WhiteboardService } from '../whiteboard.service';
import { SecurityService } from '../security.service';
import { RolesManagerService } from '../roles-manager.service';
import { NotificationService } from '../notification-service';
import { ReactionsService } from '../reactions.service';
import { BreakRoomService } from '../breakroom.service';
import { QuizService } from '../quiz.service';
import { SocketService } from '../socket.service';
import { RoomService } from '../mediasoup/room.service';
import { VirtualBackgroundService } from '../virtual-background.service';
import { ShareDataService } from '../share-data.service';
import { PoolService } from './pool.service';
import { MediaStreamService } from './media-stream.service';
import { LobbyService } from '../lobby.service';
import { ListRequestRoleService } from '../list-request-role.service';
import { AudioDeviceService } from '../audio-device.service';
import { CurrentVideoConsumer } from '../../models/consumer.model';
import { RecordService } from '../record.service';
import { LatencyService } from '../latency.service';
import { permissions } from '../../permissions';
import { OneuiI18nService } from '@vnpt/oneui-i18n';
import { SpeakerModel } from '../../models/speaker.model';
import { ParticipantModel } from '../../models/participant.model';
import { SpeakingService } from '../speaking.service';
import { TimeService, timeService } from '../time.service';

type TPeer = 'producer' | 'consumer';

export abstract class MediasoupBaseService {
    protected poolService: PoolService;
    protected mediaStreamService: MediaStreamService;
    protected socketService: SocketService;
    protected roomService: RoomService;
    protected localParticipantService: LocalParticipantService;
    // protected listRemoteParticipantService: ListRemoteParticipantService;
    protected deviceCountingService: DeviceCountingService;
    protected consumerService: ConsumerService;
    protected settingsService: SettingsService;
    protected chattingService: ChattingService;
    protected virtualBackgroundService: VirtualBackgroundService;
    protected producerService: ProducerService;
    protected activeSpeakerService: ActiveSpeakerService;
    protected whiteboardService: WhiteboardService;
    protected latencyService: LatencyService;
    protected securityService: SecurityService;
    protected userRolesService: RolesManagerService;
    protected notification: NotificationService;
    protected reactionsService: ReactionsService;
    protected quizService: QuizService;
    protected shareDataService: ShareDataService;
    protected roomExecution: RoomExecution;
    protected lobbyService: LobbyService;
    protected requestRoleService: ListRequestRoleService;
    protected audioDeviceService: AudioDeviceService;
    protected recordService: RecordService;
    protected i18nService: OneuiI18nService;
    protected speakingService: SpeakingService
    private timeLog: TimeService = timeService;

    constructor(
        poolService: PoolService,
        mediaStreamService: MediaStreamService,
        vNotificationService: VNotificationService,
        socketService: SocketService,
        roomService: RoomService,
        localParticipantService: LocalParticipantService,
        // listRemoteParticipantService: ListRemoteParticipantService,
        deviceCountingService: DeviceCountingService,
        consumerService: ConsumerService,
        settingsService: SettingsService,
        chattingService: ChattingService,
        virtualBackgroundService: VirtualBackgroundService,
        producerService: ProducerService,
        activeSpeakerService: ActiveSpeakerService,
        whiteboardService: WhiteboardService,
        latencyService: LatencyService,
        securityService: SecurityService,
        userRolesService: RolesManagerService,
        notification: NotificationService,
        reactionsService: ReactionsService,
        quizService: QuizService,
        shareDataService: ShareDataService,
        lobbyService: LobbyService,
        requestRoleService: ListRequestRoleService,
        audioDeviceService: AudioDeviceService,
        recordService: RecordService,
        i18nService: OneuiI18nService,
        speakingService: SpeakingService,

        // roomExecution: RoomExcution
        // vNotificationService: VNotificationService
    ) {
        this.poolService = poolService;
        this.mediaStreamService = mediaStreamService;
        this.socketService = socketService;
        this.roomService = roomService;
        this.localParticipantService = localParticipantService;
        // this.listRemoteParticipantService = listRemoteParticipantService;
        this.deviceCountingService = deviceCountingService;
        this.consumerService = consumerService;
        this.settingsService = settingsService;
        this.chattingService = chattingService;
        this.virtualBackgroundService = virtualBackgroundService;
        this.producerService = producerService;
        this.activeSpeakerService = activeSpeakerService;
        this.whiteboardService = whiteboardService;
        this.latencyService = latencyService;
        this.securityService = securityService;
        this.userRolesService = userRolesService;
        this.notification = notification;
        this.reactionsService = reactionsService;
        this.quizService = quizService;
        this.shareDataService = shareDataService;
        this.lobbyService = lobbyService;
        this.requestRoleService = requestRoleService;
        this.audioDeviceService = audioDeviceService;
        this.recordService = recordService;
        this.localParticipantService.setLocalParticipant(new LocalParticipant());
        this.i18nService = i18nService;
        this.speakingService = speakingService;
        this._screenSharingProducer = null;
        this._screenSharingAudioProducer = null;
        this._screenSharing = null;
        this._signalingSocket = null;
        this._useSharingSimulcast = config.simulcastSharing;
        this._harkStream = null;
        this._hark = null;
        this.roomService.listenRestartIce((transport, ice, delay) => this.restartIce(transport, ice, delay));
    }

    _screenSharingProducer: any;
    _screenSharingAudioProducer: any;
    _screenSharing: any;
    _signalingSocket: any;
    _device: any;
    _useSharingSimulcast: any;
    _harkStream: any;
    _hark: any;


    private _timeoutActiveSpeaker: Map<string, number> = new Map<string, number>();

    private closed = false;

    private isJoinedSubject = new BehaviorSubject<boolean>(false);

    private roomPermissionSubject = new BehaviorSubject<Map<string, UserRoleModel>>(null);

    private roomTypeSubject = new BehaviorSubject<ROOM_TYPE>('NORMAL');

    private roomIdSubject = new BehaviorSubject<string>(null);

    private updateVideoSubject = new BehaviorSubject<string>(null);

    private isAcceptPresenter = new BehaviorSubject<boolean>(false);
    private isRequestPresenter: BehaviorSubject<Object> = new BehaviorSubject<Object>(null);
    
    private updateLocalVideoScoreSubject = new BehaviorSubject<{ producerId: string, score: number }>(null);
    private updateRemoteVideoScoreSubject = new BehaviorSubject<{ consumerId: string, score: number }>(null);

    protected isNewPeerJoined = false;

    // private virtualBackground = null;

    private blur: Blur;

    private isDisableAudio = false;
    private isDisableVideo = false;
    private self = this;

    private _consumers: Map<string, Consumer> = new Map<string, Consumer>();

    public abstract executeJoin(joinData: JoinResponseModel): void;

    // public abstract executeNewPeer(peerInfo: object): void;

    public abstract executeNewVideoProducer(peerId: string): Promise<void>;

    public abstract executeBreakRoomNotificationEvent({ groupMap, teacherId }): Promise<void>;

    public abstract executeStopBreakRoom(): Promise<void>;

    public abstract executePeerClosed(peerId: string): void;

    public abstract executeChangeTeacherSpeakingInGroup(teacherSpeakingInGroup: string): void;

    public setAcceptPresenter(connected: boolean){
        this.isAcceptPresenter.next(connected);
    }

    public onAcceptPresenter():Observable<boolean>{
        return this.isAcceptPresenter.asObservable();
    }

    public setRequestPresenter(obj: {isRequestPresenter: boolean, peerId: string, displayName: string}): void {
        this.isRequestPresenter.next(obj);
    }

    public onRequestPresenter(): Observable<Object> {
        return this.isRequestPresenter.asObservable();
    }

    /**
     * Pause your audio stream
     */
    onSocket(): void {
        this.socketService.onSocket('connect', async () => {
            console.log("socket is established");
        });

        this.socketService.onSocket('disconnect', (reason) => {
            if (this.closed) { return; }

            if (reason === 'io server disconnect') {
                // this.roomService.closeRoom(this.i18nService.translate("service.mediasoup-base.outMeeting", {}, "Bạn đã rời khỏi cuộc họp"));
            }

            // notify: You are disconnected, attempting to reconnect
            // this.roomService.resetRoomTransports();
        });


        this.socketService.onSocket('notification', async (notification: { data: any, method: string }) => {
            switch (notification.method) {
                case 'newConsumer': {
                    const {
                        peerId,
                        producerId,
                        id,
                        kind,
                        rtpParameters,
                        type,
                        appData,
                    } = notification.data;
                try {
                    // if (producerPaused) console.warn("newConsumer", {producerId, consumerId: id, kind, type, producerPaused});
                    if(!this.poolService.getPeer(peerId)) return;
                    // this.sendListConsumer('consumeBefore');

                    if (this.roomService.consumerTransport == null) {
                        this.roomService.sendReport(kind as REPORT_TYPE,'', 'newConsumer' + "transport is null");
                        return;
                    }
                    let exists: Consumer = Array.from(this._consumers.values()).find((c: Consumer) => {
                        return (c.producerId == producerId);
                    });

                    if (exists) {
                        const consumerData = this.consumerService.getConsumer(exists.id);
                        this.consumerService.setConsumer(exists.id, { ...consumerData, remotelyPaused: false });
                        const updateKind = consumerData.source === 'mic' ? 'audio' : consumerData.source;
                        if (updateKind !== 'screen') {
                            this.poolService.setLocallyPaused(consumerData.peerId, updateKind, false);
                        }
                        return;
                    }
                    // kiểm tra phải luồng âm thanh hay không và có luồng âm thanh đã chạy trước đó hay không 
                    if( kind == 'audio' && this.roomService.delayAudioScreenIds.length > 0 ) {
                        this.roomService.delayAudioScreenIds.push(id);
                        // console.time(">>bd");
                        // Tạo sự kiện chờ tuần tự bằng async-await và timeout
                        let sequenceTimeAudio =  async (beforeId, times) => {
                            //Kiểm tra điều kiện trong vòng lặp while
                            while(this.roomService.delayAudioScreenIds.includes(beforeId)) {
                                // console.timeLog(">>bd", times++);
                                //Đặt await timeout 0,5s để mỗi khảong đó sẽ quay lại vòng lặp tránh chạy liên tục quá nhiều
                                await new Promise((resolve, reject) => {
                                       setTimeout(resolve, 500);
                                    });
                                } 
                            // console.timeLog(">>bd", "outif;", times,"--", beforeId);
                            return;
                          };
                            await sequenceTimeAudio(this.roomService.delayAudioScreenIds[this.roomService.delayAudioScreenIds.indexOf(id)-1], 1);
                    }

                    //Kiểm tra xem luồng consumer âm thanh hay ko để đẩy vào danh sách chờ
                    if(kind == 'audio' && this.roomService.delayAudioScreenIds.length == 0) {
                        this.roomService.delayAudioScreenIds.push(id);
                    }

                    const consumer: Consumer = await this.roomService.consumerTransport.consume(
                        {
                            id,
                            producerId,
                            kind,
                            rtpParameters,
                            appData: { ...appData, peerId } // Trick.
                        });
                    //kiểm tra xem phải là consumer audio không để update vào latency
                    // if(kind == "audio") {this.roomService.setAudioConsumerTransport(this.roomService.consumerTransport)};
                    this._consumers.set(consumer.id, consumer);
                    
                    consumer.on('transportclose', () => { this._consumers.delete(consumer.id); });
                    const { spatialLayers, temporalLayers } =
                        mediasoupClient.parseScalabilityMode(consumer.rtpParameters.encodings[0].scalabilityMode);

                    const consumerData: ConsumerModel = {
                        id: consumer.id,
                        peerId,
                        kind,
                        type,
                        remotelyPaused: false, // producerPaused,
                        source: consumer.appData.source as any,
                        width: consumer.appData.width,
                        height: consumer.appData.height,
                        resolutionScalings: consumer.appData.resolutionScalings,
                        spatialLayers,
                        temporalLayers,
                        preferredSpatialLayer: 0,
                        preferredTemporalLayer: 0,
                        track: consumer.track,
                        rtpReceiver: consumer.rtpReceiver,
                    };
                    let remote;
                    let updateKind = appData.source === 'mic' ? 'audio' : appData.source;

                    if(kind == 'audio' && updateKind === "screen") updateKind = 'audioscreen';
                       
                    if (updateKind !== 'audioscreen') {
                        this.poolService.updateRemoteProducer(peerId, producerId, updateKind);
                        remote = this.poolService.updateRemoteConsumer(peerId, consumer.id, updateKind);
                    }
                    this.consumerService.addConsumer(consumerData.id, updateKind, consumerData);
                    this.mediaStreamService.addStream(peerId, updateKind, consumer.track);
                    // if (producerPaused) this.pauseConsumer(consumer);
                    // else {
                        await this.startConsumer(consumer);
                    // }

                    
                    // Tạo luồng speechEvents HarkStream cho audio
                    if(this.settingsService.checkUseConfigHarkJs() && kind === "audio" && updateKind === "audio") {
                        await this.roomService.connectRemoteHark(peerId);
                    }

                    if (appData.source === 'webcam') {
                        this.updateRemoteActiveSpeaker(remote);
                        this.poolService.addWebcamStartedToPool(peerId);
                        this.poolService.updateListRemoteParticipantInfo();//cap nhat lai thong tin user
                    }

                    if (appData.source === 'screen' && kind == 'video') {
                        if(this.settingsService.getShareScreenStatusValue()) {
                            this.roomService.disableScreenSharing(true);
                            
                            this.notification.info(this.i18nService.translate("service.mediasoup-base.kickShare", 
                            { name: this.poolService.getPeer(peerId).displayName.toString() },''), '');
                        }
                        // if(kind == 'audio') {
                        //     // const updateKind = appData.source === 'mic' ? 'audio' : appData.source;
                        //     this.listRemoteParticipantService.updateRemoteProducer(peerId, producerId, kind);
                        //     const remote = this.listRemoteParticipantService.updateRemoteConsumer(peerId, consumer.id, kind);
                        //     this.consumerService.addConsumer(consumerData.id, kind, consumerData);
                        //     this.mediaStreamService.addStream(peerId, kind, consumer.track);
                        //     await this.startConsumer(consumer);
                        //     break;
                        // }
                        // HIDDEN FEATURE: (BREAKOUT ROOM)
                        // Prevent change layout to side-by-side
                        //  ----------------------------- 
                        // const layoutMode = this.settingsService.getLayoutMode();
                        // if (layoutMode && layoutMode !== 'breakroom') {
                        //     this.settingsService.changeLayoutMode('sidebyside');
                        // }
                        if(this.roomService.getForceLayout.layout == 0) this.settingsService.changeLayoutMode('sidebyside');
                        const remoteParticipant = this.poolService.getPeer(peerId);
                        this.activeSpeakerService.setActiveSpeaker(remoteParticipant);
                        this.poolService.setIsRemoteSharing(peerId, true);
                        this.poolService.updateListRemoteParticipantInfo();
                        this.poolService.resetCurrentPage();
                        //an thong bao co nguoi sharescreen
                        // this.notification.info(this.i18nService.translate("service.mediasoup-base.isShareScreen", { displayName: remoteParticipant.displayName }, `${remoteParticipant.displayName} đã chia sẻ màn hình`), '');
                    }

                    // HIDDEN FEATURE: (BREAKOUT ROOM)
                    // this.shareDataService.setCallbackAfterNewConsumer(consumer);
                    // this.sendListConsumer('consumeAfter');
                   
                } catch (error) {
                    console.log(error);
                    
                } finally {
                    if(kind == 'audio') {
                        //Xóa đi giá trị đầu trong danh sách audio khi chạy xong
                        let shiftTimeout = setTimeout(() => {
                            this.roomService.delayAudioScreenIds.shift();
                            clearTimeout(shiftTimeout);
                        }, 250);
                    }
                }
                    break;
                }

                case 'consumerClosed': {
                    const { consumerId, kind, source, producerPeerId } = notification.data;
                    const consumerData = this.consumerService.getConsumer(consumerId);
                    
                    if (!consumerData) { break; }
                    let updateKind : any = source === 'mic' ? 'audio' : source;
                    
                    if(kind == 'audio' && updateKind === "screen") updateKind = 'audioscreen';
                    
                    if(updateKind !== 'audioscreen') {
                        if (!this.poolService.hasWebcamStartedPoolPeerId(producerPeerId)) {
                            this.poolService.updateRemoteProducer(producerPeerId, '', updateKind);
                        }
                        this.poolService.updateRemoteConsumer(producerPeerId, '', updateKind);
                    }
                    this.consumerService.deleteConsumer(consumerId);
                
                    if (source === 'mic') {
                        this.speakingService.removeFromSpeakingList(producerPeerId);
                        this.poolService.updateRemoteProducer(producerPeerId, '', updateKind);
                        this.mediaStreamService.removeStream(producerPeerId, "audio");

                        // Đóng luồng speechEvents HarkStream
                        if(this.settingsService.checkUseConfigHarkJs())  {
                            this.roomService.stopListeningToSpeechRemoteHarkEvents(producerPeerId);
                        }
                    }
                    
                    const remote = this.poolService.getPeer(producerPeerId);
                    if (remote && source === 'webcam') {
                        remote.webcam.state = false;
                        this.mediaStreamService.removeStream(producerPeerId, "webcam");
                        this.updatePeerCloseActiveSpeaker(producerPeerId); //update logic chuyen active speaker giong sharescreen
                        this.poolService.deleteWebcamStartedFromPool(producerPeerId);
                        this.poolService.updateListRemoteParticipantInfo(); //cap nhat lai thong tin user
                    }

                    if(source === 'screen' && kind === 'audio') {
                        this.mediaStreamService.removeStream(producerPeerId, "audioscreen");
                    }

                    if (remote && source === 'screen' && kind === 'video') {
                        this.poolService.setIsRemoteSharing(producerPeerId, false);
                        remote.screen.state = false;

                        //Update lai logic view activeSpeaker khi user tat share screen
                        this.updatePeerCloseActiveSpeaker(producerPeerId);

                        this.mediaStreamService.removeStream(producerPeerId, "screen");
                        this.poolService.updateListRemoteParticipantInfo();
                        this.poolService.resetCurrentPage();
                        //An thong bao tat sharescreen
                        // this.notification.info(this.i18nService.translate("service.mediasoup-base.turnOffScreenSharing", { displayName: remote.displayName }, `${remote.displayName} đã tắt chia sẻ màn hình`), '');
                    }
                    this.closeConsumer(consumerId); //Đóng consumer bị động
                    break;
                }
                // case 'consumerScreenClosed': {
                //     const { peerId } = notification.data;
                //     let remote = this.listRemoteParticipantService.getRemoteParticipant(peerId);
                //     remote.screen.state = false;
                //     this.consumerService.deleteConsumer(remote.screen.consumerId);
                // }

                case 'consumerPaused': {
                    /* const { consumerId } = notification.data;
                    const consumerData = this.consumerService.getConsumer(consumerId);
                    if (!consumerData) { break; }

                    this.consumerService.setConsumer(consumerId, { ...consumerData, remotelyPaused: true });

                    const updateKind = consumerData.source === 'mic' ? 'audio' : consumerData.source;
                    if (updateKind !== 'screen') {
                        this.poolService.setLocallyPaused(consumerData.peerId, updateKind, true);
                    } */
                    // console.warn("not handle event 'consumerPaused'");
                    break;
                }

                case 'consumerResumed': {
                     const { source } = notification.data;
                     if(source == "screen")
                     this.activeSpeakerService.setResumeScreen(true);
                    break;
                }

                case 'newPeer': {
                    const peerInfo: {
                        id: string,
                        displayName: string,
                        myGroupId: string,
                        raisedHand: boolean,
                        raisedHandTimestamp: number,
                        roles: number[],
                        joinedTimestamp: number
                    } = notification.data;

                    const plitName = peerInfo.id.split('_');
                    const isRecordUser = plitName[plitName.length - 1];
                    if (isRecordUser == 'recordHiddenUser') {
                        return;
                    }

                    const remote = new RemoteParticipant(peerInfo, this.consumerService, this);
                    remote.color = this.generateRandomColor(0x44FFFF);

                    this.poolService.addPeer(remote, true);
                    this.chattingService.updateStatusMapMessages(peerInfo.id, true);
                    // HIDDEN FEATURE: (BREAKOUT ROOM)
                    // this.shareDataService.setCallbackAfterNewPeer(peerInfo);
                    if(this.localParticipantService.getRolesSubject().includes(PERMISSIONS.MODERATOR.id)){
                        this.notification.info(this.i18nService.translate("service.mediasoup-base.joinedRoom", { displayName: peerInfo.displayName }, `${peerInfo.displayName} đã tham gia phòng`), '');
                    }
                    
                    // this.activeSpeakerService.setNewPeerJoinSubject(true);

                    break;
                }

                case 'newVideoProducer': {
                    const { peerId, producerId, appDataSource } = notification.data;
                    this.poolService.updateRemoteProducer(peerId, producerId, appDataSource);
                    if (appDataSource === 'screen') {
                        await this.createNewVideoConsumer(peerId, producerId);
                        break;
                    }
                    this.poolService.addVideoConsumerMap(peerId, { isHaveWebcam: producerId })
                    const canVideoStarted = this.poolService.isPeerInCurrentPage(peerId); //isCurrentPageContainRemote(peerId);
                    if (!canVideoStarted) { break; }
                    await this.createNewVideoConsumer(peerId, producerId);
                    this.poolService.addWebcamStartedToPool(peerId);
                    break;
                }

                case 'peerClosed': {
                    const { peerId } = notification.data;
                    const remote = this.poolService.getPeer(peerId);
                    if (!remote) { break; }               

                    this.poolService.deleteElementVideoConsumerMap(peerId);
                    this.poolService.deleteWebcamStartedFromPool(peerId);
                    //Delete Peer before update view activeSpeaker
                    this.poolService.deletePeer(peerId);
                    if (this.roomService.getForceLayout.layout == 0) {
                        this.poolService.removePin(peerId);
                    }
                    this.updatePeerCloseActiveSpeaker(peerId);

                    this.shareDataService.initJoinData();
                    if(this.localParticipantService.getRolesSubject().includes(PERMISSIONS.MODERATOR.id)){
                        this.notification.info(this.i18nService.translate("service.mediasoup-base.leftRoom", { displayName: remote?.displayName }, `${remote?.displayName} đã rời khỏi phòng`), '');
                    }
                    

                    this.executePeerClosed(peerId);
                    this.chattingService.updateStatusMapMessages(peerId, false);
                    if (this.userRolesService.getRemoteParticipant()) {
                        if (this.userRolesService.getRemoteParticipant().id == peerId) this.userRolesService.setRemoteParticipant(null);
                    }
                    // HIDDEN FEATURE: (BREAKOUT ROOM)
                    // this.shareDataService.setCallbackAfterPeerClosed(peerId);

                    break;
                }

                case 'producerClosed': {
                    const { peerId, producerId, appData } = notification.data;
                    const kind = appData.source == 'mic' ? 'audio' : appData.source;
                    this.poolService.updateRemoteProducer(peerId, '', kind);
                    if (kind == 'webcam') this.poolService.deleteElementVideoConsumerMap(peerId);
                }

                case 'consumerLayersChanged': {
                    const { consumerId, spatialLayer, temporalLayer } = notification.data;
                    const consumerService = this.consumerService;
                    const consumer = consumerService.getConsumer(consumerId);

                    if (!consumer) { break; }

                    const newConsumer = {
                        ...consumer,
                        currentSpatialLayer: spatialLayer,
                        currentTemporalLayer: temporalLayer
                    };

                    consumerService.setConsumer(consumerId, newConsumer);
                    this.updateVideo(consumerId);
                    break;
                }

                case 'producerScore': {
                    const { producerId, score } = notification.data;
                    const videoScreen  = this.producerService.getProducer(producerId);
                    
                    //check sharescreen score to mute/unmute screenshare by minimize actions
                    if(videoScreen && videoScreen.source == "screen" && videoScreen.track.kind == "video") {
                        if(score[0].score == 0) {
                            this.roomService.pauseScreenSharing();
                        }
                        else {
                            this.roomService.resumeScreenSharing();
                        }
                        if(score[0].score == 0) {
                            this.roomService.pauseScreenSharing();
                        }
                        else {
                            this.roomService.resumeScreenSharing();
                        }
                    }
                        
                    // TODO: save current score to consumer model
                    this.updateLocalVideoScore(producerId, score[0].score);
                    break;
                }

                case 'consumerScore': {
                    const { consumerId, score } = notification.data;
                    // TODO: save current score to consumer model
                    this.updateRemoteVideoScore(consumerId, score.score);
                    break;
                }

                case 'numberWebcamChanged': {
                    const { numberOfTurningOnWebcams } = notification.data;
                    this.roomService.setNumberOfTunringOnWebcam(numberOfTurningOnWebcams);
                    break;
                }

                case 'roomNameChanged': {
                    const { roomId, roomName } = notification.data;
                    this.settingsService.setRoomName(roomName);
                    this.notification.success(this.i18nService.translate("room-config.notiChangeName",{},''), this.i18nService.translate("room-config.changeRoomName", { roomName: roomName }, `Bạn đã đổi tên phòng thành ${roomName}`));
                    break;
                }

                case 'changeDisplayName': {
                    const { peerId, displayName, oldDisplayName } = notification.data;
                    
                    if (this.localParticipantService.getLocalParticipant().id == peerId) {
                        this.localParticipantService.setDisplayName(displayName)
                    } else {
                        this.poolService.setDisplayNameChange(peerId, displayName);
                    }
                    this.chattingService.updateConversation(peerId);

                    this.chattingService.updateActiveChat([peerId]); //cap nhat lai thong tin map message cua user

                   // this.chattingService.initActiveChat([peerId]); // cap nhat lai thong tin map message cua user
                    this.poolService.updateSearch(); //update lai listParticipant de update tinh nang search user
                    
                    //update lai displayName o speaker
                    if(this.localParticipantService.getPeerId() == peerId){
                        this.speakingService.updateSpeakerList(this.localParticipantService.getLocalParticipant());
                    }else{
                        this.speakingService.updateSpeakerList(this.poolService.getPeer(peerId));
                    }
                    
                    if (this.localParticipantService.getPeerId() != peerId && this.localParticipantService.getRolesSubject().includes(PERMISSIONS.MODERATOR.id)) {
                        this.notification.info(this.i18nService.translate("service.mediasoup-base.notiChangeName",{},'') , this.i18nService.translate("service.mediasoup-base.changeDisplayName", { oldDisplayName: oldDisplayName, displayName: displayName }, `${oldDisplayName} đã đổi tên thành ${displayName}`));
                    } else if(this.localParticipantService.getPeerId() == peerId) {
                        this.notification.success(this.i18nService.translate("service.mediasoup-base.notiChangeName",{},''), this.i18nService.translate("service.mediasoup-base.youChangeDisplayName", { displayName: displayName }, `Bạn đã đổi tên thành ${displayName}`));
                    }
                    break;
                }

                case 'raisedHand': {
                    const { peerId, raisedHand, raisedHandTimestamp } = notification.data;
                    this.poolService.setRemoteRaisedHand(peerId, raisedHand, raisedHandTimestamp);
                    this.reactionsService.setRemoteRaisedHand(peerId, raisedHand, raisedHandTimestamp);
                    break;
                }

                case 'videoConsumersChanged': {
                    const { numberOfTurningOnWebcams, totalVideo, videoConsumerMap } = notification.data;
                    this.roomService.setNumberOfTunringOnWebcam(numberOfTurningOnWebcams);
                    break;
                }

                case 'activeSpeaker': {
                    // Check config "activeSpeaker": !"harkJs"
                    if(!this.settingsService.checkUseConfigHarkJs())  {
                        const { peerId, volume } = notification.data;
                        
                        const activity = this.activeSpeakerService.getActiveSpeaker();
                        const spotlight = this.activeSpeakerService.getSpotlight();
                        if (this.localParticipantService.getPeerId() === peerId && this.localParticipantService.getLocalParticipant().audio.state ) {
                            const localPeer = this.localParticipantService.getLocalParticipant();
                            this.speakingService.pushToSpeakingList(localPeer);
                            if (!activity.screen.state && activity.id != localPeer.id && !spotlight) {
                                this.activeSpeakerService.setActiveSpeaker(localPeer);
                            }
                        } else {
                            let peer = this.poolService.getPeer(peerId);
                            if (peer) { 
                                if(peer.audio.state){
                                    this.speakingService.pushToSpeakingList(peer);
                                    if (!activity.screen.state && activity.id != peer.id && !spotlight) {
                                        this.activeSpeakerService.setActiveSpeaker(peer);
                                    }
                                }
                            }
                        }
                    }
                    break;
                }

                case 'moderator:mute': {
                    if (!notification.data?.hasOwnProperty('peerId')) {
                        await this.roomService.muteMic(true);
                        break;
                    }

                    const { peerId } = notification.data;

                    if (peerId === this.localParticipantService.getPeerId()) {
                        await this.roomService.muteMic(true);
                    }

                    break;
                }

                case 'moderator:stopVideo': {
                    if (!notification.data?.hasOwnProperty('peerId')) {
                        await this.roomService.disableWebcam(true);
                        break;
                    }

                    const { peerId } = notification.data;

                    if (peerId === this.localParticipantService.getPeerId()) {
                        await this.roomService.disableWebcam(true);
                    }

                    break;
                }
                case 'moderator:muteAllExceptPresenter': {
                    // not sharing screen => mute mic
                    var localParticipant = this.localParticipantService.getLocalParticipant();
                    if (!localParticipant.screen.state) {
                        await this.roomService.muteMic(true);
                    }

                    break;
                }
                case 'moderator:blockAllVideo': {
                    const { lockSettingsDisableCam, roleId } = notification.data;
                    this.roomService.setRoomConfig("lockSettingsDisableCam", lockSettingsDisableCam);
                    if (!this.roomService.hasPermission(permissions.MODERATE_ROOM)) {
                        if (lockSettingsDisableCam) {
                            this.localParticipantService.removeRole(roleId);
                            this.roomService.blockWebcam();
                        } else {
                            this.localParticipantService.addRole(roleId);
                            this.settingsService.setVideoBlocked(lockSettingsDisableCam);
                        }
                    }
                    //  else {
                        this.settingsService.setVideoUnblocked(!lockSettingsDisableCam);
                        const listRemote = this.poolService.getAllPeers();//getListRemoteParticipantInfo();
                        listRemote.forEach(remote => {
                            if (!remote.roles.includes(PERMISSIONS.MODERATOR.id)) {
                                // if (!remote.roles.includes(PERMISSIONS.ADMIN.id) && !remote.roles.includes(PERMISSIONS.MODERATOR.id)) {
                                lockSettingsDisableCam
                                    ? this.poolService.removeRole(remote.id, roleId)
                                    : this.poolService.addRole(remote.id, roleId);
                            } 
                            // else {
                            //     if (!lockSettingsDisableCam) this.poolService.addRole(remote.id, roleId);
                            // }
                        });
                        // if (!lockSettingsDisableCam) this.localParticipantService.addRole(roleId);
                    // }
                    break;
                }
                // disable/enable block audio for all user
                case 'moderator:blockAllAudio': {
                    const { lockSettingsDisableMic, roleId } = notification.data;
                    this.roomService.setRoomConfig("lockSettingsDisableMic", lockSettingsDisableMic);
                    if (!this.roomService.hasPermission(permissions.MODERATE_ROOM)) {
                        if (lockSettingsDisableMic) {
                            this.localParticipantService.removeRole(roleId);
                            this.roomService.blockMic();
                        } else {
                            this.localParticipantService.addRole(roleId);
                            this.settingsService.setAudioBlocked(lockSettingsDisableMic);
                        }
                    }
                    //  else {
                        this.settingsService.setAudioUnblocked(!lockSettingsDisableMic);
                        const listRemote = this.poolService.getAllPeers();//getListRemoteParticipantInfo();
                        listRemote.forEach(remote => {
                            if (!remote.roles.includes(PERMISSIONS.ADMIN.id) && !remote.roles.includes(PERMISSIONS.MODERATOR.id)) {
                                lockSettingsDisableMic
                                    ? this.poolService.removeRole(remote.id, roleId)
                                    : this.poolService.addRole(remote.id, roleId);
                            }
                        });
                    // }
                    break;
                }

                case 'moderator:stopScreenSharing': {
                    // if (!notification.data?.hasOwnProperty('peerId')) {
                    //     await this.roomService.disableScreenSharing();
                    //     break;
                    // }

                    const { byId } = notification.data;
                    
                    if(this.localParticipantService.getLocalParticipant().isSharing) {
                        await this.roomService.disableScreenSharing(true);
                        if(byId) {
                            const remote = this.poolService.getPeer(byId);
                            if(remote){
                                this.notification.info(this.i18nService.translate("service.mediasoup-base.kickShare", 
                                { name: remote.displayName },''), '');
                            }
                        }
                    }
                    
                    // if (peerId === this.localParticipantService.getPeerId()) {
                    //     await this.roomService.disableScreenSharing();
                    //     // Notify: Moderator stopped your screen sharing
                    // }

                    break;
                }
                case 'moderator:webcamsOnlyForModerator':{
                    const { enable } = notification.data;
                    let webcamsForModeratorStatus =  this.settingsService.getIsWebcamsOnlyForModerator();
                    if(webcamsForModeratorStatus != enable){
                        if (enable && !this.roomService.hasPermission(permissions.MODERATE_ROOM)) {
                            this.poolService.addFilter(PoolService.filterPresenterOnly);
                        }
                        else {
                            this.poolService.removeFilter(PoolService.filterPresenterOnly);
                        }
                        this.poolService.resetCurrentPage();
                        this.settingsService.setWebcamsOnlyForModerator(enable);

                        //Xử lý bỏ pin (TH ko force layout) khi bật webcamsOnlyForModerator
                        if(enable && this.roomService.getForceLayout.layout == 0){
                            const listUserIsPin = this.poolService.getAllPeers().filter(remote => !!(remote.isPin))
                            listUserIsPin?.forEach(remote => {
                                this.poolService.setIsPinNormal(remote.id, !remote.isPin );
                            });
                        }
                    }  

                    // this.settingsService.setWebcamsOnlyForModerator(enable);
                    break;
                }

                case 'meeting:end': {
                    this.roomService.closeRoom(this.i18nService.translate("service.mediasoup-base.meetingEnd", {}, "Cuộc họp đã kết thúc"));
                    break;
                }

                case 'moderator:kick': {
                    this.roomService.closeRoom(this.i18nService.translate("service.mediasoup-base.moderatorKick", {}, "Bạn đã bị xóa khỏi phòng"));
                    break;
                }

                case 'gotRole': {

                    const { peerId, roleId } = notification.data;

                    const _peerId = this.localParticipantService.getPeerId();

                    if (peerId === _peerId) {
                        this.updateLocalParticipantRole(roleId, true);
                    } else {
                        this.updateRemotedParticipantRole(peerId, roleId,true);
                        this.setAcceptPresenter(true);
                    }

                    break;
                }

                case 'lostRole': {
                    const { peerId, roleId } = notification.data;

                    const _peerId = this.localParticipantService.getPeerId();

                    if (peerId === _peerId) {
                        this.updateLocalParticipantRole(roleId, false);
                        this.setRequestPresenter({isRequestPresenter: false, peerId: peerId, displayName: ""});
                        
                    } else {
                        this.updateRemotedParticipantRole(peerId, roleId,false);
                    }
                    break;
                }

                case 'requestRole': {
                    let listRequest = notification.data;
                    this.requestRoleService.addRequestRoleList(listRequest);
                    break;
                }

                case 'moderator:refuseRole': {

                    const { peerId, roleId } = notification.data;

                    const _peerId = this.localParticipantService.getPeerId();

                    if (peerId === _peerId) {
                        this.setRequestPresenter({isRequestPresenter: false, peerId: "", displayName: ""});
                        
                        this.notification.info(this.i18nService.translate("service.mediasoup-base.request.refuse_ShareScreen"), '');
                    } else {
                        // this.setRequestPresenter({isRequestPresenter: true, peerId: peerId, displayName: displayName});  
                    }
                    break;
                }

                case 'lockRoom': {
                    const { isLock } = notification.data;
                    this.securityService.setLockRoom(true);

                    // const { allowed, roleId } = true, 9583;
      
                    break;
                }

                case 'unlockRoom': {
                    const { isLock } = notification.data;
                    this.securityService.setLockRoom(false);
                    break;
                }

                case 'parkedPeers': {
                    const t = notification.data;
                    break;
                }

                case 'moderator:record': {
                    const { isRecording, recordDuration, recordError } = notification.data;
                    
                    if(!recordError){
                        this.recordService.setTime(recordDuration);
                        this.recordService.setIsRecording(isRecording);
                        this.recordService.setIsInitial(false);
                    }else{
                        this.recordService.setRecordError(true);
                    }
                    break;
                }

                case 'moderator:recordInitializing': {
                    this.recordService.setIsInitial(true);
                    break;
                }
                //moderator:grantAllPeerScreenSharing
                //Cap/thu quyen chia se man hinh cho tat ca user
                case 'moderator:grantAllPeerPermission': {
                    const { allowed, roleId } = notification.data;
                    
                    if (!this.localParticipantService.getRolesSubject().includes(PERMISSIONS.MODERATOR.id)) {
                        this.updateLocalParticipantRole(roleId, !!(allowed));
                        // if(this.localParticipantService.getLocalParticipant().isSharing && !allowed){
                        //     await this.roomService.disableScreenSharing();
                        // }
                    }
                    const listParticipant = this.poolService.getAllPeers();
                    if (listParticipant) {
                        listParticipant.forEach(remote => {
                            if(!remote.roles.includes(PERMISSIONS.MODERATOR.id)){
                                this.updateRemotedParticipantRole(remote.id, roleId, !!(allowed));
                            }
                        });
                    }
                    switch (roleId) {
                        case PERMISSIONS.PRESENTER.id:
                            this.settingsService.setIsGrantcreenSharingPermission(allowed);
                            if(!allowed){
                                this.setRequestPresenter({isRequestPresenter: false, peerId: "", displayName: ""});
                            }
                            break;
                        case PERMISSIONS.WHITEBOARD.id:
                            this.roomService.setLockAllWhiteboard(!allowed);
                            break;
                        default: break;
                    }
                    break;
                }

                // HIDDEN FEATURE: (QUIZ)
                // Quiz notification
                //  ----------------------------- 
                // case 'startQuiz': {
                //     // Check if teacher or not
                //     if (this.hasRole('admin')) { break; }

                //     // Handle start quiz
                //     const { quizId } = notification.data;

                //     this.settingsService.changeLayoutMode('quiz');
                //     this.quizService.setExamining({quizId, quizStatus: true});
                //     break;
                // }

                // case 'stopQuiz': {
                //     // Check if teacher or not
                //     if (this.hasRole('admin')) { break; }

                //     // Handle start quiz
                //     this.notification.info('Đã hết giờ làm bài', '');
                //     this.quizService.setIsFinish(true);
                //     break;
                // }

            }
        });
    }

    //Bo sung cap nhat role(gotRole/lostRole) cho user local
    public updateLocalParticipantRole(roleId: number, gotRole : boolean){
        let roleInfo = this.userRolesService.getUserRoles().get(roleId);
        if(gotRole) {
            this.localParticipantService.addRole(roleId);
            if(roleInfo.label === 'share audio') {
                this.roomService.unBlockMic(true);
            }
            if(roleInfo.label === 'share video') {
                this.roomService.unBlockWebcam(true);
            }
        }
        else {
            this.localParticipantService.removeRole(roleId);
            if(roleInfo.label === 'share audio') {
                this.roomService.blockMic();
            }
            if(roleInfo.label === 'share video') {
                this.roomService.blockWebcam();
            }
        }
        //
        // gotRole ? this.localParticipantService.addRole(roleId) 
        //             : this.localParticipantService.removeRole(roleId);

        
       
        // gotRole ? (roleInfo.label === 'share audio' ? this.roomService.unBlockMic(true) : null ) 
        //             : (roleInfo.label === 'share audio' ? this.roomService.blockMic() : null);

        // gotRole ? (roleInfo.label === 'share video' ? this.roomService.unBlockWebcam(true) : null) 
        //             : (roleInfo.label === 'share video' ? this.roomService.blockWebcam() : null);

        // if (this.roomService.hasPermission(permissions.MODERATE_ROOM) && gotRole) {
        //     // this.settingsService.setVideoUnblocked(this.roomService.getRoomConfig().lockSettingsDisableCam);
        //     // this.settingsService.setAudioUnblocked(this.roomService.getRoomConfig().lockSettingsDisableMic);
        //     this.roomService.setLockAllWhiteboard(this.roomService.getRoomConfig().whiteboardLocked);
        //     this.poolService.getPeers().forEach(peerID => {
        //         this.poolService.updatePeerPagination(this.poolService.getPeer(peerID), false);
        //     });
        // }

        // if (this.settingsService.getIsWebcamsOnlyForModerator() && roleId == PERMISSIONS.MODERATOR.id && !gotRole) {
        //     // TODO: update pagination view moderator
        //     this.poolService.filterPaginationModeratorOnly();
        // }
        if (this.settingsService.getIsWebcamsOnlyForModerator() && !this.roomService.hasPermission(permissions.MODERATE_ROOM)) {
            this.poolService.addFilter(PoolService.filterPresenterOnly);
        }
        else {
            this.poolService.removeFilter(PoolService.filterPresenterOnly);
        }
        this.poolService.resetCurrentPage();
        var roleName = this.getTranslatedRoleName(roleId);
        if (roleName) {
            gotRole ? this.notification.info(this.i18nService.translate('service.mediasoup-base.got_role', { role: roleName }), "") 
                    : this.notification.info(this.i18nService.translate('service.mediasoup-base.lost_role', { role: roleName }), "");
        }
    }

    //Bo sung cap nhat role(gotRole/lostRole) cho participant
    public updateRemotedParticipantRole(peerId: string,roleId: number, gotRole : boolean){ 
        gotRole ? this.poolService.addRole(peerId, roleId) : this.poolService.removeRole(peerId, roleId);
        this.poolService.resetCurrentPage();
        // if (gotRole && roleId == PERMISSIONS.PRESENTER.id && !this.localParticipantService.getRoles().includes(PERMISSIONS.MODERATOR.id)) {
        //     this.poolService.updatePeerPagination(this.poolService.getPeer(peerId), false);
        // }
        // if (!gotRole && this.settingsService.getIsWebcamsOnlyForModerator() 
        //     && roleId == PERMISSIONS.PRESENTER.id
        //     && !this.localParticipantService.getRoles().includes(PERMISSIONS.MODERATOR.id)) {
        //         this.poolService.removePeerPagination(peerId);
        // } 
        if (roleId == PERMISSIONS.MODERATOR.id) this.poolService.updateSearch();
    }
    // protected callbackAfterAddedNewPeer(peerInfo) {

    public updateRemoteActiveSpeaker(remote: RemoteParticipant): void {
        if (!remote) { return; }
        const activeSpeaker = this.activeSpeakerService.getActiveSpeaker();
        if (activeSpeaker && activeSpeaker.id === remote.id) {
            this.activeSpeakerService.setActiveSpeaker(remote);
        }
    }

    public hasRole(roleLabel: string): boolean {
        const userRoles = this.userRolesService.getUserRoles();
        const localRoles = this.localParticipantService.getRolesSubject();
        if (!userRoles || !localRoles) {
            return false;
        }
        // tslint:disable-next-line:no-shadowed-variable
        const role = Array.from(userRoles.values()).find((role: UserRoleModel) => role.label === roleLabel);
        if (!role) {
            return false;
        }
        const roleId = role.id;

        return localRoles.some((localRoleId: number) => localRoleId === roleId);
    }

    public remoteHasPermission(id: string, permission: string): boolean {
        const roomPermissions = this.roomService.getRoomPermission();
        const remoteRoles = this.poolService.getPeer(id).roles;
        if (!roomPermissions) { return false; }

        const permitted = remoteRoles?.some((roleId) =>
            roomPermissions[permission]?.some((permissionRole) =>
                roleId === permissionRole.id
            )
        );
        return permitted;
    }

    public remoteHasRole(id: string, roleLabel: string): boolean {
        const userRoles = this.userRolesService.getUserRoles();
        const paticipant = this.poolService.getPeer(id);
        if (!paticipant) return false;
        const remoteRoles = paticipant.roles;
        if (!userRoles || !remoteRoles) {
            return false;
        }
        // tslint:disable-next-line:no-shadowed-variable
        const role = Array.from(userRoles.values()).find((role: UserRoleModel) => role.label === roleLabel);
        if (!role) {
            return false;
        }
        const roleId = role.id;

        return remoteRoles.some((localRoleId: number) => localRoleId === roleId);
    }

    /**
     * Restart connection
     * @param type type of transport
     */
    public async restartIce(transport, ice, delay): Promise<void> {
        if (!transport) {
            console.log('restartIce(): missing valid transport object');

            return;
        }

        if (!ice) {
            console.log('restartIce(): missing valid ice object');

            return;
        }

        clearTimeout(ice.timer);
        ice.timer = setTimeout(async () => {
            try {
                if (ice.restarting) {
                    return;
                }
                ice.restarting = true;

                const iceParameters = await this.socketService.sendRequest(
                    'restartIce',
                    { transportId: transport.id });

                await transport.restartIce({ iceParameters });
                ice.restarting = false;
                clearTimeout(ice.timer);
                console.log('ICE restarted');
                if (transport.connectionState != "connected") {
                    throw new Error("transport connection " + transport.connectionState);
                }
            } catch (error) {
                console.log('restartIce() [failed:%o]', error.message);

                ice.restarting = false;
                if (delay) {
                    this.restartIce(transport, ice, 0);
                } else {
                    console.log("### reload transport failed ###");
                    await this.roomService.sendReport('other' as REPORT_TYPE,'',this.restartIce.name + error);

                    this.roomService.setMediaConnectionState("error");
                }
            }
        }, delay || 5000);

        /* try {
            const iceParameters: mediasoupClient.types.IceParameters = await this.socketService.sendRequest('restartIce',
                {
                    transportId: id
                });
                console.log('iceParameters');
                console.log(iceParameters);

            switch (type) {
                case 'producer':
                    await this.roomService.producerTransport.restartIce({ iceParameters });
                    break;
                case 'consumer':
                    await this.roomService.consumerTransport.restartIce({ iceParameters });
                    break;
            }
        } catch (error) {
            console.error(error);
        } */
    }

    /**
     * Get a keyframe from the user whose stream is being received.
     * For video only
     * @param user_id unique user identifier
     */
    public async requestConsumerKeyFrame(userId: string): Promise<void> {
        try {
            return await this.socketService.sendRequest('requestConsumerKeyFrame',
                {
                    userId,
                });
        } catch (error) {
            console.error(error.message, error.stack);
        }
    }

    public async requestPeerVideoConsumer(peerID: string): Promise<{ numberOfTurningOnWebcams, videoConsumerMap, totalVideo }> {
        try {
            return await this.socketService.sendRequest('getPeerVideoConsumer',
                {
                    peerID,
                });
        } catch (error) {
            console.error(error.message, error.stack);
            this.roomService.sendReport('video' as REPORT_TYPE,'', this.requestPeerVideoConsumer.name + error);
        }
    }

    private async startConsumer(consumer: Consumer): Promise<any> {
        if (consumer.kind === "video") {
            return await this.resumeConsumer(consumer, { initial: true });
        }

        return;
    }

    closeConsumer(consumerId: string): void {
        const consumerEventEmitter = this._consumers.get(consumerId);

        if (!consumerEventEmitter) {
            return;
        }

        consumerEventEmitter.close();
        this._consumers.delete(consumerId);
    }

    // private deleteConsumerMap(consumer: Consumer): void {
    //     this.consumerService.deleteConsumer(consumer.id);
    //     if (consumer.appData.source === 'mic') {
    //         this.consumerService.deleteAudioConsumer(consumer.id);
    //     }
    // }

    // updatePauseOrResumeStatusDevice(consumerData: ConsumerModel, muted: boolean): void {
    //     // this.updateActivatingSpeaker(consumerData.peerId, consumerData.source, muted);
    //     switch (consumerData.source) {
    //         case 'mic':
    //             this.listRemoteParticipantService.setAudioMuted(consumerData.peerId, muted);
    //             break;
    //         case 'webcam':
    //             this.listRemoteParticipantService.setVideoMuted(consumerData.peerId, muted);
    //             break;
    //     }
    // }

    // updateCloseStatusDevice(consumerData: ConsumerModel, muted: boolean): void {
    //     // this.updateActivatingSpeaker(consumerData.peerId, consumerData.source, muted);
    //     this.listRemoteParticipantService.updateConsumer({
    //         consumerId: '',
    //         remoteId: consumerData.peerId,
    //         source: consumerData.source,
    //     });
    //     this.updatePauseOrResumeStatusDevice(consumerData, muted);
    // }

    async modifyPeerConsumer(peerId, type, mute): Promise<void> {
        try {
            // const remote = this.listRemoteParticipantService.getRemoteParticipant(peerId);
            // if(type == 'audio') {

            // }
            for (const consumer of this._consumers.values()) {
                if (consumer.appData.peerId === peerId && consumer.appData.source === type && consumer) {
                    if (mute) {
                        await this.pauseConsumer(consumer);
                    }
                    else {

                        await this.resumeConsumer(consumer);
                    }
                }
            }
        }
        catch (error) {
            // logger.error('modifyPeerConsumer() [error:"%o"]', error);
        }
    }

    // Create new audio consumer
    async createNewAudioConsumer(peerID: string): Promise<void> {
        try {
            await this.socketService.sendRequest('createNewAudioConsumer', { peerId: peerID });
        } catch (error) {
            console.log('createNewAudioConsumer error', error);
            this.roomService.sendReport('audio' as REPORT_TYPE,'', this.createNewAudioConsumer.name + error);
        }
    }

    async createNewVideoConsumer(peerId: string, producerId?: string): Promise<void> {
        this.roomService.createNewConsumer(peerId, 'webcam', producerId);
    }

    // private async getProducerId(peerId: string, kind: 'webcam' | 'screen'): Promise<string> {
    //     const currentVideoConsumer = await this.roomService.getCurrentVideoConsumer();
    //     const videoConsumerMap = currentVideoConsumer.videoConsumerMap;
    //     const type = APPSOURCE[kind];

    //     if (videoConsumerMap.has(peerId)) {
    //         return videoConsumerMap.get(peerId)[type];
    //     }
    //     return null;
    // }


    public async createNewScreenConsumer(peerId: string, producerId?: string): Promise<void> {
        this.roomService.createNewConsumer(peerId, 'screen', producerId);
    }

    async pauseConsumer(consumer: Consumer): Promise<void> {
        if (consumer.paused || consumer.closed) {
            return;
        }

        try {
            await this.socketService.sendRequest('pauseConsumer', { consumerId: consumer.id });

            consumer.pause();
            if (consumer.appData.source !== 'screen') {
                //fix loi setLocallyPaused
                if (consumer.appData.source === 'mic') {
                    this.poolService.setLocallyPaused(consumer.appData.peerId.toString(), 'audio', true);
                }
                else {
                    this.poolService.setLocallyPaused(consumer.appData.peerId.toString(), 'webcam', true);
                }

            }
        }
        catch (error) {
            console.error(error);
            if (error.notFoundInMediasoupError) {
                this.closeConsumer(consumer.id);
            }
            this.roomService.sendReport('other' as REPORT_TYPE,'', this.pauseConsumer.name + error);
        }
    }

    async resumeConsumer(consumer: Consumer, { initial = false } = {}): Promise<void> {
        if ((!initial && !consumer.paused) || consumer.closed) {
            return;
        }

        try {
            if (!initial) {
                consumer.resume();
            }
            await this.socketService.sendRequest('resumeConsumer', { consumerId: consumer.id });
            if (consumer.appData.source !== 'screen') {
                //fix loi setLocallyPaused
                if (consumer.appData.source === 'webcam') {
                    this.poolService.setLocallyPaused(consumer.appData.peerId.toString(), 'webcam', false);
                }
                else {
                    this.poolService.setLocallyPaused(consumer.appData.peerId.toString(), 'audio', false);
                }
            }

        } catch (error) {
            if (error.notFoundInMediasoupError) {
                console.log(consumer);
                this.closeConsumer(consumer.id);
                if (consumer.appData.source === 'webcam') {
                    const remote = this.poolService.getPeer(consumer.appData.peerId.toString());
                    if (remote) {
                        this.createNewVideoConsumer(consumer.appData.peerId.toString(), remote.webcam.producerId);
                    }
                    return;
                }
            }
            console.error(error)
            this.roomService.sendReport('other' as REPORT_TYPE,'', this.resumeConsumer.name + error);
        }
    }

    // Close consumer
    async emitServerCloseConsumer(consumerId: string): Promise<void> {
        // debugger;
        try {
        const result = await this.socketService.sendRequest('closeConsumer', { consumerId });
        } catch (error) {
            if(!error.includes('503'))
            console.log(error);
            
        }
        // const peerId = this.localParticipantService.getPeerId();
        // const consumerData = this.consumerService.getConsumer(consumerId);
        // const peerId = consumerData.peerId;
        // const remote = this.poolService.getRemoteParticipant(peerId);
        // const remote = this.poolService.getRemoteParticipant(peerId);
        // this.mediaStreamService.removeStream(peerId, "webcam");
        this.consumerService.deleteConsumer(consumerId);
        this.closeConsumer(consumerId);
        // this.poolService.updateRemoteProducer(peerId, "", "webcam");
        // this.deviceCountingService.decreaseWebcamNumber();
        // this.poolService.deleteWebcamStartedFromPool(peerId);
    }

    private updatePeerCloseActiveSpeaker(remoteId: string): void {
        const activeSpeaker = this.activeSpeakerService.getActiveSpeaker();
        if (!activeSpeaker || remoteId !== activeSpeaker.id) { return; }

        const [listRemoteParticipant] = this.poolService.getListParticipantIsShareScreen();
        // console.log("listRemoteParticipant", [listRemoteParticipant] );
        if (listRemoteParticipant) {
            this.activeSpeakerService.setActiveSpeaker(listRemoteParticipant);
        } else {
            const local = this.localParticipantService.getLocalParticipant();
            const remotes = this.poolService.getAllPeers();
            if (local.isRecordUser && remotes.length > 0) {
                this.activeSpeakerService.setActiveSpeaker(remotes[0]);
            }
            else {
                this.activeSpeakerService.setActiveSpeaker(this.localParticipantService.getLocalParticipant());

            }
        }
        // const [remoteParticipantId] = this.listRemoteParticipantService.getWebcamStartedPool();
        // if (remoteParticipantId) {
        //     const remote = this.listRemoteParticipantService.getRemoteParticipant(remoteParticipantId);
        //     this.activeSpeakerService.setActiveSpeaker(remote);
        // }

    }

    async changeDisplayName(displayName: string): Promise<void> {
        try {
            await this.socketService.sendRequest('changeDisplayName', { displayName });
        } catch (error) {
            console.log('changeDisplayName() [error:"%o"]', error);
            this.roomService.sendReport('feature' as REPORT_TYPE,'', this.changeDisplayName.name + error);
        }
    }

    async changeRoomName(roomName: string): Promise<void> {
        try {
            await this.socketService.sendRequest('changeRoomName', { roomName });
        } catch (error) {
            console.log('changeRoomName() [error:"%o"]', error);
            this.roomService.sendReport('feature' as REPORT_TYPE,'', this.changeRoomName.name + error);
        }
    }

    async setRaisedHand(raisedHand: boolean): Promise<void> {
        try {
            await this.socketService.sendRequest('raisedHand', { raisedHand });
        } catch (error) {
            console.log('raiseHand() [error:"%o"]', error);
            this.roomService.sendReport('other' as REPORT_TYPE,'', this.setRaisedHand.name + error);
        }
    }

    async giveRemoteRole(peerId: string, roleId: number): Promise<void> {
        this.securityService.setProcessingModeratorManageRole(true);
        try {
            await this.socketService.sendRequest('moderator:giveRole', { peerId, roleId });

            if(roleId == PERMISSIONS.PRESENTER.id){
                this.requestRoleService.removeRequestRole(peerId);
            }

        } catch (error) {
            console.log(error);
            this.roomService.sendReport('feature' as REPORT_TYPE,'', this.giveRemoteRole.name + error);
        }
        this.securityService.setProcessingModeratorManageRole(false);
    }

    async removeRemoteRole(peerId: string, roleId: number): Promise<void> {
        this.securityService.setProcessingModeratorManageRole(true);
        try {
            await this.socketService.sendRequest('moderator:removeRole', { peerId, roleId });
        } catch (error) {
            console.log(error);
            this.roomService.sendReport('feature' as REPORT_TYPE,'', this.removeRemoteRole.name + error);
        }
        this.securityService.setProcessingModeratorManageRole(false);
    }

    async requestRole(peerId: string, roleId: number): Promise<void> {
        try {
            await this.socketService.sendRequest('requestRole', { roleId });
            this.setRequestPresenter({isRequestPresenter: true, peerId: peerId, displayName: ""});
        } catch (error) {
            console.log(error);
            this.roomService.sendReport('feature' as REPORT_TYPE,'', this.requestRole.name + error);
        }
    }

    async refuseRole(peerId: string, roleId: number): Promise<void> {
        try {
            await this.socketService.sendRequest('refuseRequestRole', { peerId, roleId });
            if(roleId == PERMISSIONS.PRESENTER.id){
                this.requestRoleService.removeRequestRole(peerId);
            }
        } catch (error) {
            console.log(error);
            this.roomService.sendReport('feature' as REPORT_TYPE,'', this.refuseRole.name + error);
        }
    }

    async refuseAllRequest(roleId: number): Promise<void> {
        try {
            await this.socketService.sendRequest('refuseAllRequestRole', {roleId});
        } catch (error) {
            console.log(error);
            this.roomService.sendReport('feature' as REPORT_TYPE,'', this.refuseAllRequest.name + error);
        }
    }

    async getRequestRole(roleId: number): Promise<void> {
        try {
            const request = await this.socketService.sendRequest('getRequestRole', {roleId });
            this.requestRoleService.addRequestRoleList(request);
            
        } catch (error) {
            console.log(error);
            this.roomService.sendReport('feature' as REPORT_TYPE,'', this.getRequestRole.name + error);
        }
    }


    async kickRemoteParticipant(remoteId: string): Promise<void> {
        // TODO: add setPeerKickInProgress for service list-remote-participant
        try {
            await this.socketService.sendRequest('moderator:kickPeer', { peerId: remoteId });
        } catch (error) {
            console.log(error);
            this.roomService.sendReport('feature' as REPORT_TYPE,'', this.kickRemoteParticipant.name + error);
        }
    }

    async muteRemoteParticipant(remoteId: string): Promise<void> {
        // TODO: add setMutePeerInProgress for service list-remote-participant
        try {
            await this.socketService.sendRequest('moderator:mute', { peerId: remoteId });
        } catch (error) {
            console.log(error);
            this.roomService.sendReport('feature' as REPORT_TYPE,'', this.muteRemoteParticipant.name + error);
        }
    }

    async stopRemoteParticipantVideo(remoteId): Promise<void> {
        // TODO: add setStopPeerVideoInProgress for service list-remote-participant
        try {
            await this.socketService.sendRequest('moderator:stopVideo', { peerId: remoteId });
        } catch (error) {
            console.log(error);
            this.roomService.sendReport('feature' as REPORT_TYPE,'', this.stopRemoteParticipantVideo.name + error);
        }
    }

    async stopRemoteParticipantScreenSharing(remoteId: string): Promise<void> {
        // TODO: add setStopPeerScreenSharingInProgress for service list-remote-participant
        try {
            await this.socketService.sendRequest('moderator:stopScreenSharing', { peerId: remoteId });
        } catch (error) {
            console.log(error);
            this.roomService.sendReport('feature' as REPORT_TYPE,'', this.stopRemoteParticipantScreenSharing.name + error);
        }
    }

    async muteAllPeers(): Promise<any> {
        this.securityService.setProcessingModeratorStopAllAudio(true);
        try {
            await this.socketService.sendRequest('moderator:muteAll', {});
        } catch (error) {
            console.log(error);
            this.roomService.sendReport('feature' as REPORT_TYPE, '', this.muteAllPeers.name + error);
            this.securityService.setProcessingModeratorStopAllAudio(false);
            return false;
        }
        this.securityService.setProcessingModeratorStopAllAudio(false);
        return true;
    }

    async muteAllPeersExceptPresenter(): Promise<any> {
        this.securityService.setProcessingMuteAllExceptPresenter(true);
        try {
            await this.socketService.sendRequest('moderator:muteAllExceptPresenter', {});
        } catch (error) {
            console.log(error);
            this.roomService.sendReport('feature' as REPORT_TYPE, '', this.muteAllPeersExceptPresenter.name + error);
            this.securityService.setProcessingMuteAllExceptPresenter(false);
            return false;
        }
        this.securityService.setProcessingMuteAllExceptPresenter(false);
        return true;
    }


    async stopAllPeerVideo(): Promise<any> {
        this.securityService.setProcessingModeratorStopAllWebcam(true);
        try {
            await this.socketService.sendRequest('moderator:stopAllVideo', {});
        } catch (error) {
            console.log(error);
            this.roomService.sendReport('feature' as REPORT_TYPE,'', this.stopAllPeerVideo.name + error);
            this.securityService.setProcessingModeratorStopAllWebcam(false);
            return false;
        }
        this.securityService.setProcessingModeratorStopAllWebcam(false);
        return true;
    }

    async blockAllPeerVideo(lockSettingsDisableCam: boolean): Promise<void> {
        this.securityService.setProcessingBlockAllVideo(true);
        try {
            await this.socketService.sendRequest('moderator:blockAllVideo', { lockSettingsDisableCam: lockSettingsDisableCam });
            // await this.socketService.sendRequest('moderator:stopAllVideo', {});
        } catch (error) {
            console.log(error);
            this.roomService.sendReport('feature' as REPORT_TYPE,'', this.blockAllPeerVideo.name + error);
        }
        this.securityService.setProcessingBlockAllVideo(false);
    }

    async blockAllPeerAudio(lockSettingsDisableMic: boolean): Promise<void> {
        this.securityService.setProcessingBlockAllAudio(true);
        try {
            await this.socketService.sendRequest('moderator:blockAllAudio', { lockSettingsDisableMic: lockSettingsDisableMic });
        } catch (error) {
            console.log(error);
            this.roomService.sendReport('feature' as REPORT_TYPE,'', this.blockAllPeerAudio.name + error);
        }
        this.securityService.setProcessingBlockAllAudio(false);
    }

    async stopAllPeerScreenSharing(): Promise<any> {
        this.securityService.setProcessingModeratorStopAllShareScreen(true);
        try {
            await this.socketService.sendRequest('moderator:stopAllScreenSharing', {});
        } catch (error) {
            console.log(error);
            this.roomService.sendReport('feature' as REPORT_TYPE,'', this.stopAllPeerScreenSharing.name + error);
            this.securityService.setProcessingModeratorStopAllShareScreen(false);
            return false;
        }
        this.securityService.setProcessingModeratorStopAllShareScreen(false);
        return true;
    }

    async grantAllPeerScreenSharing(allowed : boolean): Promise<void> {
        this.securityService.setProcessingBlockAllShareScreen(true);
        try {
            await this.socketService.sendRequest('moderator:grantAllPeerPermission', {allowed : allowed, roleId : PERMISSIONS.PRESENTER.id});
        } catch (error) {
            console.log(error);
            this.roomService.sendReport('feature' as REPORT_TYPE,'', this.grantAllPeerScreenSharing.name + error);
        }
        this.securityService.setProcessingBlockAllShareScreen(false);
    }

    async webcamsForModerator(flag: boolean): Promise<void>{
        this.securityService.setProcessingWebcamsForModerator(true);
        try {
            await this.socketService.sendRequest('moderator:webcamsOnlyForModerator', {enable : flag});
            //Xu ly xóa pin khi bật webcamsOnlyForModerator
            if (flag) {
                const isForceLayout = this.roomService.getForceLayout.layout != 0;
                const listUserIsPin = Array.from(this.poolService.getRemotesPin.map((remote) => remote.id));
                //TH có force layout va có danh sách pinners
                if (isForceLayout && listUserIsPin.length != 0) {
                    this.roomService.moderatorPin([]).then((result) => {
                        if (result) {
                            //cập nhật trạng thái pin của remote
                            listUserIsPin.forEach(peerId => {
                                const remote  = this.poolService.getPeer(peerId);
                                if(remote) remote.isPin = false;
                            });
                            //xoa pin localParticipant nếu có
                            if(listUserIsPin.includes(this.localParticipantService.getLocalParticipant().id)){
                                this.localParticipantService.setPin(false);
                            }
                            //Xoa danh sach pin
                            this.poolService.setRemotesPin([]);
                        }
                    });

                    //TH không có force layout và có danh sách pinners
                } else if (listUserIsPin.length != 0) {
                    listUserIsPin?.forEach(peerId => {
                        this.poolService.setIsPinNormal(peerId, false);
                    });
                }
            }
        } catch (error) {
            console.log(error);
            // this.roomService.sendReport('feature' as REPORT_TYPE,'', this.grantAllPeerScreenSharing.name + error);
        }
        this.securityService.setProcessingWebcamsForModerator(false);
    }

    async closeMeeting(): Promise<void> {
        // this.securityService.setCloseMeeting(true);
        try {
            await this.socketService.sendRequest('moderator:closeMeeting', {});
            // this.timeLog.setEndTime('moderator:closeMeeting');
        } catch (error) {
            console.log(error);
            this.roomService.sendReport('feature' as REPORT_TYPE,'', this.closeMeeting.name + error);
        }
        // this.securityService.setCloseMeeting(false);
    }

    async doSubmitBreakRoom(groupMap: any): Promise<void> {
        try {
            await this.socketService.sendRequest('doSubmitBreakRoom', { groupMap });
        } catch (error) {
            console.log(error);
            this.notification.error(`Không thể tạo BreakRoom`, '');
        }
    }

    async doStopBreakRoom(): Promise<void> {
        try {
            await this.socketService.sendRequest('doStopBreakRoom', {});
        } catch (error) {
            this.notification.error(`Không thể kết thúc BreakRoom`, '');
        }
    }

    async recording(isRecording: boolean): Promise<void> {
        try {
            await this.socketService.sendRequest('moderator:record', { isRecording: isRecording });
            
        } catch (error) {
            this.notification.error(this.i18nService.translate('moderator.record.error'), error);
            this.recordService.setRecordError(true);
            this.roomService.sendReport('feature' as REPORT_TYPE,'', this.recording.name + error);
        }
    }

    adaptConsumerPreferredLayers(consumerId: string, viewWidth: number, viewHeight: number): Promise<void> {
        const consumer = this.consumerService.getConsumer(consumerId);

        if (!consumer || consumer.type === 'simple' || !viewWidth || !viewHeight) {
            // console.warn("[WARN] adaptConsumerPreferredLayers", consumer, viewWidth, viewHeight);
            return;
        }

        const {
            id,
            preferredSpatialLayer,
            preferredTemporalLayer,
            width,
            height,
            resolutionScalings
        } = consumer;

        //Nếu ko có resolutionScalings không xử lý tiếp phía simucast
        if(!resolutionScalings) return;
        
        const adaptiveScalingFactor = Math.min(Math.max(
            config.adaptiveScalingFactor || 0.75, 0.5), 1.0);

        let newPreferredSpatialLayer = 2;

        for (let i = 0; i < resolutionScalings.length; i++) {
            //tính toán levelWidth/Height dựa trên tỉ lệ resolutionScalings=2/1.25/1 chia của các luồng simulcast
            const levelWidth = width / adaptiveScalingFactor / resolutionScalings[i];
            const levelHeight = height / adaptiveScalingFactor / resolutionScalings[i];

            // console.log("# update preferredSpatial", {scaling: i, viewWidth, levelWidth, viewHeight, levelHeight});
            //So sánh giữa chất lượng của khung hình hiện tại và chất lượng mà luồng simulcast hiện tại gửi xuống
            if (viewWidth < levelWidth || viewHeight < levelHeight ) {
                newPreferredSpatialLayer = i;
                break;
            }
        }

        let newPreferredTemporalLayer = consumer.temporalLayers - 1;

        if (newPreferredSpatialLayer === 0 && newPreferredTemporalLayer > 0) {
            const lowestLevelWidth = width / resolutionScalings[0];
            const lowestLevelHeight = height / resolutionScalings[0];

            if (viewWidth < lowestLevelWidth * 0.5
                && viewHeight < lowestLevelHeight * 0.5) {
                newPreferredTemporalLayer -= 1;
            }
            if (newPreferredTemporalLayer > 0
                && viewWidth < lowestLevelWidth * 0.25
                && viewHeight < lowestLevelHeight * 0.25) {
                newPreferredTemporalLayer -= 1;
            }
        }

        /* console.log("# adaptConsumerPreferredLayers", {
            resolutionScalings,
            consumer: {width, height},
            viewports: {width: viewWidth, height: viewHeight},
            updateData: {id, preferredSpatialLayer, newPreferredSpatialLayer, preferredTemporalLayer, newPreferredTemporalLayer}
        }); */

        if (preferredSpatialLayer !== newPreferredSpatialLayer ||
            preferredTemporalLayer !== newPreferredTemporalLayer) {
            return this.setConsumerPreferredLayers(id,
                newPreferredSpatialLayer, newPreferredTemporalLayer);
        }
    }

    async setConsumerPreferredLayers(consumerId: string, spatialLayer: number, temporalLayer: number): Promise<void> {
        try {
            const consumer = this.consumerService.getConsumer(consumerId);
            const newConsumer = {
                ...consumer,
                preferredSpatialLayer: spatialLayer,
                preferredTemporalLayer: temporalLayer
            };
            this.consumerService.setConsumer(consumerId, newConsumer);
            // console.log("[REQUEST] setConsumerPreferredLayers", {consumerId, spatialLayer, temporalLayer});

            await this.socketService.sendRequest(
                'setConsumerPreferedLayers', { consumerId, spatialLayer, temporalLayer });

        }
        catch (error) { }
    }

    async sendListConsumer(request: string) {
        let listConsumers = [];
        this._consumers.forEach((value, key) => {
            let remote = this.poolService.getPeer(value['_appData']['peerId']);
            let values = {'consumerId':value['_id'],
                            'producerId': value['_producerId'],
                            'peerIdProducer':value['_appData']['peerId'],
                            'displayNameProducer': remote ? remote.displayName : null
                        }
            listConsumers.push(values) ;
        });
        // this.roomService.sendReport('other','',JSON.stringify(listConsumers))
        const data = { consumers: listConsumers, roomId: this.roomService.getRoomId(), id: request };
        try {
            await this.latencyService.sendRequest(data);
        } catch (error) {
            console.log(error);
            // this.roomService.sendReport('other' as REPORT_TYPE,'', this.sendListConsumer.name + error);
        }
    }

    updateVideo(consumerId: string): void {
        this.updateVideoSubject.next(consumerId);
    }

    onUpdateVideo(): Observable<string> {
        return this.updateVideoSubject.asObservable();
    }

    updateLocalVideoScore(producerId: string, score: number): void {
        this.updateLocalVideoScoreSubject.next({ producerId, score });
    }

    onUpdateLocalVideoScore(): Observable<{ producerId: string, score: number }> {
        return this.updateLocalVideoScoreSubject.asObservable();
    }

    updateRemoteVideoScore(consumerId: string, score: number): void {
        this.updateRemoteVideoScoreSubject.next({ consumerId, score });
    }

    onUpdateRemoteVideoScore(): Observable<{ consumerId: string, score: number }> {
        return this.updateRemoteVideoScoreSubject.asObservable();
    }

    getTranslatedRoleName(roleId: number): string {
        switch (roleId) {
            case PERMISSIONS.MODERATOR.id:
                return this.i18nService.translate("roles-manager.role.moderator");
            case PERMISSIONS.PRESENTER.id:
                return this.i18nService.translate("roles-manager.role.presenter");
            case PERMISSIONS.SHARE_AUDIO.id:
                return this.i18nService.translate("roles-manager.role.share audio");
            case PERMISSIONS.SHARE_VIDEO.id:
                return this.i18nService.translate("roles-manager.role.share video");
            case PERMISSIONS.WHITEBOARD.id:
                return this.i18nService.translate("roles-manager.role.whiteboard");
            default: return "";
        }
    }

    generateRandomColor(initColor: number): string {
        let color = "#" + Math.floor(initColor * Math.random()).toString(16);
        let missing = 7 - color.length;
        for (let i = 0; i < missing; i++) {
            color += "0";
        }
        return color.toUpperCase();
    }
}
