/* tslint:disable:no-console */
import {Injectable} from '@angular/core';
import {ConsumerModel, VideoStatus} from '../models/consumer.model';
import {BehaviorSubject, Observable} from 'rxjs';

type KIND = 'audio' | 'webcam' | 'screen' | 'audioscreen';

@Injectable({
    providedIn: 'root'
})
export class ConsumerService {

    private consumerTypeMap: Map<string, KIND> = new Map<string, KIND>();
    private audioConsumerMap: Map<string, ConsumerModel>  = new Map<string, ConsumerModel>();
    private webcamConsumerMap: Map<string, ConsumerModel> = new Map<string, ConsumerModel>();
    private screenConsumerMap: Map<string, ConsumerModel> = new Map<string, ConsumerModel>();
    private audioscreenConsumerMap: Map<string, ConsumerModel> = new Map<string, ConsumerModel>();

    private onAddAudioConsumerMapSubject: BehaviorSubject<{peerId: string, audio: ConsumerModel}>
        = new BehaviorSubject<{peerId: string, audio: ConsumerModel}>(null);
    private onRemoveAudioConsumerMapSubject: BehaviorSubject<string>
        = new BehaviorSubject<string>(null);
    private audioConsumerMapSubject: BehaviorSubject<Map<string, ConsumerModel>>
        = new BehaviorSubject<Map<string, ConsumerModel>>(this.audioConsumerMap);

    private onAddAudioScreenConsumerMapSubject: BehaviorSubject<{peerId: string, audio: ConsumerModel}>
        = new BehaviorSubject<{peerId: string, audio: ConsumerModel}>(null);
    private onRemoveAudioScreenConsumerMapSubject: BehaviorSubject<string>
        = new BehaviorSubject<string>(null);
    // private consumerMap: Map<string, ConsumerModel> = new Map();
    // private consumerMapSubject: BehaviorSubject<Map<string, ConsumerModel>>
    //     = new BehaviorSubject<Map<string, ConsumerModel>>(this.consumerMap);

    // private consumer;

    // private audioConsumerMap: Map<string, ConsumerModel> = new Map();

    // private videoConsumerMap: Map<string, VideoStatus> = new Map();

    constructor() {}

    addConsumer(consumerId: string, kind: KIND, consumer: ConsumerModel): void {
        this.consumerTypeMap.set(consumerId, kind);
        this[`${kind}ConsumerMap`].set(consumerId, consumer);
        if (kind === 'audio') {
            //this.updateAudioConsumer();
            this.audioConsumerMapSubject.next(this.audioConsumerMap);
            this.onAddAudioConsumerMapSubject.next({ peerId: consumer.peerId, audio: consumer });
        }
        if(kind === 'audioscreen') {
            this.onAddAudioScreenConsumerMapSubject.next({ peerId: consumer.peerId, audio: consumer });
        }
    }

    deleteConsumer(consumerId: string): void {
        const kind = this.consumerTypeMap.get(consumerId);
        if (!kind) { return; }
        if(!this[`${kind}ConsumerMap`].has(consumerId)) {return;}
        const { peerId } = this[`${kind}ConsumerMap`].get(consumerId);
        this[`${kind}ConsumerMap`].delete(consumerId);
        if (kind === 'audio') {
            //this.updateAudioConsumer();
            this.audioConsumerMapSubject.next(this.audioConsumerMap);
            this.onRemoveAudioConsumerMapSubject.next(peerId);
        }
        if(kind === 'audioscreen') {
            this.onRemoveAudioScreenConsumerMapSubject.next(peerId);
        }
    }

    getConsumer(consumerId: string): ConsumerModel {
        const kind = this.consumerTypeMap.get(consumerId);
        if (!kind) { return null; }
        return this[`${kind}ConsumerMap`].get(consumerId);
    }

    setConsumer(consumerId: string, consumer: ConsumerModel): void {
        const kind = this.consumerTypeMap.get(consumerId);
        if (!kind) { return null; }
        this[`${kind}ConsumerMap`].set(consumerId, consumer);
        /* if (kind === 'audio') {
            this.updateAudioConsumer();
        } */
    }

    updateAudioConsumer(): void {
        this.audioConsumerMapSubject.next(this.audioConsumerMap);
    }

    // addAudioConsumer(peerId: string, consumer: ConsumerModel): void{
    //     this.audioConsumerMap.set(peerId, consumer);
    //     this.onAddAudioConsumerMapSubject.next({ peerId, audio: consumer });
    //     this.audioConsumerMapSubject.next(this.audioConsumerMap);
    // }

    // getAudioConsumer(peerId: string): ConsumerModel{
    //     return this.audioConsumerMap.get(peerId);
    // }

    // deleteAudioConsumer(id: string): void{
    //     this.audioConsumerMap.delete(id);
    //     this.onRemoveAudioConsumerMapSubject.next(id);
    //     this.audioConsumerMapSubject.next(this.audioConsumerMap);
    // }

    onUpdateAudioConsumer(): Observable<Map<string, ConsumerModel>>{
        return this.audioConsumerMapSubject.asObservable();
    }

    onAddAudioScreenConsumerMap(): Observable<{peerId: string, audio: ConsumerModel}>{
        return this.onAddAudioScreenConsumerMapSubject.asObservable();
    }

    onRemoveAudioScreenConsumerMap(): Observable<string>{
        return this.onRemoveAudioScreenConsumerMapSubject.asObservable();
    }

    onAddAudioConsumerMap(): Observable<{peerId: string, audio: ConsumerModel}>{
        return this.onAddAudioConsumerMapSubject.asObservable();
    }

    onRemoveAudioConsumerMap(): Observable<string>{
        return this.onRemoveAudioConsumerMapSubject.asObservable();
    }

    getAudioConsumerMap(): Map<string, ConsumerModel>{
        return this.audioConsumerMap;
    }

    // setCurrentConsumerStarted(): void{

    // }

    // getVideoConsumerMap(): Map<string, VideoStatus>{
    //     return this.videoConsumerMap;
    // }

    // setVideoConsumerMap(consumerMap: Map<string, VideoStatus>): void {
    //     this.videoConsumerMap = consumerMap;
    // }

    setPreferedLayers(consumerId: string, spatialLayers: number, temporalLayers: number): void {
        const consumer = this.getConsumer(consumerId);
        if (!consumer) { return; }
        this.setConsumer(consumerId, {...consumer, spatialLayers, temporalLayers});
    }

    // deleteConsumer(id: string): void{
    //     this.consumerMap.delete(id);
    //     this.consumerMapSubject.next(this.consumerMap);
    // }

    // getConsumer(consumerId: string): ConsumerModel{
    //     return this.consumerMap.get(consumerId);
    // }

    // getConsumerEventEmitter(consumerId: string): Consumer{
    //     const consumer = this.getConsumer(consumerId);
    //     if (consumer){
    //         return consumer.consumerEventEmitter;
    //     }
    //     return null;
    // }

    // getConsumerMap(): Map<string, ConsumerModel>{
    //     return this.consumerMap;
    // }

    // onUpdateConsumerMap(): Observable<Map<string, ConsumerModel>>{
    //     return this.consumerMapSubject.asObservable();
    // }

    // setConsumerPaused(consumerId: string): void {
    //     const consumer = this.consumerMap.get(consumerId);
    //     consumer.remotelyPaused = true;
    //     this.consumerMap.set(consumerId, consumer);
    //     this.consumerMapSubject.next(this.consumerMap);
    // }

    // addAudioConsumer(consumer: ConsumerModel): void{
    //     this.audioConsumerMap.set(consumer.id, consumer);
    //     this.audioConsumerMapSubject.next(this.audioConsumerMap);
    // }

    // getAudioConsumer(consumerId: string): ConsumerModel{
    //     return this.audioConsumerMap.get(consumerId);
    // }

    // deleteAudioConsumer(id: string): void{
    //     this.audioConsumerMap.delete(id);
    //     this.audioConsumerMapSubject.next(this.audioConsumerMap);
    // }

    // getAudioConsumerMap(): Map<string, ConsumerModel>{
    //     return this.audioConsumerMap;
    // }

    // setCurrentConsumerStarted(): void{

    // }

    // getVideoConsumerMap(): Map<string, VideoStatus>{
    //     return this.videoConsumerMap;
    // }

    // setVideoConsumerMap(consumerMap: Map<string, VideoStatus>): void {
    //     this.videoConsumerMap = consumerMap;
    //

}
