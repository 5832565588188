<div class="wrapper">
    <div class="heading" vnpt-row vJustify="space-between" vAlign="middle">
        <vnpt-page-header class="site-page-header" vTitle="Bài tập"></vnpt-page-header>
    </div>
    <vnpt-divider class="divider"></vnpt-divider>

    <div #quizHolder class="quiz-list-wrapper">
        <div [@fade]="isQuizListLoading ? 'appear' : 'disappear'" class="loading-data"
            [ngClass]="{ 'floating-below' : !isQuizListLoading }">
            <vnpt-spin vTip="Đang tải dữ liệu ..."></vnpt-spin>
        </div>
        <vnpt-empty *ngIf="!quizList.length"></vnpt-empty>
        <div *ngIf="quizList.length" vnpt-row vAlign="middle" [vGutter]="[16, 16]" class="quiz-holder">
            <div *ngFor="let quiz of quizList, index as i" vnpt-col [style.width.%]="quizHolderWidth" class="quiz-item">
                <vnpt-ribbon [vText]="quizState[startingQuizId===quiz._id].text" [vColor]="quizState[startingQuizId===quiz._id].color">
                    <div class="quiz-card">
                        <div class="quiz-content">
                            <h3 class="quiz-content-header">{{ quiz.quizName }}</h3>
                        </div>
    
                        <div class="quiz-extra-info" vnpt-row vJustify="space-between">
                            <div class="quiz-left-info">
                                <i vnpt-icon vType="Clock"></i>
                                <span>{{ quiz.quizDuration }} phút</span>
                            </div>
    
                            <button *ngIf="!(startingQuizId===quiz._id)" vnpt-button vType="primary" title="Bắt đầu"
                                    [vLoading]="isStartingButton[i]" [disabled]="isExamining" vShape="circle" (click)="start(quiz._id)">
                                <i vnpt-icon vType="PlaySolid"></i>
                            </button>
    
                            <button *ngIf="startingQuizId===quiz._id" vnpt-button vType="primary" vDanger title="Kết thúc"
                                    [vLoading]="isStoppingButton[i]" vShape="circle" (click)="stop(quiz._id)">
                                <i vnpt-icon vType="StopSolid"></i>
                            </button>
                        </div>
                    </div>
                </vnpt-ribbon>
            </div>
        </div>
    </div>
</div>
