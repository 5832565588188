import {Action} from '@ngrx/store';

export const ADD_PRODUCER = 'add producer';
export const REMOVE_PRODUCER = 'remove producer';
export const SET_PRODUCER_PAUSED = 'set producer pause';
export const SET_PRODUCER_RESUMED = 'set producer resumed';
export const SET_PRODUCER_TRACK = 'set producer track';
export const SET_PRODUCER_SCORE = 'set producer score';

export class AddProducer implements Action{
    readonly type = ADD_PRODUCER;
    public payload: any;
    constructor(producer) {
        this.payload = {producer};
    }
}
export class RemoveProducer implements Action{
    readonly type = REMOVE_PRODUCER;
    public payload: any;
    constructor(producerId) {
        this.payload = {producerId};
    }
}
export class SetProducerPaused implements Action{
    readonly type = SET_PRODUCER_PAUSED;
    public payload: any;
    constructor(producerId, originator) {
        this.payload = {producerId, originator};
    }
}
export class SetProducerResumed implements Action{
    readonly type = SET_PRODUCER_RESUMED;
    public payload: any;
    constructor(producerId, originator) {
        this.payload = {producerId, originator};
    }
}
export class SetProducerTrack implements Action{
    readonly type = SET_PRODUCER_TRACK;
    public payload: any;
    constructor(producerId, track) {
        this.payload = {producerId, track};
    }
}
export class SetProducerScore implements Action{
    readonly type = SET_PRODUCER_SCORE;
    public payload: any;
    constructor(producerId, score) {
        this.payload = {producerId, score};
    }
}

export type All =
    AddProducer |
    RemoveProducer |
    SetProducerPaused |
    SetProducerResumed |
    SetProducerTrack |
    SetProducerScore;
