import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { Line } from "konva/lib/shapes/Line";
import { Image as KonvaImage } from "konva/lib/shapes/Image";
import { Text } from "konva/lib/shapes/Text";
import { Rect } from "konva/lib/shapes/Rect";
import { RoomService } from "./mediasoup/room.service";
import { Transformer } from "konva/lib/shapes/Transformer";
import { RegularPolygon } from "konva/lib/shapes/RegularPolygon";
import { Ellipse } from "konva/lib/shapes/Ellipse";

@Injectable({
  providedIn: "root",
})
export class WhiteboardService implements OnDestroy {
  brushSize!: number;
  brushOpacity!: number;
  private isWbData = new BehaviorSubject<object>(null);
  private isWbUndo = new BehaviorSubject<string>(null);
  private isWbClear = new BehaviorSubject<boolean>(false);
  private isWbDataHistory = new BehaviorSubject<Array<object>>(null);
  subscriptions: Subscription[] = [];

  constructor(private roomService: RoomService) {
    this.subscriptions.push(this.roomService.onBroadcastData().subscribe((data: object) => {
      if (data) this.setWbData(data);
    }));

    this.subscriptions.push(this.roomService.onWhiteboardUndo().subscribe((id: string) => {
      if (id) this.setWbUndo(id);
    }));

    this.subscriptions.push(this.roomService.onWhiteboardClear().subscribe((clear: boolean) => {
      if (clear) this.setWbClear(clear);
    }));

    this.subscriptions.push(this.roomService.onWhiteboardData().subscribe((data: Array<object>) => {
      if (data) this.setWbDataHistory(data);
    }));
  }

  /**
   * Các công cụ vẽ
   */

  pencil(pos: any, size: any, color: string, opacity: number, uid: string) {
    this.brushSize = size;
    this.brushOpacity = opacity;
    var pencil = new Line({
      id: uid,
      stroke: color,
      strokeWidth: size,
      globalCompositeOperation: "source-over",
      points: [pos.x, pos.y, pos.x, pos.y],
      lineCap: "round",
      lineJoin: "round",
      opacity: opacity,
      tension: 0,
    });
    return pencil;
  }

  line(pos: any, size: any, color: string, opacity: number, uid: string){
    return new Line({
      id: uid,
      stroke: color,
      points: [pos.x, pos.y, pos.x, pos.y],
      strokeWidth: size,
      opacity: opacity,
    });
  }

  erase(pos: any, size: any, uid: string) {
    return new Line({
      id: uid,
      stroke: "#ffffff",
      strokeWidth: size,
      globalCompositeOperation: "destination-out",
      points: [pos.x, pos.y, pos.x, pos.y],
      lineCap: "round",
      lineJoin: "round",
    });
  }

  text(
    pos: any,
    size: number,
    color: string,
    opacity: number,
    stage_width: number,
    uid: string
  ) {
    return new Text({
      id: uid,
      text: "",
      x: pos.x,
      y: pos.y,
      fontSize: size + 10,
      width: stage_width - pos.x,
      fill: color,
      opacity: opacity,
    });
  }

  circle(
    pos: any,
    size: number,
    color: string,
    opacity: number,
    radius: number,
    uid: string
  ) {
    return new Ellipse({
      id: uid,
      x: pos.x,
      y: pos.y,
      radiusX: radius,
      radiusY: radius,
      stroke: color,
      strokeWidth: size,
      opacity: opacity,
      draggable: true,
    });
  }

  rect(
    pos: any,
    size: number,
    color: string,
    opacity: number,
    len: number,
    uid: string
  ) {
    return new Rect({
      id: uid,
      x: pos.x,
      y: pos.y,
      width: len,
      height: len,
      stroke: color,
      strokeWidth: size,
      opacity: opacity,
      draggable: true,
    });
  }

  triangle(
    pos: any,
    size: number,
    color: string,
    opacity: number,
    radius: number,
    uid: string
  ) {
    return new RegularPolygon({
      id: uid,
      x: pos.x,
      y: pos.y,
      sides: 3,
      radius: radius,
      stroke: color,
      strokeWidth: size,
      opacity: opacity,
      draggable: true,
    });
  }

  image(url: string, cb: (img: KonvaImage) => void, er?: OnErrorEventHandler) {
    return KonvaImage.fromURL(url, cb, er);
  }

  /**
   * Tính lại kích thước của hình ảnh, lấy tối đa 90% theo kích thước whiteboard
   */
  _fitImgDemension(img_width, img_height, stage_width, stage_height) {
    let w = stage_width * 0.8;
    let h = stage_height * 0.8;
    let r = img_width / img_height;
    if (w / r < h) return { width: w, height: w / r };
    else return { width: h * r, height: h };
  }

  /**
   * Tạo các nút transformer
   */
  getTransformerKonva(node, stage) {
    return new Transformer({
      nodes: [node],
      boundBoxFunc: (oldBox, newBox) => {
        const box = this.getClientRect(newBox);
        const isOut =
          box.x < 0 ||
          box.y < 0 ||
          box.x + box.width > stage.width() ||
          box.y + box.height > stage.height();

        // if new bounding box is out of visible viewport, let's just skip transforming
        // this logic can be improved by still allow some transforming if we have small available space
        if (isOut) {
          return oldBox;
        }
        return newBox;
      },
      ignoreStroke: true,
      padding: 5,
    });
  }

  getCorner(pivotX, pivotY, diffX, diffY, angle) {
    const distance = Math.sqrt(diffX * diffX + diffY * diffY);

    /// find angle from pivot to corner
    angle += Math.atan2(diffY, diffX);

    /// get new x and y and round it off to integer
    const x = pivotX + distance * Math.cos(angle);
    const y = pivotY + distance * Math.sin(angle);

    return { x: x, y: y };
  }

  getClientRect(rotatedBox) {
    const { x, y, width, height } = rotatedBox;
    const rad = rotatedBox.rotation;

    const p1 = this.getCorner(x, y, 0, 0, rad);
    const p2 = this.getCorner(x, y, width, 0, rad);
    const p3 = this.getCorner(x, y, width, height, rad);
    const p4 = this.getCorner(x, y, 0, height, rad);

    const minX = Math.min(p1.x, p2.x, p3.x, p4.x);
    const minY = Math.min(p1.y, p2.y, p3.y, p4.y);
    const maxX = Math.max(p1.x, p2.x, p3.x, p4.x);
    const maxY = Math.max(p1.y, p2.y, p3.y, p4.y);

    return {
      x: minX,
      y: minY,
      width: maxX - minX,
      height: maxY - minY,
    };
  }

  getTransformerText(textNode) {
    return new Transformer({
      nodes: [textNode],
      enabledAnchors: ["middle-left", "middle-right"],
      // set minimum width of text
      boundBoxFunc: function (oldBox, newBox) {
        newBox.width = Math.max(30, newBox.width);
        return newBox;
      },
    });
  }

  convertDataToKonvaData(data: any, clientRatio: number) {
    var konvaData: any;
    switch (data.type) {
      case "pencil":
        let children = data.children;
        let points = [...children].map(
          (value) => value * clientRatio
        );
        let size = data.size * clientRatio;

        konvaData = new Line({
          id: data.id,
          stroke: data.color,
          strokeWidth: size,
          globalCompositeOperation: "source-over",
          points: points,
          lineCap: "round",
          lineJoin: "round",
          opacity: data.opacity,
          tension: 0,
        });
        break;
      case "line":
        let lineData = data.children;
        let pointLine = [...lineData].map(
          (value) => value * clientRatio
        );
        konvaData = new Line({
          id: data.id,
          stroke: data.color,
          //listening: false,
          strokeWidth: data.size * clientRatio,
          points: pointLine,
          opacity: data.opacity,
          tension: 0,
        });
          break;
      case "text":
        konvaData = new Text({
          id: data.id,
          text: data.text,
          x: data.x * clientRatio,
          y: data.y * clientRatio,
          fontSize: data.fontSize * clientRatio,
          width: data.width * clientRatio,
          fill: data.fill,
          opacity: data.opacity,
        });
        break;
      case "circle":
        konvaData = new Ellipse({
          id: data.id,
          x: data.x * clientRatio,
          y: data.y * clientRatio,
          scaleX: data.scaleX,
          scaleY: data.scaleY,
          skewX: data.skewX,
          skewY: data.skewY,
          radiusX: data.radiusX * clientRatio,
          radiusY: data.radiusY * clientRatio,
          stroke: data.stroke,
          strokeWidth: data.size * clientRatio,
          opacity: data.opacity,
          rotation: data.rotation
        });
        break;
      case "rect":
        konvaData = new Rect({
          id: data.id,
          x: data.x * clientRatio,
          y: data.y * clientRatio,
          width: data.width * clientRatio,
          height: data.height * clientRatio,
          scaleX: data.scaleX,
          scaleY: data.scaleY,
          skewX: data.skewX,
          skewY: data.skewY,
          stroke: data.stroke,
          strokeWidth: data.size * clientRatio,
          opacity: data.opacity,
          rotation: data.rotation
        });
        break;
      case "triangle":
        konvaData = new RegularPolygon({
          id: data.id,
          x: data.x * clientRatio,
          y: data.y * clientRatio,
          scaleX: data.scaleX,
          scaleY: data.scaleY,
          skewX: data.skewX,
          skewY: data.skewY,
          sides: 3,
          radius: data.radius * clientRatio,
          stroke: data.stroke,
          strokeWidth: data.size * clientRatio,
          opacity: data.opacity,
          rotation: data.rotation
        });
        break;
      default:
        break;
    }
    return konvaData;
  }

  sendMessageKonvaData(data: object) {
    this.roomService.sendMessageKonvaData(data);
  }

  sendMessageUndo() {
    this.roomService.sendMessageUndo();
  }

  sendMessageClear() {
    this.roomService.sendMessageClear();
  }

  /**
   * Gửi message lấy lại dữ liệu cũ khi mới vào phòng hoặc load lại phòng
   */
  sendMessageDataHistory(): void {
    this.roomService.getWhiteboardDataHistory();
  }

  /**
   * Tạo id ngẫu nhiên cho đường vẽ
   * @param prefix
   * @param suffix
   * @returns
   */
  generateUID(prefix?: string | "", suffix?: string | ""): string {
    var uid = Date.now().toString(36); //Create the uids in chronological order
    uid += Math.round(Math.random() * 36).toString(36); //Add a random character at the end
    if (prefix) uid = prefix + uid;
    if (suffix) uid = uid + suffix;
    return uid;
  }

  public setWbData(data: object) {
    this.isWbData.next(data);
  }

  public onWbData(): Observable<object> {
    return this.isWbData.asObservable();
  }

  public setWbUndo(id: string) {
    this.isWbUndo.next(id);
  }

  public onWbUndo(): Observable<string> {
    return this.isWbUndo.asObservable();
  }

  public setWbClear(clear: boolean) {
    this.isWbClear.next(clear);
  }

  public onWbClear(): Observable<boolean> {
    return this.isWbClear.asObservable();
  }

  public setWbDataHistory(data: Array<object>) {
    this.isWbDataHistory.next(data);
  }

  public onWbDataHistory(): Observable<Array<object>> {
    return this.isWbDataHistory.asObservable();
  }

  ngOnDestroy(): void {
    for (const subs of this.subscriptions) {
      subs.unsubscribe();
    }
  }

  /* camera */
}
