import { trigger, state, style, animate, transition, AnimationTriggerMetadata } from '@angular/animations';

function createFadeAnimation(duration: number, delay?: number): AnimationTriggerMetadata {
    return trigger('fade', [
        state('appear', style({
            opacity: 1
        })),
        state('disappear', style({
            opacity: 0
        })),
        transition('appear => disappear', [
            animate(`${duration}ms ${delay ? delay + 'ms' : ''} linear`)
        ])
    ]);
}

function createExpandShrinkWidth(shrinkWidth: string, expandWidth: string, duration: number): AnimationTriggerMetadata {
    return trigger('expandShrinkWidth', [
        state('shrink', style({
            width: shrinkWidth
        })),
        state('expand', style({
            width: expandWidth
        })),
        transition('shrink => expand', [
            animate(`${duration}ms linear`)
        ]),
        transition('expand => shrink', [
            animate(`${duration}ms linear`)
        ])
    ]);
}

function createExpandShrinkHeight(shrinkHeight: string, expandHeight: string, duration: number): AnimationTriggerMetadata {
    return trigger('expandShrinkHeight', [
        state('shrink', style({
            height: shrinkHeight
        })),
        state('expand', style({
            height: expandHeight
        })),
        transition('shrink => expand', [
            animate(`${duration}ms linear`)
        ]),
        transition('expand => shrink', [
            animate(`${duration}ms linear`)
        ])
    ]);
}

function createSlideAnimation(marginIn: string, marginOut: string, duration: number): AnimationTriggerMetadata {
    return trigger('slideInOut', [
        state('open', style({
            marginLeft: marginIn
        })),
        state('close', style({
            marginLeft: marginOut,
        })),
        transition('open => close', [
            animate(`${duration}ms linear`)
        ]),
        transition('close => open', [
            animate(`${duration}ms linear`)
        ])
    ]);
}

function createFloatAnimation(bottomIn: string, bottomOut: string, duration: number): AnimationTriggerMetadata {
    return trigger('floatInOut', [
        state('in', style({
            bottom: bottomIn
        })),
        state('out', style({
            bottom: bottomOut,
        })),
        transition('in => out', [
            animate(`${duration}ms 200ms linear`)
        ]),
        transition('out => in', [
            animate(`${duration}ms 100ms linear`)
        ])
    ]);
}

function createLeftAbsoluteAnimation(leftIn: string, leftOut: string, duration: number): AnimationTriggerMetadata {
    return trigger('leftAbsolute', [
        state('in', style({
            left: leftIn
        })),
        state('out', style({
            left: leftOut,
        })),
        transition('in => out', [
            animate(`${duration}ms 200ms linear`)
        ]),
        transition('out => in', [
            animate(`${duration}ms 100ms linear`)
        ])
    ]);
}

export {
    createFadeAnimation,
    createExpandShrinkWidth,
    createExpandShrinkHeight,
    createSlideAnimation,
    createFloatAnimation,
    createLeftAbsoluteAnimation,
};
