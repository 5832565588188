import {
    Component,
    Input,
    OnInit,
    ElementRef,
    ViewChild,
    AfterViewInit,
    OnDestroy,
    ChangeDetectorRef
} from '@angular/core';
import { createFadeAnimation } from '../../animation';
import {Subscription} from 'rxjs';
import {
    LocalParticipantService,
    SettingsService,
    ActiveSpeakerService,
    RoomService,
    MediasoupService,
    NotificationService
} from '../../services';
import { DISPLAY_LAYOUT, VIDEO_FADE_DURATION } from '../../constants';
import { LocalParticipant } from '../../models/local-participant.model';
import { SpeakerModel } from '../../models/speaker.model';
import { SpeakingService } from '../../services/speaking.service';
import { permissions } from '../../permissions';
import { PoolService } from '../../services/mediasoup/pool.service';
import { RemoteParticipant } from '../../models/remote-participant.model';
import { OneuiI18nService } from '@vnpt/oneui-i18n';

@Component({
    selector: 'lib-local-streaming',
    templateUrl: './local-streaming.component.html',
    styleUrls: ['./local-streaming.component.less'],
    animations: [ createFadeAnimation(VIDEO_FADE_DURATION) ]
})
export class LocalStreamingComponent implements OnInit, AfterViewInit, OnDestroy {
    participant: LocalParticipant;
    // isOnBreakroom = false;
    // isSwapped = false;
    isShowOption = false;
    dropdownVisible = false;
    score = 10;
    coreColor: string = "";
    wrapperHeight = 'unset';
    followedByWidth = true;
    layoutMode = this.settingsService.getLayoutMode();
    screenObserver: ResizeObserver;
    isSpeaking: boolean = false;
    isHoldSpaceTitle: boolean = false;
    isFullScreen: boolean = false;
    micStatus: boolean = false;
    isJoinedAudio: boolean = false;
    micIconVisible: boolean = false;
    spotLight: boolean = false;
    timeOut: any = null;
    hasPermissionModerator = false;
    isBlockAllAudio = false;
    isBlockAllWebcam = false;
    isWebcamsOnlyForModerator: boolean = false;
    nameAcronym: string = '';
    private subscriptions: Subscription[] = [];
    @ViewChild('streamSector') streamSector: ElementRef;
    @ViewChild('localVideoRef') localVideoRef: ElementRef;
    // @ViewChild('sharingScreenRef') sharingScreenRef: ElementRef;
    @ViewChild('acronym') acronym: ElementRef;
    @Input() miniView = true;
    @Input() isTeacher = false;
    @Input() forceLayoutStatus = false

    constructor(
        private roomService: RoomService,
        private settingsService: SettingsService,
        private localParticipantService: LocalParticipantService,
        private activeSpeakerService: ActiveSpeakerService,
        private mediasoupService: MediasoupService,
        private speakingService: SpeakingService,
        private poolService: PoolService,
        private notification: NotificationService,
        private i18nService: OneuiI18nService,
        private cdRef: ChangeDetectorRef
    ) {
        this.participant = this.localParticipantService.getLocalParticipant();
        this.nameAcronym = this.getNameAcronym();
    }

    ngOnInit(): void {
        // @ts-ignore

        this.screenObserver = new ResizeObserver((entries: any) => {
            const { height } = entries[0].contentRect;
            this.wrapperHeight = `${height}px`;
            this.cdRef.detectChanges();
        });
        
        //Ktra trang thai WebcamsOnlyForModerator. True: disable button pin
        this.subscriptions.push(this.settingsService.onWebcamsOnlyForModerator().subscribe((flag: boolean) =>{
            this.isWebcamsOnlyForModerator = flag;
        }));

        this.subscriptions.push(this.localParticipantService.onDisplayName().subscribe(()=>{
                this.nameAcronym = this.getNameAcronym();
        }));

        this.subscriptions.push(this.settingsService.onChangeLayoutMode().subscribe(layout => {
            this.layoutMode = layout;
        }));
        this.subscriptions.push(this.speakingService.activeSpeakerObserver.subscribe((speakingList: SpeakerModel[]) => {
            this.isSpeaking = speakingList.find(item => item.id == this.participant.id) != undefined;
        }));

        this.subscriptions.push(this.activeSpeakerService.onSpotlight().subscribe((spot: any) => {
            if(spot){
                this.spotLight = true;
            } 
            else {
                this.spotLight = false;
            }
        }))
        
        this.subscriptions.push(this.settingsService.onJoinAudio().subscribe(status => {
            this.isJoinedAudio = status;
        }));  

        this.subscriptions.push(this.settingsService.onIsSpaceHoldSubject().subscribe(flag => {
            this.micStatus = !flag;
            if(!this.micStatus) {
             this.micIconVisible = true;
             clearTimeout(this.timeOut);   
            }
            else {
              this.timeOut =  setTimeout(() => {
                    this.micIconVisible = false;
                    clearTimeout(this.timeOut);
                }, 2000);
            }
        }));

        this.subscriptions.push(this.localParticipantService.onRoles().subscribe((permission) => {
            if (!permission) { return; }
            this.hasPermissionModerator = this.roomService.hasPermission(permissions.MODERATE_ROOM);
            this.isBlockAllAudio = !this.roomService.hasPermission(permissions.SHARE_AUDIO);
            this.isBlockAllWebcam = !this.roomService.hasPermission(permissions.SHARE_VIDEO);
        }));
    }

    ngAfterViewInit(): void {
        const sub = this.localParticipantService.onLocalWebcamMediaStream().subscribe((videoStream: MediaStream) => {
            // HIDDEN FEATURE: (BREAKOUT ROOM with SHARE SCREEN)
            // Hide video and screen share swapping method 
            //  ----------------------------- 
            // if (this.isSwapped) {
            //     this.publishLocalVideo(this.sharingScreenRef.nativeElement, videoStream);
            // } else {
            //     if (!videoStream) { return; }
            //     this.publishLocalVideo(this.localVideoRef.nativeElement, videoStream);
            // }

            if (!videoStream) { return; }
            this.publishLocalVideo(this.localVideoRef.nativeElement, videoStream);
        });
        const shareSub = this.localParticipantService.onShareLocalScreen().subscribe((videoStream: MediaStream) => {
            if (!videoStream) {
                if (this.screenObserver) { this.screenObserver.disconnect(); }
                // HIDDEN FEATURE: (BREAKOUT ROOM with SHARE SCREEN)
                // Hide video and screen share swapping method 
                //  ----------------------------- 
                // if (this.isOnBreakroom && this.isSwapped) {
                //     this.isSwapped = false;
                //     const webcamStream = this.sharingScreenRef.nativeElement.srcObject;
                //     this.publishLocalVideo(this.localVideoRef.nativeElement, webcamStream);
                // }
                return;
            }
            // this.publishShareScreen(this.sharingScreenRef.nativeElement, videoStream);
        });
        this.subscriptions.push(sub);
        this.subscriptions.push(shareSub);

        this.mediasoupService.onUpdateLocalVideoScore().subscribe(data => {
            if (!data) { return; }
            const { producerId, score } = data;
            if (!producerId || producerId !== this.localParticipantService.getLocalParticipant().webcam.producerId || !score) { return; }
            if((this.score >= 10 && score < 10) || (this.score >=7 && score < 7) || (this.score >= 4 && score < 4)) {
                this.notification.error(this.i18nService.translate("local-streaming.connectWarning",{},
                "Chất lượng mạng của bạn không ổn định"), '');
            }
            this.score = score;
            this.coreColor = this.getScoreColor();
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
        this.subscriptions = [];
        if (this.screenObserver) { this.screenObserver.disconnect(); }
    }

    isShowSpotlight(): boolean {
        return this.layoutMode === 'speaker' || this.layoutMode === 'sidebyside';
    }

    publishLocalVideo(element, videoStream: MediaStream): void {
        element.srcObject = videoStream;
        
    }

    publishShareScreen(element, videoStream: MediaStream): void {
        element.srcObject = videoStream;
        element.muted = true;
        if (element === this.localVideoRef.nativeElement) {
            this.wrapperHeight = 'unset';
            if (this.screenObserver) { this.screenObserver.disconnect(); }
        } else {
            const { width, height } = videoStream.getVideoTracks()[0].getSettings();
            if (width < height) { this.followedByWidth = false; }
            this.screenObserver.observe(element);
        }
    }

    toggleMic(): void {
        this.participant.audio.state ? this.roomService.muteMic() :
                                       this.roomService.producerAudioStart();
    }

    async toggleVideo(): Promise<void> {
        this.participant.webcam.state ? await this.roomService.disableWebcam() :
                                        await this.roomService.producerVideoStart();
    }

    async expandVideo(): Promise<void> {
        const local = this.streamSector;
        if (local) {
            if (local.nativeElement.requestFullscreen) {
                local.nativeElement.requestFullscreen();
              } else if (local.nativeElement.mozRequestFullScreen) {
                local.nativeElement.mozRequestFullScreen();
              } else if (local.nativeElement.webkitRequestFullscreen) {
                local.nativeElement.webkitRequestFullscreen();
              } else if (local.nativeElement.msRequestFullscreen) {
                local.nativeElement.msRequestFullscreen();
              }
            local.nativeElement.onfullscreenchange = ()=>  {
                // this.isFullScreen = document.fullscreenElement == null ? false : true;
                if(document.fullscreenElement == null) {
                    this.isFullScreen = false;
                }
                else {
                    this.isFullScreen = true;
                }
            }
            /* Chrome, Safari and Opera syntax */
            local.nativeElement.onwebkitfullscreenchange = ()=>  {
                // this.isFullScreen = (document as any).webkitFullscreenElement == null ? false : true;
                if((document as any).webkitFullscreenElement == null) {
                    this.isFullScreen = false;
                }
                else {
                    this.isFullScreen = true;
                }
            }
            /* IE/Edge syntax */;
            local.nativeElement.onmsfullscreenchange = ()=>  {
                // this.isFullScreen = (document as any).msFullscreenElement == null ? false : true;
                if((document as any).msFullscreenElement == null) {
                    this.isFullScreen = false;
                }
                else {
                    this.isFullScreen = true;
                }
            }
            navigator.mediaDevices.getUserMedia({audio: true}).then(async(stream) => {
                const listDevice = await navigator.mediaDevices.enumerateDevices();
                const listSpeaker = listDevice.filter(item => item.kind == "audioinput" && item.deviceId != "communications" && item.deviceId != "");
                this.isHoldSpaceTitle = this.settingsService.getIsAudioMuted() && !this.settingsService.getIsAudioBlocked() && !!(listSpeaker.length > 0);
                var timeout = setTimeout(() => {
                this.isHoldSpaceTitle = false;
                clearTimeout(timeout);
            }, 5000);
            stream.getTracks().forEach(track=> {
                track.stop();
            })
            }).catch((reason) => {});
            
        }
    }

    closeFullScreen(): void {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if ((document as any).webkitExitFullscreen) {
            (document as any).webkitExitFullscreen();
        } else if ((document as any).mozCancelFullScreen) {
            (document as any).mozCancelFullScreen();
        } else if ((document as any).msExitFullscreen) {
            (document as any).msExitFullscreen();
        }
    }

    expandShare(): void {
        //Ktra truong hop co force layout: User ko phai moderator se khong thuc hien dc thao tac
        if(!this.hasPermissionModerator && this.roomService.getForceLayout.layout != 0) return;
        const layoutMode = this.settingsService.getLayoutMode();
        // HIDDEN FEATURE: (BREAKOUT ROOM with SHARE SCREEN)
        // Hide video and screen share swapping method 
        //  ----------------------------- 
       
        if (layoutMode){

            if (this.roomService.getForceLayout.layout != 0) {
                let layoutId = DISPLAY_LAYOUT.find(layout => layout.name == 'sidebyside').id;
                let {pinners, hideNoneCamera } = this.roomService.getForceLayout;
                this.roomService.setForceLayout(layoutId, pinners, hideNoneCamera);
                this.roomService.changeForceLayout(layoutId, pinners, hideNoneCamera);
            } else {
                this.settingsService.changeLayoutMode('sidebyside');
            }
        } 
        this.activeSpeakerService.setActiveSpeaker(this.localParticipantService.getLocalParticipant());
    }

    changeDisplayName(): void {
        this.settingsService.setDisplayNameChange(true);
    }

    // toggleSpotLight(): void {
    //     this.activeSpeakerService.setActiveSpeaker(this.localParticipantService.getLocalParticipant());
    //     if(!this.activeSpeakerService.getSpotlight() || this.activeSpeakerService.getSpotlight().id !=  this.localParticipantService.getLocalParticipant().id)
    //     this.activeSpeakerService.setSpotlight(this.localParticipantService.getLocalParticipant());
    //     else {
    //         this.activeSpeakerService.setSpotlight(null);
    //     }
    // }
    
    getScoreColor(): string {
        switch (this.score) {
            case 0:
            case 1:
            case 2:
            case 3:
                return 'icon-error';
            case 4:
            case 5:
            case 6:
                return 'icon-orange';
            case 7:
            case 8:
            case 9:
                return 'icon-warning';
            case 10:
                return '';
        }
    }
    
    getNameAcronym(): string {
        const name = this.localParticipantService.getLocalParticipant().displayName.toString();
        if(name.lastIndexOf(' ') < 0){
            return name.charAt(0)
        }
        return name.charAt(0) + name.charAt(name.lastIndexOf(' ')+1);
    }

        //bo sung tinh nang pin user local khi bat force layout
        pinCameraHandler():void{
            let layoutInfo = DISPLAY_LAYOUT.find(layout => layout.id == this.roomService.getForceLayout.layout);
    
            let isPin = !this.participant.isPin;
            if(this.roomService.getForceLayout && (layoutInfo.id != 0) && this.hasPermissionModerator ){
    
                let pinners = Array.from(this.poolService.getRemotesPin.map((remote) => remote.id));
    
                let remotes = this.poolService.getRemotesPin;
                let remote = new RemoteParticipant(this.localParticipantService.getLocalParticipant(), null, null)
                if(pinners.length == layoutInfo.maxPin && !this.participant.isPin){
                    let fr = remotes.shift();
                    pinners.shift();
                    fr.isPin = !isPin;
                }
                if(isPin){
                    pinners.push(this.participant.id);
                    remotes.push(remote);
                }else{
                    remotes = remotes.filter(item => item.id !== remote.id);
                    pinners = Array.from(remotes.map((remote) => remote.id))
                }
                this.participant.isPin = isPin;
                this.poolService.setRemotesPin(remotes);
                this.roomService.moderatorPin(pinners);
                
            }
        }

}
