import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SecurityService{
    constructor() {}
    private processModeratorMuteAllExceptPresenterSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private processModeratorBlockAllAudio: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private processModeratorLockRoom: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private processModeratorBlockAllVideo: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private processModeratorBlockAllShareScreen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private processWebcamsForModerator: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private processModeratorStopAllWebcam: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private processModeratorStopAllAudio: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private processModeratorStopAllShareScreen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private processModeratorRejectAllPeers: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private processModeratorPromoteAllPeers: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private processModeratorManageRole: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private processPinCamer: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private moderatorLockRoomSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private infoModalSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public setProcessingPinCamer(flag: boolean): void{
        this.processPinCamer.next(flag);
    }

    public onProcessingPinCamer(): Observable<boolean>{
        return this.processPinCamer.asObservable();
    }

    public setProcessingModeratorManageRole(flag: boolean): void{
        this.processModeratorManageRole.next(flag);
    }

    public onProcessingModeratorManageRole(): Observable<boolean>{
        return this.processModeratorManageRole.asObservable();
    }
    
    public setProcessingPromoteAllPeers(flag: boolean): void{
        this.processModeratorPromoteAllPeers.next(flag);
    }

    public onProcessingPromoteAllPeers(): Observable<boolean>{
        return this.processModeratorPromoteAllPeers.asObservable();
    }
    
    public setProcessingRejectAllPeers(flag: boolean): void{
        this.processModeratorRejectAllPeers.next(flag);
    }

    public onProcessingRejectAllPeers(): Observable<boolean>{
        return this.processModeratorRejectAllPeers.asObservable();
    }
    
    public setProcessingMuteAllExceptPresenter(flag: boolean): void{
        this.processModeratorMuteAllExceptPresenterSubject.next(flag);
    }

    public onProcessingMuteAllExceptPresenter(): Observable<boolean>{
        return this.processModeratorMuteAllExceptPresenterSubject.asObservable();
    }
    
    public setProcessingBlockAllAudio(flag: boolean): void{
        this.processModeratorBlockAllAudio.next(flag);
    }

    public onProcessingBlockAllAudio(): Observable<boolean> {
        return this.processModeratorBlockAllAudio.asObservable();
    }

    public setProcessingBlockAllVideo(flag: boolean): void{
        this.processModeratorBlockAllVideo.next(flag);
    }

    public onProcessingBlockAllVideo(): Observable<boolean> {
        return this.processModeratorBlockAllVideo.asObservable();
    }

    public setProcessingBlockAllShareScreen(flag: boolean): void{
        this.processModeratorBlockAllShareScreen.next(flag);
    }
    
    public onProcessingBlockAllShareScreen(): Observable<boolean> {
        return this.processModeratorBlockAllShareScreen.asObservable();
    }

    public setProcessingWebcamsForModerator(flag: boolean): void{
        this.processWebcamsForModerator.next(flag);
    }
    
    public onProcessingWebcamsForModerator(): Observable<boolean> {
        return this.processWebcamsForModerator.asObservable();
    }

    public setProcessingModeratorStopAllWebcam(flag: boolean): void{
        this.processModeratorStopAllWebcam.next(flag);
    }
    
    public onProcessingModeratorStopAllWebcam(): Observable<boolean> {
        return this.processModeratorStopAllWebcam.asObservable();
    }
    public setProcessingModeratorStopAllAudio(flag: boolean): void{
        this.processModeratorStopAllAudio.next(flag);
    }
    
    public onProcessingModeratorStopAllAudio(): Observable<boolean> {
        return this.processModeratorStopAllAudio.asObservable();
    }

    public setProcessingModeratorStopAllShareScreen(flag: boolean): void{
        this.processModeratorStopAllShareScreen.next(flag);
    }
    
    public onProcessingModeratorStopAllShareScreen(): Observable<boolean> {
        return this.processModeratorStopAllShareScreen.asObservable();
    }

    public setProcessingLockRoom(flag: boolean): void{
        this.processModeratorLockRoom.next(flag);
    }

    public onProcessingLockRoom(): Observable<boolean> {
        return this.processModeratorLockRoom.asObservable();
    }


    public setLockRoom(flag: boolean): void {
        this.moderatorLockRoomSubject.next(flag);
    }

    public onLockRoom(): Observable<boolean>{
        return this.moderatorLockRoomSubject.asObservable();
    }

    public setInfoModal(flag: boolean): void {
        this.infoModalSubject.next(flag);
    }

    public onInfoModal(): Observable<boolean> {
        return this.infoModalSubject.asObservable();
    }
}
