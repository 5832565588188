import { Injectable, Optional } from '@angular/core';
import io, { Socket, Manager } from 'socket.io-client';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { EdumeetRoomConfigModel } from '../models/edumeet-room-config.model';
import { config } from '../../config';
import { Transport } from 'mediasoup-client/lib/Transport';
import { SocketService } from './socket.service';
import { LocalParticipantService } from './local-participant.service';
import { SettingsService } from './settings.service';
import deviceInfo from '../deviceInfo';

type IOSocket = typeof Socket & { request: (method: string, data?: any) => Promise<any> };

const FILTER_AUDIO_STATS = [
    'outbound-rtp',
    'inbound-rtp',
    'candidate-pair',
    'local-candidate',
    'transport',
];
const PROBES = 3;
const TYPE_MONITOR = {
    AUDIO: 'audio',
    SOCKET: 'socket'
};

@Injectable({
    providedIn: 'root'
})
export class LatencyService {
    private socket: any;//IOSocket;
    private isConnected: boolean = false;
    private roomReady: boolean = false;
    private eduMeetRoomConfig: EdumeetRoomConfigModel;
    private eventList: Map<string, void> = new Map<string, void>();
    private streamMap: Map<string, Transport> = new Map<string, Transport>();
    private interval: ReturnType<typeof setInterval>;

    private previousDataNetwork = null;
    private previousConsumerDataNetwork = null;
    private _device: any;

    constructor(
        private settingService: SettingsService,
        private socketService: SocketService,
        private localParticipantService: LocalParticipantService,
        @Optional() eduMeetRoomConfig?: EdumeetRoomConfigModel) {
        this.eduMeetRoomConfig = eduMeetRoomConfig;
        this.eventList = new Map([
            ["open", () => { this.startInterval(); }], // console.log.bind(null, "[LATENCY WSS] connected")],
            ["close", () => {}], // console.log.bind(null, "[LATENCY WSS] closed")],
            ["error", console.log],
            ["message", this.onWsMessage.bind(this)],
        ]);
        this._device = deviceInfo();
    }

    public async connect({sessionToken}): Promise<void> {
        // const url = `${this.eduMeetRoomConfig.domain}`${config.latency.wsUrl}/?sessionToken=${sessionToken}`
        console.log("socket is established");
        this.socketService.onSocket('connect', async () => {
            // console.log("socket is connected");
            this.isConnected = true;
            this.clearAllSubscribtion();
        });
        this.socketService.onSocket('disconnect', (reason) => {
            this.isConnected = false;
        });
        this.socketService.onSocket('notification', async (notification: { data: any, method: string }) => {
            if (notification.method == 'roomReady') {
                // console.log("# roomReady #");
                this.roomReady = true;
            }
        });

        const { latencyWsUrl } = await this.settingService.getAppConfig();
        if (!this.socket) {
            console.log("LatencyWs is established");
            let urlLatency = new URL(latencyWsUrl);
            urlLatency.searchParams.set("sessionToken", sessionToken);
            this.socket = new ReconnectingWebSocket(urlLatency.toString(),
                [config.latencyConfigs.secretKey],
                { connectionTimeout: 15000 }
            );
            this.eventList.forEach((fn, key) => {
                this.socket.addEventListener(key, fn);
            });
        }
        // this.startInterval();
    }

    getSocket(): IOSocket {
        return this.socket;
    }

    async sendRequest(data: any): Promise<any> {
        if (!this.socket) return;
        return this.socket.send(JSON.stringify(data));
    }

    onWsMessage(message) {
        const parsedMessage = JSON.parse(message.data);
        if (parsedMessage.id === 'pong') {
        //   const current = new Date().getTime();
        //   const start = parsedMessage.time;
        //   const res = current - start;
        //   console.log('res', res);
        }
    }

    stopConnection() {
        this.socket.close();
        ["open", "close", "error", "message"].forEach(event => {
            this.socket.removeEventListener(event);
        });
    }

    subscribe(type: string, transport: Transport) {
        // console.log("# subscribe:", type, transport.id);

        if (type === TYPE_MONITOR.AUDIO) this.streamMap.set(transport.id, transport);
        // esle this.socketMap.set()
        if (!this.interval && this.socket) {
            this.startInterval();
        }
    }

    unSubscribe(type: string, transport: Transport) {
        if (!this.streamMap.has(transport.id)) return;
        this.streamMap.delete(transport.id);
        if (this.streamMap.size <= 0) {
            this.stopInterval();
        }
    }

    clearAllSubscribtion() {
        this.streamMap.clear();
    }

    startInterval() {
        if (this.socket && this.socket.readyState == 1) {
            this.stopInterval();
            this.interval = setInterval(this.run.bind(this), 5000);
        }
    }

    stopInterval() {
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }
    }

    run() {
        // console.log("### run rtt monitor", this.isConnected, this.roomReady);
        if (!this.isConnected || !this.roomReady) return;
        this.collectSocketRtt((data) => {
            this.sendRequest({...data, id: "monitor", type: TYPE_MONITOR.SOCKET, domain: window.location.origin, platform: {
                "type": this._device.platform, 
                "os": this._device.os, 
                "software": this._device.name,
                "version": this._device.version
            }});
        });
        for (let stream of this.streamMap.values()) {
            this.collect(stream, (data: MonitorData) => {
                this.sendRequest({...data, id: "monitor", type: TYPE_MONITOR.AUDIO, domain: window.location.origin, platform: {
                    "type": this._device.platform, 
                    "os": this._device.os, 
                    "software": this._device.name,
                    "version": this._device.version
                }});
            });
        }
    }

    async collectSocketRtt(callback) {
        let t0 = Date.now();
        // console.log("##this.socketService", this.socketService.getSocket());

        // await this.roomService.ping()
        this.socketService.sendRequest("ping", null).then(async () => {
            let tf = Date.now();
            let rtt = tf - t0;
            let logDate = this.getLogDate();
            callback(await this.createMonitorModel(logDate, rtt));
        });
    }

    private collect(conn: Transport, callback: Function) {
        let stats = [];
        let statsRemote = [];
        let runnable;
      
        conn.getStats().then(async (results) => {
            if (!results) {
              console.log("### connection is closed");
              this.previousDataNetwork = null;
              this.previousConsumerDataNetwork = null;
            }
            
            let inboundRTP;
            let remoteInboundRTP;
            let dataRate;
            let stat;
            
            results.forEach(res => {
              // console.log('res.type', res.type);
              if(res.kind != 'audio') return;
              if (FILTER_AUDIO_STATS.includes(res.type)) {
                if (!stat) {
                  stat = {};
                }
                stat[res.id] = res;
                // console.log('stat', stat);
              }
              
              switch (res.type) {
                case 'inbound-rtp':
                    inboundRTP = res;
                    break;
                case 'remote-inbound-rtp':
                case 'outbound-rtp':
                    remoteInboundRTP = res;
                    break;
                // case 'remote-outbound-rtp':
                //     inboundRTP = res;
                //     break;
                default:
                    break;
              }
            });
            //Kiểm tra là luồng nhận hay gửi để update lại biến dữ liệu network cho phù hợp
            if (inboundRTP) {
                if (this.previousConsumerDataNetwork) {
                    dataRate = this.calculateBitsPerSecond(stat, this.previousConsumerDataNetwork);
                }
                this.previousConsumerDataNetwork = stat;
                // console.log("# save ConsumerDataNetwork", Object.values(this.previousConsumerDataNetwork));
            }
            if (remoteInboundRTP) {
                if (this.previousDataNetwork) {
                    dataRate = this.calculateBitsPerSecond(stat, this.previousDataNetwork);
                }
                this.previousDataNetwork = stat;
                // console.log("# save DataNetwork", Object.values(this.previousDataNetwork));
            }

            if (!dataRate || dataRate.inbound < 0 || dataRate.outbound < 0) {
                /* console.warn("[latency] no data collected", {
                    stat,
                    prevStat: inboundRTP ? this.previousConsumerDataNetwork : this.previousDataNetwork,
                    dataRate
                }); */
                return;
            }

            stats.push(this.buildData(inboundRTP || remoteInboundRTP));
            while (stats.length > PROBES) stats.shift();
    
            const interval = this.calculateInterval(stats);
            // console.log('interval ', interval);
            const result = this.buildResult(interval);
            var resultRemote = null;
            if (remoteInboundRTP) {
                statsRemote.push(this.buildData(remoteInboundRTP));
                while (statsRemote.length > PROBES) statsRemote.shift();
                const intervalRemote = this.calculateInterval(statsRemote);
                resultRemote = this.buildResult(intervalRemote);
            }
            const logDate = this.getLogDate();
            let inbound = { jitter: result.jitter, loss: result.loss, MOS: result.MOS, rate: dataRate ? dataRate.inbound : null };
            //Bổ sung dữ liệu cho remoteInboundRTP thông qua các biến dataRemote và resultRemote
            if (remoteInboundRTP && !remoteInboundRTP.rate) {
                remoteInboundRTP.rate = dataRate ? dataRate.outbound : null;
                remoteInboundRTP.loss = resultRemote.loss
            }
            callback(await this.createMonitorModel(logDate, null, inbound, remoteInboundRTP));
            // setTimeout(monitor, INTERVAL, conn, stats);
        }).catch(error => {
            console.error(
              {
                logCode: 'stats_get_stats_error',
                extraInfo: { error }
              },
              'WebRTC stats not available'
            );
        });
    }

    private getLogDate() {
        const time = new Date();
        return `${time.getFullYear().toString().padStart(4, '000')}-${(time.getMonth()+1).toString().padStart(2, '0')}-${time.getDate().toString().padStart(2, '0')} ${time.getHours().toString().padStart(2, '0')}:${time.getMinutes().toString().padStart(2, '0')}:${time.getSeconds().toString().padStart(2, '0')}`
    }

    private calculateBitsPerSecond(currentData, previousData) {
        const result = {
            outbound: 0,
            inbound: 0,
        };
    
        const getDataType = (data: any, type: string) => {
            if (!data || typeof data !== 'object' || !type) return [];
            // console.log('data ', Object.values(data));
            return Object.values(data).filter((stat: any) => stat.type === type)
                .sort((a: any, b: any) => parseInt(b.mid) - parseInt(a.mid));
        };
        if (!currentData || !previousData) return result;
        // console.log('currentData', currentData);
        // console.log('previousData', previousData);
        const currentOutboundData: any = getDataType(currentData, 'outbound-rtp')[0];
        const previousOutboundData: any = currentOutboundData ? previousData[currentOutboundData.id] : null;

        const currentInboundData: any = getDataType(currentData, 'inbound-rtp')[0];
        const previousInboundData: any = currentInboundData ? previousData[currentInboundData.id] : null;

        if (currentOutboundData && previousOutboundData) {
            const {
                bytesSent: outboundBytesSent,
                timestamp: outboundTimestamp,
            } = currentOutboundData;
        
            let {
                headerBytesSent: outboundHeaderBytesSent,
            } = currentOutboundData;
        
            if (!outboundHeaderBytesSent) outboundHeaderBytesSent = 0;
        
            const {
                bytesSent: previousOutboundBytesSent,
                timestamp: previousOutboundTimestamp,
            } = previousOutboundData;
        
            let previousOutboundHeaderBytesSent = previousOutboundData.headerBytesSent || 0;
        
            // if (!previousOutboundHeaderBytesSent) previousOutboundHeaderBytesSent = 0;
        
            const outboundBytesPerSecond = (outboundBytesSent + outboundHeaderBytesSent - previousOutboundBytesSent - previousOutboundHeaderBytesSent)/(outboundTimestamp - previousOutboundTimestamp);

            result.outbound = Math.max(Math.round((outboundBytesPerSecond * 8 * 1000) / 1024), 0);
        }
    
        if (currentInboundData && previousInboundData) {
            const {
                bytesReceived: inboundBytesReceived,
                timestamp: inboundTimestamp,
            } = currentInboundData;
        
            let {
                headerBytesReceived: inboundHeaderBytesReceived,
            } = currentInboundData;
        
            if (!inboundHeaderBytesReceived) inboundHeaderBytesReceived = 0;
        
            const {
                bytesReceived: previousInboundBytesReceived,
                timestamp: previousInboundTimestamp,
            } = previousInboundData;
        
            let {
                headerBytesReceived: previousInboundHeaderBytesReceived,
            } = previousInboundData;
        
            if (!previousInboundHeaderBytesReceived) {
                previousInboundHeaderBytesReceived = 0;
            }
        
            const inboundBytesPerSecond = (inboundBytesReceived
                + inboundHeaderBytesReceived - previousInboundBytesReceived
                - previousInboundHeaderBytesReceived)
                / (inboundTimestamp - previousInboundTimestamp);

            result.inbound = Math.max(Math.round((inboundBytesPerSecond * 8 * 1000) / 1024), 0);
        }
        return result;
    }

    private buildData(inboundRTP) {
        return {
        packets: {
            received: inboundRTP.packetsReceived,
            lost: inboundRTP.packetsLost
        },
        bytes: {
            received: inboundRTP.bytesReceived
        },
        jitter: inboundRTP.jitter,
        };
    }
  
    private buildResult(interval) {
        const rate = this.calculateRate(interval.packets);
        return {
            packets: {
                received: interval.packets.received,
                lost: interval.packets.lost
            },
            bytes: {
                received: interval.bytes.received
            },
            jitter: interval.jitter,
            rate: rate,
            loss: this.calculateLoss(rate),
            MOS: this.calculateMOS(rate)
        };
    }

    private calculateInterval(stats) {
        // console.log("### calculateInterval", stats);

        const single = stats.length === 1;
        const first = stats[0];
        const last = stats[stats.length - 1];
        const diff = (single, first, last) => Math.abs((single ? 0 : last) - first);

        return {
            packets: {
                received: diff(single, first.packets.received, last.packets.received),
                lost: diff(single, first.packets.lost, last.packets.lost)
            },
            bytes: {
                received: diff(single, first.bytes.received, last.bytes.received),
            },
            jitter: Math.max.apply(Math, stats.map(s => s.jitter))
        };
    };

    private calculateRate(packets) {
        const { received, lost } = packets;
        const rate = (received > 0) ? ((received - lost) / received) * 100 : 100;
        if (rate < 0 || rate > 100) return 100;
        return rate;
    };

    private calculateLoss(rate) {
        return 1 - (rate / 100);
    };

    private calculateMOS(rate) {
        return 1 + (0.035) * rate + (0.000007) * rate * (rate - 60) * (100 - rate);
    };

    private async createMonitorModel(logDate, rtt, inbound?, remoteInboundRTP?, type?) {
        let { meetingID: meetingId, name: meetingName } = await this.settingService.getAppConfig();
        let userId = this.localParticipantService.getPeerId();
        let username = this.localParticipantService.getDisplayName();
        return new MonitorData({
            meetingId, meetingName, userId, username,
            logDate, inbound, remoteInboundRTP, rtt, type
        });
    }
}

class MonitorData {
    logDate;
    meetingId;
    meetingName;
    userId;
    username;
    jitter;
    loss;
    MOS;
    rjitter;
    rloss;
    rMOS;
    rtt;
    type;
    arec;
    asent;

    constructor({ meetingId, meetingName, userId, username, logDate, inbound, remoteInboundRTP, rtt, type }) {
        this.logDate = logDate;
        this.meetingId = meetingId;
        this.meetingName = meetingName;
        this.userId = userId;
        this.username = username;
        this.jitter = inbound ? inbound.jitter : null;
        this.loss = inbound ? inbound.loss : null;
        this.MOS = inbound ? inbound.MOS : null;
        this.rjitter = remoteInboundRTP ? remoteInboundRTP.jitter : null;
        this.rloss = remoteInboundRTP ? remoteInboundRTP.loss : null;
        this.rMOS = remoteInboundRTP ? remoteInboundRTP.MOS : null;
        this.rtt = rtt;
        this.type = type;
        this.arec = inbound ? inbound.rate : null;
        this.asent = remoteInboundRTP ? remoteInboundRTP.rate : null;
    }

    toString() {
        return JSON.stringify(this);
    }
}
