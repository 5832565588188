import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { permissions } from '../../permissions';
import { LocalParticipantService } from '../../services/local-participant.service';
import { SettingsService } from '../../services/settings.service';
import { NotificationService, RoomService, SecurityService } from '../../services';
import { OneuiI18nService } from '@vnpt/oneui-i18n';
import { PoolService } from '../../services/mediasoup/pool.service';

@Component({
  selector: 'lib-more-options',
  templateUrl: './more-options.component.html',
  styleUrls: ['./more-options.component.less']
})
export class MoreOptionsComponent implements OnInit, OnDestroy {

  constructor(
    private localParticipantService: LocalParticipantService,
    private settingsService: SettingsService,
    private roomService: RoomService,
    private i18nService: OneuiI18nService,
    private notification: NotificationService,
    private poolService : PoolService,
    private securityService: SecurityService
  ) { }

  hasPermissionModerator = false;
  subscriptions: Subscription[] = [];
  isConfigDuration: boolean = false;
  @Output() pooper = new EventEmitter<any>();


  ngOnInit(): void {
    this.onInitEvent();
  }

  onInitEvent(): void {
    this.localParticipantService.onRoles().subscribe((roles: number[]) => {
      this.hasPermissionModerator = this.roomService.hasPermission(permissions.MODERATE_ROOM);
    });

    if (this.hasPermissionModerator && !this.roomService.getIsExtended() && this.roomService.getTimeLeftRoom() > 0) {
      this.isConfigDuration = true;
    }

    this.subscriptions.push(this.roomService.onMeetingTimeout().subscribe((obj: { timeLeft: number, message: string }) => {
      if (!obj) return;
      if (obj.timeLeft > 0) {
        if (this.hasPermissionModerator && !this.roomService.getIsExtended() && this.roomService.getTimeLeftRoom() > 0) {
          this.isConfigDuration = true;
        }
      }
    }));
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  changeDisplayName(): void {
    this.settingsService.setDisplayNameChange(true);
    this.pooper.emit();
  }

  changeRoomName(): void {
    this.settingsService.setRoomNameChange(true);
    this.pooper.emit();
  }

  reconnectAudio(): void {
    this.pooper.emit();
    this.roomService.joinAudio().then(() => {
      this.notification.info(this.i18nService.translate("notification.more.reconnected", {},
        "Đã kết nối lại âm thanh"), '');
    });
  }

  durationOpen(): void {
    let message = this.i18nService.translate("duration.title", {}, 'Vui lòng chọn thời gian cần gia hạn');
    this.settingsService.setIsDurationOpenSubject({ visible: true, message, callback: null });
    this.pooper.emit();
  }

  async openSupport(): Promise<void> {
    const appConfig = await this.settingsService.getAppConfig();
    window.open(appConfig.supportLink, '_blank');
    this.pooper.emit();
  }

  openFeedback(): void {
    let message = this.i18nService.translate("feedback.title", {}, 'Đóng góp ý kiến');
    this.settingsService.setIsFeedbackOpenSubject({ visible: true, message, callback: null });
    this.pooper.emit();
  }

  openReport(): void {
    let message = this.i18nService.translate("report.title", {}, 'Báo cáo sự cố');
    this.settingsService.setIsReportOpenSubject({ visible: true, message });
    this.pooper.emit();
  }
  openInfo(): void {
    this.securityService.setInfoModal(true);
  }

  exportPeers(): void {
    this.poolService.exportPeers();
    this.pooper.emit();
  }
}
