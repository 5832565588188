<vnpt-card class="card-holder" [vBodyStyle]="bodystyle" [vTitle]="cardTitle" [vExtra]="back">
    <lib-chatbox-list class="chatBox"></lib-chatbox-list>
    <lib-chat-input (pushLocalChat)="pushChatMessage($event)" (pushError)="pushDeleteErrorMessage()" class="input-container"></lib-chat-input>
</vnpt-card>

<ng-template #cardTitle>
    <div class="title-holder">
        <button vnpt-button [attr.data-badge]="unseenMessage"
            class="active-chat {{unseenMessage != 0 ? 'unread-message' : ''}}"
            vnpt-dropdown [vDropdownMenu]="menuChat" [vOverlayStyle]="{display: listActiveList.length > 0 ? 'flex' : 'none', justifyContent: 'flex-end'}"
            vPlacement="bottomRight" vTrigger="click">
            <!-- <i class="icon-gomeet-chat2 icon"></i> -->
            <span class="card-title">{{"chatting.to" | vI18n:{receiverName:currentActiveTitle}:currentActiveTitle}}</span>
            <i *ngIf="listActiveList.length > 0" class="icon-gomeet-chevron-down icon"></i>
        </button>
        <vnpt-dropdown-menu #menuChat>
            <ul vnpt-menu vSelectable class="dropdown">
                <li vnpt-menu-item *ngFor="let element of listActiveList" (click)="openChat(element.id)"
                    class="dropdown-item {{element.data.unreadMessageCount == 0 ? 'active-list' : 'badge'}}"
                    [attr.data-badge]="element.data.unreadMessageCount > 9 ? '9+' : element.data.unreadMessageCount">
                    {{element.data.topicName}}</li>
            </ul>
        </vnpt-dropdown-menu>
    </div>


    <!-- <vnpt-select [ngModel]="topic" (ngModelChange)="openChat($event)" vOption="listActiveList">
        <vnpt-option *ngFor="let p of listActiveList" vValue="{{p.id}}" vLabel="{{p.data.topicName}}">{{p.data.topicName}}</vnpt-option>
    </vnpt-select> -->
</ng-template>

<ng-template #back>
    <div class="extra-buttons">
        <!-- <a [class]="openFilter ? 'filter-actived filter' : 'filter'"  vTrigger="click" (click)="fopenFilter()"vnpt-tooltip vTooltipTitle="{{ 'chatting.filter' | vI18n: {}:'Lọc tin nhắn' }}">
            <i vnpt-icon vType="Filter" class="icon-filter" ></i>
        </a> -->

        <!-- <vnpt-select [(ngModel)]="keyFilter" [vOptions]="listOfOption" 
        (ngModelChange)="filter(keyFilter)"
        [vBorderless]="true"></vnpt-select> -->
        <!-- <vnpt-dropdown-menu #menu="vDropdownMenu" >
            <ul vnpt-menu vSelectable id="filterSelect">
            <li vnpt-menu-item  [vSelected]="keyFilter == 'all'" (click)="filter('all')">{{ "chatting.filter.all" | vI18n:{}:"Tất cả" }}</li>
            <li vnpt-menu-item [vSelected]="keyFilter == 'message'" (click)="filter('message')">{{ "chatting.filter.text" | vI18n:{}:"Văn bản" }}</li>
            <li vnpt-menu-item [vSelected]="keyFilter == 'link'" (click)="filter('link')">{{ "chatting.filter.links" | vI18n:{}:"Liên kết" }}</li>
            <li vnpt-menu-item [vSelected]="keyFilter == 'file'" (click)="filter('file')">{{ "chatting.filter.files" | vI18n:{}:"Tập tin" }}</li>
            <li vnpt-menu-item [vSelected]="keyFilter == 'media'" (click)="filter('media')">{{ "chatting.filter.media" | vI18n:{}:"Phương tiện" }}</li>
            </ul>
        </vnpt-dropdown-menu> -->
        <button vnpt-button vType="text" (click)="goBack()" class="active-chat" vnpt-tooltip
            vTooltipTitle="{{ 'list-participant.tabTitle' | vI18n:{}:'' }}" vTooltipPlacement="bottom">
            <vnpt-badge *ngIf="lobbyLength !== 0" [vDot]="lobbyLength" [vStyle]="{ backgroundColor: '#C41A1A'}"
                class="badgeParticipant">
            </vnpt-badge>
            <i class="icon-gomeet-group icon"></i>
        </button>
    </div>
</ng-template>