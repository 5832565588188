<vnpt-modal [vVisible]="visible"
            vTitle="Thông tin phòng họp"
            [vFooter]="null"
            [vClosable]="true"
            (vOnCancel)="handleOk()"
            >
    <ng-container *vModalContent>
    <div class="container">
        <!-- <div class="data-row">
            <span class="room-name" vnpt-col [vSpan]="22" [title]="roomName">{{roomName}}</span>
            <button vnpt-col [vSpan]="2" *ngIf="hasPermissionModerator" vnpt-button vType="default" class="info-btn" (click)="changeRoomName()"
                vnpt-tooltip vTooltipTitle='{{ "room-config.changeName-btn" | vI18n:{}:"Đổi tên" }}' vTooltipPlacement="bottomRight">
                <i vnpt-icon class="icon-white" [vType]="'Edit'" [vSize]="'sm'"></i>
            </button>
        </div> -->
        <div class="data-row">
            <span class="label"style="color: #bdbdbd">{{'room-config.roomName' | vI18n:{}:'Tên cuộc họp'}}</span>
            <span class="value">{{roomName}}</span>
        </div>
        <div class="data-row">
            <span class="label" style="color: #bdbdbd">{{'room-config.startTime' | vI18n:{}:'Thời gian bắt đầu'}}</span>
            <span class="value">{{ startTime | date:'h:mm a dd/MM/yyyy' }}</span>
        </div>
        <div class="data-row" [ngClass]= "{'d-none': roomConfig.startTime == roomConfig.endTime}">
            <span class="label" style="color: #bdbdbd" >{{'room-config.endTime' | vI18n:{}:'Thời gian kết thúc'}}</span>
            <span class="value" >{{ endTime | date:'h:mm a dd/MM/yyyy' }}</span>
            <!-- <button *ngIf="isConfigDuration" vnpt-button vType="default" class="info-btn" (click)="durationOpen()"
        vnpt-tooltip vTooltipTitle='{{ "duration.config.btn" | vI18n:{}:"Gia hạn" }}' vTooltipPlacement="bottomRight">
            <i class="icon-gomeet-add icon-white"></i>
            </button> -->
        </div>
      
        <div class="data-row">
            <span class="label" style="color: #bdbdbd">{{'room-config.accessLink' | vI18n:{}:'Link truy cập'}}</span>
            
        </div>
        <div class="data-row">
            <!-- <span class="value"></span> -->
                <input class="value invite-link" readonly [value]="roomConfig.inviteLink"/>
            <a class="action-link copy" [ngClass]="{'copied': isCopy}" (click)="copyAccess()">
                <i vnpt-icon  [vType]="isCopy? 'SkypeCircleCheck' : 'Copy'" vSize="m"></i>
                <!-- <span>{{isCopy? ('room-config.copied' | vI18n:{}:'Đã sao chép') : ('room-config.copy' | vI18n:{}:'Sao chép')}}</span> -->
            </a>
        </div>
        <div class="data-row">
            <span class="label"style="color: #bdbdbd">{{'room-config.roomId' | vI18n:{}:'ID cuộc họp'}}</span>
            <span class="value">{{roomId}}</span>
        </div>
        <div class="data-row">
            <span class="label"style="color: #bdbdbd; align-self: center;">{{'room-config.accessKey' | vI18n:{}:'Mã truy cập'}}</span>
            <span class="value">{{roomConfig.accessCode}}</span>
        </div>
    </div>
    </ng-container>
</vnpt-modal>
