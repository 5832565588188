export interface ParticipantModel {
    id: string;
    displayName: string;
    color: string;
    kind: string | unknown;
    roles: number[];
    audio: {
        state: boolean;
        producerId: string;
    };
    webcam: {
        state: boolean;
        producerId: string;
    };
    screen: {
        state: boolean;
        producerId: string;
    };
    isSpeaking: boolean;
    isSharing: boolean;
    isActivatingSpeaker: boolean;
    isPin:boolean;
    isRaisedHand: boolean;
    raisedHandTimestamp: number;
    myGroupId: string;
    joinedTimestamp: number;
}

export class Participant implements ParticipantModel {
    id: string = '';
    displayName: string = '';
    color: string = '#456BD9';
    kind: string = '';
    roles: number[] = [];
    audio = {
        state: false,
        producerId: ''
    };
    webcam = {
        state: false,
        producerId: ''
    };
    screen = {
        state: false,
        producerId: ''
    };
    isSpeaking: boolean           = false;
    isSharing: boolean            = false;
    isActivatingSpeaker: boolean  = false;
    isPin: boolean                = false;
    isRaisedHand: boolean         = false;
    raisedHandTimestamp           = 0;
    myGroupId: string             = '';
    joinedTimestamp: number       = 0;

    constructior() {}
}

