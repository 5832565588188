/* tslint:disable:no-console */
import {Injectable, Optional} from '@angular/core';
import io, {Socket} from 'socket.io-client';
import {timeoutCallback} from '../utils';
import {BehaviorSubject, Observable} from 'rxjs';
import {EdumeetRoomConfigModel} from '../models/edumeet-room-config.model';
import { environment } from '../../../../main/src/environments/environment';
import { TimeService, timeService } from './time.service';

type IOSocket = Socket & { request: (method: string, data?: any) => Promise<any> };

@Injectable({
    providedIn: 'root'
})
export class SocketService {
    private socket: IOSocket;
    private isConnectedSubject: BehaviorSubject<boolean> = new BehaviorSubject(null);
    private eduMeetRoomConfig: EdumeetRoomConfigModel;
    private timeLog: TimeService = timeService;
    constructor(@Optional() eduMeetRoomConfig?: EdumeetRoomConfigModel) {
        this.eduMeetRoomConfig = eduMeetRoomConfig;
    }

    public async connect({displayName, sessionToken}): Promise<void> {
        const url = `${environment.domain.socket}/?sessionToken=${sessionToken}`;
        if (this.socket) this.socket.close();
        this.socket = io(url, {
            transports: ['websocket'], // use WebSocket first, if available,
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            reconnectionAttempts: 1000,
            autoConnect: true,
        }) as IOSocket;
        this.socket.on('connect_error', (err) => {
            console.log(`connect_error due to ${err.message}`);
        });
        this.onSocket('connect', async () => {
            console.log("socket is established");
            this.setIsConnected(true);
        });
        this.onSocket('disconnect', (reason) => {
            this.setIsConnected(false);
        });
        this.socket.connect();
        this.addTimeoutSocket();
    }

    public reconnect(): void {
        this.socket.connect();
    }

    getSocket(): IOSocket {
        return this.socket;
    }

    addTimeoutSocket(): void {
        this.socket.request = (method: string, data: any) => {
            return new Promise((resolve, reject) => {
                if (!this.socket) { reject('No socket connection'); }
                else {
                    this.socket.emit(
                        'request',
                        {method, data},
                        timeoutCallback((err: any, response: any) => {
                            if (err) { reject(err + " " + response); }
                            else { resolve(response); }
                        })
                    );
                }
            });
        };
    }

    async sendRequest(method: string, data: any): Promise<any> {
        const listShowLog = ['join', 'joinAudio', 'leaveRoom','moderator:closeMeeting'];
        
        for (let index = 0; index < listShowLog.length; index++) {
            // const element = array[index];
            if( method == listShowLog[index]){
                this.timeLog.setStartTime(method)
            }
        }
        if (!this.isConnectedSubject.value) throw new Error("socket disconnected");
        return this.socket.request(method, data);
    }

    // tslint:disable-next-line:ban-types
    public onSocket(event: string, fn: Function): void{
        this.socket.on(event, (params: any) => { fn(params); });
    }

    // Observer: isConnected
    public setIsConnected(status: boolean): void{
        this.isConnectedSubject.next(status);
    }

    public onConnected(): Observable<boolean>{
        return this.isConnectedSubject.asObservable();
    }
}

