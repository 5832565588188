import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {UserRoleModel} from '../models/user-role.model';
import {RemoteParticipant} from '../models/remote-participant.model';
@Injectable({
    providedIn: 'root'
})
export class RolesManagerService {
    private remoteParticipantSubject = new BehaviorSubject<RemoteParticipant>(null);
    private roleManagerOpenSubject = new BehaviorSubject<boolean>(false);
    private userRolesSubject = new BehaviorSubject<Map<number, UserRoleModel>>(null);

    public setRemoteParticipant(remoteParticipant: RemoteParticipant): void{
        this.remoteParticipantSubject.next(remoteParticipant);
    }

    public getRemoteParticipant(): RemoteParticipant{
        return this.remoteParticipantSubject.value;
    }


    public onRemoteParticipant(): Observable<RemoteParticipant>{
        return this.remoteParticipantSubject.asObservable();
    }

    public setUserRoles(userRoles: Map<number, UserRoleModel>): void{
        this.userRolesSubject.next(userRoles);
    }

    public onUserRoles(): Observable<Map<number, UserRoleModel>>{
        return this.userRolesSubject.asObservable();
    }

    public getUserRoles(): Map<number, UserRoleModel>{
        return this.userRolesSubject.value;
    }

    public setRoleManagerOpen(flag: boolean): void{
        this.roleManagerOpenSubject.next(flag);
    }

    public onRoleManagerOpen(): Observable<boolean>{
        return this.roleManagerOpenSubject.asObservable();
    }

    public getHighestRoleLevel(roles): number{
        let level = 0;
        const userRoles = this.userRolesSubject.value;

        for (const role of roles)
        {
            const tmpLevel = userRoles.get(role).level;

            if (tmpLevel > level) {
                level = tmpLevel;
            }
        }
        return level;
    }

    /**
     * Kiểm tra user có role moderator hay không
     * @param roles 
     * @param MODERATOR_ROLES 
     * @returns 
     */
    public isModerator(roles: number[], MODERATOR_ROLES: number): boolean{
        const userRoles = this.userRolesSubject.value;
        for (const role of roles)
        {
            if(userRoles.get(role).level === MODERATOR_ROLES) return true;
        }
        return false;
    }
}
