<div vnpt-col>
  <div>
    <div class="micro">
      <label style="align-self: center; margin-left: -6px; font-weight: 600">Microphone</label>
      <div vnpt-row [vGutter]="12" ngClass="rowLabel">
        <label style="align-self: center;" >{{ "device" | vI18n:{}:"Thiết bị" }}</label>
        <label class="device-menu" vnpt-popover vPopoverTrigger="click" [vPopoverContent]="audioDeviceTemplates" vPopoverPlacement="bottomRight">
          <span>{{!labelMic ? ('settings.devices.default' | vI18n:{}:'Mặc định hệ thống') : labelMic.label}}</span>
          <a   [style.border]="'0px'"  style="width: auto;">
            <i vnpt-icon class="icon" [vType]="isDeviceMicro ? 'ChevronUp' : 'ChevronDown'" ngClass="iconCustom">
            </i>
          </a>
        </label>
      </div>
      <div  vnpt-row [vGutter]="8">
        <div  vnpt-col [vSpan]="24"  >
          <div class="slider-container" style="margin-left: -6px; margin-right: -6px;">
            <div class="bar-container">
              <div class="bar-5" [style.width]="changeWidth"></div>
            </div>
            <audio id ="remic" #micCheckRef autoplay></audio>
          </div>
        </div>
      </div>
      <div vnpt-row [vGutter]="8" ngClass="rowButton">
          <button (click)="toggleTestMicLocal()" [disabled]="micLock" vnpt-button vType="primary" vSize="default" vShape="circle" name="s" >
            <i vnpt-icon  [vType]="isMicPlayed ? 'Pause' : 'Play'"></i>
          </button>
          <span style="margin-left: 30px">{{ "settings.mic.test.label" | vI18n:{}:"Kiểm tra mic" }}</span>
      </div>
    </div>
  </div>      
  <div class="audio">

      <label style="align-self: center; margin-left: -6px; font-weight: 600">{{ "speaker" | vI18n:{}:"Loa" }}</label>
      <div vnpt-row [vGutter]="12" ngClass="rowLabel" >
        <label style="align-self: center;" >{{ "device" | vI18n:{}:"Thiết bị" }}</label>
          <label class="device-menu" vnpt-popover vPopoverTrigger="click" [vPopoverContent]="audioDeviceTemplate2" vPopoverPlacement="bottomRight">
            <span>{{!labelSpeaker ? ('settings.devices.default' | vI18n:{}:'Mặc định hệ thống') : labelSpeaker.label}}</span>
            <a vnpt-button  [style.border]="'0px'" style="width: auto;">
              <i vnpt-icon class="icon" [vType]="'ChevronDown'" ngClass="iconCustom" ></i>
            </a>
          </label>
      </div>
      <div vnpt-row [vGutter]="8">
        <div vnpt-col [vSpan]="24" style="margin-top: 10px; margin-left: -6px; margin-right: -6px;">
          <vnpt-slider [vMarks]="marksHGutter" [vStep]="null" [vMin]="0" [vMax]="10" (vOnAfterChange)="changeVolume()" [(ngModel)]="volumeAudio"></vnpt-slider>
        </div>
      </div>
      <div vnpt-row [vGutter]="8" ngClass="rowButton">
          <button (click)="toggleTestAudio()" vnpt-button vType="primary" vSize="default" vShape="circle" name="s" >
            <i vnpt-icon  [vType]="isAudioPlayed ? 'Pause' : 'Play'"></i>
          </button>
          <span style="margin-left: 30px">{{ "settings.audio.test.label" | vI18n:{}:"Kiểm tra audio" }}</span>
      </div>

  </div>
  <audio #audioRef class="audio-example" src="assets/audio/speaker-test.wav"></audio>
</div>
<ng-template #audioDeviceTemplates>
  <lib-audio-device></lib-audio-device>
</ng-template>
<ng-template #audioDeviceTemplate2>
  <lib-audio-device [childMessage]="true"></lib-audio-device>
</ng-template>
  