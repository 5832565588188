import { Component, ElementRef, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { NotificationService } from '../../services/notification-service';
import { BaseMessage, Conversation } from '../../models/chatting.model';
import { MediasoupService, ChattingService, LocalParticipantService, RoomService, LobbyService } from '../../services';
import { Subject } from 'rxjs';
import { HttpEventType } from '@angular/common/http';
import { LocalParticipant } from '../../models/local-participant.model';
import { COMMON_CONSTANTS } from 'projects/main/src/app/constants';
import { config } from 'projects/realtime-communication/src/config';
import { OneuiI18nService } from '@vnpt/oneui-i18n';
import { PoolService } from '../../services/mediasoup/pool.service';
import { RemoteParticipant } from '../../models/remote-participant.model';
import { element } from 'protractor';
const FIVE_MB_SIZE = 5242880;

@Component({
    selector: 'lib-chatting',
    templateUrl: './chatting.component.html',
    styleUrls: ['./chatting.component.less']
})
export class ChattingComponent implements OnInit {
    bodystyle = {
        flexFlow: "column",
        display: "flex",
        flex: "1",
        padding: "0",
        overflow: "hidden"
    };
    inputValue = '';
    textAreaRows = 1;
    localParticipant: LocalParticipant;
    pushMessageSubject: any = new Subject<BaseMessage>();
    deleteErrorMessageSubject: any = new Subject<void>();
    fileTitle = "Thêm tập tin";
    sendTitle = "Gửi tin nhắn";
    listActiveList  = [];
    optionActiveChat: string;
    listFileUploads: any[] = [];
    // keyFilter = 'all';
    errorMsg = {
        error: false,
        msg: ''
    };
    extensionLists = ['m4v', 'avi','mpg','mp4', 'webm',
    'jpg', 'gif', 'bmp', 'png','jpeg',
    'pdf','doc','docx','odt','rtf','xls','xlsx','ods','ppt','pptx','odp'];
    @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
    @ViewChild('unreadMessageCount') unreadMessageCountRef: ElementRef;
    @Output() triggerRightContainer = new EventEmitter();
    currentActiveTitle: string;
    topic: string;
    unseenMessage = 0;
    langChanges ='vi';
    lobbyLength = false;
    // openFilter = true;
    constructor(
        // private mediasoupService: MediasoupService,
        // private notification: NotificationService,
        private localParticipantService: LocalParticipantService,
        private poolService: PoolService,
        private chattingService: ChattingService,
        private i18nService: OneuiI18nService,
        private roomService: RoomService,
        private lobbyService: LobbyService
        // private readonly changeDetector: ChangeDetectorRef
    ) {
        this.localParticipant = new LocalParticipant();
        
        this.i18nService.langChanges$.subscribe(evt=>{
            if(evt.type == "Success"){
                this.langChanges = this.i18nService.getActiveLang();
                this.currentActiveTitle = (this.topic == "PUBLIC_CHAT"
                ? this.i18nService.translate('chatting.publicChat',{},"Thảo luận chung")
                : this.poolService.getLatestDisplayName(this.topic));
                    
            }
          });

    }

    ngOnInit(): void {
        // this.roomService.rightPaneViewObservable.subscribe(panel =>{
        //     if(panel != 'chatting' && this.openFilter){
        //         this.fopenFilter();
        //     }
        // });
        
        this.localParticipantService.onPeerID().subscribe((peerID: string) => {
            this.localParticipant.id = peerID;
        });

        this.localParticipantService.onDisplayName().subscribe((displayName: string) => {
            this.localParticipant.displayName = displayName;
        });

        this.chattingService.topicChangedBehavior.subscribe(topic => {
            this.topic = topic;
            this.currentActiveTitle = (topic == "PUBLIC_CHAT"
                ? this.i18nService.translate('chatting.publicChat',{},"Thảo luận chung")
                : this.poolService.getLatestDisplayName(topic));
            this.listActiveList =  this.chattingService.getActiveList();
            // this.keyFilter = 'all';
            // this.openFilter = false;
            this.chattingService.setFilterChat('all');

            if (!this.listActiveList.find(element => element.data.unreadMessageCount)) {
                this.unseenMessage = 0;
            } else {
                this.unseenMessage = 1;
            }

        });
        
        this.chattingService.onUpdateMapMessage.subscribe(flag =>{
            this.listActiveList =  this.chattingService.getActiveList();   
            //Bổ sung cap nhat lai title
            this.currentActiveTitle = (this.topic == "PUBLIC_CHAT"
            ? this.i18nService.translate('chatting.publicChat',{},"Thảo luận chung")
            : this.poolService.getLatestDisplayName(this.topic) == '' ? this.currentActiveTitle : this.poolService.getLatestDisplayName(this.topic));
            if (!this.listActiveList.find(element => element.data.unreadMessageCount)) {
                this.unseenMessage = 0;
            } else {
                this.unseenMessage = 1;
            }
        })
        
        this.chattingService.newMessageBehavior.subscribe((msg: BaseMessage) => {
            if(msg?.topic != this.topic){
                // if(msg.topic != this.topic){
                    this.unseenMessage = 1;
                // }
                // this.chattingService.getHistory(msg.topic).then((conversation: Conversation) => {
                //     this.unseenMessage = conversation.unreadMessageCount;
                // });
            }
        });

        this.lobbyService.onChangeLobbyList().subscribe((isChange: boolean) => {
            const length = this.lobbyService.getLobbyList().size;
            // this.lobbyLength = length > 0 ? true : false;
            if(length > 0 )
                this.lobbyLength = true;
            else
                this.lobbyLength = false;
        });
    }

    pushChatMessage(message: any) {
        this.pushMessageSubject.next(message);
    }

    pushDeleteErrorMessage() {
        this.deleteErrorMessageSubject.next();
    }
    
    goBack(): void {
        this.roomService.rightPaneView = "listParticipant";
        // this.triggerRightContainer.emit({type: "listParticipant"});
    }
    openChat(topic: string):void{
        if(topic){
            this.chattingService.setActiveChat(topic);
            this.roomService.rightPaneView = "chatting" ;
        }
    }

    // filter(key: string): void {
    //     this.keyFilter =  key;
    //     this.chattingService.setFilterChat(key);
    // }

    //Bat/tat tinh nang filter tin nhan
    // fopenFilter():void{
    //     this.openFilter = this.openFilter ? false : true;
    //     if(!this.openFilter){
    //         // this.keyFilter = 'all';
    //         this.chattingService.setFilterChat('all');
    //     }
    // }
}
