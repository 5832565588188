import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './main/main.component';
import {AuthGuardService} from './services/auth-guard.service';

const routes: Routes = [
    {
        path: '', redirectTo: 'join', pathMatch: 'full'
    },
    {
        path: 'room',
        loadChildren: () => import('./modules/room/room.module').then(m => m.RoomModule),
        canActivate: [AuthGuardService],
        canDeactivate: [AuthGuardService]
    },
    {
        path: 'join', loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
