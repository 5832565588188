<div vJustify="space-between" (mouseenter)="triggerVisibleQuickActionChange(true)" (mouseleave)="triggerVisibleQuickActionChange(false)" vAlign="middle" class="parent">
    <div class="order" *ngIf="numberOrder >= 0">
        <p class="user-name custom-order" >{{ numberOrder + 2 }}.</p>
    </div>
    <div class="display-name user-name child child-one" >
        <p class="user-name" title="{{participant.displayName}}">{{ participant.displayName }}</p>
    </div>
    <div class="display-name child child-two" *ngIf='participantIsModerator'>
        <p class="role" *ngIf='participantIsAdmin else elseIsModeratorBlock'> ({{'admin' | vI18n:{}:'Quản trị viên'}})</p>
    </div>
    <ng-template #elseIsModeratorBlock>
        <div class="display-name child child-two">
            <p class="role"> ({{'moderator' | vI18n:{}:'Người điều hành'}})</p>
        </div>
    </ng-template>
    <div class="icon-holder child child-three">
        <div vnpt-row vJustify="end" vAlign="middle" class="no-w">
            <div *ngIf="participant.isRaisedHand" class="icon-container">
                <a vnpt-button vType="text" disabled>
                    <i class="icon-gomeet-raise-hand icon-yellow" vSize="xs"></i>
                </a>
            </div>
            <div *ngIf="isSpeaking && isJoinedAudio && participant.audio.state" class="icon-container">
                <a vnpt-button vType="text" disabled>
                    <i  class="icon-gomeet-talking icon-success"></i>
                </a>
            </div>
            <div *ngIf="participant.isSharing" class="icon-container">
                <a vnpt-button vType="text" disabled>
                    <i class="icon-gomeet-sharescreen icon-success"></i>
                </a>
            </div>
            <div class="icon-container" *ngIf="participant.waitingMessage">
                <a vnpt-button vType="text" (click)="openPrivateChat('privateChat')" vnpt-tooltip vTooltipTitle="Tin nhắn riêng">
                    <vnpt-badge *ngIf="participant.waitingMessage" [vDot]="participant.waitingMessage" [vStyle]="{ backgroundColor: '#52C41A'}" class="badge">
                    </vnpt-badge>
                    <i vnpt-icon vType="ChatInviteFriend" ngClass="icon-success"></i>
                </a>
                <!-- <a vnpt-button vType="text" disabled >
                    <i class="icon-gomeet-chat icon-success" ></i>
                </a> -->
            </div>
            <div  *ngIf="participant.isPin" class="icon-container" style="cursor: pointer; justify-content: flex-end; align-self: center;" >
                <a vType="text"   >
                    <i  class="icon-gomeet-pin icon-primary iconPin"></i>
                </a>
            </div>
            <ng-template [ngIf]="!hasBlockAudio && isJoinedAudio">
                <div class="icon-container">
                    <!-- <a vnpt-button vType="text" [disabled]="!participant.audio.state" (click)="toggleRemoteAudio()" -->
                        <a vnpt-button vType="text" disabled 
                    vnpt-tooltip [vTooltipTitle]="participant.audio.isLocallyPaused ? 'Kết nối lại' : 'Tạm dừng'">
                        <i [ngClass]="!participant.audio.state ? 'icon-gomeet-state-micoff icon-error': 'icon-gomeet-state-mic icon-success'"></i>
                    </a>
                </div>
            </ng-template>

            <ng-template [ngIf]="hasBlockAudio && isJoinedAudio">
                <div class="icon-container">
                    <a vnpt-button vType="text" disabled>
                        <i ngClass="icon-gomeet-state-micban icon-error"></i>
                    </a>
                </div>
                </ng-template>

            <ng-template [ngIf]="!hasBlockVideo">
                <div class="icon-container">
                    <!-- <a vnpt-button vType="text" [disabled]="!participant.webcam.state" (click)="toggleRemoteVideo()" -->
                    <a vnpt-button vType="text" disabled 
                    vnpt-tooltip [vTooltipTitle]="participant.webcam.isLocallyPaused ? 'Kết nối lại' : 'Tạm dừng'">
                        <i [ngClass]="!participant.webcam.state || participant.webcam.isLocallyPaused ? 'icon-gomeet-state-videooff icon-error': 'icon-gomeet-state-video icon-success'"></i>
                    </a>
                </div>
            </ng-template>

            <ng-template [ngIf]="hasBlockVideo">
                <div class="icon-container">
                    <a vnpt-button vType="text" disabled>
                        <i ngClass="icon-gomeet-state-videoban icon-error"
                            ></i>
                    </a>
                </div>
                </ng-template>

            <!-- <div class="icon-container" >
                <a vnpt-button vType="text" vnpt-dropdown [vDropdownMenu]="moreMenu" vTrigger="click" [vPlacement]="'bottomRight'"
                vnpt-tooltip vTooltipTitle="{{'list-participant.more' | vI18n:{}:'Thêm'}}">
                    <i vnpt-icon [vType]="'MoreVertical'" vSize="sm" class="icon-white"></i>
                </a>
            </div> -->
            <!-- <div class="icon-container" ></div> -->
        </div>
    </div>
    <div vnpt-row *ngIf="showUp || moreMenuVisible" class="quick-action">        
        <button vnpt-button VSize="small" *ngIf="hasPermissionModerator && !isBlockAllAudio && participant.audio.state"
         (click)="muteRemoteParticipant()" vDanger style="background-color: white;" class="quick-button">
            <i style="margin-right: 5px;" class="icon-gomeet-state-micoff"></i>
            {{'list-participant.offAudio' | vI18n:{}:'tắt tiếng'}}
        </button>
    
        <button vnpt-button VSize="small" *ngIf="hasPermissionModerator && !isBlockAllWebcam && participant.webcam.state"
         (click)="stopRemoteParticipantVideo()" vDanger style="background-color: white;" class="quick-button">
            <i style="margin-right: 5px;" class="icon-gomeet-state-videooff"></i>
            {{'list-participant.offVideo' | vI18n:{}:'Dừng video'}}
        </button>

        <button vnpt-button VSize="small" vType="primary" (click)="pinCameraHandler()" class="quick-button"
        [disabled]="(!hasPermissionModerator && forceLayoutStatus) || isWebcamsOnlyForModerator"
        >
            <i class="icon-gomeet-pin" ></i>
            {{participant.isPin ? ('unpin' | vI18n:{}:'Bỏ ghim') : ('pin' | vI18n:{}:'Ghim')}}
        </button>

        <button vnpt-button VSize="small" vType="default" style="background-color: white; color: black; height: 24.45px;"
        vnpt-dropdown [vDropdownMenu]="moreMenu" vTrigger="click" [vPlacement]="'bottomRight'" (vVisibleChange)="openMoreMenu($event)"
        vnpt-tooltip vTooltipTitle="{{'list-participant.more' | vI18n:{}:'Thêm'}}" class="quick-button"
        >
            <!-- <i vnpt-icon vType="More" [vSize]="'sm'"></i> -->
            <i class="icon-gomeet-more"></i>
        </button>
    </div>
</div>

<!-- TODO: ADD PRIVATE CHAT -->
<vnpt-dropdown-menu #moreMenu>
    <ul class="menu-background" vnpt-menu>
        <ng-template [ngIf]="!isBlockAllAudio && this.participant.audio.state" *ngIf="hasPermissionModerator">
            <li vnpt-menu-item (click)="muteRemoteParticipant()">
                <i vnpt-icon class="icon-gomeet-state-micoff" [vSize]="'sm'"></i>
                <span>{{'list-participant.stopSharingAudio' | vI18n:{}:'Dừng chia sẻ audio'}}</span>
            </li>
        </ng-template>

        <ng-template [ngIf]="!isBlockAllWebcam && this.participant.webcam.state" *ngIf="hasPermissionModerator">
            <li vnpt-menu-item (click)="stopRemoteParticipantVideo()">
                <i vnpt-icon class="icon-gomeet-state-videooff" [vSize]="'sm'"></i>
                <span>{{'list-participant.stopSharingVideo' | vI18n:{}:'Dừng chia sẻ video'}}</span>
            </li>
        </ng-template>

        <li vnpt-menu-item (click)="stopRemoteParticipantScreenSharing()" *ngIf="hasPermissionModerator && participant.isSharing">
            <i vnpt-icon vType="LockShare" [vSize]="'sm'"></i>
            <span>{{'list-participant.stopSharingScreen' | vI18n:{}:'Dừng chia sẻ màn hình'}}</span>
        </li>

        <li vnpt-menu-item (click)="openRolesManager()" *ngIf="hasPermissionModerator">
            <i vnpt-icon vType="PagePermission" [vSize]="'sm'"></i>
            <span>{{'list-participant.changePermissions' | vI18n:{}:'Thay đổi quyền'}}</span>
        </li>

        <li vnpt-menu-item (click)="viewShareScreen()" *ngIf="participant.isSharing && (forceLayoutStatus ? hasPermissionModerator : true)">
            <i vnpt-icon vType="ScreenCast" [vSize]="'sm'"></i>
            <span>{{'list-participant.viewShareScreen' | vI18n:{}:'Xem màn hình chia sẻ'}}</span>
        </li>

        <li vnpt-menu-item (click)="openPrivateChat(participant.id)" [ngClass]="{'noti-dot' : participant.waitingMessage }">
            <i vnpt-icon vType="ChatInviteFriend" [vSize]="'sm'"></i>
            <span>{{'list-participant.privateChat' | vI18n:{}:'Tin nhắn riêng'}}</span>
        </li>

        <li vnpt-menu-item (click)="kickRemoteParticipant()" 
            *ngIf="(hasPermissionModerator && !hasPermissionAdmin && !participantIsModerator) 
                    || (hasPermissionAdmin && !participantIsAdmin)">
            <i vnpt-icon vType="UserRemove" [vSize]="'sm'"></i>
            <span>{{'list-participant.kick' | vI18n:{}:'Xóa khỏi cuộc họp'}}</span>
        </li>
    </ul>
</vnpt-dropdown-menu>
