<div [class]="mainframe">
    <lib-activating-speaker class="active-video" [ngClass]='{"pointer-none": isFullScreen}' *ngIf="layoutMode != 'gallery' && layoutMode != 'whiteboard'"></lib-activating-speaker>
    <lib-whiteboard [ngClass]='{"fullScreen-top": isFullScreen}' class="active-video" *ngIf="layoutMode == 'whiteboard'"></lib-whiteboard>
    <div #listView class="paging-box" >
        <div #listVideo class="video-list show-scrollbar" [ngClass]='{"fullScreen-state": isFullScreen}' >
            <div class="gutter-row" *ngIf="!isRecordUser" [ngClass]= '{"d-none": isRecordUser}' [style.width]="layoutMode == 'gallery' ? videoColumnSize+ '%' : ''">
                <lib-local-streaming [miniView]="miniView" [forceLayoutStatus]="forceLayoutStatus" ></lib-local-streaming>
            </div>
            <ng-template [ngIf]="listRemoteParticipant && listRemoteParticipant[0]">
                <div class="gutter-row" 
                *ngFor="let remote of listRemoteParticipant" [style.width]="layoutMode == 'gallery' ? videoColumnSize+ '%' : ''">
                    <lib-remote-streaming [participantId]="remote.id" [miniView]="miniView" [forceLayoutStatus]="forceLayoutStatus">
                    </lib-remote-streaming>
                </div>
            </ng-template>
        </div>

        <button class="paging-button left-button" vnpt-button vType="primary" vShape="circle" (click)="previousPage()"
            [ngClass]="{'d-none': currentPageIndex - 1 <= 0 || hideNextPage || forceLayoutStatus}">
            <i vnpt-icon vType="ChevronLeft"></i>
        </button>

        <button class="paging-button right-button" vnpt-button vType="primary" vShape="circle" (click)="nextPage()"
            [ngClass]="{'d-none': currentPageIndex + 1 > totalPage || hideNextPage || forceLayoutStatus}">
            <i vnpt-icon vType="ChevronRight"></i>
        </button>
    </div>
    <p class="paging-count" [ngClass]="{'d-none': totalPage < 2 || hideNextPage || forceLayoutStatus}">{{currentPageIndex + '/' +
        totalPage}}</p>
</div>