export interface BreakroomModel {
    myGroupId: string;
    isBreakRoom?: boolean;
    groupMap: any;
}

export class BreakroomData implements BreakroomModel {
    teacherId = '';
    myGroupId: string;
    groupMap: any;
    myId = '';
    currentGroup: any;
    teacherSpeakingInGroup = 'all';

    constructor(jsonObj: object) {
        // tslint:disable-next-line:forin
        for (const prop in jsonObj) {
            this[prop] = jsonObj[prop];
        }
        this.prepareData();
    }

    public setMyID(peerID: string): void {
        this.myId = peerID;
    }

    public prepareData(): void {
        this.teacherId && this.teacherId !== '' && this.teacherId === this.myId ?
            this.myGroupId = '1' : this.myGroupId = this.getGroupOfPeer(this.myId);
    }

    public sameGroup(peerID: string): boolean {
        return this.getGroupOfPeer(peerID) === this.myGroupId;
    }

    public getGroupOfPeer(peerID: string): string {
        for (const [roomId, roomMembers] of Object.entries(this.groupMap) as any) {
            for (const pID of roomMembers) {
                if (pID === peerID) { return roomId; }
            }
        }
        return 'noGroup';
    }

    public peerIsTeacher(peerID: string): boolean {
        return peerID === this.teacherId;
    }

    public addPeerToGroup(peerID: string, groupName: string): void {
        if (peerID && groupName) {
            this.groupMap[groupName].push(peerID);
            this.groupMap[groupName] = [...new Set(this.groupMap[groupName])];
        }
    }
}

export interface BreakroomDataF5 {
    teacherId: string;
    groupMap: any;
}
export class BreakroomDataF5 implements BreakroomDataF5 {
    teacherId = '';
    groupMap: any;
}

