<!-- <vnpt-card class="card-holder" vTitle="{{ 'lobby.title' | vI18n: {}:'Danh sách người chờ duyệt vào phòng' }} ({{lobbyList.size}})" [vExtra]="back" > -->
  <div vJustify="space-between" vAlign="middle" class="header-btn">
    <div vnpt-row vAlign="middle" vJustify="space-between" class="switch">
      <span style="color:black" vI18n="security.selfCensor"></span>
      <label class="switch2">
          <input type="checkbox" [checked]="!isLock" (click) = "lockRoom($event)" [disabled]="checkEvent">
          <span class="slider round"></span>
        </label>
    </div>
    <div vnpt-row vJustify="center" vAlign="middle" class="no-w">
      <!-- <button  vnpt-button vType="primary" (click)="lockRoom()"  class="child child-one acceptAll lobby-btn" >
        {{isLock ? ('security.roomUnlock' | vI18n:{}:'Mở khóa phòng họp') : ('security.roomLock' | vI18n:{}:'Khóa phòng họp')}}
      </button> -->
      <button *ngIf="lobbyList.size > 0" vnpt-button vDanger (click)="rejectAllLobby()" vI18n="lobby.denyAll"  class="denyAll lobby-btn" [disabled]="checkRejectAllPeersEvent"></button>
      <button *ngIf="lobbyList.size > 0" vnpt-button vType="primary" (click)="promoteAllLobby()" [disabled]="acceptAllDisable || checkPromoteAllPeersEvent" vI18n="lobby.acceptAll"class="acceptAll lobby-btn"></button>
    </div>
  </div>
  <!-- <div *ngIf="maxParticipant != 0"class="child child-one max-user">
    <span style="color:black"> {{'lobby.participants' | vI18n:{}:'Người tham gia:'}}   {{lengthList}}/{{maxParticipant}}</span>
  </div> -->
  <div class="lobbyList">
    <span *ngIf="lobbyList.size == 0" class="no-user">{{'lobby.noUser' | vI18n:{}:'Không có người chờ duyệt'}}</span>
    <!-- <div class="lobby" *ngFor="let lobby of lobbyList | keyvalue; trackBy: trackByKey; let numberOrder=index"
    >
      <div vnpt-row vAlign="middle" vJustify="space-between" class="parent">
        <p class="user-name" >{{ numberOrder + 1 }}.</p>
        <span class="user-name child child-one" [title]="lobby.value.displayName">{{ lobby.value.displayName }}</span>
        <div class="buttonLobby"  *ngIf="showWho == lobby.value">
          <button  vnpt-button vType="primary" class="buttonOK lobby-btn" (click)="promoteLobby(lobby.key)" vnpt-dropdown [vDropdownMenu]="layoutMenu"
            vTrigger="click" [vPlacement]="'bottomRight'"  [disabled]="acceptDisable">
            <span vI18n="lobby.accept"></span>
          </button>
          <button vnpt-button  vDanger class="deny lobby-btn" (click)="rejectLobby(lobby.key)" vnpt-dropdown  [vDropdownMenu]="layoutMenu"
            vTrigger="click" [vPlacement]="'bottomRight'" >
            <span vI18n="lobby.deny"></span>
          </button>
        </div>
      </div>
    </div> -->
    <div class="lobby" *ngFor="let lobby of lobbyList | keyvalue; trackBy: trackByKey; let numberOrder = index">
      <div vnpt-row vAlign="middle" vJustify="space-between" class="parent">
          <!-- <span class="child child-one" style=" color: black;">{{ numberOrder + 1 }}.</span> -->
        <span class="user-name child child-one" [title]="lobby.value.displayName"> {{ numberOrder + 1 }}. {{ lobby.value.displayName }}</span>
        <div class="buttonLobby">
          <button vnpt-button vDanger class="deny lobby-btn" (click)="rejectLobby(lobby.key)" vnpt-dropdown  [vDropdownMenu]="layoutMenu"
            vTrigger="click" [vPlacement]="'bottomRight'"  style="height: auto;">
            <span vI18n="lobby.deny"></span>
          </button>
          <button  vnpt-button vType="primary" class="buttonOK lobby-btn" (click)="promoteLobby(lobby.key)" vnpt-dropdown [vDropdownMenu]="layoutMenu"
            vTrigger="click" [vPlacement]="'bottomRight'"  [disabled]="acceptDisable" style="height: auto;">
            <span vI18n="lobby.accept"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- <div *ngIf="lobbyList.size > 0"vJustify="space-between" vAlign="middle"  [class]="{'parent': maxParticipant != 0}" style="padding: 0px 20px;">
    <div *ngIf="maxParticipant != 0"class="child child-one" style="text-align: left; margin-top: 10px;" >
        <span> {{'lobby.participants' | vI18n:{}:'Người tham gia:'}}   {{lengthList}}/{{maxParticipant}}</span>
    </div>
    <div vnpt-row vJustify="end" vAlign="middle" class="no-w">
        <button  vnpt-button vType="primary" (click)="promoteAllLobby()" [disabled]="acceptAllDisable" vI18n="lobby.acceptAll" class="acceptAll"></button>
        <button vnpt-button vType="default" (click)="rejectAllLobby()" vI18n="lobby.denyAll"  class="denyAll"></button>
    </div>
  </div> -->
<!-- </vnpt-card> -->

<!-- <ng-template #back> -->
  <!-- <button vnpt-button vType="text" (click)="goBack()" vnpt-tooltip vTooltipTitle="{{ 'chatting.back' | vI18n: {}:'Quay lại' }}" vTooltipPlacement="bottom" >
    <i vnpt-icon vType="Reply" [vColor]="'primary'"></i>
  </button>
</ng-template> -->
