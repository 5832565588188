/* tslint:disable:max-line-length */
import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {RealtimeCommunicationComponent} from './realtime-communication.component';
import {SharedModuleRealtimeCommunication} from './shared/shared.module';
import {RealtimeCommunicationStateModule} from './state/state.module';
import {JoiningOptionsComponent} from './components/options-meeting/joining-options/joining-options.component';
import {ListAudioComponent} from './components/list-audio/list-audio.component';
import {ListParticipantComponent} from './components/list-participant/list-participant.component';
import { LocalParticipantDetailComponent } from './components/list-participant/local-participant-detail/local-participant-detail.component';
import {LobbyOptionsComponent} from './components/options-meeting/lobby-options/lobby-options.component';
import {ManagerComponent} from './components/meeting-views/quiz/manager/manager.component';
import {LocalStreamingComponent} from './components/local-streaming/local-streaming.component';
import {QuizComponent} from './components/meeting-views/quiz/quiz.component';
import { ReactionsComponent } from './components/reactions/reactions.component';
import {RemoteStreamingComponent} from './components/remote-streaming/remote-streaming.component';
import {BreakRemoteStreamingComponent} from './components/break-remote-streaming/break-remote-streaming.component';
import {RemoteParticipantDetailComponent} from './components/list-participant/remote-participant-detail/remote-participant-detail.component';
import { RolesManagerComponent } from './components/roles-manager/roles-manager.component';
import { SearchingParticipantComponent } from './components/searching-participant/searching-participant.component';
import { SecurityComponent } from './components/security/security.component';
import { MoreOptionsComponent } from './components/more-options/more-options.component';
import {SettingsComponent} from './components/settings/settings.component';
import { SideBySideViewComponent } from './components/meeting-views/side-by-side/sidebyside.component';
import { PolycomViewComponent } from './components/meeting-views/polycom/polycom.component';
import { WhiteboardComponent } from './components/meeting-views/whiteboard/whiteboard.component';
import {ViewingParticipantQuizComponent} from './components/meeting-views/quiz/viewing-participant-quiz/viewing-participant-quiz.component';
import { VirtualBackgroundComponent } from './components/virtual-background/virtual-background.component';
import { BreakoutRoomManagerComponent } from './components/breakout-room-manager/breakout-room-manager.component';
import { BreakRoomComponent} from './components/meeting-views/breakroom/breakroom.component';
import { SafariRecommenComponent } from './components/safari-recommen/safari-recommen.component';
import { JoinAudioComponent } from './components/join-audio/join-audio.component';
import { SpeakersComponent } from './components/speakers/speakers.component';
import { AgVirtualScrollModule } from 'ag-virtual-scroll';

import {
    ConsumerService,
    LobbyService,
    ListRequestRoleService,
    MediasoupService,
    QuizService,
    RoomService,
    WhiteboardService,
    LatencyService,
    ChattingService
} from './services';
import {ActivatingSpeakerComponent} from './components/activating-speaker/activating-speaker.component';
import {ChatboxComponent} from './components/chatting/chatbox/chatbox.component';
import {ChattingComponent} from './components/chatting/chatting.component';
import {BreakRoomService, SocketService} from './services';
import {ExerciseComponent} from './components/meeting-views/quiz/exercise/exercise.component';
import {EdumeetRoomConfigModel} from './models/edumeet-room-config.model';
import {CommonModule} from '@angular/common';
import { LobbyComponent } from './components/lobby/lobby.component';
import {AudioComponent} from './components/list-audio/audio/audio.component';
import {PoolService} from './services/mediasoup/pool.service';
import { MediaStreamService } from './services/mediasoup/media-stream.service';
import { MeetingTimeoutComponent } from './components/meeting-timeout/meeting-timeout.component';
import { AudioDeviceComponent } from './components/audio-device/audio-device.component';
import { VideoDeviceComponent } from './components/video-device/video-device.component';
import { GeneralSettingComponent } from './components/settings/general-setting/general-setting.component';
import { VideoSettingComponent } from './components/settings/video-setting/video-setting.component';
import { AudioSettingComponent } from './components/settings/audio-setting/audio-setting.component';
import { InformationComponent } from './components/settings/information/information.component';
import { ChatInputComponent } from './components/chatting/chat-input/chat-input.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { DurationComponent } from './components/duration/duration.component';
import { DisplayNameComponent } from './components/display-name/display-name.component';
import { RoomNameComponent } from './components/room-name/room-name.component';
import { RoomInfoComponent } from './components/room-info/room-info.component';
import { LobbyV2Component } from './components/lobby-v2/lobby-v2.component';
import { ReportComponent } from './components/room-info/report/report.component';
import { ListRequestRoleComponent } from './components/list-request-role/list-request-role.component';
import { DeviceGuideComponent } from './components/device-guide/device-guide.component';
import { TimeoutDialogComponent } from './components/timeout-dialog/timeout-dialog.component';

@NgModule({
    declarations: [
        ActivatingSpeakerComponent,
        BreakoutRoomManagerComponent,
        BreakRoomComponent,
        ChatboxComponent,
        ChattingComponent,
        SafariRecommenComponent,
        ExerciseComponent,
        JoiningOptionsComponent,
        ListAudioComponent,
        ListParticipantComponent,
        LobbyOptionsComponent,
        LocalParticipantDetailComponent,
        LocalStreamingComponent,
        ManagerComponent,
        QuizComponent,
        ReactionsComponent,
        RealtimeCommunicationComponent,
        RemoteParticipantDetailComponent,
        RemoteStreamingComponent,
        BreakRemoteStreamingComponent,
        RolesManagerComponent,
        SearchingParticipantComponent,
        SecurityComponent,
        SettingsComponent,
        SideBySideViewComponent,
        PolycomViewComponent,
        WhiteboardComponent,
        ViewingParticipantQuizComponent,
        VirtualBackgroundComponent,
        LobbyComponent,
        AudioComponent,
        MeetingTimeoutComponent,
        JoinAudioComponent,
        AudioDeviceComponent,
        VideoDeviceComponent,
        GeneralSettingComponent,
        VideoSettingComponent,
        AudioSettingComponent,
        InformationComponent,
        ChatInputComponent,
        SpeakersComponent,
        FeedbackComponent,
        DurationComponent,
        DisplayNameComponent,
        RoomNameComponent,
        RoomInfoComponent,
        LobbyV2Component, 
        ReportComponent,
        ListRequestRoleComponent,
        MoreOptionsComponent,
        DeviceGuideComponent,
        TimeoutDialogComponent
    ],
    imports: [
        AgVirtualScrollModule,
        CommonModule,
        RealtimeCommunicationStateModule,
        SharedModuleRealtimeCommunication,
    ],
    exports: [
        ActivatingSpeakerComponent,
        BreakoutRoomManagerComponent,
        BreakRoomComponent,
        ChattingComponent,
        ChatboxComponent,
        SafariRecommenComponent,
        ExerciseComponent,
        JoiningOptionsComponent,
        LobbyOptionsComponent,
        LocalParticipantDetailComponent,
        LocalStreamingComponent,
        ListAudioComponent,
        ListParticipantComponent,
        ManagerComponent,
        QuizComponent,
        RealtimeCommunicationComponent,
        RemoteStreamingComponent,
        BreakRemoteStreamingComponent,
        RolesManagerComponent,
        SearchingParticipantComponent,
        SideBySideViewComponent,
        PolycomViewComponent,
        WhiteboardComponent,
        ViewingParticipantQuizComponent,
        VirtualBackgroundComponent,
        LobbyComponent,
        AudioComponent,
        MeetingTimeoutComponent,
        JoinAudioComponent,
        SettingsComponent,
        SpeakersComponent,
        FeedbackComponent,
        DurationComponent,
        DisplayNameComponent,
        RoomNameComponent,
        RoomInfoComponent,
        LobbyV2Component, 
        ReportComponent,
        ListRequestRoleComponent,
        MoreOptionsComponent,
        DeviceGuideComponent,
        TimeoutDialogComponent
    ],
    providers: [
        MediasoupService,
        QuizService,
        WhiteboardService,
        LatencyService,
        BreakRoomService,
        RoomService,
        SocketService,
        ConsumerService,
        PoolService,
        MediaStreamService,
        LobbyService,
        ListRequestRoleService,
        ChattingService,
        SettingsComponent,
        JoinAudioComponent
    ]
})
export class RealtimeCommunicationModule {
    constructor(@Optional() @SkipSelf() parentModule?: RealtimeCommunicationModule) {
        if (parentModule) {
            throw new Error(
                'RealtimeCommunicationModule is already loaded. Import it in the AppModule only');
        }
    }

    static forRoot(config: EdumeetRoomConfigModel): ModuleWithProviders<RealtimeCommunicationModule> {
        return {
            ngModule: RealtimeCommunicationModule,
            providers: [
                {provide: EdumeetRoomConfigModel, useValue: config}
            ]
        };
    }
}
