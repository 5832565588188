import randomString from 'random-string';
import deviceInfo from '../deviceInfo';
import {environment} from '@vnpt/oneui-ui/core/environments';

export class EdumeetRoomConfigModel {
    domain: any;
    whiteBoardDomain: string;
    peerId: string;
    accessCode: string;
    produce: boolean;
    forceTcp: boolean;
    displayName: string;
    muted: boolean;
    headless: string;
    showConfigDocumentationPath: boolean;
    device: any;
    basePath: string;

    constructor(domainConfig: any) {
        this.domain = domainConfig.socket;
        this.whiteBoardDomain = domainConfig.whiteBoard;
        this.peerId = randomString({ length: 8 }).toLowerCase();
        const urlParser = new URL(window.location.href);
        const parameters = urlParser.searchParams;
        const { pathname } = window.location;
        this.basePath = pathname.substring(0, pathname.lastIndexOf('/'));
        this.accessCode = parameters.get('code');
        this.produce = parameters.get('produce') !== 'false';
        this.forceTcp = parameters.get('forceTcp') === 'true';
        this.displayName = parameters.get('displayName');
        this.muted = parameters.get('muted') === 'true';
        this.headless = parameters.get('headless');
        this.showConfigDocumentationPath = parameters.get('config') === 'true';
        this.device = deviceInfo();
    }
}
