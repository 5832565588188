import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { Subscription } from 'rxjs';
import { DISPLAY_LAYOUT } from '../../../constants';
import {LocalParticipant} from '../../../models/local-participant.model';
import { RemoteParticipant } from '../../../models/remote-participant.model';
import { SpeakerModel } from '../../../models/speaker.model';
import { permissions } from '../../../permissions';
import {
    LocalParticipantService,
    SettingsService,
    RoomService,
    MediasoupService
} from '../../../services';
import { PoolService } from '../../../services/mediasoup/pool.service';
import { SpeakingService } from '../../../services/speaking.service';
@Component({
    selector: 'lib-local-participant-detail',
    templateUrl: './local-participant-detail.component.html',
    styleUrls: ['./local-participant-detail.component.less']
})
export class LocalParticipantDetailComponent implements OnInit, OnDestroy{
    participant: LocalParticipant;
    isSpeaking: boolean = false;
    isBlockAllWebcam: boolean = false;
    isBlockAllAudio = false;
    isJoinedAudio = false;
    subscriptions: Subscription[] = [];
    showUp: boolean = false;
    moreMenuVisible: boolean = false;
    isWebcamsOnlyForModerator: boolean = false;
    @Input() hasPermissionModerator = false;
    @Input() hasPermissionAdmin = false;
    @Input() numberOrder = -1;
    @Input() forceLayoutStatus =  false;
    checkToggleMic= false;
    checkToggleVideo = false;
    constructor(
        private localParticipantService: LocalParticipantService,
        private settingsService: SettingsService,
        private roomService: RoomService,
        private speakingService: SpeakingService,
        private mediasoupService: MediasoupService,
        private poolService: PoolService
    ) {
        this.participant = this.localParticipantService.getLocalParticipant(); 
    }

    ngOnInit(): void {
        this.onInitEvent();
        // this.checkRoleBreakroom();
    }

    ngOnDestroy(): void {
        for (const subs of this.subscriptions) {
            subs.unsubscribe();
        }
    }

    onInitEvent(): void {
        // this.subscriptions.push(this.settingsService.onVideoBlocked().subscribe((isBlockAllVideo: boolean) => {
        //     this.isBlockAllWebcam = isBlockAllVideo;
        // }));

        // this.subscriptions.push(this.settingsService.onAudioBlocked().subscribe((isBlockAllAudio: boolean) => {
        //     this.isBlockAllAudio = isBlockAllAudio;
        // }));
        
        this.subscriptions.push(this.settingsService.onJoinAudio().subscribe((isJoin: boolean) => {
            this.isJoinedAudio = isJoin;
        }));

        this.subscriptions.push(this.speakingService.activeSpeakerObserver.subscribe((speakingList: SpeakerModel[]) => {
            this.isSpeaking = speakingList.find(item => item.id == this.participant.id) != undefined;
        }));

        //Ktra trang thai WebcamsOnlyForModerator. True: disable button pin
        this.subscriptions.push(this.settingsService.onWebcamsOnlyForModerator().subscribe((flag: boolean) =>{
            this.isWebcamsOnlyForModerator = flag;
        }));

        this.localParticipantService.onRoles().subscribe((roles: number[]) => {
            // this.hasPermissionModerator = this.roomService.hasPermission(permissions.MODERATE_ROOM);
            this.isBlockAllAudio = !this.roomService.hasPermission(permissions.SHARE_AUDIO);
            this.isBlockAllWebcam = !this.roomService.hasPermission(permissions.SHARE_VIDEO);
            // this.hasPermissionAdmin = this.roomService.hasPermission(permissions.PROMOTE_PEER);
        });
    }

    async toggleMic(): Promise<void> {
        this.checkToggleMic = !this.checkToggleMic;
        this.participant.audio.state ? await this.roomService.muteMic() :
            await this.roomService.producerAudioStart();

        setTimeout(async () => {
            this.checkToggleMic = !this.checkToggleMic;
        }, 2000);


    }

    async toggleVideo(): Promise<void> {
        this.checkToggleVideo = !this.checkToggleVideo;
        this.participant.webcam.state ? await this.roomService.disableWebcam() :
            await this.roomService.producerVideoStart();

        setTimeout(async () => {
            this.checkToggleVideo = !this.checkToggleVideo;
        }, 2000);
    }

    changeDisplayName(): void {
        this.settingsService.setDisplayNameChange(true);
    }

    //bo sung tinh nang pin user local khi bat force layout
    pinCameraHandler():void{
        let layoutInfo = DISPLAY_LAYOUT.find(layout => layout.id == this.roomService.getForceLayout.layout);

        let isPin = !this.participant.isPin;
        if(this.roomService.getForceLayout && (layoutInfo.id != 0) && this.hasPermissionModerator ){

            let pinners = Array.from(this.poolService.getRemotesPin.map((remote) => remote.id));

            let remotes = this.poolService.getRemotesPin;
            let remote = new RemoteParticipant(this.localParticipantService.getLocalParticipant(), null, null)
            //Kiểm tra pin của remote
            if(pinners.length == layoutInfo.maxPin && !this.participant.isPin){
                let fr = remotes.shift();
                pinners.shift();
                fr.isPin = !isPin;
            }
            if(isPin){
                pinners.push(this.participant.id);
                remotes.push(remote);
            }else{
                remotes = remotes.filter(item => item.id !== remote.id);
                pinners = Array.from(remotes.map((remote) => remote.id))
            }
            this.roomService.moderatorPin(pinners).then((result) => {
                if(result){
                    //cập nhật trạng thái pin của remote và danh sách remotesPin khi thành công
                    this.participant.isPin = isPin;
                    this.poolService.setRemotesPin(remotes);
                }
            });    
            
        }
    }

    triggerVisibleQuickActionChange(flag): void {
        this.showUp = flag;
    }

    openMoreMenu(event): void {
        this.moreMenuVisible = event;
    }
}
