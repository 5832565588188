import { AfterViewInit, Component, ElementRef, OnInit, OnDestroy, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { NotificationService } from '../../../services/notification-service';
import { BaseMessage, ChatBox, LocalChatBox, RemoteChatBox, Conversation, FileMessage } from '../../../models/chatting.model';
import { LocalParticipantService, ChattingService } from '../../../services';
import { MediasoupService } from '../../../services/mediasoup.service';
import { Observable, Subscription } from 'rxjs';
import { LocalParticipant } from '../../../models/local-participant.model';
import { AgVirtualSrollComponent } from 'ag-virtual-scroll';
import { OneuiI18nService } from '@vnpt/oneui-i18n';
import urlRegex from 'url-regex';
import { RoomService } from '../../../services';
import { PoolService } from '../../../services/mediasoup/pool.service';
import { VModalService } from '@vnpt/oneui-ui/modal';

@Component({
    selector: 'lib-chatbox-list',
    templateUrl: './chatbox.component.html',
    styleUrls: ['./chatbox.component.less']
})
export class ChatboxComponent implements AfterViewInit, OnInit, OnDestroy {
    private eventsSubscription: Subscription | undefined;
    private eventsSub: Subscription | undefined;
    private eventsChatHistorySubscription: Subscription | undefined;
    private receiveSub: Subscription;
    private removeChatSub: Subscription;
    private eventUpdateConservationSub: Subscription;
    private eventOnViewShow: Subscription;
    @Input() type = 'chatting';
    @Input() remote: any;
    listChatBox: ChatBox[] = [];
    localParticipant: LocalParticipant;
    chatboxListViewHeight: number;
    chatboxListHeight: number;
    chatboxListViewObserver: ResizeObserver;
    chatboxListObserver: ResizeObserver;
    @ViewChild('chatboxListView', { static: true }) chatboxListView: ElementRef;
    @ViewChild('chatboxList', { static: true }) chatboxList: ElementRef;
    @ViewChild('vs', { static: false }) vs: AgVirtualSrollComponent;
    timeoutScroll: NodeJS.Timeout;
    flagScroll: boolean = false;
    keyFilter = 'all';
    unseenMessage = false;
    resetTab = 0;
    langChanges ='vi';
    // @Input()  openFilter!: boolean;
    // @Input() listOfOption!: Array<Object>;
    listOfOption : Array<Object>;
    @Output() keyFilterChange = new EventEmitter<string>();
    constructor(
        private notification: NotificationService,
        private localParticipantService: LocalParticipantService,
        private chattingService: ChattingService,
        private poolService : PoolService,
        private mediasoupService: MediasoupService,
        private i18nService: OneuiI18nService,
        private roomService: RoomService,
        private modalService: VModalService,
    ) {
        this.localParticipant = new LocalParticipant();
        this.i18nService.langChanges$.subscribe(evt=>{
            if(evt.type == "Success"){
                this.langChanges = this.i18nService.getActiveLang();
                this.listOfOption =  [
                    { label: this.i18nService.translate('chatting.filter.all',{},"Trò chuyện"), value: 'all' },
                    { label: this.i18nService.translate('chatting.filter.media',{},"Phương tiện"), value: 'media' },
                    { label: this.i18nService.translate('chatting.filter.files',{},"Tập tin"), value: 'file' },
                    { label: this.i18nService.translate('chatting.filter.links',{},"Liên kết"), value: 'link' }]
                    
            }
          });

          this.listOfOption =  [
            { label: this.i18nService.translate('chatting.filter.all',{},"Trò chuyện"), value: 'all' },
            { label: this.i18nService.translate('chatting.filter.media',{},"Phương tiện"), value: 'media' },
            { label: this.i18nService.translate('chatting.filter.files',{},"Tập tin"), value: 'file' },
            { label: this.i18nService.translate('chatting.filter.links',{},"Liên kết"), value: 'link' }]
    }

    ngOnInit(): void {
        this.onInitEvent();
        this.langChanges = this.i18nService.getActiveLang();
    }

    trackById(index: number, item) {
        return index;
    }

    ngAfterViewInit(): void {
        this.chatboxListViewObserver.observe(this.chatboxListView.nativeElement);
        this.chatboxListObserver.observe(this.chatboxList.nativeElement);

        /* this.chattingService.topicChangedBehavior.subscribe(topic => {
            this.keyFilter = "all";
            console.log("topicChangedBehavior: ", this.keyFilter)
            this.listChatBox = [];
            this.chattingService.getHistory(topic).then(conversation => {
                if(conversation == null) return;
                conversation.history?.forEach((chat: Chatting) => {
                    const latestDisplayName = this.getLatestDisplayName(chat.sender);
                    if (latestDisplayName) { chat.name = latestDisplayName; }
                    this.pushChatbox(chat);
                });
            });
           
        }); */

        this.chattingService.topicChangedBehavior.subscribe(topic => {
            this.unseenMessage = false;
            this.resetTab = 0;
        });
        this.chattingService.newMessageBehavior.subscribe(<T extends BaseMessage>(msg: T) => {
            if (!msg) return;
            if ((this.roomService.rightPaneView != 'chatting')
                || (this.chattingService.activeChat != msg.topic)
                || (this.chattingService.getFilterChat != 'all' 
                    && ((this.roomService.rightPaneView == 'chatting') || (this.chattingService.activeChat == msg.topic))
                    && (msg.sender != this.localParticipant.id))) {
                // an thong bao co tin nhan
                // this.popNotificationUp(msg);
                if (this.chattingService.activeChat == msg.topic) this.unseenMessage = true;
            } else {
                /* let content = msg.content; // msg.type === 'message' ? msg.content : msg.content.name;
                const prefix = msg.type === 'message' ? this.i18nService.translate('message') : this.i18nService.translate('file');
                // An thong bao co tin nhan
                this.notification.notificationWindow(this.i18nService.translate("notification.chatbox.receiveMessage",
                { name: msg.name, prefix: prefix }, "{{name}} đã gửi {{prefix}}"),content); */
            }

            if (
              this.chattingService.activeChat == msg.topic &&
              (msg.type == this.keyFilter ||
                this.keyFilter == "all" ||
                (this.keyFilter == "link" && msg.hasLink))
            ) {
              this.pushChatbox(msg);
            }
        });
        this.chattingService.deleteMessageBehavior.subscribe((msgId: string) => {
            let index = this.listChatBox.findIndex(msg => msg.message.id == msgId);
            if (index == -1) return;
            if(this.listChatBox[index].start && this.listChatBox.length-1 > index) {
                this.listChatBox[index+1].start = true;
            }
            this.listChatBox.splice(index, 1);
            
            if (this.checkShouldRender()) {
                this.updateRenderChat();
                if (this.vs) this.vs.el.scrollTop = this.vs.el.scrollTop - 40;
            }
        });
    }

    ngOnDestroy(): void {
        window.removeEventListener("showContainerRight", this.reloadList);
        clearTimeout(this.timeoutScroll);
        this.eventsSubscription.unsubscribe();
        this.eventsSub.unsubscribe();
        this.chatboxListViewObserver.disconnect();
        this.chatboxListObserver.disconnect();
        this.eventsChatHistorySubscription.unsubscribe();
        this.receiveSub.unsubscribe();
        this.removeChatSub.unsubscribe();
        this.eventUpdateConservationSub.unsubscribe();
    }

    onInitEvent(): void {
        window.addEventListener("showContainerRight", this.reloadList);
        this.localParticipantService.onPeerID().subscribe((peerID: string) => {
            this.localParticipant.id = peerID;
        });

        this.localParticipantService.onDisplayName().subscribe((displayName: string) => {
            this.localParticipant.displayName = displayName;
        });

        /* this.receiveSub = this.chattingService.receiveChatting().subscribe((msg: Chatting) => {
            if (msg) {
                this.chattingService.incUnseenMessageCounting();
                if (this.chattingService.containerRightType != 'chatting') {
                    this.popNotificationUp(msg);
                } else {
                    let content = msg.type === 'message' ? msg.content : msg.content.name;
                    const prefix = msg.type === 'message' ? this.i18nService.translate('message') : this.i18nService.translate('file');
                    this.notification.notificationWindow(this.i18nService.translate("notification.chatbox.receiveMessage",
                    { name: msg.name, prefix: prefix }, "{{name}} đã gửi {{prefix}}"),content);
                }
                this.pushChatbox(msg);
                if (this.checkShouldRender()) {
                    this.updateRenderChat();
                    if (this.vs) this.vs.el.scrollTop = this.vs.el.scrollTop - 40;
                }
                this.scrollToBottom(true);
            }
        }); */

        this.eventUpdateConservationSub = this.chattingService.onUpdateConversation().subscribe((sender: string) => {
            if (sender) {
                this.listChatBox = this.listChatBox.map((chatbox: ChatBox) => {
                    if (chatbox.message.sender === sender) {
                        const latestDisplayName = this.getLatestDisplayName(chatbox.message.sender);
                        if (latestDisplayName) { chatbox.message.name = latestDisplayName; }
                    }
                    return chatbox;
                });
            }
        });

        // @ts-ignore
        this.chatboxListViewObserver = new ResizeObserver((entries: any) => {
            this.chatboxListViewHeight = entries[0].contentRect.height;
            // console.log('this.chatboxListViewHeight ', this.chatboxListViewHeight);
        });

        // @ts-ignore
        this.chatboxListObserver = new ResizeObserver((entries: any) => {
            this.chatboxListHeight = entries[0].contentRect.height;
        });

        this.chattingService.onFilterChat().subscribe(async (key: string) => {
          if (key) {
            this.keyFilter = key;
            var conversation: Conversation = await this.chattingService.getHistory();
            if(!conversation) return;

            if(this.unseenMessage && key == 'all' && conversation?.unreadMessageCount == 0){
                this.unseenMessage = false;
            }
            // if(conversation?.unreadMessageCount > 0 && key != 'all'){
            //     this.unseenMessage = true;
            // }else{
            //     this.unseenMessage = false;
            // }
            var historyChat = conversation?.history;
            switch (key) {
              case "link":
                historyChat = historyChat?.filter(
                  (e) => e.hasLink && e.type == "message"
                );
                break;
              case "all":
                break;
              default:
                historyChat = historyChat?.filter((e) => e.type == key);
                break;
            }
            this.listChatBox = [];
            historyChat?.forEach((chat: BaseMessage) => {
              const latestDisplayName = this.getLatestDisplayName(chat.sender);
              if (latestDisplayName) {
                chat.name = latestDisplayName;
              }
              this.pushChatbox(chat);
            });
          }
        });
    }

    reloadList = (evt: any) => {
        if (evt.detail.screen == 'chatting') {
            if (this.checkShouldRender()) {
                this.updateRenderChat();
                this.scrollToBottom(true);
            }
        }
    }

    onItemsRender(event) {
        if (this.flagScroll && event.endIndex == this.listChatBox.length - 1) {
            if (this.timeoutScroll) {
                clearTimeout(this.timeoutScroll);
            }
            this.timeoutScroll = setTimeout(() => {
                this.vs.el.scrollTop = this.vs.el.scrollHeight;
                clearTimeout(this.timeoutScroll);
            }, 100);
            this.flagScroll = false;
        }
    }

    processChatContent(msg: BaseMessage): BaseMessage {
        let newMessage: BaseMessage = {...msg};
        if (newMessage.type == 'message' && newMessage.hasLink) {
            // const urlList = newMessage.content.match(urlRegex());
            // let urls = Array.from(new Set(urlList))
            
            // if (urls && urls.length > 0) {
            //     urls.forEach(element => {
            //         newMessage.content = newMessage.content.replaceAll(element, "<a href=" + element + " target='_blank'>" + element + "</a>");
            //     });
            //     newMessage.hasLink = true;
                
            // }
            // @ts-ignore
            newMessage.content = newMessage.content.replaceAll(/&/g, " &amp; ")
                    .replaceAll(/</g, " &lt;")
                    .replaceAll(/>/g, "&gt; ");
            // newMessage.content = newMessage.content.replaceAll(/"/g, " &quot; ")
            // newMessage.content = newMessage.content.replaceAll(/'/g, " &#39; ");
            const reg = /((((ftp|https?):\/\/)|(w{3}\.))[\-\w@:%_\+.~#?,&\/\/=]+)/g;
            // @ts-ignore
            newMessage.content = newMessage.content.replaceAll(reg, (m) => (`<a target="_blank" href="${m}">${m}</a>`))
            newMessage.hasLink = true;
        }
        return newMessage;
    }

    pushChatbox<T extends BaseMessage>(chat: T): void {
        if (!chat) return;
        const latestChatBox: ChatBox = this.getLatestChatBox();
        let newLatestChatBox: ChatBox;
        let chatContent: BaseMessage = this.processChatContent(chat);
        newLatestChatBox = this.isLocalChatting(chat.sender) ? new LocalChatBox(chatContent) : new RemoteChatBox(chatContent);
        if (latestChatBox && latestChatBox.message.sender === chat.sender) {
            newLatestChatBox.start = false;
        }
        // newLatestChatBox = {...newLatestChatBox, ...this.processChatContent(chat)};
        if(newLatestChatBox.message.type == "file") {            
            newLatestChatBox.message = this.showFileName(newLatestChatBox.message as FileMessage);
        }
        this.listChatBox.push(newLatestChatBox);
        if (this.checkShouldRender()) {
            this.updateRenderChat();
            // if (this.vs) this.vs.el.scrollTop = this.vs.el.scrollTop - 40;
        }
        window.setTimeout(this.scrollToBottom.bind(this, true), 100);
    }

    removeChatBox(chat: ChatBox): void {
        this.listChatBox.splice(this.listChatBox.indexOf(chat), 1);
        if (this.checkShouldRender()) {
            this.updateRenderChat();
            this.vs.el.scrollTop = this.vs.el.scrollTop - 40;
        }
    }

    popNotificationUp(msg: BaseMessage): void {
        let content = msg.content; // msg.type === 'message' ? msg.content : msg.content.name;
        const prefix = msg.type === 'message' ? this.i18nService.translate('message') : this.i18nService.translate('file');
        this.notification.info(this.i18nService.translate("notification.chatbox.receiveMessage",
            { name: msg.name, prefix: prefix }, "{{name}} đã gửi {{prefix}}"), content, null, this.onClickNoti.bind(this,msg));
    }

    onClickNoti(msg): void {
        this.chattingService.setActiveChat(msg.topic);
        this.roomService.rightPaneView = "chatting";
    }

    scrollToBottom(isOpen: boolean = false): void {
        if (this.vs) {
            // const chatListView = document.getElementById('chat-listview');
            const chatListView = this.vs.el;
            if (Math.abs(chatListView.scrollTop - chatListView.scrollHeight) < 1000 || isOpen) {
                chatListView.scrollTop = chatListView.scrollHeight;
                chatListView.scrollTop = chatListView.scrollTop - 15;
                this.flagScroll = true;
                // clearTimeout(this.timeoutScroll);
                // this.timeoutScroll = setTimeout(() => {
                //     chatListView.scrollTop = chatListView.scrollHeight;
                // }, 0);
            }
        }
    }

    isLocalChatting(sender: string): boolean {
        return sender === this.localParticipant.id;
    }

    getLatestChatBox(): ChatBox {
        const length = this.listChatBox.length;
        return length ? this.listChatBox[length - 1] : null;
    }

    setLatestChatBox(chat: BaseMessage): void {
        const length = this.listChatBox.length;
        const latestChatBox = this.listChatBox[length - 1];
        this.listChatBox[length - 1] = {
            ...latestChatBox,
            ...chat
        };
    }

    showFileName(fileMess: FileMessage): BaseMessage {
        if (!fileMess) return fileMess;
        const getName = fileMess.fileName.split('/');
        const name = decodeURI(getName[getName.length - 1]);
        if (name.length > 36) {
            fileMess.shortName = `${name.slice(0, 26)}...${name.slice(name.lastIndexOf('.') - 1)}`;
            return fileMess;
        }
        fileMess.shortName = name;
        return fileMess;
    }

    formatBytes(bytes: any, decimals = 2): string {
        if (bytes === 0) { return '0 Bytes'; }

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    downloadFile(url: string): void {
        const link = document.createElement('a');
        link.href = url;
        link.download = url.split('/').pop();
        document.body.appendChild(link);
        link.click();
        link.remove();
        document.body.removeChild(link);
    }

    getLatestDisplayName(sender: string): string {
        return this.poolService.getLatestDisplayName(sender);
    }

    showIcon(content): string {
        if (!content) return null;
        const ext = content.match(/\.[0-9a-z]+$/i)[0];
        switch (ext) {
            case '.pdf':
                return 'PDF';
            case '.doc':
            case '.docx':
            case '.odt':
            case '.rtf':
                return 'WordDocument';
            case '.xls':
            case '.xlsx':
            case '.ods':
                return 'ExcelDocument';
            case '.ppt':
            case '.pptx':
            case '.odp':
                return 'PowerPointDocument';
            default:
                return 'OpenFile';
        }
    }

    deleteMessage(chat: ChatBox): void {
        this.modalService.confirm({
            vTitle: this.i18nService.translate("chatting.deleteTitle",{},"Xóa tin nhắn"),
            vContent: this.i18nService.translate("chatting.deleteContent",{},"Bạn có thực sự muốn xóa tin nhắn không?"),
            vOkText: this.i18nService.translate("ok",{},"Đồng ý"),
            vCancelText: this.i18nService.translate("cancel",{},"Hủy"),
            vOnOk: ()=> this.chattingService.deleteMessage(chat.message.id),
            vOkDanger: true,
          });
    }

    checkShouldRender() {
        return this.chatboxListHeight != 0 && this.roomService.rightPaneView == 'chatting';
    }

    updateRenderChat() {
        this.listChatBox = [...this.listChatBox];
    }

    //filter tin nhan
    filter(key: string): void {
        this.chattingService.setFilterChat(key);
    }
}
