import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import {RoomService} from '../../../../realtime-communication/src/lib/services';

@Injectable({
    providedIn: 'root',
})
export class AuthGuardService implements CanActivate {

    tokenQueryParams: string;
    constructor(
        private router: Router,
        private roomService: RoomService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.checkToken(route);
    }

    canDeactivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        this.roomService.resetRoomTransports();
        this.roomService.closeRoom();
        return of(false);
    }

    checkToken(route: ActivatedRouteSnapshot): Observable<boolean> {
        if (route.queryParams?.sessionToken) {
            return of(true);
        }
        this.router.navigateByUrl('/join');
        return of(false);
    }

}
