import {Action} from '@ngrx/store';

export const ADD_TRANSPORT_STATS = 'add transport stats';

export class AddTransportStats implements Action{
    readonly type = ADD_TRANSPORT_STATS;
    public payload: any;
    constructor(transport, type) {
        this.payload = {transport, type};
    }
}

export type All = AddTransportStats;
