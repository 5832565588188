<div class="video-stream" (mousemove)="showOptions(true)" (mouseout)="showOptions(false)">
    <video [style]="{'z-index' : isSwapped || participant.webcam.consumerId ? '2' : '1'}" class="video-screen"
           [ngClass]="{ 'full-height': isOnBreakroom && !followedByWidth, 'full-width': followedByWidth }" 
            #remoteVideoRef autoplay="true" muted="muted">
    </video>

    <div [@fade]="!isSwapped && (!participant.webcam.consumerId || participant.webcam.isLocallyPaused) ? 'appear': 'disappear'" class="logo-box">
        <img style="height: 30%" src="assets/images/logo-vnpt.png"/>
    </div>

    <button vnpt-button vType="default" title="Thêm" class="more-btn" 
            vnpt-dropdown [vDropdownMenu]="moreMenu" vTrigger="click" 
            [ngClass]="{ 'd-none' : !isShowOption, 'on-video': participant.webcam.consumerId && !participant.webcam.isLocallyPaused }"
            [vPlacement]="'bottomRight'" [(vVisible)]="dropdownVisible">
        <i vnpt-icon vType="MoreVertical" class="icon-white"></i>
    </button>

    <div class="left-icon-holder">
        <div class="raised-hand" [hidden]="!participant.isRaisedHand">
            <i class="icon-gomeet-raise-hand"></i>
        </div>
    </div>

    <div [hidden]="!participant.screen.consumerId" class="right-overlay" [style]="{ 'height': wrapperHeight }">
        <div class="video-stream" style="background-color: transparent;">
            <video [ngClass]="{ 'full-height': !followedByWidth, 'full-width': followedByWidth }"
                   #sharingScreenRef playsinline autoplay="true" muted="muted"
                   (click)="expandShareScreen()"
            ></video>
        </div>
    </div>

    <div vnpt-row vAlign="middle" class="participant-info">
        <div vnpt-row vAlign="middle" class="info-wrapper">
            <i  vnpt-col class="audio" vnpt-icon 
                *ngIf="!participant.audio.state || participant.audio.isLocallyPaused" 
                [vType]="!participant.audio.state ? 'MicOff2' : 'MicOff'"></i>
            <span *ngIf="!isTeacher"vnpt-col class="participant-name">{{participant.displayName}}</span>
            <span *ngIf="isTeacher" vnpt-col class="participant-name">{{participant.displayName}} (Giáo viên)</span>
        </div>

        <div *ngIf="score !== 10" class="info-wrapper">
            <i vnpt-icon vType="BarChart4" [class]="coreColor"></i>
        </div>
    </div>
</div>

<vnpt-dropdown-menu #moreMenu>
    <ul vnpt-menu>
        <li *ngIf="participant.webcam.consumerId" vnpt-menu-item (click)="expandVideo()">
            <i vnpt-icon vType="FullView" [vSize]="'sm'"></i>
            <span>Fullscreen</span>
        </li>
        <li *ngIf="participant.webcam.consumerId" vnpt-menu-item (click)="toggleRemoteVideo()">
            <i vnpt-icon vType="FullView" [vSize]="'sm'"
               [vType]="participant.webcam.isLocallyPaused ? 'Video' : 'VideoOff2'"></i>
            <span>{{participant.webcam.isLocallyPaused ? 'Mở video' : 'Tắt video'}}</span>
        </li>
        <li *ngIf="participant.audio.consumerId" vnpt-menu-item (click)="toggleRemoteAudio()">
            <i vnpt-icon vType="FullView" [vSize]="'sm'"
               [vType]="participant.audio.isLocallyPaused ? 'Microphone' : 'MicOff2'"></i>
            <span>{{participant.audio.isLocallyPaused ? 'Mở audio' : 'Tắt audio'}}</span>
        </li>

    </ul>
</vnpt-dropdown-menu>


