/* eslint-disable no-console */
/* eslint max-len: ["error", { "code": 200 }] */

import * as PeersActions from './peers.actions';

export type Action = PeersActions.All;

const initialState = {};

const PeerReducer = (state: any = initialState, action: Action, root) =>
{
    switch (action.type)
    {
        case PeersActions.ADD_PEER:
            return action.payload.peer;

        case PeersActions.SET_PEER_DISPLAY_NAME:
            return { ...state, displayName: action.payload.displayName };

        case PeersActions.SET_PEER_VIDEO_IN_PROGRESS:
            return { ...state, peerVideoInProgress: action.payload.flag };

        case PeersActions.SET_PEER_AUDIO_IN_PROGRESS:
            return { ...state, peerAudioInProgress: action.payload.flag };

        case PeersActions.SET_PEER_SCREEN_IN_PROGRESS:
            return { ...state, peerScreenInProgress: action.payload.flag };

        case PeersActions.SET_PEER_KICK_IN_PROGRESS:
            return { ...state, peerKickInProgress: action.payload.flag };

        case PeersActions.SET_PEER_MODIFY_ROLES_IN_PROGRESS:
            return { ...state, peerModifyRolesInProgress: action.payload.flag };

        case PeersActions.SET_PEER_RAISED_HAND:
            return {
                ...state,
                raisedHand          : action.payload.raisedHand,
                raisedHandTimestamp : action.payload.raisedHandTimestamp
            };

        case PeersActions.SET_PEER_RAISED_HAND_IN_PROGRESS:
            return {
                ...state,
                raisedHandInProgress : action.payload.flag
            };

        // case PeersActions.ADD_CONSUMER:
        // {
        //     const consumers = [ ...state.consumers, action.payload.consumer.id ];
        //
        //     return { ...state, consumers };
        // }

        // case PeersActions.REMOVE_CONSUMER:
        // {
        //     const consumers = state.consumers.filter((consumer) =>
        //         consumer !== action.payload.consumerId);
        //
        //     return { ...state, consumers };
        // }

        case PeersActions.SET_PEER_PICTURE:
        {
            return { ...state, picture: action.payload.picture };
        }

        case PeersActions.ADD_PEER_ROLE:
        {
            const roles = [ ...state.roles, action.payload.roleId ];

            return { ...state, roles };
        }

        case PeersActions.REMOVE_PEER_ROLE:
        {
            const roles = state.roles.filter((roleId) =>
                roleId !== action.payload.roleId);

            return { ...state, roles };
        }

        case PeersActions.STOP_PEER_AUDIO_IN_PROGRESS:
            return {
                ...state,
                stopPeerAudioInProgress : action.payload.flag
            };

        case PeersActions.STOP_PEER_VIDEO_IN_PROGRESS:
            return {
                ...state,
                stopPeerVideoInProgress : action.payload.flag
            };

        case PeersActions.STOP_PEER_SCREEN_SHARING_IN_PROGRESS:
            return {
                ...state,
                stopPeerScreenSharingInProgress : action.payload.flag
            };

        case PeersActions.SET_PEER_LOCAL_RECORDING_STATE:
            return {
                ...state,
                localRecordingState : action.payload.localRecordingState
            };

        default:
            return state;
    }
};

export const PeersReducer = (state = initialState, action: Action) =>
{
    switch (action.type)
    {
        case PeersActions.ADD_PEER:
        {
            return { ...state, [action.payload.peer.id]: PeerReducer(undefined, action, undefined) };
        }

        case PeersActions.REMOVE_PEER:
        {
            const { peerId } = action.payload;
            const newState = { ...state };

            delete newState[peerId];

            return newState;
        }

        case PeersActions.SET_PEER_DISPLAY_NAME:
        case PeersActions.SET_PEER_VIDEO_IN_PROGRESS:
        case PeersActions.SET_PEER_AUDIO_IN_PROGRESS:
        case PeersActions.SET_PEER_SCREEN_IN_PROGRESS:
        case PeersActions.SET_PEER_RAISED_HAND:
        case PeersActions.SET_PEER_RAISED_HAND_IN_PROGRESS:
        case PeersActions.SET_PEER_PICTURE:
        // case PeersActions.ADD_CONSUMER:
        case PeersActions.ADD_PEER_ROLE:
        case PeersActions.REMOVE_PEER_ROLE:
        case PeersActions.STOP_PEER_AUDIO_IN_PROGRESS:
        case PeersActions.STOP_PEER_VIDEO_IN_PROGRESS:
        case PeersActions.STOP_PEER_SCREEN_SHARING_IN_PROGRESS:
        case PeersActions.SET_PEER_KICK_IN_PROGRESS:
        case PeersActions.SET_PEER_MODIFY_ROLES_IN_PROGRESS:
        // case PeersActions.REMOVE_CONSUMER:
        case PeersActions.SET_PEER_LOCAL_RECORDING_STATE:
        {
            const oldPeer = state[action.payload.peerId];

            // NOTE: This means that the Peer was closed before, so it's ok.
            if (!oldPeer) {
                return state;
            }

            return { ...state, [oldPeer.id]: PeerReducer(oldPeer, action, undefined) };
        }

        case PeersActions.CLEAR_PEERS:
        {
            return initialState;
        }

        default:
            return state;
    }
};
