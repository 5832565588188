/* tslint:disable:variable-name prefer-const */
import { Injectable } from '@angular/core';
import { Producer } from 'mediasoup-client/lib/Producer';
import { Transport } from 'mediasoup-client/lib/Transport';
import * as mediasoupClient from 'mediasoup-client';
import { Device } from 'mediasoup-client';
import { DtlsParameters, IceCandidate, IceParameters } from 'mediasoup-client/lib/Transport';
import { timer } from "rxjs";
import { config } from '../../../config';
import { ProducerModel } from '../../models/producer.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { RoomConfigModel, getDefaultRoomConfig } from '../../models/room-config.model';
import { RemoteParticipant } from '../../models/remote-participant.model';
import { DISPLAY_LAYOUT, LIST_ROLE_ID, PERMISSIONS, REPORT_TYPE } from "../../constants";
import { createMediaFilterStream, stopMediaFilterStream } from '../../mediaUtils';
// @ts-ignore
import {
    DEFAULT_NETWORK_PRIORITIES,
    PC_PROPRIETARY_CONSTRAINTS,
    VIDEO_CONSTRAINS,
    VIDEO_KSVC_ENCODINGS,
    VIDEO_SIMULCAST_PROFILES,
    VIDEO_SVC_ENCODINGS,
    LAYOUT_MODE
} from '../../constants';
import hark from 'hark';
import { ScreenShareDefault } from '../../models/share-screen.model';

import {
    ProducerService,
    LocalParticipantService,
    SettingsService,
    DeviceCountingService,
    VirtualBackgroundService,
    ActiveSpeakerService, ConsumerService, RolesManagerService, ChattingService, ShareDataService,
    MediaStreamService,
    LobbyService,
    AudioDeviceService,
    NotificationService,
    RecordService,
    SecurityService,
    LatencyService
} from '../index';

import { SocketService } from '../socket.service';

import ScreenShare from '../../ScreenShare';
import deviceInfo from '../../deviceInfo';
import { JoinResponseModel, ROOM_TYPE } from '../../models/join-response.model';
import { UserRoleModel } from '../../models/user-role.model';
import { LayoutModel } from '../../models/layout.model';
import { EdumeetRoomConfigModel } from '../../models/edumeet-room-config.model';
import { CurrentVideoConsumer, VideoStatus } from '../../models/consumer.model';
import { PoolService } from './pool.service';
import { permissions } from '../../permissions';

type TState = 'new' | 'connecting' | 'connected' | 'disconnected' | 'failed' | 'closed';
type TPeer = 'producer' | 'consumer';
type TKind = 'video' | 'audio';
import { APPSOURCE } from '../../constants';
import { HttpClient } from '@angular/common/http';
import { OneuiI18nService } from '@vnpt/oneui-i18n';
import { SpeakerModel, HarkStream } from '../../models/speaker.model';
import { ParticipantModel } from '../../models/participant.model';
import { SpeakingService } from '../speaking.service';
import { TimeService, timeService } from '../time.service';
import { RecordClientService } from '../record-client.service';

// @ts-ignore
const insertableStreamsSupported = Boolean(RTCRtpSender.prototype.createEncodedStreams);

export type RightPaneViewType = "" | "chatting" | "listParticipant" | "lobby";
@Injectable({
    providedIn: 'root'
})
export class RoomService {
    constructor(
        private http: HttpClient,
        private poolService: PoolService,
        private socketService: SocketService,
        private producerService: ProducerService,
        private localParticipantService: LocalParticipantService,
        private settingsService: SettingsService,
        private deviceCountingService: DeviceCountingService,
        private virtualBackgroundService: VirtualBackgroundService,
        private activeSpeakerService: ActiveSpeakerService,
        private consumerService: ConsumerService,
        private userRolesService: RolesManagerService,
        private chattingService: ChattingService,
        private shareDataService: ShareDataService,
        private mediaStreamService: MediaStreamService,
        private lobbyService: LobbyService,
        private audioDeviceService: AudioDeviceService,
        private notification: NotificationService,
        private recordService: RecordService,
        private securityService: SecurityService,
        private i18nService: OneuiI18nService,
        private speakingService: SpeakingService,
        private latencyService: LatencyService,
        private recordClientService: RecordClientService
    ) {
        this.mediasoupDevice = new mediasoupClient.Device();
        this._useSharingSimulcast = config.simulcastSharing;
        this._screenSharingProducer = null;
        this._screenSharingAudioProducer = null;
        this._device = deviceInfo();
        this._screenSharing = ScreenShare.create(this._device);
    }
    private timeLog: TimeService = timeService;
    private _appConfig: any = {};

    private _socketConnectionState = new BehaviorSubject<string>("");

    private _rightPaneView = new BehaviorSubject<RightPaneViewType>("");

    private _producerVideo: Producer;
    private _producerAudio: Producer;

    private _producerTransport: Transport;
    private _consumerTransport: Transport;


    private _recvRestartIce = { timer: null, restarting: false };
    private _sendRestartIce = { timer: null, restarting: false };

    private callbackTransportState: (transport, ice, delay) => Promise<void>;

    private _turnServers: RTCIceServer[];

    private _mediasoupDevice: Device;

    private _harkStream = null;

    private _harkStreamRemote:HarkStream[]= [];

    private _quality = new BehaviorSubject<string>('HD');

    private _screenSharing: any;

    private _useSharingSimulcast: any;

    private _screenSharingProducer: any;

    private _screenSharingAudioProducer: any;

    private numberOfTurningOnWebcamSubject = new BehaviorSubject<number>(0);

    private isJoinedSubject = new BehaviorSubject<boolean>(false);

    private roomPermissionSubject = new BehaviorSubject<Map<string, UserRoleModel>>(null);

    private roomTypeSubject = new BehaviorSubject<ROOM_TYPE>('NORMAL');

    private roomIdSubject = new BehaviorSubject<string>(null);

    private prepareRoomSubject = new BehaviorSubject<boolean>(null);

    private isProcessingVideoSubject = new BehaviorSubject<boolean>(null);
    private isProcessingAudioSubject = new BehaviorSubject<boolean>(null);
    private isMeetingTimeout: BehaviorSubject<Object> = new BehaviorSubject<Object>(null);
    private isBroadcastData = new BehaviorSubject<object>(null);
    private isWhiteboardUndo = new BehaviorSubject<string>(null);
    private isWhiteboardClear = new BehaviorSubject<boolean>(null);
    private layoutModeSubject = new BehaviorSubject<LAYOUT_MODE>(null);
    private isWhiteboardData = new BehaviorSubject<Array<object>>(null);
    private isWhiteboard = new BehaviorSubject<boolean>(false);
    private roomNotify = new BehaviorSubject<object>(null);
    private meetingEnded = false;
    private audioTransport = new BehaviorSubject<Transport>(null);
    private audioConsumerTransport = new BehaviorSubject<Transport>(null);
    private isWhiteboardLocked = new BehaviorSubject<boolean>(true);
    private isConnectedTransport = new BehaviorSubject<boolean>(true);
    private mediaConnectionStateObserver = new BehaviorSubject<string>("closed");

    private joinData: JoinResponseModel;

    private _device: any;
    private isDisableAudio = false;
    private isDisableVideo = false;
    private eduMeetRoomConfig: EdumeetRoomConfigModel;

    private roomConfig: RoomConfigModel = getDefaultRoomConfig();
    private timeLeftRoom: number = 0;

    private connectionStats: any;

    private forceLayoutSubject = new BehaviorSubject<LayoutModel>({layout: 0, pinners:[], hideNoneCamera: false});
    private forceLayoutStatusSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private speechLocalEvents: any;

    localStream: any;
    originAudioStream: MediaStream;
    
    delayAudioScreenIds: string[] = [];
    screenPause = false;

    audioIn: MediaStreamAudioSourceNode = null;
    audioSCIn: MediaStreamAudioSourceNode = null;
    public setConnectedTransport(connected: boolean){
        this.isConnectedTransport.next(connected);
    }

    public onIsConnectedTransport():Observable<boolean>{
        return this.isConnectedTransport.asObservable();
    }

    public setMediaConnectionState(state: string){
        this.mediaConnectionStateObserver.next(state);
    }

    public onMediaConnectionStateChange():Observable<string>{
        return this.mediaConnectionStateObserver.asObservable();
    }

    public setForceLayout(layout: number, pinners: Array<string>, hideNoneCamera: boolean) {
        this.forceLayoutSubject.next({ layout: layout, pinners: pinners, hideNoneCamera: hideNoneCamera });
    }

    get getForceLayout(): LayoutModel {
        return this.forceLayoutSubject.value;
    }

    get forceLayoutStatus(): Object {
        return this.forceLayoutStatusSubject.value;
    }

    public setForceLayoutStatus(status: boolean) {
        this.forceLayoutStatusSubject.next(status);
    }

    public onForceLayoutStatusObservable(): Observable<boolean> {
        return this.forceLayoutStatusSubject.asObservable();
    }

    public onForceLayoutObservable(): Observable<Object> {
        return this.forceLayoutSubject.asObservable();
    }

    get turnServers(): RTCIceServer[] {
        return this._turnServers;
    }

    set turnServers(turnServers: RTCIceServer[]) {
        this._turnServers = turnServers;
    }

    get mediasoupDevice(): Device {
        return this._mediasoupDevice;
    }

    set mediasoupDevice(mediasoupDevice: Device) {
        this._mediasoupDevice = mediasoupDevice;
    }

    get producerVideo(): Producer {
        return this._producerVideo;
    }

    set producerVideo(producerVideo: Producer) {
        this._producerVideo = producerVideo;
    }

    get producerAudio(): Producer {
        return this._producerAudio;
    }

    set producerAudio(producerAudio: Producer) {
        this._producerAudio = producerAudio;
    }

    get producerTransport(): Transport {
        return this._producerTransport;
    }

    set producerTransport(producerTransport: Transport) {
        this._producerTransport = producerTransport;
    }

    get consumerTransport(): Transport {
        return this._consumerTransport;
    }

    set consumerTransport(consumerTransport: Transport) {
        this._consumerTransport = consumerTransport;
    }

    get quality(): string {
        return this._quality.value;
    }

    set quality(quality: string) {
        this._quality.next(quality);
    }

    onQuality(): Observable<string> {
        return this._quality.asObservable();
    }

    /* private initRoomDataToPool(joinData: JoinResponseModel): void {
        this.poolService.setJoinData(this.joinData);
        this.shareDataService.initJoinData();
    } */

    public isBreakRoomLayoutFromJoinData(): boolean {
        return this.joinData.groupMap && Object.keys(this.joinData.groupMap).length > 0;
    }

    public set rightPaneView(viewType: RightPaneViewType) {
        this.chattingService.containerRightType = viewType;
        this._rightPaneView.next(viewType);
    }

    public get rightPaneViewObservable() {
        return this._rightPaneView.asObservable();
    }

    public get rightPaneView(): RightPaneViewType {
        return this._rightPaneView.getValue();
    }

    public getLayout(): string {
        if (this.isBreakRoomLayoutFromJoinData()) {
            return 'breakroom';
        }
        return 'default';
    }

    public getTeacherId(): string {
        return this.joinData.teacherId;
    }


    public setJoinData(joinData: JoinResponseModel, participants: Array<RemoteParticipant>): void {
        this.joinData = joinData;
        this.poolService.deleteAllPeer();
        this.poolService.setJoinData(joinData);
        if (joinData.lobbyPeers && joinData.lobbyPeers.length != 0) {
            this.lobbyService.addLobbyList(joinData.lobbyPeers);
        }
        // this.initRoomDataToPool(joinData);
        participants.forEach(p => {
            this.poolService.addPeer(p, false);
        });
        this.shareDataService.initJoinData();
    }

    public getJoinData(): JoinResponseModel {
        return this.joinData;
    }

    public async initJoinRoom(joinData: JoinResponseModel, participants: Array<RemoteParticipant>, isRecordingUser: boolean): Promise<void> {
        if (!joinData) { return; }
        if(isRecordingUser) this.poolService.setMaxItem(5);
        try {
            this.setJoinData(joinData, participants);
            this.setJoinFlag(true);
            // this.setRoomPermission(joinData.roomPermissions);
            this.poolService.setVideoConsumerMap(joinData.videoConsumerMap);
            this.setNumberOfTunringOnWebcam(joinData.numberOfTurningOnWebcams);

            this.setRoomType(joinData.roomType);

            const { userRoles } = joinData;
            this.checkAudioAndVideoPermission(userRoles, joinData.roles);

            // const { videoConsumerMap } = await this.getCurrentVideoConsumer(); //Backend đã xác nhận là không dùng sự kiện này nữa
            
            this.reProduceScreenSharing(new Map(Object.entries(joinData.videoConsumerMap)));

            // this.chattingService.initChatHistory(joinData.chatHistory);
            this.chattingService.initActiveChat(joinData.activeChats);
        } catch (error) {
            console.log(error);
            this.sendReport('other' as REPORT_TYPE, '', this.initJoinRoom.name + error);
        }
    }

    async join({ displayName, rtpCapabilities }): Promise<JoinResponseModel> {
        return this.socketService.sendRequest(
            'join',
            {
                displayName,
                picture: null,
                rtpCapabilities,
                returning: false,
                platform: {
                    type: this._device.platform, 
                    os: this._device.os, 
                    software: this._device.name,
                    version: this._device.version
                },
            });
    }

    public get socketConnectionState(): string {
        return this._socketConnectionState.getValue();
    }

    public get socketConnectionStateObserver() {
        return this._socketConnectionState.asObservable();
    }

    public setSocketConnectionState(state: string) {
        this._socketConnectionState.next(state);
    }

    public onSocket(): void {
        this.socketService.onSocket('connect', async () => {
            console.log("socket is established");
            this.setSocketConnectionState("connected");
        });
        this.socketService.onSocket('disconnect', (reason) => {
            console.log("### socket io disconnected:", reason);

            if (reason === 'io server disconnect') {
                this.timeLog.setEndTime('')
                // this.roomService.closeRoom(this.i18nService.translate("service.mediasoup-base.outMeeting", {}, "Bạn đã rời khỏi cuộc họp"));
                this.setSocketConnectionState("disconnected");
                // this.socketService.reconnect();
            }
            if (reason === 'transport close') {
                this.setSocketConnectionState("disconnected");
            }

            // notify: You are disconnected, attempting to reconnect
            // this.resetRoomTransports();
        });
        this.socketService.onSocket('notification', async (notification: { data: any, method: string }) => {
            switch (notification.method) {
                case 'roomReady': {
                    const { turnServers, roomConfig, timeLeft, duration, isRecording } = notification.data;
                    
                    roomConfig.endTime = roomConfig.duration*60000 + roomConfig.startTime;
                    this.roomConfig = roomConfig;
                    this.settingsService.setRoomConfig(roomConfig);
                    this.recordService.setRecord((roomConfig.record && roomConfig.allowStartStopRecording));
                    this.securityService.setLockRoom(this.roomConfig.locked);
                    this.settingsService.setIsGrantcreenSharingPermission(this.roomConfig.publicScreenShare);
                    if (duration) {
                        this.recordService.setTime(duration);
                        this.recordService.setIsRecording(isRecording);
                    }

                    if (this.roomConfig.layout != 0) {
                        this.setForceLayout(this.roomConfig.layout, this.roomConfig.pinners, this.roomConfig.hideNoneCamera);
                    }
                    if (this.roomConfig.muteOnStart) {
                        this.settingsService.setAudioMuted(true);
                    }

                    if (this.roomConfig.lockSettingsDisableCam) {
                        //block webcam only user NORMAL
                        if (!this.hasPermission(permissions.MODERATE_ROOM) && !this.hasPermission(permissions.SHARE_VIDEO)) {
                            this.settingsService.setVideoBlocked(true);
                        }
                        this.settingsService.setVideoUnblocked(false);
                    }

                    if (this.roomConfig.lockSettingsDisableMic) {
                        //block audio only user NORMAL
                        if (!this.hasPermission(permissions.MODERATE_ROOM) && !this.hasPermission(permissions.SHARE_AUDIO)) {
                            this.settingsService.setAudioBlocked(true);
                        }
                        this.settingsService.setAudioUnblocked(false);
                    }

                    this.settingsService.setMaxParticipants(this.roomConfig.maxParticipants);
                    this.settingsService.setWebcamsOnlyForModerator(this.roomConfig.webcamsOnlyForModerator);
                    if (this.roomConfig.webcamsOnlyForModerator && !this.hasPermission(permissions.MODERATE_ROOM)) {
                        this.poolService.addFilter(PoolService.filterPresenterOnly);
                    }
                    await this.handleRoomReady({
                        isBlockAllVideo: this.settingsService.getIsVideoBlocked(),
                        isBlockAllAudio: this.settingsService.getIsAudioBlocked(),
                        isDisableAudio: this.settingsService.getIsAudioMuted(),
                        isDisableVideo: this.settingsService.getIsVideoMuted(),
                        turnServers
                    }, this.prepareRoom.bind(this));

                    if (this.roomConfig.whiteboard) {
                        this.triggerWhiteboard('whiteboard');
                        this.setIsWhiteboard(true);
                    }

                    if (timeLeft != undefined && timeLeft > 0) {
                        this.timeLeftRoom = timeLeft;
                        this.setMeetingTimeout({timeLeft: timeLeft, message: "notification.meeting-timeout.timout", isResetDestruct: false});
                    }
                    else if(timeLeft == undefined){
                        this.timeLeftRoom = 0;
                    }

                    if (this.hasPermission(permissions.MODERATE_ROOM)) {
                        this.setLockAllWhiteboard(this.roomConfig.whiteboardLocked);
                    }

                    // TODO: prevent join again after joined room
                    break;
                }

                case 'parkedPeer': {
                    const { peerId, displayName } = notification.data;
                    this.lobbyService.addLobby({ peerId, displayName }, true);
                    break;
                }

                case 'parkedPeers': {
                    const { lobbyPeers } = notification.data;
                    if (!lobbyPeers || !lobbyPeers.length) return;
                    this.lobbyService.addLobbyList(lobbyPeers);
                    break;
                }

                case 'enteredLobby': {
                    // const displayName = this.localParticipantService.getDisplayName();
                    // const picture = null;
                    // await this.socketService.sendRequest('changeDisplayName', { displayName });
                    // await this.socketService.sendRequest('changePicture', { picture });
                    this.lobbyService.setIsLobby(true);
                    break;
                }

                case 'overRoomLimit': {
                    this.lobbyService.setIsLimited(true);
                    setTimeout(() => {
                        this.closeRoom();
                    }, 5000);
                    break;
                }

                case 'rejected': {
                    this.closeRoom(
                        this.i18nService.translate("service.room.rejected", {}, "Bạn bị từ chối tham gia vào phòng"), 
                        false);
                    break;
                }

                case 'lobby:changeDisplayName': {
                    const { peerId, displayName } = notification.data;
                    let lobby = this.lobbyService.getLobby(peerId);
                    lobby.displayName = displayName;
                    break;
                }

                case 'lobby:changePicture': {
                    const { peerId, picture } = notification.data;
                    let lobby = this.lobbyService.getLobby(peerId);
                    lobby.picture = picture;
                    break;
                }

                case 'lobby:promotedPeer':
                case 'lobby:peerClosed':     {
                    const { peerId } = notification.data;
                    this.lobbyService.removeLobby(peerId);
                    break;
                }

                // case 'lobby:peerClosed': {
                //     const { peerId } = notification.data;
                //     this.lobbyService.removeLobby(peerId);
                //     break;
                // }

                case 'lobby:peersClosed': {
                    const { peerIds } = notification.data;
                    peerIds.forEach(peer => {
                        this.lobbyService.removeLobby(peer);
                    });
                    break;
                }

                case 'duration': {
                    const { timeLeft } = notification.data;
                    this.timeLeftRoom = timeLeft;
                    this.setMeetingTimeout({timeLeft: timeLeft, message: "notification.meeting-timeout.timout", isResetDestruct: false});
                    break;
                }

                case 'wb:start': {
                    this.triggerWhiteboard('whiteboard');
                    this.setIsWhiteboard(true);
                    break;
                }

                case 'wb:stop': {
                    this.triggerWhiteboard('gallery');
                    this.setIsWhiteboard(false);
                    break;
                }

                case 'wb:broadcast': {
                    const data = notification.data;
                    this.setBroadcastData(data);
                    break;
                }

                case 'wb:undo': {
                    const { id } = notification.data;
                    this.setWhiteboardUndo(id);
                    break;
                }

                case 'wb:clear': {
                    this.setWhiteboardClear(true);
                    break;
                }

                case 'wb:lock': {
                    const { lock, roleId } = notification.data;
                    this.setLockAllWhiteboard(lock);
                    const remotePeerIds = this.poolService.getPeers();
                    if (this.hasPermission(permissions.MODERATE_ROOM)) {
                        //user hiện tại là admin
                        if (!lock) this.localParticipantService.addRole(roleId);
                        remotePeerIds.forEach((peerId) => {
                            let participant =
                                this.poolService.getPeer(peerId);//getRemoteParticipant(peerId);
                            if (
                                participant.roles.includes(LIST_ROLE_ID.ADMIN_ID) ||
                                participant.roles.includes(LIST_ROLE_ID.MODERATOR_ID)
                            ) {
                                // user là admin
                                if (!lock)
                                    this.poolService.addRole(peerId, roleId);
                            } else {
                                lock
                                    ? this.poolService.removeRole(peerId, roleId)
                                    : this.poolService.addRole(peerId, roleId);
                            }
                        });
                    } else {//user không là admin
                        lock
                            ? this.localParticipantService.removeRole(roleId)
                            : this.localParticipantService.addRole(roleId);
                    }
                    break;
                }
                case 'moderator:forceLayout': {
                    const { layout, pinners, hideNoneCamera } = notification.data;
                    this.setForceLayout(layout, pinners, hideNoneCamera);
                    if (layout != 0) {
                        let remotes = this.poolService.getAllPeers().filter(
                                (element) => pinners.includes(element.id));

                        let unpinners = this.poolService.getRemotesPin;
                            unpinners.forEach((remote) => {
                                // this.poolService.setIsPin(remote.id, false, true);
                                remote.isPin = false;
                            });

                        if(pinners.includes(this.localParticipantService.getPeerId())){
                            this.localParticipantService.getLocalParticipant().isPin = true;
                            let remote = new RemoteParticipant(this.localParticipantService.getLocalParticipant(), null, null);
                            remotes.push(remote);
                            
                        }else if(this.localParticipantService.getLocalParticipant().isPin){
                            this.localParticipantService.getLocalParticipant().isPin = false
                        }
                        remotes.forEach(element => {
                            element.isPin = true
                        });
                        this.poolService.setRemotesPin(remotes);
                        this.poolService.setNonCamView(hideNoneCamera);
                    }
                    else {
                        //Tắt pin: reset pin cho tất cả user trừ người dùng thực hiện thao tác
                        let remotesPin = Array.from(this.poolService.getRemotesPin.map((remote) => remote.id));
                        remotesPin.forEach(peerId => {
                            this.poolService.setIsPin(peerId, false, true,layout );
                        });
                        if(this.localParticipantService.getLocalParticipant().isPin) this.localParticipantService.getLocalParticipant().isPin = false
                        this.poolService.setRemotesPin([]);
                        this.poolService.setNonCamView(false);
                    }
                    break;
                }
                case 'moderator:pinCamera': {
                    const { pinners } = notification.data;
                    if (this.forceLayoutSubject.getValue().layout != 0) {
                        let unpinners = this.poolService.getRemotesPin;
                        unpinners.forEach((remote) => {
                            // this.poolService.setIsPin(remote.id, false, true);
                            remote.isPin = false;
                        });
                        // console.log(this.poolService.getRemotesPin);
                        
                        if(this.localParticipantService.getLocalParticipant().isPin) this.localParticipantService.getLocalParticipant().isPin = false
                        this.poolService.setRemotesPin([]);
                        pinners?.forEach((peerId) => {
                            this.poolService.setIsPin(peerId, true, true, this.getForceLayout.layout);
                        });
                        if(pinners.includes(this.localParticipantService.getPeerId())){
                            this.localParticipantService.getLocalParticipant().isPin = true;
                            let remote = new RemoteParticipant(this.localParticipantService.getLocalParticipant(), null, null);
                            let remotes = this.poolService.getRemotesPin;
                            remotes.push(remote);
                            this.poolService.setRemotesPin(remotes);
                        }
                        this.setForceLayout(this.getForceLayout.layout, pinners, this.getForceLayout.hideNoneCamera);
                    }
                    break;
                }
                case 'moderator:extendRoomDuration': {
                    const { duration } = notification.data;
                    this.roomConfig.endTime = duration*60000 + this.roomConfig.startTime;
                    this.roomConfig.isExtended = true;
                    this.timeLeftRoom = 0;
                    this.setMeetingTimeout({timeLeft: 0, message: "", isResetDestruct: false});
                    break;
                }
                // Start Destruct Coundown
                case 'destructCoundown': {
                    if(this.timeLeftRoom == 0){
                        // duration: second
                        const { duration } = notification.data;
                        if(this.hasPermission(permissions.MODERATE_ROOM)){
                            if(!this.settingsService.isTimeoutRecommendedForAdmin()){
                                this.settingsService.setTimeoutRecommendedForAdmin(false);
                            }
                            this.setMeetingTimeout({timeLeft: duration, message: "notification.meeting.only-one-admin", isResetDestruct: true});
                        }else{
                            if(!this.settingsService.isTimeoutRecommendedForUser()){
                                this.settingsService.setTimeoutRecommendedForUser(false);
                            }
                            this.setMeetingTimeout({timeLeft: duration, message: "notification.meeting.no-admin", isResetDestruct: false});
                        }
                    }
                    break;
                }
                // Clear Destruct Countdown
                case 'clearDestructCoundown': {
                    // const { duration } = notification.data;
                    if(this.timeLeftRoom == 0){
                        this.settingsService.setTimeoutRecommendedForAdmin(false);
                        this.settingsService.setTimeoutRecommendedForUser(false);
                        this.setMeetingTimeout({timeLeft: 0, message: "", isResetDestruct: false});
                    }
                    break;
                }
            }
        });
    }

    public setLockAllWhiteboard(lock: boolean): void {
        this.setRoomConfig("whiteboardLocked", lock);
        if (this.hasPermission(permissions.MODERATE_ROOM)) {
            this.isWhiteboardLocked.next(lock);
        }
    }

    public onLockAllWhiteboard(): Observable<boolean> {
        return this.isWhiteboardLocked.asObservable();
    }

    public setIsWhiteboard(data: boolean): void {
        this.isWhiteboard.next(data);
    }

    public onIsWhiteboard(): Observable<boolean> {
        return this.isWhiteboard.asObservable();
    }

    public isMeetingEnded(): boolean {
        return this.meetingEnded;
    }

    public showFeedbackForm(message: string, cb?: Function): void {
        // this.roomNotify.next({ message, cb });
        this.settingsService.setIsFeedbackOpenSubject({visible: true, message, callback: cb})
    }

    public onMeetingEnded(): Observable<Object> {
        return this.roomNotify.asObservable();
    }

    public setWhiteboardData(data: Array<object>): void {
        this.isWhiteboardData.next(data);
    }

    public onWhiteboardData(): Observable<Array<object>> {
        return this.isWhiteboardData.asObservable();
    }

    public setAudioTransport(data: Transport): void {
        this.audioTransport.next(data);
        if (data) this.latencyService.subscribe("audio", data);
    }

    public onAudioTransport(): Observable<Transport> {
        return this.audioTransport.asObservable();
    }

    public setAudioConsumerTransport(data: Transport): void {
        this.audioConsumerTransport.next(data);
        if (data) this.latencyService.subscribe("audio", data);
    }

    public onAudioConsumerTransport(): Observable<Transport> {
        return this.audioConsumerTransport.asObservable();
    }

    public triggerWhiteboard(mode: LAYOUT_MODE): void {
        this.layoutModeSubject.next(mode);
    }

    public onTriggerWhiteboard(): Observable<LAYOUT_MODE> {
        return this.layoutModeSubject.asObservable();
    }

    public setBroadcastData(data: object) {
        this.isBroadcastData.next(data);
    }

    public onBroadcastData(): Observable<object> {
        return this.isBroadcastData.asObservable();
    }

    public setWhiteboardUndo(id: string) {
        this.isWhiteboardUndo.next(id);
    }

    public onWhiteboardUndo(): Observable<string> {
        return this.isWhiteboardUndo.asObservable();
    }

    public setWhiteboardClear(clear: boolean) {
        this.isWhiteboardClear.next(clear);
    }

    public onWhiteboardClear(): Observable<boolean> {
        return this.isWhiteboardClear.asObservable();
    }

    // timeLeft: second
    public setMeetingTimeout(obj: {timeLeft: number, message: string, isResetDestruct: boolean}): void {
        this.isMeetingTimeout.next(obj);
    }

    public onMeetingTimeout(): Observable<Object> {
        return this.isMeetingTimeout.asObservable();
    }

    public onJoined(): Observable<boolean> {
        return this.isJoinedSubject.asObservable();
    }

    public setJoinFlag(flag: boolean): void {
        this.isJoinedSubject.next(flag);
    }

    public setRoomType(type: ROOM_TYPE): void {
        this.roomTypeSubject.next(type);
    }


    public setNumberOfTunringOnWebcam(webcamNumber: number): void {
        this.numberOfTurningOnWebcamSubject.next(webcamNumber);
    }

    public setRoomPermission(roomPermission: Map<string, UserRoleModel>): void {
        this.roomPermissionSubject.next(roomPermission);
    }

    public getRoomPermission(): Map<string, UserRoleModel> {
        return this.roomPermissionSubject.value;
    }

    public onRoomPermission(): Observable<Map<string, UserRoleModel>> {
        return this.roomPermissionSubject.asObservable();
    }

    public onRoomType(): Observable<ROOM_TYPE> {
        return this.roomTypeSubject.asObservable();
    }

    public setRoomId(roomId: string): void {
        this.roomIdSubject.next(roomId);
    }

    public getRoomId(): string {
        return this.roomIdSubject.value;
    }

    public onRoomId(): Observable<string> {
        return this.roomIdSubject.asObservable();
    }

    public setIsProcessingVideo(status: boolean): void {
        this.isProcessingVideoSubject.next(status);
    }

    public onIsProcessingVideo(): Observable<boolean> {
        return this.isProcessingVideoSubject.asObservable();
    }

    public setIsProcessingAudio(status: boolean): void {
        this.isProcessingAudioSubject.next(status);
    }

    public onIsProcessingAudio(): Observable<boolean> {
        return this.isProcessingAudioSubject.asObservable();
    }

    public prepareRoom(): void {
        this.prepareRoomSubject.next(true);
    }

    public onPrepareRoom(): Observable<boolean> {
        return this.prepareRoomSubject.asObservable();
    }

    public onNumberOfTurningOnWebcam(): Observable<number> {
        return this.numberOfTurningOnWebcamSubject.asObservable();
    }

    public async loadTransport(skipConsume: boolean = false): Promise<void> {
        let routerRtpCapabilities: mediasoupClient.types.RtpCapabilities
            = await this.socketService.sendRequest('getRouterRtpCapabilities', null);

        routerRtpCapabilities.headerExtensions = routerRtpCapabilities.headerExtensions
            .filter((ext) => ext.uri !== 'urn:3gpp:video-orientation');

        if (!this.mediasoupDevice.loaded) {
            await this.mediasoupDevice.load({ routerRtpCapabilities });
        }

        this.resetRoomTransports();
        await Promise.all([
            this.createProducerTransport(),
            this.createConsumerTransport()
        ]).then(() => {
            // console.log("@ create producer/consumer transport: DONE");    
        });
        // console.log("@ load transport: DONE");
    }

    listenRestartIce(callback: (transport, ice, delay) => Promise<void>) {
        this.callbackTransportState = callback;
    }

    private async createProducerTransport(): Promise<void> {
        try {
            const data: { id: string; iceParameters: IceParameters; iceCandidates: IceCandidate[]; dtlsParameters: DtlsParameters }
                = await this.socketService.sendRequest('createWebRtcTransport', {
                    forceTcp: false, // TODO: map from config URL
                    producing: true,
                    consuming: false
                });
            this.producerTransport = this.mediasoupDevice.createSendTransport(
                {
                    ...data,
                    iceServers: this.turnServers,
                    iceTransportPolicy: undefined, // TODO: update for firefox and other browser, view edumeet for more information
                    proprietaryConstraints: PC_PROPRIETARY_CONSTRAINTS
                });

            // 'connect' | 'produce' | 'producedata' | 'connectionstatechange'
            this.producerTransport.on('connect', async ({ dtlsParameters }, callback, errback) => {
                await this.socketService.sendRequest('connectWebRtcTransport', {
                    transportId: this.producerTransport.id,
                    dtlsParameters
                })
                    .then(callback)
                    .catch(errback);
            });

            this.producerTransport.on('produce', async ({ kind, rtpParameters, appData }, callback, errback) => {
                try {
                    var typeLog = '';
                    switch (appData.source) {
                        case 'webcam':
                            this.timeLog.setStartTime('produce-camera');
                            typeLog = 'produce-camera';
                            break;
                        case 'mic':
                            this.timeLog.setStartTime('produce-mic');
                            typeLog = 'produce-mic';
                            break;
                        case 'screen':
                            this.timeLog.setStartTime('produce-screen');
                            typeLog = 'produce-screen';
                            break;
                    }
                    
                    const { id } = await this.socketService.sendRequest(
                        'produce',
                        {
                            transportId: this.producerTransport.id,
                            kind,
                            rtpParameters,
                            appData
                        });

                    this.timeLog.setEndTime(typeLog);

                    callback({ id });
                    if (appData.source === 'mic') {
                        this.setAudioTransport(this.producerTransport);
                    }
                    this.sendProduceInfo(id);
                    // this.latencyService.subscribe("audio", this.producerTransport);
                } catch (error) {
                    console.log(error);
                    errback(error);
                    this.sendReport('other' as REPORT_TYPE,'',this.createProducerTransport.name + error);
                }
            });

            this.producerTransport.on('connectionstatechange', async (state: TState) => {
                // console.log("# producerTransport - connectionstatechange", state);

                switch (state) {
                    case 'connecting':
                        break;
                    case 'connected':
                        //cap nhat lai trang thai connect transport thanh cong
                        this.setConnectedTransport(true);
                        break;
                    case 'disconnected':
                        // this.callbackTransportState(this.producerTransport, this._sendRestartIce, 2000);
                        break;
                    case 'failed':
                        // console.log("Producer Transport: ", state);
                        // this.callbackTransportState(data.id, 'producer');
                         //cap nhat lai trang thai connect transport ko thanh cong
                        this.setConnectedTransport(false);
                        // this.sendReport("other",'',this.createProducerTransport.name + ' connectionstatechange failed');
                        this.callbackTransportState(this.producerTransport, this._sendRestartIce, 2000);
                        // this.producerTransport.close();
                        // this.latencyService.unSubscribe("audio", this.producerTransport);
                        // this.notification.error("Lỗi tạo kết nối đến máy chủ", "đang thử kết nối lại");
                        break;

                    default:
                        clearTimeout(this._sendRestartIce.timer);
                        break;
                }
            });
        } catch (error) {
            console.error(error.message, error.stack);
            this.sendReport('other' as REPORT_TYPE,'', this.createProducerTransport.name + error);
        }
    }

    private async createConsumerTransport(): Promise<void> {
        try {
            const data: { id: string; iceParameters: IceParameters; iceCandidates: IceCandidate[]; dtlsParameters: DtlsParameters }
                = await this.socketService.sendRequest('createWebRtcTransport', {
                    forceTcp: false, // TODO: map from config URL
                    producing: false,
                    consuming: true
                });
            const enableOpusDetails = false; // TODO: move to settings store

            this.consumerTransport = this.mediasoupDevice.createRecvTransport({
                ...data,
                iceServers: this.turnServers,
                // TODO: Fix for issue #72
                iceTransportPolicy: undefined,
                additionalSettings: {
                    encodedInsertableStreams: insertableStreamsSupported && enableOpusDetails
                },
                appData: {
                    encodedInsertableStreams: insertableStreamsSupported && enableOpusDetails
                }
            });

            // 'connect' | 'connectionstatechange'
            this.consumerTransport.on('connect', async ({ dtlsParameters }, callback, errback) => {
                await this.socketService.sendRequest('connectWebRtcTransport', {
                    transportId: this.consumerTransport.id,
                    dtlsParameters
                })
                    .then(callback)
                    .catch(errback);
                    this.setAudioConsumerTransport(this.consumerTransport);
            });

            this.consumerTransport.on('connectionstatechange', async (state: TState) => {
                switch (state) {
                    case 'connecting':
                        break;
                    case 'connected':
                         //cap nhat lai trang thai connect transport thanh cong
                        this.setConnectedTransport(true);
                        break;
                    case 'disconnected':
                        // this.callbackTransportState(this.consumerTransport, this._recvRestartIce, 5000);
                        this.delayAudioScreenIds = [];
                        break;
                    case 'failed':
                        console.log("Consumer Transport: ", state)
                        // this.callbackTransportState(data.id, 'consumer');
                         //cap nhat lai trang thai connect transport ko thanh cong
                        this.setConnectedTransport(false);
                        this.sendReport("other",'',this.createConsumerTransport.name + ' connectionstatechange failed')
                        this.callbackTransportState(this.consumerTransport, this._recvRestartIce, 5000);
                        // this.consumerTransport.close();
                        
                        break;
                    default:
                        clearTimeout(this._recvRestartIce.timer);
                        break;
                }
            });
        } catch (error) {
            console.error(error.message, error.stack);
            this.sendReport('other' as REPORT_TYPE,'',this.createConsumerTransport.name + error);
        }
    }

    public getVideoConstrains(resolution, aspectRatio): any {
        return {
            width: { ideal: VIDEO_CONSTRAINS[resolution].width },
            height: { ideal: VIDEO_CONSTRAINS[resolution].width / aspectRatio }
        };
    }

    public getEncodings(width: number, height: number, screenSharing = false): RTCRtpCodingParameters[] {
        // If VP9 is the only available video codec then use SVC.
        const firstVideoCodec = this.mediasoupDevice
            .rtpCapabilities
            .codecs
            .find((c) => c.kind === 'video');

        let encodings;

        // const size = (width > height ? width : height);

        if (firstVideoCodec.mimeType.toLowerCase() === 'video/vp9') {
            encodings = screenSharing ? VIDEO_SVC_ENCODINGS : VIDEO_KSVC_ENCODINGS;
        } else if (config.simulcastProfiles) {
            encodings = this.chooseEncodings(config.simulcastProfiles, width);
        } else {
            encodings = this.chooseEncodings(VIDEO_SIMULCAST_PROFILES, width);
        }

        return encodings;
    }

    private chooseEncodings(simulcastProfiles: any, size: any): any {
        let encodings;

        const sortedMap = new Map([...Object.entries(simulcastProfiles)]
            .sort((a, b) => Number(b[0]) - Number(a[0])));

        for (const [key, value] of sortedMap) {
            if (key < size) {
                if (encodings === null) {
                    encodings = value;
                }

                break;
            }

            encodings = value;
        }

        // hack as there is a bug in mediasoup
        if (encodings.length === 1) {
            encodings.push({ ...encodings[0] });
        }

        return encodings;

    }

    public getResolutionScalings(encodings: any): any[] {
        const resolutionScalings = [];

        // SVC encodings
        if (encodings.length === 1) {
            const { spatialLayers } =
                mediasoupClient.parseScalabilityMode(encodings[0].scalabilityMode);

            for (let i = 0; i < spatialLayers; i++) {
                resolutionScalings.push(2 ** (spatialLayers - i - 1));
            }

            return resolutionScalings;
        }

        // Simulcast encodings
        let scaleResolutionDownByDefined = false;

        encodings.forEach((encoding) => {
            if (encoding.scaleResolutionDownBy !== undefined) {
                // at least one scaleResolutionDownBy is defined
                scaleResolutionDownByDefined = true;
                // scaleResolutionDownBy must be >= 1.0
                resolutionScalings.push(Math.max(1.0, encoding.scaleResolutionDownBy));
            } else {
                // If encodings contains any encoding whose scaleResolutionDownBy
                // attribute is defined, set any undefined scaleResolutionDownBy
                // of the other encodings to 1.0.
                resolutionScalings.push(1.0);
            }
        });

        // If the scaleResolutionDownBy attribues of sendEncodings are
        // still undefined, initialize each encoding's scaleResolutionDownBy
        // to 2^(length of sendEncodings - encoding index - 1).
        if (!scaleResolutionDownByDefined) {
            encodings.forEach((encoding, index) => {
                resolutionScalings[index] = 2 ** (encodings.length - index - 1);
            });
        }
        return resolutionScalings;
    }

public async changeAudioMic(): Promise<void> {
            if (this.producerAudio) {
            // Xóa sự kiện onended của luồng âm thanh cũ
            if (!this.settingsService.isBrowseSafari()) {
                this.originAudioStream.getTracks().forEach((track) => {
                    track.onended = null;
                });
            }
            await this.producerAudioPause().then(async () => {
                const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
                var constraints = {
                    deviceId: { exact: this.audioDeviceService.getMic().deviceId },
                    noiseSuppression: Object.keys(supportedConstraints).includes("noiseSuppression"),
                    echoCancellation: Object.keys(supportedConstraints).includes("echoCancellation")
                };
                // const track = audioStream.getAudioTracks()[0];
                this.recordClientService.splitAudio(this.audioIn);
                this.originAudioStream.getTracks()[0].stop();

                this.originAudioStream = await navigator.mediaDevices.getUserMedia({ audio: constraints });
                this.audioIn = this.recordClientService.mergeAudio(this.originAudioStream);
                // Theo dõi sự kiện onended của luồng âm thanh mới
                if (!this.settingsService.isBrowseSafari()) {
                    this.originAudioStream.getTracks().forEach((track) => {
                        track.onended = () => {
                            console.log(" orginStrack.onended")
                            this.notification.error('Micro',
                            this.i18nService.translate('service.room.micTrackend'));
                            this.muteMic();
                        };
                    });
                }

                
                // this.originAudioStream = audioStream;

                this.mediaStreamService.replaceStream(this.localParticipantService.getPeerId(), "audio", this.originAudioStream);
                let track : MediaStreamTrack;
                if (this.settingsService.enableFilterNoise()) {
                    stopMediaFilterStream();
                    const {bufferSizeFilterNoise, sizeArrayFilterNoise } = this.settingsService.getConfigFilterNoise();
                    track = createMediaFilterStream(this.originAudioStream, true, bufferSizeFilterNoise, sizeArrayFilterNoise).getTracks()[0];
                }else{
                    track = createMediaFilterStream(this.originAudioStream, false).getTracks()[0];
                }
                
                this.producerAudio.replaceTrack({ track });
                if (!this.settingsService.getIsAudioMuted()) {
                    this.producerAudioResume();
                }
            })
        }
    }

    public reloadVideo(): void {
        this.producerVideo.resume();
    }

    public async changeQuality(newQuality): Promise<void> {
        this.quality = newQuality.name;
        if(!this.producerVideo) return;
        const { aspectRatio, videoQuality } = config;
        const quality = videoQuality[`${this.quality}`]
        const videoContrains =
        {
            width: quality.width,
            height: quality.height,
            ...this.getVideoConstrains(quality.resolution, aspectRatio),
            frameRate: quality.frameRate            
        };

        if(this.settingsService.getUrlBackground()) {
            let urlBackground = this.virtualBackgroundService.virtualBackground;
            this.virtualBackgroundService.stop();
            this.virtualBackgroundService.virtualBackground = urlBackground;
            await this.virtualBackgroundService.handleVirtualBackground(videoContrains, this.quality, async (videoStream) => {
                let track;
                this.producerVideo.pause();
                ([track] = videoStream.getVideoTracks());
                this.producerVideo.replaceTrack({ track: track });
                this.producerVideo.resume();
                this.mediaStreamService.addStream(this.localParticipantService.getLocalParticipant().id, 'webcam', track);
                this.localParticipantService.sendLocalWebcamMediaStream(videoStream);
            }, this.disableWebcam);
            return;
        }

        let videoTrack = this.producerVideo.track;
        videoTrack.applyConstraints(videoContrains).then(()=>{
            const { width } = videoTrack.getSettings();
            if (quality.width > width && this.quality != 'Max Setting') {
                this.notification.info('video', this.i18nService.translate("service.room.videoQuality", { quality: this.quality }, "video quality"));
            }
            this.producerVideo.replaceTrack({track: videoTrack});
            const encodings = this.chooseEncodings(config.simulcastProfiles, width);

            // console.log("setRtpEncodingParameters", encodings);
            this.producerVideo.setRtpEncodingParameters(encodings);
        }).catch((reason)=> {
            console.log(reason.message);
            this.notification.info('video', this.i18nService.translate("service.room.videoQuality", { quality: this.quality }, "video quality"));
            this.producerVideo.replaceTrack({track: videoTrack});
        });
    }

    public async changeQualityLocal(newQuality): Promise<void> {
        this.quality = newQuality.name;
      
        const { aspectRatio, videoQuality } = config;
        const quality = videoQuality[`${this.quality}`]
        const videoContrains =
        {
            width: quality.width,
            height: quality.height,
            ...this.getVideoConstrains(quality.resolution, aspectRatio),
            frameRate: quality.frameRate            
        };

        if(this.settingsService.getUrlBackground()) {

            let urlBackground = this.virtualBackgroundService.virtualBackground;
            this.virtualBackgroundService.stop();
            this.virtualBackgroundService.virtualBackground = urlBackground;
            for (var sub of this.settingsService.getLocalVideoStream().getVideoTracks()) {
                sub.stop()
            }

            await this.virtualBackgroundService.handleVirtualBackgroundLocalVideo(videoContrains, this.quality, async (videoStream) => {
                this.settingsService.setLocalVideoStream(videoStream);
            }, this.disableWebcam);
            return;
        }

        let videoStream = this.settingsService.getLocalVideoStream();
        let track = videoStream.getVideoTracks()[0]
        track.applyConstraints(videoContrains).then(()=>{
            const { width } = track.getSettings();
                if (quality.width > width && this.quality != 'Max Setting') {
                    this.notification.info('video', this.i18nService.translate("service.room.videoQuality", { quality: this.quality }, "video quality"));
                }
                videoStream.addTrack(track);
                this.settingsService.setLocalVideoStream(videoStream);
        }).catch((reason)=> {
            console.log(reason);
            this.notification.info('video', this.i18nService.translate("service.room.videoQuality", { quality: this.quality }, "video quality"));
            this.settingsService.setLocalVideoStream(videoStream);
        });
    }

    public async changeVideoMic(): Promise<boolean> {
        // await this.disableWebcam().then(async () => {
        // debugger
    try {
        if (!this.producerVideo) {
            const timeOutVideoButton = setTimeout(() => {
                var evt = new CustomEvent("toggleVideo", { detail: { type: "" } });
                window.dispatchEvent(evt);
                clearTimeout(timeOutVideoButton);
            }, 2000);
            return false;
        }
        
        let videoStream;
        const { aspectRatio,  videoQuality } = config;
        let quality = videoQuality[`${this.quality}`]
        const videoContrains =
        {
            width: quality.width,
            height: quality.height,
            ...this.getVideoConstrains(quality.resolution, aspectRatio),
            frameRate: quality.frameRate  
        };
        if (this.audioDeviceService.getVideo() && this.audioDeviceService.getVideo().deviceId != "default") {
            videoContrains.deviceId = {exact: this.audioDeviceService.getLocalVideo().deviceId};
            videoStream = await navigator.mediaDevices.getUserMedia({
                video: videoContrains
            });
        } else {
            videoStream = await navigator.mediaDevices.getUserMedia({ video: videoContrains });
        }
        // const videoStream = await navigator.mediaDevices.getUserMedia({
        //     video: {
        //         width: 640, height: 360,
        //         deviceId: { exact: this.audioDeviceService.getVideo().deviceId }
        //     }
        // });       

        if (!videoStream) {
            this.audioDeviceService.changeVideo(null);
            return false;
        }
        if (this.settingsService.getUrlBackground()) {
            // debugger
            this.virtualBackgroundService.virtualBackground = this.settingsService.getUrlBackground();
            await this.virtualBackgroundService.handleVirtualBackground(videoContrains, this.quality, async (videoStream) => {
                let track;
                this.producerVideo.pause();
                ([track] = videoStream.getVideoTracks());
                this.producerVideo.replaceTrack({ track: track });
                this.producerVideo.resume();
                this.mediaStreamService.addStream(this.localParticipantService.getLocalParticipant().id, 'webcam', track);
                this.localParticipantService.sendLocalWebcamMediaStream(videoStream);

            }, this.disableWebcam);
            const timeOutVideoButton = setTimeout(() => {
                var evt = new CustomEvent("toggleVideo", { detail: { type: "" } });
                window.dispatchEvent(evt);
                clearTimeout(timeOutVideoButton);
            }, 2000);
            return true;
        }
        for (var sub of this.localParticipantService.getLocalWebcamMediaStream().getVideoTracks()) {
            sub.stop();
        }
        this.producerVideo.pause();
        const videoTrack = videoStream.getVideoTracks()[0];

        let { width, height } = videoTrack.getSettings();
        if (quality.width > width && this.quality != 'Max Setting') {
            this.notification.info('video', this.i18nService.translate("service.room.videoQuality", { quality: this.quality }, "video quality"));
        }
        this.producerVideo.replaceTrack({ track: videoTrack });
        // this.producerAudioStart();
        this.producerVideo.resume();
        this.mediaStreamService.addStream(this.localParticipantService.getLocalParticipant().id, 'webcam', videoTrack);
        this.localParticipantService.sendLocalWebcamMediaStream(videoStream);
        const timeOutVideoButton = setTimeout(() => {
            var evt = new CustomEvent("toggleVideo", { detail: { type: "" } });
            window.dispatchEvent(evt);
            clearTimeout(timeOutVideoButton);
        }, 2000);
        // })
        return true;
    } catch (error) {
        this.sendReport('video' as REPORT_TYPE,'', this.changeVideoMic.name + error);
        return false;
        
    }
        

    }

    public async changeVideoMicV2(): Promise<void> {
        if (!this.producerVideo) {
            this.offVideoDevice();
            this.virtualBackgroundService.stop();
            return;
        }
        const videoStream = this.settingsService.getLocalVideoStream();
        this.producerVideo.pause();
        const videoTrack = videoStream.getVideoTracks()[0];
        this.producerVideo.replaceTrack({ track: videoTrack });
        this.producerVideo.resume();
        this.mediaStreamService.addStream(this.localParticipantService.getLocalParticipant().id, 'webcam', videoTrack);
        this.localParticipantService.sendLocalWebcamMediaStream(videoStream);
        const timeOutVideoButton = setTimeout(() => {
            var evt = new CustomEvent("toggleVideo", { detail: { type: "" } });
            window.dispatchEvent(evt);
            clearTimeout(timeOutVideoButton);
        }, 2000);
    }

    public async changeVideoMicLocal(): Promise<boolean> {
        try {
            let videoStream;
            const { aspectRatio,  videoQuality } = config;
            let quality = videoQuality[`${this.quality}`]
            const videoContrains =
            {
                width: quality.width,
                height: quality.height,
                ...this.getVideoConstrains(quality.resolution, aspectRatio),
                frameRate: quality.frameRate,
                deviceId: { exact: this.audioDeviceService.getVideo().deviceId }
            };
            
            videoStream = await navigator.mediaDevices.getUserMedia({
                video: videoContrains
            });
    
            if (!videoStream) {
                this.audioDeviceService.changeLocalVideo(null);
                return true;
            }
            let urlBackground = this.virtualBackgroundService.virtualBackground;
            
            for (var sub of this.settingsService.getLocalVideoStream().getVideoTracks()) {
                sub.stop()
            }
    
            if (this.virtualBackgroundService.virtualBackground) {
                this.virtualBackgroundService.stop();
                this.virtualBackgroundService.virtualBackground = urlBackground;
                await this.virtualBackgroundService.handleVirtualBackgroundLocalVideo(videoContrains, this.quality, async (stream) => {
                    this.settingsService.setLocalVideoStream(stream);
    
                }, this.disableWebcam);
                return true;
            }
            this.settingsService.setLocalVideoStream(videoStream);
            return true;
            // })     
        } catch (error) {
            this.sendReport('video' as REPORT_TYPE,'', this.changeVideoMicLocal.name + error);
            return false;
        }
    }

    public async producerAudioPause(): Promise<void> {
        try {
            if (this.producerAudio && !this.producerAudio.paused) {
                this.producerAudio.pause();
            }
        } catch (error) {
            this.sendReport('audio' as REPORT_TYPE,'', this.producerAudioPause.name + error);
            console.error(error.message, error.stack);
        }
    }

    /**
     * Unpause the transmission of your audio stream
     */
    public async producerAudioResume(): Promise<void> {
        try {
            if (this.producerAudio && this.producerAudio.paused && !this.producerAudio.closed) {
                this.producerAudio.resume();
            } else if (this.producerAudio && this.producerAudio.closed) {
                await this.producerAudioStart();
            }
        } catch (error) {
            console.error(error.message, error.stack);
            this.sendReport('audio' as REPORT_TYPE,'',this.producerAudioResume.name + error);
        }
    }

    /**
     * Stop transmitting your audio stream (to re-transmit, you need to recreate the producer)
     */
    public async producerAudioClose(): Promise<void> {
        try {
            if (this.producerAudio && !this.producerAudio.closed) {
                this.producerAudio.close();
            }
        } catch (error) {
            console.error(error.message, error.stack);
            this.sendReport('audio' as REPORT_TYPE,'', this.producerAudioClose.name + error);
        }
    }

    /**
     * Pause user stream
     */
    public async targetProducerPause(data: { user_id: string, kind: TKind }): Promise<void> {
        try {
            await this.socketService.sendRequest('producerPause', data);
        } catch (error) {
            console.error(error.message, error.stack);
            this.sendReport('other' as REPORT_TYPE, '', this.targetProducerPause.name + error);
        }
    }

    /**
     * Unpause user stream
     * @param data user and stream type
     */
    public async targetProducerResume(data: { user_id: string, kind: TKind }): Promise<void> {
        try {
            await this.socketService.sendRequest('producerResume', data);
        } catch (error) {
            this.sendReport('other' as REPORT_TYPE, '', this.targetProducerResume.name + error);
            console.error(error.message, error.stack);
        }
    }

    /**
     * Stop the user's stream (to resume the broadcast, this user will have to recreate the producer)
     * @param data user and stream type
     */
    public async targetProducerClose(data: { user_id: string, kind: TKind }): Promise<void> {
        try {
            await this.socketService.sendRequest('producerClose', data);
        } catch (error) {
            console.error(error.message, error.stack);
            this.sendReport('other' as REPORT_TYPE,'', this.targetProducerClose.name + error);
        }
    }

    /**
     * Pause the stream of all users
     * @param data stream type
     */
    public async allProducerPause(data: { kind: TKind }): Promise<void> {
        try {
            await this.socketService.sendRequest('allProducerPause', data);
        } catch (error) {
            console.error(error.message, error.stack);
            this.sendReport('other' as REPORT_TYPE, this.allProducerPause.name + error);
        }
    }

    /**
     * Unpause the stream of all users
     * @param data stream type
     */
    public async allProducerResume(data: { kind: TKind }): Promise<void> {
        try {
            await this.socketService.sendRequest('allProducerResume', data);
        } catch (error) {
            console.error(error.message, error.stack);
            this.sendReport('other' as REPORT_TYPE,'', this.allProducerResume.name + error);
        }
    }

    /**
     * Stop the stream of all users (in order to resume the transmission, these users will have to recreate the producer)
     * @param data stream type
     */
    public async allProducerClose(data: { kind: TKind }): Promise<void> {
        try {
            await this.socketService.sendRequest('allProducerClose', data);
        } catch (error) {
            console.error(error.message, error.stack);
            this.sendReport('other' as REPORT_TYPE, '', this.allProducerClose.name + error);
        }
    }

    /**
     * Receive a video stream from another user
     * @param user_id the user to whom the video stream is transmitted
     */

    public async producerAudioStart(): Promise<void> {
        // if(!this.isConnectedTransport.getValue()) {
        if (["failed", "closed", "disconnected"].includes(this.producerTransport.connectionState)) {
            // khong start audio neu ket noi transport ko thanh cong
            this.notification.error(this.i18nService.translate('service.room.camera.error.connect.server', {}, ""), "");
            var evt = new CustomEvent("toggleMic", { detail: { type: "" } });
            window.dispatchEvent(evt);
            return;
        }
        try {
            // console.log("producerAudioStart")
            this.setIsProcessingAudio(true);
                        if (this.mediasoupDevice.canProduce('audio')) {
                const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
                if (this.audioDeviceService.getMic()) {
                    var constraints = {
                        deviceId: { exact: this.audioDeviceService.getMic().deviceId },
                        noiseSuppression: Object.keys(supportedConstraints).includes("noiseSuppression"),
                        echoCancellation: Object.keys(supportedConstraints).includes("echoCancellation"),
                        autoGainControl: Object.keys(supportedConstraints).includes("autoGainControl")
                    };

                    // audioStream = await navigator.mediaDevices.getUserMedia({ audio: constraints });
                    this.originAudioStream = await navigator.mediaDevices.getUserMedia({audio: constraints});
                }
                else {
                    const devices = await navigator.mediaDevices.enumerateDevices();
                    const listMic = devices.filter(item => (item.kind === "audioinput" && item.deviceId !== "communications" && item.deviceId !== ""));
                    
                    if(listMic.length > 0 )this.audioDeviceService.changeMicDevice(listMic[0]);
                    else throw new Error("Requested device not found");
                    this.originAudioStream = await navigator.mediaDevices.getUserMedia({ audio: {
                        deviceId: { exact: this.audioDeviceService.getMic().deviceId },
                        noiseSuppression: Object.keys(supportedConstraints).includes("noiseSuppression"),
                        echoCancellation: Object.keys(supportedConstraints).includes("echoCancellation"),
                        autoGainControl: Object.keys(supportedConstraints).includes("autoGainControl")
                    } });
                }

                if (!this.settingsService.isBrowseSafari()) {
                    this.originAudioStream.getTracks().forEach((track) => {
                        track.onended = () => {
                            this.notification.error('Micro',
                            this.i18nService.translate('service.room.micTrackend'));
                            this.muteMic();
                        };
                    });
                }
                

                let audioTrack : MediaStreamTrack;
                if (this.settingsService.enableFilterNoise()) {
                    const {bufferSizeFilterNoise, sizeArrayFilterNoise } = this.settingsService.getConfigFilterNoise();
                    audioTrack = createMediaFilterStream(this.originAudioStream, true, bufferSizeFilterNoise, sizeArrayFilterNoise).getTracks()[0];
                } else {
                    audioTrack = createMediaFilterStream(this.originAudioStream, false).getTracks()[0];
                }

                const { deviceId } = this.originAudioStream.getTracks()[0].getSettings();
                const listDevice = await navigator.mediaDevices.enumerateDevices();

                // Lấy danh sách mic và lưu sử dụng cho sự kiện ondevicechange
                const listMic = listDevice.filter(item => item.kind === "audioinput");
                this.audioDeviceService.changeListMic(listMic);

                const mic = listDevice.find(ele => ele.deviceId == deviceId);
                this.audioDeviceService.changeMicDevice(mic);
                const networkPriority =
                    config.networkPriorities.audio ?
                        config.networkPriorities.audio :
                        DEFAULT_NETWORK_PRIORITIES.audio;

                const encodings: RTCRtpCodingParameters[] = [
                    {
                        // @ts-ignore
                        networkPriority
                    }
                ];

                if (audioTrack) {
                    if (this.producerTransport && !this.producerTransport.closed) {
                        this.producerAudio = await this.producerTransport.produce({
                            track: audioTrack,
                            encodings,
                            codecOptions:
                            {
                                opusStereo: config.opusStereo,
                                opusFec: config.opusFec,
                                // opusDtx: config.opusDtx,
                                opusDtx: false,
                                opusMaxPlaybackRate: config.opusMaxPlaybackRate,
                                opusPtime: config.opusPtime
                            },
                            // disableTrackOnPause: false,
                            // zeroRtpOnPause: true,
                            appData: { source: 'mic' }
                        });

                        /* const dataProducer: ProducerModel = {
                            id: this.producerAudio.id,
                            deviceLabel: 'mic',
                            source: 'mic',
                            paused: this.producerAudio.paused,
                            track: this.producerAudio.track,
                            rtpParameters: this.producerAudio.rtpParameters,
                            codec: this.producerAudio.rtpParameters.codecs[0].mimeType.split('/')[1]
                        }; */

                        this.localParticipantService.updateLocalProducer(this.producerAudio.id, 'audio');
                     
                        this.audioIn = this.recordClientService.mergeAudio(this.originAudioStream);
                    }

                    // this.mediaStreamService.addStream(this.localParticipantService.getLocalParticipant().id, 'audio', audioTrack);
                    this.mediaStreamService.replaceStream(this.localParticipantService.getLocalParticipant().id, 'audio', this.originAudioStream);
                    // Tạo luồng speechEvents harkStream của local
                    if(this.settingsService.checkUseConfigHarkJs())  {
                        await this.connectLocalHark();
                    }



                    this.producerAudio.on('transportclose', () => {
                        this.producerAudio = null;
                        // console.log(">>>> producerTransport đã đóng producerAudio đã rỗng");
                    });

                    this.producerAudio.on('trackended', () => {
                        // notify: "Microphone disconnected"
                       this.notification.error('Micro',
                       this.i18nService.translate('service.room.micTrackend'));
                        this.muteMic();
                    });

                }

                // this.setIsProcessingAudio(false);
                this.settingsService.setAudioMuted(false);
                

            }
        } catch (error) {
            // this.setIsProcessingAudio(false);
            // var evt = new CustomEvent("toggleMic", { detail: { type: "" } });
            // window.dispatchEvent(evt);
            if(error && error.name && error.name == "NotAllowedError") {
                this.settingsService.setTypeGuideDeviceSubject(1);
                // if(error.message && error.message == "Permission denied") {
                //     this.settingsService.setTypeGuideDeviceSubject(1);
                // }

                // if(error.message && error.message == "Permission denied by system") {
                //     if(this.settingsService.isBrowseSafari()){
                //         this.settingsService.setTypeGuideDeviceSubject(1);
                //     }
                //     else {
                //         this.settingsService.setTypeGuideDeviceSubject(1);
                //     }
                // }
                
            }
            this.notification.error(this.i18nService.translate("service.room.microphone", {}, "Microphone"), this.i18nService.translate("service.room.openMicError", {}, "Mở mic không thành công, vui lòng thử lại"));
            if (this.settingsService.getIsSpaceHoldSubject()) {
                this.settingsService.setIsSpaceHoldSubject(false);
            }
            this.settingsService.setAudioMuted(true);
            console.error(error);
            this.sendReport('audio' as REPORT_TYPE,'', this.producerAudioStart.name + error);
        }
        finally {       
            this.setIsProcessingAudio(false);
            const t = setTimeout(() => {     
                var evt = new CustomEvent("toggleMic", { detail: { type: "" } });
                window.dispatchEvent(evt);
                clearTimeout(t);
            }, 2000);
        }
    }

    public async joinAudio(): Promise<any> {
        try {
            return await this.socketService.sendRequest('joinAudio', {});
        } catch (error) {
            this.sendReport('audio' as REPORT_TYPE,'', this.joinAudio.name + error);
            console.log('join audio [error:"%o"]', error);
        }
    }

    public async producerVideoStart(): Promise<void> {
        // if(!this.isConnectedTransport.getValue()) {
        if (["failed", "closed", "disconnected"].includes(this.producerTransport.connectionState)) {
            // khong start audio neu ket noi transport ko thanh cong
            this.notification.error(this.i18nService.translate('service.room.camera.error.connect.server', {}, ""), "");
            var evt = new CustomEvent("toggleVideo", { detail: { type: "" } });
            window.dispatchEvent(evt);
            return;
        }
        const { aspectRatio, videoQuality } = config;
        const { simulcast } = await this.settingsService.getAppConfig();
        const quality = videoQuality[`${this.quality}`]
        const videoContrains =
        {
            width: quality.width,
            height: quality.height,
            ...this.getVideoConstrains(quality.resolution, aspectRatio),
            frameRate: quality.frameRate       
        };

        let videoStream;
        try {
            if (!this.mediasoupDevice.canProduce('video')) {
                throw new Error('cannot produce video');
            }

            // TODO: move resolution and aspectRatio to settings service
           

            const networkPriority =
                config.networkPriorities?.mainVideo ?
                    config.networkPriorities?.mainVideo :
                    DEFAULT_NETWORK_PRIORITIES.mainVideo as any;

            this.setIsProcessingVideo(true);
            if (this.settingsService.getUrlBackground()) {
                this.virtualBackgroundService.virtualBackground = this.settingsService.getUrlBackground();
                await this.virtualBackgroundService.handleVirtualBackground(videoContrains, this.quality, async (stream) => {
                    let track;

                    ([track] = stream.getVideoTracks());
                    this.mediaStreamService.addStream(this.localParticipantService.getLocalParticipant().id, 'webcam', track);
                    this.localParticipantService.sendLocalWebcamMediaStream(stream);
                    const { width, height } = track.getSettings();
                    
                    if (simulcast) {
                        const encodings = this.getEncodings(width, height, false) as any;
                        const resolutionScalings = this.getResolutionScalings(encodings);

                        encodings[0].networkPriority = networkPriority;
                        //Disable layer lowest 
                        if (encodings?.length > 1) {
                            encodings[0].active = false;
                        }

                        this.producerVideo = await this.producerTransport.produce(
                            {
                                track,
                                encodings,
                                codecOptions:
                                {
                                    videoGoogleStartBitrate: 1000
                                },
                                appData:
                                {
                                    source: 'webcam',
                                    width,
                                    height,
                                    resolutionScalings
                                }
                            });
                    } else {
                        this.producerVideo = await this.producerTransport.produce({
                            track,
                            encodings: [{ networkPriority }],
                            appData:
                            {
                                source: 'webcam',
                                width,
                                height
                            }
                        });
                    }

                    this.updateProducer();
                    this.producerVideo.on('transportclose', () => {
                        this.producerVideo = null;
                    });

                    this.producerVideo.on('trackended', () => {
                        this.disableWebcam();
                    });
                }, ()=> {this.disableWebcam()});

            } else {
                if (this.audioDeviceService.getVideo() && this.audioDeviceService.getVideo().deviceId != "default") {
                    videoContrains.deviceId = {exact: this.audioDeviceService.getLocalVideo().deviceId};
                        videoStream = await navigator.mediaDevices.getUserMedia({
                            video: videoContrains
                        });
                    // }
                    
                    
                }
                else {
                    videoStream = await navigator.mediaDevices.getUserMedia({ video: videoContrains });
                }
                const videoTrack = videoStream.getVideoTracks()[0];
                this.mediaStreamService.addStream(this.localParticipantService.getLocalParticipant().id, 'webcam', videoTrack);
                const { width, height, deviceId } = videoTrack.getSettings();
                if (quality.width > width && this.quality != 'Max Setting') {
                    this.notification.info('video', this.i18nService.translate("service.room.videoQuality", { quality: this.quality }, "video quality"));
                }

                const encodings = this.getEncodings(width, height)as any;
                //Disable layer lowest 
                if (encodings?.length > 1) {
                    encodings[0].active = false;
                }

                const resolutionScalings = this.getResolutionScalings(encodings);
                const listVideo = await navigator.mediaDevices.enumerateDevices();
                const video = listVideo.find(ele => ele.deviceId == deviceId);
                this.audioDeviceService.changeVideo(video);
                this.audioDeviceService.changeLocalVideo(video);

                // Gởi luồng videoStream để view
                this.localParticipantService.sendLocalWebcamMediaStream(videoStream);
                if (!videoTrack) {
                    return;
                }
                if (simulcast) {
                    if (this.producerTransport && !this.producerTransport.closed) {
                        this.producerVideo = await this.producerTransport.produce({
                            track: videoTrack,
                            encodings,
                            codecOptions:
                            {
                                videoGoogleStartBitrate: 1000
                            },
                            appData:
                            {
                                source: 'webcam',
                                width,
                                height,
                                resolutionScalings
                            }
                        });
                        // this.getCurrentVideoConsumer();
                    }
                } else {
                    this.producerVideo = await this.producerTransport.produce({
                        track: videoTrack,
                        // @ts-ignore
                        encodings: [{ networkPriority }],
                        appData:
                        {
                            source: 'webcam',
                            width,
                            height
                        }
                    });
                }

                this.updateProducer();

                this.producerVideo.on('transportclose', () => {
                    this.producerVideo = null;
                });

                this.producerVideo.on('trackended', () => {
                    this.disableWebcam();
                });

            }

            this.settingsService.setVideoMuted(false);
            // this.deviceCountingService.increaseWebcamNumber();
            this.updateLocalActiveSpeaker();
            this.poolService.setOnVideoConsumerMaps(true);
            

        } catch (error) {
            // window.dispatchEvent(new CustomEvent("toggleVideo", { detail: { type: "" } }));
            if(error && error.name && error.name == "NotAllowedError") {
                this.settingsService.setTypeGuideDeviceSubject(1);
                // if(error.message && error.message == "Permission denied") {
                //     this.settingsService.setTypeGuideDeviceSubject(1);
                // }

                // if(error.message && error.message == "Permission denied by system") {
                //     if(this.settingsService.isBrowseSafari()){
                //         this.settingsService.setTypeGuideDeviceSubject(1);
                //     }
                //     else {
                //         this.settingsService.setTypeGuideDeviceSubject(1);
                //     }
                // }
                
            }
            var errorMsg = (error === 500
                ? this.i18nService.translate("service.room.camera.error.fromServer", {}, "Lỗi máy chủ. Xin vui lòng thử lại")
                : this.i18nService.translate("service.room.camera.error.openCamera", {}, "Vui lòng kiểm tra lại thiết bị"));

            this.notification.error(this.i18nService.translate("service.room.camera.error.title", {}, 'Không thể mở camera'), errorMsg);

            // Giải phóng tài nguyên của videoStream (nếu đã được khởi tạo)
            if (videoStream) {
                videoStream.getTracks().forEach(track => track.stop());
                videoStream = null;
            }
            this.setIsProcessingVideo(false);
            this.settingsService.setVideoMuted(true);
            this.sendReport('video' as REPORT_TYPE,'', this.producerVideoStart.name + error);
            // console.log("[error]", error);
        }
        finally {
            this.setIsProcessingVideo(false);
            const timeOutVideoButton = setTimeout(() => {    
                var evt = new CustomEvent("toggleVideo", { detail: { type: "" } });
                window.dispatchEvent(evt);
                clearTimeout(timeOutVideoButton);
            }, 2000);
        }
    }

    public updateLocalActiveSpeaker(): void {
        const local = this.localParticipantService.getLocalParticipant();
        if (!this.activeSpeakerService.isExistParticipant(local.id)) {
            return;
        }

        this.activeSpeakerService.setActiveSpeaker(local);
    }

    // private async getProducerId(peerId: string, kind: 'webcam' | 'screen'): Promise<string> {
    //     // const currentVideoConsumer = await this.getCurrentVideoConsumer();
    //     const currentVideoConsumer;
    //     const videoConsumerMap = currentVideoConsumer.videoConsumerMap;
    //     const type = APPSOURCE[kind];

    //     if (videoConsumerMap.has(peerId)) {
    //         return videoConsumerMap.get(peerId)[type];
    //     }
    //     return null;
    // }

    // Create new consumer (kind: webcam | screen)
    public async createNewConsumer(peerId: string, type: 'webcam' | 'screen', producerId?: string): Promise<void> {
        try {
            // if (!producerId) { producerId = await this.getProducerId(peerId, type); }
            // if (producerId) {
                await this.socketService.sendRequest('createNewVideoConsumer', { peerId, producerId });
            // }
        } catch (error) {
            console.log('createNewVideoConsumer error', error);
            this.sendReport('video' as REPORT_TYPE,'', this.createNewConsumer.name + error);
        }
    }

    public async createNewScreenConsumer(peerId: string, producerId?: string): Promise<void> {
        this.createNewConsumer(peerId, 'screen', producerId);
    }

    //Xóa hàm getCurrentVideoConsumer

    public updateProducer(): void {
        const dataProducer: ProducerModel = {
            id: this.producerVideo.id,
            deviceLabel: 'webcam',
            source: 'webcam',
            paused: this.producerVideo.paused,
            track: this.producerVideo.track,
            rtpParameters: this.producerVideo.rtpParameters,
            codec: this.producerVideo.rtpParameters.codecs[0].mimeType.split('/')[1]
        };

        this.producerService.addProducer(dataProducer);
        this.localParticipantService.updateLocalProducer(this.producerVideo.id, 'webcam');
    }

    public async disableWebcam(unCheckError: boolean = false): Promise<void> {
        this.localParticipantService.updateLocalProducer('', 'webcam');
        this.settingsService.setVideoMuted(true);
        // this.deviceCountingService.decreaseWebcamNumber();
        
        if (!this.producerVideo) return;
        this.setIsProcessingVideo(true);
        const producerId = this.producerVideo.id;
        try {
            this.producerService.removeProducer(this.producerVideo.id);
            // this.setIsProcessingVideo(false);

            this.virtualBackgroundService.stop();
            const videoStream = this.producerVideo.track;
            videoStream.stop();
            this.producerVideo.close();
            this.producerVideo = null;
            this.updateLocalActiveSpeaker();
            this.localParticipantService.sendLocalWebcamMediaStream(null);
            this.poolService.setOnVideoConsumerMaps(true);
        } catch (error) {
            this.notification.error(this.i18nService.translate("service.room.camera.error.title", {}, 'Video'), this.i18nService.translate("service.room.turnOffCameraError", {}, "Không thể tắt camera, vui lòng thử lại thiết bị"));
            console.log(error);
            this.setIsProcessingVideo(false);
            this.sendReport('video' as REPORT_TYPE,'', this.disableWebcam.name + error);
            return;
        }
        try {            
            await this.socketService.sendRequest(
                'closeProducer', { producerId: producerId });
            // const { videoQuality } = config;
            // const quality = videoQuality[`${this.quality}`]
            this.mediaStreamService.removeStream(this.localParticipantService.getLocalParticipant().id, 'webcam');
            // this.offVideoDevice();
            
        } catch (error) {
            // var evt = new CustomEvent("toggleVideo", { detail: { type: "" } });
            // window.dispatchEvent(evt);
            if(unCheckError) return;
            this.notification.error(this.i18nService.translate("service.room.camera.error.title", {}, 'Video'), this.i18nService.translate("service.room.turnOffCameraError", {}, "Không thể tắt camera, vui lòng thử lại thiết bị"));
            console.log(error);
            this.setIsProcessingVideo(false);
            this.sendReport('video' as REPORT_TYPE,'', this.disableWebcam.name + error);
        }
        finally {
            this.setIsProcessingVideo(false);
            const timeOutVideoButton = setTimeout(() => {
                var evt = new CustomEvent("toggleVideo", { detail: { type: "" } });
                window.dispatchEvent(evt);
                clearTimeout(timeOutVideoButton);
            }, 2000);
        }
    }

    public async offVideoDevice(): Promise<void> {
        const { aspectRatio, videoQuality } = config;
        const quality = videoQuality[`${this.quality}`]
        const videoContrains =
        {
            width: quality.width,
            height: quality.height,
            ...this.getVideoConstrains(quality.resolution, aspectRatio),
            frameRate: quality.frameRate       
        };
        if(this.settingsService.getLocalVideoStream()) {
            this.settingsService.setLocalVideoStream(null);
        }
        let listDevice = await navigator.mediaDevices.enumerateDevices();
            let listVideo = listDevice.filter(item => (item.kind == "videoinput"));
            listVideo.forEach(async (video) => {
                videoContrains.deviceId = {exact: video.deviceId};
                const videoStream2 = await navigator.mediaDevices.getUserMedia({
                    video: videoContrains
                });
        
            videoStream2.getVideoTracks().forEach(element => {
                element.stop();
            });
            })
            // this.virtualBackgroundService.videoElement.srcObject = null
    }

    public async blockWebcam(): Promise<void> {
        if (!this.producerVideo) {
            this.settingsService.setVideoBlocked(true);
            this.settingsService.setVideoMuted(true);
            // this.settingsService.setVideoUnblocked(false);
            return;
        }

        this.setIsProcessingVideo(true);
        this.producerVideo.close();

        try {
            await this.socketService.sendRequest(
                'closeProducer', { producerId: this.producerVideo.id });
            // this.getCurrentVideoConsumer();
        } catch (error) {
            console.log(error);
            this.sendReport('video' as REPORT_TYPE,'', this.blockWebcam.name + error);
        }

        this.localParticipantService.updateLocalProducer('', 'webcam');

        this.updateLocalActiveSpeaker();

        this.producerService.removeProducer(this.producerVideo.id);

        this.producerVideo = null;

        this.setIsProcessingVideo(false);
        // debugger
        this.settingsService.setVideoBlocked(true);
        // this.settingsService.setVideoUnblocked(false);
        this.settingsService.setVideoMuted(true);
        // this.deviceCountingService.decreaseWebcamNumber();
        this.localParticipantService.sendLocalWebcamMediaStream(null);
        this.poolService.setOnVideoConsumerMaps(true);
    }

    public async unBlockWebcam(videoMuted: boolean) {
        this.settingsService.setVideoBlocked(false);
        // this.settingsService.setVideoUnblocked(false);
        if (videoMuted) {
            this.settingsService.setVideoMuted(videoMuted);
        }
    }

    public async blockMic(): Promise<void> {
        this.settingsService.setAudioBlocked(true);
        this.settingsService.setAudioMuted(true);
        // this.settingsService.setAudioUnblocked(false);
        this.muteMic();
    }

    public async unBlockMic(audioMuted: boolean) {
        this.settingsService.setAudioBlocked(false);
        // this.settingsService.setAudioUnblocked(false);
        if (audioMuted) {
            this.settingsService.setAudioMuted(audioMuted);
        }

    }

    public async muteMic(unCheckError: boolean = false): Promise<void> {
        if (this.producerAudio && !this.producerAudio.closed) {
            this.setIsProcessingAudio(true);
            this.localParticipantService.updateLocalProducer('', 'audio');
            this.settingsService.setAudioMuted(true);
            
            try {
                //Ktra config filter noise => stop filter truoc do
                if(this.settingsService.enableFilterNoise()){
                    stopMediaFilterStream();
                }
                const audioStrack = this.producerAudio.track;
                if(audioStrack){
                this.recordClientService.splitAudio(this.audioIn);
                audioStrack.stop();
                }
                
                // Xóa sự kiện onended khi tắt Mic
                this.originAudioStream.getTracks().forEach((track) => {
                    track.onended = null;
                });
                
                if(this.originAudioStream && this.originAudioStream.getAudioTracks()[0])
                this.originAudioStream.getAudioTracks()[0].stop();

                this.producerAudio.close();
                await this.socketService.sendRequest('closeProducer', { producerId: this.producerAudio.id });
                this.speakingService.removeFromSpeakingList(this.localParticipantService.getPeerId());
                // Đóng luồng speechEvents khi tắt mic
                if(this.settingsService.checkUseConfigHarkJs())  {
                    this.stopListeningToSpeechEvents();
                }
                // this.localParticipantService.setIsMuted(true);
                this.updateLocalActiveSpeaker();
                this.consumerService.updateAudioConsumer();
                this.mediaStreamService.removeStream(this.localParticipantService.getLocalParticipant().id, 'audio');
                
            } catch (error) {
                // this.notification.error(this.i18nService.translate("service.room.microphone", {}, "Microphone"), this.i18nService.translate("service.room.turnOffMicError", {}, "Tắt mic không thành công, vui lòng thử lại"));
                if(unCheckError) return;
                console.log(error);
                this.sendReport('audio' as REPORT_TYPE,'', this.muteMic.name + error);
                // notify "Unable to mute your microphone"
            }
            finally {
                this.setIsProcessingAudio(false);
                const timeOutAudioButton = setTimeout(() => {
                    var evt = new CustomEvent("toggleMic", { detail: { type: "" } });
                    window.dispatchEvent(evt);
                    clearTimeout(timeOutAudioButton);
                }, 2000);
            }
        }
    }

    async unmuteMic(): Promise<void> {

        if (!this.producerAudio) { this.producerAudioStart(); }
        else {
            this.producerAudio.resume();
            try {
                await this.socketService.sendRequest('resumeProducer', { producerId: this.producerAudio.id });
                this.settingsService.setAudioMuted(false);
                this.updateLocalActiveSpeaker();
            } catch (error) {
                console.log('unmuteMic() [error:"%o"]', error);
                this.sendReport('audio' as REPORT_TYPE,'', this.unmuteMic.name + error);
                // notify: "Unable to unmute your microphone"
            }
        }
    }

    public resetRoomTransports(): void {
        if (this._sendRestartIce && this._sendRestartIce.timer) clearTimeout(this._sendRestartIce.timer);
        if (this._recvRestartIce && this._recvRestartIce.timer) clearTimeout(this._recvRestartIce.timer);

        if (this.audioTransport.getValue()) {
            this.latencyService.unSubscribe("audio", this.audioTransport.getValue());
        }
        
        try {
            if (this.producerVideo) {
                this.producerVideo.close();
            }
        } catch(error: any) {
            console.warn("close video producer failed with error:", error);
        } finally {
            this.producerVideo = null;
            this.localParticipantService.updateLocalProducer('', 'webcam'); //cap nhat lai trang thai webcam cua local
        }

        try {
            if (this.producerAudio) {
                this.producerAudio.close();
            }
        } catch (error: any) {
            console.warn("close audio producer failed with error:", error);
        } finally {
            this.producerAudio = null;
            this.localParticipantService.updateLocalProducer('','audio');//cap nhat lai trang thai audio cua local
        }

        try {
            if (this.producerTransport) {
                this.producerTransport.close();
            }

            if (this.consumerTransport) {
                this.consumerTransport.close();
            }
        } catch(error) {
            console.warn("close transport failed with error:", error);
        } finally {
            this.producerTransport = null;
            this.consumerTransport = null;
            this.delayAudioScreenIds = [];
        }
    }

    public async connectLocalHark(): Promise<void> {
        {
            this._harkStream = this.mediaStreamService.getMediaStream(this.localParticipantService.getLocalParticipant().id, 'audio');

            if(this._harkStream){
                const options = {threshold: -50};
                const speechEvents = hark(this._harkStream, options);
                if (this.speechLocalEvents) {
                    this.speechLocalEvents.stop();
                }

                this.speechLocalEvents = speechEvents;

                speechEvents.on('speaking', () => {
                    const localPeer = this.localParticipantService.getLocalParticipant();
                    this.speakingService.pushToSpeakingList(localPeer);

                    // Add logic view ActiveSpeaker
                    const activity = this.activeSpeakerService.getActiveSpeaker();
                    const spotlight = this.activeSpeakerService.getSpotlight();
                    if (!activity.screen.state && activity.id != localPeer.id && !spotlight) {
                        this.activeSpeakerService.setActiveSpeaker(localPeer);
                    }
                });
            }
        }
    }

    public stopListeningToSpeechEvents(): void {
        if (this.speechLocalEvents) {
            this.speechLocalEvents.stop();
            this.speechLocalEvents = null;
        }
    }

    public async connectRemoteHark(peerId: string): Promise<void> {
        {
            const _harkStream = this.mediaStreamService.getMediaStream(peerId, 'audio');

            if(_harkStream){
                let peer = this.poolService.getPeer(peerId);
                let index = this._harkStreamRemote.findIndex(p => p.id == peerId);
                if (index != -1 && this._harkStreamRemote[index].events) {
                    this._harkStreamRemote[index].events.stop();
                    this._harkStreamRemote.splice(index, 1);
                }

                const options = {threshold: -50};
                const speechEvents = hark(_harkStream, options);
                this._harkStreamRemote.push({id: peerId, stream: _harkStream, events: speechEvents});
                
                speechEvents.on('speaking', () => {
                    this.speakingService.pushToSpeakingList(peer);

                    // Add logic view ActiveSpeaker
                    const activity = this.activeSpeakerService.getActiveSpeaker();
                    const spotlight = this.activeSpeakerService.getSpotlight();
                    if (!activity.screen.state && activity.id != peer.id && !spotlight) {
                        this.activeSpeakerService.setActiveSpeaker(peer);
                    }
                });
            }
        }
    }

    public stopListeningToSpeechRemoteHarkEvents(peerId: string): void {
        let index = this._harkStreamRemote.findIndex(p => p.id == peerId);
        
        if (this._harkStreamRemote[index].events ) {
            this._harkStreamRemote[index].events.stop();
            this._harkStreamRemote[index].events = null;
            this._harkStreamRemote.splice(index, 1);
        }
    }

    // tslint:disable-next-line:ban-types
    public async handleRoomReady(data: any, cb: Function): Promise<void> {
        const { turnServers } = data;
        this.turnServers = turnServers;
        await this.loadTransport();
        cb();
        //disable start producer audio in handleRoomReady => check join audio before start producer audio (join-audio component)
        // if (!isDisableAudio && !isBlockAllAudio) { await this.producerAudioStart(); }
        const appConfig = await this.settingsService.getAppConfig();
        if (appConfig.test?.autoOnWebCam) {
            await this.producerVideoStart();
        }
    }

    public async leaveRoom(): Promise<void> {
        if (this.meetingEnded) return;
        this.meetingEnded = true;
        this.socketService.sendRequest('leaveRoom', {});
        
        if (this.recordClientService.getIsRecordingClient())
            await this.recordClientService.recordStop(this.settingsService.getRoomName());
        this.handleCloseRoom();
    }

    public async closeRoom(message?: string, showFeedback: boolean = true): Promise<void> {
        if (this.meetingEnded) return;
        this.meetingEnded = true;
        this.resetRoomTransports();
        this.poolService.deleteAllPeer();
        if (this.recordClientService.getIsRecordingClient())
            await this.recordClientService.recordStop(this.settingsService.getRoomName());
        if (message) {
            if (showFeedback && this.isJoinedSubject.getValue()) this.showFeedbackForm(message, this.handleCloseRoom.bind(this));
            else this.roomNotify.next({ message, cb: this.handleCloseRoom.bind(this) });
        } else {
            this.handleCloseRoom();
        }
    }

    public async handleCloseRoom() {
        if (this.producerTransport) { this.producerTransport.close(); }
        if (this.consumerTransport) { this.consumerTransport.close(); }
        this.timeLog.setEndTime('leaveRoom');
        this.settingsService.getAppConfig().then(({homePage}) => {
            window.location.href = (this.roomConfig && this.roomConfig.logoutURL)
                ? this.roomConfig.logoutURL
                : homePage;
        });
    }

    async startLocalVideo(): Promise<void> {
        // debugger;
        //Tam dung producer video
        if (this.producerVideo) {
            // return;
            this.producerVideo.pause();
        }
        // let videoStream;
        const { resolution, aspectRatio, frameRate, videoQuality } = config;
        const quality = videoQuality[`${this.quality}`]
        this.virtualBackgroundService.stop();
        const videoContrains =
        {
            width: quality.width,
            height: quality.height,
            ...this.getVideoConstrains(quality.resolution, aspectRatio),
            frameRate: quality.frameRate
        };
        try {
            if (this.settingsService.getUrlBackground()) {
                // debugger
                this.virtualBackgroundService.virtualBackground = this.settingsService.getUrlBackground();
                await this.virtualBackgroundService.handleVirtualBackground(videoContrains, this.quality,  async (stream) => {
                    this.settingsService.setLocalVideoStream(stream);
                    // return;
                }, this.disableWebcam);
            } else {
                // Mở setting không có virtual background/Mở lần đầu
                if (this.audioDeviceService.getVideo() && this.audioDeviceService.getVideo().deviceId != "default") {
                    videoContrains.deviceId = { exact: this.audioDeviceService.getVideo().deviceId }
                    this.localStream = await navigator.mediaDevices.getUserMedia({
                        video: videoContrains
                    });
                }
                else {
                    this.localStream = await navigator.mediaDevices.getUserMedia({ video: videoContrains });
                }

                // Bổ sung lấy deviceId dựa trên luồng videoTrack và lưu vào audioDeviceService
                const videoTrack = this.localStream.getVideoTracks()[0];
                const { deviceId } = videoTrack.getSettings();
                
                const listVideo = await navigator.mediaDevices.enumerateDevices();
                const video = listVideo.find(ele => ele.deviceId == deviceId);
                this.audioDeviceService.changeVideo(video);
                this.audioDeviceService.changeLocalVideo(video);

                this.settingsService.setLocalVideoStream(this.localStream);
            }
        } catch (error) {
            console.log(error);
        }
    }

    // Stop luồng stream video trong setting/video
    async stopLocalVideo(): Promise<void> {
        if(this.localStream)
            this.localStream.getTracks().forEach(track=> {
                track.stop();
        })
    }

    async updateVirtualBackground(): Promise<void> {
        const { aspectRatio, videoQuality } = config;

        const quality = videoQuality[`${this.quality}`]
        const videoContrains =
        {
            width: quality.width,
            height: quality.height,
            ...this.getVideoConstrains(quality.resolution, aspectRatio),
            frameRate: quality.frameRate       
        };
        await this.virtualBackgroundService.handleVirtualBackgroundLocalVideo(videoContrains, this.quality, async (stream) => {
            this.settingsService.setLocalVideoStream(stream);
        }, this.disableWebcam);
    }

    async startVirtualBackground(background): Promise<void> {
        this.virtualBackgroundService.stop();
        this.settingsService.setLocalVideoStream(null);
        this.virtualBackgroundService.virtualBackground = background;
        // this.disableWebcam().then(() => {
        // this.producerVideoStart();
        // });

        this.updateVirtualBackground();
    }


    async stopVirtualBackground(): Promise<void> {
        const { aspectRatio, videoQuality } = config;
        const quality = videoQuality[`${this.quality}`]
        const videoContrains =
        {
            width: quality.width,
            height: quality.height,
            ...this.getVideoConstrains(quality.resolution, aspectRatio),
            frameRate: quality.frameRate       
        };
        this.virtualBackgroundService.stop();
        let videoStream;
        if (this.audioDeviceService.getLocalVideo() && this.audioDeviceService.getLocalVideo().deviceId != 'default') {
            videoContrains.deviceId = { exact: this.audioDeviceService.getLocalVideo().deviceId };
            videoStream = await navigator.mediaDevices.getUserMedia({
                video: videoContrains
            });
        }
        else {
            videoStream = await navigator.mediaDevices.getUserMedia({video: videoContrains});
        }
        this.settingsService.setLocalVideoStream(videoStream);

    }



    async updateScreenSharing({
        start = false,
        newResolution = null,
        newFrameRate = null
    } = {}): Promise<void> {

        let track;
        try {
            const available = this._screenSharing.isScreenShareAvailable();

            const isAudioEnabled = this._screenSharing.isAudioEnabled();

            if (!available) {
                this.notification.warning("", this.i18nService.translate("service.room.noshareScreen",{},""));
                throw new Error('screen sharing not available');
            }

            if (!this.mediasoupDevice.canProduce('video')) {
                throw new Error('cannot produce video');
            }

            // if (newResolution) {
            //     this.settingsService.setScreenSharingResolution(newResolution);
            // }

            // if (newFrameRate) {
            //     this.settingsService.setScreenSharingFrameRate(newFrameRate);
            // }
            // this.settingsService.setScreenShareInProgress(true);

            let {
                screenSharingResolution,
                autoGainControl,
                echoCancellation,
                noiseSuppression,
                aspectRatio,
                screenSharingFrameRate,
                sampleRate,
                channelCount,
                sampleSize,
                opusStereo,
                opusDtx,
                opusFec,
                opusPtime,
                opusMaxPlaybackRate
            } = new ScreenShareDefault();

            aspectRatio = 16 / 9;
            screenSharingResolution = 'high';

            if (start) {
                let stream;
                if (isAudioEnabled) {
                    stream = await this._screenSharing.start({
                        ...this.getVideoConstrains(screenSharingResolution, aspectRatio),
                        frameRate: screenSharingFrameRate,
                        sampleRate,
                        channelCount,
                        autoGainControl,
                        echoCancellation,
                        noiseSuppression,
                        sampleSize
                    });
                } else {
                    stream = await this._screenSharing.start({
                        ...this.getVideoConstrains(screenSharingResolution, aspectRatio),
                        frameRate: screenSharingFrameRate
                    });

                }

                ([track] = stream.getVideoTracks());

                this.localParticipantService.shareLocalScreen(stream);

                if (stream) {
                    this.mediaStreamService.addStream(this.localParticipantService.getLocalParticipant().id, 'screen', track);
                    const layoutMode = this.settingsService.getLayoutMode();
                    if (layoutMode && layoutMode !== 'breakroom') {
                        if(this.getForceLayout.layout != 0 && this.hasPermission(permissions.MODERATE_ROOM)){
                            let layoutId = DISPLAY_LAYOUT.find(layout => layout.name == 'sidebyside').id;
                            let pinners = this.getForceLayout.pinners;
                            this.setForceLayout(layoutId, pinners, this.getForceLayout.hideNoneCamera);
                            this.changeForceLayout(layoutId, pinners, this.getForceLayout.hideNoneCamera);
                        }else if(this.getForceLayout.layout == 0){
                            this.settingsService.changeLayoutMode('sidebyside');
                        }
                    }
                    this.settingsService.setShareScreenStatus(true);
                }
                const { width, height } = track.getSettings();

                const networkPriority =
                    config.networkPriorities?.screenShare ?
                        config.networkPriorities?.screenShare :
                        DEFAULT_NETWORK_PRIORITIES.screenShare;

                if (this._useSharingSimulcast) {
                    let encodings = this.getEncodings(width, height, true);

                    // If VP9 is the only available video codec then use SVC.
                    const firstVideoCodec = this.mediasoupDevice
                        .rtpCapabilities
                        .codecs
                        .find((c) => c.kind === 'video');

                    if (firstVideoCodec.mimeType.toLowerCase() !== 'video/vp9') {
                        encodings = encodings
                            .map((encoding) => ({ ...encoding, dtx: true }));
                    }

                    const resolutionScalings = this.getResolutionScalings(encodings);


                    /**
                     * TODO:
                     * I receive DOMException:
                     * Failed to execute 'addTransceiver' on 'RTCPeerConnection':
                     * Attempted to set an unimplemented parameter of RtpParameters.
                     * encodings.forEach((encoding) =>{encoding.networkPriority=networkPriority;});
                     */
                    // @ts-ignore
                    encodings[0].networkPriority = networkPriority;

                    this._screenSharingProducer = await this.producerTransport.produce(
                        {
                            track,
                            encodings,
                            codecOptions:
                            {
                                videoGoogleStartBitrate: 1000
                            },
                            appData:
                            {
                                source: 'screen',
                                width,
                                height,
                                resolutionScalings
                            }
                        });
                } else {
                    this._screenSharingProducer = await this.producerTransport.produce({
                        track,
                        encodings: [{ networkPriority }],
                        appData:
                        {
                            source: 'screen',
                            width,
                            height
                        }
                    } as any);
                }
                const dataProducer: ProducerModel = {
                    id: this._screenSharingProducer.id,
                    deviceLabel: 'screen',
                    source: 'screen',
                    paused: this._screenSharingProducer.paused,
                    track: this._screenSharingProducer.track,
                    rtpParameters: this._screenSharingProducer.rtpParameters,
                    codec: this._screenSharingProducer.rtpParameters.codecs[0].mimeType.split('/')[1]
                };

                // if(displaySurface =="window") {
                //     track.onunmute = () => {
                //         if(!this.activeSpeakerService.getnewPeerJoinSubject()) return;
                //         console.log(">>>mute before unmute");
                //         this.pauseScreenSharing();
                //         setTimeout(() => {
                //             this.resumeScreenSharing();
                //             this.activeSpeakerService.setNewPeerJoinSubject(false);
                //         }, 500);
                //     }
                // }
                
                this.producerService.addProducer(dataProducer);

                this.localParticipantService.updateLocalProducer(this._screenSharingProducer.id, 'screen');
                this.localParticipantService.setIsSharing(true);

                const local = this.localParticipantService.getLocalParticipant();
                this.activeSpeakerService.setActiveSpeaker(local);

                this._screenSharingProducer.on('transportclose', () => {
                    this._screenSharingProducer = null;
                });

                this._screenSharingProducer.on('trackended', () => {
                    this.disableScreenSharing();
                    this.settingsService.setShareScreenStatus(false);
                });
                let trackAudio: any;
                ([trackAudio] = stream.getAudioTracks());
                if (isAudioEnabled && trackAudio) {
                    let audioStream = new MediaStream();
                    audioStream.addTrack(trackAudio)
                    this.audioSCIn = this.recordClientService.mergeAudio(audioStream);
                    this._screenSharingAudioProducer = await this.producerTransport.produce(
                        {
                            track: trackAudio,
                            codecOptions:
                            {
                                opusStereo,
                                opusFec,
                                opusDtx: false,
                                opusMaxPlaybackRate,
                                opusPtime
                            },
                            // disableTrackOnPause: false,
                            // zeroRtpOnPause: true,
                            appData:
                                { source: 'screen' }
                        });
                    const dataProducer2 = {
                        id: this._screenSharingAudioProducer.id,
                        deviceLabel: '',
                        source: 'screen',
                        paused: this._screenSharingAudioProducer.paused,
                        track: this._screenSharingAudioProducer.track,
                        rtpParameters: this._screenSharingAudioProducer.rtpParameters,
                        codec: this._screenSharingAudioProducer.rtpParameters.codecs[0].mimeType.split('/')[1]
                    };
                    this.producerService.addProducer(dataProducer2);

                    this._screenSharingAudioProducer.on('transportclose', () => {
                        this._screenSharingAudioProducer = null;
                    });

                    this._screenSharingAudioProducer.on('trackended', () => {
                    });

                    this._screenSharingAudioProducer.volume = 0;
                }

            } else {
                if (this._screenSharingProducer) {
                    ({ track } = this._screenSharingProducer);

                    await track.applyConstraints(
                        {
                            ...this.getVideoConstrains(screenSharingResolution, aspectRatio),
                            frameRate: screenSharingFrameRate
                        }
                    );
                }
                if (this._screenSharingAudioProducer) {
                    ({ track } = this._screenSharingAudioProducer);

                    await track.applyConstraints(
                        {
                            sampleRate,
                            channelCount,
                            autoGainControl,
                            echoCancellation,
                            noiseSuppression,
                            sampleSize
                        }
                    );
                }
            }
        } catch (error) {
            console.log(error);
            if (error.name == 'NotAllowedError') {
                switch (this._device.flag) {
                    case 'firefox':
                    case 'safari':
                        this.notification.error(this.i18nService.translate("service.room.notification", {}, `Thông báo`),
                            this.i18nService.translate("service.room.notAllowedShareScreen", {}, "Trình duyệt chưa được cấp quyền hoặc bị từ chối quyền share screen. Bạn kiểm tra lại thiết bị đã cung cấp quyền này chưa"));
                        break;
                    case 'opera':
                    case 'chrome':
                    case 'chromium':
                    case 'edge':
                        break;
                    default:
                        break;
                }
            }
            this.settingsService.setShareScreenStatus(false);
            // logger.error('updateScreenSharing() [error:"%o"]', error);
            // logger.error('updateScreenSharing() [error:"%o"]', 'An error occurred while accessing your screen');
            if (track) {
                track.stop();
            }
            this.sendReport('video' as REPORT_TYPE,'', this.updateScreenSharing.name + error);
        }
        // this.settingsService.setScreenShareInProgress(false);
    }

    public async pauseScreenSharing(): Promise<void> {
        try {
            // if (this._screenSharingProducer && !this._screenSharingProducer.paused) {
            if(!this.screenPause){
                this.screenPause = true;
                await this.socketService.sendRequest('pauseProducer', {producerId: this._screenSharingProducer.id});  
            }
               // this._screenSharingProducer.pause();
            // }
        } catch (error) {
            this.sendReport('screen share' as REPORT_TYPE,'', this.pauseScreenSharing.name + error);
            console.error(error.message, error.stack);
        }
    }

    public async resumeScreenSharing(): Promise<void> {
        try {
            // if (this._screenSharingProducer && !this._screenSharingProducer.paused) {
            if(this.screenPause){
                this.screenPause = false;
                await this.socketService.sendRequest('resumeProducer', {producerId: this._screenSharingProducer.id});
            }
            // this._screenSharingProducer.resume();
            // }
        }
        catch (error) {
            this.sendReport('screen share' as REPORT_TYPE,'', this.resumeScreenSharing.name + error);
            console.error(error.message, error.stack);
        }
    }

    async disableScreenSharing(unCheckError: boolean = false): Promise<void> {

        this.localParticipantService.updateLocalProducer('', 'screen');
        this.localParticipantService.shareLocalScreen(null);
        this.localParticipantService.setIsSharing(false);
        this.settingsService.setShareScreenStatus(false);
        if (!this._screenSharingProducer) {
            return;
        }
        // this.settingsService.setScreenShareInProgress(true);
        if(this.audioSCIn) {
            this.recordClientService.splitAudio(this.audioSCIn);
            this.audioSCIn = null;
        }
        this._screenSharingProducer.close();

        if (this._screenSharingAudioProducer) {
            this._screenSharingAudioProducer.close();
            this.producerService.removeProducer(this._screenSharingAudioProducer.id);
        }

        this.producerService.removeProducer(this._screenSharingProducer.id);

        try {
            await this.socketService.sendRequest(
                'closeProducer', { producerId: this._screenSharingProducer.id });

            if (this._screenSharingAudioProducer) {
                await this.socketService.sendRequest(
                    'closeProducer', { producerId: this._screenSharingAudioProducer.id });
            }

            // const local = this.localParticipantService.getLocalParticipant();
            // if (local && this.activeSpeakerService.isExistParticipant(local.id)) {
            //     this.activeSpeakerService.setActiveSpeaker(local);
            // }

            //Cap nhat lai view activeSpeaker khi tat sharescreen 
            const listRemoteParticipant = this.poolService.getListParticipantIsShareScreen();
            if (listRemoteParticipant && listRemoteParticipant.length) {
                this.activeSpeakerService.setActiveSpeaker(listRemoteParticipant[0]);
            } else {
                this.activeSpeakerService.setActiveSpeaker(this.localParticipantService.getLocalParticipant());
            }

            this._screenSharingProducer = null;
            this._screenSharingAudioProducer = null;
            // await this.consumerVideoStart();
            this._screenSharing.stop();

            // this.settingsService.setScreenShareInProgress(false);

        } catch (error) {
            if(unCheckError) return;
            console.error('disableScreenSharing() [error:"%o"]', error);
            this.sendReport('video' as REPORT_TYPE,'', this.disableScreenSharing.name + error);
        }
    }

    async reProduceScreenSharing(videoConsumerMap: Map<string, VideoStatus>): Promise<void> {
        const map = videoConsumerMap.entries();
        let iterator = map.next().value;

        while (iterator) {
            const isHaveScreenShare = iterator[1].isHaveScreenShare;
            if (isHaveScreenShare) {
                await this.createNewScreenConsumer(iterator[0], iterator[1].isHaveScreenShare);
            }
            iterator = map.next().value;
        }
    }

    checkAudioAndVideoPermission(roomUserRoles, userRoles): void {
        const audioPermissionId = roomUserRoles[permissions.SHARE_AUDIO].id;
        const videoPermissionId = roomUserRoles[permissions.SHARE_VIDEO].id;

        if (!userRoles.includes(audioPermissionId)) {
            this.settingsService.setAudioMuted(true);
            this.settingsService.setAudioBlocked(true);
        } else {
            this.settingsService.setAudioBlocked(false);
        }
        if (!userRoles.includes(videoPermissionId)) {
            this.settingsService.setVideoMuted(true);
            this.settingsService.setVideoBlocked(true);
        } else {
            this.settingsService.setVideoBlocked(false);
        }
        // this.settingsService.setVideoUnblocked(false);
        // this.settingsService.setAudioUnblocked(false);
        this.updateLocalActiveSpeaker();
    }

    async startWhiteboard(): Promise<void> {
        try {
            var started = this.isWhiteboard.getValue() || await this.socketService.sendRequest('wb:start', {});
            if (started) {
                this.triggerWhiteboard('whiteboard');
                this.setIsWhiteboard(true);
            }
        } catch (error) {
            console.log('startWhiteboard() [error:"%o"]', error);
        }
    }

    async stopWhiteboard(): Promise<void> {
        try {
            await this.socketService.sendRequest('wb:stop', {});
            this.setIsWhiteboard(false);
        } catch (error) {
            console.log('stopWhiteBoard() [error:"%o"]', error);
        }
    }

    async sendMessageKonvaData(data: object): Promise<void> {
        try {
            await this.socketService.sendRequest('wb:broadcast', data);
        } catch (error) {
            console.log('sendMessageKonvaData [error:"%o"]', error);
        }
    }

    async sendMessageUndo(): Promise<void> {
        try {
            await this.socketService.sendRequest('wb:undo', {});
        } catch (error) {
            console.log('sendMessageUndo [error:"%o"]', error);
        }
    }

    async sendMessageClear(): Promise<void> {
        try {
            await this.socketService.sendRequest('wb:clear', {});
        } catch (error) {
            console.log('sendMessageClear [error:"%o"]', error);
        }
    }

    async getWhiteboardDataHistory(): Promise<void> {
        try {
            var data = await this.socketService.sendRequest('wb:data', {});
            if (data.length > 0) {
                this.setWhiteboardData(data);
            }
        } catch (error) {
            console.log('getWhiteboardDataHistory [error:"%o"]', error);
        }
    }

    async lockAllWhiteboard(whiteboardLocked: boolean) {
        try {
            // await this.socketService.sendRequest('wb:lock', { lock: whiteboardLocked });
            await this.socketService.sendRequest('moderator:grantAllPeerPermission',
                {
                    roleId: PERMISSIONS.WHITEBOARD.id,
                    allowed: !whiteboardLocked
                }
            );
        } catch (error) {
            console.log('lockAllWhiteboard [error:"%o"]', error);
        }
    }

    public hasPeerPermission(permission: string, roles: number[]): boolean {
        const roomPermissions = this.getRoomPermission();

        if (!roomPermissions) { return false; }

        const permitted = roles?.some((roleId) =>
            roomPermissions[permission]?.some((permissionRole) =>
                roleId === permissionRole.id
            )
        );
        return permitted;
    }

    public hasPermission(permission: string): boolean {
        const roomPermissions = this.getRoomPermission();
        const localRoles = this.localParticipantService.getRolesSubject();

        if (!roomPermissions) { return false; }

        const permitted = localRoles?.some((roleId) =>
            roomPermissions[permission]?.some((permissionRole) =>
                roleId === permissionRole.id
            )
        );
        return permitted;
    }

    public setRoomConfig(key: string, value: any): void {
        this.roomConfig[key] = value;
        // console.log(this.roomConfig.lockSettingsDisableCam);
    }

    public getRoomConfig(): RoomConfigModel {
        return this.roomConfig;
    }

    public getIsExtended(): boolean {
        return this.roomConfig.isExtended;
    }

    public getTimeLeftRoom(): number {
        return this.timeLeftRoom;
    }

    async changeForceLayout(layoutId: number, pinners: Array<string>, hideNonCamera: boolean) {
        try {
            await this.socketService.sendRequest('moderator:forceLayout',
                {
                    layout: layoutId,
                    pinners: pinners,
                    hideNoneCamera: hideNonCamera
                }
            );
        } catch (error) {
            console.log('send request moderator:forceLayout [error:"%o"]', error);
            this.sendReport('feature' as REPORT_TYPE,'',this.changeForceLayout.name + error);
        }
    }

    async moderatorPin(pinners: Array<string>): Promise<any> {
        this.securityService.setProcessingPinCamer(true);
        try {
            const response = await this.socketService.sendRequest('moderator:pinCamera',
                {
                    pinners: pinners
                }
            );
            this.setForceLayout(this.getForceLayout.layout, response.message, this.getForceLayout.hideNoneCamera)

        } catch (error) {
            console.log('send request moderator:pinCamera [error:"%o"]', error);
            this.sendReport('feature' as REPORT_TYPE,'', this.moderatorPin.name + error);
            this.securityService.setProcessingPinCamer(false);
            return false;
        }
        this.securityService.setProcessingPinCamer(false);
        return true;
    }

    async sendRoomDuration(durationNumber: number): Promise<void> {
        try {
            const extendTime = await this.socketService.sendRequest('moderator:extendRoomDuration', {'extendTime':durationNumber});
           
            this.roomConfig.duration = extendTime['duration'];
            this.roomConfig.endTime = this.roomConfig.duration*60000 + this.roomConfig.startTime;
            
            this.setMeetingTimeout({timeLeft: 0, message: "", isResetDestruct: false});
            this.timeLeftRoom = 0;
            this.roomConfig.isExtended = true;
            this.notification.success(this.i18nService.translate("duration.config.success",{},
            "Đã gia hạn thành công"), '');
        } catch (error) {
            console.log('extendTime() [error:"%o"]', error);
            this.notification.error(this.i18nService.translate("duration.config.error",{}, "Đã gia hạn thất bại"), '');
            this.sendReport('feature' as REPORT_TYPE,'', this.sendRoomDuration.name + error);
        }
    }

    async sendReport(type: REPORT_TYPE, selectReport?: string, otherReport?: string) : Promise<boolean>{
        try {
            if (selectReport) selectReport = this.i18nService.translate("report." + selectReport, {}, '');
            let message = otherReport ? otherReport : selectReport;
            // console.log("info: ", type, message, this._device.name, this._device.version, this._device.os, this._device.platform);
            let result = await this.socketService.sendRequest('report',
                {
                    // 'types': type.toUpperCase(),
                    // 'message': message,
                    // 'browser': {
                    //     'name': this._device.name,
                    //     'version': this._device.version
                    // },
                    // 'os': this._device.os,
                    // 'platform': this._device.platform
                    'types': type.toUpperCase().split(","),
                    'message': message,
                    'version': config.ver,
                    'platform': {
                        "type": this._device.platform, 
                        "os": this._device.os, 
                        "software": this._device.name,
                        "version": this._device.version ,
                        "note": null
                    },
                });
            if (result.code === 200) return true;
            else return false;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    async resetDestructTimeout(): Promise<void> {
        try {
            await this.socketService.sendRequest('moderator:resetDestructTimeout', {});
           
            this.notification.success(this.i18nService.translate("reset-destruct-timout.config.success",{},
            "Đặt lại thời gian thành công"), '');
        } catch (error) {
            console.log('extendTime() [error:"%o"]', error);
            this.notification.error(this.i18nService.translate("reset-destruct-timout.config.error",{}, "Đặt lại thời gian thất bại"), '');
            this.sendReport('feature' as REPORT_TYPE,'', this.resetDestructTimeout.name + error);
        }
    }

    async sendProduceInfo(producerId: string){
        const data = { producerId: producerId, roomId: this.getRoomId(), id: 'produceInfo' };
        try {
            await this.latencyService.sendRequest(data);
        // try {
            // await this.socketService.sendRequest('produceInfo', { producerId: producerId, roomId: this.roomConfig.roomID });
        } catch (error) {
            console.log(error);
            // this.sendReport('other' as REPORT_TYPE,'', this.sendProduceInfo.name + error);
        }
    }

    async lockRoom(): Promise<void> {
        this.securityService.setProcessingLockRoom(true);
        try {
            const t = await this.socketService.sendRequest('lockRoom', {});
            this.securityService.setLockRoom(true);
        } catch (error) {
            console.log("lockRoom error ", error);
            this.sendReport('feature' as REPORT_TYPE,'', this.lockRoom.name + error);
        }
        this.securityService.setProcessingLockRoom(false);    
    }

    async unLockRoom(): Promise<void> {
        this.securityService.setProcessingLockRoom(true);
        try {
            // const t = await this.socketService.sendRequest('unlockRoom', {});
            // this.promoteAllPeers();
            const maxParticipant = this.settingsService.getMaxParticipants();
            const listRemoteParticipant = this.poolService.getAllPeers();
            const lengthList = listRemoteParticipant.length + 1;
            const slot = maxParticipant - listRemoteParticipant.length - 1;
            if (maxParticipant != 0) {
                if (this.lobbyService.getLobbyList() && (this.lobbyService.getLobbyList().size > slot)) {
                    if (slot == 0) {
                        this.notification.error(this.i18nService.translate("service.mediasoup-base.overRoomLimit", {}, "Vượt giới hạn số người trong phòng"),
                            this.i18nService.translate("service.mediasoup-base.pleaseRefuseUsers", {}, "Vui lòng từ chối người dùng trước khi mở khóa phòng")); // Cắt ngắn và chia nội dung thông báo lại

                    } else {
                        this.notification.error(this.i18nService.translate("service.mediasoup-base.lobbyLimit", {}, "Số lượng người dùng trong phòng chờ vượt giới hạn"),
                            this.i18nService.translate("service.mediasoup-base.pleaseApproveRejectUsers", {}, "Vui lòng duyệt/từ chối người dùng trước khi mở khóa phòng")); // Cắt ngắn và chia nội dung thông báo lại
                    }
                    // this.securityService.setLockRoom(true);
                    this.lobbyService.setLobbyListOpen(true);
                    return;
                }
            }
            await this.promoteAllPeers();
            const t = await this.socketService.sendRequest('unlockRoom', {});
            this.securityService.setLockRoom(false);
            
        } catch (error) {
            console.log("unLockRoom error ", error);
            this.sendReport('feature' as REPORT_TYPE,'', this.unLockRoom.name + error);
        }
        this.securityService.setProcessingLockRoom(false);
    }

    async promotePeer(peerId): Promise<void> {
        // this.lobbyService.setLobbyListOpen(false);
        try {
            await this.socketService.sendRequest('promotePeer', { peerId });

        } catch (error) {
            console.log(error);
            this.sendReport('feature' as REPORT_TYPE,'', this.promotePeer.name + error);
        }
        // this.lobbyService.setLobbyListOpen(true);
    }

    async promoteAllPeers(): Promise<void> {
        this.securityService.setProcessingPromoteAllPeers(true);
        try {
            await this.socketService.sendRequest('promoteAllPeers', {});
        } catch (error) {
            console.log(error);
            this.sendReport('feature' as REPORT_TYPE,'', this.promoteAllPeers.name + error);
        }
        this.securityService.setProcessingPromoteAllPeers(false);
    }

    async rejectPeer(peerId): Promise<void> {
        try {
            await this.socketService.sendRequest('rejectPeer', { peerId });
        } catch (error) {
            console.log(error);
            this.sendReport('feature' as REPORT_TYPE,'', this.rejectPeer.name + error);
        }
    }

    async rejectAllPeers(): Promise<void> {
        this.securityService.setProcessingRejectAllPeers(true);
        try {
            await this.socketService.sendRequest('rejectAllPeers', {});
        } catch (error) {
            console.log(error);
            this.sendReport('feature' as REPORT_TYPE,'', this.rejectAllPeers.name + error);
        }
        this.securityService.setProcessingRejectAllPeers(false);
    }
}
