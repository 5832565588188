/* tslint:disable:prefer-const no-debugger variable-name no-console */
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    AfterViewChecked,
    ViewChild
} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActiveSpeakerService, LocalParticipantService, MediaStreamService, MediasoupService, SettingsService} from '../../services';
import {Participant} from '../../models/participant.model';
import { RATIO_WIDTH, RATIO_HEIGHT, VIDEO_FADE_DURATION, VIDEO_FADE_DURATION_WITH_NO_GLITCH_DELAY } from '../../constants';
import { createFadeAnimation } from '../../animation';
import { LocalParticipant } from '../../models/local-participant.model';
import { PoolService } from '../../services/mediasoup/pool.service';
import { RemoteParticipant } from '../../models/remote-participant.model';

@Component({
    selector: 'lib-activating-speaker',
    templateUrl: './activating-speaker.component.html',
    styleUrls: ['./activating-speaker.component.less'],
    animations: [ createFadeAnimation(VIDEO_FADE_DURATION, VIDEO_FADE_DURATION_WITH_NO_GLITCH_DELAY) ]
})
export class ActivatingSpeakerComponent implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked {
    dropdownVisible = false;
    isShowOption = false;
    _webcamConsumerId = "";
    participant: Participant;
    currentSpeaker: Participant;
    mediaType: string;
    isScreenMuted = true;
    score = 10;
    coreColor: string = "";
    // followedByWidth = true;
    private subscriptions: Array<Subscription> = [];
    videoObserver: ResizeObserver;
    screenObserver: ResizeObserver;
    wrapperHeight = 'unset';
    isHoldSpaceTitle: boolean = false;
    isFullScreen: boolean = false;
    micStatus: boolean = false;
    joinAudio: boolean = false;
    micIconVisible: boolean = false;
    isLocal: boolean = false;
    holdSpacebarTimeOut: any = null;
    changeSpeakerTimeout: any = null;
    nameAcronym: string = '';
    @ViewChild('activatingSpeakerBox') activatingSpeakerBox: ElementRef;
    @ViewChild('activatingSpeakerVideo') activatingSpeakerVideo: ElementRef;
    activatingSpeakerArea: ElementRef;
    @ViewChild('activatingSpeakerArea') set content(content: ElementRef) {
       if (content) { // initially setter gets called with undefined
           this.activatingSpeakerArea = content;
           // console.log("# set remoteViewRef #");
           this.checkJoinAudio(this.settingsService.getIsJoinedAudio());
           this.onActiveSpeaker(this.activeSpeakerService.getActiveSpeaker());
       }
    }
    
    get webcamConsumerId(): string {
        return this._webcamConsumerId;
    }

    constructor(
        private activeSpeakerService: ActiveSpeakerService,
        private mediaStreamService: MediaStreamService,
        private mediasoupService: MediasoupService,
        private settingsService: SettingsService,
        private poolService: PoolService,
        private localParticipantService: LocalParticipantService,
        private cdRef: ChangeDetectorRef
    ) {
        this.participant = new Participant();
        this.currentSpeaker = this.localParticipantService.getLocalParticipant();
        this.nameAcronym = this.getNameAcronym();
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    ngOnInit(): void {
        // @ts-ignore
        this.videoObserver = new ResizeObserver((entries: any) => {
            const { width, height } = entries[0].contentRect;
            this.activatingSpeakerVideo.nativeElement.style.width = this.calcVideoBoxSize(width, height);
        });
        // @ts-ignore
        this.screenObserver = new ResizeObserver((entries: any) => {
            // console.log("@ activeSpeakerVideo size changed", entries[0].contentRect);

            const { height } = entries[0].contentRect;
            this.wrapperHeight = `${height}px`;
            const remote = this.poolService.getPeer(this.participant.id);
            this.requestConsumerReferredLayer(remote);
            this.cdRef.detectChanges();
        });
        this.onInitEvent();
    }

    ngAfterViewInit(): void {
        // this.onInitEvent();
        this.videoObserver.observe(this.activatingSpeakerBox.nativeElement);

        /* Standard syntax */
        this.activatingSpeakerBox.nativeElement.onfullscreenchange = this.onFullscreenChange.bind(this);
        /* Chrome, Safari and Opera syntax */
        this.activatingSpeakerBox.nativeElement.onwebkitfullscreenchange = this.onFullscreenChange.bind(this);
        /* IE/Edge syntax */
        this.activatingSpeakerBox.nativeElement.onmsfullscreenchange = this.onFullscreenChange.bind(this);
        this.screenObserver.observe(this.activatingSpeakerArea.nativeElement);
        this.cdRef.detectChanges();
    }

    onInitEvent(): void {
        this.subscriptions.push( this.settingsService.onJoinAudio().subscribe(this.checkJoinAudio.bind(this)));
        this.subscriptions.push(this.activeSpeakerService.onActiveSpeaker().subscribe(this.onActiveSpeaker.bind(this)));

        this.subscriptions.push(this.localParticipantService.onDisplayName().subscribe(()=>{
            if(this.currentSpeaker.id == this.localParticipantService.getLocalParticipant().id){
                this.nameAcronym = this.getNameAcronym();
            }
        }));

        this.subscriptions.push(this.poolService.onDisplayNameChange().subscribe((info)=>{
            if(this.currentSpeaker.id == info?.id) {
                this.nameAcronym = this.getNameAcronym();
            }
        }));
        
        this.subscriptions.push(this.mediasoupService.onUpdateRemoteVideoScore().subscribe(data => {
            if (!data) { return; }
            const { consumerId, score } = data;
            // tslint:disable-next-line:no-string-literal

            if (!consumerId || consumerId !== this.participant.webcam["consumerId"] || !score) { return; }
            this.score = score;
            this.coreColor = this.getScoreColor();
            this.cdRef.detectChanges();
        }));

        this.subscriptions.push(this.mediasoupService.onUpdateLocalVideoScore().subscribe(data => {
            if (!data) { return; }
            const { producerId, score } = data;
            // tslint:disable-next-line:no-string-literal
            if (!producerId || producerId !== this.participant.webcam.producerId || !score) { return; }
            this.score = score;
            this.coreColor = this.getScoreColor();
            this.cdRef.detectChanges();
        }));

        this.subscriptions.push(this.settingsService.onIsSpaceHoldSubject().subscribe(flag => {
            this.micStatus = !flag;
            if (flag) {
                this.micIconVisible = true;
                clearTimeout(this.holdSpacebarTimeOut);
            } else {
                this.holdSpacebarTimeOut = setTimeout(() => {
                    this.micIconVisible = false;
                    clearTimeout(this.holdSpacebarTimeOut);
                }, 2000);
            }
        }));

        this.subscriptions.push(this.activeSpeakerService.onResumeScreen().subscribe(flag => {
            if(flag) {
                this.resumeScreen();
            }
        }))
    }

    checkJoinAudio(isJoined: boolean) {

        const remote = this.activatingSpeakerArea;
        if(!isJoined || !remote?.nativeElement) { return; }
        let video =  remote.nativeElement;
        
        if (this.settingsService.isBrowseSafari() && remote.nativeElement.paused) {
            video.oncanplay = () => {
                video.play();
            };
            video.load();
        }
        
        if (!this.currentSpeaker) return;
        let validatePeer = this.poolService.getPeer(this.currentSpeaker.id);
        if (validatePeer && (this.mediaType == "screen" ? !validatePeer.screen.state : !validatePeer.webcam.state)) {
            this.mediaType = "";
            this.activatingSpeakerArea.nativeElement.srcObject = null;
            this.currentSpeaker = validatePeer;
            this.onActiveSpeaker(this.activeSpeakerService.getActiveSpeaker());
        }
        // this.cdRef.detectChanges();
    }

    onActiveSpeaker(participant: Participant) {
        if (!this.activatingSpeakerArea || (participant instanceof LocalParticipant && participant.isRecordUser)) {
            this.participant = new Participant();
            return;
        }
        if (!participant || !participant.id) { return; }
        // const canUpdate = this.participant.id !== participant.id;
        this.participant = participant;
        const screenState = participant.screen.state;
        const webcamState = participant.webcam.state;

        if (screenState) {
            this.updateShareScreen();
            return;
        }

        // this.disableScreen();
        if (webcamState) {
            const remote = this.poolService.getPeer(this.participant.id);
            const inCurrentPage = this.poolService.isPeerInCurrentPage(this.participant.id);
            if (remote && !inCurrentPage) {
                remote.startWebcam();
            }
        }
        this.updateWebcam(); 
    }

    updateWebcam(): void {
        if (this.changeSpeakerTimeout) {
            window.clearTimeout(this.changeSpeakerTimeout);
            this.changeSpeakerTimeout = null;
        }
        this.changeSpeakerTimeout = window.setTimeout(() => {
            // console.log("# start changeSpeakerTimeout", this.speakerId, this.participant.id, new Date().toLocaleTimeString());
            let [presenter] = this.poolService.getListParticipantIsShareScreen();

            if (!presenter || this.currentSpeaker.id != presenter.id) {
                // const oldRemote =  this.poolService.getPeer(this.currentSpeaker.id);
                const inCurrentPage = this.poolService.isPeerInCurrentPage(this.currentSpeaker.id);
                if (this.currentSpeaker.id != this.participant.id
                    && this.currentSpeaker.id != this.localParticipantService.getPeerId()
                    && !inCurrentPage && this.currentSpeaker.webcam.state
                ) {
                    RemoteParticipant.prototype.stopWebcam.call(this.currentSpeaker);
                }
                if (this.currentSpeaker.id != this.participant.id) {
                    this.currentSpeaker = this.participant;
                    this.nameAcronym = this.getNameAcronym();
                    this.score = 10;
                    if (this.localParticipantService.getPeerId() !=this.currentSpeaker.id) {
                        this.requestConsumerReferredLayer(this.currentSpeaker as RemoteParticipant);
                    }
                    this.isLocal = (this.currentSpeaker instanceof LocalParticipant);
                    this.mediaType = "";
                }
                if (this.currentSpeaker.webcam.state) {
                    if (this.mediaType == "webcam" && this.activatingSpeakerArea.nativeElement.srcObject) return;
                    this.mediaType = "webcam";
                    let stream = this.mediaStreamService.getMediaStream(this.participant.id, 'webcam');
                    this.updateVideo(stream);
                } else {
                    this.mediaType = "";
                    this.activatingSpeakerArea.nativeElement.srcObject = null;
                }
            }
            window.clearTimeout(this.changeSpeakerTimeout);
            this.changeSpeakerTimeout = null;
        }, this.mediaType ? 2000 : 0);
    }

    updateShareScreen(): void{
        if (this.changeSpeakerTimeout) {
            window.clearTimeout(this.changeSpeakerTimeout);
            this.changeSpeakerTimeout = null;
        }
        if (this.currentSpeaker.id != this.participant.id) {
            this.currentSpeaker = this.participant;
            this.nameAcronym = this.getNameAcronym();
            this.score = 10;
            this.isLocal = (this.currentSpeaker instanceof LocalParticipant);
            this.activatingSpeakerArea.nativeElement.srcObject = null;
            this.mediaType = "";
        }
        if (this.mediaType != "screen") {
            this.mediaType = "screen";
            const stream = this.mediaStreamService.getMediaStream(this.participant.id, 'screen');
            // const { width, height } = stream.getVideoTracks()[0].getSettings();
            // if (width < height) { this.followedByWidth = false; }
            this.updateVideo(stream);
            this.cdRef.detectChanges();
        }
    }

    updateVideo(stream: MediaStream): void{
        if(!this.activatingSpeakerArea.nativeElement.paused) {
            this.activatingSpeakerArea.nativeElement.pause();
        }
        if(!this.activatingSpeakerArea.nativeElement.srcObject) {
            this.activatingSpeakerArea.nativeElement.load();
        }
        this.activatingSpeakerArea.nativeElement.srcObject = stream;
        this.activatingSpeakerArea.nativeElement.muted = false;
        this.activatingSpeakerArea.nativeElement.oncanplay = () => {
            this.activatingSpeakerArea.nativeElement.play()
                .then(() => {
                    // console.log("# updateVideo #");
                    // const remote = this.poolService.getPeer(this.participant.id);
                })
                .catch((error) => {
                    this.activatingSpeakerArea.nativeElement.muted = true;
                    this.activatingSpeakerArea.nativeElement.play().catch(console.warn);
                });
        };
        this.activatingSpeakerArea.nativeElement.load();
        this.cdRef.detectChanges();
    }

    resumeScreen() {
        if(this.activatingSpeakerArea)
        this.activatingSpeakerArea.nativeElement.load();
    }

    requestConsumerReferredLayer(remote: RemoteParticipant) {
        const { clientWidth, clientHeight } = this.activatingSpeakerBox.nativeElement;
        if (remote && clientWidth && clientHeight) {
            // console.log("@onFullscreenChange", event.target, isFullScreen);
            // const screenState = remote.screen.state;
            const webcamState = remote.webcam.state;
            // console.log("# requestConsumerReferredLayer", {screenState, webcamState});
            if (webcamState) {
                this.mediasoupService.adaptConsumerPreferredLayers(remote.webcam.consumerId, clientWidth, clientHeight);
            }
        }
    }
    
    onFullscreenChange(event) {
        this.isFullScreen = (document.fullscreenElement == this.activatingSpeakerBox.nativeElement
            || (document as any).webkitFullscreenElement == this.activatingSpeakerBox.nativeElement
            || (document as any).msFullscreenElement == this.activatingSpeakerBox.nativeElement
            )
    // this.isFullScreen = isFullScreen;
        // console.log("@onFullscreenChange", event.target, isFullScreen);
        // if (this.isFullScreen) {
            // const remote = this.poolService.getPeer(this.participant.id);
            // this.requestConsumerReferredLayer(remote);
        // }
    }

    async expandVideo(): Promise<void> {
        const speaker = this.activatingSpeakerBox;
        if (speaker) {
            if (speaker.nativeElement.requestFullscreen) {
                speaker.nativeElement.requestFullscreen();
            } else if (speaker.nativeElement.mozRequestFullScreen) {
                speaker.nativeElement.mozRequestFullScreen();
            } else if (speaker.nativeElement.webkitRequestFullscreen) {
                speaker.nativeElement.webkitRequestFullscreen();
            } else if (speaker.nativeElement.msRequestFullscreen) {
                speaker.nativeElement.msRequestFullscreen();
            }

             navigator.mediaDevices.getUserMedia({audio: true}).then(async(stream) => {
                const listDevice = await navigator.mediaDevices.enumerateDevices();
                const listSpeaker = listDevice.filter(item => item.kind == "audioinput" && item.deviceId != "communications" && item.deviceId != "");
           
                this.isHoldSpaceTitle = this.settingsService.getIsAudioMuted() && !this.settingsService.getIsAudioBlocked() && !!(listSpeaker.length > 0);
                var timeout = setTimeout(() => {
                this.isHoldSpaceTitle = false;
                clearTimeout(timeout);
                }, 5000);
                stream.getTracks().forEach(track=> {
                track.stop();
                })
            }).catch((reason) => {});
            
        }
    }
    
    closeFullScreen(): void {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if ((document as any).webkitExitFullscreen) {
            (document as any).webkitExitFullscreen();
        } else if ((document as any).mozCancelFullScreen) {
            (document as any).mozCancelFullScreen();
        } else if ((document as any).msExitFullscreen) {
            (document as any).msExitFullscreen();
        }
    }

    calcVideoBoxSize(width: number, height: number): string {
        const ratioHeightTransform = width * RATIO_HEIGHT * 1.0 / RATIO_WIDTH;
        const ratioWidthTransform = height * RATIO_WIDTH * 1.0 / RATIO_HEIGHT;
        return ratioHeightTransform > height ?
               `${Math.round(ratioWidthTransform * 100 / width)}%` : '100%';
    }

    disableScreen(): void {
        if(this.isScreenMuted == this.participant.screen.state) {
            let stream = this.mediaStreamService.getMediaStream(this.currentSpeaker.id, 'webcam');
            this.updateVideo(stream);
        }
        this.isScreenMuted = !this.currentSpeaker.screen.state;
        this.screenObserver.disconnect();
        this.wrapperHeight = 'unset';
    }

    ngOnDestroy(): void {
        for (const subs of this.subscriptions) {
            subs.unsubscribe();
        }
        this.videoObserver.disconnect();
        if (this.screenObserver) { this.screenObserver.disconnect(); }
    }

    getScoreColor(): string {
        switch (this.score) {
            case 0:
            case 1:
            case 2:
            case 3:
                return 'icon-error';
            case 4:
            case 5:
            case 6:
                return 'icon-orange';
            case 7:
            case 8:
            case 9:
                return 'icon-warning';
            case 10:
                return '';
        }
    }

    getNameAcronym(): string {
        const name = this.currentSpeaker.displayName.toString();
        if(name.lastIndexOf(' ') < 0){
            return name.charAt(0)
        }
        return name.charAt(0) + name.charAt(name.lastIndexOf(' ')+1);
    }
}
