<vnpt-card style="position: relative; height: calc(100vh - 70px);">
    <div vnpt-row [vGutter]="[16, 24]" class="list-video-box">
        <div vnpt-col
             class="gutter-row"
             [vLg]="videoColumnSize"
             vMd="24"
             vXs="24"
             vSm="24">
            <lib-remote-streaming *ngIf="listCurrentRemoteParticipant && listCurrentRemoteParticipant[0]" style="height: 270px"
                                  [participantId]="listCurrentRemoteParticipant[0].id"
            ></lib-remote-streaming>
        </div>
        <div vnpt-col
             class="gutter-row"
             [vLg]="videoColumnSize"
             vMd="24"
             vXs="24"
             vSm="24">
            <lib-remote-streaming *ngIf="listCurrentRemoteParticipant && listCurrentRemoteParticipant[1]" style="height: 270px"
                                  [participantId]="listCurrentRemoteParticipant[1].id"
            ></lib-remote-streaming>
        </div>
        <div vnpt-col
             class="gutter-row"
             [vLg]="videoColumnSize"
             vMd="24"
             vXs="24"
             vSm="24">
            <lib-remote-streaming *ngIf="listCurrentRemoteParticipant && listCurrentRemoteParticipant[2]" style="height: 270px"
                                  [participantId]="listCurrentRemoteParticipant[2].id"
            ></lib-remote-streaming>
        </div>
        <div vnpt-col *ngIf="isEmpty">
            <p vnpt-typography>Không tìm thấy người tham gia!</p>
        </div>
    </div>
    <button class="button-left" vnpt-button vType="secondary" (click)="previousPage()"
            [disabled]="currentPageIndex - 1 <= 0">
        <i vnpt-icon vType="ChevronLeft"></i>
    </button>
    &nbsp;
    <button class="button-right" vnpt-button vType="secondary" (click)="nextPage()"
            [disabled]="currentPageIndex + 1 > getTotalPageIndex()"
    >
        <i vnpt-icon vType="ChevronRight"></i>
    </button>
    <p class="paging-list-participant-video"
       style="font-style: italic"
       [ngClass]="{'d-none': getTotalPageIndex() < 2}"
    >{{currentPageIndex + '/' + getTotalPageIndex()}}</p>

    <!--    <p>currentPageIndex {{currentPageIndex}}</p>-->
    <!--    <p>getTotalPageIndex {{getTotalPageIndex()}}</p>-->
</vnpt-card>
