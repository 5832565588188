import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RoomService, SettingsService } from '../../services';
import { config } from 'projects/realtime-communication/src/config';


@Component({
  selector: 'lib-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.less']
})
export class FeedbackComponent implements OnInit {
  isVisible = false;
  title: string;
  callback: Function | null;
  linkFeedback: string;
  trustURL: any;
  style = {
    top: "56px",
  };
  bodyStyle = {
    height: "calc(100vh - 194px)"
  }
  constructor(
    private sanitizer: DomSanitizer,
    private settingService: SettingsService,
    private roomService: RoomService
  ) {

  }

  async ngOnInit(): Promise<void> {
    const appConfig = await this.settingService.getAppConfig();
    if (appConfig.feedBackLink) {
      this.linkFeedback = appConfig.feedBackLink;
      this.trustURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.linkFeedback);
    }
    this.settingService.onIsFeedbackOpenSubject().subscribe((feedbackObj: {visible: boolean, message: string, callback: Function | null}) => {
      if (!feedbackObj) return;
      this.isVisible = feedbackObj.visible;
      this.title = feedbackObj.message;
      this.callback = feedbackObj.callback;
    });
  }

  handleCancel(): void {
    this.isVisible = false;
    if (this.callback != null) this.callback();
  }


  handleOk(): void {
    this.isVisible = false;
  }



}
