import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RecordService {

    private record: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private time: BehaviorSubject<number> = new BehaviorSubject(0);
    private isRecording: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private isInitial: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private isRecordError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        ) {
    }  

    public setRecord(flag: boolean): void {
        this.record.next(flag);
    }

    public onRecord(): Observable<boolean> {
        return this.record.asObservable();
    }

    public setIsRecording(flag: boolean): void {
        this.isRecording.next(flag);
    } 

    public onIsRecording(): Observable<boolean> {
        return this.isRecording.asObservable();
    }

    public setTime(second: number): void {
        this.time.next(second);
    } 

    public onTime(): Observable<number> {
        return this.time.asObservable();
    }

    public getTime(): number {
        return this.time.value;
    }

    public setIsInitial(flag: boolean): void {
        this.isInitial.next(flag);
    }

    public onIsInitial(): Observable<boolean> {
        return this.isInitial.asObservable();
    }

    public setRecordError(flag: boolean): void {
        this.isRecordError.next(flag);
    }

    public onRecordError(): Observable<boolean> {
        return this.isRecordError.asObservable();
    }
}
