/* tslint:disable:variable-name */
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import blur from '../tfliteBackgroundBlur';
import { AudioDeviceService } from './audio-device.service';
import { config } from '../../config';
import { OneuiI18nService } from '@vnpt/oneui-i18n';
import { NotificationService } from './notification-service';

declare var VirtualVNPTBrowserSDK: any;

@Injectable({
    providedIn: 'root'
})

export class VirtualBackgroundService {
    constructor(
        private audioDeviceService: AudioDeviceService,
        private i18nService: OneuiI18nService,
        private notification: NotificationService,
    ) {}
    public videoElement = null;
    private blur = blur ;

    private _virtualBackground = null;

    private virtualBackgroundOpenSubject = new BehaviorSubject<boolean>(false);

    private renderRequestId;

    get virtualBackground(): any{
        return this._virtualBackground;
    }

    set virtualBackground(data: any){
        this._virtualBackground = data;
    }

    public setVirtualBackgroundOpen(flag: boolean): void {
        this.virtualBackgroundOpenSubject.next(flag);
    }

    public onVirtualBackgroundOpen(): Observable<boolean> {
        return this.virtualBackgroundOpenSubject.asObservable();
    }

    setupBackground(): HTMLImageElement {
        const tmpImg = document.createElement('img');
        tmpImg.src = this.virtualBackground;
        tmpImg.id = 'tmpImg';
        tmpImg.width = 320;
        tmpImg.height = 240;
        tmpImg.crossOrigin = 'anonymous';
        tmpImg.alt = 'background';
        return tmpImg;
    }

    public setupCanvasElement(): HTMLCanvasElement {
        const canvas = document.createElement('canvas');
        return canvas;
    }

    async setupCamera(videoContrains, quality: string, isLocalCamera: boolean, disabeCam: Function): Promise<HTMLVideoElement> {
        this.videoElement = document.createElement('video');

        this.videoElement.id = 'tmpVideo';
        this.videoElement.autoplay = true;
        this.videoElement.muted = true;
        let videoStream;
        if(isLocalCamera){
            if (this.audioDeviceService.getLocalVideo() && this.audioDeviceService.getLocalVideo().deviceId != "default") {
                videoContrains.deviceId = {exact: this.audioDeviceService.getLocalVideo().deviceId};
                videoStream = await navigator.mediaDevices.getUserMedia({
                    video: videoContrains
                });
                videoStream.getVideoTracks()[0].applyConstraints(videoContrains).then(()=>{
                    const { width } = videoStream.getVideoTracks()[0].getSettings();
                        if (videoContrains.width.ideal > width && quality != 'Max Setting') {
                            this.notification.info('video', this.i18nService.translate("service.room.videoQuality", { quality: quality }, "video quality"));
                        }
                }).catch((reason)=> {
                    console.log(reason);
                    this.notification.info('video', this.i18nService.translate("service.room.videoQuality", { quality: quality }, "video quality"));
                });
            }
            else {
                videoStream = await navigator.mediaDevices.getUserMedia({ video: videoContrains });
                videoStream.getVideoTracks()[0].applyConstraints(videoContrains).then(()=>{
                    const { width } = videoStream.getVideoTracks()[0].getSettings();
                        if (videoContrains.width.ideal > width && quality != 'Max Setting') {
                            this.notification.info('video', this.i18nService.translate("service.room.videoQuality", { quality: quality }, "video quality"));
                        }
                }).catch((reason)=> {
                    console.log(reason);
                    this.notification.info('video', this.i18nService.translate("service.room.videoQuality", { quality: quality }, "video quality"));
                });
            }
        } else {
            if (this.audioDeviceService.getVideo() && this.audioDeviceService.getVideo().deviceId != "default") { 
                videoContrains.deviceId = {exact: this.audioDeviceService.getLocalVideo().deviceId};
                videoStream = await navigator.mediaDevices.getUserMedia({
                    video: videoContrains
                });
                videoStream.getVideoTracks()[0].applyConstraints(videoContrains).then(()=>{
                    const { width } = videoStream.getVideoTracks()[0].getSettings();
                        if (videoContrains.width.ideal > width && quality != 'Max Setting') {
                            this.notification.info('video', this.i18nService.translate("service.room.videoQuality", { quality: quality }, "video quality"));
                        }
                }).catch((reason)=> {
                    console.log(reason);
                    this.notification.info('video', this.i18nService.translate("service.room.videoQuality", { quality: quality }, "video quality"));
                });
            }
            else {
                videoStream = await navigator.mediaDevices.getUserMedia({ video: videoContrains });
                videoStream.getVideoTracks()[0].applyConstraints(videoContrains).then(()=>{
                    const { width } = videoStream.getVideoTracks()[0].getSettings();
                        if (videoContrains.width.ideal > width && quality != 'Max Setting') {
                            this.notification.info('video', this.i18nService.translate("service.room.videoQuality", { quality: quality }, "video quality"));
                        }
                }).catch((reason)=> {
                    console.log(reason);
                    this.notification.info('video', this.i18nService.translate("service.room.videoQuality", { quality: quality }, "video quality"));
                });
            }
        }
        videoStream.getTracks()[0].onended = () => {
          disabeCam();
        }
        if (this.videoElement) {
            this.videoElement.srcObject = videoStream;

            return new Promise((resolve, reject) => {
                this.videoElement.onloadedmetadata = () => {
                    this.videoElement.oncanplay = () => {
                        this.videoElement.play()
                    };
                    resolve(this.videoElement);
                };
            });
        }
    }

    // tslint:disable-next-line:ban-types
    public async handleVirtualBackground(videoContrains: any, quality: string, cb: Function, disabeCam: Function): Promise<void> {
            const videoElement = await this.setupCamera(videoContrains, quality, false, disabeCam);

            // const backgroundElement = this.setupBackground();

            const canvasElement = this.setupCanvasElement();

            this.blur.isReady().then((res) => {
                if (res) {
                    this.blur.setBackgroundSource(this.virtualBackground);
                    this.blur.segmentBodyInRealTime(canvasElement, videoElement, async (stream: MediaStream) => {
                        cb(stream);
                    });
                }
        });
    }

    public async handleVirtualBackgroundLocalVideo(videoContrains: any, quality: string, cb: Function, disabeCam: Function): Promise<void> {
        const videoElement = await this.setupCamera(videoContrains, quality, true, disabeCam);

        // const backgroundElement = this.setupBackground();

        const canvasElement = this.setupCanvasElement();
        // this.blur.isReady();

        this.blur.isReady().then((res) => {
            if (res) {
                this.blur.setBackgroundSource(this.virtualBackground);
                this.blur.segmentBodyInRealTime(canvasElement, videoElement, async (stream: MediaStream) => {
                    cb(stream);
                });
            }
        });
    }

    segmentBodyInRealTime(backgroundElement, canvas, videoElement, callback): void {

        const dstCtx = canvas.getContext('2d');

        let imageData = null;

        canvas.height = videoElement.videoHeight;
        canvas.width = videoElement.videoWidth;

        if (callback) {
            const stream = canvas.captureStream(60);
            callback(stream);
        }

        const bodySegmentationFrame = async () => {
            if (videoElement) {
                canvas.width = videoElement.videoWidth;
                canvas.height = videoElement.videoHeight;
                imageData = await VirtualVNPTBrowserSDK.processBackground(videoElement, backgroundElement, true);
                dstCtx.putImageData(imageData, 0, 0);
            }
            // End monitoring code for frames per second
            if(this.renderRequestId) clearTimeout(this.renderRequestId);
            this.renderRequestId = setTimeout(bodySegmentationFrame, 1000 / 30);
        };

        bodySegmentationFrame();
    }


    public stop(): void{
       if (this.videoElement && this.videoElement.srcObject) {
            if(this.videoElement.srcObject.getVideoTracks()){
                for (var sub of this.videoElement.srcObject.getVideoTracks()) {
                    sub.stop();
                  }
            }
        }
        this.virtualBackground = '';
        this.blur.Stop();
    }
}
