import {Injectable} from '@angular/core';
import {ActionRemoteParticipant, RemoteParticipant} from '../../models/remote-participant.model';
import {JoinResponseModel} from '../../models/join-response.model';
import {SocketService, ConsumerService, ShareDataService} from '../../services';
import {BehaviorSubject, Observable} from 'rxjs';
import { SettingsService } from "../settings.service";
import { LocalParticipantService } from "../local-participant.service";
import { DISPLAY_LAYOUT, FORMAT_ACTION_REMOTE_PARTICIPANT, LIST_ROLE_ID, PERMISSIONS } from "../../constants";
import { Participant } from '../../models/participant.model';
import { ExportExcelService } from '../../services/export-excel.service';

export const MAX_ITEM_IN_PAGE = 5;
type BREAKROOM_SUBJECT = 'add' | 'remove';

/*
class Pagination extends Map<number, Set<string>> {
    private currentPage = 1;
    private peerPageMap = {};
    private maxItem = MAX_ITEM_IN_PAGE;

    constructor(maxItem: number){
        super();
        this.maxItem = maxItem;
    }

    // Current Page
    public getCurrentPage(): number {
        return this.currentPage;
    }

    public setCurrentPage(pageIndex: number): void {
        this.currentPage = pageIndex;
    }

    public getMaxItem(): number {
        return this.maxItem;
    }

    public setMaxItem(newMaxItem: number): void {
        this.maxItem = newMaxItem;
        this.refreshTotalPage();
    }

    // Interaction
    public getTotalPage(): number {
        return this.size;
    }

    
    public refreshTotalPage(): void {
        let allPeer = new Set();
        for (let i = 0; i < this.size; i++) {
            this.get(i+1).forEach((Peer: String) =>{
                allPeer.add(Peer);
            })  
        }
        this.clear();
        let newpage = 1;
        this.set(newpage, new Set());
        allPeer.forEach((Peer: string) => {
            if(this.get(newpage).size < this.maxItem){
                this.get(newpage).add(Peer);
                this.peerPageMap[Peer] = newpage;
            }
            else {
                newpage = newpage+1;
                this.set(newpage, new Set());
                this.get(newpage).add(Peer);
                this.peerPageMap[Peer] = newpage;
            }
        });
    }

    private checkExists(peerId: string): boolean {
        for (let [key, value] of this) {
            if (value.has(peerId)) return true;
        }
        return false;
    }

    public addPeer(peerId: string): void {
        if (this.checkExists(peerId)) return;
        let page = this.size || 1;
        const currentPageSet = this.get(page);
        if (!currentPageSet) { this.set(page, new Set()); }
        else if (currentPageSet.size >= this.maxItem) {
            this.set(++page, new Set());
        }

        this.get(page).add(peerId);
        this.peerPageMap[peerId] = page;
    }

    private spliceLastPeer(): string | undefined {
        const lastPageIndex = this.size;
        const lastPage = this.get(lastPageIndex);
        if (lastPage && lastPage.size) {
            const listPeerId = Array.from(lastPage);
            const lastPeerId = listPeerId.pop();
            lastPage.delete(lastPeerId);
            if (!listPeerId.length && lastPageIndex > 1) {
                this.deleteLastPageIfNoPeer(lastPageIndex);
            }
            return lastPeerId;
        }
        if (lastPageIndex > 1) { this.deleteLastPageIfNoPeer(lastPageIndex); }
        return undefined;
    }

    private deleteLastPageIfNoPeer(lastPageIndex: number): void {
        this.delete(lastPageIndex);
        if (this.currentPage === lastPageIndex && lastPageIndex > 1) { --this.currentPage; }
    }

    public removePeer(peerId: string): boolean {
        const peerPage = this.peerPageMap;
        const page = peerPage[peerId];
        if (!this.has(page) || !this.get(page).has(peerId)) { return false; }
        this.get(page).delete(peerId);
        delete peerPage[peerId];
        if (page < this.size) {
            const lastPeer = this.spliceLastPeer();
            if (lastPeer) {
                this.get(page).add(lastPeer);
                peerPage[lastPeer] = page;
            }
        } else if (this.get(page).size === 0) { this.deleteLastPageIfNoPeer(page); }
        return true;
    }

    public getCurrentPeerList(): Set<string> {
        return this.get(this.currentPage);
    }

    public getPeerPage(peerId: string): number {
        return this.peerPageMap[peerId];
    }

    public isCurrentPageContainPeer(peerId: string): boolean {
        return this.peerPageMap[peerId] === this.currentPage;
    }
} */

abstract class Pagination {
    private _maxItem: number;
    private _currentPage: number;
    private _maxPinItem: number;

    constructor() {
        this._maxItem = MAX_ITEM_IN_PAGE
        this._currentPage = 1;
        this._maxPinItem = 6;
    }

    public abstract get totalItem(): number;
    public abstract getPageItems(filters: Array<Function>, page?: number, limit?: number): Array<any>;
    public abstract onPageChange(): void;

    public nextPage(): number {
        if (this._currentPage == this.totalPage) return this._currentPage;
        this._currentPage++;
        return this._currentPage;
    }

    public prevPage(): number {
        if (this._currentPage == 0) return this._currentPage;
        this._currentPage--;
        return this.currentPage;
    }

    public gotoPage(page: number): void {
        if (page > this.totalPage || page < 0) throw new Error("page number is invalid");
        this._currentPage = page;
    }

    public get currentPage(): number {
        if(this._currentPage > this.totalPage) this._currentPage = this.totalPage;
        return this._currentPage;
    }

    // public getCurrentPage(): number {
    //     return this.currentPage;
    // }

    public get totalPage(): number {
        return Math.ceil(this.totalItem / this._maxItem) || 1;
    }

    public set maxItem(value: number) {
        this._maxItem = value;
    }

    public get maxItem(): number {
        return this._maxItem;
    }

    public getMaxItem(): number {
        return this._maxItem;
    }
    public get maxPinItem(): number {
        return this._maxPinItem;
    }
    
    public set maxPinItem(value: number) {
        this._maxPinItem = value;
    }

}

@Injectable({
    providedIn: 'root'
})
export class PoolService extends Pagination {

    private joinData: JoinResponseModel;
    private videoConsumerMap = new Map();
    private peerIdMap: Map<string, RemoteParticipant> = new Map<string, RemoteParticipant>();
    private peerList: Array<RemoteParticipant> = [];
    private _filters: Array<Function> = [];
    private _comparators: Array<Function> = [];
    public onVideoConsumerMaps: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    // private peerIdList: Set<string> = new Set<string>();
    // private peerIdBreakroomPagination: Pagination = new Pagination(MAX_ITEM_IN_PAGE);
    private webcamStartedPool = new Set<string>();
    // private peerPageMap : Set<string> = new Set<string>();

    public nonCamView: BehaviorSubject<boolean> = new BehaviorSubject(false);
    // public nonCamSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public onUpdateMaxItem: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public onUpdateCurrentPage: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public onUpdateListRemoteParticipantInfo: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public onUpdateBreakroom: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public onAddRemoteToBreakroom: BehaviorSubject<RemoteParticipant> = new BehaviorSubject<RemoteParticipant>(null);
    public onRemoveRemoteToBreakRoom: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private updateSearchUser = new BehaviorSubject<boolean>(null);
    private remoteSpeaking: BehaviorSubject<RemoteParticipant> = new BehaviorSubject<RemoteParticipant>(null);
    private displayNameChangeSubject = new BehaviorSubject<{ id, displayName }>(null);
    private pinnedIds: Array<string> = [];
    private _remotesPin: BehaviorSubject<Array<RemoteParticipant>> = new BehaviorSubject<Array<RemoteParticipant>>([]);
    private _updateRemoteParticipantInfo: BehaviorSubject<ActionRemoteParticipant> = new BehaviorSubject<ActionRemoteParticipant>(null);
    private peerWithSort: BehaviorSubject<Array<RemoteParticipant>> = new BehaviorSubject<Array<RemoteParticipant>>([]);
    
    constructor(
        private settingsService: SettingsService,
        private localParticipantService: LocalParticipantService,
        private consumerService: ConsumerService,
        private exportService : ExportExcelService,
        private shareDataService: ShareDataService) {
            super();
    }

    /** Format 
    action: FORMAT_ACTION_REMOTE_PARTICIPANT
    data: any
    example: updateListRemoteParticipantInfo('ADDPEER', peer)
    **/
    private updateRemoteParticipantInfo(action: FORMAT_ACTION_REMOTE_PARTICIPANT, data: any): void {
        this._updateRemoteParticipantInfo.next({ action, data });
    }

    public onUpdateRemoteParticipantInfo() : Observable<any> {
        return this._updateRemoteParticipantInfo.asObservable();
    }

    public setNonCamView(isActive: boolean) {
        if (isActive) this.addFilter(PoolService.filterWebcamOnly);
        else this.removeFilter(PoolService.filterWebcamOnly);
        this.nonCamView.next(isActive);
    }

    public getNonCamViewObserver(): Observable<boolean> {
        return this.nonCamView.asObservable();
    }

    public get totalItem(): number {
        return this.peerList.filter(peer => {
            return PoolService.validatePeer(peer, this._filters)}
        ).length;
    }

    /* public get totalPage(): number {
        if (this.nonCamView.value) {
            const peerList = this.sortByShareScreen(this.getPeers().map((p) => this.peerIdMap.get(p))).filter(peer => peer.webcam.state == true);
            return  Math.ceil(peerList.length / this.maxItem) || 1;
        }
        return Math.ceil(this.peerPageMap.size / this.maxItem) || 1;
    } */

    public onPageChange(): void {
        this.onUpdateCurrentPage.next(true);
    }

    public addFilter(filter: Function): void {
        // console.log("# add filter", filter);
        
        if (this._filters.indexOf(filter) == -1) this._filters.push(filter);
    }

    public removeFilter(filter: Function): void {
        // console.log("# remove filter", filter);
        this._filters = this._filters.filter(f => f != filter);
    }

    public getRoomFilter(): Array<Function> {
        return this._filters;
    }

    public setJoinData(joinData: JoinResponseModel): void {
        this.joinData = joinData;
        // this.initPeers(this.joinData.peers);
    }

    public static filterPinnerOnly(peer: Participant): boolean {
        return peer.isPin;
    }

    public static filterWebcamOnly(peer: Participant): boolean {
        return peer && peer.webcam && peer.webcam.state;
    }

    // check localParticipant is not MODERATOR befor apply this filter
    public static filterPresenterOnly(peer: Participant): boolean {
        return peer.roles.includes(LIST_ROLE_ID.PRESENTER_ID);
    }

    public static filterExclude(ids: Array<string>): Function {
        return (peer: RemoteParticipant): boolean => {
            return !ids.includes(peer.id);
        }
    }

    public static validatePeer(peer: Participant, filters: Array<Function>): boolean {
        return filters.reduce((v, fn) => {
            let r = fn(peer);
            // if (!r) console.log(">> fail", fn.name, peer);
            return (v && r);
        }, true);
    }

    public hasScreenShareComparator(a: Participant, b: Participant): number {
        return a.screen.state ? -1 : (b.screen.state ? 1 : 0);
    }

    public getPageItems(filters = [], page?: number, limit?: number): Array<RemoteParticipant> {
        let viewPage = page ? page : this.currentPage;
        let startIndex = (viewPage - 1) * (limit && limit > 0 ? limit : this.maxItem);

        return this.peerList
            .sort(this.hasScreenShareComparator)
            .filter(p => PoolService.validatePeer(p, filters))
            .slice(startIndex, startIndex + (limit && limit > 0 ? limit : this.maxItem));
    }

    // Init from join data
    private updateProducer(remote: RemoteParticipant): RemoteParticipant {
        const {videoConsumerMap} = this.joinData;
        if (videoConsumerMap.hasOwnProperty(remote.id)) {
            //xem lại
            const {isHaveWebcam, isHaveScreenShare} = videoConsumerMap[remote.id];
            if (isHaveWebcam) {
                remote.webcam.producerId = isHaveWebcam;
                remote.webcam.state = !!isHaveWebcam;
            }

            if (isHaveScreenShare) {
                remote.screen.producerId = isHaveScreenShare;
            }
        }
        return remote;
    }

    public get getRemotesPin(): Array<RemoteParticipant>{
        return this._remotesPin.getValue();
    }

    public onRemotesPin():  Observable<Array<RemoteParticipant>>{
        return this._remotesPin.asObservable();
    }
    public setRemotesPin(value: Array<RemoteParticipant>) {
        this.pinnedIds = value.map(p => p.id);
        this._remotesPin.next(value);
    }

    public getPeerWithSort(): Array<RemoteParticipant>{
        return this.peerWithSort.getValue();
    }

    public onPeerWithSort():  Observable<Array<RemoteParticipant>>{
        return this.peerWithSort.asObservable();
    }
    public setPeerWithSort(value: Array<RemoteParticipant>) {
        this.peerWithSort.next(value);
    }

    public updatePeerPagination(remote: RemoteParticipant, isNew: boolean): void {
        var participant = isNew ? remote : this.updateProducer(remote);
        const localRoles = this.localParticipantService.getRolesSubject();
        if (localRoles.includes(PERMISSIONS.MODERATOR.id)
        || !this.settingsService.getIsWebcamsOnlyForModerator()
        || (this.settingsService.getIsWebcamsOnlyForModerator()
        && participant.roles.includes(PERMISSIONS.PRESENTER.id))) {
            // this.peerPageMap.add(peerID);
            const peerIdPage = this.getPeerPage(participant.id);
            this.updateListRemoteParticipant(peerIdPage, 'add', participant.id);
            // console.log("### addPeer:", peerID, pagination);
        }
    }

    // pageIndex start at 1
    private getPeerPage(peerID: string): number {
        // var peerList = this.getPeers();
        var layout = this.settingsService.getLayoutMode();
        var index = 0;

        if (layout == 'polycom-121' || layout == 'polycom-71') {
            var limit = (layout == 'polycom-121') ? 11 : 6;
            index = this.getDisplayedPeers(1, limit).findIndex((element) => element.id == peerID);
        } else {
            index = this.getDisplayedPeers(1, this.peerList.length).findIndex((element) => element.id == peerID);
        }
        
        return Math.floor(index / this.maxItem) + 1;
    }

    public getPeers(): string[] {
        // return Array.from(this.peerPageMap);
        return this.peerList.map(p => p.id);
    }

    public addPeer(remote: RemoteParticipant, isNew: boolean = true) {
        if (this.peerIdMap.has(remote.id)) return;
        var peer = isNew ? remote : this.updateProducer(remote);
        this.peerIdMap.set(peer.id, peer);
        /* let result = this.peerList.every((v, i) => {
            let r = this._comparators.reduce((a, f) => {
                if (a > 0) return a;
                return f(peer, v);
            }, 0);
            if (r <= 0) {
                this.peerList.splice(i, 0, peer);
                return false;
            }
            return true;
        });
        if (result)  */
        this.peerList.push(peer);
        this.updateListRemoteParticipantInfo();
        this.updatePeerPagination(remote, isNew);
    }

    public deletePeer(id: string) {
        let index = this.peerList.findIndex((i) => i.id == id);
        if (index != -1) {
            this.peerList.splice(index, 1);
        }
        this.peerIdMap.delete(id);
        this.updateListRemoteParticipantInfo();
    }

    public deleteAllPeer() {
        this.peerIdMap.clear();
        this.peerList = [];
        this.pinnedIds = [];
        this.setRemotesPin([]);
        this.updateListRemoteParticipantInfo();
    }

    private hasPeer(peerID: string): boolean {
        // return peerID && this.peerIdList.has(peerID);
        return this.peerIdMap.has(peerID);
    }

    public getPeer(peerID: string): RemoteParticipant {
        return this.peerIdMap.get(peerID);
    }

    public isPeerInCurrentPage(peerID: string): boolean {
        // return this.getPeerPage(peerID) === this.currentPage;
        var layout = this.settingsService.getLayoutMode();
        if (layout == 'polycom-121' || layout == 'polycom-71') {
            var limit = (layout == 'polycom-121') ? 11 : 6;
            return this.getDisplayedPeers(1, limit).findIndex((element) => element.id == peerID) != -1;
        }
        return this.getDisplayedPeers().findIndex((peer: RemoteParticipant) => {
            return peer.id == peerID;
        }) != -1;
    }

    // Pagination interaction
    /* public setCurrentPage(currentPage: number): void {
        this.currentPage = currentPage;
    } */

    public setMaxItem(newMaxItem: number): void {
        this.maxItem = newMaxItem;
        this.onUpdateMaxItem.next(true);
    }

    private updateListRemoteParticipant(page: number, type: BREAKROOM_SUBJECT, peerID: string): void {
        this.updateCurrentPage(page);
        this.updateListRemoteParticipantInfo();
        this.updateBreakroom(type, peerID);
    }

    private updateCurrentPage(page: number): void {
        // if (page === this.currentPage) { this.onUpdateCurrentPage.next(true); }
        this.onUpdateCurrentPage.next(true);
    }

    public updateListRemoteParticipantInfo(): void {
        this.onUpdateListRemoteParticipantInfo.next(true);
    }

    private updateBreakroom(type: BREAKROOM_SUBJECT, peerID: string): void {
        /* if (type === 'add') {
            const remote = this.peerIdMap[peerID];
            this.onAddRemoteToBreakroom.next(remote);
        }
        else { this.onRemoveRemoteToBreakRoom.next(peerID); } */
    }

    public resetCurrentPage(){
        this.onUpdateCurrentPage.next(true);
    }
    // List view
    public getViewPeers(): string[] {
        var peerList = [];
        // var isWebcamsOnlyForModerator = this.settingsService.getIsWebcamsOnlyForModerator();
        // if (!isWebcamsOnlyForModerator) {
        //     peerList = this.sortByShareScreen(this.getPeers().map((p) => this.peerIdMap[p]));
        // } else {
        //     peerList = this.getPeers(); 
        // }

        // let pinList =this._remotesPin.getValue();
        let pinList =[...this._remotesPin.getValue().filter(element => element.id != this.localParticipantService.getPeerId())].reverse();
        // if(pinList.find(element=>element.id == this.localParticipantService.getPeerId())){
        //     pinList.
        //     this.foo_objects = this.foo_objects.filter(obj => return obj !== foo_object);
        // }
        if(pinList && pinList.length != 0) {
            let normalList= this.sortByShareScreen(this.getPeers().map((p) => this.peerIdMap.get(p)));
            normalList = normalList.filter(remote => !pinList.includes(remote));
            peerList = pinList.concat(normalList);
        }
        else{
            peerList  = this.sortByShareScreen(this.getPeers().map((p) => this.peerIdMap.get(p)));
        }
        
        // peerList  = this.sortByPin(peerList);
        if(this.nonCamView.value) {
            peerList = peerList.filter(peer => !!(peer.webcam.state) )
        }
        
        if(peerList.length > 0){
            let peerListSort = [];  
            let index = this.currentPage == 1 ? 0 : (this.currentPage * this.maxItem) - this.maxItem;
            let size = (peerList.length - index - 1 ) < this.maxItem ? (peerList.length - index) : this.maxItem;
            for (let i = 0 ; i < size; i++) {
                // if(!isWebcamsOnlyForModerator) peerListSort.push(peerList[index].id);
                // else peerListSort.push(peerList[index]);
                peerListSort.push(peerList[index].id)
                index++;
            }
            // console.log(peerListSort.length);
            return peerListSort ? [...peerListSort] : [];
        }
        return peerList;
    }

    private getViewFullPeers(): RemoteParticipant[] {
        return [...this.getViewPeers()].map((p) => this.peerIdMap.get(p));
    }

    private getViewPolycom(cameraNumber: number): RemoteParticipant[] {
        
        // let listViewPolycom : RemoteParticipant[] = Object.values(this.peerIdMap);
        let listViewPolycom : RemoteParticipant[] = [...this.getInfoPeers().map((p) => this.peerIdMap.get(p))];
        if(this.nonCamView.value) {
            listViewPolycom = listViewPolycom.filter(peer => !!(peer.webcam.state))
        }

        let pinList =[...this._remotesPin.getValue().filter(element => element.id != this.localParticipantService.getPeerId())].reverse();

        if(pinList && pinList.length != 0) {
            let normalList= this.sortByShareScreen(this.getPeers().map((p) => this.peerIdMap.get(p)));
            normalList = normalList.filter(remote => !pinList.includes(remote));
            listViewPolycom = pinList.concat(normalList);
        }
        listViewPolycom.length = cameraNumber;
        return listViewPolycom;
    }

    public getDisplayedPeers(page?: number, limit?: number): RemoteParticipant[] {
        let viewPage = page ?? this.currentPage;
        let maxItem = limit ? limit : this.maxItem;

        let pinners = [];
        this.pinnedIds.forEach(pinId => {
            if (this.peerIdMap.has(pinId)) pinners.push(this.peerIdMap.get(pinId));
        })
        pinners.reverse();

        if (pinners.length >= maxItem) return pinners.slice(0, maxItem);

        let filters = [
            ...this.getRoomFilter(),
            PoolService.filterExclude(this.pinnedIds)
        ];
        return [
            ...pinners,
            ...this.getPageItems(filters, viewPage, maxItem - pinners.length)
        ];
    }

    // List infornation
    private getInfoPeers(): string[] {
        return Array.from(this.peerIdMap.keys());
    }

    private sortByShareScreen(sortPeer: any): RemoteParticipant[] {
        //sap xep danh sach remote participant theo trang thai share screen
        if (sortPeer) {
            sortPeer.sort((a,b) => {
                if (a['screen']['state'] > b['screen']['state']) {
                    return -1;
                }
                if(a['screen']['state'] < b['screen']['state']) {
                    return 1;
                }
                return 0;
            });
            return [...sortPeer];
        }

    }

    public sortByPin(sortPeer: any): RemoteParticipant[] {
        if (sortPeer) {
            sortPeer.sort((a,b) => {
                if (a.isPin > b.isPin) {
                    return -1;
                }
                if(a.isPin < b.isPin) {
                    return 1;
                }
                return 0;
            })
        }
        return [...sortPeer];
    }

    /* public sort(list: Array<Participant>, ...comparators) {
        list.sort((a, b) => comparators.reduce((r, f) => {
            if (r != 0) return r;
            return f(a, b);
        }, 0));
    } */

    private getInfoFullPeers(): RemoteParticipant[] {
        
        // const infoFullPeers = this.sortByShareScreen(this.getInfoPeers().map((p) => this.peerIdMap[p]));
        return this.peerList; // [...this.getInfoPeers().map((p) => this.peerIdMap.get(p))];
    }

    public getAllPeers(): RemoteParticipant[] {
        return this.peerList; // this.getInfoPeers().map((p) => this.peerIdMap.get(p));
    }

    // List breakroom
    private initBreakroomParticipantPagination(participantIdArr: string[]): void {
        /* for (const p of participantIdArr) {
            if (this.hasPeer(p)) { this.peerIdBreakroomPagination.addPeer(p); }
        } */
    }

    private updateBreakroomParticipant(): void {
        this.onUpdateBreakroom.next(true);
    }

    public initBreakroomParticipant(participantIdArr: string[], isTeacher: boolean): void {
        /* if (this.peerIdBreakroomPagination) { delete this.peerIdBreakroomPagination; }
        let maxItem = MAX_ITEM_IN_PAGE;
        if (isTeacher) { maxItem = MAX_ITEM_IN_PAGE + 1; }
        this.peerIdBreakroomPagination = new Pagination(maxItem);
        this.initBreakroomParticipantPagination(participantIdArr);
        this.updateBreakroomParticipant(); */
    }

    public removeBreakroomParticipant(participantId: string): void {
        /* this.peerIdBreakroomPagination.removePeer(participantId);
        this.onRemoveRemoteToBreakRoom.next(participantId);
        this.updateBreakroomParticipant(); */
    }

    public getListBreakroomParticipant(): string[] {
        // const peerList = this.peerIdBreakroomPagination.getCurrentPeerList();
        // return peerList ? [...peerList] : [];
        return [];
    }

    public getListFullBreakroomParticipant(): RemoteParticipant[] {
        return []; // [...this.getListBreakroomParticipant().map((p) => this.peerIdMap.get(p))];
    }

    public getBreakroomTotalPage(): number {
        return 0; // this.peerIdBreakroomPagination.getTotalPage();
    }

    public getBreakroomCurrentPage(): number {
        return 0; // this.peerIdBreakroomPagination.getCurrentPage();
    }

    public setBreakroomCurrentPage(currentPage: number): void {
        // this.peerIdBreakroomPagination.setCurrentPage(currentPage);
    }
    
    public setVideoConsumerMap(videoConsumerMap: any){
        const objToMap =  new Map(Object.entries(videoConsumerMap));
        for (let [key, value] of objToMap) {
            this.videoConsumerMap.set(key,value);
        }
        this.onVideoConsumerMaps.next(true);
    }

    public addVideoConsumerMap(key: any, value: any){
        this.videoConsumerMap.set(key,value);
        this.onVideoConsumerMaps.next(true);
    }

    public getVideoConsumerMapIsHaveWebcam(): any{
        let isHaveWebcam = new Map();
        for (let [key, value] of this.videoConsumerMap) {
            if(value["isHaveWebcam"]) isHaveWebcam.set(key, value);
        }
        
        // console.log("isHaveWebcam", isHaveWebcam.size, this.videoConsumerMap.size);
        return isHaveWebcam;
    }

    public deleteElementVideoConsumerMap(key:string) {
        if(this.videoConsumerMap.has(key)){
            this.videoConsumerMap.delete(key);
        }
        this.onVideoConsumerMaps.next(true);
    } 

    public setOnVideoConsumerMaps(value: boolean) {
        this.onVideoConsumerMaps.next(value);
    }

    public getLatestDisplayName(remoteId: string): string {  //new
        let remoteParticipant: RemoteParticipant = this.getPeer(remoteId);
        return remoteParticipant ? remoteParticipant.displayName : '';
    }
    public setDisplayNameChange(remoteId: string, newDisplayName: string): void { //Bỏ
        let remote: RemoteParticipant = this.getPeer(remoteId);
        if (!remote) { return; }
        remote.displayName = newDisplayName;
        this.displayNameChangeSubject.next({ id: remoteId, displayName: newDisplayName });
    }

    public onDisplayNameChange(): Observable<{ id, displayName }> {
        return this.displayNameChangeSubject.asObservable();
    }

    public setRemoteRaisedHand(remoteId: string, raisedHand: boolean, timestamp: number): void { 
        let remote: RemoteParticipant = this.getPeer(remoteId);
        if (!remote) { return; }
        remote.setRaisedHand(raisedHand, timestamp);
    }

    public onUpdateSearch() : Observable<boolean>{
        return this.updateSearchUser.asObservable();
    }
    
    public updateSearch():void{
        this.updateSearchUser.next(true);
    }

    public getListParticipantIsShareScreen() : RemoteParticipant[] {
        let listParticipantIsShareScreen = [];
        this.getAllPeers().forEach(participant => {
            if(participant.isSharing) listParticipantIsShareScreen.push(participant) });
        return listParticipantIsShareScreen;
    }

    public updateListParticipantVideo(): RemoteParticipant[] {
        let listParticipantVideo = [];
        this.getAllPeers().forEach(participant => {
            if(participant.webcam.state) listParticipantVideo.push(participant) 
        });
        return listParticipantVideo;
    }

    private onRemoteSpeaking(): Observable<RemoteParticipant> {
        return this.remoteSpeaking.asObservable();
    }

    private setRemoteSpeaking(remote: RemoteParticipant): void {
        this.remoteSpeaking.next(remote);
    }

    // Set is remote sharing
    public setIsRemoteSharing(remoteId: string, isSharing: boolean): void {
        let remote: RemoteParticipant = this.getPeer(remoteId);
        if (!remote) { return; }
        remote.isSharing = isSharing;
    }

    // Set is remote speaking
    public setIsRemoteSpeaking(remoteId: string, isSpeaking: boolean): void {
        let remote: RemoteParticipant = this.getPeer(remoteId);//getRemoteParticipant
        if (isSpeaking) this.setRemoteSpeaking(remote);
        if (remote && remote.isSpeaking != isSpeaking) {
          remote.isSpeaking = isSpeaking;
          remote.setSpeaking(isSpeaking);
        }
    }
    
    public setIsPinNormal(remoteId: string, isPin: boolean): void {
        if (this.pinnedIds.includes(remoteId)) {
            if (!isPin) this.pinnedIds.splice(this.pinnedIds.indexOf(remoteId), 1);
        } else {
            if (isPin) this.pinnedIds.push(remoteId);
        }
        // console.log("@ setIsPinNormal:", remoteId, isPin, this.pinnedIds);
        
        let remote: RemoteParticipant = this.getPeer(remoteId);
        if (!remote) { return; }
        remote.isPin = isPin;
        let remotes = this._remotesPin.getValue();
        let maxPin = this.getMaxItem();
        switch (this.settingsService.getLayoutMode()) {
            case 'polycom-121':
                maxPin = 11;
                break;
            case 'polycom-71':
                maxPin = 6;
                break;
        }
        if(isPin) {
            if(remotes.length == maxPin) {
                let fr = remotes.shift();
                fr.isPin = !isPin;
                this.pinnedIds.shift();
            }
            remotes.push(remote);
        }
        else {
            remotes = remotes.filter(item => item !== remote);
        }
        this._remotesPin.next(remotes);
    }

    public setIsPin(remoteId: string, isPin: boolean, forceLayout: boolean, layoutId: number): void {
        let remote: RemoteParticipant = this.getPeer(remoteId);
        if (!remote) { return; }
        if (this.pinnedIds.includes(remoteId)) {
            if (!isPin) this.pinnedIds.splice(this.pinnedIds.indexOf(remoteId), 1);
        } else {
            if (isPin) this.pinnedIds.push(remoteId);
        }
        remote.isPin = isPin;
        let remotes = this._remotesPin.getValue();

        let layoutInfo = DISPLAY_LAYOUT.find(layout => layout.id == layoutId);
        if(layoutInfo.name == 'gallery' || layoutInfo.name == ''){
            // switch (this.getMaxItem()) {
            //     case 6:
            //         this.setMaxItem(5);
            //         break;
            //     default:
            //         this.setMaxItem(this.maxItem)
            //         break;
            // }
            if(this.getMaxItem() == 6) {
                this.setMaxItem(5);
            }
            else {
                this.setMaxItem(this.maxItem)
            }
        }else{
            this.setMaxItem(layoutInfo.maxItem);
        }
        if (isPin) {
            if ((remotes.length ==  layoutInfo.maxItem && !forceLayout) || (remotes.length == layoutInfo.maxPin && forceLayout)) {
                let fr = remotes.shift();
                this.pinnedIds.shift();
                fr.isPin = !isPin;
                if(fr.id == this.localParticipantService.getLocalParticipant().id) this.localParticipantService.getLocalParticipant().isPin = !isPin;
            }
            let listPeerId = Array.from(remotes.map((element) => element.id));
            if (!listPeerId.includes(remote.id)) {
                remotes.push(remote);
            } else {
                let index = remotes.findIndex((element) => 
                    element.id == remote.id
                );
                remotes[index] = remote;
            }
        }
        else {
            remotes = remotes.filter(item => item.id !== remote.id);
        }
        this._remotesPin.next(remotes);
    }

    public removePin(peerId: string) {
        let index = this.pinnedIds.indexOf(peerId)
        if (index != -1) {
            this.pinnedIds.splice(index, 1);
        }else{
            return;
        }
        let listRemotesPin = this._remotesPin.getValue();
        listRemotesPin.forEach((element, index )=> {
            if(element.id == peerId) listRemotesPin.splice(index, 1);
        });
        this.setRemotesPin(listRemotesPin);
    }

    public changePinWithPagination(): void {
        let remotes = this._remotesPin.getValue();
        let maxPin = this.getMaxItem();
        switch (this.settingsService.getLayoutMode()) {
            case 'polycom-121':
                maxPin = 11;
                break;
            case 'polycom-71':
                maxPin = 6;
                break;
        }
        if(remotes.length > maxPin) {
            let removeremotes = remotes.splice(0, remotes.length - maxPin);
            removeremotes.forEach(remote => {
                remote.isPin = false;
            })
        }
        if(this.pinnedIds.length > maxPin){
            this.pinnedIds.splice(0,this.pinnedIds.length - maxPin );
        }
    }

        // Role interaction
    public addRole(remoteId: string, roleId: number): void {
        let remote: RemoteParticipant = this.getPeer(remoteId);
        if (!remote) { return; }
        if (!remote.roles.includes(roleId)) {
            remote.roles.push(roleId);
        }
        this.updateRemoteParticipantInfo("ADD_ROLE", { peerId: remoteId, roleId: roleId });
    }

    public removeRole(remoteId: string, roleId: number): void {
        let remote: RemoteParticipant = this.getPeer(remoteId);
        if (!remote) { return; }
        remote.roles = remote.roles.filter((role: number) => role !== roleId);
        this.updateRemoteParticipantInfo("REMOVE_ROLE", { peerId: remoteId, roleId: roleId });
    }

        // Update consumer
    public updateRemoteProducer(peerId: string, producerId: string, kind: 'audio' | 'webcam' | 'screen' | 'audioscreen'): RemoteParticipant {
        let remote: RemoteParticipant = this.getPeer(peerId);//getRemoteParticipant(peerId);
        if (!remote) { return null; }
        remote[kind].producerId = producerId;
        remote[kind].state = !!producerId;
        return remote;
    }

    public updateRemoteConsumer(peerId: string, consumerId: string, kind: 'audio' | 'webcam' | 'screen' | 'audioscreen'): RemoteParticipant {
        let remote: RemoteParticipant = this.getPeer(peerId);
        if (!remote) { return null; }
        remote[kind].consumerId = consumerId;
        if (kind !== 'audio') { this.shareDataService.setUpdateStreamingParticipant(remote.id); }
        if (kind === 'webcam') { remote[kind].isViewing = !!consumerId; }
        return remote;
    }

    private isWebcamStartedPoolFullfilled(): boolean {
        const poolCapacity = this.maxItem;
        return this.webcamStartedPool.size === poolCapacity;
    }

    public addWebcamStartedToPool(peerId: string): void {
        this.webcamStartedPool.add(peerId);
    }

    public deleteWebcamStartedFromPool(peerId: string): void {
        if (this.webcamStartedPool.has(peerId)) {
            this.webcamStartedPool.delete(peerId);
        }
    }

    private getWebcamStartedPool(): Set<string> {
        return this.webcamStartedPool;
    }

    public hasWebcamStartedPoolPeerId(peerId: string): boolean {
        return this.webcamStartedPool.has(peerId);
    }

    // Mute locally
    public setLocallyPaused(remoteId: string, kind: 'webcam' | 'audio', status: boolean): void {
        let remote: RemoteParticipant = this.getPeer(remoteId);
        if (!remote) { return; }
        remote.setLocallyPaused(kind, status);
    }

    public exportPeers(){
        const listRemoteParticipant = this.getPeerWithSort().length > 0 ? this.getPeerWithSort() : this.getAllPeers();
        const participant = this.localParticipantService.getLocalParticipant(); 
        
        const exportArray: { orderNumber: number, id: string, displayName: string }[] = [
          { "orderNumber": 1, "id": participant.id, "displayName": participant.displayName}
        ];
        
        const remoteParticipantsWithOrder = listRemoteParticipant.map(({ id, displayName }, index) => ({ orderNumber: index + 2, id, displayName}));
        
        exportArray.push(...remoteParticipantsWithOrder);
        
        this.exportService.exportExcel(exportArray, 'customers');
    }

}
