import {
  Component,
  Input,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef,
  DoCheck,
  Output,
  EventEmitter
} from "@angular/core";
import {
  LocalParticipantService,
  SettingsService,
  RoomService,
  MediasoupService,
  VirtualBackgroundService,
  AudioDeviceService,
  NotificationService
} from '../../../services';
import { Subscription } from 'rxjs';
import { createFadeAnimation } from '../../../animation';
import { VIDEO_FADE_DURATION } from '../../../constants';
import { LocalParticipant } from '../../../models/local-participant.model';
import { VideoDeviceComponent } from "../../video-device/video-device.component";
import { DomSanitizer } from '@angular/platform-browser';
import { OneuiI18nService } from "@vnpt/oneui-i18n";
import { PoolService } from "../../../services/mediasoup/pool.service";
import { LayoutModel } from "../../../models/layout.model";

@Component({
  selector: 'lib-video-setting',
  templateUrl: './video-setting.component.html',
  styleUrls: ['./video-setting.component.less'],
  animations: [createFadeAnimation(VIDEO_FADE_DURATION)]
})
export class VideoSettingComponent implements OnInit, OnDestroy, AfterViewInit, DoCheck {
  followedByWidth = true;
  loading  = true;
  participant: LocalParticipant;
  screenObserver: ResizeObserver;
  wrapperHeight = 'unset';
  private subscriptions: Subscription[] = [];
  score = 10;
  labelVideo = null;
  labelQuality = null;
  selectedbg = null;
  imgSrcList = [];
  backgroundList = [
    'assets/background/Media.PNG'
    ,
    'assets/background/IT.PNG'
    ,
    'assets/background/Net.PNG'
  ]
  extensionLists = ['jpg','png','jpeg'];

  listQuality = [{label:'SD (640x360)', name: 'SD'},
  {label:'HD (1280x720)' ,name: 'HD'},
  {label:'FullHD (1920x1080)', name: 'FullHD'},
  {label: this.i18nService.translate("video-device.byDevice",{},"Theo thiết bị"), name: 'Max Setting'}
  ]

  visibility = {
  listVideo: false,
  listQuality: false
  };

  blo = null ;
  
  temp = null;

  pagination : string;
  pagingCameraDisable = false;
  nonCam: boolean = false;
  timeCheckDeviceHaveExist: any;
  isVisible = true;

  @ViewChild("localVideoRef") localVideoRef: ElementRef;
  @ViewChild(VideoDeviceComponent) child;

  @Output() changeIsLock = new EventEmitter<boolean>();

  constructor(
    private roomService: RoomService,
    private settingsService: SettingsService,
    private localParticipantService: LocalParticipantService,
    private virtualBackgroundService: VirtualBackgroundService,
    public domSanitizer: DomSanitizer,
    private audioDeviceService: AudioDeviceService,
    private notification: NotificationService,
    private i18nService: OneuiI18nService,
    private poolService: PoolService,
    private cd: ChangeDetectorRef
  ) {
    this.participant = this.localParticipantService.getLocalParticipant();
    this.pagination = poolService.getMaxItem().toString();
  }

  url = null;
  videoStream =  null;
  haveStream = true;
  isSaved = false;
  ngOnInit(): void {
    this.roomService.startLocalVideo().then(()=> {
      this.labelVideo = this.audioDeviceService.getVideo();
    });

    this.labelQuality = this.roomService.quality;
    const urlList = localStorage;

    if (this.settingsService.isBrowseSafari()) {
      this.isVisible = false;
    }
    
    this.changeIsLock.emit(false);
    this.selectedbg = this.settingsService.getUrlBackground();
    if (urlList) {
      // const listKey = Object.keys(urlList);
      // const preview = document.querySelector("#preview");
      this.temp = localStorage.getItem("backgroundImg");
      this.url = this.temp;
    }
    
    this.audioDeviceService.onLocalVideoDevice().subscribe((video: any)=> {
      if(video){
        this.labelVideo = video;
        this.handler();
      }
    })

    

    this.roomService.onQuality().subscribe((quality: string) => {
      this.labelQuality = this.listQuality.find(({ name }) => name === quality).label;
      this.handler();
    })

    this.roomService.onForceLayoutObservable().subscribe((layoutInfo: LayoutModel)=>{
      if(layoutInfo.layout != 0 && this.settingsService.getLayoutMode() != 'gallery'){
        this.pagingCameraDisable = true;
      }else{
        this.pagingCameraDisable = false;
      }
      this.pagination = this.poolService.getMaxItem().toString() ;

    });

    this.poolService.getNonCamViewObserver().subscribe((flag: boolean) => {
      this.nonCam = flag;
    })
  }

  ngDoCheck(): void {
    if (this.url != localStorage.getItem("backgroundImg")) {
      this.temp = localStorage.getItem("backgroundImg");
      this.url = this.temp;
      this.selectedbg = this.temp;
    }
  }

  async ngAfterViewInit(): Promise<void> {
    
    const sub2 = this.settingsService.onLocalVideoStream().subscribe(async (localMediaStream: MediaStream): Promise<void>=>{
      
      if (!localMediaStream) {
        this.changeIsLock.emit(true);
        // this.haveStream = false;
        clearTimeout(this.timeCheckDeviceHaveExist);
        this.timeCheckDeviceHaveExist = setTimeout(() => {
          this.haveStream = false;
        }, 2000);
        return; 
      }
      this.videoStream = localMediaStream;
      await this.publishLocalVideo(this.localVideoRef.nativeElement, this.videoStream).then(()=> {
        clearTimeout(this.timeCheckDeviceHaveExist);
        this.haveStream = true;
        this.changeIsLock.emit(true);
        }
      );
      // this.isSaved = false;
    });
    this.subscriptions.push(sub2);
    this.cd.detectChanges();
  }

  async ngOnDestroy(): Promise<void> {
    this.roomService.stopLocalVideo();

    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];

    if (this.screenObserver) { this.screenObserver.disconnect(); }
    // this.localVideoRef.nativeElement.srcObject;
    this.stopVideoWhenChange();
    if(!this.isSaved){
      this.settingsService.setLocalVideoStream(null);
      if(this.audioDeviceService.getVideo() && this.audioDeviceService.getLocalVideo()){
        if(this.audioDeviceService.getVideo().deviceId !== this.audioDeviceService.getLocalVideo().deviceId){
          this.audioDeviceService.changeLocalVideo(this.audioDeviceService.getVideo());
        }
      }
      // console.log(this.settingsService.getUrlBackground());
      this.virtualBackgroundService.stop();
      if(this.settingsService.getUrlBackground() !== null){  
          this.virtualBackgroundService.virtualBackground = this.settingsService.getUrlBackground();
        }
        
          if (this.videoStream !== null) {
            if (this.videoStream.getVideoTracks()) {
              for (var sub of this.videoStream.getVideoTracks()) {                
                sub.stop();
              }
            }
          }
        //     window.dispatchEvent(evt);
        await this.roomService.changeVideoMic();

        return;
    }
    // this.virtualBackgroundService.stop();
    
    this.virtualBackgroundService.stop();
    // window.dispatchEvent(evt);
    this.roomService.changeVideoMicV2();
  }

  stopVideoWhenChange() {
    if( this.localVideoRef.nativeElement.srcObject && this.localVideoRef.nativeElement.srcObject.getVideoTracks()){
      for (var sub of this.localVideoRef.nativeElement.srcObject.getVideoTracks()) {
          sub.stop();
        }
    }
  }

  handler():void{
    try{
      this.settingsService.setUrlBackground(this.selectedbg);
      this.virtualBackgroundService.virtualBackground = this.settingsService.getUrlBackground();
      if(this.audioDeviceService.getLocalVideo()){
        this.audioDeviceService.changeVideo(this.audioDeviceService.getLocalVideo());
      }
      // this.isSaved = true;
      // this.notification.success(this.i18nService.translate('notification.video-setting.virtualBackground'), '');
    }catch(error){
      this.notification.error(this.i18nService.translate('notification.video-setting.error'),'');
      console.error(error);
    }

  }

  async publishLocalVideo(element, videoStream: MediaStream): Promise<void> {
    element.srcObject = videoStream;
    this.loading = false;
  }

  triggerOpenVirtualBackground(): void {
    this.virtualBackgroundService.setVirtualBackgroundOpen(true);
  }

  setBg(bg): void {
    this.changeIsLock.emit(false);
    this.selectedbg = bg;
    if(!this.haveStream) {
      this.changeIsLock.emit(true);
    }
    if (bg == null) {
      this.roomService.stopVirtualBackground();
      this.handler();
      return;
    }
    fetch(bg).then(res => res.blob())
      .then(async (blob) => {
        const f = new File([blob], bg, blob);
        var reader = new FileReader();
        // const url = window.URL.createObjectURL(blob);
        reader.readAsDataURL(f);
        reader.addEventListener('load', () => {
          this.roomService.startVirtualBackground(reader.result);
          this.handler();
      });
      })
  }

   setBgV2(bg): void {
    try {
      this.changeIsLock.emit(false);
      this.loading = true;
      this.selectedbg = bg;
      if(!this.haveStream) {
        this.changeIsLock.emit(true);
      }
      if (bg == null) {
        this.roomService.stopVirtualBackground();
        this.handler();
        return;
      }
      this.stopVideoWhenChange();
      this.roomService.startVirtualBackground(bg);
      this.handler();
    } catch (error) {
      this.haveStream = false;
      this.changeIsLock.emit(true);
    }
   
  }

  async addImgSrc(e: any): Promise<void> {
    const files = e.srcElement.files;
    if (!files.length) { return; }
    if (!this.isValidFileType(files[0].name.toLowerCase())) {
      this.notification.error(this.i18nService.translate('notification.video-setting.errorFormatImageTitle'), 
      this.i18nService.translate('notification.video-setting.errorFormatImageContent')) ;
      return;
    }
    const firstFileIndex = 0;
    const file = files[firstFileIndex];

    const reader = new FileReader();

    reader.addEventListener('load', () => {
      this.roomService.startVirtualBackground(reader.result);
      localStorage.setItem("backgroundImg", "" + reader.result);
      this.selectedbg = reader.result;
      this.handler();
    });
    const blob = new Blob([file], { type: "image/png" });
    reader.readAsDataURL(blob);
  }
    isValidFileType(fileName: any) :boolean{
        return this.extensionLists.indexOf(fileName.split('.').pop()) > -1;
    }

    changePagination(num: string): void {
      this.poolService.setMaxItem(Number(num));
      if(Number(num) > 5){
        this.notification.warning("", this.i18nService.translate("setting.general.numberCamera",{},""));
      }
      this.pagination = num.toString() ;
    }

    nonCamera(): void {
      this.poolService.setNonCamView(!this.nonCam);
  }

    changeIsLockSecondFloor(flag): void {
      this.changeIsLock.emit(flag);
      this.loading = !flag;
      if(!this.haveStream) {
        this.changeIsLock.emit(true);
      }
  }

  pooper(event, component, key): void {
    // if (component)
    this.visibility[key] = !component;
}
}
