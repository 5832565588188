import {Action} from '@ngrx/store';

export const ADD_LOBBY_PEER = 'add lobby peer';
export const REMOVE_LOBBY_PEER = 'remove lobby peer';
export const SET_LOBBY_PEER_DISPLAY_NAME = 'set lobby peer display name';
export const SET_LOBBY_PEER_PICTURE = 'set lobby peer picture';
export const SET_LOBBY_PEER_PROMOTION_IN_PROGRESS = 'set lobby peer promotion in progress';

export class AddLobbyPeer implements Action{
    readonly type = ADD_LOBBY_PEER;
    public payload: any;
    constructor(peerId) {
        this.payload = {peerId};
    }

}
export class RemoveLobbyPeer implements Action{
    readonly type = REMOVE_LOBBY_PEER;
    public payload: any;
    constructor(peerId) {
        this.payload = {peerId};
    }

}
export class SetLobbyPeerDisplayName implements Action{
    readonly type = SET_LOBBY_PEER_DISPLAY_NAME;
    public payload: any;
    constructor(displayName, peerId) {
        this.payload = {displayName, peerId};
    }

}
export class SetLobbyPeerPicture implements Action{
    readonly type = SET_LOBBY_PEER_PICTURE;
    public payload: any;
    constructor(picture, peerId) {
        this.payload = {picture, peerId};
    }

}
export class SetLobbyPeerPromotionInProgress implements Action{
    readonly type = SET_LOBBY_PEER_PROMOTION_IN_PROGRESS;
    public payload: any;
    constructor(peerId, flag) {
        this.payload = {peerId, flag};
    }
}

export type All =
    AddLobbyPeer |
    RemoveLobbyPeer |
    SetLobbyPeerDisplayName |
    SetLobbyPeerPicture |
    SetLobbyPeerPromotionInProgress;
