import { Component, OnInit } from '@angular/core';
import { config } from 'projects/realtime-communication/src/config';
import { InfoModel } from '../../../models/info.model';
@Component({
  selector: 'lib-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.less']
})
export class InformationComponent implements OnInit {
  info : InfoModel = config;
  constructor() {}

  ngOnInit(): void {}

}
