import {config} from "../config";

/**
 * Create a function which will call the callback function
 * after the given amount of milliseconds has passed since
 * the last time the callback function was called.
 */
export const idle = (callback, delay) =>
{
    let handle;

    return () =>
    {
        if (handle)
        {
            clearTimeout(handle);
        }

        handle = setTimeout(callback, delay);
    };
};

/**
 * Error produced when a socket request has a timeout.
 */
export class SocketTimeoutError extends Error
{
    constructor(message)
    {
        super(message);

        this.name = 'SocketTimeoutError';

        if (Error.hasOwnProperty('captureStackTrace')) { // Just in V8.
            Error.captureStackTrace(this, SocketTimeoutError);
        }
        else {
            this.stack = (new Error(message)).stack;
        }
    }
}

export function timeoutCallback(callback): (...args) => any {
    let called = false;
    const interval = setTimeout(
        () => {
            if (called) {
                return;
            }
            called = true;
            callback(new SocketTimeoutError('Request timed out'));
        },
        config.requestTimeout
    );

    return (...args) => {
        if (called) {
            return;
        }
        called = true;
        clearTimeout(interval);

        callback(...args);
    };
}

