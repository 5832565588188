<div class="list-wrapper">
    <div #listView class="paging-box">
        <div #listVideo class="video-list">
            <div class="gutter-row">
                <lib-local-streaming></lib-local-streaming>
            </div>

            <div class="gutter-row"
                *ngIf="listRemoteParticipant && listRemoteParticipant[0]">
                <lib-remote-streaming [participantId]="listRemoteParticipant[0].id">
                </lib-remote-streaming>
            </div>

            <div class="gutter-row"
                *ngIf="listRemoteParticipant && listRemoteParticipant[1]">
                <lib-remote-streaming [participantId]="listRemoteParticipant[1].id">
                </lib-remote-streaming>
            </div>

            <div class="gutter-row"
                *ngIf="listRemoteParticipant && listRemoteParticipant[2]">
                <lib-remote-streaming [participantId]="listRemoteParticipant[2].id">
                </lib-remote-streaming>
            </div>

            <div class="gutter-row"
                *ngIf="listRemoteParticipant && listRemoteParticipant[3]">
                <lib-remote-streaming [participantId]="listRemoteParticipant[3].id">
                </lib-remote-streaming>
            </div>

            <div class="gutter-row"
                *ngIf="listRemoteParticipant && listRemoteParticipant[4]">
                <lib-remote-streaming [participantId]="listRemoteParticipant[4].id">
                </lib-remote-streaming>
            </div>
        </div> 
    </div>

    <button class="paging-button left-button" vnpt-button vType="primary" vShape="circle" (click)="previousPage()"
            [ngClass]="{'d-none': currentPageIndex - 1 <= 0}">
        <i vnpt-icon vType="ChevronLeft"></i>
    </button>

    <button class="paging-button right-button" vnpt-button vType="primary" vShape="circle" (click)="nextPage()"
            [ngClass]="{'d-none': currentPageIndex + 1 > getTotalPageIndex() }">
        <i vnpt-icon vType="ChevronRight"></i>
    </button>

    <p class="paging-count" [ngClass]="{'d-none': getTotalPageIndex() < 2}">{{currentPageIndex + '/' + getTotalPageIndex()}}</p>
</div>

<!-- <vnpt-card style="position: relative">
    <div class="mb-2">
        <lib-local-streaming></lib-local-streaming>
    </div>

    <div class="mb-2">
        <lib-remote-streaming *ngIf="listCurrentRemoteParticipant && listCurrentRemoteParticipant[0]"
                              [participantId]="listCurrentRemoteParticipant[0].id"></lib-remote-streaming>
    </div>
    <div class="mb-2">
        <lib-remote-streaming *ngIf="listCurrentRemoteParticipant && listCurrentRemoteParticipant[1]"
                              [participantId]="listCurrentRemoteParticipant[1].id"></lib-remote-streaming>
    </div>

    <div class="mb-2">
        <lib-remote-streaming *ngIf="listCurrentRemoteParticipant && listCurrentRemoteParticipant[2]"
                              [participantId]="listCurrentRemoteParticipant[2].id"></lib-remote-streaming>
    </div>

    <div class="pagination-box"
         [ngClass]="{'d-none': getTotalPageIndex() < 2}">
        <div vnpt-row vJustify="space-between">
            <div vnpt-col vSpan="6">
                <button vnpt-button vType="secondary" vSize="small" (click)="previousPage()"
                        [disabled]="isSearching || currentPageIndex - 1 <= 0">
                    <i vnpt-icon vType="ChevronLeft"></i>
                </button>
            </div>
            <div vnpt-col vSpan="6">
                <p
                        style="font-style: italic"
                >{{currentPageIndex + '/' + getTotalPageIndex()}}</p>
            </div>
            <div vnpt-col vSpan="6">
                <button vnpt-button vType="secondary" vSize="small" (click)="nextPage()"
                        [disabled]="isSearching || currentPageIndex + 1 > getTotalPageIndex()"
                >
                    <i vnpt-icon vType="ChevronRight"></i>
                </button>
            </div>
        </div>
    </div>
</vnpt-card> -->

