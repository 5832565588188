<div #activatingSpeakerBox class="activating-speaker-box">
    <div #activatingSpeakerVideo [ngClass]= "{'d-none': currentSpeaker.id == '', 'fullscreen': isFullScreen}" class="activating-speaker-video cringi">
        <!-- <img style="height: 20%" src="assets/images/logo-vnpt.png"/> -->
        <div #acronym class="acronym" [style]="{'background': currentSpeaker.color}">
            <svg viewBox="0 0 40 40" width="100%">
                <text x="50%" y="53%" dominant-baseline="middle" text-anchor="middle" fill= "white" stroke= "white">{{nameAcronym}}</text>
            </svg>
        </div>
        <video #activatingSpeakerArea autoplay="true"
            [@fade]="!currentSpeaker.webcam.state && !currentSpeaker.screen.state ? 'disappear': 'appear'">
        </video>
        <div [ngClass]="{ 'd-none': !(isHoldSpaceTitle && isFullScreen) }" class="hold-space">
            <span>{{ "hold_space1" | vI18n:{}:"Bạn đang tắt mic" }}</span>
            <br>
            <span>{{ "hold_space2" | vI18n:{}:"giữ phím cách để nói chuyện" }}</span>
        </div>
        <div *ngIf="currentSpeaker.isRaisedHand" class="raised-hand">
            <a vnpt-button vType="text" disabled>
                <i class="icon-gomeet-raise-hand raised-icon"></i>
            </a>
        </div>
        <div vnpt-row vAlign="middle" class="activating-speaker-info">
            <div vnpt-row vAlign="middle" class="info-wrapper">
                <i vnpt-col [ngClass]="currentSpeaker.audio.state ? 'icon-success': 'icon-error'"
                 [class]="!currentSpeaker.audio.state ? 'icon-gomeet-state-micoff' : 'icon-gomeet-state-mic'"></i>
                <span vnpt-col *ngIf="isLocal" class="currentSpeaker-name">{{('me' | vI18n:{name: currentSpeaker.displayName}:'Tôi')}}</span>
                <span vnpt-col *ngIf="!isLocal" class="currentSpeaker-name">{{currentSpeaker.displayName}}</span>
            </div>

            <div *ngIf="score !== 10" class="info-wrapper">
                <i vnpt-icon vType="BarChart4" [class]="coreColor"></i>
            </div>
        </div>
        <div vnpt-row vAlign="middle" vAlign="middle" class="activating-speaker-info" [ngClass]="{micLocal: true}">
            <i vnpt-col class="micIcon" *ngIf="(micIconVisible && isFullScreen)" [ngClass]="micStatus ? 'icon-gomeet-state-micoff' : 'icon-gomeet-state-mic'"></i>
        </div>
        <button vnpt-button vType="default" (click)="expandVideo()"
            title='{{ "moremenu.fullscreen" | vI18n:{}:"Xem toàn màn hình" }}'
            *ngIf="currentSpeaker.webcam.state || currentSpeaker.screen.state" 
            class="more-btn fullscreen-expand" [ngClass]="{ 'd-none' : isFullScreen }">
                <i vnpt-icon vType="FullView" class="icon-white"></i>
        </button>
    </div>
    <button vnpt-button vType="default"
        vTrigger="click" (click)="closeFullScreen()"
        class="more-btn fullscreen-exit"
        [ngClass]="{ 'd-none' : !isFullScreen }"
        title='{{ "close" | vI18n:{}:"close" }}'>
            <i vnpt-icon vType="Cancel" class="icon-white"></i>
    </button>
</div>

<!-- <vnpt-dropdown-menu #moreMenu>
    <ul vnpt-menu>
      <li *ngIf="currentSpeaker.webcam.state || currentSpeaker.screen.state" vnpt-menu-item (click)="expandVideo()">
        <i vnpt-icon vType="FullView" [vSize]="'sm'"></i>
        <span>{{ "moremenu.fullscreen" | vI18n:{}:"Xem toàn màn hình" }}</span>
      </li>
    </ul>
</vnpt-dropdown-menu> -->
