import {Action} from '@ngrx/store';

export const SET_PEER_VOLUME = 'set peer volume';

export class SetPeerVolume implements Action{
    readonly type = SET_PEER_VOLUME;
    public payload: any;
    constructor(peerId, volume) {
        this.payload = {peerId, volume};
    }
}

export type All = SetPeerVolume;
