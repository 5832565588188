import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {QuizService} from '../../../../services/quiz.service';
import {Examining, QuizModel, ResponsesUserParticipatedModel} from '../../../../models/api/quiz/quiz.model';
import {QuestionModel} from '../../../../models/api/quiz/question.model';
import {CheckResponseModel} from '../../../../models/api/quiz/check.model';
import { SettingsService } from '../../../../services';
// @ts-ignore
import {Cache} from 'commonLibrary';
import {CACHE_CONSTANTS, EXERSICE_QUIZ_STATE} from '../../../../constants';

const INIT_TIME = 1000 * 60 * 60 * 17;
const DEADLINE = INIT_TIME + 1200 * 1000;

@Component({
    selector: 'lib-exercise',
    templateUrl: './exercise.component.html',
    styleUrls: ['./exercise.component.less']
})
export class ExerciseComponent implements OnInit, OnDestroy {

    radioValue = 'A';
    submitLoading = false;
    isShowResult = false;
    isLoading = true;
    subscribeTimer: number;
    deadline = DEADLINE;
    quizName = '';
    quizId = '';
    result = new CheckResponseModel();
    isFinish = false;
    state: EXERSICE_QUIZ_STATE = 'empty';
    differ: any;
    intervalDeadline: any;
    subscriptions: Subscription[] = [];

    questions: QuestionModel[];

    answers: ResponsesUserParticipatedModel[];

    constructor(
        private quizService: QuizService,
        private settingsService: SettingsService
    ) {}

    ngOnInit(): void {
        this.onInitEvent();
    }

    ngOnDestroy(): void {
        for (const subs of this.subscriptions) {
            subs.unsubscribe();
        }
        clearInterval(this.intervalDeadline);
    }

    onInitEvent(): void {
        this.subscriptions.push(this.quizService.onExamining().subscribe((examining: Examining) => {
            if (!examining){
                return;
            }
            this.getQuiz();
        }));

        this.subscriptions.push(this.quizService.onIsFinish().subscribe((isFinish: boolean) => {
            const isFinishCaching = Cache.getCache(CACHE_CONSTANTS.quizFinish) === 'true';
            if (isFinish && !isFinishCaching){
                this.submit();
            }
        }));
    }

    getAnswerHistory(): ResponsesUserParticipatedModel[]{
        const isFinishCache = Cache.getCache(CACHE_CONSTANTS.quizFinish);
        const quizIdHistory = Cache.getCache(CACHE_CONSTANTS.quizId);

        const {quizId} = this.quizService.getExamining();


        if (quizIdHistory === quizId){
            this.isFinish = isFinishCache === 'true';
            if (this.isFinish){
                this.switchToCachingFinish();
                return null;
            }
            const history = JSON.parse(Cache.getCache(CACHE_CONSTANTS.quizAnswer)) as ResponsesUserParticipatedModel[];
            return history;
        }
        Cache.deleteCache(CACHE_CONSTANTS.quizDeadline);
        Cache.addCache(CACHE_CONSTANTS.quizId, quizId);
        Cache.addCache(CACHE_CONSTANTS.quizFinish, 'false');
        this.quizService.setIsFinish(false);
        return null;
    }

    switchToCachingFinish(): void{
        this.state = 'finish';
        clearInterval(this.intervalDeadline);
        const quizResultCache = Cache.getCache(CACHE_CONSTANTS.quizResult);
        if (quizResultCache){
            this.result = JSON.parse(quizResultCache);
            return;
        }
        this.result = new CheckResponseModel();
    }

    initQuestions(): void {
        this.subscriptions.push(this.quizService.getListQuestion().subscribe((questions: QuestionModel[]) => {
            this.questions = questions;
            const answerHistory = this.getAnswerHistory();
            const exitDateCaching = Cache.getCache(CACHE_CONSTANTS.quizExitDate);

            const deltaTime = Date.now() - Number(exitDateCaching || Date.now());
            const deadlineCaching = Cache.getCache(CACHE_CONSTANTS.quizDeadline);
            this.deadline = Number(deadlineCaching || DEADLINE) - deltaTime;

            if (!answerHistory){
                this.initAnswers();
            }else{
                this.answers = answerHistory;
            }
            clearInterval(this.intervalDeadline);
            this.setDeadline();
            this.isLoading = false;
        }));
    }

    initAnswers(): void{
        this.answers = this.questions.map((question: QuestionModel) => ({
            selectedOption: null,
            quesId: question._id,
        }));
    }

    getQuiz(): void{
        this.subscriptions.push(
            this.quizService.getQuiz().subscribe((quiz: QuizModel) => {
                if (!quiz){
                    this.state = 'empty';
                    this.isLoading = false;
                    clearInterval(this.intervalDeadline);
                    return;
                }
                this.state = 'doing';
                this.quizName = quiz.quizName;
                this.initQuestions();
                clearInterval(this.intervalDeadline);
            }));
    }

    submit(): void {
        this.submitLoading = true;
        this.subscriptions.push(this.quizService.check(this.answers).subscribe((result: CheckResponseModel) => {
            if (!result){
                console.log('Không thể nộp bài, vui lòng thử lại sau!');
            }else{
                this.result = result;
                Cache.addCache(CACHE_CONSTANTS.quizFinish, 'true');
                Cache.addCache(CACHE_CONSTANTS.quizResult, JSON.stringify(this.result));
                Cache.addCache(CACHE_CONSTANTS.quizId, result.quizId);
                this.isFinish = true;
                this.state = 'finish';
                Cache.deleteCache(CACHE_CONSTANTS.quizDeadline);
                clearInterval(this.intervalDeadline);
            }
            this.submitLoading = false;
        }));
    }

    handleOk(): void {
        this.isShowResult = false;
    }

    handleCancel(): void {
        this.isShowResult = false;
    }

    saveAnswer(): void {
        const {quizId} = this.quizService.getExamining();
        Cache.addCache(CACHE_CONSTANTS.quizAnswer, JSON.stringify(this.answers));
        Cache.addCache(CACHE_CONSTANTS.quizId, quizId);
    }

    saveDeadline($event: Event): void {
    }

    showDeadline(): any {
        return JSON.stringify(this.deadline);
    }

    setDeadline(): void{
        this.intervalDeadline = setInterval(() => {
            this.deadline -= 1000;
            Cache.addCache(CACHE_CONSTANTS.quizDeadline, this.deadline.toString());
            Cache.addCache(CACHE_CONSTANTS.quizExitDate, Date.now().toString());
            if (this.deadline <= INIT_TIME){
                this.submit();
                clearInterval(this.intervalDeadline);
            }
        }, 1000);
    }

    backToGallery(): void {
        this.settingsService.changeLayoutMode('gallery');
    }
}
