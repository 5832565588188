import { Component, OnDestroy, OnInit, ViewChild, ElementRef, ChangeDetectorRef} from '@angular/core';
import { VirtualBackgroundService, SettingsService } from '../../services';
import { PoolService } from '../../services/mediasoup/pool.service';
import { VTabComponent } from '@vnpt/oneui-ui/tabs';
@Component({
  selector: 'lib-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.less']
})
export class SettingsComponent implements OnInit,OnDestroy {
  isOptionPinned = this.settingsService.getTriggerOptionBar();
  pagination = 0;
  isVisible = false;
  isLock = true;
  index = 0;
  joinBodyStyle = {
    // height: "80vh"
  };
  tabShow = {
    general: true,
    video: false,
    audio: false,
    information: false
  };
  timeout: any;

  constructor(
    private virtualBackgroundService: VirtualBackgroundService,
    private settingsService: SettingsService,
    private poolService: PoolService,
    private cd: ChangeDetectorRef
  ) {
    this.pagination = poolService.maxItem;
  }

  ngOnInit(): void {
    this.settingsService.onGeneralSetting().subscribe((isOpen: boolean) => {
      this.isVisible = isOpen;
      this.index = 0;
    });
    
    this.settingsService.onTriggerOptionBar().subscribe((isOptionPinned: boolean) => {
      this.isOptionPinned = isOptionPinned;
    });

    this.settingsService.onAudioSetting().subscribe((isOpen: boolean) => {
      if(!isOpen) return;
      this.isVisible = isOpen;
      this.index = 2;
      this.showTab('audio');
    });

    this.settingsService.onVideoSetting().subscribe((isOpen: boolean) => {
      if(!isOpen) return;
      this.isVisible = isOpen;
      this.isLock = false;
      this.index = 1;
      this.showTab('video');
    });
  }
  ngOnDestroy(): void {
  }

  changeIsLock(flag) {
    if(!flag) {
    clearTimeout(this.timeout);
    this.isLock = flag;
    }
    else {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(()=> {
        this.isLock = flag;
        clearTimeout(this.timeout);
      }, 2000);
    }
    this.cd.detectChanges();
  }

  triggerOpenVirtualBackground(): void {
    this.virtualBackgroundService.setVirtualBackgroundOpen(true);
  }

  handleCancel(): void {
    this.settingsService.setGeneralSetting(false);
    this.tabShow = {
      general: true,
      video: false,
      audio: false,
      information: false
    }
  }

  showTab(tab): void {
    const keys = Object.keys(this.tabShow);
    keys.forEach(key=> {
      if (key == tab) {
        this.tabShow[key] = true;
      }
      else {
        this. tabShow[key] = false;
      }
    })    
    // console.log(tab + ": " + this.tabShow[tab]);
    
  }

  pinOption(event): void {
    this.isOptionPinned = !this.isOptionPinned;
    this.settingsService.triggerOptionBar(Boolean(this.isOptionPinned));
  }

  changePagination(num: number): void {
    this.poolService.setMaxItem(num);
    this.pagination = num;
  }


}
