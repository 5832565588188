/* tslint:disable:prefer-const no-debugger no-console */
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {BreakroomData, BreakroomDataF5} from '../models/breakroom.model';
import {VNotificationService} from '@vnpt/oneui-ui/notification';
import {MediasoupBaseService} from './mediasoup/mediasoup-base.service';
import {MediasoupService} from './mediasoup.service';
import {LocalParticipantService} from './local-participant.service';
import {DeviceCountingService} from './device-counting.service';
import {ConsumerService} from './consumer.service';
import {SettingsService} from './settings.service';
import {ChattingService} from './chatting.service';
import {ProducerService} from './producer.service';
import {ActiveSpeakerService} from './active-speaker.service';
import {WhiteboardService} from './whiteboard.service';
import {SecurityService} from './security.service';
import {RolesManagerService} from './roles-manager.service';
import {NotificationService} from './notification-service';
import {ReactionsService} from './reactions.service';
import {QuizService} from './quiz.service';
import {SocketService} from './socket.service';
import {RoomService} from './mediasoup/room.service';
import {ShareDataService} from './share-data.service';
import {VirtualBackgroundService} from './virtual-background.service';
import {JoinResponseModel} from '../models/join-response.model';
import {Consumer} from 'mediasoup-client/lib/Consumer';
import {RemoteParticipant} from '../models/remote-participant.model';
import {PoolService} from './mediasoup/pool.service';
import {MediaStreamService} from './mediasoup/media-stream.service';
import { LobbyService } from './lobby.service';
import { ListRequestRoleService } from './list-request-role.service';
import { AudioDeviceService } from './audio-device.service';
import { RecordService } from './record.service';
import { LatencyService } from './latency.service';
import { OneuiI18nService } from '@vnpt/oneui-i18n';
import { SpeakingService } from './speaking.service';

const ALL_GROUP_NAME = 'all';


interface PeerInfo {
    id;
    displayName;
    picture;
    roles;
    returning;
    myGroupId;
}

@Injectable({
    providedIn: 'root'
})
export class BreakRoomService extends MediasoupBaseService {
    constructor(
        private poolServiceInjectable: PoolService,
        private mediaStreamServiceInjectable: MediaStreamService,
        private vNotificationServiceInjectable: VNotificationService,
        private socketServiceInjectable: SocketService,
        private roomServiceInjectable: RoomService,
        private localParticipantServiceInjectable: LocalParticipantService,
        private deviceCountingServiceInjectable: DeviceCountingService,
        private consumerServiceInjectable: ConsumerService,
        private settingsServiceInjectable: SettingsService,
        private chattingServiceInjectable: ChattingService,
        private virtualBackgroundServiceInjectable: VirtualBackgroundService,
        private producerServiceInjectable: ProducerService,
        private activeSpeakerServiceInjectable: ActiveSpeakerService,
        private whiteboardServiceInjectable: WhiteboardService,
        private latencyServiceInjectable: LatencyService,
        private securityServiceInjectable: SecurityService,
        private userRolesServiceInjectable: RolesManagerService,
        private notificationInjectable: NotificationService,
        private reactionsServiceInjectable: ReactionsService,
        private quizServiceInjectable: QuizService,
        private shareDataServiceInjectable: ShareDataService,
        private lobbyServiceInjectable: LobbyService,
        private requestRoleServiceInjectable: ListRequestRoleService,
        private audioDeviceServiceInjectable: AudioDeviceService,
        private recordServiceInjectable: RecordService,
        private mediasoupService: MediasoupService,
        private i18nServiceInjectable: OneuiI18nService,
        private speakingServiceInjectable: SpeakingService
    ) {
        super(
            poolServiceInjectable,
            mediaStreamServiceInjectable,
            vNotificationServiceInjectable,
            socketServiceInjectable,
            roomServiceInjectable,
            localParticipantServiceInjectable,
            deviceCountingServiceInjectable,
            consumerServiceInjectable,
            settingsServiceInjectable,
            chattingServiceInjectable,
            virtualBackgroundServiceInjectable,
            producerServiceInjectable,
            activeSpeakerServiceInjectable,
            whiteboardServiceInjectable,
            latencyServiceInjectable,
            securityServiceInjectable,
            userRolesServiceInjectable,
            notificationInjectable,
            reactionsServiceInjectable,
            quizServiceInjectable,
            shareDataServiceInjectable,
            lobbyServiceInjectable,
            requestRoleServiceInjectable,
            audioDeviceServiceInjectable,
            recordServiceInjectable,
            i18nServiceInjectable,
            speakingServiceInjectable
        );
        this.socketService.onConnected().subscribe((isConnected: boolean) => {
            if (isConnected) { this.onSocket(); }
        });

        this.shareDataServiceInjectable.onCallbackAfterNewPeer().subscribe((peerInfo) => {
            if (!peerInfo || !this.shareDataServiceInjectable.getIsBreakRoom()) { return; }
            this.executeNewPeer(peerInfo);
            // this.getCurrentVideoConsumer();
        });

        this.shareDataServiceInjectable.onCallbackAfterPeerClosed().subscribe((peerId: string) => {
            if (!peerId) { return; }
            this.executePeerClosed(peerId);
        });

        this.shareDataServiceInjectable.onCallbackAfterNewConsumer().subscribe((consumer: Consumer) => {
            if (!consumer) { return; }
            this.executeNewConsumer(consumer);
        });
    }

    private breakRoomDataSubject = new BehaviorSubject<BreakroomData>(null);
    private teacherGRViewSubject = new BehaviorSubject<string>(null);
    private teacherIDSubject = new BehaviorSubject<string>('');
    private breakroomDataF5Subject = new BehaviorSubject<BreakroomDataF5>(null);
    private isTeacherSpeakingAllSubject = new BehaviorSubject<boolean>(true);

    public setInitData(teacherId: string, groupMap: object): void {
        this.setTeacherID(teacherId);
        this.setBreakRoomData(new BreakroomData({
            teacherId,
            groupMap,
            myId: this.localParticipantServiceInjectable.getPeerId()
        }));

    }

    public onSocket(): void {
        this.socketServiceInjectable.onSocket('notification', async (notification: { data: any, method: string }) => {

            if (!notification.data) { return; }

            switch (notification.method) {
                case 'breakRoom': {
                    const {teacherId} = notification.data;
                    this.setTeacherID(teacherId);

                    await this.executeBreakRoomNotificationEvent(new BreakroomData({
                        ...notification.data,
                        myId: this.localParticipantServiceInjectable.getPeerId()
                    }));
                    break;
                }

                case 'stopBreakRoom': {
                    await this.executeStopBreakRoom();
                    break;
                }

                case 'changeTeacherSpeakingInGroup': {
                    const {teacherSpeakingInGroup} = notification.data;
                    this.executeChangeTeacherSpeakingInGroup(teacherSpeakingInGroup);
                    break;
                }
            }
        });
    }

    public setIsTeacherSpeakingAll(status: boolean): void{
        this.isTeacherSpeakingAllSubject.next(status);
    }

    public getIsTeacherSpeakingAll(): boolean{
        return this.isTeacherSpeakingAllSubject.value;
    }

    public onIsTeacherSpeakingAll(): Observable<boolean>{
        return this.isTeacherSpeakingAllSubject.asObservable();
    }

    // 2. SET data breakroom for reload page
    public setBreakRoomData(data: BreakroomData): void {
        data.myId = this.localParticipantServiceInjectable.getPeerId();
        data.prepareData();
        this.breakRoomDataSubject.next(data);
    }

    public getBreakRoomData(): BreakroomData {
        return this.breakRoomDataSubject.value;
    }

    public onBreakRoomData(): Observable<BreakroomData> {
        return this.breakRoomDataSubject.asObservable();
    }

    public setGroupMapBody(data: BreakroomDataF5): void {
        this.breakroomDataF5Subject.next(data);
    }

    public getGroupMapBody(): BreakroomDataF5 {
        return this.breakroomDataF5Subject.value;
    }

    // 4. SET teacher ID
    public setTeacherID(id: string): void {
        this.teacherIDSubject.next(id);
    }


    public onTeacherID(): Observable<string> {
        return this.teacherIDSubject.asObservable();
    }

    public getTeacherId(): string {
        return this.teacherIDSubject.value;
    }

    // Execution Function Request from Mediasoup-base Service
    // 1. Case: 'newPeer'
    public executeNewPeer(peerInfo: PeerInfo): void {
        this.handleNewPeerJoinBreakrooms(peerInfo);
    }

    // 2. Case: 'newVideoProducer'
    async executeNewVideoProducer(peerId: string): Promise<void> {
    }

    // 3. Case: 'breakRoom'
    public async executeBreakRoomNotificationEvent(breakRoomData: BreakroomData): Promise<void> {
        const meData = this.localParticipantService.getLocalParticipant();
        this.setBreakRoomData(breakRoomData);

        this.shareDataServiceInjectable.setIsBreakRoom(true);

        if (meData.id === breakRoomData.teacherId) {
            await this.teacherChangeGroupView('1');
        }
        if (meData.id !== breakRoomData.teacherId) {
            this.handleBreakRoom();
        }
    }

    public stopOldWebcamAndMicConsumer(): void {
        const layoutMode = this.settingsService.getLayoutMode();
        const remoteService = this.poolServiceInjectable;

        if (layoutMode !== 'breakroom'){
            const currentRemotes = remoteService.getAllPeers();//getListRemoteParticipantInfo();
            this.stopWebcamAndMicForListRemote(currentRemotes);
        }else{
            const remotes = remoteService.getListFullBreakroomParticipant();//getListBreakroomParticipant();
            this.stopWebcamAndMicForListRemote(remotes);
        }

    }

    // 4. Case: 'stopBreakRoom'
    public async executeStopBreakRoom(): Promise<void> {
        const remoteService = this.poolServiceInjectable;
        const breakRoomRemotes = remoteService.getListFullBreakroomParticipant();//getListBreakroomParticipant();
        this.stopWebcamAndMicForListRemote(breakRoomRemotes);

        // Set flag is break room
        this.shareDataServiceInjectable.setIsBreakRoom(false);
        this.notification.info(`Đã kết thúc chia nhóm`, '');

        const currentPageRemotes = remoteService.getAllPeers();//getListRemoteParticipantView();
        this.stopWebcamAndMicForListRemote(currentPageRemotes);

        const meId = this.localParticipantServiceInjectable.getPeerId();
        if (meId !== this.getTeacherId()){
            const allRemote = remoteService.getAllPeers();//getListRemoteParticipantInfo();
            const pagingRemote = remoteService.getPageItems();//getListRemoteParticipantView();
            for (const r of allRemote){
                r.startAudio();

            }
            for (const r of pagingRemote) {
                r.startWebcam();
            }
        }

        this.settingsService.changeLayoutMode('gallery');
    }

    // 5. Case: 'peerClosed'
    public executePeerClosed(peerId: string): void {

        const teacherId = this.getTeacherId();
        if (teacherId && teacherId === peerId) {
            this.setTeacherID('');
        }

        let breakRoomData = this.getBreakRoomData();

        if (breakRoomData && breakRoomData.sameGroup(peerId)) {
            this.poolServiceInjectable.removeBreakroomParticipant(peerId);//deleteBreakRoomParticipant(peerId);
        }
    }

    // 6. Case: 'join'
    public executeJoin(joinData: JoinResponseModel): void {
        const breakRoom = this.getBreakRoomData();
        if (breakRoom && Object.keys(breakRoom.groupMap).length) {
            this.handleMeBackJoinBreakroom();
            this.breakRoomDataSubject.next(breakRoom);
        }
    }

    public changeLayoutToBreakRoom(): void {
        this.handleMeBackJoinBreakroom();
    }

    // 7. Case: 'changeTeacherSpeakingInGroup'
    public executeChangeTeacherSpeakingInGroup(teacherSpeakingInGroup: string): void {
        this.handleChangeTeacherSpeakingInGroup(teacherSpeakingInGroup);
    }

    // FUNCTION HABDLE BREAKROOM

    // 1. Handle BREAKROOM
    async handleBreakRoom(): Promise<void> {
        const remoteService = this.poolServiceInjectable;
        // this.stopOldWebcamAndMicConsumer();
        const meID = this.localParticipantService.getPeerId();

        let breakRoomData = this.getBreakRoomData();

        let groupMap = breakRoomData.groupMap;
        let isMeHasNoGroup = false;
        let listNoGroup: any;
        if (groupMap.noGroup) {
            listNoGroup = groupMap.noGroup;
            isMeHasNoGroup = listNoGroup.includes(meID);
        }

        const myGroupId = isMeHasNoGroup ? 'noGroup' : breakRoomData.myGroupId;

        const myGroupMembers = groupMap[myGroupId] ? groupMap[myGroupId] : [];

        remoteService.initBreakroomParticipant(myGroupMembers, false); //setBreakroomParticipant

        const teacherId = this.getTeacherId();
        const teacher = remoteService.getPeer(teacherId);
        const layoutRemoteList = remoteService.getAllPeers().filter(r => r !== teacher);// getListRemoteParticipantInfo
        this.stopWebcamAndMicForListRemote(layoutRemoteList);

        const remotes = remoteService.getListFullBreakroomParticipant();//getListBreakroomParticipant();
        this.startWebcamAndMicForListRemote(remotes);
        teacher.startWebcam();

        this.settingsServiceInjectable.changeLayoutMode('breakroom');

    }

    // 2. Change group view for teacher
    async teacherChangeGroupView(groupView): Promise<void> {
        this.stopOldWebcamAndMicConsumer();
        const breakRoom = this.getBreakRoomData();
        breakRoom.myGroupId = groupView;
        const groupMap = breakRoom.groupMap;
        const myGroupMembers = groupMap[groupView] ? groupMap[groupView] : [];
        this.poolServiceInjectable.initBreakroomParticipant(myGroupMembers, true);


        const remotes = this.poolServiceInjectable.getListFullBreakroomParticipant();//getListBreakroomParticipant();
        this.startWebcamAndMicForListRemote(remotes);
        this.settingsServiceInjectable.changeLayoutMode('breakroom');
        this.teacherGRViewSubject.next(groupView);
    }

    onTeacherChangeGroupView(): Observable<string> {
        return this.teacherGRViewSubject.asObservable();
    }

    // 3. Handle when local (me) reload and join back breakroom
    handleMeBackJoinBreakroom(): void {
        // get Data for breakroom
        this.shareDataServiceInjectable.setIsBreakRoom(true);
        const remoteService = this.poolServiceInjectable;
        const meID = this.localParticipantService.getPeerId();
        let breakRoom = this.getBreakRoomData();

        if (!breakRoom) {
            return;
        }

        const teacherId = breakRoom.teacherId;
        const groupMap = breakRoom.groupMap;

        this.setTeacherID(teacherId);

        if (groupMap) {
            let myGroupMembers = [];
            myGroupMembers = groupMap[breakRoom.myGroupId] ? groupMap[breakRoom.myGroupId] : [];

            if (teacherId && meID === teacherId) {
                myGroupMembers = groupMap['1'];
            }

            remoteService.initBreakroomParticipant(myGroupMembers, breakRoom.peerIsTeacher(meID));

            const remotes = remoteService.getListFullBreakroomParticipant();//getListBreakroomParticipant();
            this.startWebcamAndMicForListRemote(remotes);
        }
    }

    // 4. Handle when newPeer join breakroom
    async handleNewPeerJoinBreakrooms(peerInfo: PeerInfo): Promise<void> {
        let breakRoom = this.getBreakRoomData();
        if ( !breakRoom || !breakRoom.groupMap){ return; }

        const remoteService = this.poolServiceInjectable;
        let localPeerID = this.localParticipantServiceInjectable.getPeerId();
        let membersGroup = [];

        breakRoom.addPeerToGroup(peerInfo.id, peerInfo.myGroupId);

        if (breakRoom.peerIsTeacher(localPeerID)) {
            if (breakRoom.sameGroup(peerInfo.id)) {
                membersGroup = breakRoom.groupMap[breakRoom.myGroupId];
            }
        } else {

            if (breakRoom.peerIsTeacher(peerInfo.id)) {
                this.setTeacherID(peerInfo.id);
            } else {
                if (breakRoom.sameGroup(peerInfo.id)) {
                    membersGroup = breakRoom.groupMap[breakRoom.myGroupId];
                }
            }
        }

        if (membersGroup.length > 0 && breakRoom.sameGroup(peerInfo.id)) {
            membersGroup = [...new Set(membersGroup)];
            membersGroup = membersGroup.filter((id: string) => id !== this.getTeacherId());

            remoteService.initBreakroomParticipant(membersGroup, breakRoom.peerIsTeacher(localPeerID));

            // let tempListBreakroomParticipant = remoteService.getListFullBreakroomParticipant();//getListBreakroomParticipant();
            // let tempListBreakroomParticipant = remoteService.getListFullBreakroomParticipant().filter((p) => {
            //     return p.id !== localPeerID;
            // });
        }

    }

    // 5. Handle when Teacher change group to view
    private stopWebcamAndMicForListRemote(remotes: RemoteParticipant[]): void{
        for (const r of remotes){
            if (r.webcam.consumerId) { r.stopWebcam(); }
            if (r.audio.consumerId) { r.stopAudio(); }
        }
    }

    private startWebcamAndMicForListRemote(remotes: RemoteParticipant[]): void{
        for (const r of remotes){
            r.startWebcam();
            r.startAudio();
        }

    }

    // 6. Handle when participant out meeting
    async handleOutBreakroom(peerId: string): Promise<void> {
        let breakRoomData = this.getGroupMapBody();
        let teacherId = breakRoomData.teacherId;
        let groupMap = breakRoomData.groupMap;

        let dataSendRQBreakroom = {
            roomId: this.roomService.getRoomId(),
            teacherId,
            groupMap
        };

        await this.doSubmitBreakRoom(dataSendRQBreakroom);
    }

    // 7. Check newPeer Has Grouped
    checkNewPeerHasGroup(newPeerID: string, groupMap: any): any {
        if (groupMap) {
            let listOfMember = [];
            for (const [roomMembers] of Object.entries(groupMap) as any) {
                listOfMember = listOfMember.concat(roomMembers);
            }
            return listOfMember.includes(newPeerID);
        }
    }

    // 8. doSubmitBreakroom
    async doSubmitBreakRoom(groupMap: any): Promise<void> {

        try {
            await this.socketService.sendRequest('doSubmitBreakRoom', {groupMap});
        } catch (error) {
            this.notification.error(`Không thể tạo BreakRoom`, '');
        }
    }

    // 9. doStopBreakRoom
    async doStopBreakRoom(): Promise<void> {
        this.shareDataServiceInjectable.setIsBreakRoom(false);
        try {
            await this.socketService.sendRequest('doStopBreakRoom', {});
        } catch (error) {
            this.notification.error(`Không thể kết thúc BreakRoom`, '');
        }
    }

    // 10. doChangeTeacherSpeakingInGroup
    async doChangeTeacherSpeakingInGroup(groupName: string): Promise<void> {
        try {
            const speakingGroupName = this.getIsTeacherSpeakingAll() ? ALL_GROUP_NAME : groupName;
            await this.socketService.sendRequest('doChangeTeacherSpeakingInGroup', {teacherSpeakingInGroup: speakingGroupName});
        } catch (error) {
            this.notification.error(`Không thể gửi thay đổi teacher Speaking`, '');
        }
    }

    // 11. handleChangeTeacherSpeakingInGroup
    handleChangeTeacherSpeakingInGroup(groupID: string): void {
        const meID = this.localParticipantService.getPeerId();
        const dataBreakroom = this.getBreakRoomData();
        const teacherId = dataBreakroom.teacherId;

        // todo close audio of teacher
        if (teacherId && teacherId === meID) { return; }

        const teacher = this.poolServiceInjectable.getPeer(teacherId);
        const teacherMicConsumer = teacher.audio.consumerId;
        if ( teacherMicConsumer ){
            if ( groupID !== 'all' && groupID !== dataBreakroom.myGroupId) {
                this.mediasoupService.emitServerCloseConsumer(teacherMicConsumer);
            }
        } else {
            if (groupID === 'all' || groupID === dataBreakroom.myGroupId) {
                this.createNewAudioConsumer(teacher.id);
            }
        }

    }

    private async executeNewConsumer(consumer: Consumer): Promise<void> {
        const breakroom = this.getBreakRoomData();

        const meId = this.localParticipantService.getPeerId();

        if (meId === consumer.appData.peerId && consumer.appData.source === 'mic' ){
            await this.mediasoupService.emitServerCloseConsumer(consumer.id);
            return;
        }

        if (!this.shareDataServiceInjectable.getIsBreakRoom()) { return; }

        const peerId = consumer.appData?.peerId;

        const { teacherId, myGroupId } = breakroom;

        if (peerId && peerId !== teacherId && breakroom.getGroupOfPeer(peerId.toString()) !== myGroupId) {
            await this.mediasoupService.emitServerCloseConsumer(consumer.id);
        }
    }
}
