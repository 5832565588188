import {Action} from '@ngrx/store';

export const ADD_CONSUMER = 'add consumer';
export const REMOVE_CONSUMER = 'remove consumer';
export const CLEAR_CONSUMERS = 'clear consumers';
export const SET_CONSUMER_PAUSED = 'set consumer paused';
export const SET_CONSUMER_RESUMED = 'set consumer resumed';
export const SET_CONSUMER_CURRENT_LAYERS = 'set consumer current layers';
export const SET_CONSUMER_PREFERRED_LAYERS = 'set consumer preferred layers';
export const SET_CONSUMER_PRIORITY = 'set consumer priority';
export const SET_CONSUMER_TRACK = 'set consumer track';
export const SET_CONSUMER_SCORE = 'set consumer score';
export const SET_CONSUMER_AUDIO_GAIN = 'set consumer audio gain';
export const SET_CONSUMER_OPUS_CONFIG = 'set consumer opus config';

export class AddConsumer implements Action{
    readonly type = ADD_CONSUMER;
    public payload: any;
    constructor(consumer, peerId) {
        this.payload = {consumer, peerId};
    }

}

export class RemoveConsumer implements Action{
    readonly type = REMOVE_CONSUMER;
    public payload: any;
    constructor(consumerId, peerId) {
        this.payload = {consumerId, peerId};
    }

}

export class ClearConsumers implements Action{
    readonly type = CLEAR_CONSUMERS;
    public payload: any;
    constructor() {}

}

export class SetConsumerPaused implements Action{
    readonly type = SET_CONSUMER_PAUSED;
    public payload: any;
    constructor(consumerId, originator) {
        this.payload = {consumerId, originator};
    }

}

export class SetConsumerResumed implements Action{
    readonly type = SET_CONSUMER_RESUMED;
    public payload: any;
    constructor(consumerId, originator) {
        this.payload = {consumerId, originator};
    }

}

export class SetConsumerCurrentLayers implements Action{
    readonly type = SET_CONSUMER_CURRENT_LAYERS;
    public payload: any;
    constructor(consumerId, spatialLayer, temporalLayer) {
        this.payload = {consumerId, spatialLayer, temporalLayer};
    }

}

export class SetConsumerPreferredLayers implements Action{
    readonly type = SET_CONSUMER_PREFERRED_LAYERS;
    public payload: any;
    constructor(consumerId, spatialLayer, temporalLayer) {
        this.payload = {consumerId, spatialLayer, temporalLayer};
    }

}

export class SetConsumerPriority implements Action{
    readonly type = SET_CONSUMER_PRIORITY;
    public payload: any;
    constructor(consumerId, priority) {
        this.payload = {consumerId, priority};
    }

}

export class SetConsumerTrack implements Action{
    readonly type = SET_CONSUMER_TRACK;
    public payload: any;
    constructor(consumerId, track) {
        this.payload = {consumerId, track};
    }

}

export class SetConsumerScore implements Action{
    readonly type = SET_CONSUMER_SCORE;
    public payload: any;
    constructor(consumerId, score) {
        this.payload = {consumerId, score};
    }

}

export class SetConsumerAudioGain implements Action{
    readonly type = SET_CONSUMER_AUDIO_GAIN;
    public payload: any;
    constructor(consumerId, audioGain) {
        this.payload = {consumerId, audioGain};
    }

}

export class SetConsumerOpusConfig implements Action{
    readonly type = SET_CONSUMER_OPUS_CONFIG;
    public payload: any;
    constructor(consumerId, opusConfig) {
        this.payload = {consumerId, opusConfig};
    }
}

export type All =
    AddConsumer |
    RemoveConsumer |
    ClearConsumers |
    SetConsumerPaused |
    SetConsumerResumed |
    SetConsumerCurrentLayers |
    SetConsumerPreferredLayers |
    SetConsumerPriority |
    SetConsumerTrack |
    SetConsumerScore |
    SetConsumerAudioGain |
    SetConsumerOpusConfig;
