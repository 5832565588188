<div>
    <!-- <vnpt-divider class="divider" style="margin-bottom: 8px;" vText="Tính năng mở rộng" vOrientation="center"></vnpt-divider>

    <div class="list-item" (click)="triggerOpenVirtualBackground()">
        <i vnpt-icon vType="Photo2" [vSize]="'sm'"></i>
        <span>Phông nền ảo</span>
    </div>

    <vnpt-divider class="divider" vText="Cài đặt" vOrientation="center"></vnpt-divider> -->
    <div vnpt-row vAlign="middle" vJustify="space-between" class="switch">
        <span vI18n="settings.general.language">Ngôn ngữ</span>
        <vnpt-select class="select-button" [vDropdownStyle]="{fontSize: '12px'}" [(ngModel)]="locale" [vOptions]="languages" (ngModelChange)="changeLang(locale)"></vnpt-select>
    </div>
    <div vnpt-row vAlign="middle" vJustify="space-between" class="switch">
        <span vI18n="settings.general.pin_optionbar">Ghim thanh tuỳ chọn:</span>
        <label class="switch2">
            <input type="checkbox" [checked]="isOptionPinned" (change) = "pinOption($event)">
            <span class="slider round"></span>
        </label>
    </div>
    <div vnpt-row vAlign="middle" vJustify="space-between" class="switch">
        <span vI18n="settings.general.noti_count">Số lượng thông báo:</span>
        <vnpt-select class="select-button" vDropdownClassName="select-button" [(ngModel)]="notiCount" (ngModelChange)="changeNotiCount(notiCount)">
            <vnpt-option class="select-button" vValue="0" vLabel="{{ 'settings.general.noti_off' | vI18n: {}:'Tắt' }}" ></vnpt-option>
            <vnpt-option class="select-button" vValue="1" vLabel="1"></vnpt-option>
            <vnpt-option class="select-button" vValue="2" vLabel="2"></vnpt-option>
            <vnpt-option class="select-button" vValue="3" vLabel="3"></vnpt-option>
            <vnpt-option class="select-button"vValue="4" vLabel="4"></vnpt-option>
            <vnpt-option class="select-button" vValue="5" vLabel="5"></vnpt-option>
            <vnpt-option class="select-button" vValue="6" vLabel="6"></vnpt-option>
        </vnpt-select>
    </div>
</div>
