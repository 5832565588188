/* tslint:disable:variable-name */
import {Component, Input, Output, OnInit, ChangeDetectorRef, EventEmitter} from '@angular/core';
import {
    MediasoupService,
    RolesManagerService,
    SettingsService,
    ChattingService,
    RoomService,
    RightPaneViewType,
    ActiveSpeakerService
} from 'projects/realtime-communication/src/projects';
import {ActionRemoteParticipant, RemoteParticipant} from '../../../models/remote-participant.model';
import {permissions} from 'projects/realtime-communication/src/lib/permissions';
import { UserRoleModel } from '../../../models/user-role.model';
import { DISPLAY_LAYOUT, PERMISSIONS } from '../../../constants';
import { PoolService } from '../../../services/mediasoup/pool.service';
import { Subscription } from 'rxjs';
import { SpeakerModel } from '../../../models/speaker.model';
import { SpeakingService } from '../../../services/speaking.service';
import { VModalService } from '@vnpt/oneui-ui/modal';
import { OneuiI18nService } from '@vnpt/oneui-i18n';

@Component({
    selector: 'lib-remote-participant-detail',
    templateUrl: './remote-participant-detail.component.html',
    styleUrls: ['./remote-participant-detail.component.less']
})
export class RemoteParticipantDetailComponent  implements OnInit {
    _participantId = '';
    participant: RemoteParticipant;
    isSpeaking: boolean = false;
    participantIsModerator = false;
    participantIsAdmin = false;
    showUp: boolean = false;
    private readonly changeDetector: ChangeDetectorRef;

    isBlockAllWebcam: boolean = false;
    isBlockAllAudio = false;
    isJoinedAudio = false;
    moreMenuVisible: any;
    subscriptions: Subscription[] = [];
    micStatus: string;
    camStatus: string;
    hasBlockVideo = false;
    hasBlockAudio = false;
    isWebcamsOnlyForModerator = false;
    @Input() forceLayoutStatus =  false;
    // @Input() set participantId(id: string) {
    //     if (id) {
    //         this._participantId = id;
    //         this.updateParticipant(this._participantId);
    //     }
    // }
    @Input() set remote(remote : RemoteParticipant){
            if (remote) {
            this._participantId = remote.id;
            this.participant = remote;
            this.updateParticipant(this._participantId);
        }
    };
    get participantId(): string{
        return this._participantId;
    }

    @Input() hasPermissionModerator = false;
    @Input() hasPermissionAdmin = false;
    @Input() numberOrder = -1;
    // @Output() triggerRightContainer = new EventEmitter();
    // @Output() onShowContainerRight = new EventEmitter();

    constructor(
        private rolesManagerService: RolesManagerService,
        private mediasoupService: MediasoupService,
        private settingsService: SettingsService,
        private chattingService: ChattingService,
        private roomService: RoomService,
        private activeSpeakerService: ActiveSpeakerService,
        private poolService : PoolService,
        private speakingService: SpeakingService,
        private modalService: VModalService,
        private i18nService:OneuiI18nService
    ) {
        // this.participant = this.poolService.getPeer(this.participantId);
    }

    ngOnInit(): void {
        this.onInitEvent();
        // this.checkRoleBreakroom();
        //Chuyển check các trạng thái này xuống func updateParticipant
        // this.participantIsModerator = this.participant.roles.includes(PERMISSIONS.MODERATOR.id);
        // this.participantIsAdmin =  this.participant.roles.includes(PERMISSIONS.ADMIN.id);
        // this.hasBlockVideo =  !this.participant.roles.includes(PERMISSIONS.SHARE_VIDEO.id);
        // this.hasBlockAudio = !this.participant.roles.includes(PERMISSIONS.SHARE_AUDIO.id);
    }

    ngOnDestroy(): void {
        for (const subs of this.subscriptions) {
            subs.unsubscribe();
        }
    }

    onInitEvent(): void {
        this.subscriptions.push(this.speakingService.activeSpeakerObserver.subscribe((speakingList: SpeakerModel[]) => {
            this.isSpeaking = speakingList.find(item => item.id == this.participant.id) != undefined;
        }));

        this.subscriptions.push(this.poolService.onUpdateSearch().subscribe(flag =>{
            this.participantIsModerator = this.participant.roles.includes(PERMISSIONS.MODERATOR.id);
        }));

        this.settingsService.onVideoBlocked().subscribe((isBlockAllVideo: boolean) => {
            this.isBlockAllWebcam = isBlockAllVideo;
        });

        this.settingsService.onAudioBlocked().subscribe((isBlockAllAudio: boolean) => {
            this.isBlockAllAudio = isBlockAllAudio;
        });

        this.subscriptions.push(this.settingsService.onJoinAudio().subscribe((isJoined: boolean) => {
            this.isJoinedAudio = isJoined;
        }));

        //Ktra trang thai WebcamsOnlyForModerator. True: disable button pin
        this.subscriptions.push(this.settingsService.onWebcamsOnlyForModerator().subscribe((flag: boolean) =>{
            this.isWebcamsOnlyForModerator = flag;
        }));

        this.subscriptions.push(this.poolService.onUpdateRemoteParticipantInfo().subscribe((info: ActionRemoteParticipant) => {
            if(this._participantId == info?.data.peerId &&(info?.action == 'ADD_ROLE'  || info?.action == 'REMOVE_ROLE') ){
                // console.log("onUpdateRemoteParticipantInfo");
                this.hasBlockVideo =  !this.participant.roles.includes(PERMISSIONS.SHARE_VIDEO.id);
                this.hasBlockAudio = !this.participant.roles.includes(PERMISSIONS.SHARE_AUDIO.id);
            }
        }));

    }

    updateParticipant(id: string): void {
        // this.participant = this.poolService.getPeer(this._participantId);
        //check lai cac trang thai 
        this.participantIsModerator = this.participant.roles.includes(PERMISSIONS.MODERATOR.id);
        this.participantIsAdmin =  this.participant.roles.includes(PERMISSIONS.ADMIN.id);
        this.hasBlockVideo =  !this.participant.roles.includes(PERMISSIONS.SHARE_VIDEO.id);
        this.hasBlockAudio = !this.participant.roles.includes(PERMISSIONS.SHARE_AUDIO.id);
        // this.micStatus = this.getAudioIcon();
        // this.camStatus = this.getVideoIcon();
    }

    // hasBlockAudio(): boolean{
    //     this.logger.log("hasBlockAudio");
    //     console.log("AAAA: ",this.participant.displayName, this.roomService.hasPermission(permissions.MODERATE_ROOM) && !this.mediasoupService.remoteHasRole(this._participantId, "share audio") );
        
    //     return !this.participant.roles.includes(PERMISSIONS.SHARE_AUDIO.id);
    // }

    // hasBlockVideo(): boolean{
    //     this.logger.log("hasBlockVideo");
    //     return  !this.participant.roles.includes(PERMISSIONS.SHARE_VIDEO.id);
    // }

    kickRemoteParticipant(): void {
        this.modalService.confirm({
            vTitle: this.i18nService.translate("list-participant.kickTitle",{name: this.participant.displayName},"Xóa khỏi cuộc họp"),
            vContent: this.i18nService.translate("list-participant.kickContent",{name: this.participant.displayName},"Bạn có thực sự muốn đóng cuộc họp không?"),
            vOkText: this.i18nService.translate("ok",{},"Đồng ý"),
            vCancelText: this.i18nService.translate("cancel",{},"Hủy"),
            vOnOk: ()=> this.mediasoupService.kickRemoteParticipant(this.participant.id),
            vOkDanger: true,
          });
          
        //   this.modalService.create({
        //     vTitle: this.i18nService.translate("list-participant.kickTitle",{name: this.participant.displayName},"Xóa khỏi cuộc họp"),
        //     vContent: this.i18nService.translate("list-participant.kickContent",{name: this.participant.displayName},"Bạn có thực sự muốn đóng cuộc họp không?"),
        //     vFooter: [
        //       {
        //         label: this.i18nService.translate("cancel",{},"Hủy"),
        //         type: 'primary',
        //         onClick: () => this.modalService.closeAll()
        //       },
        //       {
        //         label: this.i18nService.translate("ok",{},"Đồng ý"),
        //         type: 'primary',
        //         danger: true,
        //         loading: false,
        //         onClick: () => this.mediasoupService.kickRemoteParticipant(this.participant.id)
        //       },
        //     ]
        //   });
        
    }

    muteRemoteParticipant(): void {
        this.mediasoupService.muteRemoteParticipant(this.participant.id);
    }

    stopRemoteParticipantVideo(): void {
        this.mediasoupService.stopRemoteParticipantVideo(this.participant.id);
    }

    stopRemoteParticipantScreenSharing(): void {
        this.mediasoupService.stopRemoteParticipantScreenSharing(this.participant.id);
    }

    openRolesManager(): void {
        this.rolesManagerService.setRoleManagerOpen(true);
        this.rolesManagerService.setRemoteParticipant(this.participant);
    }

    // toggleRemoteVideo(): void {
    //     if (!this.participant.webcam.consumerId) { return; }
    //     const status = this.participant.getLocallyPaused('webcam');
    //     this.mediasoupService.modifyPeerConsumer(this.participant.id, 'webcam', !status);
    // }

    // toggleRemoteAudio(): void {
    //     if (!this.participant.audio.consumerId) { return; }
    //     const status = this.participant.getLocallyPaused('audio');
    //     this.mediasoupService.modifyPeerConsumer(this.participant.id, 'mic', !status);
    // }

    getVideoIcon(): string {
        if (this.participant.webcam.state) {
            return 'icon-gomeet-state-video';
        } else if (this.participant.webcam.isLocallyPaused) {
            return 'icon-gomeet-state-videooff';
        } else { return 'icon-gomeet-state-videooff'; }
    }

    getAudioIcon(): string {
        if (!this.participant.audio.state || this.participant.audio.isLocallyPaused) {
            return 'icon-gomeet-state-micoff';
        } else { return 'icon-gomeet-state-mic'; }
    }

    openPrivateChat(peerId): void {
        // this.triggerSelectedOption(type);
        // this.chattingService.getPrivateChatMessage(id);
        this.chattingService.setActiveChat(peerId);
        this.roomService.rightPaneView = "chatting" as RightPaneViewType;
        // this.poolService.getPeer(peerId).setWaitingMessage(0);
        // this.onShowContainerRight.emit({ type, id});
    }

    viewShareScreen(): void {
        // const remoteParticipant = this.poolService.getPeer(this.participant.id);
        // this.activeSpeakerService.setActiveSpeaker(remoteParticipant);
        // const layoutMode = this.settingsService.getLayoutMode();
        // if (layoutMode && layoutMode !== 'breakroom') {
        //     this.settingsService.changeLayoutMode('sidebyside');
        // }

        //Ktra truong hop co force layout: User ko phai moderator se khong thuc hien dc thao tac
        if (!this.hasPermissionModerator && this.roomService.getForceLayout.layout != 0) return;

        const remoteParticipant = this.poolService.getPeer(this.participant.id);
        this.activeSpeakerService.setActiveSpeaker(remoteParticipant);
        const layoutMode = this.settingsService.getLayoutMode();
        if (layoutMode && layoutMode !== 'breakroom') {
            if (this.roomService.getForceLayout.layout != 0) {
                let layoutId = DISPLAY_LAYOUT.find(layout => layout.name == 'sidebyside').id;
                let { pinners, hideNoneCamera } = this.roomService.getForceLayout;
                this.roomService.setForceLayout(layoutId, pinners, hideNoneCamera);
                this.roomService.changeForceLayout(layoutId, pinners, hideNoneCamera);
            } else {
                this.settingsService.changeLayoutMode('sidebyside');
            }
        }
    }

    pinCameraHandler(): void {
        let isForceLayout : boolean = this.roomService.getForceLayout.layout != 0;
        if(isForceLayout){
            // this.poolService.setIsPin(this.participant.id, !this.participant.isPin, isForceLayout, this.roomService.getForceLayout.layout );
            if(this.roomService.getForceLayout && this.hasPermissionModerator){
                let pinners = Array.from(this.poolService.getRemotesPin.map((remote) => remote.id));
                //Kiểm tra pin của remote
                if (!this.participant.isPin) {
                    pinners.push(this.participant.id)
                }else {
                    const index = pinners.indexOf(this.participant.id, 0);
                    if (index > -1) {
                        pinners.splice(index, 1);
                    }
                }
                this.roomService.moderatorPin(pinners).then((result) =>{
                    if(result){
                        //cập nhật trạng thái pin của remote và danh sách remotesPin khi thành công
                        this.poolService.setIsPin(this.participant.id, !this.participant.isPin, isForceLayout, this.roomService.getForceLayout.layout );
                        // this.roomService.moderatorPin(pinners);
                    }
                });
            } 
        }else{
            this.poolService.setIsPinNormal(this.participant.id, !this.participant.isPin );
        }

    }
    
    triggerVisibleQuickActionChange(flag): void {
        this.showUp = flag;
    }
    openMoreMenu(event): void {
        this.moreMenuVisible = event;
    }
}
