
<div class="main-frame">
  <div vnpt-row>
    <div id="whiteboard" #whiteboard class="whiteboard">
      <section class="white_board--wrapper">
        <div *ngIf="canDrawWhiteboard" id="control_container" #control_container
          class="white_board--controls mat-elevation-z8">
          <button class="button-color" mat-mini-fab
          title="{{'whiteboard.tool.color' | vI18n:{}:''}}" aria-label="Ink Color Picker">
            <input class="input-color" role="button" type="color" [value]="inkColor" [style.background]="inkColor" [(ngModel)]="inkColor"
              aria-label="Color Picker" />
          </button>

          <button vnpt-button (click)='openModalSizeOpacity()' matTooltip="Brush Size & Opacity"
            matTooltipPosition="right" aria-label="Brush Size & Opacity"
            title="{{'whiteboard.tool.size' | vI18n:{}:''}}"><i vnpt-icon [vColor]="'primary'"
              vType="LineThickness"></i></button>

          <hr class="hr_divider" />

          <button vnpt-button vnpt-dropdown [vDropdownMenu]="menu" vPlacement="bottomCenter"
          title="{{'whiteboard.tool.toolbox' | vI18n:{}:''}}"><i vnpt-icon
              [vColor]="'warning'" vType="Toolbox"></i></button>
          <vnpt-dropdown-menu #menu="vDropdownMenu">
            <ul vnpt-menu class="ul-menu-tool">
              <li vnpt-menu-item class="li-menu-tool" style="display: table-cell;">
                <button vnpt-button [vType]='getTypeButton("pencil")' (click)='setSelectionButton("pencil")'
                title="{{'whiteboard.tool.pencil' | vI18n:{}:''}}" aria-label="Pencil"><i vnpt-icon
                    vType="PenWorkspace"></i></button>
              </li>
              <li vnpt-menu-item class="li-menu-tool" style="display: table-cell;">
                <button vnpt-button [vType]='getTypeButton("line")' (click)='setSelectionButton("line")' title="{{'whiteboard.tool.line' | vI18n:{}:''}}" aria-label="Line"><i vnpt-icon
                    vType="CharticulatorLine"></i></button>
              </li>
              <li vnpt-menu-item class="li-menu-tool" style="display: table-cell;">
                <button vnpt-button [vType]='getTypeButton("circle")' (click)='setSelectionButton("circle")'
                title="{{'whiteboard.tool.circle' | vI18n:{}:''}}" aria-label="Circle"><i vnpt-icon
                    vType="CircleShape"></i></button>
              </li>
              <li vnpt-menu-item class="li-menu-tool" style="display: table-cell;">
                <button vnpt-button [vType]='getTypeButton("rect")' (click)='setSelectionButton("rect")'
                title="{{'whiteboard.tool.rectangle' | vI18n:{}:''}}" aria-label="Rect"><i vnpt-icon
                    vType="RectangleShape"></i></button>
              </li>
              <li vnpt-menu-item class="li-menu-tool" style="display: table-cell;">
                <button vnpt-button [vType]='getTypeButton("triangle")' (click)='setSelectionButton("triangle")'
                title="{{'whiteboard.tool.triangle' | vI18n:{}:''}}" aria-label="Triangle"><i vnpt-icon
                    vType="TriangleShape"></i></button>
              </li>
              <li vnpt-menu-item class="li-menu-tool" style="display: table-cell;">
                <button vnpt-button [vType]='getTypeButton("text")' (click)='setSelectionButton("text")'
                title="{{'whiteboard.tool.text' | vI18n:{}:''}}" aria-label="Text"><i vnpt-icon
                    vType="PlainText"></i></button>
              </li>
              <li vnpt-menu-item class="li-menu-tool" style="display: table-cell;">
                <vnpt-upload class="btn_upload_image" [vHeaders]="headerUpload" [vAction]="apiUpload"
                  vAccept=".jpg,.png,.jpeg"  [vShowUploadList]="false"
                  (vChange)="handleUploadImage($event)" [(vDisabled)]="isDisableUpload" [vBeforeUpload]="beforeUpload">
                  <button vnpt-button
                  title="{{'whiteboard.tool.image' | vI18n:{}:''}}"><i vnpt-icon vType="FileImage"></i></button>
                </vnpt-upload>
              </li>
            </ul>
          </vnpt-dropdown-menu>

          <button vnpt-button [vType]='getTypeButton("eraser")' (click)='setSelectionButton("eraser")'
          title="{{'whiteboard.tool.eraser' | vI18n:{}:''}}" aria-label="Eraser"><i vnpt-icon vType="EraseTool"
              [vColor]="'primary'"></i></button>

          <button vnpt-button (click)='sendMessageUndo()' title="{{'whiteboard.tool.undo' | vI18n:{}:''}}"
            aria-label="Undo"><i vnpt-icon [vColor]="'primary'" vType="Undo"></i></button>

          <button *ngIf="isModerator" vnpt-button (click)='clearBoard()' title="{{'whiteboard.tool.tras' | vI18n:{}:''}}"
            aria-label="Clear Canvas"><i vnpt-icon [vColor]="'primary'" vType="Delete"></i></button>

          <button vnpt-button (click)='saveAsImage()' title="{{'whiteboard.tool.download' | vI18n:{}:''}}"><i vnpt-icon [vColor]="'primary'" vType="Download"></i></button>
        </div>
        <span class="hide-element" id="zoom_size" #zoom_size>{{zoomSize}}</span>
        <div id="container" [ngClass]="cursorClass"></div>
      </section>
    </div>

    <vnpt-modal [(vVisible)]="isVisible" (vOnCancel)="closeModalSizeOpacity()">
      <div *vModalContent>
        <div>
          <span vI18n="whiteboard.brushSize">Kích thước</span>
          <vnpt-slider [vMin]="1" [vMax]="100" [vStep]="1" [(ngModel)]="brushSize"></vnpt-slider>
        </div>
        <div>
          <span vI18n="whiteboard.brushOpacity">Độ mờ</span>
          <vnpt-slider [vMin]="0.1" [vMax]="1" [vStep]="0.1" [(ngModel)]="brushOpacity"></vnpt-slider>
        </div>
      </div>
      <div *vModalFooter>
        <button vnpt-button vType="default" (click)="closeModalSizeOpacity()" vI18n="whiteboard.closeModal">Đóng</button>
      </div>
    </vnpt-modal>

    <!-- <div class="list-wrapper">
      <div #listView class="paging-box">
        <div #listVideo class="video-list">
          <div class="gutter-row">
            <lib-local-streaming></lib-local-streaming>
          </div>

          <div class="gutter-row" *ngIf="listRemoteParticipant && listRemoteParticipant[0]">
            <lib-remote-streaming [participantId]="listRemoteParticipant[0].id">
            </lib-remote-streaming>
          </div>

          <div class="gutter-row" *ngIf="listRemoteParticipant && listRemoteParticipant[1]">
            <lib-remote-streaming [participantId]="listRemoteParticipant[1].id">
            </lib-remote-streaming>
          </div>

          <div class="gutter-row" *ngIf="listRemoteParticipant && listRemoteParticipant[2]">
            <lib-remote-streaming [participantId]="listRemoteParticipant[2].id">
            </lib-remote-streaming>
          </div>

          <div class="gutter-row" *ngIf="listRemoteParticipant && listRemoteParticipant[3]">
            <lib-remote-streaming [participantId]="listRemoteParticipant[3].id">
            </lib-remote-streaming>
          </div>

          <div class="gutter-row" *ngIf="listRemoteParticipant && listRemoteParticipant[4]">
            <lib-remote-streaming [participantId]="listRemoteParticipant[4].id">
            </lib-remote-streaming>
          </div>
        </div>
      </div>

      <button class="paging-button left-button" vnpt-button vType="primary" vShape="circle" (click)="previousPage()"
        [ngClass]="{'d-none': currentPageIndex - 1 <= 0}">
        <i vnpt-icon vType="ChevronLeft"></i>
      </button>

      <button class="paging-button right-button" vnpt-button vType="primary" vShape="circle" (click)="nextPage()"
        [ngClass]="{'d-none': currentPageIndex + 1 > getTotalPageIndex() }">
        <i vnpt-icon vType="ChevronRight"></i>
      </button>

      <p class="paging-count" [ngClass]="{'d-none': getTotalPageIndex() < 2}">{{currentPageIndex + '/' +
        getTotalPageIndex()}}</p>
    </div> -->
  </div>
</div>