import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    RolesManagerService,
    MediasoupService,
    LocalParticipantService,
    ConsumerService, SocketService, SecurityService
} from '../../services';
import {Subscription} from 'rxjs';
import {UserRoleManagerModel, UserRoleModel} from '../../models/user-role.model';
import {ActionRemoteParticipant, RemoteParticipant} from '../../models/remote-participant.model';
import {ROLES_CONSTANTS, ADMIN_ROLES, MODERATOR_ROLES, PERMISSIONS} from '../../constants';
import { OneuiI18nService } from '@vnpt/oneui-i18n';
import { PoolService } from '../../services/mediasoup/pool.service';

@Component({
    selector: 'lib-roles-manager',
    templateUrl: './roles-manager.component.html',
    styleUrls: ['./roles-manager.component.less']
})
export class RolesManagerComponent implements OnInit, OnDestroy {
    isVisible = false;
    title: string = "";
    subscriptions: Subscription[] = [];
    userRoles: Map<number, UserRoleModel>;
    adminRoles: UserRoleManagerModel[] = [];
    moderatorRoles: UserRoleManagerModel[] = [];
    normalRoles: UserRoleManagerModel[] = [];
    participant: RemoteParticipant;
    switchValue = false;
    highestRoleLevel = 0;
    constants = {ROLES_CONSTANTS};
    isModeratorDisabled = false;
    isNormalDisable =  false;
    checkRoleEvent = false;
    constructor(
        private localParticipantService: LocalParticipantService,
        private rolesManagerService: RolesManagerService,
        private mediasoupService: MediasoupService,
        private consumerService: ConsumerService,
        private poolService: PoolService,
        private securityService: SecurityService,
        private i18nService:OneuiI18nService
    ) {
        this.participant = new RemoteParticipant({}, this.consumerService, mediasoupService);
    }

    ngOnInit(): void {
        this.onInitEvent();
    }

    ngOnDestroy(): void {
        for (const sub of this.subscriptions){
            sub.unsubscribe();
        }
    }

    onInitEvent(): void{
        this.subscriptions.push(this.securityService.onProcessingModeratorManageRole().subscribe((flag) => {
            this.checkRoleEvent =  flag
        }));
        this.subscriptions.push(this.rolesManagerService.onRoleManagerOpen().subscribe((isVisible: boolean) => {
            this.isVisible = isVisible;
        }));

        this.subscriptions.push(this.rolesManagerService.onUserRoles().subscribe((userRoles: Map<number, UserRoleModel>) => {
            this.userRoles = userRoles;
            
        }));

        this.subscriptions.push(this.localParticipantService.onRoles().subscribe((localRoles: number[]) => {
            if (!localRoles){
                return;
            }
            this.highestRoleLevel = this.rolesManagerService.getHighestRoleLevel(localRoles);

            if(!this.rolesManagerService.isModerator(localRoles, MODERATOR_ROLES)){
                this.rolesManagerService.setRoleManagerOpen(false);
                return;
            }
        }));
        //Lang nghe event Add role va remove role de cap nhat trang thai quyen cua user.
        this.subscriptions.push(this.poolService.onUpdateRemoteParticipantInfo().subscribe((info: ActionRemoteParticipant)=>{
            if((info?.action == 'ADD_ROLE') || (info?.action == 'REMOVE_ROLE')){
                const  { peerId, roleId } = info.data;
                if(peerId == this.participant.id){
                    //Tìm index của role đc cập nhật trong 2 list: normalRoles, moderatorRoles
                    //Cập nhật lại trạng thái đc cấp quyền/thu hồi quyền 
                    const indexNomarlRole = this.normalRoles.findIndex(e => e.id == roleId)
                    if(indexNomarlRole > -1){
                        // this.normalRoles[indexNomarlRole].active = info.action == 'ADD_ROLE' ?  true : false;
                        if(info.action == 'ADD_ROLE' ) {
                            this.normalRoles[indexNomarlRole].active = true;
                        }
                        else {
                            this.normalRoles[indexNomarlRole].active = false;
                        }
                        return;
                    }
                    const indexModeratorRole = this.moderatorRoles.findIndex(e => e.id == roleId)
                    if(indexModeratorRole > -1){
                        // this.moderatorRoles[indexModeratorRole].active = info.action == 'ADD_ROLE' ?  true : false;
                        //TH user dc cấp quyền Morderator => disable các button quyền khác
                        if(info.action == 'ADD_ROLE') {
                            this.moderatorRoles[indexModeratorRole].active = true;
                        }
                        else {
                            this.moderatorRoles[indexModeratorRole].active = false;
                        }
                        // this.isNormalDisable = info.action == 'ADD_ROLE' ?  true : false;
                        if(info.action == 'ADD_ROLE') {
                            this.isNormalDisable = true;
                        }
                        else {
                            this.isNormalDisable = false;
                        }
                        return;
                    }
                } 
            }
        }))

        this.subscriptions.push(this.rolesManagerService.onRemoteParticipant().subscribe((remoteParticipant: RemoteParticipant) => {
            if (!remoteParticipant){
                //close modal khi user f5 hoac roi khoi room
                this.rolesManagerService.setRoleManagerOpen(false);
                return;
            }
            if (remoteParticipant.roles.includes(PERMISSIONS.ADMIN.id)
                || (!this.localParticipantService.getRolesSubject().includes(PERMISSIONS.ADMIN.id) && this.localParticipantService.getRolesSubject().includes(PERMISSIONS.MODERATOR.id))) {
                this.isModeratorDisabled = true;
            } else {
                this.isModeratorDisabled = false;
            }

            this.participant = remoteParticipant;
            //Chuyển seperateUserRoles() xuống onRemoteParticipant => Khi bật componment mới gọi seperateUserRoles
            this.seperateUserRoles();
            this.title = this.getModalTitle();
        }));
    }

    onInitRemoteParticipant(): void{

    }

    getModalTitle(): string {
        return this.i18nService.translate("roles-manager.modalTitle",{displayName: this.participant.displayName},`Thay đổi quyền cho ${this.participant.displayName}`);
    }

    checkRoleActived(roleModel: UserRoleManagerModel): UserRoleManagerModel {
        //Cap nhat active = true nếu user có quyền 
        if (this.participant.roles.includes(roleModel.id)) {
            roleModel.active = true;
        } else {
            roleModel.active = false;
        }
        //TH user dc cấp quyền Morderator => disable các button quyền khác
        // this.isNormalDisable = this.participant.roles.includes(PERMISSIONS.MODERATOR.id) ? true : false;
        if(this.participant.roles.includes(PERMISSIONS.MODERATOR.id)) {
            this.isNormalDisable = true;
        }
        else {
            this.isNormalDisable = false;
        }
        return roleModel;
    }

    seperateUserRoles(): void {
        if (this.userRoles) {
            this.moderatorRoles = [];
            this.normalRoles = [];
            const iterator = this.userRoles.entries();
            let iteratorValue = iterator.next().value;
            while (iteratorValue) {
                if (iteratorValue[1].level >= ADMIN_ROLES) { 
                    this.adminRoles.push(this.checkRoleActived(iteratorValue[1] as UserRoleManagerModel)); 
                }
                else
                if (iteratorValue[1].level >= MODERATOR_ROLES) { 
                    this.moderatorRoles.push(this.checkRoleActived(iteratorValue[1] as UserRoleManagerModel));
                 }
                else if (PERMISSIONS.WHITEBOARD.id != iteratorValue[1].id){
                    this.normalRoles.push(this.checkRoleActived(iteratorValue[1] as UserRoleManagerModel));
                } 

                iteratorValue = iterator.next().value;
            }
        }
    }

    handleClose(): void {
        this.rolesManagerService.setRoleManagerOpen(false);
    }



    changeRole(roleId: number): void {
        const isExistRole = this.isExistRole(roleId);
        isExistRole ?
            this.mediasoupService.removeRemoteRole(this.participant.id, roleId) :
            this.mediasoupService.giveRemoteRole(this.participant.id, roleId);
    }

    isExistRole(roleId: number): boolean {
        if (this.participant.roles.includes(PERMISSIONS.MODERATOR.id)) {
            this.isNormalDisable = true;
        } else {
            this.isNormalDisable = false;
        }
        return this.participant.roles.some((peerRole) => peerRole === roleId);
    }
}
