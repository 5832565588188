import * as SettingsActions from './settings.actions';

export type Action = SettingsActions.All;

import { config } from '../../../config';

const initialState =
    {
        displayName               : '',
        selectedWebcam            : null,
        selectedAudioDevice       : null,
        advancedMode              : false,
        autoGainControl           : config.autoGainControl,
        echoCancellation          : config.echoCancellation,
        noiseSuppression          : config.noiseSuppression,
        voiceActivatedUnmute      : config.voiceActivatedUnmute,
        noiseThreshold            : config.noiseThreshold,
        audioMuted                : false,
        videoMuted                : false,
        // low, medium, high, veryhigh, ultra
        resolution                : config.resolution,
        frameRate                 : config.frameRate,
        screenSharingResolution   : config.screenResolution,
        screenSharingFrameRate    : config.screenSharingFrameRate,
        // recorderPreferredMimeType : window.config.defaultRecorderMimeType || 'video/webm',
        lastN                     : 4,
        permanentTopBar           : true,
        hiddenControls            : false,
        showNotifications         : true,
        notificationSounds        : true,
        mirrorOwnVideo            : true,
        hideNoVideoParticipants   : false,
        buttonControlBar          : config.buttonControlBar,
        drawerOverlayed           : config.drawerOverlayed,
        aspectRatio               : config.aspectRatio,
        mediaPerms                : { audio: true, video: true },
        localPicture              : null,
        audioPreset               : config.audioPreset,
        audioPresets              : config.audioPresets,
        sampleRate                : config.sampleRate,
        channelCount              : config.channelCount,
        sampleSize                : config.sampleSize,
        opusStereo                : config.opusStereo,
        opusDtx                   : config.opusDtx,
        opusFec                   : config.opusFec,
        opusPtime                 : config.opusPtime,
        opusMaxPlaybackRate       : config.opusMaxPlaybackRate,
        enableOpusDetails         : false
    };

export const SettingsReducer = (state = initialState, action: Action) =>
{
    switch (action.type)
    {
        case SettingsActions.CHANGE_WEBCAM:
        {
            return { ...state, selectedWebcam: action.payload.deviceId };
        }

        case SettingsActions.CHANGE_AUDIO_DEVICE:
        {
            return { ...state, selectedAudioDevice: action.payload.deviceId };
        }

        case SettingsActions.CHANGE_AUDIO_OUTPUT_DEVICE:
        {
            return { ...state, selectedAudioOutputDevice: action.payload.deviceId };
        }

        case SettingsActions.SET_DISPLAY_NAME:
        {
            const { displayName } = action.payload;

            return { ...state, displayName };
        }

        case SettingsActions.SET_IS_NEED_HANDLE_JOIN_LINK:
        {
            const { val } = action.payload;

            return { ...state, isNeedHandleJoinLink: val };
        }

        case SettingsActions.TOGGLE_ADVANCED_MODE:
        {
            const advancedMode = !state.advancedMode;

            return { ...state, advancedMode };
        }

        case SettingsActions.SET_SAMPLE_RATE:
        {
            const { sampleRate } = action.payload;

            return { ...state, sampleRate, opusMaxPlaybackRate: sampleRate };
        }

        case SettingsActions.SET_CHANNEL_COUNT:
        {
            const { channelCount } = action.payload;

            return { ...state, channelCount, opusStereo: channelCount > 1 };
        }

        // case SettingsActions.SET_VOLUME:
        // {
        //     const { volume } = action.payload;
        //
        //     return { ...state, volume };
        // }

        case SettingsActions.SET_AUTO_GAIN_CONTROL:
        {
            const { autoGainControl } = action.payload;

            return { ...state, autoGainControl };
        }

        case SettingsActions.SET_AUDIO_PRESET:
        {
            const { audioPreset } = action.payload;

            return { ...state, audioPreset };
        }

        case SettingsActions.SET_ECHO_CANCELLATION:
        {
            const { echoCancellation } = action.payload;

            return { ...state, echoCancellation };
        }

        case SettingsActions.SET_NOISE_SUPPRESSION:
        {
            const { noiseSuppression } = action.payload;

            return { ...state, noiseSuppression };
        }

        case SettingsActions.SET_VOICE_ACTIVATED_UNMUTE:
        {
            const { voiceActivatedUnmute } = action.payload;

            return { ...state, voiceActivatedUnmute };
        }

        case SettingsActions.SET_NOISE_THRESHOLD:
        {
            const { noiseThreshold } = action.payload;

            return { ...state, noiseThreshold };
        }

        case SettingsActions.SET_OPUS_STEREO:
        {
            const { opusStereo } = action.payload;

            return { ...state, opusStereo };
        }

        case SettingsActions.SET_OPUS_DTX:
        {
            const { opusDtx } = action.payload;

            return { ...state, opusDtx };
        }

        case SettingsActions.SET_OPUS_FEC:
        {
            const { opusFec } = action.payload;

            return { ...state, opusFec };
        }

        case SettingsActions.SET_OPUS_PTIME:
        {
            const { opusPtime } = action.payload;

            return { ...state, opusPtime };
        }

        case SettingsActions.SET_OPUS_MAX_PLAYBACK_RATE:
        {
            const { opusMaxPlaybackRate } = action.payload;

            return { ...state, opusMaxPlaybackRate };
        }

        case SettingsActions.SET_ENABLE_OPUS_DETAILS:
        {
            const { enableOpusDetails } = action.payload;

            return { ...state, enableOpusDetails };
        }

        case SettingsActions.SET_DEFAULT_AUDIO:
        {
            const { audio } = action.payload;

            return { ...state, audio };
        }

        case SettingsActions.SET_SAMPLE_SIZE:
        {
            const { sampleSize } = action.payload;

            return { ...state, sampleSize };
        }

        case SettingsActions.SET_ASPECT_RATIO:
        {
            const { aspectRatio } = action.payload;

            return { ...state, aspectRatio };
        }

        case SettingsActions.SET_LAST_N:
        {
            const { lastN } = action.payload;

            return { ...state, lastN };
        }

        case SettingsActions.TOGGLE_PERMANENT_TOPBAR:
        {
            const permanentTopBar = !state.permanentTopBar;

            return { ...state, permanentTopBar };
        }

        case SettingsActions.TOGGLE_BUTTON_CONTROL_BAR:
        {
            const buttonControlBar = !state.buttonControlBar;

            return { ...state, buttonControlBar };
        }

        case SettingsActions.TOGGLE_DRAWER_OVERLAYED:
        {
            const drawerOverlayed = !state.drawerOverlayed;

            return { ...state, drawerOverlayed };
        }

        case SettingsActions.TOGGLE_HIDDEN_CONTROLS:
        {
            const hiddenControls = !state.hiddenControls;

            return { ...state, hiddenControls };
        }

        case SettingsActions.TOGGLE_NOTIFICATION_SOUNDS:
        {
            const notificationSounds = !state.notificationSounds;

            return { ...state, notificationSounds };
        }

        case SettingsActions.TOGGLE_SHOW_NOTIFICATIONS:
        {
            const showNotifications = !state.showNotifications;

            return { ...state, showNotifications };
        }

        case SettingsActions.SET_VIDEO_RESOLUTION:
        {
            const { resolution } = action.payload;

            return { ...state, resolution };
        }

        case SettingsActions.SET_VIDEO_FRAME_RATE:
        {
            const { frameRate } = action.payload;

            return { ...state, frameRate };
        }

        case SettingsActions.SET_SCREEN_SHARING_RESOLUTION:
        {
            const { screenSharingResolution } = action.payload;

            return { ...state, screenSharingResolution };
        }

        case SettingsActions.SET_SCREEN_SHARING_FRAME_RATE:
        {
            const { screenSharingFrameRate } = action.payload;

            return { ...state, screenSharingFrameRate };
        }

        case SettingsActions.TOGGLE_MIRROR_OWN_VIDEO:
        {
            const mirrorOwnVideo = !state.mirrorOwnVideo;

            return { ...state, mirrorOwnVideo };
        }

        case SettingsActions.TOGGLE_HIDE_NO_VIDEO_PARTICIPANTS:
        {
            const hideNoVideoParticipants = !state.hideNoVideoParticipants;

            return { ...state, hideNoVideoParticipants };
        }

        case SettingsActions.SET_MEDIA_PERMS:
        {
            const { mediaPerms } = action.payload;

            return { ...state, mediaPerms };
        }

        case SettingsActions.SET_AUDIO_MUTED:
        {
            const { audioMuted } = action.payload;

            return { ...state, audioMuted };
        }

        case SettingsActions.SET_VIDEO_MUTED:
        {
            const { videoMuted } = action.payload;

            return { ...state, videoMuted };
        }

        case SettingsActions.SET_LOCAL_PICTURE:
        {
            const { localPicture } = action.payload;

            return { ...state, localPicture };
        }

        case SettingsActions.SET_RECORDER_SUPPORTED_MIME_TYPES:
        {
            const { recorderSupportedMimeTypes } = action.payload;

            return { ...state, recorderSupportedMimeTypes };
        }

        // case SettingsActions.SET_RECORDER_PREFERRED_MIME_TYPE:
        // {
        //     const { recorderPreferredMimeType } = action.payload;
        //
        //     return { ...state, recorderPreferredMimeType };
        // }

        default:
            return state;
    }
};
