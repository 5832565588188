<div class="info" vJustify="space-between" vAlign="middle">
  <div vnpt-row class="logo" vJustify="space-between" vAlign="middle">
    <i vnpt-icon class="logo-img" [vSrc]="info.logo"></i>
  </div>
  <div vnpt-row class="logo" vJustify="space-between" vAlign="middle">
    <span class="room">{{"settings.information.copyright" | vI18n:{}:'Bản quyền'}}: {{info.license}}</span>
  </div>
  <div vnpt-row class="logo" vJustify="space-between" vAlign="middle">
    <span class="room">{{"settings.information.version" | vI18n:{}:'Phiên bản'}}: {{info.ver}}</span>
  </div>
</div>
