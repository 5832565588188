<vnpt-card class="card-holder" [vBodyStyle]="{flexFlow: 'column',display: 'flex',flex: 1, padding: 0, overflow: 'hidden'}" [vTitle]="cardTitle" [vExtra]="back">
    <ng-template [ngIf]="hasPermissionModerator" [ngIfElse]="normalBlock">
        <vnpt-tabset [vCentered]="true" [vTabBarStyle]="{marginBottom:'0px'}" vSize="small"
            [vTabBarGutter]="changeLangage == 'vi' ? '0' : 0" [(vSelectedIndex)]="selectedIndex">
            <vnpt-tab [vTitle]="titleTab1" (vClick)="eventTab(0)">
                <ng-template #titleTab1>
                    <div [class]="{'tabContent': true ,'unselected': selectedIndex}">
                        {{ tabTitle }}
                    </div>
                </ng-template>
                <div *ngTemplateOutlet="listParticipantBlock"></div>
            </vnpt-tab>
            <vnpt-tab [vTitle]="titleTab2" (vClick)="eventTab(1)">
                <ng-template #titleTab2>
                    <div [class]="{'tabContent': true, 'unselected': !selectedIndex}">
                        <i class="{{isLock ? 'icon-gomeet-lock' : 'icon-gomeet-unlock'}} icon-black"></i>
                        {{ 'lobby.title' | vI18n: {}:'Chờ duyệt' }}
                        <vnpt-badge *ngIf="hasPermissionModerator && lobbyLength !== 0" [vCount]="lobbyLength"
                            [vOverflowCount]="9" [vStyle]="{ backgroundColor: '#D9534F'}" class="badge">
                        </vnpt-badge>
                    </div>
                </ng-template>
                <lib-lobby-v2></lib-lobby-v2>
            </vnpt-tab>
        </vnpt-tabset>
    </ng-template>

    <ng-template #normalBlock>
        <!-- <vnpt-row vJustify="space-between" class="title">
            <span class="drawer-title">{{ getTabTitle() }}</span>
        </vnpt-row> -->
        <div *ngTemplateOutlet="listParticipantBlock"></div>
    </ng-template>

    <ng-template #listParticipantBlock>
        <vnpt-row vJustify="space-between" class="custom">
            <div class="search">
                <vnpt-input-group [vPrefix]="suffixIcon" [vSuffix]="suffixTemplate" class="search-input">
                    <input type="text" placeholder="{{'list-participant.search' | vI18n:{}:'Tìm kiếm người tham gia'}}"
                        vnpt-input [(ngModel)]="inputValue" (ngModelChange)="this.userSearchUpdate.next($event)" />
                </vnpt-input-group>
            </div>
            <a class="sort" vnpt-dropdown [vDropdownMenu]="menu" vTrigger="click" vnpt-tooltip
                vTooltipPlacement="topRight" vTooltipTitle='{{ "list-participant.sort" | vI18n:{}:"Sắp xếp" }}'>
                <i style="font-size: 17px;" class="icon icon-black icon-gomeet-sort"></i>
            </a>
            <a class="sort" vnpt-dropdown [vDropdownMenu]="menu2" vTrigger="click" vnpt-tooltip
                vTooltipPlacement="topRight" vTooltipTitle='{{ "list-participant.filter" | vI18n:{}:"Lọc" }}'>
                <i style="font-size: 17px;" class="icon icon-black icon-gomeet-filter"></i>
            </a>
            <a *ngIf="hasPermissionModerator" class="sort" (click)="export()" vnpt-tooltip
                vTooltipPlacement="topRight" vTooltipTitle='{{ "list-participant.export" | vI18n:{}:"Xuất danh sách" }}'>
                <i vnpt-icon vType="DownloadDocument" [vSize]="'sm'" class="icon icon-black"></i>
            </a>
            <vnpt-dropdown-menu #menu="vDropdownMenu">
                <ul vnpt-menu class="menu-background">
                    <!-- <li vnpt-menu-item (click)="filterAndSortBy(inputValue,'alphabet')">{{'list-participant.sortByAlphabet' | vI18n:{}:'Tên'}}</li>
                    <li vnpt-menu-item (click)="filterAndSortBy(inputValue,'raisedHand')">{{'list-participant.sortByRaisedHand' | vI18n:{}:'Giơ tay'}}</li>
                    <li vnpt-menu-item vSelected="true" (click)="filterAndSortBy(inputValue,'role')">{{'list-participant.sortByRole' | vI18n:{}:'Quyền'}}</li> -->
                    <ng-template ngFor let-item [ngForOf]="sortUserListBy">
                        <li vnpt-menu-item class="text-color" (click)="filterAndSortBy(inputValue, item.name, keyFilter)"
                            [vSelected]="keySort == item.name">
                            {{ "list-participant."+ item.title| vI18n:{}:"" }}
                        </li>
                    </ng-template>
                </ul>
            </vnpt-dropdown-menu>
            <vnpt-dropdown-menu #menu2="vDropdownMenu">
                <ul vnpt-menu vSelectable class="menu-background">
                    <li vnpt-menu-item vSelected="true" class="text-color" (click)="filterAndSortBy(inputValue, keySort,'all')">
                        {{'list-participant.all' | vI18n:{}:'Tất cả'}}</li>
                    <li vnpt-menu-item class="text-color" (click)="filterAndSortBy(inputValue, keySort,'raisedHand')">
                        {{'list-participant.filterByRaisedHand' | vI18n:{}:'Giơ tay'}}</li>
                    <li vnpt-menu-item class="text-color" (click)="filterAndSortBy(inputValue, keySort,'speaker')">
                        {{'list-participant.filterBySpeaker' | vI18n:{}:'Mở micro'}}</li>
                    <li vnpt-menu-item class="text-color" (click)="filterAndSortBy(inputValue, keySort,'shareScreen')">
                        {{'list-participant.filterByShareVideo' | vI18n:{}:'Mở video'}}</li>

                </ul>
            </vnpt-dropdown-menu>
        </vnpt-row>

        <!-- <vnpt-divider class="divider"></vnpt-divider> -->
        <ng-template #suffixIcon>
            <i disabled class="icon-gomeet-search icon-black"></i>
        </ng-template>
        <ng-template #suffixTemplate>
            <i vnpt-icon [vType]="isTyping ? 'Clear' : ''" (click)="clear()" class="icon-black"></i>
        </ng-template>
        <div class="list-holder">
            <lib-local-participant-detail [forceLayoutStatus]="forceLayoutStatus"
                [hasPermissionModerator]="hasPermissionModerator" [hasPermissionAdmin]="hasPermissionAdmin"
                [numberOrder]="1" class="local">
            </lib-local-participant-detail>
            <ag-virtual-scroll #vs [items]="filteredOptions" height="calc(100vh - 180px)" min-row-height="32" class="custom-scroll">
                <div *ngFor="let rp of vs.items; let i = index ;  trackBy: trackById" class="remotes">
                    <lib-remote-participant-detail [forceLayoutStatus]="forceLayoutStatus"
                        [hasPermissionModerator]="hasPermissionModerator" [hasPermissionAdmin]="hasPermissionAdmin"
                        [numberOrder]="vs.startIndex + i" [remote]="rp">
                    </lib-remote-participant-detail>
                </div>
            </ag-virtual-scroll>
        </div>
    </ng-template>
</vnpt-card>

<ng-template #cardTitle>
    <div class="title-holder">
        <!-- <i class="icon-gomeet-group icon"></i> -->
        <span class="card-title">
            {{hasPermissionModerator ? ('list-participant.tabTitle' | vI18n:{}:'') : tabTitle}}
        </span>
    </div>
</ng-template>

<ng-template #securityTemplate>
    <lib-security></lib-security>
</ng-template>

<ng-template #back>
    <div class="extra-buttons">
        <button vnpt-button *ngIf="hasPermissionModerator"
            class="btn-icon"
            vnpt-popover
            [(vPopoverVisible)]="visibility.security" vTabBarGutter="0" vPopoverPlacement="bottomRight"
            (click)="pooper(visibility.security, 'security')"
            vPopoverTrigger="!visibility.security ? 'click' : null"
            [vPopoverContent]="securityTemplate"
            vPopoverOverlayClassName="backgroundPopover pooper-height-max">
            <i class="icon icon-gomeet-shield"></i>
        </button>
        <button (click)="goBack()" vnpt-button vType="text" class="btn-icon" vnpt-tooltip
            vTooltipTitle="{{ 'joining.button.chat' | vI18n:{}:'' }}" vTooltipPlacement="bottom">
            <vnpt-badge *ngIf="unseenMessage !== 0"
                class="badgeParticipant"
                [vDot]="unseenMessage" [vStyle]="{ backgroundColor: '#C41A1A'}">
            </vnpt-badge>
            <i class="icon-gomeet-chat2 icon"></i>
        </button>
    </div>
</ng-template>
