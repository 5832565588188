<ng-template [ngIf]="changeTemplate">
    <div>
        <div [class]="'list-item'" (click)="openSetting()">
            <i vnpt-icon [vType]="'Settings'" [vSize]="'sm'"></i>
            <span>{{ "audio-device.setting" | vI18n:{}:"Cài đặt âm thanh"}}</span>
        </div>
        <vnpt-divider class="divider" style="margin-bottom: 8px;" vText="Filter" vOrientation="left"></vnpt-divider>
    
        <div  [class]="'list-item'" (click)="onFilterNoise()">
            <i vnpt-icon [vType]="filterNoiseStatus == true ? 'Accept' : ''" [vSize]="'sm'"></i>
            <span>{{ "audio-device.filterNoise" | vI18n:{}:"Lọc nhiễu"}}</span>
        </div>
    
        <vnpt-divider class="divider" style="margin-bottom: 8px;" vText="Microphone" vOrientation="left"></vnpt-divider>
        <div *ngFor="let mic of listMic">
        <div [class]="mic.deviceId == selectedMic?.deviceId ? 
        'list-item-pick' : 'list-item'" (click)="choseMic(mic)">
            <i vnpt-icon [vType]="mic.deviceId == selectedMic?.deviceId ? 'Accept' : ''" [vSize]="'sm'"></i>
            <span>{{mic.label == '' ?  defaultSystem : mic.label}}</span>
        </div>
        </div>
        <div *ngIf="listMic.length == 0" class='list-item-pick' [style]="{'color': noDevice ? 'red' : 'white'}">
            <i vnpt-icon [vType]="noDevice ? '': 'loading'" [vSize]="'sm'" ></i>
            <span>{{noDevice ? ("device.label.unidentified" | vI18n:{}:"") : ("device.label.findDevice" | vI18n:{}:"")}}</span>
        </div>
        <vnpt-divider class="divider" vText="Speaker" vOrientation="left"></vnpt-divider>
        <div *ngFor="let speaker of listSpeaker">
        <div [class]="speaker.deviceId == selectedSpeaker.deviceId ? 
        'list-item-pick' : 'list-item'" (click)="choseSpeaker(speaker)">
            <i vnpt-icon [vType]="speaker.deviceId == selectedSpeaker.deviceId ? 'Accept' : ''" [vSize]="'sm'"></i>
            <span>{{speaker.label == '' ? defaultSystem : speaker.label}}</span>
        </div>
    </div>
    </div>
    </ng-template>
    
    <ng-template [ngIf]="!changeTemplate">
        <ng-template [ngIf]="!childMessage">
            <vnpt-divider class="divider" style="margin-bottom: 8px;" vText="Microphone" vOrientation="left"></vnpt-divider>
                <div *ngFor="let mic of listMic">
                    <div [class]="mic.deviceId == selectedMic.deviceId ? 
            'list-item-pick' : 'list-item'" (click)="choseMic(mic)">
                    <i vnpt-icon [vType]="mic.deviceId == selectedMic.deviceId ? 'Accept' : ''" [vSize]="'sm'"></i>
                    <span>{{mic.label == '' ?  defaultSystem : mic.label}}</span>
                    </div>
                </div>
        </ng-template>
        <ng-template [ngIf]="childMessage">
            <vnpt-divider class="divider" vText="Speaker" vOrientation="left"></vnpt-divider>
            <div *ngFor="let speaker of listSpeaker">
            <div [class]="speaker.deviceId == selectedSpeaker.deviceId ? 
            'list-item-pick' : 'list-item'" (click)="choseSpeaker(speaker)">
                <i vnpt-icon [vType]="speaker.deviceId == selectedSpeaker.deviceId ? 'Accept' : ''" [vSize]="'sm'"></i>
                <span>{{speaker.label == '' ? defaultSystem : speaker.label}}</span>
            </div>
        </div>
        </ng-template>
    
    </ng-template>