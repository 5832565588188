import { Component, Input, Output, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { OneuiI18nService } from '@vnpt/oneui-i18n';
import { AudioDeviceService, SettingsService } from '../../services'; 
import { RoomService } from '../../services';
import { config } from '../../../config';
@Component({
  selector: 'lib-video-device',
  templateUrl: './video-device.component.html',
  styleUrls: ['./video-device.component.less']
})
export class VideoDeviceComponent implements OnInit, OnDestroy {
  // changeTemplate = false;
  @Input() changeTemplate: boolean;
  @Input() qualityTemplate: boolean;
  @Output() changeIsLock = new EventEmitter<boolean>();
  @Output() pooper = new EventEmitter<any>();
  listVideo = [];
  listQuality = [{label:'SD (640x360)', name: 'SD', isTrue: false},
  {label:'HD (1280x720)' ,name: 'HD', isTrue: true},
  {label:'FullHD (1920x1080)', name: 'FullHD', isTrue: false},
  {label: this.i18nService.translate("video-device.byDevice",{},"Theo thiết bị"), name: 'Max Setting', isTrue: false}
]
  selectedVideo = this.audioDeviceService.getVideo();
  selectedLocalVideo = this.audioDeviceService.getLocalVideo();
  defaultSystem = this.i18nService.translate("device.label.default",{},"Mặc định hệ thống");
  noDevice = false;
  streamCheck: MediaStream;
  constructor(private audioDeviceService: AudioDeviceService,
    private roomService: RoomService,
    private i18nService: OneuiI18nService,
    private settingsService: SettingsService,
    ) { }

    ngOnInit(): void {
      this.listQuality.forEach(el=>{
        if(el.name === this.roomService.quality) el.isTrue = true;
        else el.isTrue = false;
      })
      this.getVideoDevices().then(()=> {
        this.stopTrack();
      });

      this.audioDeviceService.onListVideo().subscribe((listVideo)=> {
        this.listVideo = listVideo;
      })

      this.audioDeviceService.onVideoDevice().subscribe((flag)=> {
          this.selectedVideo = this.audioDeviceService.getVideo();
          this.selectedLocalVideo = this.audioDeviceService.getVideo();
      })
      
      // navigator.mediaDevices.ondevicechange = event => {
      //   this.getVideoDevices().then(()=> {
      //     if(this.streamCheck)
      //     this.streamCheck.getTracks().forEach(track=> {
      //      track.stop();
      //     })
      //   });
      // }
    }

    ngOnDestroy(): void {
     this.stopTrack();
    }

    stopTrack() {
      if(this.streamCheck)
        this.streamCheck.getTracks().forEach(track=> {
        track.stop();
      })
    }

    async getVideoDevices(): Promise<void> {
      try {
          this.streamCheck = await navigator.mediaDevices.getUserMedia({ video: true });
        
          const list = await navigator.mediaDevices.enumerateDevices();
          
          this.listVideo = list.filter(item => item.kind === "videoinput" && item.label !== "");
          // set noDevice nếu không tìm được thiết bị
          if(this.listVideo.length === 0){
            this.noDevice = true;
          }else{
            const findSelectedVideo = ele => ele.deviceId === this.selectedVideo?.deviceId;
            this.selectedVideo = this.listVideo.find(findSelectedVideo) || this.listVideo[0];
            this.selectedLocalVideo = this.listVideo.find(findSelectedVideo) || this.listVideo[0];
        
            this.audioDeviceService.changeVideo(this.selectedVideo);
            this.audioDeviceService.changeLocalVideo(this.selectedVideo);
          }
      } catch (error) {
        this.noDevice = true;
      }
    }
  
    async choseVideo(video): Promise<void> {
      
      this.pooper.emit();
      this.audioDeviceService.changeVideo(video);
      this.audioDeviceService.changeLocalVideo(video);
      // this.roomService.changeVideoMicLocal();
      // this.roomService.changeVideoMic();
      if(!this.settingsService.getIsVideoMuted()) {
        let t = await this.roomService.changeVideoMic();
        if(t) {
          this.selectedVideo = video;   
          this.selectedLocalVideo = video;
        }
        else {
          this.audioDeviceService.changeVideo(this.selectedVideo);
          this.audioDeviceService.changeLocalVideo(this.selectedLocalVideo);
        }
      }
    }

     async choseVideoLocal(video): Promise<void> {
      this.audioDeviceService.changeLocalVideo(video);
      let t = await this.roomService.changeVideoMicLocal();
      if(t) {
        this.changeIsLock.emit(false);
        this.selectedLocalVideo = video;
      }
      else {
        this.audioDeviceService.changeLocalVideo(this.selectedLocalVideo);
        this.changeIsLock.emit(true);
      }
      this.pooper.emit(); 
    }

    choseQuality(quality): void {
      this.listQuality.forEach(qua=> {
        qua.name == quality.name ? qua.isTrue = true : qua.isTrue = false;
      });
      this.roomService.changeQuality(quality);
      this.pooper.emit();
    }

    async choseLocalQuality(quality): Promise<void> {
      this.listQuality.forEach(qua=> {
        qua.name == quality.name ? qua.isTrue = true : qua.isTrue = false;
      });
      this.pooper.emit();
      this.changeIsLock.emit(false);
      await this.roomService.changeQualityLocal(quality);
    }

    openSetting(): void {
      this.settingsService.setVideoSetting(true);
      this.pooper.emit();
    }
    // choseQualityLocal(quality): void {
    //   this.listQuality.forEach(qua=> {
    //     qua.name == quality.name ? qua.isTrue = true : qua.isTrue = false;
    //   });
    //   this.roomService.changeQualityLocal(quality);
    // }
}
