import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, AfterViewInit, OnDestroy} from '@angular/core';
import {RemoteParticipant} from '../../../../models/remote-participant.model';
import {Subscription} from 'rxjs';
import { PoolService } from 'projects/realtime-communication/src/lib/services/mediasoup/pool.service';

@Component({
    selector: 'lib-viewing-participant-quiz',
    templateUrl: './viewing-participant-quiz.component.html',
    styleUrls: ['./viewing-participant-quiz.component.less']
})
export class ViewingParticipantQuizComponent implements OnInit, AfterViewInit, OnDestroy {

    constructor(
        private poolService: PoolService,
        private cdRef: ChangeDetectorRef
    ) {
    }

    listRemoteParticipant: RemoteParticipant[] = [];
    currentPageIndex = 1;
    listViewHeight: number;
    listVideoHeight: number;
    listViewObserver: ResizeObserver;
    listVideoObserver: ResizeObserver;
    subscriptions: Subscription[] = [];
    @ViewChild('listView') listView: ElementRef;
    @ViewChild('listVideo') listVideo: ElementRef;

    ngOnInit(): void {
        this.currentPageIndex = this.poolService.currentPage;
        this.updateListRemoteParticipant();
        this.onInitEvent();
    }

    ngAfterViewInit(): void {
        this.listViewObserver.observe(this.listView.nativeElement);
        this.listVideoObserver.observe(this.listVideo.nativeElement);
    }

    ngOnDestroy(): void {
        for (const subs of this.subscriptions) {
            subs.unsubscribe();
        }
        this.listViewObserver.disconnect();
        this.listVideoObserver.disconnect();
    }

    onInitEvent(): void {
        this.subscriptions.push(this.poolService.onUpdateCurrentPage.asObservable().subscribe((flag: boolean) => {
            if (flag) { this.updateListRemoteParticipant(); }
        }));
        
        this.subscriptions.push(this.poolService.onUpdateMaxItem.asObservable().subscribe((flag: boolean) => {
            if (flag) { 
                this.poolService.gotoPage(1);                   
                this. pagination(); }
        }));

        // @ts-ignore
        this.listVideoObserver = new ResizeObserver((entries: any) => {
            this.listViewHeight = this.listView.nativeElement.clientHeight;
            this.listVideoHeight = entries[0].contentRect.height;
            const isScrollbarShown = this.listViewHeight < this.listVideoHeight;
            this.triggerScrollbar(isScrollbarShown);
        });
        // @ts-ignore
        this.listViewObserver = new ResizeObserver((entries: any) => {
            this.listViewHeight = entries[0].contentRect.height;
            const isScrollbarShown = this.listViewHeight < this.listVideoHeight;
            this.triggerScrollbar(isScrollbarShown);
        });
    }

    stopListWebcamConsumer(remotes: RemoteParticipant[]): void{
        for (const remote of remotes){
            remote.stopWebcam();
        }
    }

    startListWebcamConsumer(remotes: RemoteParticipant[]): void{
        for (const remote of remotes){
            remote.startWebcam();
        }
    }

    updateListRemoteParticipant(): void {
        const remoteService = this.poolService;
        this.currentPageIndex = remoteService.currentPage;
        this.listRemoteParticipant = remoteService.getAllPeers();//getListRemoteParticipantView();
        this.startListWebcamConsumer(this.listRemoteParticipant);
        this.cdRef.detectChanges();
    }

    getTotalPageIndex(): number {
        return this.poolService.totalPage;
    }

    nextPage(): void {
        this.poolService.nextPage();
        this.pagination();
    }

    previousPage(): void {
        this.poolService.prevPage();
        this.pagination();
    }

    pagination(): void {
        // TODO: change order of start/stop, should be start first and stop later for queue reprocessing
        // Hint: save old list current with new list avoid conflict common list.
        const remoteService = this.poolService;
        this.currentPageIndex = remoteService.currentPage;
        const newList = remoteService.getAllPeers();//getListRemoteParticipantView();
        const stopList = [];
        this.listRemoteParticipant.forEach(remote => {
            if(newList.indexOf(remote) == -1)
            stopList.push(remote);
        })
        this.stopListWebcamConsumer(stopList);
        this.updateListRemoteParticipant();
        this.cdRef.detectChanges();
    }

    triggerScrollbar(isShown: boolean): void {
        // const listView = this.listView.nativeElement.classList;
        const listVideo = this.listVideo.nativeElement.classList;
        isShown ?
            listVideo.remove('center-items') :
            listVideo.add('center-items');
    }

}
