/* tslint:disable:no-output-on-prefix */
import {
    Component,
    OnDestroy,
    OnInit,
    Output,
    EventEmitter,
    Input,
    ChangeDetectorRef,
    ViewChild,
    ElementRef
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
// @ts-ignore
import { BOTTOM_IN, BOTTOM_OUT, FLOAT_DURATION, LAYOUT_MODE } from '../../../constants';
import {
    LocalParticipantService,
    MediasoupService,
    SettingsService,
    ReactionsService,
    ChattingService,
    ListRequestRoleService,
    RoomService, NotificationService, RecordService, RightPaneViewType, LobbyService, AudioDeviceService,
} from '../../../services';
import { createFloatAnimation } from '../../../animation';
import { permissions } from '../../../permissions';
import { OneuiI18nService } from '@vnpt/oneui-i18n';
import { PoolService } from '../../../services/mediasoup/pool.service';
import { BaseMessage, Conversation, TOPIC_PUBLIC_CHAT } from '../../../models/chatting.model';
import { VModalService, VModalRef } from '@vnpt/oneui-ui/modal';
import { reduce } from 'rxjs-compat/operator/reduce';
import { PERMISSIONS } from '../../../constants';
import { RecordClientService } from '../../../services/record-client.service';

type Options = 'audio' | 'video' | 'security' | 'listParticipant' | 'shareScreen' | 'chatting' | 'reactions' | 'breakoutRoomManager' | 'settings' | 'privateChat' | 'record'| 'more' | '';
const mediaDevices = navigator.mediaDevices as any;
let completeBlob: Blob;
declare var MediaRecorder: any;
var autoVideo, autoMic;
@Component({
    selector: 'lib-joining-options',
    templateUrl: './joining-options.component.html',
    styleUrls: ['./joining-options.component.less'],
    animations: [createFloatAnimation(BOTTOM_IN, BOTTOM_OUT, FLOAT_DURATION)]
})
export class JoiningOptionsComponent implements OnInit, OnDestroy {
    isAudioMuted = false;
    isVideoMuted = true;
    isMeShareScreen = false;
    shareScreenVisible = false;
    participantLength = 1;
    unseenMessage = 0;
    // isRightContainerShown = false;
    visibility = {
        security: false,
        reactions: false,
        settings: false,
        endMeeting: false,
        listAudio: false,
        listVideo: false,
        options: false
    };
    isSelected = false;
    selectedOption: Options;
    isOptionShown = true;
    forcedOptionShown = false;
    hasPermissionModerator = false;
    hasPermissionShareScreen = false;
    isRoleAdmin = false;
    isProcessingVideo = false;
    isProcessingAudio = false;

    hasVideo = false;
    isRecording = false;
    isLoading = false;
    recorder: any;
    stream: any;
    isBlockAllWebcam = false;
    isBlockAllAudio = false;
    isJoinedAudio = false;
    timeOut: any;
    isWhiteboardOn: boolean = false;
    canTriggerWhiteboard = false;
    hasPermissionAdmin = false;
    isInitial: boolean = false;
    record: boolean = false;
    recordSpam: boolean = false;
    time: any = this.recordService.getTime();
    interval: any = null;
    statusRecording : string = this.i18nService.translate("joining.reaction.record", {}, "Ghi hình");
    recordingIconValue : string = '';
    holdSpaceTitle2 = this.i18nService.translate("hold_space2", {}, "Ghi hình");
    shareScreenName: string = '';
    spaceHold: boolean = false;
    spaceHoldAction: any = null;
    lobbyLength: boolean = false;
    isRequestPresenter: boolean = false;
    isShowConfirmModal: boolean = false;
    confirmModal?: VModalRef; 
    haveEventSpaceHold: any = false;
    keyAction: string = '';
    keyupSpaceHoldRunBefore: boolean = false;

    @ViewChild('recordVideo') recordVideo!: ElementRef;
    @ViewChild('micDiv') micDivRef: ElementRef;
    @ViewChild('videoDiv') videoDivRef: ElementRef;
    @ViewChild('lobby') lobbyRef: ElementRef;

    @Input() expandSubject: Observable<boolean> = new Observable<boolean>();
    @Input() forceExpandSubject = false;
    @Output() onShareLocalScreen = new EventEmitter();
    @Output() onClearMinimumSharingLocalScreen = new EventEmitter();
    // @Output() onShowContainerRight = new EventEmitter();
    @Output() onShareScreen = new EventEmitter();
    @Output() onVisibleChange = new EventEmitter();
    // layoutMode: LAYOUT_MODE;
    @Input() layoutMode: LAYOUT_MODE = 'gallery';
    @Input() isRightContainerShown: boolean = false;
    @Output() toggleContainerRight = new EventEmitter<RightPaneViewType>();
    @Output() triggerWhiteboardMode = new EventEmitter<LAYOUT_MODE>();
    private ngUnsubscribe = new Subject();

    constructor(
        private mediasoupService: MediasoupService,
        private localParticipantService: LocalParticipantService,
        private settingsService: SettingsService,
        private poolService: PoolService,
        private chattingService: ChattingService,
        private reactionsService: ReactionsService,
        private roomService: RoomService,
        private notificationService: NotificationService,
        private recordService: RecordService,
        private i18nService: OneuiI18nService,
        private readonly changeDetector: ChangeDetectorRef,
        private lobbyService: LobbyService,
        private listRequestRoleService: ListRequestRoleService,
        private modalService: VModalService,
        private audioDeviceService: AudioDeviceService,
        private recordClientService: RecordClientService
    ) {
        this.i18nService.langChanges$.subscribe(evt=>{
            if(evt.type == "Success"){
                this.recordingIcon();       
                this.holdSpaceTitle2 = this.i18nService.translate("hold_space2", {}, "Bạn có thể giữ phím cách để nói");
            }
          });
    }


    ngOnInit(): void {
        this.onInitEvent();
        // this.checkRoleBreakroom();
    }
    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
    async onInitEvent(): Promise<void> {

        const appConfig = await this.settingsService.getAppConfig();

        this.lobbyService.onChangeLobbyList().subscribe((isChange: boolean) => {
            const length = this.lobbyService.getLobbyList().size;
            // this.lobbyLength = length > 0 ? true : false;
            if(length > 0) {
                this.lobbyLength = true;
            }
            else {
                this.lobbyLength = false;
            }
            if (length == 0) {
                this.lobbyRef.nativeElement.dataset.columns = '';
            } else {
                this.lobbyRef.nativeElement.dataset.columns = length > 9 ? '9+' : length;
            }
        });

        if (appConfig.test?.autoOnOffCam) {
            this.autoVideo(appConfig.test.interval || 10);
        }

        if (appConfig.test?.autoOnOffMic) {
            this.autoMic(appConfig.test.interval || 10);
        }
        this.poolService.onUpdateListRemoteParticipantInfo.asObservable().subscribe((flag: boolean) => {
            if (flag) {
                const listRemoteParticipant = this.poolService.getAllPeers();
                this.participantLength = listRemoteParticipant.length + 1;
            }
        });

        this.localParticipantService.onRoles().subscribe((roles: number[]) => {
            this.hasPermissionModerator = this.roomService.hasPermission(permissions.MODERATE_ROOM);
            if(!this.hasPermissionModerator) this.lobbyRef.nativeElement.dataset.columns = '';
            this.hasPermissionShareScreen = this.roomService.hasPermission(permissions.SHARE_SCREEN);
            this.isBlockAllAudio = !this.roomService.hasPermission(permissions.SHARE_AUDIO);
            this.isBlockAllWebcam = !this.roomService.hasPermission(permissions.SHARE_VIDEO);
            this.canTriggerWhiteboard = this.roomService.hasPermission(permissions.MODERATE_ROOM)
                || this.mediasoupService.hasRole('admin'); //ktra quyen whiteboard cho tat ca user
            this.hasPermissionAdmin = this.roomService.hasPermission(permissions.PROMOTE_PEER);
        });

        this.chattingService.newMessageBehavior.subscribe(<T extends BaseMessage>(msg: T) => {
            // if (!msg || msg.topic != TOPIC_PUBLIC_CHAT || (this.chattingService.activeChat == TOPIC_PUBLIC_CHAT && this.roomService.rightPaneView == "chatting")) return;
            if (!msg || (this.chattingService.activeChat == msg.topic && this.roomService.rightPaneView == "chatting")) return;
            this.chattingService.getHistory(msg.topic).then((conversation: Conversation) => {
                this.unseenMessage = conversation.unreadMessageCount;
            });
        });

        this.chattingService.messageReadedBehavior.subscribe((topic: string) => {
            var activeChatList =  this.chattingService.getActiveList();   
            if (!activeChatList?.find(element => element.data.unreadMessageCount)) {
                this.unseenMessage = 0;
            } else { this.unseenMessage = 1; }
        });

        this.roomService.onIsProcessingVideo().subscribe((isProcessingVideo: boolean) => {
            if (isProcessingVideo === null) {
                return;
            }
            this.isProcessingVideo = isProcessingVideo;
        });

        this.roomService.onIsProcessingAudio().subscribe((isProcessingAudio: boolean) => {
            if (isProcessingAudio === null) {
                return;
            }
            this.isProcessingAudio = isProcessingAudio;
        });

        this.roomService.onIsWhiteboard().subscribe((isWhiteboard: boolean) => {
            this.isWhiteboardOn = isWhiteboard;
        });

        this.mediasoupService.onRequestPresenter().subscribe((obj: {isRequestPresenter: boolean, peerId: string, displayName: string}) =>{
            if (!obj) return;
            this.isRequestPresenter = obj.isRequestPresenter;
            
        });

        this.mediasoupService.onAcceptPresenter().subscribe((isAcceptPresenter: boolean) =>{
            if (isAcceptPresenter && this.isShowConfirmModal){
                this.confirmModal.close();
                this.isShowConfirmModal = false;
            }
        });

        this.settingsObserve();
        this.reactionsObserve();

        this.expandSubject.subscribe((isShowOptions: boolean) => {
            this.isOptionShown = isShowOptions;
            if (!isShowOptions) {
                Object.keys(this.visibility).forEach(key => this.visibility[key] = isShowOptions);
                this.changeDetector.detectChanges();
            }
        });

        this.recordService.onIsInitial().subscribe((isInitial: boolean) => {
            this.isInitial = isInitial;
            this.recordingIconValue = this.recordingIcon();
        });

        this.recordService.onRecord().subscribe((record: boolean) => {
            this.record = record;
        });

        this.recordService.onIsRecording().subscribe((isRecord: boolean) => {
            this.isRecording = isRecord;
            //Set timeout tắt chống spam sau 2s
            const recordSpamTimeOut = setTimeout(() => {
                this.recordSpam = false; // tắt chống spam sau khi nhận được trạng thái record mới
                clearTimeout(recordSpamTimeOut);
            }, 2000);
            this.recordingIconValue = this.recordingIcon();
            if (!this.isRecording) {
                clearInterval(this.interval);
                this.interval = null;
            } else if (this.interval === null) {
                this.time = this.recordService.getTime();
                this.interval = setInterval(() => {
                    this.time = this.time + 1;
                    this.recordService.setTime(this.time);
                }, 1000);
            }
        })

        this.recordService.onRecordError().subscribe(obs => {
            //Set timeout tắt chống spam sau 2s
            const recordSpamTimeOut = setTimeout(() => {
                this.recordSpam = false; //tắt chống spam khi nhận được có lỗi trường hợp không nhận được noti update lại trạng thái record
                clearTimeout(recordSpamTimeOut);
            }, 2000)
        })
    }

    // HIDDEN FEATURE: (BREAKOUT ROOM ) 
    // Hide check role admin in breakout room 
    // ----------------------------- 
    // checkRoleBreakroom(): void {
    //     if (this.localParticipantService.getRoleGroup() === 'ADMIN') {
    //         this.isRoleAdmin = true;
    //     } else {
    //         this.isRoleAdmin = false;
    //     }
    // }
    pooper(event, component, key): void {
        // if (component)
        this.visibility[key] = !component;
        if(event)
        event.stopPropagation();
    }

    settingsObserve(): void {
        this.settingsService.onAudioMuted().subscribe((isAudioMuted: boolean) => {
            this.isAudioMuted = isAudioMuted;
            //Kiểm tra trường hợp keyup chạy trước khi đổi biến audioMuted
            if(this.keyupSpaceHoldRunBefore) {
                this.keyupSpaceHoldRunBefore = false;
                this.settingsService.setIsSpaceHoldSubject(false);
                this.spaceHold = false;
                this.roomService.muteMic();
            }
            if(this.keyAction == "keyup") {
                if(!this.isAudioMuted) {
                    this.settingsService.setIsSpaceHoldSubject(false);
                    this.spaceHold = false;
                    this.roomService.muteMic();
                }
                if(this.isAudioMuted) {
                    this.keyAction = '';
                }
            }
        });

        this.settingsService.onVideoMuted().subscribe((isVideoMuted: boolean) => {
            this.isVideoMuted = isVideoMuted;
        });

        this.settingsService.onVideoBlocked().subscribe((isBlockAllVideo: boolean) => {
            this.isBlockAllWebcam = isBlockAllVideo;
        });

        this.settingsService.onAudioBlocked().subscribe((isBlockAllAudio: boolean) => {
            this.isBlockAllAudio = isBlockAllAudio;
        });

        this.settingsService.onTriggerRightContainer().subscribe((isShown: boolean) => {
            // this.isRightContainerShown = isShown;
            if (!isShown) {
                this.isSelected = false;
                this.selectedOption = null;
            }
        });

        this.settingsService.onTriggerOptionBar().subscribe((isShown: boolean) => {
            this.forcedOptionShown = isShown;
            this.isOptionShown = isShown;
        });

        this.settingsService.onJoinAudio().subscribe((isJoin: boolean) => {
            this.isJoinedAudio = isJoin;
            if (isJoin && !this.haveEventSpaceHold) {
                this.haveEventSpaceHold = true;
                document.addEventListener('keydown', (event) => {
                    if (event.repeat) { return; }
                    const source = event.target;
                    const exclude = ['input', 'textarea', 'div'];
                    if(this.isBlockAllAudio && this.isJoinedAudio) return;
                    if (source instanceof Element
                        && exclude.indexOf(source.tagName.toLowerCase()) === -1
                        && event.code === 'Space'
                        && this.isAudioMuted && !this.spaceHold) {  
                            this.keyAction = "keydown";                     
                            navigator.mediaDevices.getUserMedia({audio: true}).then(async(stream) => {
                            this.spaceHoldAction = setTimeout(async () => {
                                const listDevice = await navigator.mediaDevices.enumerateDevices();
                                const listSpeaker = listDevice.filter(item => item.kind == "audioinput" && item.deviceId != "communications" && item.deviceId != "");
                                if(listSpeaker.length > 0  && !this.spaceHold) {
                                    this.spaceHold = !this.spaceHold;
                                    this.settingsService.setIsSpaceHoldSubject(this.spaceHold);
                                    this.toggleMic();
                                    }
                                }, 300);
                                stream.getTracks().forEach(track=> {
                                track.stop();
                            })
                        }).catch((reason) => {});
                        
            }
                });
                document.addEventListener('keyup', (event) => {
                    if (event.code === 'Space') {
                        this.keyAction = "keyup";
                        if(this.isBlockAllAudio && this.isJoinedAudio) return;
                        clearTimeout(this.spaceHoldAction);                        
                        if (!this.isAudioMuted && this.spaceHold) {
                            this.spaceHold = !this.spaceHold;
                            this.settingsService.setIsSpaceHoldSubject(this.spaceHold);
                            this.toggleMic();
                            return;
                        }
                        if(this.isAudioMuted && this.spaceHold) {
                            this.keyupSpaceHoldRunBefore = true;
                        }
                    }
                });
            }
        });
    }

    reactionsObserve(): void {
        this.reactionsService.onLocalRaisedHand().subscribe((isRaisedHand: boolean) => {
            this.visibility.reactions = false;
        });
    }

    triggerSelectedOption(opt: Options): void {
        if (this.isSelected) {
            this.selectedOption === opt ? this.isSelected = false : this.selectedOption = opt;
        } else {
            this.isSelected = true;
            this.selectedOption = opt;
        }
        this.changeDetector.detectChanges();

    }

    toggleMic(): void {
        if (this.isProcessingAudio) { return; }
        this.micDivRef.nativeElement.style.pointerEvents = "none";
        window.addEventListener("toggleMic", (evt: any) => {
            this.micDivRef.nativeElement.style.pointerEvents = "";
        });
        this.triggerSelectedOption('audio');
        this.isAudioMuted ? this.roomService.producerAudioStart()
            : this.roomService.muteMic();
    }

    async toggleVideo(): Promise<void> {
        if (this.isProcessingVideo) { return; }
        this.videoDivRef.nativeElement.style.pointerEvents = "none";
        window.addEventListener("toggleVideoOff", (evt: any) => {
            this.videoDivRef.nativeElement.style.pointerEvents = "none";
        });
        window.addEventListener("toggleVideo", (evt: any) => {
            this.videoDivRef.nativeElement.style.pointerEvents = "";
        });
        this.triggerSelectedOption('video');
        this.isVideoMuted ? await this.roomService.producerVideoStart()
            : await this.roomService.disableWebcam();
    }

    toggleJoinAudio(): void {
        this.triggerSelectedOption('audio');
        this.settingsService.setJoinAudio(false);
    }

    openShareScreenModal() {
        var listParticipantIsShareScreen = this.poolService.getListParticipantIsShareScreen();
        if (listParticipantIsShareScreen.length > 0) {
            this.shareScreenName = listParticipantIsShareScreen[0].displayName;
            this.shareScreenName = this.shareScreenName.toString().bold();
            this.shareScreenVisible = true;
        }
        else {
            this.shareScreen('sidebyside');
        }
    }

    requestRole(){
        if(!this.isRequestPresenter){
            this.mediasoupService.requestRole(this.localParticipantService.getPeerId(), PERMISSIONS.PRESENTER.id);
        }
    }

    handleCancel() {
        this.shareScreenVisible = false;
    }

    shareScreen(type: LAYOUT_MODE): void {
        this.shareScreenVisible = false;
        if (!this.hasPermissionShareScreen) {
            this.notificationService.warning(this.i18nService.translate("notification.joining-options.errorShareScreen", {},
                'Bạn chưa được cấp quyền chia sẻ màn hình!'), '');
            return;
        }
        this.triggerSelectedOption('shareScreen');
        this.isMeShareScreen = !this.isMeShareScreen;

        this.isMeShareScreen ? this.roomService.updateScreenSharing({ start: true })
            : this.roomService.disableScreenSharing();

        if (this.isMeShareScreen) {
            this.settingsService.getShareScreenStatus().subscribe((mode) => {
                this.isMeShareScreen = mode;
            });
        } else {
            this.settingsService.setShareScreenStatus(false);
            this.settingsService.getShareScreenStatus().subscribe((mode) => {
                this.isMeShareScreen = mode;
            });
        }
    }

    endMeeting(): void {
        // this.roomService.closeRoom();
        this.modalService.confirm({
            vTitle: this.i18nService.translate("joining.endmeeting.leaveRoom",{},"Kết thúc cuộc họp"),
            vContent: this.i18nService.translate("joining.endmeeting.title",{},"Bạn có muốn thoát phòng họp không?"),
            vOkText: this.i18nService.translate("security.closeMeetingOk",{},"Đồng ý"),
            vOkType: "danger",
            vCancelText: this.i18nService.translate("security.closeMeetingCancel",{},"Hủy"),
            vOnOk: ()=> this.roomService.leaveRoom(),
          });
        
    }

    showContainerRight(event: Event, type: RightPaneViewType): void {
        this.toggleContainerRight.emit(type);
    }

    triggerVisibleChange(isVisible: boolean, opt?: Options): void {
        if (opt) { this.triggerSelectedOption(opt); }
        if (!this.forcedOptionShown) { this.onVisibleChange.emit(isVisible); }
    }

    // toggleRecord(): void {
    //     if (this.isRecording) {
    //         this.hasVideo = true;
    //         this.isRecording = false;
    //         this.recorder.stop();
    //         this.stream.getVideoTracks()[0].stop();
    //     } else {
    //         this.hasVideo = false;
    //         this.startRecording();
    //     }
    // }

    toggleRecord(): void {
        if (this.isInitial) return;
        const newStatus = !this.isRecording;
        // this.recordService.setIsRecording(newStatus);
        //Bật chống spam
        this.recordSpam = true;
        this.mediasoupService.recording(newStatus);
        if(newStatus) {
            if(!this.recordClientService.getIsRecordingClient())
            this.modalService.confirm({
                vTitle: this.i18nService.translate("joining.recordClient.title",{},"Quay lại màn hình"),
                vContent: this.i18nService.translate("joining.recordClient.content",{},"Bạn có muốn đồng thời quay lại màn hình cuộc họp ở ngay máy tính bạn ?"),
                vOkText: this.i18nService.translate("security.closeMeetingOk",{},"Đồng ý"),
                vOkType: "primary",
                vCancelText: this.i18nService.translate("security.closeMeetingCancel",{},"Hủy"),
                vOnOk: ()=> this.recordClientService.setIsRecordingClient(newStatus),
              });
        }
        else {
            this.recordClientService.setIsRecordingClient(newStatus);
        }
    }

    triggerWhiteboards(): void {
        this.layoutMode = this.layoutMode === 'whiteboard' ? 'gallery' : 'whiteboard';
        this.triggerWhiteboardMode.emit(this.layoutMode);
    }

    recordingIcon(): string {
        if (this.isInitial) {
            this.statusRecording = this.i18nService.translate("joining.reaction.record", {}, "Ghi hình")
            return "loading";
        }
        if (this.isRecording) {
            this.statusRecording = this.i18nService.translate("joining.reaction.pauseRecord", {}, 'Dừng ghi hình')
            return "icon-gomeet-record-pause CircleFill";
        }
        else if (this.time > 0) {
            this.statusRecording = this.i18nService.translate("joining.reaction.resumeRecord", {}, 'Tiếp tục ghi hình')
            return "icon-gomeet-record-resume PlayResume"
        }
        this.statusRecording = this.i18nService.translate("joining.reaction.record", {}, "Ghi hình");
        return 'icon-gomeet-record2 Play';
    }

    autoVideo(interval: number): void {
        const timeRandom = Math.floor(Math.random() * 5) + interval;
        this.toggleVideo();
        if (autoVideo) { clearTimeout(autoVideo) }
        autoVideo = setTimeout(this.autoVideo.bind(this, interval), timeRandom * 1000);
    }

    autoMic(interval: number): void {
        const timeRandom = Math.floor(Math.random() * 5) + interval;
        if (this.settingsService.getIsJoinedAudio()) this.toggleMic();
        if (autoMic) { clearTimeout(autoMic) }
        autoMic = setTimeout(this.autoMic.bind(this, interval), timeRandom * 1000);
    }

    closeMeeting(): void{
        this.modalService.error({
          vTitle: this.i18nService.translate("security.closeMeetingTitle",{},"Kết thúc cuộc họp"),
          vContent: this.i18nService.translate("security.closeMeetingContent",{},"Bạn có thực sự muốn đóng cuộc họp không?"),
          vOkText: this.i18nService.translate("security.closeMeetingOk",{},"Đồng ý"),
          vOkType: "danger",
          vCancelText: this.i18nService.translate("security.closeMeetingCancel",{},"Hủy"),
          vOnOk: ()=> this.mediasoupService.closeMeeting(),
          vIconType: "Warning"
        });
      }
}

