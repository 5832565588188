import * as MeActions from './me.actions';

export type Action = MeActions.All;

const initialState =
    {
        id                    : null,
        picture               : null,
        browser               : null,
        roles                 : [],
        canSendMic            : false,
        canSendWebcam         : false,
        canShareScreen        : false,
        canShareFiles         : false,
        audioDevices          : null,
        webcamDevices         : null,
        webcamInProgress      : false,
        audioInProgress       : false,
        screenShareInProgress : false,
        displayNameInProgress : false,
        loginEnabled          : false,
        raisedHand            : false,
        raisedHandInProgress  : false,
        loggedIn              : false,
        isSpeaking            : false,
        isAutoMuted           : true,
        localRecordingState   : null
    };

export const MeReducer = (state = initialState, action: Action) =>
{
    switch (action.type)
    {
        case MeActions.SET_ME:
        {
            const {
                peerId,
                loginEnabled
            } = action.payload;

            return {
                ...state,
                id : peerId,
                loginEnabled
            };
        }

        case MeActions.SET_BROWSER:
        {
            const { browser } = action.payload;

            return { ...state, browser };
        }

        case MeActions.LOGGED_IN:
        {
            const { flag } = action.payload;

            return { ...state, loggedIn: flag };
        }

        case MeActions.ADD_ROLE:
        {
            const roles = [ ...state.roles, action.payload.roleId ];

            return { ...state, roles };
        }

        case MeActions.REMOVE_ROLE:
        {
            const roles = state.roles.filter((roleId) =>
                roleId !== action.payload.roleId);

            return { ...state, roles };
        }

        case MeActions.SET_PICTURE:
            return { ...state, picture: action.payload.picture };

        case MeActions.SET_MEDIA_CAPABILITIES:
        {
            const {
                canSendMic,
                canSendWebcam,
                canShareScreen,
                canShareFiles
            } = action.payload;

            return {
                ...state,
                canSendMic,
                canSendWebcam,
                canShareScreen,
                canShareFiles
            };
        }

        case MeActions.SET_AUDIO_DEVICES:
        {
            const { devices } = action.payload;

            return { ...state, audioDevices: devices };
        }

        case MeActions.SET_AUDIO_OUTPUT_DEVICES:
        {
            const { devices } = action.payload;

            return { ...state, audioOutputDevices: devices };
        }

        case MeActions.SET_WEBCAM_DEVICES:
        {
            const { devices } = action.payload;

            return { ...state, webcamDevices: devices };
        }

        case MeActions.SET_AUDIO_IN_PROGRESS:
        {
            const { flag } = action.payload;

            return { ...state, audioInProgress: flag };
        }

        case MeActions.SET_WEBCAM_IN_PROGRESS:
        {
            const { flag } = action.payload;

            return { ...state, webcamInProgress: flag };
        }

        case MeActions.SET_SCREEN_SHARE_IN_PROGRESS:
        {
            const { flag } = action.payload;

            return { ...state, screenShareInProgress: flag };
        }

        case MeActions.SET_RAISED_HAND:
        {
            const { flag } = action.payload;

            return { ...state, raisedHand: flag };
        }

        case MeActions.SET_RAISED_HAND_IN_PROGRESS:
        {
            const { flag } = action.payload;

            return { ...state, raisedHandInProgress: flag };
        }

        case MeActions.SET_DISPLAY_NAME_IN_PROGRESS:
        {
            const { flag } = action.payload;

            return { ...state, displayNameInProgress: flag };
        }

        case MeActions.SET_IS_SPEAKING:
        {
            const { flag } = action.payload;

            return { ...state, isSpeaking: flag };
        }

        case MeActions.SET_AUTO_MUTED:
        {
            const { flag } = action.payload;

            return { ...state, isAutoMuted: flag };
        }

        case MeActions.SET_LOCAL_RECORDING_STATE:
        {
            const { localRecordingState } = action.payload;

            return { ...state, localRecordingState };
        }

        default:
            return state;
    }
};
