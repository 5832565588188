<div class="wrapper">
    <div *ngIf="isLoading" class="center-items">
        <vnpt-spin vTip="Đang tải ..."></vnpt-spin>
    </div>

    <div *ngIf="state === 'empty' && !isLoading" class="center-items">
        <vnpt-result vStatus="info" vTitle="Không có bài tập được giao">
            <div vnpt-result-extra>
                <button vnpt-button class="default-button" (click)="backToGallery()">Trở về lớp học</button>
            </div>
        </vnpt-result>
    </div>

    <div *ngIf="state === 'doing' && !isLoading">
        <vnpt-row vAlign="middle" vJustify="space-between" class="heading">
            <vnpt-page-header class="site-page-header" [vTitle]="quizName"></vnpt-page-header>

            <div vnpt-row>
                <span class="timer">Thời gian làm bài: </span>
                <vnpt-time-picker [(ngModel)]="deadline" vSize="large" vDisabled></vnpt-time-picker>
            </div>
        </vnpt-row>
        <vnpt-divider class="divider"></vnpt-divider>

        <div class="question-holder">
            <div *ngFor="let question of questions; let i = index">
                <div class="question-heading">
                    <h3>Câu {{ i + 1 }}: {{question.description}}</h3>
                </div>
                <vnpt-divider class="divider"></vnpt-divider>
                <div class="answer-options">
                    <vnpt-radio-group [(ngModel)]="answers[i].selectedOption" (ngModelChange)="saveAnswer()">
                        <label *ngFor="let option of question.options"
                               vnpt-radio [vValue]="option.text">
                            {{option.text}}
                        </label>
                    </vnpt-radio-group>
                </div>
                <vnpt-divider class="divider"></vnpt-divider>
            </div>
            <vnpt-row vJustify="center" class="submit">
                <button vnpt-button vType="primary" [vLoading]="submitLoading" (click)="submit()">
                    <i vnpt-icon vType="CheckMark"></i>Nộp bài
                </button>
            </vnpt-row>
        </div>
    </div>

    <div *ngIf="state === 'finish' && !isLoading" class="center-items">
        <div>
            <vnpt-result [vIcon]="'EndPointSolid'" [vTitle]="'Đã hoàn thành bài ' + quizName">
                <div vnpt-result-content>
                    <div class="desc">
                        <h4 vnpt-title>Kết quả bài làm {{quizName}}:</h4>
                        <p vnpt-paragraph>
                            <i vnpt-icon vType="Timer"></i>
                            <span>Bắt đầu lúc: {{result.timeStarted}}</span>
                        </p>
                        <p vnpt-paragraph>
                            <i vnpt-icon vType="Timer"></i>
                            <span>Kết thúc lúc: {{result.timeStarted}}</span>
                        </p>
                        <p vnpt-paragraph>
                            <i vnpt-icon vType="CheckMark" class="point-icon"></i>
                            <span class="point-description">Số điểm đạt được: {{result.marks + '/' + result.totalQuesion}}</span>
                        </p>
                    </div>
                </div>
                <div vnpt-result-extra>
                    <button vnpt-button class="default-button" (click)="backToGallery()">Trở về lớp học</button>
                </div>
            </vnpt-result>
        </div>
    </div>
</div>



