import {Injectable} from '@angular/core';
import {ProducerModel} from '../models/producer.model';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProducerService {
    constructor() {
    }

    private producerMap: Map<string, ProducerModel> = new Map();
    private producerMapSubject: BehaviorSubject<Map<string, ProducerModel>>
        = new BehaviorSubject<Map<string, ProducerModel>>(this.producerMap);


    addProducer(producer: ProducerModel): void{
        this.producerMap.set(producer.id, producer);
        this.producerMapSubject.next(this.producerMap);
    }

    getProducer(producerId: string): ProducerModel{
        return this.producerMap.get(producerId);
    }

    onProducer(): Observable<Map<string, ProducerModel>>{
        return this.producerMapSubject.asObservable();
    }

    removeProducer(id: string): void{
        this.producerMap.delete(id);
        this.producerMapSubject.next(this.producerMap);
    }
}
