import {AfterViewInit, Component, ElementRef, Input, ViewChild, OnInit, Output, EventEmitter, OnDestroy} from '@angular/core';
import {NotificationService, MediaStreamService, DeviceCountingService, RoomService} from '../../../services';
import {
  SettingsService,
  AudioDeviceService
} from '../../../services';
import { OneuiI18nService } from '@vnpt/oneui-i18n';
import { REPORT_TYPE } from '../../../constants';
import deviceInfo from '../../../deviceInfo';
import {Subscription} from 'rxjs';
import { RecordClientService } from '../../../services/record-client.service';
@Component({
  selector: 'lib-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.css']
})
export class AudioComponent implements AfterViewInit, OnInit, OnDestroy {
  isJoinedAudio = false;
  speakerId = "default";
  device = deviceInfo();
  audioIn: MediaStreamAudioSourceNode = null;
  constructor(
      private roomService: RoomService,
      private mediaStreamService: MediaStreamService,
      private notification: NotificationService,
      private settingsService: SettingsService,
      private audioDiviceService: AudioDeviceService,
      private deviceCountingService: DeviceCountingService,
      private i18nService: OneuiI18nService,
      private recordClientService: RecordClientService
  ) {
  }
  ngOnDestroy(): void {
    console.log("LOG DESTROY element audio tag");
    if (this.audio) {
      this.audio.muted = true;
      this.audio.onpause = null;
      this.audio.oncanplay = null;
      this.audio = null;
    }
    if (this.remoteVideoRef) {
      this.remoteVideoRef.nativeElement.remove();
    }
    for (const subs of this.subscriptions) {
      subs.unsubscribe();
    }
    if(this.audioIn)
    this.recordClientService.splitAudio(this.audioIn);
  }

  volumeAudio = 0;
  private subscriptions: Array<Subscription> = [];
  @ViewChild('audioRef') remoteVideoRef: ElementRef;
  @Input() elementId: string;
  @Input() peerId: string;
  @Input() isAudioScreen: boolean;
  @Output() audioStateChange = new EventEmitter<string>();
  audio: any;
  ngOnInit(): void {
    this.subscriptions.push(
      this.audioDiviceService.onSpeakerDevice().subscribe((speaker: any) => {
        this.speakerId = speaker ? speaker.deviceId : "default";
        const audio = this.remoteVideoRef ? this.remoteVideoRef.nativeElement : null;
        if(audio) audio.setSinkId(this.speakerId);
        // audio ? audio.setSinkId(this.speakerId): null;
    }))
    ;
    this.subscriptions.push(
      this.settingsService.onJoinAudio().subscribe((isJoined: boolean) => {
        this.isJoinedAudio = isJoined;
      })
    );
  }

  ngAfterViewInit(): void {
    this.onJoinAudio();
  }

  onJoinAudio(): void {
    this.subscriptions.push(
      this.deviceCountingService.onVolumeAudio().subscribe((volume: number) => {
        this.volumeAudio = volume;
        if (!this.remoteVideoRef.nativeElement) return;
        
        const audio = this.remoteVideoRef.nativeElement;
        audio.volume = this.volumeAudio / 10;
        if (this.isJoinedAudio) {
          
          let audioStream;
          if (this.isAudioScreen) {
            audioStream = this.mediaStreamService.getMediaStream(this.peerId, "audioscreen");
          }
          else {
            audioStream = this.mediaStreamService.getMediaStream(this.peerId, 'audio');
          }
          this.audioIn =  this.recordClientService.mergeAudio(audioStream);
          this.playAudio(audioStream, 3, null);
        }
      })
    );
  }

  playAudio(audioStream, retryCount: number, callback: Function): void {
        this.audio = this.remoteVideoRef.nativeElement;
        if (this.audio.sinkId == "" && this.device.flag != "firefox") {
          this.audio.setSinkId(this.speakerId);
        }
        this.audio.srcObject = audioStream;
        this.audio.muted = true;
        this.audio.volume = this.volumeAudio/10;

        this.audio.oncanplay = () => {
          this.audio.play()
              .then(() => {
                this.audio.muted = false;
                this.audioStateChange.emit("playing");
                if (callback) callback();
              })
              .catch(error => {
                if (callback) callback();
                if (!retryCount) {
                  if (error.name === 'NotAllowedError') {
                    this.notification.error(this.i18nService.translate('settings.permission.require'), '');
                  }
                  this.audioStateChange.emit("error");
                  this.roomService.sendReport('audio' as REPORT_TYPE, '', this.playAudio.name + error);
                  return;
                }
                var timeout = window.setTimeout(() => {
                  this.playAudio(audioStream, retryCount - 1, () => {
                    window.clearTimeout(timeout);
                  });
                }, 1000);
              });
        };

    // Kiểm tra trạng thái thẻ để thực hiện play lại audio
    this.audio.onpause = () => {
      console.log("audio is pausing");
      if (this.audio) {
        console.log("audio play again");
        this.audio.play();
      }
    };
  }

}
