import { Component, OnDestroy, OnInit } from '@angular/core';
import { element } from 'protractor';
import { Subscription } from 'rxjs';
import { LocalParticipant } from '../../models/local-participant.model';
import { ParticipantModel } from '../../models/participant.model';
import { RemoteParticipant } from '../../models/remote-participant.model';
import { LocalParticipantService, MediasoupService, RoomService, SettingsService } from '../../services';
import { PoolService } from '../../services/mediasoup/pool.service';
import { SpeakingService } from '../../services/speaking.service';
interface SpeakerModel {
  id: string;
  displayName: string;
  activeTime: number;
  color: string;
  opacity: number
}
@Component({
  selector: 'lib-speakers',
  templateUrl: './speakers.component.html',
  styleUrls: ['./speakers.component.less']
})
export class SpeakersComponent implements OnInit, OnDestroy {
  speakingList: SpeakerModel[] = [];
  remotes: RemoteParticipant[] = [];
  local: LocalParticipant;
  limitShowItem: number = 3;
  subscriptions: Subscription[] = [];
  checkSpeakerInterval: ReturnType<typeof setInterval>;

  constructor(
    private roomService: RoomService,
    private poolService: PoolService,
    private localParticipantService: LocalParticipantService,
    private settingsService: SettingsService,
    private speakingService: SpeakingService,
  ) {
    this.local = this.localParticipantService.getLocalParticipant();
   }

  ngOnInit(): void {
    this.settingsService.onJoinAudio().subscribe((isJoined: boolean) => {
      if (isJoined) {
        this.speakingService.activeSpeakerObserver.subscribe((speakingList: SpeakerModel[]) => {
          // console.log(speakingList)
          this.speakingList = [...speakingList];
        });
      }
    });
  }

  ngOnDestroy(): void {
    // do nothings
  }

  canShow(remote): boolean {
    return remote.isSpeaking;
  }

}
