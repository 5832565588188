/* tslint:disable:no-output-on-prefix */
import {Component, OnDestroy, OnInit} from '@angular/core';
import {RoomService} from '../../../services';
import { Subscription } from 'rxjs';

import { VModalService } from '@vnpt/oneui-ui/modal';

type Options = 'audio' | 'video';

@Component({
    selector: 'lib-lobby-options',
    templateUrl: './lobby-options.component.html',
    styleUrls: ['./lobby-options.component.less']
})
export class LobbyOptionsComponent implements OnInit, OnDestroy {
    endMeetingVisible = false;
    isSelected = false;
    selectedOption: Options;
    subscriptions: Subscription[] = [];
    timeOut: any;
    constructor(
        private roomService: RoomService,
        private modalService: VModalService
    ) {
    }

    ngOnDestroy(): void {
        if(this.subscriptions.length > 0) {
            this.subscriptions.forEach((s) => {
                s.unsubscribe()
            })
        }
    }

    ngOnInit(): void {
        this.subscriptions.push(this.roomService.onMeetingEnded().subscribe((data: any) => {
            if (!data) return;
            let warningModal = this.modalService.warning({
                vTitle: data.message,
                vClosable: false,
                vClassName: "meeting-end-dialog"
            });
            warningModal.afterClose.subscribe(() => {
                if (data.cb) data.cb();
            });
            if(this.timeOut) clearTimeout(this.timeOut);
            this.timeOut = setTimeout(() => {
                warningModal.close();
                if (data.cb) data.cb();
                clearTimeout(this.timeOut);
            }, 10000);
        }));
    }

    triggerSelectedOption(opt: Options): void {
        if (this.isSelected) {
            this.selectedOption === opt ? this.isSelected = false : this.selectedOption = opt;
        } else {
            this.isSelected = true;
            this.selectedOption = opt;
        }
    }

    leaveRoom(): void {
        this.roomService.leaveRoom();
    }
}
