/* tslint:disable:one-variable-per-declaration no-debugger */
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ElementRef,
    Input
} from '@angular/core';
import {RemoteParticipant} from '../../../models/remote-participant.model';
import {ActiveSpeakerService, LocalParticipantService, RoomService, SettingsService, ShareDataService} from '../../../services';
import {Subscription} from 'rxjs';
import { LocalParticipant } from '../../../models/local-participant.model';
import { PoolService } from '../../../services/mediasoup/pool.service';
import {RATIO_HEIGHT, RATIO_WIDTH, VIDEO_COLUMN_SIZE_FORMAT, COL_ONE_ROW, LAYOUT_MODE} from '../../../constants';
import { LayoutModel } from '../../../models/layout.model';

// @ts-ignore
const VIDEO_MAX_WIDTH = 25;
const STABLE_HEIGHT = 178;
@Component({
    selector: 'lib-sidebyside-view',
    templateUrl: './sidebyside.component.html',
    styleUrls: ['./sidebyside.component.less']
})
export class SideBySideViewComponent implements OnInit, OnDestroy, AfterViewInit {

    constructor(
        private localParticipantService: LocalParticipantService,
        private poolService: PoolService,
        private activeSpeakerService: ActiveSpeakerService,
        private cdRef: ChangeDetectorRef,
        private shareDataService: ShareDataService,
        private settingService: SettingsService,
        private roomService: RoomService
    ) {
    }

    // Component data
    currentPageIndex = 1;
    listRemoteParticipant: RemoteParticipant[] = [];
    // Component view
    isRecordUser: boolean =this.localParticipantService.getIsRecordUser();
    isScrollbarShown = false;
    listViewHeight: number;
    listVideoHeight: number;
    listViewObserver: ResizeObserver;
    listVideoObserver: ResizeObserver;
    subscriptions: Subscription[] = [];
    changePageTimeOut: any;
    @ViewChild('listView') listView: ElementRef;
    @ViewChild('listVideo') listVideo: ElementRef;
    @Input() layoutMode = "gallery";
    // @Input()
    @Input() isFullScreen = false;
    // @Input() outFullScreen = false;
    videoWidth = 15;
    boxSize = { width: 0, height: 0};
    videoColumnSize = VIDEO_COLUMN_SIZE_FORMAT.ONE_COLUMN * 100;
    mainframe = "main-frame-gallery";
    miniView =  false;
    hideNextPage = false;
    forceLayoutStatus = false;
    totalPage = 1;
    ngOnInit(): void {
        this.currentPageIndex = this.poolService.currentPage;
        this.updateListRemoteParticipant();
        this.handleShowingActivatingSpeaker();
        this.onInitEvent();
    }

    ngAfterViewInit(): void {
        this.listViewObserver.observe(this.listView.nativeElement);
        this.listVideoObserver.observe(this.listVideo.nativeElement);
    }

    ngOnDestroy(): void {
        for (const subs of this.subscriptions) {
            subs.unsubscribe();
        }
        this.listViewObserver.disconnect();
        this.listVideoObserver.disconnect();
    }

    onInitEvent(): void {
        this.subscriptions.push(this.roomService.onForceLayoutObservable().subscribe((layoutInfo: LayoutModel)=>{
            // this.forceLayoutStatus = layoutInfo.layout != 0 ? true : false;
            if(layoutInfo.layout != 0) {
                this.forceLayoutStatus = true;
            }
            else {
                this.forceLayoutStatus = false;
            }
            // if(!this.forceLayoutStatus){
            //     this.pagination();
            //     this.poolService.changePinWithPagination();
            // }
        }))

        // this.subscriptions.push(this.poolService.getNonCamViewObserver().subscribe((flag: boolean) => {
        //     if(flag) {
        //         this.updateListRemoteParticipant();
        //     }
        // }))

        //cap nhat lai danh sach view neu co thay doi
        this.poolService.onUpdateListRemoteParticipantInfo.asObservable().subscribe((flag: boolean) =>{
            if (flag) {
                this.updateListRemoteParticipant();
            }
        });

        //bo check flag.
        this.subscriptions.push(this.poolService.getNonCamViewObserver().subscribe((flag: boolean) => {
            // if (!flag) return;
            this.poolService.gotoPage(1);                   
            this.pagination();     
        }));

        this.subscriptions.push(this.poolService.onUpdateCurrentPage.asObservable().subscribe((flag: boolean) => {
            if (flag) {
                this.updateListRemoteParticipant();
                this.handleShowingActivatingSpeaker();
            }
        }));

        this.subscriptions.push(this.poolService.onUpdateMaxItem.asObservable().subscribe((flag: boolean) => {
            if (flag) {
                this.poolService.gotoPage(1);
                if(!this.forceLayoutStatus)this.poolService.changePinWithPagination();
                this.pagination();
                this.handleShowingActivatingSpeaker();
            }
        }));
        
        this.subscriptions.push(this.localParticipantService.onIsRU().subscribe((flag: boolean) => {
            this.isRecordUser = flag;
        }))
        
        this.subscriptions.push(this.poolService.onRemotesPin().subscribe((list: [])=> {
            if(list && list.length != 0) this.hideNextPage = true;
            else this.hideNextPage = false;
            this.poolService.gotoPage(1);
            this.pagination();
            this.handleShowingActivatingSpeaker();
        }))
        // @ts-ignore
        this.listVideoObserver = new ResizeObserver((entries: any) => {
            const { width, height } = entries[0].contentRect;
            this.boxSize = { width, height };
            this.settingService.onChangeLayoutMode().subscribe((mode: LAYOUT_MODE) => {
                this.pagination(); //cap nhat lai danh sach/page. Mac dinh chuyen layout, currentPage = 1
                this.layoutMode = mode;
                switch (this.layoutMode) {
                    case 'speaker':
                        this.mainframe = 'main-frame-speaker';
                        this.updateVideoWidth(width);
                        break;
                    case 'sidebyside':
                    case 'whiteboard':
                        this.mainframe = 'main-frame';
                        this.listViewHeight = this.listView.nativeElement.clientHeight;
                        this.listVideoHeight = entries[0].contentRect.height;
                        this.isScrollbarShown = this.listViewHeight < this.listVideoHeight;
                        this.triggerScrollbar();
                        break;
                    case 'polycom-71':
                    case 'polycom-121':
                        break;
                    default:
                        this.mainframe = 'main-frame-gallery';
                        this.updateVideoGrid();
                        break;
                }
            });
        });

        // @ts-ignore
        this.listViewObserver = new ResizeObserver((entries: any) => {
            this.listViewHeight = entries[0].contentRect.height;
            this.isScrollbarShown = this.listViewHeight < this.listVideoHeight;
            this.triggerScrollbar();
        });
    }

    updateVideoGrid(): void {
        const newGridCol = this.calcVideoBoxSize();     
        if (this.videoColumnSize !== newGridCol) {
            this.videoColumnSize = newGridCol * 100;
        }
        this.cdRef.detectChanges();
    }
    calcVideoBoxSize(): number {
        for (const [index, colCondition] of COL_ONE_ROW.entries()) {
            const preCol = this.fillVideoInOneRow({
                minCol: colCondition.minCol,
                maxCol: colCondition.maxCol
            }, index + 1);
            if (preCol) {
                return preCol;
            }
        }
        return 0;
    }
    fillVideoInOneRow({ minCol, maxCol }, numVideoInOneRow: number): number {
        let { height } = this.boxSize;
        const totalVideo = this.listRemoteParticipant.length + 1;
        const rows = Math.ceil(totalVideo * 1.0 / numVideoInOneRow);
        height -= rows * 16;
        const minHeight = this.calcVideoHeight(minCol, rows);
        const maxHeight = this.calcVideoHeight(maxCol, rows);
        if (maxHeight <= height) { return maxCol; }
        else if (minHeight <= height) { return this.predictCol(height, rows); }
        else { return 0; }
    }

    calcVideoHeight(col: number, rows: number): number {
        const { width } = this.boxSize;
        const videoWidth = width * col;
        return videoWidth * RATIO_HEIGHT * rows / RATIO_WIDTH;
    }

    predictCol(height: number, rows: number): number {
        const { width } = this.boxSize;
        const calcWidth = (height * RATIO_WIDTH * 1.0) / (RATIO_HEIGHT * rows);
        return Math.floor(calcWidth * 100 / width) / 100;
    }

    updateVideoWidth(width: number): void {
        const ratioHeightTransform = (STABLE_HEIGHT - 10) * RATIO_WIDTH * 1.0 / RATIO_HEIGHT;
        const newWidth = Math.round(ratioHeightTransform * 100 / width);
        if (this.videoWidth !== newWidth) {
            this.videoWidth = this.listRemoteParticipant.length <= 3 ?
                newWidth : Math.min(newWidth, VIDEO_MAX_WIDTH);
        }
        this.cdRef.detectChanges();
    }
    handleShowingActivatingSpeaker(): void {
        let [presenter] = this.poolService.getListParticipantIsShareScreen();
        if (presenter) {
            this.activeSpeakerService.setActiveSpeaker(presenter);
            return;
        }
        if (!this.listRemoteParticipant.length) {
            this.activeSpeakerService.setActiveSpeaker(this.localParticipantService.getLocalParticipant());
            return;
        }
        const activeSpeaker = this.activeSpeakerService.getActiveSpeaker();
        if (!activeSpeaker || (activeSpeaker instanceof LocalParticipant && activeSpeaker.isRecordUser)) {
            const firstIndexRemote = 0;
            const remote = this.listRemoteParticipant[firstIndexRemote];
            this.activeSpeakerService.setActiveSpeaker(remote);
            return;
        }
    }

    updateListRemoteParticipant(): void {
        this.totalPage = this.poolService.totalPage;
        this.currentPageIndex = this.poolService.currentPage;
        this.listRemoteParticipant = this.poolService.getDisplayedPeers();

        if(this.changePageTimeOut) {clearTimeout(this.changePageTimeOut);}
        this.changePageTimeOut = setTimeout(() => {
            this.startListWebcamConsumer(this.listRemoteParticipant);
            this.cdRef.detectChanges();
            clearTimeout(this.changePageTimeOut);
        }, 1000);
        if(this.layoutMode == 'gallery'){
            this.updateVideoGrid();
        }
    }

    // getTotalPageIndex(): number {
    //     return this.poolService.totalPage;
    // }

    stopListWebcamConsumer(remotes: RemoteParticipant[]): void {
        for (const remote of remotes) {
            const activeSpeaker = this.activeSpeakerService.getActiveSpeaker();
            if(remote.id != activeSpeaker.id) remote.stopWebcam();
        }
    }

    startListWebcamConsumer(remotes: RemoteParticipant[]): void {
        for (const remote of remotes) {
            remote.startWebcam();
        }
    }

    nextPage(): void {
        this.poolService.nextPage();
        this.pagination();
    }

    previousPage(): void {
        this.poolService.prevPage();
        this.pagination();
    }

    pagination(): void {
        // TODO: change order of start/stop, should be start first and stop later for queue reprocessing
        // Hint: save old list current with new list avoid conflict common list.
        this.currentPageIndex = this.poolService.currentPage;
        const newList = this.poolService.getDisplayedPeers();//getAllPeers();
        const stopList = [];
        this.listRemoteParticipant.forEach(remote => {
            if(newList.indexOf(remote) == -1)
            stopList.push(remote);
        })
        this.stopListWebcamConsumer(stopList);
        this.updateListRemoteParticipant();
        this.cdRef.detectChanges();
    }

    triggerScrollbar(): void {
        // const listVideo = this.listVideo.nativeElement.classList;
        // this.isScrollbarShown ?
        //     listVideo.remove('center-items') :
        //     listVideo.add('center-items');
    }
}

