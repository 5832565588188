<div #streamSector class="video-stream cringi" [ngClass]="{'speaking': isSpeaking}" >
    <div [style]="{'background' : participant.color}" class="acronym">
        <svg viewBox="0 0 40 40"  width="100%">
            <text x="50%" y="53%" dominant-baseline="middle" text-anchor="middle" fill= "white"
            stroke= "white">{{nameAcronym}}</text>
        </svg>
    </div>
    <video class="video-screen"
        [@fade]="!participant.webcam.state || !participant.webcam.isViewing || participant.webcam.isLocallyPaused ? 'disappear': 'appear'"
        #remoteVideoRef autoplay="true" muted="muted">
    </video>
    <div  [ngClass]="{ 'd-none' : !(isHoldSpaceTitle && isFullScreen)}"  class="hold-space">
        <span>
            {{ "hold_space1" | vI18n:{}:"Bạn đang tắt mic" }}
        </span>
        <br>
        <span>
            {{ "hold_space2" | vI18n:{}:"giữ phím cách để nói chuyện" }}
        </span>
      </div>

    <div class="left-icon-holder">
        <!-- <button vnpt-button vType="default" class="spotlight-box" [hidden]="!isShowSpotlight()" (click)="toggleSpotLight()"
        [ngClass]="'on-video'">
            <i class="spotlight-icon" vnpt-icon [vType]="participant.isActivatingSpeaker && spotLight ? 'PinnedSolid' : 'Pinned'"></i>
        </button> -->
        <!-- [vSize]="miniView ? 'sm' : 'xl'" -->
        <div class="raised-hand" [hidden]="!participant.isRaisedHand">
            <i class="icon-gomeet-raise-hand raise-icon" [style]="{'font-size': miniView ? '22px' : '52px'}"></i>
        </div>
    </div>

    <button vnpt-button vType="default" class="more-btn" 
            vnpt-dropdown [vDropdownMenu]="moreMenu" vTrigger="click" 
            [ngClass]="{ 'd-none' : isFullScreen, 'on-video': participant.webcam.consumerId && !participant.webcam.isLocallyPaused }"
            [vPlacement]="'bottomRight'" [(vVisible)]="dropdownVisible"
            vnpt-tooltip vTooltipTitle='{{ "morebutton.more" | vI18n:{}:"Thêm" }}'>
        <i vnpt-icon vType="MoreVertical" class="icon-white"></i>
    </button>
    <button vnpt-button vType="default" class="more-btn" 
            vTrigger="click" (click)="closeFullScreen()"
            [ngClass]="{ 'd-none' : !isFullScreen, 'on-video': participant.webcam.consumerId && !participant.webcam.isLocallyPaused }"
            title='{{ "close" | vI18n:{}:"close" }}'>
        <i vnpt-icon vType="Cancel" class="icon-white"></i>
    </button>

    <div vnpt-row vAlign="middle" class="participant-info">
        <div vnpt-row vAlign="middle" class="info-wrapper">
            <div *ngIf="participant.isPin" class="icon-container">
                <i class="icon-gomeet-pin icon-primary iconPin" style="font-size: 16px;"></i>
            </div>
            <i  vnpt-col vnpt-icon 
                *ngIf="isJoinedAudio" 
                [ngClass]="participant.audio.state ? 'icon-gomeet-state-mic icon-success': 'icon-gomeet-state-micoff icon-error'"></i>
            <!-- <span vnpt-col class="participant-name">{{participant.displayName}} {{participant.id}}</span> -->
            <span vnpt-col class="participant-name" title="{{participant.displayName}}">{{participant.displayName}}</span> 
        </div>

        <div *ngIf="score !== 10 && participant.webcam.state" class="info-wrapper">
            <i vnpt-icon vType="BarChart4" [class]="coreColor"></i>
        </div>
    
        <div [hidden]="!participant.screen.consumerId" class=" icon-container info-wrapper" style="padding: 0px;">
            <a vnpt-button vType="text" (click)="addScreenToSpotlight()">
                <i class="icon-gomeet-sharescreen icon-success"></i>
            </a>
        </div>

    <div *ngIf="(micIconVisible && isFullScreen)" vnpt-row vAlign="middle" vAlign="middle" class="participant-info2" [ngClass]="{micLocal: true}">
        <i class="micIcon" [ngClass]="micStatus ? 'icon-gomeet-state-micoff' : 'icon-gomeet-state-mic'"></i>
    </div>
    <!-- <div [hidden]="!participant.screen.consumerId" class="right-overlay" [style]="{ 'height': wrapperHeight }"
         (click)="addScreenToSpotlight()">
        
            <video [ngClass]="{ 'full-height': !followedByWidth, 'full-width': followedByWidth }"
                   #sharingScreenRef playsinline autoplay="true" muted="muted"
            ></video>
     
    </div> -->
</div>

<vnpt-dropdown-menu #moreMenu>
    <ul vnpt-menu>
        <li vnpt-menu-item (click)="pinCameraHandler()" *ngIf="((forceLayoutStatus && hasPermissionModerator) || !forceLayoutStatus ) && !isWebcamsOnlyForModerator">
            <i class="icon-gomeet-pin" ></i>
            {{participant.isPin ? ('unpin' | vI18n:{}:'Bỏ ghim') : ('pin' | vI18n:{}:'Ghim')}}
        </li>
        <li *ngIf="participant.webcam.consumerId && !participant.webcam.isLocallyPaused" vnpt-menu-item (click)="expandVideo()">
            <i vnpt-icon vType="FullView" [vSize]="'sm'"></i>
            <span>{{ "moremenu.fullscreen" | vI18n:{}:"Xem toàn màn hình" }}</span>
        </li>
        <ng-template [ngIf]="!isBlockAllAudio && this.participant.audio.state && hasPermissionModerator">
            <li vnpt-menu-item (click)="muteRemoteParticipant()">
                <i vnpt-icon class="icon-gomeet-mute" [vSize]="'sm'"></i>
                <span>{{ "moremenu.stopAudio" | vI18n:{}:"Dừng chia sẻ audio" }}</span>
            </li>
        </ng-template>

        <ng-template [ngIf]="!isBlockAllWebcam && this.participant.webcam.state && hasPermissionModerator">
            <li vnpt-menu-item (click)="stopRemoteParticipantVideo()">
                <i vnpt-icon class="icon-gomeet-video-stop" [vSize]="'sm'"></i>
                <span>{{ "moremenu.stopVideo" | vI18n:{}:"Dừng chia sẻ video" }}</span>
            </li>
        </ng-template>

        <li vnpt-menu-item (click)="stopRemoteParticipantScreenSharing()" *ngIf="hasPermissionModerator && participant.isSharing">
            <i vnpt-icon vType="LockShare" [vSize]="'sm'"></i>
            <span>{{ "moremenu.stopShareScreen" | vI18n:{}:"Dừng chia sẻ màn hình" }}</span>
        </li>

        <li vnpt-menu-item (click)="openRolesManager()" *ngIf="hasPermissionModerator">
            <i vnpt-icon vType="PagePermission" [vSize]="'sm'"></i>
            <span>{{ "moremenu.changRole" | vI18n:{}:"Thay đổi quyền" }}</span>
        </li>

        <li vnpt-menu-item (click)="kickRemoteParticipant()" 
            *ngIf="(hasPermissionModerator && !hasPermissionAdmin && !participantIsModerator) 
                        || (hasPermissionAdmin && !participantIsAdmin)">
            <i vnpt-icon vType="UserRemove" [vSize]="'sm'"></i>
            <span>{{ "moremenu.kickRoom" | vI18n:{}:"Xóa khỏi phòng họp" }}</span>
        </li>
        <li vnpt-menu-item (click)="openPrivateChat('privateChat')" [ngClass]="{'noti-dot' : participant.waitingMessage }">
            <i vnpt-icon vType="ChatInviteFriend" [vSize]="'sm'"></i>
            <span>{{ "moremenu.privateChat" | vI18n:{}:"Tin nhắn riêng" }}</span>
        </li>
    </ul>
</vnpt-dropdown-menu>


