import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RemoteRaisedHand } from '../models/reactions.model';

@Injectable({
    providedIn: 'root'
})
export class ReactionsService {
    constructor() {}

    private localRaisedHandSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private remoteRaisedHandSubject: BehaviorSubject<RemoteRaisedHand> = new BehaviorSubject<RemoteRaisedHand>(null);

    public setRemoteRaisedHand(peerId: string, raisedHand: boolean, timestamp: number): void {
        this.remoteRaisedHandSubject.next({ peerId, raisedHand, timestamp });
    }

    public onRemoteRaisedHand(): Observable<RemoteRaisedHand> {
        return this.remoteRaisedHandSubject.asObservable();
    }

    public setLocalRaisedHand(raisedHand: boolean): void {
        this.localRaisedHandSubject.next(raisedHand);
    }

    public onLocalRaisedHand(): Observable<boolean> {
        return this.localRaisedHandSubject.asObservable();
    }
}
