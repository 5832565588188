import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {MediasoupService} from '../../services/mediasoup.service';
import {ConsumerService} from '../../services/consumer.service';
import {ActiveSpeakerService} from '../../services/active-speaker.service';
import {RemoteParticipant} from '../../models/remote-participant.model';
import {VIDEO_COLUMN_SIZE_FORMAT} from '../../constants';

@Component({
  selector: 'lib-searching-participant',
  templateUrl: './searching-participant.component.html',
  styleUrls: ['./searching-participant.component.css']
})
export class SearchingParticipantComponent implements OnInit, OnDestroy {

  constructor(
      private mediasoupService: MediasoupService,
      private consumerService: ConsumerService,
      private activeSpeakerService: ActiveSpeakerService,
      private cdRef: ChangeDetectorRef
  ) { }

  isEmpty = false;
  currentPageIndex = 1;
  listRemoteParticipant: RemoteParticipant[] = [];
  listCurrentRemoteParticipant: RemoteParticipant[] = [];
  videoColumnSize = VIDEO_COLUMN_SIZE_FORMAT.ONE_COLUMN;
  defaultRemoteParticipantPageSize = 0;

  ngOnInit(): void {
    // this.currentPageIndex = this.listRemoteParticipantService.getCurrentPageIndex();
    // this.defaultRemoteParticipantPageSize = this.listRemoteParticipantService.pageSize;
    this.onInitEvent();
  }

  ngOnDestroy(): void {
    this.stopListWebcamConsumer(this.listCurrentRemoteParticipant);
  }

  onInitEvent(): void{
    // this.listRemoteParticipantService.onSearchingParticipant().subscribe((listRemoteParticipant: RemoteParticipantModel[]) => {
    //   this.listRemoteParticipant = listRemoteParticipant;
    //   this.updateListCurrentRemoteParticipant();
    //   this.startListWebcamConsumer(this.listCurrentRemoteParticipant);
    //   const participantNumber = this.listRemoteParticipant.length;
    //   this.isEmpty = participantNumber ? false : true;
    //   this.updateVideoGrid(participantNumber);
    // });
  }

  updateListCurrentRemoteParticipant(): void {
    const startIndex = (this.currentPageIndex - 1) * this.defaultRemoteParticipantPageSize;
    const stopIndex = this.currentPageIndex * this.defaultRemoteParticipantPageSize;
    this.listCurrentRemoteParticipant = this.listRemoteParticipant.slice(startIndex, stopIndex);
    if (!this.listCurrentRemoteParticipant.length && this.currentPageIndex > 1) {
      this.currentPageIndex -= 1;
      this.updateListCurrentRemoteParticipant();
    }
    // this.listRemoteParticipantService.setCurrentPageIndex(this.currentPageIndex);
    this.cdRef.detectChanges();
  }


  updateVideoGrid(remoteParticipantLength: number): void{
    switch (remoteParticipantLength){
      case 1:
        this.videoColumnSize = VIDEO_COLUMN_SIZE_FORMAT.ONE_COLUMN;
        break;
      case 2:
      case 3:
      case 4:
        this.videoColumnSize = VIDEO_COLUMN_SIZE_FORMAT.TWO_COLUMN;
        break;
      default:
        this.videoColumnSize = VIDEO_COLUMN_SIZE_FORMAT.THREE_COLUMN;
        break;
    }
  }

  stopListWebcamConsumer(remotes: RemoteParticipant[]): void{
    // for (const remote of remotes){
    //   remote.stopWebcamSearching();
    //   this.listRemoteParticipantService.removeRemoteContainWebcamStarted(remote.id);
    // }
  }

  startListWebcamConsumer(remotes: RemoteParticipant[]): void{
    // for (const remote of remotes){
    //   remote.startWebcamSearching();
    //   this.listRemoteParticipantService.addRemoteContainWebcamStarted(remote.id);
    // }
  }


  getTotalPageIndex(): number {
    const totalPageIndex = Math.ceil((this.listRemoteParticipant.length) / this.defaultRemoteParticipantPageSize);
    return totalPageIndex ? totalPageIndex : 1;
  }

  nextPage(): void {
    this.currentPageIndex += 1;
    this.pagination();
  }

  previousPage(): void {
    this.currentPageIndex -= 1;
    this.pagination();
  }

  pagination(): void{
    // TODO: change order of start/stop, should be start first and stop later for queue reprocessing
    // Hint: save old list current with new list avoid conflict common list.
    this.stopListWebcamConsumer(this.listCurrentRemoteParticipant);
    this.updateListCurrentRemoteParticipant();
    this.startListWebcamConsumer(this.listCurrentRemoteParticipant);
  }

}
