import { Participant } from './participant.model';

export class LocalParticipant extends Participant {
    color = "#" +  (Math.floor(Math.random() * (10733941 - 2040100 + 1)) + 2040100).toString(16);
    kind = 'local';
    audio = {
        state: false,
        producerId: ''
    };
    webcam = {
        state: false,
        producerId: ''
    };
    screen = {
        state: false,
        producerId: ''
    };
    myGroupId = '';
    isRecordUser = false;
}
