import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { VGridModule } from '@vnpt/oneui-ui/grid';
import { VButtonModule } from '@vnpt/oneui-ui/button';
import { VIconModule } from '@vnpt/oneui-ui/icon';
import { VDropDownModule } from '@vnpt/oneui-ui/dropdown';
import { VLayoutModule } from '@vnpt/oneui-ui/layout';
import { VTypographyModule } from '@vnpt/oneui-ui/typography';
import { VInputModule } from '@vnpt/oneui-ui/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VSelectModule } from '@vnpt/oneui-ui/select';
import { VDatePickerModule } from '@vnpt/oneui-ui/date-picker';
import { VTabsModule } from '@vnpt/oneui-ui/tabs';
import { VTableModule } from '@vnpt/oneui-ui/table';
import { VEmptyModule } from '@vnpt/oneui-ui/empty';
import { VBadgeModule } from '@vnpt/oneui-ui/badge';
import { VToolTipModule } from '@vnpt/oneui-ui/tooltip';
import { VDrawerModule } from '@vnpt/oneui-ui/drawer';
import { VFormModule } from '@vnpt/oneui-ui/form';
import { VCheckboxModule } from '@vnpt/oneui-ui/checkbox';
import { VModalModule } from '@vnpt/oneui-ui/modal';
import { VDescriptionsModule } from '@vnpt/oneui-ui/descriptions';
import { VCardModule } from '@vnpt/oneui-ui/card';
import { VAffixModule } from '@vnpt/oneui-ui/affix';
import { VRadioModule } from '@vnpt/oneui-ui/radio';
import { VDividerModule } from '@vnpt/oneui-ui/divider';
import { VTagModule } from '@vnpt/oneui-ui/tag';
import { VSpaceModule } from '@vnpt/oneui-ui/space';
import { VSpinModule } from '@vnpt/oneui-ui/spin';
import { VAlertModule } from '@vnpt/oneui-ui/alert';
import { VListModule } from '@vnpt/oneui-ui/list';
import { VAvatarModule } from '@vnpt/oneui-ui/avatar';
import { VPopconfirmModule } from '@vnpt/oneui-ui/popconfirm';
import { VPopoverModule } from '@vnpt/oneui-ui/popover';
import { VSwitchModule } from '@vnpt/oneui-ui/switch';
import { VProgressModule } from '@vnpt/oneui-ui/progress';
import { VUploadModule } from '@vnpt/oneui-ui/upload';
import { VPageHeaderModule } from '@vnpt/oneui-ui/page-header';
import { VStatisticModule } from '@vnpt/oneui-ui/statistic';
import { VInputNumberModule } from '@vnpt/oneui-ui/input-number';
import { VTimePickerModule } from '@vnpt/oneui-ui/time-picker';
import { VResultModule } from '@vnpt/oneui-ui/result';
import { VSliderModule } from '@vnpt/oneui-ui/slider';
import { VLogoModule } from '@vnpt/oneui-ui/logo';
import { VImageModule } from '@vnpt/oneui-ui/image';
import { I18N_CONFIG, oneuiI18nConfig, OneuiI18nModule } from '@vnpt/oneui-i18n';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
@NgModule({
    declarations: [],
    imports: [OneuiI18nModule],
    exports: [
        VGridModule,
        VButtonModule,
        VIconModule,
        VDropDownModule,
        VLayoutModule,
        VTypographyModule,
        VInputModule,
        FormsModule,
        VSelectModule,
        VDatePickerModule,
        VTabsModule,
        VTableModule,
        VEmptyModule,
        VBadgeModule,
        VToolTipModule,
        VDrawerModule,
        VFormModule,
        ReactiveFormsModule,
        VCheckboxModule,
        VModalModule,
        VDescriptionsModule,
        VCardModule,
        VAffixModule,
        VRadioModule,
        VDividerModule,
        VTagModule,
        VSpaceModule,
        VSpinModule,
        VAlertModule,
        VListModule,
        VAvatarModule,
        VPopconfirmModule,
        VPopoverModule,
        VSwitchModule,
        VProgressModule,
        VUploadModule,
        VPageHeaderModule,
        VStatisticModule,
        VInputNumberModule,
        VTimePickerModule,
        VResultModule,
        VSliderModule,
        VLogoModule,
        VImageModule,
        OneuiI18nModule,
        PickerModule
    ],
    providers: [{
        provide: I18N_CONFIG,
        useValue: oneuiI18nConfig({
            availableLangs: ['en', 'vi'],
            defaultLang: 'vi'
        }),
    }],
})
export class SharedModuleRealtimeCommunication {
}
