/* tslint:disable:no-output-on-prefix prefer-const variable-name adjacent-overload-signatures */
import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnInit,
    Renderer2,
    ViewChild,
    OnDestroy, ChangeDetectorRef
} from '@angular/core';
import { createFadeAnimation } from '../../animation';
import {Subscription} from 'rxjs';
import {RemoteParticipant} from '../../models/remote-participant.model';
import {
    MediasoupService,
    SocketService,
    ShareDataService,
    MediaStreamService,
    ConsumerService
} from '../../services';
import { VIDEO_FADE_DURATION_WITH_NO_GLITCH, VIDEO_FADE_DURATION_WITH_NO_GLITCH_DELAY } from '../../constants';
import { PoolService } from '../../services/mediasoup/pool.service';

@Component({
    selector: 'lib-break-remote-streaming',
    templateUrl: './break-remote-streaming..component.html',
    styleUrls: ['./break-remote-streaming..component.less'],
    animations: [ createFadeAnimation(VIDEO_FADE_DURATION_WITH_NO_GLITCH, VIDEO_FADE_DURATION_WITH_NO_GLITCH_DELAY) ]
})
export class BreakRemoteStreamingComponent implements OnInit, AfterViewInit, OnDestroy {
    isShowOption = false;
    dropdownVisible = false;
    isOnBreakroom = false;
    isSwapped = false;
    _webcamConsumerId = '';
    _participantId = '';
    _screenConsumerID = '';
    participant: RemoteParticipant;
    subscriptions: Subscription[] = [];
    score = 10;
    coreColor: string = "";
    screenObserver: ResizeObserver;
    wrapperHeight = 'unset';
    followedByWidth = true;
    @Input() miniView = true;
    @Input() isTeacher = false;
    @Input() set participantId(id: string) {
        if (id) {
            this._participantId = id;
            this.updateViewingParticipant();
        }
    }

    get participantId(): string{
        return this._participantId;
    }
    get webcamConsumerId(): string {
        return this._webcamConsumerId;
    }
    get screenConsumerID(): string {
        return this._screenConsumerID;
    }
    @ViewChild('remoteVideoRef') remoteVideoRef: ElementRef;
    @ViewChild('sharingScreenRef') sharingScreenRef: ElementRef;

    constructor(
        private poolService: PoolService,
        private mediasoupService: MediasoupService,
        private socketService: SocketService,
        private renderer: Renderer2,
        private cdRef: ChangeDetectorRef,
        private shareDataService: ShareDataService,
        private mediaStreamService: MediaStreamService,
        private consumerService: ConsumerService
    ) {
        this.participant = new RemoteParticipant({}, this.consumerService, this.mediasoupService);
    }

    ngOnInit(): void {
        this.onInitEvent();
    }

    ngAfterViewInit(): void {
        const remote = this.remoteVideoRef;
        this.renderer.setAttribute(remote.nativeElement, 'background-color', 'black');

        if (this.participant.webcam.consumerId) {
            this.updateWebcamConsumer();
        }
        if (this.participant.screen.consumerId) {
            this.updateShareScreenConsumer();
        }

        if (remote && remote.nativeElement) {
            remote.nativeElement.muted = true;
        }
    }

    ngOnDestroy(): void {
        for (const subs of this.subscriptions) {
            subs.unsubscribe();
        }
        if (this.screenObserver) { this.screenObserver.disconnect(); }
    }

    onInitEvent(): void{
        // @ts-ignore
        this.screenObserver = new ResizeObserver((entries: any) => {
            const { height } = entries[0].contentRect;
            this.wrapperHeight = `${height}px`;
            this.cdRef.detectChanges();
        });

        this.subscriptions.push(this.shareDataService.onUpdateStreamingParticipant().subscribe((peerId: string) => {
            if (peerId === this.participant.id) { this.updateViewingParticipant(); }
        }));

        this.subscriptions.push(this.mediasoupService.onUpdateRemoteVideoScore().subscribe(data => {
            if (!data) { return; }
            const { consumerId, score } = data;
            if (!consumerId || consumerId !== this.participant.webcam.consumerId || !score) { return; }
            this.score = score;
            this.coreColor = this.getScoreColor();
            this.cdRef.detectChanges();
        }));

        this.subscriptions.push(this.mediaStreamService.onUpdateStream().subscribe(stream => {
            if (stream && stream.peerId === this.participant.id) {
                const {kind, mediaStream} = stream;
                this.updateViewingParticipant(kind, mediaStream);
            }
        }));
    }

    updateViewingParticipant(kind?: 'audio' | 'webcam' | 'screen' | 'audioscreen', stream?: MediaStream): void{
        const remoteService = this.poolService;
        let participant = remoteService.getPeer(this.participantId);

        // const isWebcamConsumerClosed = remoteService.isRemoteContainWebcamClosed(this.participantId);
        // if (isWebcamConsumerClosed){
        //     this.socketService.createNewVideoConsumer(this.participantId);
        //     remoteService.removeRemoteContainWebcamClosed(this.participantId);
        // }

        this.participant.destroyIntervalStatusStreaming();
        this.participant = participant;
        if (this.participant.webcam.consumerId){
            if (kind && kind === 'webcam') { this.updateWebcamConsumer(stream); }
            else { this.updateWebcamConsumer(); }
        }

        if (this.participant.screen.consumerId){
            if (kind && kind === 'screen') { this.updateShareScreenConsumer(stream); }
            else { this.updateShareScreenConsumer(); }
        }

        // this.checkingStatusVideo();
    }

    updateWebcamConsumer(stream?: MediaStream): void {
        const remote = this.remoteVideoRef;
        if (remote && remote.nativeElement) {
            this.playVideo(stream);
        }
    }

    updateShareScreenConsumer(stream?: MediaStream): void {
        if (!stream) { stream = this.mediaStreamService.getMediaStream(this.participant.id, 'screen'); }
        if (!stream || !this.sharingScreenRef){ return; }
        const ref = this.isSwapped ? this.remoteVideoRef.nativeElement : this.sharingScreenRef.nativeElement;
        ref.srcObject = stream;
        if (stream.getVideoTracks().length) {
            if (this.isSwapped) {
                this.wrapperHeight = 'unset';
                if (this.screenObserver) { this.screenObserver.disconnect(); }
            } else {
                const { width, height } = stream.getVideoTracks()[0].getSettings();
                if (width < height) { this.followedByWidth = false; }
                this.screenObserver.observe(this.sharingScreenRef.nativeElement);
            }
        } else {
            if (this.screenObserver) { this.screenObserver.disconnect(); }
            if (this.isOnBreakroom && this.isSwapped) {
                this.isSwapped = false;
                const webcamStream = this.sharingScreenRef.nativeElement.srcObject;
                this.remoteVideoRef.nativeElement.srcObject = webcamStream;
            }
        }
    }

    showOptions(isShown: boolean): void {
        this.dropdownVisible ? this.isShowOption = true : this.isShowOption = isShown;
    }

    expandVideo(): void {
        if (this.remoteVideoRef && this.remoteVideoRef.nativeElement.requestFullscreen) {
            this.remoteVideoRef.nativeElement.requestFullscreen();
        }
    }

    expandShareScreen(): void {
        this.isOnBreakroom = this.isTeacher;
        this.isSwapped = !this.isSwapped;
        const screen = this.sharingScreenRef.nativeElement;
        const webcam = this.remoteVideoRef.nativeElement;
        const screenStream = screen.srcObject;
        const webcamStream = webcam.srcObject;
        screen.srcObject = webcamStream;
        webcam.srcObject = screenStream;
        this.wrapperHeight = 'unset';
        if (this.screenObserver) { this.screenObserver.disconnect(); }
    }

    playVideo(stream?: MediaStream): void {
        let cameraTrack: MediaStream;
        cameraTrack = this.mediaStreamService.getMediaStream(this.participant.id, 'webcam');
        if (stream) { cameraTrack = stream; }
        if (!cameraTrack) { return; }
        let video = this.isSwapped ? this.sharingScreenRef.nativeElement : this.remoteVideoRef.nativeElement;
        video.removeAttribute('srcObject');
        video.srcObject = cameraTrack;
        video.pause();
        video.currentTime = 0;
        video.oncanplay = () => {
            video.play();
        };
        video.load();
    }

    // private checkingStatusVideo(): void {
    //     let isResetVideoConsumer = false;
    //     this.subscriptions.push(this.participant.onStatusStreamingVideo()
    //         .subscribe(async (status) => {
    //             if (this.remoteVideoRef) {
    //                 if (status === 1 && this.remoteVideoRef.nativeElement.paused ) {
    //                     isResetVideoConsumer = true;
    //                     const {videoConsumerMap} =  await this.mediasoupService.requestPeerVideoConsumer(this.participant.id);
    //                     if (videoConsumerMap && videoConsumerMap.isHaveWebcam) {
    //                         await this.mediasoupService.emitServerCloseConsumer(this.participant.webcam.consumerId);
    //                         await this.mediasoupService.createNewVideoConsumer(this.participant.id);
    //                     }
    //                     isResetVideoConsumer = false;
    //                 } else if (this.remoteVideoRef.nativeElement?.played?.length === 0 && !isResetVideoConsumer) {
    //                     isResetVideoConsumer = true;
    //                     const {videoConsumerMap} =  await this.mediasoupService.requestPeerVideoConsumer(this.participant.id);
    //                     if (videoConsumerMap && videoConsumerMap.isHaveWebcam) {
    //                         await this.mediasoupService.emitServerCloseConsumer(this.participant.webcam.consumerId);
    //                         await this.mediasoupService.createNewVideoConsumer(this.participant.id);
    //                     }
    //                     isResetVideoConsumer = false;
    //                 } else if (this.remoteVideoRef.nativeElement.played && this.remoteVideoRef.nativeElement.currentTime === 0 ) {
    //                     isResetVideoConsumer = true;
    //                     const {videoConsumerMap} =  await this.mediasoupService.requestPeerVideoConsumer(this.participant.id);
    //                     if (videoConsumerMap && videoConsumerMap.isHaveWebcam) {
    //                         await this.mediasoupService.emitServerCloseConsumer(this.participant.webcam.consumerId);
    //                         await this.mediasoupService.createNewVideoConsumer(this.participant.id);
    //                     }
    //                     isResetVideoConsumer = false;
    //                 }
    //             }

    //         }));
    // }

    toggleRemoteVideo(): void {
        if (!this.participant.webcam.consumerId) { return; }
        const status = this.participant.getLocallyPaused('webcam');
        this.mediasoupService.modifyPeerConsumer(this.participant.id, 'webcam', !status);
    }

    toggleRemoteAudio(): void {
        if (!this.participant.audio.consumerId) { return; }
        const status = this.participant.getLocallyPaused('audio');
        this.mediasoupService.modifyPeerConsumer(this.participant.id, 'mic', !status);
    }

    getScoreColor(): string {
        switch (this.score) {
            case 0:
            case 1:
            case 2:
            case 3:
                return 'icon-error';
            case 4:
            case 5:
            case 6:
                return 'icon-orange';
            case 7:
            case 8:
            case 9:
                return 'icon-warning';
            case 10:
                return '';
        }
    }
}
