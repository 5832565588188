/* tslint:disable:one-variable-per-declaration no-debugger */
import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    Input
} from '@angular/core';
import {RemoteParticipant} from '../../../models/remote-participant.model';
import {ActiveSpeakerService, LocalParticipantService, RoomService, SettingsService, ShareDataService} from '../../../services';
import {Subscription} from 'rxjs';
import { LocalParticipant } from '../../../models/local-participant.model';
import { PoolService } from '../../../services/mediasoup/pool.service';
import {LAYOUT_MODE} from '../../../constants';
import { LayoutModel } from '../../../models/layout.model';
const CAMERA_NUMBER_LAYOUT_71 = 6;
const CAMERA_NUMBER_LAYOUT_121 = 11;
@Component({
    selector: 'lib-polycom-view',
    templateUrl: './polycom.component.html',
    styleUrls: ['./polycom.component.less']
})


export class PolycomViewComponent implements OnInit, OnDestroy {

    constructor(
        private localParticipantService: LocalParticipantService,
        private poolService: PoolService,
        private activeSpeakerService: ActiveSpeakerService,
        private cdRef: ChangeDetectorRef,
        private shareDataService: ShareDataService,
        private settingService: SettingsService,
        private roomService: RoomService
    ) {
    }

    // Component data
    listRemoteParticipant: RemoteParticipant[] = [];
    // Component view
    isRecordUser: boolean =this.localParticipantService.getIsRecordUser();
    subscriptions: Subscription[] = [];
    changePageTimeOut: any;
    @Input() layoutMode = "polycom-71";
    miniView =  false;
    arrayLayout71 = new Array(6);
    arrayLayout121 = new Array(11);
    cameraNumber = CAMERA_NUMBER_LAYOUT_121;
    forceLayoutStatus = false;
    @Input() isFullScreen = false;
    ngOnInit(): void {
        this.updateListRemoteParticipant();
        this.handleShowingActivatingSpeaker();
        this.onInitEvent();
    }

    ngOnDestroy(): void {
        for (const subs of this.subscriptions) {
            subs.unsubscribe();
        }
    }

    onInitEvent(): void {
        this.subscriptions.push(this.roomService.onForceLayoutObservable().subscribe((layoutInfo: LayoutModel)=>{
            // this.forceLayoutStatus = layoutInfo.layout != 0 ? true : false;
            if(layoutInfo.layout != 0) {
                this.forceLayoutStatus = true;
            }
            else {
                this.forceLayoutStatus = false;
            }
        }));

        // this.subscriptions.push(this.poolService.getNonCamViewObserver().subscribe((flag: boolean) => {
        //     if(flag) {
        //         this.updateListRemoteParticipant();
        //     }
        // }))

        this.subscriptions.push(this.poolService.getNonCamViewObserver().subscribe((flag: boolean) => {     
            // this.poolService.gotoPage(1);                   
            this.pagination();     
        }));

        //cap nhat lai danh sach view neu co thay doi
        this.poolService.onUpdateListRemoteParticipantInfo.asObservable().subscribe((flag: boolean) =>{
            if (flag) { this.updateListRemoteParticipant(); }
        });

        this.subscriptions.push(this.shareDataService.onInitJoinData().subscribe((onInit: boolean) => {
            if(onInit) this.updateListRemoteParticipant();
        }));

        this.subscriptions.push(this.poolService.onUpdateCurrentPage.asObservable().subscribe((flag: boolean) => {
            if (flag) {
                this.updateListRemoteParticipant();
                this.handleShowingActivatingSpeaker();
            }
        }));


        this.subscriptions.push(this.settingService.onChangeLayoutMode().subscribe((mode: LAYOUT_MODE) => {
            if (mode == 'polycom-71') {
                this.cameraNumber = CAMERA_NUMBER_LAYOUT_71;
            } else if (mode == 'polycom-121') {
                this.cameraNumber = CAMERA_NUMBER_LAYOUT_121;
            }
            if (!this.forceLayoutStatus) this.poolService.changePinWithPagination();
            this.listRemoteParticipant = this.poolService.getDisplayedPeers(1, this.cameraNumber);
        }));

        this.subscriptions.push(this.poolService.onRemotesPin().subscribe((list: [])=> {               
            this.pagination();
            this.handleShowingActivatingSpeaker();
        }))
    }

    handleShowingActivatingSpeaker(): void {
        let [presenter] = this.poolService.getListParticipantIsShareScreen();
        if (presenter) {
            this.activeSpeakerService.setActiveSpeaker(presenter);
            return;
        }
        if (!this.listRemoteParticipant.length) {
            this.activeSpeakerService.setActiveSpeaker(this.localParticipantService.getLocalParticipant());
            return;
        }
        const activeSpeaker = this.activeSpeakerService.getActiveSpeaker();
        if (!activeSpeaker || (activeSpeaker instanceof LocalParticipant && activeSpeaker.isRecordUser)) {
            const firstIndexRemote = 0;
            const remote = this.listRemoteParticipant[firstIndexRemote];
            this.activeSpeakerService.setActiveSpeaker(remote);
            return;
        }
    }

    updateListRemoteParticipant(): void {
        this.listRemoteParticipant = this.poolService.getDisplayedPeers(1, this.cameraNumber);
        if(this.changePageTimeOut) {clearTimeout(this.changePageTimeOut);}
        this.changePageTimeOut = setTimeout(()=>{
        this.startListWebcamConsumer(this.listRemoteParticipant);
        this.cdRef.detectChanges();
        clearTimeout(this.changePageTimeOut);
        }, 1000);
    }

    getTotalPageIndex(): number {
        return this.poolService.totalPage;
    }

    stopListWebcamConsumer(remotes: RemoteParticipant[]): void {
        for (const remote of remotes) {
            if(remote){
                const activeSpeaker = this.activeSpeakerService.getActiveSpeaker();
                if(remote.id != activeSpeaker.id) remote.stopWebcam();
            }

        }
    }

    startListWebcamConsumer(remotes: RemoteParticipant[]): void {
        for (const remote of remotes) {
            if(remote) remote.startWebcam();
        }
    }

    pagination(): void {
        // TODO: change order of start/stop, should be start first and stop later for queue reprocessing
        // Hint: save old list current with new list avoid conflict common list.
        const newList = this.poolService.getDisplayedPeers(1, this.cameraNumber);
        const stopList = [];
        this.listRemoteParticipant.forEach(remote => {
            if(newList.indexOf(remote) == -1)
            stopList.push(remote);
        })
        this.stopListWebcamConsumer(stopList);
        this.updateListRemoteParticipant();
        this.cdRef.detectChanges();
    }
}

