import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Subscription } from "rxjs";
import { PERMISSIONS } from "../../constants";
import { Lobby } from "../../models/lobby.model";
import { RemoteParticipant } from "../../models/remote-participant.model";
import {
  LobbyService,
  LocalParticipantService,
  MediasoupService,
  RoomService,
  SecurityService,
  SettingsService,
} from "../../services";
import { PoolService } from "../../services/mediasoup/pool.service";

@Component({
  selector: "lib-lobby-v2",
  templateUrl: "./lobby-v2.component.html",
  styleUrls: ["./lobby-v2.component.less"],
})
export class LobbyV2Component implements OnInit, OnDestroy {
  maxParticipant = 0;
  subscriptions: Subscription[] = [];
  lobbyList: Map<string, Lobby>;
  acceptDisable: boolean = true;
  acceptAllDisable: boolean = true;
  listRemoteParticipant: RemoteParticipant[];
  lengthList = 0;
  isLock = false;
  showWho: any;
  checkEvent = false;
  checkRejectAllPeersEvent = false;
  checkPromoteAllPeersEvent = false;
  // @Output() triggerRightContainer = new EventEmitter();
  constructor(
    private settingService: SettingsService,
    private lobbyService: LobbyService,
    private mediasoupService: MediasoupService,
    private roomService: RoomService,
    private poolService : PoolService,
    private localParticipantService: LocalParticipantService, 
    private securityService: SecurityService
  ) {}

  ngOnInit(): void {
    this.maxParticipant = this.settingService.getMaxParticipants();
    this.subscriptions.push(this.securityService.onProcessingLockRoom().subscribe((flag)=> {
      this.checkEvent = flag;
    }));

    this.subscriptions.push(this.securityService.onProcessingPromoteAllPeers().subscribe((flag)=>{
      this.checkPromoteAllPeersEvent = flag;
    }));

    this.subscriptions.push(this.securityService.onProcessingRejectAllPeers().subscribe((flag)=>{
      this.checkRejectAllPeersEvent = flag;
    }));
    this.subscriptions.push(
      this.lobbyService.onChangeLobbyList().subscribe((isUpdate: boolean) => {
        this.lobbyList = this.lobbyService.getLobbyList();
        if (this.lobbyList.size == 0 && this.roomService.rightPaneView == "lobby") {
          this.goBack();
        }
      
        this.poolService.onUpdateListRemoteParticipantInfo.asObservable()
          .subscribe((flag: boolean) => {
            this.listRemoteParticipant = this.poolService.getAllPeers();
            this.lengthList = this.listRemoteParticipant.length + 1;
            if (this.maxParticipant != 0) {
              const slot =
                this.maxParticipant - this.listRemoteParticipant.length - 1;
              if (this.lobbyList.size <= slot) {
                this.acceptDisable = false;
                this.acceptAllDisable = false;
              } else {
                this.acceptAllDisable = true;
                // this.acceptDisable = slot == 0 ? true : false;
                if(slot == 0)
                  this.acceptDisable = true;
                else
                  this.acceptDisable = false;
              }
            } else {
              this.acceptDisable = false;
              this.acceptAllDisable = false;
            }
          });
          
          this.localParticipantService.onRoles().subscribe((roles: number[]) => {      
            if(!roles.includes(PERMISSIONS.MODERATOR.id) && this.roomService.rightPaneView == "lobby"){
              this.goBack();
          }});

          this.subscriptions.push(this.securityService.onLockRoom().subscribe((isLock: boolean) => {
            //Bắt trạng thái khóa khi bấm nút khóa/mở phòng
            this.isLock = isLock;
          }));
      })
    );

  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  promoteLobby(peerId): void {
    this.roomService.promotePeer(peerId);
  }

  promoteAllLobby(): void {
    this.roomService.promoteAllPeers();
  }

  rejectLobby(peerId): void {
    this.roomService.rejectPeer(peerId);
  }

  rejectAllLobby(): void {
    this.roomService.rejectAllPeers();
  }

  trackByKey(index: number, item) {
    return item.key;
  }
  
  goBack(): void {
    this.roomService.rightPaneView = "listParticipant";
  }
  
  lockRoom(event): void {
    event.preventDefault();
    // event.currentTarget.checked = false;
    if (this.isLock) {
      this.roomService.unLockRoom();
    }
    else {
      this.roomService.lockRoom();
    }
  }

  triggerVisibleQuickActionChange(value, flag): void {
    if(flag) {
      this.showWho = value;
    }
    else {
      this.showWho = null;
    }
  }
}
