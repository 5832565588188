<div>
  <ng-template [ngIf]="loading">
    <video [style]="{'z-index' : '2', width: 'auto', height:'240px'}" class="video-screen" 
      [ngClass]="{'d-none': !haveStream, 'full-height': !followedByWidth, 'full-width': followedByWidth, 'loading':loading}" autoplay playsinline >
    </video>
  </ng-template>

  <video [style]="{'z-index' : '2', width: 'auto', height:'240px'}" class="video-screen" [hidden]="loading"
    [ngClass]="{'d-none': !haveStream,  'full-height': !followedByWidth, 'full-width': followedByWidth}"
    #localVideoRef autoplay playsinline>
  </video>
  <img src="assets/block.svg" [style]="{'z-index' : '2', width: 'auto', height:'240px'}"
   class="video-screen" 
    [ngClass]="{'d-none': haveStream, 'full-height': !followedByWidth, 'full-width': followedByWidth}"/>
    <!-- <div class="list-item" (click)="triggerOpenVirtualBackground()">
        <i vnpt-icon vType="Photo2" [vSize]="'sm'"></i>
        <span>Phông nền ảo</span>
    </div> -->
    <span vI18n="settings.video.virtualbackground.label">Phông nền ảo</span>
    <div *ngIf="!isVisible" class="BgList label-color">
      <span>{{'settings.video.virtualbackground.no-support.label' | vI18n:{}:'Trình duyệt chưa hỗ trợ'}}</span>
    </div>
    <div *ngIf="isVisible" class="BgList" [style]="{'pointer-events': loading || !haveStream ? 'none': '', 'opacity': loading || !haveStream ? '0.7' : '1'}">
        <div (click)="setBg(null)" [class]="(!selectedbg) ? 'screenItem2' : ['screenItem','screenItem3']" >
            <img src="assets/block.svg"   class="imageScreenDefault"/>
            <!-- <div class= "imageScreen" style="background-color: white" ></div> -->
        </div>
        <div *ngFor="let bg of backgroundList" (click)="setBgV2(bg)" [class]="(selectedbg && bg == selectedbg) ? 'screenItem2' : 'screenItem'">
            <img id="bg"  class="imageScreen" [src]="bg" alt="" />
        </div>

        <div (click)="setBgV2(temp)" [class]="(selectedbg && temp == selectedbg) ? 'screenItem2' : 'screenItem'" [hidden]="temp ? false : true">
            <img class="imageScreen"  [src]="temp ? domSanitizer.bypassSecurityTrustUrl(temp) : ''"  />
        </div>
        <div (click)="fileInput.click()" [class]="['screenItem','screenItem3']" >
            <!-- <div class= "imageScreen" > -->
                <img src="assets/black-upload-folder-11468.svg"   class="imageScreenDefault"/>
                <span class="spanText"> Upload</span>
                <input hidden #fileInput class= "imageScreen" type="file" accept=".png,.jpeg,.jpg" (change)="addImgSrc($event)">
            <!-- </div> -->
        </div>
    </div>
    <!-- <div class="submit-button" >
  
    <button vnpt-button type="submit" vI18n="settings.video.btn_save" vType="primary"  (click)="handler()">Lưu</button>    
  </div>        -->
  <div ngClass="rowLabel" style= "margin-top: 10px;">
    <label style="align-self: center;">Camera</label>
    <label class="device-menu" vnpt-button vnpt-popover vPopoverTrigger="click" 
    [(vPopoverVisible)]="visibility.listVideo"
    (click) = "pooper($event, visibility.listVideo, 'listVideo')"
    vPopoverTrigger="!visibility.listVideo ? 'click' : null"
    [vPopoverContent]="videoDeviceTemplates"
    [style]="{'pointer-events': loading || !haveStream ? 'none': '', 'opacity': !haveStream ? '0.7' : '1'}">
      <span>{{!labelVideo || labelVideo.label == '' || labelVideo.deviceId == 'default' || !haveStream ? '' : labelVideo.label}}</span>
      <a   [style.border]="'0px'" style="width: auto;">
        <i vnpt-icon class="icon" vType="ChevronUp" ngClass="iconCustom">
        </i>
      </a>
    </label>
  </div>

  <div ngClass="rowLabel" style= "margin-top: 10px;">
    <label style="align-self: center;">{{'settings.video.quality_send' | vI18n:{}:'Độ phân giải tối đa khi gửi'}}</label>
    <label class="device-menu" vnpt-button vnpt-popover vPopoverTrigger="click"
    [(vPopoverVisible)]="visibility.listQuality"
    (click) = "pooper($event, visibility.listQuality, 'listQuality')"
    vPopoverTrigger="!visibility.listQuality ? 'click' : null"
    [vPopoverContent]="videoDeviceTemplates2"
    [style]="{'pointer-events': loading || !haveStream ? 'none': '', 'opacity': !haveStream ? '0.7' : '1'}"
    >
      <span>{{!labelQuality ? ('settings.devices.default' | vI18n:{}:'Mặc định hệ thống') : labelQuality}}</span>
      <a   [style.border]="'0px'" style="width: auto;">
        <i vnpt-icon class="icon" vType="ChevronUp" ngClass="iconCustom">
        </i>
      </a>
    </label>
  </div>

  <div vnpt-row vAlign="middle" vJustify="space-between" class="switch">
    <span vI18n="settings.general.paging_camera">Phân trang camera:</span>
    <vnpt-select class="select-button" vDropdownClassName="select-button" [(ngModel)]="pagination" (ngModelChange)="changePagination(pagination)" [disabled]="pagingCameraDisable">
        <vnpt-option vValue="1" vLabel="2"></vnpt-option>
        <vnpt-option vValue="3" vLabel="4"></vnpt-option>
        <vnpt-option vValue="5" vLabel="6"></vnpt-option>
        <vnpt-option vValue="11" vLabel="12"></vnpt-option>
        <vnpt-option vValue="23" vLabel="24"></vnpt-option>
    </vnpt-select>
  </div>
  <div vnpt-row vAlign="middle" vJustify="space-between" class="switch">
    <span>{{ "header.changelayout.nonCamera" | vI18n:{}:"Ẩn người dùng không mở camera" }}</span>
    <label class="switch2">
      <input type="checkbox" [checked]="nonCam" (change) = "nonCamera()">
      <span class="slider round"></span>
    </label>
  </div>
</div>
<ng-template #videoDeviceTemplates>
  <lib-video-device [changeTemplate]="true" (changeIsLock)= "changeIsLockSecondFloor($event)"
  (pooper)="pooper($event, visibility.listVideo, 'listVideo')"
  ></lib-video-device>
</ng-template>
<ng-template #videoDeviceTemplates2>
  <lib-video-device [changeTemplate]="true" (changeIsLock)= "changeIsLockSecondFloor($event)"
  (pooper)="pooper($event, visibility.listQuality, 'listQuality')"
  [qualityTemplate]="true"></lib-video-device>
</ng-template>
