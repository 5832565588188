import {Injectable, Optional} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {EdumeetRoomConfigModel} from '../models/edumeet-room-config.model';
import {BaseResponseApi} from '../models/api/base-response-api.model';
import {CheckRequestApi, CheckResponseApi, CheckResponseModel} from '../models/api/quiz/check.model';
import {QuestionModel, QuestionResponseApi} from '../models/api/quiz/question.model';
import {
    Examining,
    ListQuizResponseApi,
    QuizModel,
    QuizResponseApi,
    ResponsesUserParticipatedModel
} from '../models/api/quiz/quiz.model';
import {SelectOptionOneUIModel} from '../models/api/select-option-oneui.model';
import {CACHE_CONSTANTS} from '../constants';
// @ts-ignore
import {Base, Cache} from 'commonLibrary';
import {SettingsService} from './settings.service';

const USER_ID = '61c02b4bbd166bd75bc6ad60';
const DETAIL_QUIZ = '/api/vnpt-edumeet/v1/quiz-management/quiz/';
const LIST_QUIZ = '/api/vnpt-edumeet/v1/quiz-management/quiz/getbyuser/' + USER_ID;
const START_QUIZ = '/api/vnpt-edumeet/v1/quiz-management/quiz/start/';
const STOP_QUIZ = '/api/vnpt-edumeet/v1/quiz-management/quiz/finish/';
const LIST_QUESTION = '/api/vnpt-edumeet/v1/quiz-management/question/all/';
const CHECK = '/api/vnpt-edumeet/v1/quiz-management/quiz/check';

@Injectable({
    providedIn: 'root'
})
export class QuizService{
    private eduMeetRoomConfig: EdumeetRoomConfigModel;
    private examiningSubject = new BehaviorSubject<Examining>(null);
    private finishSubject = new BehaviorSubject<boolean>(null);

    constructor(
        private http: HttpClient,
        private settingsService: SettingsService,
        @Optional() eduMeetRoomConfig?: EdumeetRoomConfigModel
    ) {
        this.eduMeetRoomConfig = eduMeetRoomConfig;
    }

    public setExamining(examining: Examining): void{
        this.examiningSubject.next(examining);
    }

    public onExamining(): Observable<Examining>{
        return this.examiningSubject.asObservable();
    }

    public getExamining(): Examining{
        return this.examiningSubject.value;
    }

    public setIsFinish(finish: boolean): void{
        this.finishSubject.next(finish);
    }

    public onIsFinish(): Observable<boolean>{
        return this.finishSubject.asObservable();
    }

    public getQuiz(): Observable<QuizModel>{
        const {quizId} = this.examiningSubject.value;
        if (!quizId){
            return of(null);
        }

        return this.http.get<QuizResponseApi>(this.eduMeetRoomConfig.domain + DETAIL_QUIZ + quizId).pipe(
            map((res: QuizResponseApi) => {
                return res.data;
            }),
            catchError((err: HttpErrorResponse) => {
                console.log(err);
                return of(null);
            })
        );
    }

    public getListQuiz(): Observable<QuizModel[]>{
        return this.http.get<ListQuizResponseApi>(`${this.eduMeetRoomConfig.domain}${LIST_QUIZ}`).pipe(
            map((res: ListQuizResponseApi) => {
                return res.data;
            }),
            catchError((err: HttpErrorResponse) => {
                console.log(err);
                return of([]);
            })
        );
    }

    public getListQuizSelectOption(): Observable<SelectOptionOneUIModel[]>{
        return this.getListQuiz().pipe(
            map((res: QuizModel[]) => {
                const selectOptions = res.map((q: QuizModel) => ({label: q.quizName, value: q._id}));
                return selectOptions;
            }),
            catchError((err: HttpErrorResponse) => {
                console.log(err);
                return of([]);
            })
        );
    }

    public startQuiz(roomId: string, quizId: string): Observable<boolean>{
        return this.http.patch(`${this.eduMeetRoomConfig.domain}${START_QUIZ}${quizId}`, {},
            {params: {roomId}}).pipe(
                map((res: BaseResponseApi<null>) => {
                    return true;
                }),
            catchError((err: HttpErrorResponse) => {
                console.log(err);
                return of(false);
            })
        );
    }

    public stopQuiz(roomId: string, quizId: string): Observable<boolean>{
        return this.http.patch(`${this.eduMeetRoomConfig.domain}${STOP_QUIZ}/${quizId}`, {},
            {params: {roomId}}).pipe(
            map((res: BaseResponseApi<null>) => {
                return true;
            }),
            catchError((err: HttpErrorResponse) => {
                console.log(err);
                return of(false);
            })
        );
    }

    public getListQuestion(): Observable<QuestionModel[]>{
        const {quizId} = this.examiningSubject.value;
        if (!quizId){
            return of(null);
        }

        return this.http.get<QuestionResponseApi>(this.eduMeetRoomConfig.domain + LIST_QUESTION + quizId).pipe(
            map((res: QuestionResponseApi) => {
                return res.data;
            }),
            catchError((err: HttpErrorResponse) => {
                console.log(err);
                return of([]);
            })
        );
    }

    public check(answers: ResponsesUserParticipatedModel[]): Observable<CheckResponseModel>{
        const {quizId} = this.examiningSubject.value;
        if (!quizId){
            return of(null);
        }

        const body: CheckRequestApi = {
            quizId,
            userId: USER_ID,
            clientRespones: answers,
            timeStarted: '1',
            timeEnded: '2'
        };

        return this.http.post<CheckResponseApi>(this.eduMeetRoomConfig.domain + CHECK, body).pipe(
            map((res: CheckResponseApi) => {
                return res.data;
            }),
            catchError((err: HttpErrorResponse) => {
                console.log(err);
                return of(null);
            })
        );
    }

    public handleOpenQuizWhenJoin(joinData): boolean {
        // const quizFinishCaching = Cache.getCache(CACHE_CONSTANTS.quizFinish) === 'true';
        if (joinData.quizStatus) {
            return true;
        }
        return false;
    }

}
