import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SettingsService } from './settings.service';

/**
 * BIQUAD TYPE
 * Values: "lowpass","highpass","bandpass","lowshelf","highshelf","peaking","notch","allpass"
 */
const TYPE_BIQUAD = "notch";
/**
 * BIQUAD GAIN: only used for lowshelf, highshelf, and peaking filters.
 * Default: 0, Min: -3.4E38, Max: 1541
 */
const GAIN = 0.2;
/**
 * Voice Frequency
 * Values: M: [100Hz...900Hz], F: [350Hz...3KHz]
 */
const FREQUENCY = 850;
// Q Factor values: [0...1] * 30
const QUANLITY = 15;

@Injectable({
    providedIn: 'root'
})
export class AudioDeviceService {

    private micDevice: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private speakerDevice: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private videoDevice: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private localVideoDevice: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private listMic: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
    private listSpeaker:BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
    private listVideo: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
    private listLocalVideo: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);

    constructor(private settingService: SettingsService) {}

    static createFilterStream(stream: MediaStream, useFilter: boolean = false): MediaStream {
        try {
            // const stream = await navigator.mediaDevices.getUserMedia(constraints);
            if (useFilter) {
                const ctx = new AudioContext();
                const src = ctx.createMediaStreamSource(stream);
                const dst = ctx.createMediaStreamDestination();
                const biquad = ctx.createBiquadFilter();
                // const gainNode = ctx.createGain();
                [biquad, dst].forEach(c => src.connect(c));
                // src.connect(biquad).connect(dst);
                biquad.type = TYPE_BIQUAD;
                // biquad.gain.value = GAIN; // only used for lowself, highself, peaking
                biquad.frequency.value = FREQUENCY;
                biquad.Q.value = QUANLITY;
                // gainNode.gain.setValueAtTime(2, ctx.currentTime);
                // ctx.close();
                return dst.stream;
            }
            return stream;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    public changeMicDevice(device) {
        return this.micDevice.next(device);
    }

    public changeSpeaker(device) {
        return this.speakerDevice.next(device);
    }

    public changeVideo(device) {
        return this.videoDevice.next(device);
    }
    
    public changeLocalVideo(device) {
        this.localVideoDevice.next(device);
    }

    public onMicDevice(): Observable<boolean> {
        return this.micDevice.asObservable();
    }

    public onSpeakerDevice(): Observable<boolean> {
        return this.speakerDevice.asObservable();
    }

    public onVideoDevice(): Observable<boolean> {
        return this.videoDevice.asObservable();
    }

    public onLocalVideoDevice(): Observable<boolean> {
        return this.localVideoDevice.asObservable();
    }
 
    public getSpeaker(): any {
        return this.speakerDevice.getValue();
    }

    public getMic(): any {
        return this.micDevice.getValue();
    }

    public getVideo(): any {
        return this.videoDevice.getValue();
    }

    public getLocalVideo(): any {
        return this.localVideoDevice.getValue();
    }

    public changeListMic(devices) {
        return this.listMic.next(devices);
    }

    public changeListSpeaker(devices) {
        return this.listSpeaker.next(devices);
    }

    public changeListVideo(devices) {
        return this.listVideo.next(devices);
    }
    
    public changeListLocalVideo(devices) {
        this.listLocalVideo.next(devices);
    }

    public onListMic(): Observable<Array<any>> {
        return this.listMic.asObservable();
    }

    public onListSpeaker(): Observable<Array<any>> {
        return this.listSpeaker.asObservable();
    }

    public onListVideo(): Observable<Array<any>> {
        return this.listVideo.asObservable();
    }

    public onListLocalVideo(): Observable<Array<any>> {
        return this.listLocalVideo.asObservable();
    }
 
    public getSpeakerList(): any {
        return this.listSpeaker.getValue();
    }

    public getMicList(): any {
        return this.listMic.getValue();
    }

    public getVideoList(): any {
        return this.listVideo.getValue();
    }

    public getLocalVideoList(): any {
        return this.listLocalVideo.getValue();
    }
}
