<div vnpt-row>
    <div #listView vnpt-col class="group-monitor" [hidden]="!isSiteTeacher" >
        <div #listGroup class="group-wrapper">
            <div class="group-view-title">THEO DÕI NHÓM</div>
            <div *ngFor="let group of listGroups" class="group"
                 [ngClass]="{ 'group-chosen' : myGroupId === group }"
                 (click)="joinGroupBreakroom(group)">
                {{getGroupName(group)}}
            </div>
        </div>
    </div>
    <div vnpt-col class="teacher-view" [ngClass]="{ 'teacher-view__student' : !isSiteTeacher }">
        <div #adminVideoBox class="main-frame">
            <div [style.width.%]="adminVideoBoxWidth" class="admin-video">
                <div *ngIf="isSiteTeacher" class="video-col">
                    <lib-local-streaming [miniView]="miniView"
                                         [isTeacher]="isSiteTeacher"></lib-local-streaming>
                </div>
                <div *ngIf="!isSiteTeacher" class="video-col">
                    <div class="group-info">
                        <div class="group">Nhóm {{ myGroupId }}</div>
                    </div>
                    <lib-break-remote-streaming *ngIf="teacherID && teacherID != ''" [miniView]="miniView"
                                          [participantId]="teacherID" [isTeacher]="!isSiteTeacher">
                    </lib-break-remote-streaming>
                </div>
            </div>
        </div>
    </div>
    <div vnpt-col class="student-view" [ngClass]="{ 'student-view__student' : !isSiteTeacher }">
        <div #participantVideoBox class="main-frame">
            <div vnpt-row vJustify="center" vAlign="middle" [vGutter]="[16, 16]" class="video-list">
                <div *ngIf="!isSiteTeacher"
                    vnpt-col [style.width.%]="videoColumnSize" class="video-col">
                    <lib-local-streaming [miniView]="miniView"></lib-local-streaming>
                </div>

                <div vnpt-col [style.width.%]="videoColumnSize" class="video-col" 
                    *ngFor="let remote of listRemoteParticipant">
                    <div *ngIf="remote && remote.id 
                                && remote.id != ''
                                && remote.id != teacherID">
                        <lib-break-remote-streaming [miniView]="miniView"
                                                    [participantId]="remote.id">

                        </lib-break-remote-streaming>
                    </div>
                </div>
            </div>

            <button class="paging-button left-button" vnpt-button vType="primary" vShape="circle"
                    [ngClass]="{'d-none': currentPageIndex - 1 <= 0}"
                    (click)="previousPage()">
                <i vnpt-icon vType="ChevronLeft"></i>
            </button>

            <button class="paging-button right-button" vnpt-button vType="primary" vShape="circle"
                    [ngClass]="{'d-none': currentPageIndex + 1 > getTotalPageIndex() }"
                    (click)="nextPage()">
                <i vnpt-icon vType="ChevronRight"></i>
            </button>
                
            <p class="paging-count" [ngClass]="{'d-none': getTotalPageIndex() < 2}">{{currentPageIndex + '/' + getTotalPageIndex()}}</p>
        </div>
    </div>
  </div>
