export interface RoomConfigModel {
    muteOnStart: boolean | false;
    logoutURL: string | '';
    lockSettingsDisableCam: boolean | false;
    lockSettingsDisableMic: boolean | false;
    webcamsOnlyForModerator: boolean | false;
    whiteboard: boolean | false;
    whiteboardLocked: boolean | true;
    maxParticipants: number | 0;
    locked: boolean | false;
    inviteLink: string;
    accessCode: string;
    roomID: string;
    publicScreenShare: boolean | false;
    startTime: number;
    endTime: number;
    duration: number;
    destructTimeout: number;
    layout: number;
    pinners: Array<string>;
    hideNoneCamera: boolean
    isExtended: boolean | false;
}

export function getDefaultRoomConfig(): RoomConfigModel{
    return {
        muteOnStart: false,
        logoutURL: '',        
        lockSettingsDisableCam: false,
        lockSettingsDisableMic: false,
        webcamsOnlyForModerator: false,
        whiteboard: false,
        whiteboardLocked: true,
        maxParticipants: 0,
        locked: false,
        inviteLink: '',
        accessCode: '',
        roomID: '',
        publicScreenShare: false,
        startTime: 0,
        endTime: 0,
        duration: 0,
        destructTimeout: 0,
        layout: 0,
        pinners: [],
        hideNoneCamera: false,
        isExtended: false
    }
}
