import {Action} from '@ngrx/store';

export const ADD_NOTIFICATION = 'add notification';
export const REMOVE_NOTIFICATION = 'remove notification';
export const REMOVE_ALL_NOTIFICATIONS = 'remove all notification';
export const CLOSE_NOTIFICATION = 'close notification';

export class AddNotification implements Action{
    readonly type = ADD_NOTIFICATION;
    public payload: any;
    constructor( notification ) {
        this.payload = { notification };
    }

}
export class RemoveNotification implements Action{
    readonly type = REMOVE_NOTIFICATION;
    public payload: any;
    constructor( notificationId ) {
        this.payload = { notificationId };
    }

}
export class RemoveAllNotifications implements Action{
    readonly type = REMOVE_ALL_NOTIFICATIONS;
    public payload: any;
    constructor() {}
}

export class CloseNotification implements Action{
    readonly type = CLOSE_NOTIFICATION;
    public payload: any;
    constructor( notificationId ) {
        this.payload = { notificationId };
    }

}

export type All =
    AddNotification |
    RemoveNotification |
    RemoveAllNotifications |
    CloseNotification;
