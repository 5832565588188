/* tslint:disable:typedef no-debugger */
import {Room} from './room.model';
import * as RoomActions from './room.actions';
import {RealtimeCommunicationState} from '../state';
import {config} from '../../../config';

const initialState: Room =
    {
        name                          : '',
        // new/connecting/connected/disconnected/closed,
        state                         : 'new',
        locked                        : false,
        inLobby                       : false,
        signInRequired                : false,
        overRoomLimit                 : false,
        // access code to the room if locked and joinByAccessCode == true
        accessCode                    : '',
        // if true: accessCode is a possibility to open the room
        joinByAccessCode              : true,
        activeSpeakerId               : null,
        torrentSupport                : false,
        showSettings                  : false,
        fullScreenConsumer            : null, // ConsumerID
        windowConsumer                : null, // ConsumerID
        toolbarsVisible               : true,
        mode                          : config.defaultLayout,
        selectedPeers                 : [],
        spotlights                    : [],
        rolesManagerPeer              : null, // peerId
        settingsOpen                  : false,
        extraVideoOpen                : false,
        hideSelfView                  : false,
        rolesManagerOpen              : false,
        helpOpen                      : false,
        aboutOpen                     : false,
        leaveOpen                     : false,
        currentSettingsTab            : 'media', // media, appearance, advanced
        lockDialogOpen                : false,
        joined                        : false,
        muteAllInProgress             : false,
        lobbyPeersPromotionInProgress : false,
        stopAllVideoInProgress        : false,
        closeMeetingInProgress        : false,
        clearChatInProgress           : false,
        clearFileSharingInProgress    : false,
        roomPermissions               : null,
        userRoles                     : null,
        allowWhenRoleMissing          : null,
        whiteBoardStatus              : false,
        joinedRoomStatus              : false,
        presentationFileUploaderOpen  : false
    };

export type Action = RoomActions.All;

// export namespace ChatQuery {
//     export const getChat = (state: RealtimeCommunicationState) => state.chat;
// }

export function RoomReducer(state: Room = initialState, action: Action)
{
    switch (action.type)
    {
        case RoomActions.SET_ROOM_NAME:
        {
            const { name } = action.payload;

            return { ...state, name };
        }

        case RoomActions.SET_ROOM_STATE:
        {
            const roomState = action.payload.state;

            if (roomState === 'connected') {
                return { ...state, state: roomState };
            }
            else {
                return { ...state, state: roomState, activeSpeakerId: null };
            }
        }

        case RoomActions.SET_ROOM_LOCKED:
        {
            return { ...state, locked: true };
        }

        case RoomActions.SET_ROOM_UNLOCKED:
        {
            return { ...state, locked: false };
        }

        case RoomActions.SET_IN_LOBBY:
        {
            const { inLobby } = action.payload;

            return { ...state, inLobby };
        }

        case RoomActions.SET_SIGN_IN_REQUIRED:
        {
            const { signInRequired } = action.payload;

            return { ...state, signInRequired };
        }
        case RoomActions.SET_OVER_ROOM_LIMIT:
        {
            const { overRoomLimit } = action.payload;

            return { ...state, overRoomLimit };
        }
        case RoomActions.SET_ACCESS_CODE:
        {
            const { accessCode } = action.payload;

            return { ...state, accessCode };
        }

        case RoomActions.SET_JOIN_BY_ACCESS_CODE:
        {
            const { joinByAccessCode } = action.payload;

            return { ...state, joinByAccessCode };
        }

        case RoomActions.SET_LOCK_DIALOG_OPEN:
        {
            const { lockDialogOpen } = action.payload;

            return { ...state, lockDialogOpen };
        }

        case RoomActions.SET_SETTINGS_OPEN:
        {
            const { settingsOpen } = action.payload;

            return { ...state, settingsOpen };
        }

        case RoomActions.SET_VIRTUAL_BACKGROUND_OPEN:
        {
            const { virtualBackgroundOpen } = action.payload;

            return { ...state, virtualBackgroundOpen };
        }

        case RoomActions.SET_EXTRA_VIDEO_OPEN:
        {
            const { extraVideoOpen } = action.payload;

            return { ...state, extraVideoOpen };
        }

        case RoomActions.SET_ROLES_MANAGER_PEER:
        {
            const { rolesManagerPeer } = action.payload;

            return { ...state, rolesManagerPeer };
        }

        case RoomActions.SET_ROLES_MANAGER_OPEN:
        {
            const { rolesManagerOpen } = action.payload;

            return { ...state, rolesManagerOpen };
        }

        case RoomActions.SET_HELP_OPEN:
        {
            const { helpOpen } = action.payload;

            return { ...state, helpOpen };
        }

        case RoomActions.SET_ABOUT_OPEN:
        {
            const { aboutOpen } = action.payload;

            return { ...state, aboutOpen };
        }

        case RoomActions.SET_LEAVE_OPEN:
        {
            const { leaveOpen } = action.payload;

            return { ...state, leaveOpen };
        }

        case RoomActions.SET_SETTINGS_TAB:
        {
            const { tab } = action.payload;

            return { ...state, currentSettingsTab: tab };
        }

        case RoomActions.SET_ROOM_ACTIVE_SPEAKER:
        {
            const { peerId } = action.payload;

            return { ...state, activeSpeakerId: peerId };
        }

        case RoomActions.FILE_SHARING_SUPPORTED:
        {
            const { supported } = action.payload;

            return { ...state, torrentSupport: supported };
        }

        case RoomActions.TOGGLE_JOINED:
        {
            const joined = true;

            return { ...state, joined };
        }

        case RoomActions.TOGGLE_FULLSCREEN_CONSUMER:
        {
            const { consumerId } = action.payload;
            const currentConsumer = state.fullScreenConsumer;

            return { ...state, fullScreenConsumer: currentConsumer ? null : consumerId };
        }

        case RoomActions.TOGGLE_WINDOW_CONSUMER:
        {
            const { consumerId } = action.payload;
            const currentConsumer = state.windowConsumer;

            if (currentConsumer === consumerId) {
                return { ...state, windowConsumer: null };
            }
            else {
                return { ...state, windowConsumer: consumerId };
            }
        }

        case RoomActions.SET_TOOLBARS_VISIBLE:
        {
            const { toolbarsVisible } = action.payload;

            return { ...state, toolbarsVisible };
        }

        case RoomActions.SET_PAGING:
        {
            const { paging } = action.payload;

            return { ...state, paging };
        }

        case RoomActions.SET_DISPLAY_MODE:
            return { ...state, mode: action.payload.mode };

        case RoomActions.ADD_SELECTED_PEER:
        {
            const { peerId } = action.payload;

            const selectedPeers = [ ...state.selectedPeers, peerId ];

            return { ...state, selectedPeers };
        }

        // Also listen for peers leaving
        // case RoomActions.REMOVE_PEER:
        case RoomActions.REMOVE_SELECTED_PEER:
        {
            const { peerId } = action.payload;

            const selectedPeers =
                state.selectedPeers.filter((peer) =>
                    peer !== peerId);

            return { ...state, selectedPeers };
        }

        case RoomActions.CLEAR_SELECTED_PEERS:
        {
            return { ...state, selectedPeers: [] };
        }

        case RoomActions.SET_SPOTLIGHTS:
        {
            const { spotlights } = action.payload;

            return { ...state, spotlights };
        }

        case RoomActions.CLEAR_SPOTLIGHTS:
        {
            return { ...state, spotlights: [] };
        }

        case RoomActions.SET_LOBBY_PEERS_PROMOTION_IN_PROGRESS:
            return { ...state, lobbyPeersPromotionInProgress: action.payload.flag };

        case RoomActions.MUTE_ALL_IN_PROGRESS:
            return { ...state, muteAllInProgress: action.payload.flag };

        case RoomActions.STOP_ALL_VIDEO_IN_PROGRESS:
            return { ...state, stopAllVideoInProgress: action.payload.flag };

        case RoomActions.STOP_ALL_SCREEN_SHARING_IN_PROGRESS:
            return { ...state, stopAllScreenSharingInProgress: action.payload.flag };

        case RoomActions.CLOSE_MEETING_IN_PROGRESS:
            return { ...state, closeMeetingInProgress: action.payload.flag };

        case RoomActions.CLEAR_CHAT_IN_PROGRESS:
            return { ...state, clearChatInProgress: action.payload.flag };

        case RoomActions.CLEAR_FILE_SHARING_IN_PROGRESS:
            return { ...state, clearFileSharingInProgress: action.payload.flag };

        case RoomActions.SET_ROOM_PERMISSIONS:
        {
            const { roomPermissions } = action.payload;

            return { ...state, roomPermissions };
        }

        case RoomActions.SET_USER_ROLES:
        {
            const { userRoles } = action.payload;

            return { ...state, userRoles };
        }

        case RoomActions.SET_ALLOW_WHEN_ROLE_MISSING:
        {
            const { allowWhenRoleMissing } = action.payload;

            return { ...state, allowWhenRoleMissing };
        }

        case RoomActions.SET_HIDE_SELF_VIEW:
        {
            const { hideSelfView } = action.payload;

            return { ...state, hideSelfView };
        }

        case RoomActions.SET_WHITE_BOARD_STATUS:
        {
            const { whiteBoardStatus } = action.payload;

            return { ...state, whiteBoardStatus };
        }

        case RoomActions.SET_JOINED_ROOM_STATUS:
        {
            const { joinedRoomStatus } = action.payload;

            return { ...state, joinedRoomStatus };
        }

        case RoomActions.SET_PRESENTATION_FILE_UPLOADER_OPEN:
        {
            const { presentationFileUploaderOpen } = action.payload;

            return { ...state, presentationFileUploaderOpen };
        }

        default:
            return state;
    }
}
