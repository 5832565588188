// type constants
// -------------------
type LAYOUT_MODE = 'gallery' | 'speaker' | 'sidebyside' | 'whiteboard'| 'quiz' | 'breakroom' | 'polycom-71' | 'polycom-121';

type REPORT_TYPE = 'audio' | 'video' | 'network' | 'feature'| 'other' | 'userReport';
type FORMAT_ACTION_REMOTE_PARTICIPANT = 'ADDPEER' | 'UPDATE_REMOTE_CONSUMER' | 'UPDATE_REMOTE_PRODUCER' | 'DELETE_ALL_PEER' | 'DELETE_PEER' | 'CHANGE_DISPLAY_NAME'
| 'ADD_ROLE' | 'REMOVE_ROLE' ;
const DISPLAY_LAYOUT = [
    {id: 0, name: '', icon: '', maxItem: 0, maxPin: 0}, 
    {id: 1, name: "gallery",  icon: 'GridViewMedium',maxItem:0, maxPin: 6 },
    {id: 2, name: "speaker",  icon: 'LargeGrid',maxItem:5 , maxPin: 6}, 
    {id: 3, name: "sidebyside",  icon: 'FiveTileGrid', maxItem:5, maxPin: 6},
    {id: 4, name: "whiteboard",  icon: 'FiveTileGrid', maxItem:5, maxPin: 6},
    {id: 5, name: "polycom-71",  icon: 'FiveTileGrid', maxItem:6, maxPin: 7},
    {id: 6, name: "polycom-121",  icon: 'FiveTileGrid', maxItem: 11, maxPin: 12}]



const APPSOURCE = {
    webcam: 'isHaveWebcam',
    screen: 'isHaveScreenShare'
};

// quiz constants
// --------------------
type EXERSICE_QUIZ_STATE = 'empty' | 'doing' | 'finish';
const CACHE_CONSTANTS = {
    quizFinish: 'quizFinish',
    quizId: 'quizId',
    quizAnswer: 'quizAnswer',
    quizDeadline: 'quizDeadline',
    quizExitDate: 'quizExitDate',
    quizResult: 'quizResult',
    roomToken: 'token',
    expiredTime: 3600 * 24 * 3600 * 1000
};

// role constants
// -------------------
const ROLES_CONSTANTS = {
    admin: 'Quyền quản trị',
    moderator: 'Quyền điều hành',
    presenter: 'Quyền trình chiếu',
    whiteboard: 'Quyền vẽ bảng trắng',
    'share video': 'Quyền chia sẻ video',
    'share audio': 'Quyền chia sẻ audio'
};
const ADMIN_ROLES = 50;
const MODERATOR_ROLES = 40;
const NORMAL_ROLES = 10;

const SAFARI_VERSION = 16.4;

const PERMISSIONS = {
    ADMIN         :  { id: 2529, name: 'Quyền quản trị', label: 'admin', level: 50, promotable: true },
    MODERATOR     :  { id: 5337, name: 'Quyền điều hành', label: 'moderator', level: 40, promotable: true },
    AUTHENTICATED :  { id: 5714, name: '', label: 'authenticated', level: 20, promotable: false },
    PRESENTER     :  { id: 9583, name: 'Quyền trình chiếu', label: 'presenter', level: 10, promotable: true },
    WHITEBOARD    :  { id: 4264, name: 'Quyền vẽ bảng trắng', label: 'whiteboard', level: 10, promotable: true },
    SHARE_VIDEO   :  { id: 4262, name: 'Quyền chia sẻ video', label: 'share video', level: 10, promotable: true },
    SHARE_AUDIO   :  { id: 4263, name: 'Quyền chia sẻ audio', label: 'share audio', level: 10, promotable: true },
    NORMAL        :  { id: 4261, name: '', label: 'normal', level: 10, promotable: false }
};

const LIST_ROLE_ID = {
    ADMIN_ID : 2529,
    MODERATOR_ID : 5337,
    NORMAL_ID : 4261,
    PRESENTER_ID : 9583,
    AUTHENTICATED_ID : 5714
}

// video constants
const VIDEO_COLUMN_SIZE_FORMAT = {
    ONE_COLUMN: 1,
    TWO_COLUMN: 0.42,
    THREE_COLUMN: 0.33,
    FOUR_COLUMN: 0.25,
    FIVE_COLUMN: 0.2
};
const COL_ONE_ROW = [
    { minCol: 0.5, maxCol: 1 },
    { minCol: 0.33, maxCol: 0.5 },
    { minCol: 0.25, maxCol: 0.33 },
    { minCol: 0.2, maxCol: 0.25 },
    { minCol: 0.15, maxCol: 0.2 },
    { minCol: 0.01, maxCol: 0.15 },
  ];
const RATIO_WIDTH = 16;
const RATIO_HEIGHT = 9;
const MAX_VIDEO = 11;
const MAX_ROW = 6;
const MAX_COLUMN = 24;
const PC_PROPRIETARY_CONSTRAINTS = {
    optional: [{googDscp: true}]
};

const VIDEO_CONSTRAINS =
    {
        low:
            {
                width: 320
            },
        medium:
            {
                width: 640
            },
        high:
            {
                width: 1280
            },
        veryhigh:
            {
                width: 1920
            },
        ultra:
            {
                width: 3840
            }
    };

const DEFAULT_NETWORK_PRIORITIES = {
    audio: 'high',
    mainVideo: 'high',
    additionalVideos: 'medium',
    screenShare: 'medium'
};

// Used for VP9 webcam video.
const VIDEO_KSVC_ENCODINGS =
    [
        {
            networkPriority: 'high',
            scalabilityMode: 'S3T3_KEY'
        }
    ];

// Used for VP9 desktop sharing.
const VIDEO_SVC_ENCODINGS =
    [
        {scalabilityMode: 'S3T3', dtx: true}
    ];

const VIDEO_SIMULCAST_PROFILES = {
    3840:
        [
            {scaleResolutionDownBy: 12, maxBitRate: 150000},
            {scaleResolutionDownBy: 6, maxBitRate: 500000},
            {scaleResolutionDownBy: 1, maxBitRate: 10000000}
        ],
    1920:
        [
            {scaleResolutionDownBy: 6, maxBitRate: 150000},
            {scaleResolutionDownBy: 3, maxBitRate: 500000},
            {scaleResolutionDownBy: 1, maxBitRate: 3500000}
        ],
    1280:
        [
            {scaleResolutionDownBy: 4, maxBitRate: 150000},
            {scaleResolutionDownBy: 2, maxBitRate: 500000},
            {scaleResolutionDownBy: 1, maxBitRate: 1200000}
        ],
    640:
        [
            {scaleResolutionDownBy: 2, maxBitRate: 150000},
            {scaleResolutionDownBy: 1, maxBitRate: 500000}
        ],
    320:
        [
            {scaleResolutionDownBy: 1, maxBitRate: 150000}
        ]
};

export {
    LAYOUT_MODE,
    EXERSICE_QUIZ_STATE,
    APPSOURCE,
    CACHE_CONSTANTS,
    ROLES_CONSTANTS,
    ADMIN_ROLES,
    MODERATOR_ROLES,
    NORMAL_ROLES,
    VIDEO_COLUMN_SIZE_FORMAT,
    COL_ONE_ROW,
    RATIO_WIDTH,
    RATIO_HEIGHT,
    MAX_ROW,
    MAX_COLUMN,
    MAX_VIDEO,
    PC_PROPRIETARY_CONSTRAINTS,
    VIDEO_CONSTRAINS,
    DEFAULT_NETWORK_PRIORITIES,
    VIDEO_KSVC_ENCODINGS,
    VIDEO_SVC_ENCODINGS,
    VIDEO_SIMULCAST_PROFILES,
    LIST_ROLE_ID,
    PERMISSIONS,
    REPORT_TYPE
};

// Animation Constants
// Gallery view
const LAYOUT_GALLERY_WIDTH_SHRINK = 'calc(100vw - 408px)';
const LAYOUT_GALLERY_WIDTH_EXPAND = 'calc(100vw - 28px)';
// Right Container
const CONTAINER_IN = 'calc(100% - 380px)';
const CONTAINER_OUT = '100%';

const BOTTOM_IN = '0';
const BOTTOM_OUT = '-56px';

// Duration
const EXPAND_SHRINK_DURATION = 300;
const SLIDE_DURATION = 300;
const FLOAT_DURATION = 300;
const FADE_DURATION = 300;
const VIDEO_FADE_DURATION = 300;
const VIDEO_FADE_DURATION_WITH_NO_GLITCH = 900;
const VIDEO_FADE_DURATION_WITH_NO_GLITCH_DELAY = 100;
const LEFT_ABSOLUTE_DURATION = 100;

export {
    LAYOUT_GALLERY_WIDTH_SHRINK,
    LAYOUT_GALLERY_WIDTH_EXPAND,
    CONTAINER_IN,
    CONTAINER_OUT,
    BOTTOM_IN,
    BOTTOM_OUT,
    EXPAND_SHRINK_DURATION,
    SLIDE_DURATION,
    FLOAT_DURATION,
    FADE_DURATION,
    VIDEO_FADE_DURATION,
    VIDEO_FADE_DURATION_WITH_NO_GLITCH,
    VIDEO_FADE_DURATION_WITH_NO_GLITCH_DELAY,
    LEFT_ABSOLUTE_DURATION,
    DISPLAY_LAYOUT,
    SAFARI_VERSION,
    FORMAT_ACTION_REMOTE_PARTICIPANT
};
