import * as NotificationsActions from './notifications.actions';

export type Action = NotificationsActions.All;

export const NotificationsReducer = (state = [], action: Action) =>
{
    switch (action.type)
    {
        case NotificationsActions.ADD_NOTIFICATION:
        {
            const { notification } = action.payload;

            notification.toBeClosed = false;

            return [ ...state, notification ];
        }

        case NotificationsActions.REMOVE_NOTIFICATION:
        {
            const { notificationId } = action.payload;

            return state.filter((notification) => notification.id !== notificationId);
        }

        case NotificationsActions.REMOVE_ALL_NOTIFICATIONS:
        {
            return [];
        }

        case NotificationsActions.CLOSE_NOTIFICATION:
        {
            const { notificationId } = action.payload;

            return (state.map((e) =>
            {
                if (e.id === notificationId)
                {
                    e.toBeClosed = true;
                }

                return e;
            }));
        }

        default:
            return state;
    }
};
