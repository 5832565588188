import {Action} from '@ngrx/store';

export const ADD_MESSAGE = 'add message';
export const SET_ROOM_NAME = 'set room name';
export const SET_ROOM_STATE = 'set room state';
export const SET_ROOM_ACTIVE_SPEAKER = 'set room active speaker';
export const SET_ROOM_LOCKED = 'set room locked';
export const SET_ROOM_UNLOCKED = 'set room unlocked';
export const SET_IN_LOBBY = 'set in lobby';
export const SET_SIGN_IN_REQUIRED = 'set sign in required';
export const SET_OVER_ROOM_LIMIT = 'set over room limit';
export const SET_ACCESS_CODE = 'set access code';
export const SET_JOIN_BY_ACCESS_CODE = 'set join by access code';
export const SET_SETTINGS_OPEN = 'set settings open';
export const SET_VIRTUAL_BACKGROUND_OPEN = 'set virtual background open';
export const SET_EXTRA_VIDEO_OPEN = 'set extra video open';
export const SET_ROLES_MANAGER_OPEN = 'set roles manager open';
export const SET_ROLES_MANAGER_PEER = 'set roles manager peer';
export const SET_HELP_OPEN = 'set help open';
export const SET_ABOUT_OPEN = 'set about open';
export const SET_LEAVE_OPEN = 'set leave open';
export const SET_SETTINGS_TAB = 'set settings tab';
export const SET_LOCK_DIALOG_OPEN = 'set lock dialog open';
export const FILE_SHARING_SUPPORTED = 'file sharing supported';
export const TOGGLE_WINDOW_CONSUMER = 'toggle window consumer';
export const SET_TOOLBARS_VISIBLE = 'set toolbars visible';
export const SET_PAGING = 'set paging';
export const SET_DISPLAY_MODE = 'set display mode';
export const ADD_SELECTED_PEER = 'add selected peer';
export const REMOVE_SELECTED_PEER = 'remove selected peer';
export const CLEAR_SELECTED_PEERS = 'clear selected peers';
export const SET_SPOTLIGHTS = 'set spotlights';
export const CLEAR_SPOTLIGHTS = 'clear spotlights';
export const TOGGLE_JOINED = 'toggle joined';
export const TOGGLE_FULLSCREEN_CONSUMER = 'toggle fullscreen consumer';
export const SET_LOBBY_PEERS_PROMOTION_IN_PROGRESS = 'set lobby peers promotion in progress';
export const MUTE_ALL_IN_PROGRESS = 'mute all in progress';
export const STOP_ALL_VIDEO_IN_PROGRESS = 'stop all video in progress';
export const STOP_ALL_SCREEN_SHARING_IN_PROGRESS = 'stop all screen sharing in progress';
export const CLOSE_MEETING_IN_PROGRESS = 'close meeting in progress';
export const CLEAR_CHAT_IN_PROGRESS = 'clear chat in progress';
export const CLEAR_FILE_SHARING_IN_PROGRESS = 'clear file sharing in progress';
export const SET_ROOM_PERMISSIONS = 'set room permissions';
export const SET_USER_ROLES = 'set user roles';
export const SET_ALLOW_WHEN_ROLE_MISSING = 'set allow when role missing';
export const SET_HIDE_SELF_VIEW = 'set hide self view';
export const SET_WHITE_BOARD_STATUS = 'set white board status';
export const SET_JOINED_ROOM_STATUS = 'set joined room status';
export const SET_PRESENTATION_FILE_UPLOADER_OPEN = 'set presentation file uploader open';

export class SetRoomName implements Action {
    readonly type = SET_ROOM_NAME;
    public payload: any;
    constructor(name: any) {
        this.payload = {name};
    }
}

export class SetRoomState implements Action {
    readonly type = SET_ROOM_STATE;
    public payload: any;
    constructor(state: any) {
        this.payload = {state};
    }
}

export class SetRoomActiveSpeaker implements Action {
    readonly type = SET_ROOM_ACTIVE_SPEAKER;
    public payload: any;
    constructor(peerId: any) {
        this.payload = {peerId};
    }
}

export class SetRoomLocked implements Action {
    readonly type = SET_ROOM_LOCKED;
    public payload: any;
    constructor() {}
}

export class SetRoomUnLocked implements Action {
    readonly type = SET_ROOM_UNLOCKED;
    public payload: any;
    constructor() {}
}

export class SetInLobby implements Action {
    readonly type = SET_IN_LOBBY;
    public payload: any;
    constructor(inLobby: any) {
        this.payload = {inLobby};
    }
}

export class SetSignInRequired implements Action {
    readonly type = SET_SIGN_IN_REQUIRED;
    public payload: any;
    constructor(signInRequired: any) {
        this.payload = {signInRequired};
    }
}

export class SetOverRoomLimit implements Action {
    readonly type = SET_OVER_ROOM_LIMIT;
    public payload: any;
    constructor(overRoomLimit: any) {
        this.payload = {overRoomLimit};
    }
}

export class SetAccessCode implements Action {
    readonly type = SET_ACCESS_CODE;
    public payload: any;
    constructor(accessCode: any) {
        this.payload = {accessCode};
    }
}

export class SetJoinByAccessCode implements Action {
    readonly type = SET_JOIN_BY_ACCESS_CODE;
    public payload: any;
    constructor(joinByAccessCode: any) {
        this.payload = {joinByAccessCode};
    }
}

export class SetSettingsOpen implements Action {
    readonly type = SET_SETTINGS_OPEN;
    public payload: any;
    constructor(settingsOpen: any) {
        this.payload = {settingsOpen};
    }
}

export class SetVirtualBackgroundOpen implements Action {
    readonly type = SET_VIRTUAL_BACKGROUND_OPEN;
    public payload: any;
    constructor(virtualBackgroundOpen: any) {
        this.payload = {virtualBackgroundOpen};
    }
}

export class SetExtraVideoOpen implements Action {
    readonly type = SET_EXTRA_VIDEO_OPEN;
    public payload: any;
    constructor(extraVideoOpen: any) {
        this.payload = {extraVideoOpen};
    }
}

export class SetRolesManagerOpen implements Action {
    readonly type = SET_ROLES_MANAGER_OPEN;
    public payload: any;
    constructor(rolesManagerOpen: any) {
        this.payload = {rolesManagerOpen};
    }
}

export class SetRolesManagerPeer implements Action {
    readonly type = SET_ROLES_MANAGER_PEER;
    public payload: any;
    constructor(rolesManagerPeer: any) {
        this.payload = {rolesManagerPeer};
    }
}

export class SetHelpOpen implements Action {
    readonly type = SET_HELP_OPEN;
    public payload: any;
    constructor(helpOpen: any) {
        this.payload = {helpOpen};
    }
}

export class SetAboutOpen implements Action {
    readonly type = SET_ABOUT_OPEN;
    public payload: any;
    constructor(aboutOpen: any) {
        this.payload = {aboutOpen};
    }
}

export class SetLeaveOpen implements Action {
    readonly type = SET_LEAVE_OPEN;
    public payload: any;
    constructor(leaveOpen: any) {
        this.payload = {leaveOpen};
    }
}

export class SetSettingsTab implements Action {
    readonly type = SET_SETTINGS_TAB;
    public payload: any;
    constructor(tab: any) {
        this.payload = {tab};
    }
}

export class SetLockDialogOpen implements Action {
    readonly type = SET_LOCK_DIALOG_OPEN;
    public payload: any;
    constructor(lockDialogOpen: any) {
        this.payload = {lockDialogOpen};
    }
}

export class SetFileSharingSupported implements Action {
    readonly type = FILE_SHARING_SUPPORTED;
    public payload: any;
    constructor(supported: any) {
        this.payload = {supported};
    }
}

export class ToggleConsumerWindow implements Action{
    readonly type = TOGGLE_WINDOW_CONSUMER;
    public payload: any;
    constructor(consumerId: any) {
        this.payload = {consumerId};
    }

}

export class SetToolbarsVisible implements Action{
    readonly type = SET_TOOLBARS_VISIBLE;
    public payload: any;
    constructor(toolbarsVisible: any) {
        this.payload = {toolbarsVisible};
    }

}

export class SetPaging implements Action{
    readonly type = SET_PAGING;
    public payload: any;
    constructor(paging: any) {
        this.payload = {paging};
    }

}

export class SetDisplayMode implements Action{
    readonly type = SET_DISPLAY_MODE;
    public payload: any;
    constructor(mode: any) {
        this.payload = {mode};
    }

}

export class AddSelectedPeer implements Action{
    readonly type = ADD_SELECTED_PEER;
    public payload: any;
    constructor(peerId: any) {
        this.payload = {peerId};
    }

}

export class RemoveSelectedPeer implements Action{
    readonly type = REMOVE_SELECTED_PEER;
    public payload: any;
    constructor(peerId: any) {
        this.payload = {peerId};
    }

}

export class ClearSelectedPeers implements Action{
    readonly type = CLEAR_SELECTED_PEERS;
    public payload: any;
    constructor() {
    }

}
export class SetSpotlights implements Action{
    readonly type = SET_SPOTLIGHTS;
    public payload: any;
    constructor(spotlights: any) {
        this.payload = {spotlights};
    }

}

export class ClearSpotlights implements Action{
    readonly type = CLEAR_SPOTLIGHTS;
    public payload: any;
    constructor() {}

}
export class ToggleJoined implements Action{
    readonly type = TOGGLE_JOINED;
    public payload: any;
    constructor() {}

}
export class ToggleConsumerFullscreen implements Action{
    readonly type = TOGGLE_FULLSCREEN_CONSUMER;
    public payload: any;
    constructor(consumerId: any) {
        this.payload = {consumerId};
    }

}

export class SetLobbyPeersPromotionInProgress implements Action{
    readonly type = SET_LOBBY_PEERS_PROMOTION_IN_PROGRESS;
    public payload: any;
    constructor(flag: any) {
        this.payload = {flag};
    }

}

export class SetMuteAllInProgress implements Action{
    readonly type = MUTE_ALL_IN_PROGRESS;
    public payload: any;
    constructor(flag: any) {
        this.payload = {flag};
    }

}

export class SetStopAllVideoInProgress implements Action{
    readonly type = STOP_ALL_VIDEO_IN_PROGRESS;
    public payload: any;
    constructor(flag: any) {
        this.payload = {flag};
    }

}

export class SetStopAllScreenSharingInProgress implements Action{
    readonly type = STOP_ALL_SCREEN_SHARING_IN_PROGRESS;
    public payload: any;
    constructor(flag: any) {
        this.payload = {flag};
    }

}

export class SetCloseMeetingInProgress implements Action{
    readonly type = CLOSE_MEETING_IN_PROGRESS;
    public payload: any;
    constructor(flag: any) {
        this.payload = {flag};
    }

}

export class SetClearChatInProgress implements Action{
    readonly type = CLEAR_CHAT_IN_PROGRESS;
    public payload: any;
    constructor(flag: any) {
        this.payload = {flag};
    }

}

export class SetClearFileSharingInProgress implements Action{
    readonly type = CLEAR_FILE_SHARING_IN_PROGRESS;
    public payload: any;
    constructor(flag: any) {
        this.payload = {flag};
    }

}

export class SetRoomPermissions implements Action{
    readonly type = SET_ROOM_PERMISSIONS;
    public payload: any;
    constructor(roomPermissions: any) {
        this.payload = {roomPermissions};
    }

}

export class SetUserRoles implements Action{
    readonly type = SET_USER_ROLES;
    public payload: any;
    constructor(userRoles: any) {
        this.payload = {userRoles};
    }

}

export class SetAllowWhenRoleMissing implements Action{
    readonly type = SET_ALLOW_WHEN_ROLE_MISSING;
    public payload: any;
    constructor(allowWhenRoleMissing: any) {
        this.payload = {allowWhenRoleMissing};
    }

}

export class SetHideSelfView implements Action{
    readonly type = SET_HIDE_SELF_VIEW;
    public payload: any;
    constructor(hideSelfView: any) {
        this.payload = {hideSelfView};
    }

}

export class SetWhiteBoardStatus implements Action{
    readonly type = SET_WHITE_BOARD_STATUS;
    public payload: any;
    constructor(whiteBoardStatus: any) {
        this.payload = {whiteBoardStatus};
    }

}

export class SetJoinedRoomStatus implements Action{
    readonly type = SET_JOINED_ROOM_STATUS;
    public payload: any;
    constructor(joinedRoomStatus: any) {
        this.payload = {joinedRoomStatus};
    }

}

export class SetPresentationFileUploaderOpen implements Action{
    readonly type = SET_PRESENTATION_FILE_UPLOADER_OPEN;
    public payload: any;
    constructor(presentationFileUploaderOpen: any) {
        this.payload = {presentationFileUploaderOpen};
    }

}

export type All =
    SetRoomName |
    SetRoomState |
    SetRoomActiveSpeaker |
    SetRoomLocked |
    SetRoomUnLocked |
    SetInLobby |
    SetSignInRequired |
    SetOverRoomLimit |
    SetAccessCode |
    SetJoinByAccessCode |
    SetSettingsOpen |
    SetVirtualBackgroundOpen |
    SetExtraVideoOpen |
    SetRolesManagerOpen |
    SetRolesManagerPeer |
    SetHelpOpen |
    SetAboutOpen |
    SetLeaveOpen |
    SetSettingsTab |
    SetLockDialogOpen |
    SetFileSharingSupported |
    ToggleConsumerWindow |
    SetToolbarsVisible |
    SetPaging |
    SetDisplayMode |
    AddSelectedPeer |
    RemoveSelectedPeer |
    ClearSelectedPeers |
    SetSpotlights |
    ClearSpotlights |
    ToggleJoined |
    ToggleConsumerFullscreen |
    SetLobbyPeersPromotionInProgress |
    SetMuteAllInProgress |
    SetStopAllVideoInProgress |
    SetStopAllScreenSharingInProgress |
    SetCloseMeetingInProgress |
    SetClearChatInProgress |
    SetClearFileSharingInProgress |
    SetRoomPermissions |
    SetUserRoles |
    SetAllowWhenRoleMissing |
    SetHideSelfView |
    SetWhiteBoardStatus |
    SetJoinedRoomStatus |
    SetPresentationFileUploaderOpen;
