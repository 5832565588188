import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Lobby, RequestRole } from '../models/lobby.model';
import { PoolService } from './mediasoup/pool.service';

@Injectable({
    providedIn: 'root'
})
export class ListRequestRoleService {

    private changeRequestRoleList: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private RequestRoleListOpenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private requestRoleList: Map<string, RequestRole> = new Map<string, RequestRole>();
    constructor(
        private poolService: PoolService
        ) {
    }

    public getRequestRoleList(): Map<string, RequestRole> {
        return this.requestRoleList;
    }

    public getRequestRole(peerId): RequestRole {
        return this.requestRoleList.get(peerId);
    }

    public addRequestRoleList(requestPeers: Array<any>): void {
        this.requestRoleList = new Map<string, RequestRole>();
        requestPeers.forEach(element => {
            var remote = this.poolService?.getPeer(element.peerId);
            if (remote) {
                this.requestRoleList.set(element.peerId,
                    { peerId: element.peerId, displayName: remote.displayName, roleId: element.roleId });
            }
        });
        this.changeRequestRoleList.next(true);
    }

    public addRequestRole(request, isNew): void {
        this.requestRoleList.set(request.peerId, request);
        this.changeRequestRoleList.next(isNew);
    }

    public removeRequestRole(peerId: string): void {
        if(this.requestRoleList.has(peerId)){
            this.requestRoleList.delete(peerId);
            this.changeRequestRoleList.next(true);
        }
    }

    public onChangeRequestRoleList(): Observable<boolean> {
        return this.changeRequestRoleList.asObservable();
    }

    public setRequestRoleListOpen(flag: boolean): void {
        this.RequestRoleListOpenSubject.next(flag);
    }

    public onRequestRoleListOpen(): Observable<boolean> {
        return this.RequestRoleListOpenSubject.asObservable();
    }
}
