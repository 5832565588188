import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DeviceCountingService{
    constructor() {
    }

    private micCount = 0;

    private webcamCount = 0;

    private micCountSubject = new BehaviorSubject(this.micCount);

    // private webcamCountSubject = new BehaviorSubject(this.webcamCount);

    private volumeAudio =  10;
    private volumeAudioSubject = new BehaviorSubject(this.volumeAudio);

    public setVolumeAudio(level: number):void{
        this.volumeAudio =  level;
        this.volumeAudioSubject.next(this.volumeAudio);
    }

    public onVolumeAudio(): Observable<number>{
        return this.volumeAudioSubject.asObservable();
    }

    public getVolumeAudioSubject(): any {
        return this.volumeAudioSubject.getValue();
    }

    // public onWebcamNumber(): Observable<number>{
    //     return this.webcamCountSubject.asObservable();
    // }

    // public increaseWebcamNumber(): void{
    //     this.webcamCount += 1;
    //     this.webcamCountSubject.next(this.webcamCount);
    // }

    // public decreaseWebcamNumber(): void{
    //     this.webcamCount -= 1;
    //     this.webcamCountSubject.next(this.webcamCount);
    // }

    public onMicNumber(): Observable<number>{
        return this.micCountSubject.asObservable();
    }

}
