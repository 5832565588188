/* tslint:disable:typedef */
import {Chat} from './chat.model';
import * as ChatActions from './chat.actions';
import {RealtimeCommunicationState} from '../state';

const initialState: Chat =
    {
        order       : 'asc',
        isScrollEnd : true,
        messages    : [],
        count       : 0,
        countUnread : 0
    };

export type Action = ChatActions.All;

export const getChat = (state: RealtimeCommunicationState) => state.chat;

export function ChatReducer(state: Chat = initialState, action: Action)
{
    switch (action.type)
    {
        case ChatActions.ADD_MESSAGE:
        {
            const { message } = action.payload;

            return {
                ...state,
                messages    : [ ...state.messages, message ],
                count       : state.count + 1,
                countUnread : message.sender === 'response' ? ++state.countUnread : state.countUnread
            };

        }

        case ChatActions.ADD_CHAT_HISTORY:
        {
            const { chatHistory } = action.payload;

            chatHistory.forEach(
                (item, index) => { chatHistory[index].isRead = true; }
            );

            return {
                ...state,
                messages : chatHistory,
                count    : chatHistory.length
            };
        }

        case ChatActions.CLEAR_CHAT:
        {
            return {
                ...state,
                messages    : [],
                count       : 0,
                countUnread : 0
            };
        }

        case ChatActions.SORT_CHAT:
        {
            const { order } = action.payload;

            return { ...state, order };
        }

        case ChatActions.SET_IS_SCROLL_END:
        {
            const { flag } = action.payload;

            return { ...state, isScrollEnd: flag };
        }

        case ChatActions.SET_IS_MESSAGE_READ:
        {
            const { id, isRead } = action.payload;

            state.messages.forEach((key, index) =>
            {
                if (state.messages[index].time === Number(id))
                {
                    state.messages[index].isRead = isRead;

                    state.countUnread--;
                }
            });

            return { ...state };
        }

        default:
            return state;
    }
}
