import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {LocalParticipant} from '../models/local-participant.model';
import {UpdateProducerInput} from '../models/producer.model';

@Injectable({
    providedIn: 'root'
})
export class LocalParticipantService {
    private roles: number[] = [];
    private localParticipant: LocalParticipant = new LocalParticipant();
    private isSpeaking: BehaviorSubject<LocalParticipant> = new BehaviorSubject<LocalParticipant>(null);
    private isRU: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    private localParticipantSubject: LocalParticipant = this.localParticipant;
    private localSharingScreenSubject = new BehaviorSubject<any>(null);
    private localWebcamMediaStreamSubject = new BehaviorSubject<MediaStream>(null);
    private peerIDSubject = new BehaviorSubject<string>('');
    // private roleGroupSubject = new BehaviorSubject<string>('');
    private displayNameSubject = new BehaviorSubject<string>('');
    private rolesSubject = new BehaviorSubject<number[]>(null);
    private roleGroupSubject : string = '';

    constructor() {}

    public getLocalParticipant(): LocalParticipant {
        return this.localParticipant;
    }

    public setLocalParticipant(localParticipant: LocalParticipant): void{
        this.localParticipant = localParticipant;
    }

    public updateLocalProducer(producerId: string, kind: 'audio' | 'webcam' | 'screen' | 'audioscreen'): void {
        this.localParticipant[kind].producerId = producerId;
        this.localParticipant[kind].state = !!producerId;
    }

    // public getSharingLocalScreen(): any{
    //     return this.localSharingScreenSubject.value;
    // }

    // public getWebcamLocalTrack(): MediaStream{
    //     return this.localWebcamMediaStreamSubject.value;
    // }

    public shareLocalScreen(screen: any): void {
        this.localSharingScreenSubject.next(screen);
    }

    public onShareLocalScreen(): Observable<any> {
        return this.localSharingScreenSubject.asObservable();
    }

    public sendLocalWebcamMediaStream(mediaStream: MediaStream): void{
        this.localWebcamMediaStreamSubject.next(mediaStream);
    }

    public onLocalWebcamMediaStream(): Observable<MediaStream>{
        return this.localWebcamMediaStreamSubject.asObservable();
    }

    public getLocalWebcamMediaStream(): MediaStream {
        return this.localWebcamMediaStreamSubject.value;
    }

    public setIsMuted(status: boolean): void{
        this.localParticipant.audio.state = status;
    }

    public setPeerID(id: string): void {
        this.localParticipant.id = id;
        if (id.endsWith("recordHiddenUser")) this.localParticipant.isRecordUser = true;
        this.peerIDSubject.next(id);
        this.localParticipantSubject = this.localParticipant;
    }

    public getPeerId(): string {
        return this.peerIDSubject.value;
    }

    public setDisplayName(name: string): void{
        this.localParticipant.displayName = name;
        this.displayNameSubject.next(name);
    }

    public getDisplayName(): string{
        return this.displayNameSubject.value;
    }

    public onDisplayName(): Observable<string>{
        return this.displayNameSubject.asObservable();
    }

    public setRaiseHand(isRaiseHand: boolean): void{
        this.localParticipant.isRaisedHand = isRaiseHand;
    }

    public setRoleGroup(role: string): void {
        this.roleGroupSubject = role;
    }

    public getRoleGroup(): string{
        return this.roleGroupSubject;
    }

    public onPeerID(): Observable<string> {
        return this.peerIDSubject.asObservable();
    }

    public addRole(roleId: number): void{
        if (this.roles.includes(roleId)) return;
        this.roles.push(roleId);
        this.rolesSubject.next(this.roles);

        this.localParticipant.roles = this.roles;
        this.localParticipantSubject = this.localParticipant;
    }

    public removeRole(roleId: number): void{
        this.roles = this.roles.filter((role: number) => role !== roleId);
        this.rolesSubject.next(this.roles);

        this.localParticipant.roles = this.roles;
        this.localParticipantSubject = this.localParticipant;
    }

    public setRoles(roles: number[]): void {
        this.roles = roles;
        this.rolesSubject.next(this.roles);

        this.localParticipant.roles = this.roles;
        this.localParticipantSubject = this.localParticipant;
    }

    public getRolesSubject(): number[]{
        return this.rolesSubject.value;
    }

    public onRoles(): Observable<number[]>{
        return this.rolesSubject.asObservable();
    }

    public onSpeaking(): Observable<LocalParticipant> {
        return this.isSpeaking.asObservable();
    }

    private setSpeaking(local: LocalParticipant): void {
        this.isSpeaking.next(local);
    }

    public setIsSpeaking(isSpeaking: boolean): void {
        if (isSpeaking) this.setSpeaking(this.getLocalParticipant());
        this.localParticipant.isSpeaking = isSpeaking;
    }

    public setIsSharing(isSharing: boolean): void {
        this.localParticipant.isSharing = isSharing;
    }

    public setIsRecordUser(isRU: boolean): void {
        this.localParticipant.isRecordUser = isRU;
        this.isRU.next(true);
    }

    public getIsRecordUser(): boolean {
        return this.localParticipant.isRecordUser;
    }

    public setIsRU(flag: boolean): void {
        this.isRU.next(flag);
    }

    public onIsRU(): Observable<boolean> {
        return this.isRU.asObservable();
    }

    public setPin(flag: boolean): void {
        this.localParticipant.isPin = flag;
    }
}
