import urlRegex from 'url-regex';
import { Participant } from './participant.model';

export type MSG_TYPE = 'message' | 'file' | 'media';
export type FILE_TYPE = "PDF" | "WordDocument" | "ExcelDocument" | "PowerPointDocument" | "Document";

export const TOPIC_PUBLIC_CHAT = "PUBLIC_CHAT";

/* 
export class Chatting {
    id: string;
    type: 'message' | 'file' | 'media';
    time = Date.now();
    sender: string;
    isRead: boolean;
    name: string;
    picture: any;
    content: any;
    hasLink: boolean = false;
    topic: string;
    fileName: string; //Bổ sung fileName để hiển thị tên file nếu tin nhăn là dạng file hoặc media
    fileIcon: string; //Bổ sung fileicon để hiển thị icon file nếu tin nhăn là dạng file hoặc media
} */

export interface IMessage {
    id: string;
    topic: string;
    type: MSG_TYPE;
    time: number;
    sender: string;
    name: string; // sender display name
    isRead: boolean;
    hasLink: boolean;
    content: string;
}

export class BaseMessage implements IMessage {
    id: string = "";
    topic: string;
    type: MSG_TYPE;
    time: number;
    sender: string;
    name: string; // sender display name
    isRead: boolean;
    hasLink: boolean;
    content: string;
    constructor(sender: Participant, content: string) {
        this.id = "";
        this.topic = "";
        this.time = Date.now();
        this.sender = sender.id;
        this.name = sender.displayName;
        this.content = content;
        this.isRead = false;
    }
}

export class TextMessage extends BaseMessage {
    constructor(sender: Participant, content: string) {
        super(sender, content);
        this.type = "message" as MSG_TYPE;
        this.time = Date.now();
        this.isRead = false;
        this.content = content;
        this.hasLink = isContainLink(content);
    }
}

export class MediaMessage extends BaseMessage {
    fileName: string;
    constructor(sender: Participant, fileName: string, location: string) {
        super(sender, location);
        this.type = "media" as MSG_TYPE;
        this.fileName = fileName;
        this.hasLink = false;
    }
}

export class FileMessage extends BaseMessage {
    fileName: string;
    shortName: string;
    fileIcon: FILE_TYPE;
    constructor(sender: Participant, fileName: string, location: string) {
        super(sender, location);
        this.type = "file" as MSG_TYPE;
        this.fileName = fileName;
        this.fileIcon = getFileIcon(fileName);
    }
}

function isContainLink(content: string): boolean {
    const urlList = content.match(urlRegex());
    return (urlList && urlList.length > 0);
}

function getFileIcon(fileName: string): FILE_TYPE {
    if (!fileName) return null;
    const ext = fileName.match(/\.[0-9a-z]+$/i)[0];
    switch (ext) {
        case '.pdf':
            return 'PDF';
        case '.doc':
        case '.docx':
        case '.odt':
        case '.rtf':
            return 'WordDocument';
        case '.xls':
        case '.xlsx':
        case '.ods':
            return 'ExcelDocument';
        case '.ppt':
        case '.pptx':
        case '.odp':
            return 'PowerPointDocument';
        default:
            return 'Document';
    }
}
export interface Conversation {
    topicName: string,
    status: boolean,
    unreadMessageCount: number,
    history: BaseMessage[]
}

export class ChatBox {
    justify: string;
    kind: string;
    style: string;
    start = true;
    message: BaseMessage;
    constructor(message: BaseMessage) {
        this.message = message;
    }
}

export class LocalChatBox extends ChatBox {
    constructor(message: BaseMessage) {
        super(message);
        this.justify = 'end';
        this.kind = 'local';
        this.style = 'chat-box-local';
    }
}

export class RemoteChatBox extends ChatBox {
    constructor(message: BaseMessage) {
        super(message);
        this.justify = 'start';
        this.kind = 'remote';
        this.style = 'chat-box-remote';
    }
}
