<div id="chat-box" #chatboxListView class="chat-holder"
     [ngClass]="type === 'chatting' ? 'chat-holder' : 'private-chat-holder'">
    <div #chatboxList class="conversation" >
            <vnpt-tabset
            vSize="small"
            [vTabBarGutter]="langChanges == 'vi' ? 20 : 50"
            vCentered="true"
            [(vSelectedIndex)]="resetTab"
            [vTabBarStyle]="{marginBottom: '16px', color: 'black', fontWeight: '600'}">
                <vnpt-tab *ngFor="let tab of listOfOption" [vTitle]="titleTab" (vClick)="filter(tab.value)" >
                    <ng-template #titleTab>
                        {{ tab.label }}
                        <vnpt-badge *ngIf="unseenMessage && tab.label == ('chatting.filter.all' | vI18n:{}:'Tất cả')" [vDot]="1" [vStyle]="{ backgroundColor: '#52C41A'}" class="unseenMessage">
                        </vnpt-badge>
                      </ng-template>
                </vnpt-tab>
            </vnpt-tabset>
        <span *ngIf="listChatBox.length === 0" vnpt-typography class="no-message">{{'chatting.noMessage' | vI18n:{}:'Chưa có tin nhắn'}}</span>
        <ag-virtual-scroll #vs  [items]="listChatBox" [height]="keyFilter == 'all' ? 'calc(100vh - 160px)' : 'calc(100vh - 125px)'" min-row-height="40" (onItemsRender)= "onItemsRender($event)">
            <div vnpt-row *ngFor="let chatbox of vs.items; trackBy: trackById" [vJustify]="chatbox.justify" class="chat-box-holder">
                <div [style.height.px]="chatbox.height">
                    <span *ngIf="chatbox.start" vnpt-row [vJustify]="chatbox.justify"
                            vnpt-typography class="display-name">
                        {{ chatbox.kind === 'local'? ('list-participant.me' | vI18n:{}:'Tôi'): chatbox.message.name }}
                    </span>
                    <div class="row-message">
                        <button *ngIf="chatbox.kind === 'local'" vnpt-button vtype="link" (click)="deleteMessage(chatbox)" class="delete-button">
                            <i vnpt-icon vType="Delete"></i>
                        </button>
                    
                        <div *ngIf="chatbox.message.type !== 'media'" class="chat-box" [ngClass]="[chatbox.style]">
                            <span *ngIf="chatbox.message.type === 'message' && !chatbox.message.hasLink" vnpt-typography class="msg" [innerText]="chatbox.message.content"></span>
                            <span *ngIf="chatbox.message.type === 'message' && chatbox.message.hasLink" vnpt-typography class="msg2" [innerHTML]="chatbox.message.content"></span>
                            <div *ngIf="chatbox.message.type === 'file'" vnpt-row>
                                <div vnpt-col class="file-icon">
                                    <i vnpt-icon [vType]="chatbox.message.fileIcon"></i>
                                </div>
                                <div vnpt-col class="file-info">
                                    <p vnpt-typography class="file-name" [title]="chatbox.message.fileName">{{ chatbox.message.shortName }}</p>
                                    <vnpt-progress *ngIf="chatbox.message.content.hasOwnProperty('progress')" [vPercent]="chatbox.message.content.progress" vStatus="active" vSize="small"></vnpt-progress>
                                    <!-- <span *ngIf="!chatbox.message.content.hasOwnProperty('progress')" vnpt-typography class="file-size">{{ formatBytes(chatbox.message.content.size) }} -</span> -->
                                    <a *ngIf="!chatbox.message.content.hasOwnProperty('progress')" class="file-size" style="color: #62BCF9;" [download]="chatbox.message.fileName" target="_blank" [href]="chatbox.message.content">Tải xuống</a>
                                </div>
                            </div>                        
                            <span vnpt-typography class="sent-time">{{ chatbox.message.time | date:'h:mm a' }}</span>
                        </div>
                        <div *ngIf="chatbox.message.type === 'media'" class="chat-box-image" [ngClass]="[chatbox.style]">
                            <div class="image-content" vnpt-row>
                                <div  vnpt-col >
                                    <img vnpt-image [vSrc]="chatbox.message.content" width="200" height="auto" />
                                    <!-- <vnpt-progress *ngIf="chatbox.message.content.hasOwnProperty('progress')" [vPercent]="chatbox.message.content.progress" vStatus="active" vSize="small"></vnpt-progress> -->
                                    <!-- <span *ngIf="!chatbox.message.content.hasOwnProperty('progress')" vnpt-typography class="file-size">{{ formatBytes(chatbox.message.content.size) }} -</span> -->
                                    <!-- <a *ngIf="!chatbox.message.content.hasOwnProperty('progress')" class="file-size" style="color: #62BCF9;" (click)="downloadFile(chatbox.message.content)">Tải xuống</a> -->
                                </div>
                                <span vnpt-typography class="sent-time">{{ chatbox.message.time | date:'h:mm a' }}</span>
                            </div>                        
                        </div>
                    </div>
                </div>
            </div>
        </ag-virtual-scroll>
    </div>
</div>