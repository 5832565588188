
<vnpt-modal vWidth="50vw" vCentered [vBodyStyle]="joinBodyStyle" [(vVisible)]="isVisible"
[vClosable]="isLock" [vMaskClosable]="isLock" [vKeyboard]="isLock" 
[vTitle]="configTitle" (vOnCancel)="handleCancel()" [vFooter]= "null">
    <ng-container *vModalContent>
        <vnpt-tabset [(vSelectedIndex)]="index" class="tabsetSetting" vSize="small"
        [vTabBarStyle]="{fontWeight: '600'}" #tabSetting vTabPosition="left">
            <vnpt-tab [vDisabled]="!isLock" (vClick)= "showTab('general')" vStyle="height: 100vh;" vTitle="{{'settings.general.title' | vI18n:{}:'Cài đặt chung'}}">
                <lib-general-setting *ngIf="tabShow.general"></lib-general-setting>
            </vnpt-tab>
            <vnpt-tab [vDisabled]="!isLock" (vClick)= "showTab('video')" style="height: 100vh;" vTitle="Video">
                <lib-video-setting *ngIf="tabShow.video" (changeIsLock)="changeIsLock($event)"></lib-video-setting>
            </vnpt-tab>
            <vnpt-tab [vDisabled]="!isLock" (vClick)= "showTab('audio')" style="height: 100vh;" vTitle="Audio">
                <lib-audio-setting *ngIf="tabShow.audio"></lib-audio-setting>
            </vnpt-tab>  
            <vnpt-tab [vDisabled]="!isLock" (vClick)= "showTab('information')" style="height: 100vh;" vTitle="{{'settings.information.title' | vI18n:{}:'Giới thiệu'}}">
                <lib-information *ngIf="tabShow.information"></lib-information>
            </vnpt-tab>
        </vnpt-tabset>
    </ng-container>
</vnpt-modal>

<ng-template #configTitle >
    <span>{{'settings.title' | vI18n:{}:''}}</span>
</ng-template>
