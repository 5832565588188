
import {
  AfterViewInit,
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  ViewChild,
  ElementRef,
} from "@angular/core";
import {
  LocalParticipantService,
  NotificationService,
  RoomService,
  WhiteboardService,
} from "../../../services";
import { RemoteParticipant } from "../../../models/remote-participant.model";
import { Observable, Observer, Subscription } from "rxjs";

import { Stage } from "konva/lib/Stage";
import { Layer } from "konva/lib/Layer";
import { VUploadChangeParam, VUploadFile } from "@vnpt/oneui-ui/upload";
import { environment } from "../../../../../../main/src/environments/environment";
import { permissions } from 'projects/realtime-communication/src/lib/permissions';
import { ActivatedRoute } from "@angular/router";
import { OneuiI18nService } from '@vnpt/oneui-i18n';
import { PERMISSIONS } from "../../../constants";
import { PoolService } from "../../../services/mediasoup/pool.service";

const WHITEBOARD_RATIAL = 16 / 9;
const STANDARD_DEMENSION = { width: 1920, height: 1080 };
@Component({
  selector: "lib-whiteboard",
  templateUrl: "./whiteboard.component.html",
  styleUrls: ["./whiteboard.component.less"],
})
export class WhiteboardComponent implements OnInit, OnDestroy, AfterViewInit {
  private _selection: string = "pencil";
  isVisible = false;
  shapes: any = [];
  stage!: Stage;
  layer!: Layer;
  inkColor: string = "#000000";
  //control_container: any;
  eraserSize: number = 10;
  brushSize: number = 3;
  brushOpacity: number = 0.8;
  serverRatio = 1;
  clientRatio = 1;
  isText: boolean = false;
  textNode: any;
  textArea: any;
  isTransformer: boolean = false;
  newKonvaData: any;
  transformers: any;
  isDisableUpload: boolean = false;
  apiUpload: string = "/api/edumeet-management/v1/whiteboard/upload";
  headerUpload: object = {
    sessionToken: "",
  };
  zoomSize: string = '';
  zoomSizeTimeout: any;
  canDrawWhiteboard: boolean = false;
  isModerator = false;
  textAreaTimout: any;
  cursorClass: string = "";

  constructor(
    private roomService: RoomService,
    private localParticipantService: LocalParticipantService,
    private notification: NotificationService,
    private poolService: PoolService,
    private whiteboardService: WhiteboardService,
    private readonly changeDetector: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private i18nService:OneuiI18nService
  ) { }

  currentPageIndex = 1;
  listRemoteParticipant: RemoteParticipant[] = [];
  listViewHeight: number;
  listVideoHeight: number;
  // listViewObserver: ResizeObserver;
  // listVideoObserver: ResizeObserver;
  whiteboardSizeObserver: ResizeObserver;
  subscriptions: Subscription[] = [];
  changePageTimeOut: any;

  @ViewChild("whiteboard") whiteboard: ElementRef;
  // @ViewChild("listView") listView: ElementRef;
  // @ViewChild("listVideo") listVideo: ElementRef;
  @ViewChild("control_container") control_container: ElementRef;
  @ViewChild("zoom_size") zoom_size: ElementRef;

  ngOnInit(): void {
    this.inkColor = "#" + Math.floor(Math.random() * 16777215).toString(16);

    this.apiUpload = `${environment.domain.api}/api/edumeet-management/v1/whiteboard/upload`;
    this.headerUpload = {
      sessionToken: this.activatedRoute.snapshot.queryParams.sessionToken,
    };
    /* camera */
    this.currentPageIndex = this.poolService.currentPage;
    this.updateListRemoteParticipant();
    this.onInitEvent();
    /* end */

    this.setSelectionButton("");
    this.cursorClass = this.getCursorClass();
    const { width, height } = this._fitBoardDemension(
      this.whiteboard.nativeElement.clientWidth,
      this.whiteboard.nativeElement.clientHeight
    );
    this.stage = new Stage({
      container: "container",
      width,
      height,
    });
    this.layer = new Layer();
    this.stage.add(this.layer);
    this.addMouseListeners();
    this.handleEventKeydown();
    this.handleEventZoom();

    let resizeTimeout;
    // @ts-ignore
    this.whiteboardSizeObserver = new ResizeObserver((entries: any) => {
      if(resizeTimeout) clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        this.isTransformer = false;
        this.isDisableUpload = false;
        this.control_container?.nativeElement.classList.remove("hide_palette");
        this.newKonvaData = null;
        this.transformers = null;

        const newSize = this._fitBoardDemension(
          entries[0].contentRect.width,
          entries[0].contentRect.height
        );
        this.stage.width(newSize.width);
        this.stage.height(newSize.height);
        this.serverRatio = STANDARD_DEMENSION.width / newSize.width;
        this.clientRatio = newSize.width / STANDARD_DEMENSION.width;
        this.layer.destroyChildren();
        this.layer.draw();
        //Gửi message lấy lại dữ liệu cũ khi mới vào phòng hoặc load lại phòng
        this.whiteboardService.sendMessageDataHistory();
        clearTimeout(resizeTimeout);
      }, 100);
    });

    // Gửi message lấy lại dữ liệu cũ khi mới vào phòng hoặc load lại phòng
    //this.whiteboardService.sendMessageDataHistory();

    //Lắng nghe khi có đường vẽ mới từ client khác
    this.subscriptions.push(this.whiteboardService.onWbData().subscribe((data: any) => {
      if (data) {
        if (data.type == "image") {
          const component = this;
          this.whiteboardService.image(data.url, function (imageNode) {
            imageNode.setAttrs({
              id: data.id,
              x: data.x * component.clientRatio,
              y: data.y * component.clientRatio,
              width: data.width * component.clientRatio,
              height: data.height * component.clientRatio,
              scaleX: data.scaleX,
              scaleY: data.scaleY,
              skewX: data.skewX,
              skewY: data.skewY,
              rotation: data.rotation,
            });
            component.shapes.push(imageNode);
            component.layer.add(imageNode);
          });
        } else {
          let konvaData = this.whiteboardService.convertDataToKonvaData(
            data,
            this.clientRatio
          );
          this.shapes.push(konvaData);
          this.layer.add(konvaData);
        }
      }
    }));

    //undo lại đường vẽ mới nhất của mình theo id
    this.subscriptions.push(this.whiteboardService.onWbUndo().subscribe((id: string) => {
      if (id) {
        const removedShapes = this.shapes.filter((as) => as.attrs.id == id);
        removedShapes.forEach((removedShape) => {
          if (removedShape) {
            removedShape.remove();
          }
        });
        this.layer.draw();
      }
    }));

    //clear hết dữ liệu tất cả user
    this.subscriptions.push(this.whiteboardService.onWbClear().subscribe((clear: boolean) => {
      if (clear) {
        this.layer.destroyChildren();
        this.layer.draw();
      }
    }));

    //vẽ lại dữ liệu cũ khi mới vào phòng hoặc load lại phòng
    this.subscriptions.push(this.whiteboardService.onWbDataHistory().subscribe((data: Array<any>) => {
      if (data && data.length > 0) {
        this.generateDataHistory(data, 0);
      }
    }));

    //Kiểm tra quyền vẽ whiteboard
    this.subscriptions.push(this.localParticipantService.onRoles().subscribe((roles) => {
      if (!roles) { return; }
      this.isModerator = roles.includes(PERMISSIONS.MODERATOR.id);
      this.canDrawWhiteboard = this.roomService.hasPermission(permissions.WHITE_BOARD);
      if (this.canDrawWhiteboard)
        this.setSelectionButton("pencil");
      else{
        this.setSelectionButton("");
        this.isDisableUpload = false;
        this.isTransformer = false;
        this.isText = false;
        if(this.newKonvaData) this.newKonvaData.destroy();
        if(this.transformers) this.transformers.nodes([]);
        if(this.textNode) this.textNode.destroy();
        if(this.textArea){
          document.body.removeChild(this.textArea);
          this.textArea = null;
        }
        this.layer.draw();
      }
      this.cursorClass = this.getCursorClass();
    }));
  }

  generateDataHistory(data, count) {
    if (count >= data.length) {
      return;
    }
    let element = data[count];
    if (element.type == "image") {
      const component = this;
      this.whiteboardService.image(element.url, function (imageNode) {
        imageNode.setAttrs({
          id: element.id,
          x: element.x * component.clientRatio,
          y: element.y * component.clientRatio,
          width: element.width * component.clientRatio,
          height: element.height * component.clientRatio,
          scaleX: element.scaleX,
          scaleY: element.scaleY,
          skewX: element.skewX,
          skewY: element.skewY,
          rotation: element.rotation,
        });
        component.shapes.push(imageNode);
        component.layer.add(imageNode);
        component.generateDataHistory(data, count + 1);
      });
    } else {
      let konvaData = this.whiteboardService.convertDataToKonvaData(
        element,
        this.clientRatio
      );
      this.shapes.push(konvaData);
      this.layer.add(konvaData);
      this.generateDataHistory(data, count + 1);
    }
  }

  _fitBoardDemension(w, h) {
    if (w / WHITEBOARD_RATIAL < h)
      return { width: w, height: w / WHITEBOARD_RATIAL };
    else return { width: h * WHITEBOARD_RATIAL, height: h };
  }

  openModalSizeOpacity(): void {
    this.isVisible = true;
  }

  closeModalSizeOpacity(): void {
    this.isVisible = false;
  }

  setSelectionButton(value: string) {
    this._selection = this.canDrawWhiteboard ? value : '';
  }

  getSelectionButton(): string {
    return this._selection;
  }

  getTypeButton(value: string) {
    if (this.getSelectionButton() == value) {
      return "primary";
    } else {
      return "default";
    }
  }

  addMouseListeners(): void {
    const component = this;
    let lastData: any;
    let isPaint: boolean = false;
    let uid = "";
    let pos: any;

    this.stage.on("mousedown touchstart", () => {
      pos = this.stage.getPointerPosition();
      var scale = this.stage.scaleX();
      var posScale = {
        x: (pos.x - this.stage.x()) / scale,
        y: (pos.y - this.stage.y()) / scale,
      };
      if (!isPaint) uid = this.whiteboardService.generateUID("s");
      
      switch (this.getSelectionButton()) {
        case "pencil":
          if (!isPaint) {
            isPaint = true;
            lastData = this.whiteboardService.pencil(
              posScale,
              this.brushSize * this.clientRatio,
              this.inkColor,
              this.brushOpacity,
              uid
            );
            this.shapes.push(lastData);
            this.layer.add(lastData);
            this.control_container?.nativeElement.classList.add("hide_palette");
          }
          break;

        case "line":
          if (isPaint) {//Kết thúc vẽ
            isPaint = false;
            component.control_container.nativeElement?.classList.remove("hide_palette");
            if (lastData) {
              //send data pencil
              let points = [...lastData.points()].map(
                (value) => value * component.serverRatio
              );
              this.whiteboardService.sendMessageKonvaData({
                type: "line",
                id: lastData.id(),
                color: lastData.stroke(),
                size: component.brushSize,
                opacity: lastData.opacity(),
                children: points,
              });
              lastData = null;
            }
          } else {//vẽ đường mới
            isPaint = true;
            lastData = this.whiteboardService.line(
              posScale,
              this.brushSize * this.clientRatio,
              this.inkColor,
              this.brushOpacity,
              uid
            );
            this.shapes.push(lastData);
            this.layer.add(lastData);
            this.control_container.nativeElement?.classList.add("hide_palette");
          }
          break;

        case "eraser":
          if (!isPaint) {
            isPaint = true;
            lastData = this.whiteboardService.erase(
              posScale,
              this.eraserSize * this.clientRatio,
              uid
            );
            this.shapes.push(lastData);
            this.layer.add(lastData);
            this.control_container.nativeElement?.classList.add("hide_palette");
          }
          break;

        case "text":
          if (!this.isText) {
            this.handleAddText(posScale);
          }
          break;

        case "circle":
          if (!this.isTransformer) {
            this.handleAddCircle(posScale, uid);
          }
          break;

        case "rect":
          if (!this.isTransformer) {
            this.handleAddRect(posScale, uid);
          }
          break;

        case "triangle":
          if (!this.isTransformer) {
            this.handleAddTriangle(posScale, uid);
          }
          break;
        default:
          break;
      }
    });

    this.stage.on("mouseup touchend", () => {
      switch (this.getSelectionButton()) {
        case "pencil":
          isPaint = false;
          this.control_container.nativeElement?.classList.remove("hide_palette");
          if (lastData) {
            //send data pencil
            let points = [...lastData.points()].map(
              (value) => value * this.serverRatio
            );
            this.whiteboardService.sendMessageKonvaData({
              type: "pencil",
              id: lastData.id(),
              color: lastData.stroke(),
              size: this.brushSize,
              opacity: lastData.opacity(),
              children: points,
            });
            lastData = null;
          }
          break;
        case "eraser":
          isPaint = false;
          this.control_container.nativeElement?.classList.remove("hide_palette");
          if (lastData) {
            //send data pencil
            let points = [...lastData.points()].map(
              (value) => value * this.serverRatio
            );
            this.whiteboardService.sendMessageKonvaData({
              type: "pencil",
              id: uid,
              color: lastData.stroke(),
              size: this.eraserSize,
              opacity: lastData.opacity(),
              children: points,
            });
            lastData = null;
          }
          break;
        default:
          break;
      }
    });

    this.stage.on("mousemove touchmove", (e) => {
      e.evt.preventDefault();
      if (!isPaint) {
        return;
      }
      var position: any = this.stage.getPointerPosition();
      var scale = this.stage.scaleX();
      var posScale = {
        x: (position.x - this.stage.x()) / scale,
        y: (position.y - this.stage.y()) / scale,
      };
      switch (this.getSelectionButton()) {
        case "pencil":
        case "eraser":
          var newPoints = lastData.points().concat([posScale.x, posScale.y]);
          lastData.points(newPoints);
          this.layer.batchDraw();
          break;
        case "line":
          const points = lastData.points().slice();
          points[2] = posScale.x;
          points[3] = posScale.y;
          lastData.points(points);
          this.layer.batchDraw();
          break;
        default:
          break;
      }
    });
  }

  sendMessageUndo(): void {
    if (!this.canDrawWhiteboard) return;
    this.whiteboardService.sendMessageUndo();
  }

  clearBoard(): void {
    if (!this.canDrawWhiteboard) return;
    this.whiteboardService.sendMessageClear();
  }

  /**
   * Sự kiện zoom
   */
  handleEventZoom(): void {
    var scaleBy = 0.5;
    this.stage.on("wheel", (e) => {
      // stop default scrolling
      e.evt.preventDefault();

      var oldScale = this.stage.scaleX();
      var pointer = this.stage.getPointerPosition();

      let tmpX = (pointer.x - this.stage.x()) / oldScale;
      let tmpY = (pointer.y - this.stage.y()) / oldScale;
      var mousePointTo = {
        x: tmpX,
        y: tmpY,
      };

      // how to scale? Zoom in? Or zoom out?
      let direction = e.evt.deltaY > 0 ? 1 : -1;

      // when we zoom on trackpad, e.evt.ctrlKey is true
      // in that case lets revert direction
      if (e.evt.ctrlKey) {
        direction = -direction;
      }

      var newScale = direction > 0 ? oldScale + scaleBy : oldScale - scaleBy;
      if (newScale < 1 || newScale > 8) return;

      //Hiển thị kích thước zoom trên màn hình
      this.zoomSize = (newScale * 100).toString() + '%';
      this.zoom_size?.nativeElement.classList.remove("hide-element");
      if(this.zoomSizeTimeout) clearTimeout(this.zoomSizeTimeout);
      this.zoomSizeTimeout = setTimeout(() => {
        this.zoom_size?.nativeElement.classList.add("hide-element");
        clearTimeout(this.zoomSizeTimeout);
      }, 1000);

      this.stage.scale({ x: newScale, y: newScale });
      var newO = {
        x: 0,
        y: 0,
      }
      if (newScale > 1) {
        let newPos = {
          x: pointer.x - mousePointTo.x * newScale,
          y: pointer.y - mousePointTo.y * newScale,
        }

        newO.x = newPos.x > 0 ? 0 : newPos.x;
        newO.y = newPos.y > 0 ? 0 : newPos.y;

        //tọa độ điểm cuối sau scale
        let widthSc = this.stage.width() * newScale + newPos.x;
        let heightSc = this.stage.height() * newScale + newPos.y;

        if (widthSc < this.stage.width()) {
          newO.x = newO.x + (this.stage.width() - widthSc);
        }

        if (heightSc < this.stage.height()) {
          newO.y = newO.y + (this.stage.height() - heightSc);
        }
      }
      this.stage.position(newO);
    });
  }

  /**
   * bắt sự kiện enter
   * circle, triangle, square, image
   */
  handleEventKeydown() {
    var container = this.stage.container();
    container.tabIndex = 1;
    container.focus();

    const component = this;
    container.addEventListener("keydown", function (e) {
      if (e.key === "Enter") {
        e.preventDefault();
        if (component.isTransformer) {
          component.isTransformer = false;
          component.isDisableUpload = false;
          component.control_container.nativeElement?.classList.remove("hide_palette");
          component.newKonvaData.draggable(false);
          component.transformers.nodes([]);
          component.sendMessageKonvaData();
        }
      } else if (e.key === "Delete" || e.key === "Escape") {
        if (component.isTransformer) {
          e.preventDefault();
          component.isTransformer = false;
          component.newKonvaData.destroy();
          component.transformers.nodes([]);
          component.layer.draw();
          component.isDisableUpload = false;
          component.control_container.nativeElement?.classList.remove("hide_palette");
        }
      }
      container.blur();
    });
  }

  /**
   * Triangle
   */
  handleAddTriangle(pos: any, uid: string): void {
    this.control_container?.nativeElement.classList.add("hide_palette");
    this.isTransformer = true;
    let radius = this.stage.height() / 10;

    this.newKonvaData = this.whiteboardService.triangle(
      pos,
      this.brushSize * this.clientRatio,
      this.inkColor,
      this.brushOpacity,
      radius,
      uid
    );

    this.shapes.push(this.newKonvaData);
    this.layer.add(this.newKonvaData);

    this.transformers = this.addTransformerKonva();
  }

  /**
   * Rect
   */
  handleAddRect(pos: any, uid: string): void {
    this.control_container?.nativeElement.classList.add("hide_palette");
    this.isTransformer = true;
    let len = this.stage.height() / 10;

    this.newKonvaData = this.whiteboardService.rect(
      pos,
      this.brushSize * this.clientRatio,
      this.inkColor,
      this.brushOpacity,
      len,
      uid
    );

    this.shapes.push(this.newKonvaData);
    this.layer.add(this.newKonvaData);

    this.transformers = this.addTransformerKonva();
  }

  /**
   * Circle
   */
  handleAddCircle(pos: any, uid: string): void {
    this.control_container?.nativeElement.classList.add("hide_palette");
    this.isTransformer = true;
    let radius = this.stage.height() / 10;

    this.newKonvaData = this.whiteboardService.circle(
      pos,
      this.brushSize * this.clientRatio,
      this.inkColor,
      this.brushOpacity,
      radius,
      uid
    );
    this.shapes.push(this.newKonvaData);
    this.layer.add(this.newKonvaData);

    this.transformers = this.addTransformerKonva();
  }

  /**
   * Gửi message dữ liệu circle qua socket
   */
  sendMessageKonvaData() {
    let shapesData = null;
    switch (this.newKonvaData.className) {
      case "Ellipse":
        shapesData = {
          type: "circle",
          id: this.newKonvaData.id(),
          x: this.newKonvaData.x() * this.serverRatio,
          y: this.newKonvaData.y() * this.serverRatio,
          scaleX: this.newKonvaData.scaleX(),
          scaleY: this.newKonvaData.scaleY(),
          skewX: this.newKonvaData.skewX(),
          skewY: this.newKonvaData.skewY(),
          radiusX: this.newKonvaData.radiusX() * this.serverRatio,
          radiusY: this.newKonvaData.radiusY() * this.serverRatio,
          stroke: this.newKonvaData.stroke(),
          size: this.newKonvaData.strokeWidth() * this.serverRatio,
          opacity: this.newKonvaData.opacity(),
          rotation: this.newKonvaData.rotation()
        };
        break;
      case "Rect":
        shapesData = {
          type: "rect",
          id: this.newKonvaData.id(),
          x: this.newKonvaData.x() * this.serverRatio,
          y: this.newKonvaData.y() * this.serverRatio,
          width: this.newKonvaData.width() * this.serverRatio,
          height: this.newKonvaData.height() * this.serverRatio,
          scaleX: this.newKonvaData.scaleX(),
          scaleY: this.newKonvaData.scaleY(),
          skewX: this.newKonvaData.skewX(),
          skewY: this.newKonvaData.skewY(),
          stroke: this.newKonvaData.stroke(),
          size: this.newKonvaData.strokeWidth() * this.serverRatio,
          opacity: this.newKonvaData.opacity(),
          rotation: this.newKonvaData.rotation()
        };
        break;
      case "RegularPolygon":
        shapesData = {
          type: "triangle",
          id: this.newKonvaData.id(),
          x: this.newKonvaData.x() * this.serverRatio,
          y: this.newKonvaData.y() * this.serverRatio,
          sides: 3,
          scaleX: this.newKonvaData.scaleX(),
          scaleY: this.newKonvaData.scaleY(),
          skewX: this.newKonvaData.skewX(),
          skewY: this.newKonvaData.skewY(),
          radius: this.newKonvaData.radius() * this.serverRatio,
          stroke: this.newKonvaData.stroke(),
          size: this.newKonvaData.strokeWidth() * this.serverRatio,
          opacity: this.newKonvaData.opacity(),
          rotation: this.newKonvaData.rotation()
        };
        break;
      case "Image":
        shapesData = {
          type: "image",
          id: this.newKonvaData.id(),
          height: this.newKonvaData.getHeight() * this.serverRatio,
          width: this.newKonvaData.getWidth() * this.serverRatio,
          x: this.newKonvaData.x() * this.serverRatio,
          y: this.newKonvaData.y() * this.serverRatio,
          rotation: this.newKonvaData.rotation(),
          scaleX: this.newKonvaData.scaleX(),
          scaleY: this.newKonvaData.scaleY(),
          skewX: this.newKonvaData.skewX(),
          skewY: this.newKonvaData.skewY(),
          url: this.newKonvaData.image().currentSrc,
        };
        this.setSelectionButton("pencil");
        this.cursorClass = this.getCursorClass();
        this.isDisableUpload = false;
        this.control_container?.nativeElement.classList.remove("hide_palette");
        break;
      default:
        break;
    }
    if (shapesData) this.whiteboardService.sendMessageKonvaData(shapesData);
    this.newKonvaData = null;
  }

  addTransformerKonva() {
    const tr = this.whiteboardService.getTransformerKonva(
      this.newKonvaData,
      this.stage
    );
    this.layer.add(tr);

    //Tính toán lại width height theo scale, giữ nguyên scale = 1
    tr.on('transform', () => {
      // adjust size to scale
      this.newKonvaData.width(this.newKonvaData.width() * this.newKonvaData.scaleX());
      this.newKonvaData.height(this.newKonvaData.height() * this.newKonvaData.scaleY());
      // reset scale to 1
      this.newKonvaData.scaleX(1);
      this.newKonvaData.scaleY(1);
    });

    // we can use transformer event
    // or just shape event
    tr.on("dragmove", () => {
      const boxes = tr.nodes().map((node) => node.getClientRect());
      const box = this.getTotalBox(boxes);
      tr.nodes().forEach((shape) => {
        const absPos = shape.getAbsolutePosition();
        // where are shapes inside bounding box of all shapes?
        const offsetX = box.x - absPos.x;
        const offsetY = box.y - absPos.y;

        // we total box goes outside of viewport, we need to move absolute position of shape
        const newAbsPos = { ...absPos };
        if (box.x < 0) {
          newAbsPos.x = -offsetX;
        }
        if (box.y < 0) {
          newAbsPos.y = -offsetY;
        }
        if (box.x + box.width > this.stage.width()) {
          newAbsPos.x = this.stage.width() - box.width - offsetX;
        }
        if (box.y + box.height > this.stage.height()) {
          newAbsPos.y = this.stage.height() - box.height - offsetY;
        }
        shape.setAbsolutePosition(newAbsPos);
      });
    });
    return tr;
  }

  /**
   * Tính toán vị trí các nút transformer
   */
  getTotalBox(boxes) {
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    boxes.forEach((box) => {
      minX = Math.min(minX, box.x);
      minY = Math.min(minY, box.y);
      maxX = Math.max(maxX, box.x + box.width);
      maxY = Math.max(maxY, box.y + box.height);
    });
    return {
      x: minX,
      y: minY,
      width: maxX - minX,
      height: maxY - minY,
    };
  }

  /**
   * Text
   */
  handleAddText(pos: any): void {
    this.control_container?.nativeElement.classList.add("hide_palette");
    this.isText = true;
    const component = this;
    let uid = this.whiteboardService.generateUID("s");

    this.textNode = this.whiteboardService.text(
      pos,
      this.brushSize * this.clientRatio,
      this.inkColor,
      this.brushOpacity,
      component.stage.width(),
      uid
    );
    this.shapes.push(this.textNode);
    this.layer.add(this.textNode);

    //textNode.on("dblclick dbltap", () => {
    // create textarea over canvas with absolute position

    // first we need to find position for textarea
    // how to find it?

    // at first lets find position of text node relative to the stage:
    var textPosition = this.textNode.getAbsolutePosition();

    // then lets find position of stage container on the page:
    var stageBox = this.stage.container().getBoundingClientRect();

    // so position of textarea will be the sum of positions above:
    var areaPosition = {
      x: stageBox.left + textPosition.x,
      y: stageBox.top + textPosition.y,
    };

    // create textarea and style it
    this.textArea = document.createElement("textarea");
    document.body.appendChild(this.textArea);

    this.textArea.value = this.textNode.text();
    this.textArea.style.position = "absolute";
    this.textArea.style.top = areaPosition.y + "px";
    this.textArea.style.left = areaPosition.x + "px";
    this.textArea.style.width = this.textNode.width().toString();
    this.textArea.style.color = "#000000";

    if(this.textAreaTimout) clearTimeout(this.textAreaTimout);
    this.textAreaTimout = setTimeout(function () {
      component.textArea.focus();
      clearTimeout(this.textAreaTimout);
    }, 0);

    this.textArea.addEventListener("keydown", function (e) {
      // hide on enter
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        component.textNode.text(component.textArea.value);
        document.body.removeChild(component.textArea);
        component.isText = false;
        component.control_container.nativeElement?.classList.remove("hide_palette");

        component.whiteboardService.sendMessageKonvaData({
          type: "text",
          id: component.textNode.id(),
          text: component.textNode.text(),
          x: component.textNode.x() * component.serverRatio,
          y: component.textNode.y() * component.serverRatio,
          fontSize: component.textNode.fontSize() * component.serverRatio,
          width: component.textNode.width() * component.serverRatio,
          fill: component.textNode.fill(),
          opacity: component.textNode.opacity(),
        });
      } else if (e.key === "Escape") {
        e.preventDefault();
        component.textNode.destroy();
        document.body.removeChild(component.textArea);
        component.isText = false;
        component.control_container.nativeElement?.classList.remove("hide_palette");
      }
    });
    //});
  }
  /**
   * Lưu whiteboard về file png
   */
  saveAsImage(): void {
    const dataUrl: string = this.stage.toDataURL({
      mimeType: "image/png",
      quality: 1,
      pixelRatio: 1,
    });

    const link = document.createElement("a");
    link.download = "whiteboard_image.png";
    link.href = dataUrl;
    link.click();
  }

  getCursorClass(): string {
    if (
      this.getSelectionButton() == "pencil" ||
      this.getSelectionButton() == "eraser"
    ) {
      return "pointer_cursor";
    } else if (this.getSelectionButton() == "text") {
      return "text_cursor";
    } else {
      return "default";
    }
  }

  /**
   * Thực hiện upload hình ảnh
   * @param info dữ liệu hình ảnh upload
   */
   beforeUpload = (file: VUploadFile, _fileList: VUploadFile[]) => {
    return new Observable((observer: Observer<boolean>) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.notification.error(this.i18nService.translate('whiteboard.formatError'),"");
        observer.complete();
        return;
      }
      observer.next(isJpgOrPng);
      observer.complete();
    });
  }

  handleUploadImage = (info: VUploadChangeParam): void => {
    if (!this.canDrawWhiteboard) return;
    if (info.file.status === "done") {
      this.control_container?.nativeElement.classList.add("hide_palette");
      this.setSelectionButton("image");
      this.isDisableUpload = true;
      let url = info.file.response.location;
      this.whiteboardService.image(
        url,
        this.generateImageData.bind(this),
        this.uploadImageError.bind(this)
      );
      this.stage.container().focus();
    } else if (info.file.status === "error") {
      this.control_container?.nativeElement.classList.remove("hide_palette");
      this.setSelectionButton("pencil");
      this.isDisableUpload = false;
    }
    this.cursorClass = this.getCursorClass();
  };

  uploadImageError(e) {
    this.notification.error(this.i18nService.translate("whiteboard.uploadImageError",{},"Không tải được hình ảnh"), "", {
      vPlacement: "topRight",
    });
    this.control_container?.nativeElement.classList.remove("hide_palette");
    this.setSelectionButton("pencil");
    this.cursorClass = this.getCursorClass();
    this.isDisableUpload = false;
  }

  generateImageData(imageNode) {
    this.isTransformer = true;
    let uid = this.whiteboardService.generateUID("s");
    let img = imageNode.image();
    let stage_width = this.stage.width();
    let stage_height = this.stage.height();
    const imgSize = this.whiteboardService._fitImgDemension(
      img.width,
      img.height,
      this.stage.width(),
      this.stage.height()
    );
    imageNode.setAttrs({
      id: uid,
      x: stage_width * 0.05,
      y: stage_height * 0.1,
      width: imgSize.width,
      height: imgSize.height,
      draggable: true,
      rotation: 0,
    });
    this.newKonvaData = imageNode;
    this.shapes.push(this.newKonvaData);
    this.layer.add(this.newKonvaData);
    this.transformers = this.addTransformerKonva();
  }

  /* camera */
  ngOnDestroy(): void {
    for (const subs of this.subscriptions) {
      subs.unsubscribe();
    }
    // this.listViewObserver.disconnect();
    // this.listVideoObserver.disconnect();
    this.whiteboardSizeObserver.disconnect();
    
    this.isDisableUpload = false;
    this.isTransformer = false;
    this.isText = false;
    if(this.newKonvaData) this.newKonvaData.destroy();
    if(this.transformers) this.transformers.nodes([]);
    if(this.textNode) this.textNode.destroy();
    if(this.textArea){
      document.body.removeChild(this.textArea);
      this.textArea = null;
    }
  }

  ngAfterViewInit(): void {
    // this.listViewObserver.observe(this.listView.nativeElement);
    // this.listVideoObserver.observe(this.listVideo.nativeElement);
    this.whiteboardSizeObserver.observe(this.whiteboard.nativeElement);
  }

  onInitEvent(): void {
    this.subscriptions.push(
      this.poolService.onUpdateCurrentPage.asObservable()
        .subscribe((flag: boolean) => {
          if (flag) {
            this.updateListRemoteParticipant();
          }
        })
    );

    this.subscriptions.push(
      this.poolService.onUpdateMaxItem.asObservable()
        .subscribe((flag: boolean) => {
          if (flag) {
            this.poolService.gotoPage(1);
            this.pagination();
          }
        })
    );

    // @ts-ignore
    // this.listVideoObserver = new ResizeObserver((entries: any) => {
    //   this.listViewHeight = this.listView.nativeElement.clientHeight;
    //   this.listVideoHeight = entries[0].contentRect.height;
    //   const isScrollbarShown = this.listViewHeight < this.listVideoHeight;
    //   this.triggerScrollbar(isScrollbarShown);
    // });
    // @ts-ignore
    // this.listViewObserver = new ResizeObserver((entries: any) => {
    //   this.listViewHeight = entries[0].contentRect.height;
    //   const isScrollbarShown = this.listViewHeight < this.listVideoHeight;
    //   this.triggerScrollbar(isScrollbarShown);
    // });
  }

  updateListRemoteParticipant(): void {
    this.currentPageIndex = this.poolService.currentPage;
    this.listRemoteParticipant = this.poolService.getAllPeers();//getListRemoteParticipantView();
    if (this.changePageTimeOut) clearTimeout(this.changePageTimeOut);
    this.changePageTimeOut = setTimeout(() => {
      this.startListWebcamConsumer(this.listRemoteParticipant);
      clearTimeout(this.changePageTimeOut);
    }, 1000);
    this.changeDetector.detectChanges();
  }

  getTotalPageIndex(): number {
    return this.poolService.totalPage;
  }

  stopListWebcamConsumer(remotes: RemoteParticipant[]): void {
    for (const remote of remotes) {
      remote.stopWebcam();
    }
  }

  startListWebcamConsumer(remotes: RemoteParticipant[]): void {
    for (const remote of remotes) {
      remote.startWebcam();
    }
  }

  nextPage(): void {
    this.poolService.nextPage();
    this.pagination();
  }

  previousPage(): void {
    this.poolService.prevPage();
    this.pagination();
  }

  pagination(): void {
    // TODO: change order of start/stop, should be start first and stop later for queue reprocessing
    // Hint: save old list current with new list avoid conflict common list.
    this.currentPageIndex = this.poolService.currentPage;
    const newList = this.poolService.getAllPeers();//getListRemoteParticipantView();
    const stopList = [];
    this.listRemoteParticipant.forEach(remote => {
      if (newList.indexOf(remote) == -1)
        stopList.push(remote);
    })
    this.stopListWebcamConsumer(stopList);
    this.updateListRemoteParticipant();
    this.changeDetector.detectChanges();
  }

  triggerScrollbar(isShown: boolean): void {
    // const listVideo = this.listVideo.nativeElement.classList;
    // isShown ? listVideo.remove("center-items") : listVideo.add("center-items");
  }
}