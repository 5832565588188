import * as PeerVolumesActions from './peer-volumes.actions';

export type Action = PeerVolumesActions.All;

const initialState = {};

export const PeerVolumesReducer = (state = initialState, action: Action) =>
{
    // switch (action.type)
    // {
        // case 'SET_ME':
        // {
        //     const {
        //         peerId
        //     } = action.payload;
        //
        //     return { ...state, [peerId]: -100 };
        // }
        // case 'ADD_PEER':
        // {
        //     const { peer } = action.payload;
        //
        //     return { ...state, [peer.id]: -100 };
        // }
        //
        // case 'REMOVE_PEER':
        // {
        //     const { peerId } = action.payload;
        //     const newState = { ...state };
        //
        //     delete newState[peerId];
        //
        //     return newState;
        // }

    //     case PeerVolumesActions.SET_PEER_VOLUME:
    //     {
    //         const { peerId } = action.payload;
    //         const dBs = action.payload.volume < -100 ? -100 : action.payload.volume;

    //         return { ...state, [peerId]: dBs };
    //     }

    //     default:
    //         return state;
    // }
    if(action.type == PeerVolumesActions.SET_PEER_VOLUME) {

        const { peerId } = action.payload;
        const dBs = action.payload.volume < -100 ? -100 : action.payload.volume;

        return { ...state, [peerId]: dBs };
    }
    else {
        return state;
    }
};
