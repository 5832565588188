<ng-template [ngIf]="!hideChatInput">
    <div *ngIf="isEmojiPickerVisible">
        <emoji-mart   class="emoji-mart" (emojiSelect)="addEmoji($event)" title="" 
            [i18n]="i18n" [emojiSize]="20" class="emoji" [style]="{ width: '378px' }"
            [backgroundImageFn]="backgroundImageFn"
            ></emoji-mart>
    </div>
    <input type="file" #fileInput multiple hidden (change)="beforeUploadFile($event)" />
    <div vnpt-column class="listFile">
        <div vnpt-row class="fileInfo" *ngFor="let file of listFileUploads; let i = index">
            <i  vnpt-icon [vType]="listFileIcons[i]" [class]="listFileIcons[i]"></i>
            <label class="nameFile">{{file.name}}</label>
            <label class="sizeFile">{{listSizeFiles[i]}} kb</label>
            <i vnpt-icon vType="ChromeClose" (click)="removeFile(file)" class="removeFile"></i>
        </div>
    </div>
    <div  class="input-holder">
        <div vnpt-row>
            
            <!-- <button vnpt-col [vSpan]="2" vnpt-button vType="text">
                <i vnpt-icon vType="MediaAdd" class="extra-fts"></i>
            </button>
            <button vnpt-col [vSpan]="2" vnpt-button vType="text">
                <i vnpt-icon vType="Microphone" class="extra-fts"></i>
            </button> -->
        </div>
    
        <div vnpt-row style="flex-flow: row nowrap;">
            <span style="color:red" ng-show="errorMsg" [hidden]="!errorMsg.error">
                <span ng-show="errorMsg.error">{{errorMsg.msg}}</span>
            </span>
            <span *ngIf="(inputValue || (listFileUploads.length > 0))" class="send-to">
                {{"chatting.to" | vI18n:{receiverName:currentActiveTitle}:currentActiveTitle}}
            </span>
            <div vnpt-col [vSpan]="21" class="input-area">
                <button vnpt-button vType="text"  class="input-item" (click)="fileInput.click()"
                vnpt-tooltip vTooltipTitle="{{'chatting.uploadFile' | vI18n:{}:'Thêm tập tin'}}" vTooltipPlacement="top">
                    <i  vnpt-icon vType="PageAdd" class="extra-fts"></i>
                </button>
                <button vnpt-button vType="text" class="input-item" (click)="isEmojiPickerVisible = !isEmojiPickerVisible;" vType="text">
                    <i vnpt-icon vType="Emoji2" class="extra-fts"></i>
            </button>
                <textarea #textInput vnpt-col [vSpan]="20" [rows]="textAreaRows" autofocus [class]="{'input-item': !errorMsg.error , 'input-item2': errorMsg.error}" 
                placeholder="{{'chatting.input' | vI18n:{}:'Nhập tin nhắn'}}" (paste)= "handlePaste($event)" (keyup)="triggerKeyboard($event)"  maxLength="200"></textarea>
                
            </div>       
            <div vnpt-col [vSpan]="3">       
                <button class="input-item" [disabled]="!(inputValue || (listFileUploads.length > 0))" vnpt-button vType="default" (click)="sendAction()"
                    vnpt-tooltip vTooltipTitle="{{'chatting.sendMessage' | vI18n:{}:'Gửi tin nhắn'}}" vTooltipPlacement="top" style="margin-left: 9px;">
                    <i vnpt-icon vType="Send" class="custom"></i>
                </button>
            </div>
        </div>
    </div>
</ng-template>
