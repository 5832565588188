import * as ToolAreaActions from './toolarea.actions';

export type Action = ToolAreaActions.All;

const initialState =
    {
        toolAreaOpen   : false,
        currentToolTab : 'chat', // chat, settings, users
        unreadMessages : 0,
        unreadFiles    : 0
    };

export const ToolareaReducer = (state = initialState, action: Action) =>
{
    switch (action.type)
    {
        case ToolAreaActions.TOGGLE_TOOL_AREA:
        {
            const toolAreaOpen = !state.toolAreaOpen;
            const unreadMessages = toolAreaOpen && state.currentToolTab === 'chat' ? 0 : state.unreadMessages;
            const unreadFiles = toolAreaOpen && state.currentToolTab === 'chat' ? 0 : state.unreadFiles;

            return { ...state, toolAreaOpen, unreadMessages, unreadFiles };
        }

        case ToolAreaActions.OPEN_TOOL_AREA:
        {
            const toolAreaOpen = true;
            const unreadMessages = state.currentToolTab === 'chat' ? 0 : state.unreadMessages;
            const unreadFiles = state.currentToolTab === 'chat' ? 0 : state.unreadFiles;

            return { ...state, toolAreaOpen, unreadMessages, unreadFiles };
        }

        case ToolAreaActions.CLOSE_TOOL_AREA:
        {
            const toolAreaOpen = false;

            return { ...state, toolAreaOpen };
        }

        case ToolAreaActions.SET_TOOL_TAB:
        {
            const { toolTab } = action.payload;
            const unreadMessages = toolTab === 'chat' ? 0 : state.unreadMessages;
            const unreadFiles = toolTab === 'chat' ? 0 : state.unreadFiles;

            return { ...state, currentToolTab: toolTab, unreadMessages, unreadFiles };
        }

        // case ToolAreaActions.ADD_MESSAGE:
        // {
        //     if (state.toolAreaOpen && state.currentToolTab === 'chat')
        //     {
        //         return state;
        //     }
        //
        //     return { ...state, unreadMessages: state.unreadMessages + 1 };
        // }
        //
        // case ToolAreaActions.ADD_FILE:
        // {
        //     if (state.toolAreaOpen && state.currentToolTab === 'chat')
        //     {
        //         return state;
        //     }
        //
        //     return { ...state, unreadFiles: state.unreadFiles + 1 };
        // }

        default:
            return state;
    }
};
