import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Lobby } from '../models/lobby.model';
import { SocketService } from 'projects/realtime-communication/src/projects';

@Injectable({
    providedIn: 'root'
})
export class LobbyService {

    private changeLobbyList: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private LobbyListOpenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private isLobby: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private isLimited: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private lobbyList: Map<string, Lobby> = new Map<string, Lobby>();
    private isLock: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        ) {
    }

    public getLobbyList(): Map<string, Lobby> {
        return this.lobbyList;
    }

    public getLobby(peerId): Lobby {
        return this.lobbyList.get(peerId);
    }

    public addLobbyList(lobbyPeers: Array<any>): void {
        lobbyPeers.forEach(element => {
            this.lobbyList.set(element.id, 
                {peerId: element.id, displayName: element.displayName, picture: element.picture});
        });
        this.changeLobbyList.next(true);
    }

    public addLobby(lobby, isNew): void {
        this.lobbyList.set(lobby.peerId, lobby);
        this.changeLobbyList.next(isNew);
    }

    public removeLobby(peerId: string): void {
        if(this.lobbyList.has(peerId)){
            this.lobbyList.delete(peerId);
            this.changeLobbyList.next(true);
        }
    }

    public onChangeLobbyList(): Observable<boolean> {
        return this.changeLobbyList.asObservable();
    }

    public setLobbyListOpen(flag: boolean): void {
        this.LobbyListOpenSubject.next(flag);
    }

    public onLobbyListOpen(): Observable<boolean> {
        return this.LobbyListOpenSubject.asObservable();
    }

    public setIsLobby(flag: boolean): void {
        this.isLobby.next(flag);
    } 
    public onIsLobby(): Observable<boolean> {
        return this.isLobby.asObservable();
    }

    public setIsLimited(flag: boolean): void {
        this.isLimited.next(flag);
    } 

    public onIsLimited(): Observable<boolean> {
        return this.isLimited.asObservable();
    }

    public changeIsLock(flag: boolean): void {
        this.isLock.next(flag);
    }

    public onIsLock(): Observable<boolean> {
        return this.isLock.asObservable();
    }
}
