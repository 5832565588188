import {EffectsModule} from '@ngrx/effects';
import {ChatReducer as chat} from './chat/chat.reducer';
import {ConsumersReducer as consumer} from './consumers/consumers.reducer';
import {FilesReducer as files} from './files/files.reducer';
import {LobbyPeersReducer as lobbyPeers} from './lobby-peers/lobby-peers.reducer';
import {MeReducer as me} from './me/me.reducer';
import {NotificationsReducer as notification} from './notifications/notifications.reducer';
import {PeerVolumesReducer as peerVolumes} from './peer-volumes/peer-volumes.reducer';
import {PeersReducer as peers} from './peers/peers.reducer';
import {ProducersReducer as producer} from './producers/producers.reducer';
import {RoomReducer as room} from './room/room.reducer';
import {SettingsReducer as settings} from './settings/settings.reducer';
import {ToolareaReducer as toolarea} from './toolarea/toolarea.reducer';
import {TransportsReducer as transport} from './transports/transports.reducer';
import {Store, StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {NgModule} from '@angular/core';

@NgModule({
    imports: [

        // EffectsModule.forRoot([
        //     OneUIRoomFacade
        // ]),

        // Signature matches AppState interface
        StoreModule.forRoot({
            chat,
            consumer,
            files,
            lobbyPeers,
            me,
            notification,
            peerVolumes,
            peers,
            producer,
            room,
            settings,
            toolarea,
            transport,
        }),
        // StoreModule.forFeature('oneUIRoom', OneUIRoomReducer),

        // Retains last 25 states
        StoreDevtoolsModule.instrument({maxAge: 25})
    ],
    providers: [
        Store
    ],
})
export class RealtimeCommunicationStateModule {
}
