/* tslint:disable:no-console */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LAYOUT_MODE } from '../constants';
import { HttpClient } from '@angular/common/http';
import { Cache } from 'projects/common-library/src/public-api';
import { environment } from '../../../../main/src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    private _roomConfig: any = null;
    private _configs: any = null;

    constructor(private http: HttpClient) {}

    private isAudioMutedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private isVideoMutedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private isVideoBlockedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private isAudioBlockedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private isAudioUnblockedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private isVideoUnblockedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private isShareScreenStsSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    private isWebcamsOnlyForModeratorSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private isSpaceHoldSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    // private isShareScreenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    // private isScreenSharingFrameRate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    // private isScreenSharingResolution: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    private layoutModeSubject = new BehaviorSubject<LAYOUT_MODE>('gallery');
    private rightContainerSubject = new BehaviorSubject<boolean>(false);
    private optionSubject = new BehaviorSubject<boolean>(true);
    private isDisplayNameChangeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private isRoomNameChangeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private roomNameSubject = new BehaviorSubject<string>('');
    private isJoinedAudioSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private isGeneralSettingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private isFeedbackOpenSubject: BehaviorSubject<Object> = new BehaviorSubject<Object>(null);
    private isReportOpenSubject: BehaviorSubject<Object> = new BehaviorSubject<Object>(null);
    private isDurationOpenSubject: BehaviorSubject<Object> = new BehaviorSubject<Object>(null);
    private isResetDestructOpenSubject: BehaviorSubject<Object> = new BehaviorSubject<Object>(null);
    private videoStreamSubject: BehaviorSubject<MediaStream> = new BehaviorSubject<MediaStream>(null);
    private VideoQuality: BehaviorSubject<string> = new BehaviorSubject<string>('default');
    // private isSavedBackgoundSubject: BehaviorSubject<Boolean> = new BehaviorSubject<boolean>(false);
    private grantScreenSharingPermissionSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private isAudioSettingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private isVideoSettingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private typeGuideDeviceSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private timeoutRecommendedForAdmin: boolean = false;
    private timeoutRecommendedForUser: boolean = false;

    private maxParticipants: number = 0;
    private urlBackground: string = null;
    private timing: any = null;

    setRoomConfig(config: any) {
        this._roomConfig = config;
    }

    public setRoomName(roomName: string): void{
        this.roomNameSubject.next(roomName);
        this._roomConfig.name = roomName;
    }

    public getRoomName(): string{
        return this._roomConfig.name;
    }

    public onRoomName(): Observable<string>{
        return this.roomNameSubject.asObservable();
    }

    public async getAppConfig(): Promise<any> {
        if (!this._configs) {
            this._configs = await this.http.get('/assets/config/config.json').toPromise();
            const config = { ...this._configs, ...this._roomConfig };
            if (config.useOriginApiURL) environment.setEnvironment(location.origin);
        }
        return { ...this._configs, ...this._roomConfig };
    }

    public checkUseConfigHarkJs(): boolean {
        if (!this._configs) {
            return false;
        }
        return this._configs.enableHarkJS;
    }

    public enableFilterNoise(): boolean {
        if (!this._configs) {
            return false;
        }
        return this._configs.filterNoise;
    }

    public getConfigFilterNoise(): any {
        if (!this._configs) {
            return;
        }
        return { bufferSizeFilterNoise: this._configs.bufferSizeFilterNoise, sizeArrayFilterNoise: this._configs.sizeArrayFilterNoise };
    }

    setFilterNoise(filterNoise: boolean){
        this._configs.filterNoise = filterNoise;
    }

    setAudioMuted(flag: boolean): void {
        this.isAudioMutedSubject.next(flag);
    }

    onAudioMuted(): Observable<boolean> {
        return this.isAudioMutedSubject.asObservable();
    }

    onVideoBlocked(): Observable<boolean>{
        return this.isVideoBlockedSubject.asObservable();
    }

    onAudioBlocked(): Observable<boolean>{
        return this.isAudioBlockedSubject.asObservable();
    }

    onAudioUnblocked(): Observable<boolean>{
        return this.isAudioUnblockedSubject.asObservable();
    }

    onVideoUnblocked(): Observable<boolean>{
        return this.isVideoUnblockedSubject.asObservable();
    }

    getIsAudioMuted(): boolean{
        return this.isAudioMutedSubject.value;
    }

    setVideoMuted(flag: boolean): void {
        this.isVideoMutedSubject.next(flag);
    }

    setVideoBlocked(flag: boolean): void {
        this.isVideoBlockedSubject.next(flag);
    }

    setAudioBlocked(flag: boolean): void {
        this.isAudioBlockedSubject.next(flag);
    }

    setAudioUnblocked(flag: boolean): void {
        this.isAudioUnblockedSubject.next(flag);
    }

    setVideoUnblocked(flag: boolean): void {
        this.isVideoUnblockedSubject.next(flag);
    }

    onVideoMuted(): Observable<boolean>{
        return this.isVideoMutedSubject.asObservable();
    }

    getIsVideoMuted(): boolean {
        return this.isVideoMutedSubject.value;
    }

    getIsVideoBlocked(): boolean{
        return this.isVideoBlockedSubject.value;
    }

    getIsAudioBlocked(): boolean{
        return this.isAudioBlockedSubject.value;
    }

    setShareScreenStatus(flag: boolean): void {
        this.isShareScreenStsSubject.next(flag);
    }

    getShareScreenStatus(): Observable<boolean> {
        return this.isShareScreenStsSubject.asObservable();
    }

    getShareScreenStatusValue(): boolean {
        return this.isShareScreenStsSubject.value;
    }
    // setScreenShareInProgress(flag: boolean): void {
    //     this.isShareScreenSubject.next(flag);
    // }

    // onShareScreenInProgress(): Observable<boolean>{
    //     return this.isShareScreenSubject.asObservable();
    // }

    // setScreenSharingResolution(flag: boolean): void {
    //     this.isScreenSharingResolution.next(flag);
    // }

    // setScreenSharingFrameRate(flag: boolean): void {
    //     this.isScreenSharingFrameRate.next(flag);
    // }

    changeLayoutMode(mode: LAYOUT_MODE): void {
        if (mode === this.layoutModeSubject.value) {
            return;
        }
        this.layoutModeSubject.next(mode);
    }

    onChangeLayoutMode(): Observable<any> {
        return this.layoutModeSubject.asObservable();
    }

    getLayoutMode(): LAYOUT_MODE {
        return this.layoutModeSubject.value;
    }

    triggerRightContainer(isShown: boolean): void {
        this.rightContainerSubject.next(isShown);
    }

    onTriggerRightContainer(): Observable<boolean> {
        return this.rightContainerSubject.asObservable();
    }

    triggerOptionBar(isShown: boolean): void {
        this.optionSubject.next(isShown);
    }
    
    getTriggerOptionBar(): boolean {
       return this.optionSubject.getValue();
    }

    onTriggerOptionBar(): Observable<boolean> {
        return this.optionSubject.asObservable();
    }

    setDisplayNameChange(isChange: boolean): void {
        this.isDisplayNameChangeSubject.next(isChange);
    }

    onNameChange(): Observable<boolean> {
        return this.isDisplayNameChangeSubject.asObservable();
    }

    setRoomNameChange(isChange: boolean): void {
        this.isRoomNameChangeSubject.next(isChange);
    }

    onRoomNameChange(): Observable<boolean> {
        return this.isRoomNameChangeSubject.asObservable();
    }

    setSafariRecommended(isChange: boolean): void {
        Cache.addCache(
            "SafariRecommended",
            isChange.toString(),
            Cache.COOKIE,
            5 * 24 * 60 * 60 * 1000 // expire after 5 days
        );
    }

    isSafariRecommended(): boolean {
        // return this.isSafariRecommenSubject;
        return /^true$/i.test(Cache.getCache(
            "SafariRecommended",
            Cache.COOKIE
        ));
    }

    setTimeoutRecommendedForAdmin(isChange: boolean): void {
        // Cache.addCache(
        //     "TimeoutRecommendedForAdmin",
        //     isChange.toString(),
        //     Cache.COOKIE,
        //     5 * 24 * 60 * 60 * 1000 // expire after 5 days
        // );
        this.timeoutRecommendedForAdmin =  isChange;
    }

    setTimeoutRecommendedForUser(isChange: boolean): void {
        // Cache.addCache(
        //     "TimeoutRecommendedForUser",
        //     isChange.toString(),
        //     Cache.COOKIE,
        //     5 * 24 * 60 * 60 * 1000 // expire after 5 days
        // );
        this.timeoutRecommendedForUser = isChange;
    }

    isTimeoutRecommendedForAdmin(): boolean {
        // return /^true$/i.test(Cache.getCache(
        //     "TimeoutRecommendedForAdmin",
        //     Cache.COOKIE
        // ));
        return this.timeoutRecommendedForAdmin;
    }

    isTimeoutRecommendedForUser(): boolean {
        // return /^true$/i.test(Cache.getCache(
        //     "TimeoutRecommendedForUser",
        //     Cache.COOKIE
        // ));
        return this.timeoutRecommendedForUser;
    }

    setWebcamsOnlyForModerator(flag: boolean): void {
        this.isWebcamsOnlyForModeratorSubject.next(flag);
    }

    getIsWebcamsOnlyForModerator(): boolean {
        return this.isWebcamsOnlyForModeratorSubject.getValue();
    }

    onWebcamsOnlyForModerator(): Observable<boolean>{
        return this.isWebcamsOnlyForModeratorSubject.asObservable();
    }

    setJoinAudio(isJoined: boolean): void {
        this.isJoinedAudioSubject.next(isJoined);
    }

    onJoinAudio(): Observable<boolean> {
        return this.isJoinedAudioSubject.asObservable();
    }

    getIsJoinedAudio(): boolean {
        return this.isJoinedAudioSubject.value;
    }

    setGeneralSetting(isOpen): void {
        this.isGeneralSettingSubject.next(isOpen);
    }

    onGeneralSetting(): Observable<boolean> {
        return this.isGeneralSettingSubject.asObservable();
    }

    setAudioSetting(isOpen): void {
        this.isAudioSettingSubject.next(isOpen);
    }

    onAudioSetting(): Observable<boolean> {
        return this.isAudioSettingSubject.asObservable();
    }

    setVideoSetting(isOpen): void {
        this.isVideoSettingSubject.next(isOpen);
    }

    onVideoSetting(): Observable<boolean> {
        return this.isVideoSettingSubject.asObservable();
    }

    setUrlBackground(url : string):void{
        this.urlBackground = url;
    }

    getUrlBackground(): string {
        return this.urlBackground;
    }

    setLocalVideoStream(videoStream : MediaStream):void{
        if(videoStream == null) {
            clearTimeout(this.timing);
            this.videoStreamSubject.next(videoStream);
            return;
        }
        clearTimeout(this.timing);
        this.timing = setTimeout(()=> {
            this.videoStreamSubject.next(videoStream);
            clearTimeout(this.timing);
        }, 500);
        
    }

    onLocalVideoStream(): Observable<MediaStream> {
        return this.videoStreamSubject.asObservable();
    }

    getLocalVideoStream(): MediaStream {
        return this.videoStreamSubject.value;
    }
    // setSavedBackgoundSubject(isSaved : Boolean):void{
    //     this.isSavedBackgoundSubject.next(isSaved);
    // }

    // onSavedBackgoundSubject(): Observable<Boolean> {
    //     return this.isSavedBackgoundSubject.asObservable();
    // }

    // getSavedBackgoundSubject(): Boolean {
    //     return this.isSavedBackgoundSubject.value;
    // }

    getMaxParticipants():number{
        return this.maxParticipants;
    }

    setMaxParticipants(value : number):void{
        this.maxParticipants =  value;
    }

    setIsFeedbackOpenSubject(obj: {visible: boolean, message: string, callback: Function | null}): void {
        this.isFeedbackOpenSubject.next(obj);
    }

    onIsFeedbackOpenSubject(): Observable<Object> {
        return this.isFeedbackOpenSubject.asObservable();
    }

    setIsDurationOpenSubject(obj: {visible: boolean, message: string, callback: Function | null}): void {
        this.isDurationOpenSubject.next(obj);
    }

    onIsDurationOpenSubject(): Observable<Object> {
        return this.isDurationOpenSubject.asObservable();
    }

    setIsResetDestructOpenSubject(obj: {visible: boolean, message: string, callback: Function | null}): void {
        this.isResetDestructOpenSubject.next(obj);
    }

    onIsResetDestructOpenSubject(): Observable<Object> {
        return this.isResetDestructOpenSubject.asObservable();
    }

    setIsSpaceHoldSubject(flag: boolean): void {
        this.isSpaceHoldSubject.next(flag);
    }

    onIsSpaceHoldSubject(): Observable<boolean> {
        return this.isSpaceHoldSubject.asObservable();
    }

    getIsSpaceHoldSubject(): boolean {
        return this.isSpaceHoldSubject.getValue();
    }

    setIsGrantcreenSharingPermission(allow : boolean){
        this.grantScreenSharingPermissionSubject.next(allow);
    }

    onIsGrantcreenSharingPermissionSubject(): Observable<boolean> {
        return this.grantScreenSharingPermissionSubject.asObservable();
    }

    setVideQuality(flag: string): void {
        this.VideoQuality.next(flag);
    }

    onVideoQuality(): Observable<string> {
        return this.VideoQuality.asObservable();
    }

    setIsReportOpenSubject(obj: {visible: boolean, message: string}): void {
        this.isReportOpenSubject.next(obj);
    }

    onIsReportOpenSubject(): Observable<Object> {
        return this.isReportOpenSubject.asObservable();
    }

    setTypeGuideDeviceSubject(type: number): void{
        this.typeGuideDeviceSubject.next(type);
    }

    onTypeGuideDeviceSubject(): Observable<number> {
        return this.typeGuideDeviceSubject.asObservable();
    }

    detectBrowserName() { 
        const agent = window.navigator.userAgent.toLowerCase()
        switch (true) {
          case agent.indexOf('edge') > -1:
            return 'edge';
          case agent.indexOf('opr') > -1 && !!(<any>window).opr:
            return 'opera';
          case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
            return 'chrome';
          case agent.indexOf('trident') > -1:
            return 'ie';
          case agent.indexOf('firefox') > -1:
            return 'firefox';
          case agent.indexOf('safari') > -1:
            return 'safari';
          default:
            return 'other';
        }
    }

    isBrowseSafari(): boolean{
        const agent = window.navigator.userAgent.toLowerCase()
        switch (true) {
          case agent.indexOf('edge') > -1:
            return false;
          case agent.indexOf('opr') > -1 && !!(<any>window).opr:
            return false;
          case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
            return false;
          case agent.indexOf('trident') > -1:
            return false;
          case agent.indexOf('firefox') > -1:
            return false;
          case agent.indexOf('safari') > -1:
            return true;
          default:
            return false;
        }
    }
       
    detectBrowserVersion(){
        var userAgent = navigator.userAgent, tem, 
        matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        
        if(/trident/i.test(matchTest[1])){
            tem =  /\brv[ :]+(\d+)/g.exec(userAgent) || [];
            return 'IE '+(tem[1] || '');
        }
        if(matchTest[1]=== 'Chrome'){
            tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
            if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }
        matchTest= matchTest[2]? [matchTest[1], matchTest[2]]: [navigator.appName, navigator.appVersion, '-?'];
        
        if((tem= userAgent.match(/Version\/(\d+\.\d+)/i))!= null) {
            matchTest.splice(1, 1, tem[1]);
        }
        return matchTest[1]
    }
    
}

