import {ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, AfterViewInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import {
    LocalParticipantService,
    DeviceCountingService,
    MediasoupService,
    ReactionsService, RoomService,
    ConsumerService,
    LobbyService,
    SecurityService,
    SettingsService,
    ChattingService,
    ShareDataService
} from '../../services';
import {RemoteParticipant} from '../../models/remote-participant.model';
import {permissions} from '../../permissions';
import { NotificationService } from '../../services/notification-service';
import { AgVirtualSrollComponent, AgVsRenderEvent } from 'ag-virtual-scroll';
import { OneuiI18nService } from '@vnpt/oneui-i18n';
import { Subject } from 'rxjs';
import { SortingService, SORT_USERS_BY } from '../../services/sorting.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { PoolService } from '../../services/mediasoup/pool.service';
import { PERMISSIONS } from '../../constants';
import { LayoutModel } from '../../models/layout.model';
import { element } from 'protractor';
import { FUNCTION_TYPE } from '@angular/compiler/src/output/output_ast';
import { BaseMessage, Conversation } from '../../models/chatting.model';
import { Participant } from '../../models/participant.model';
const TOPIC_PUBLIC_CHAT = "PUBLIC_CHAT";
@Component({
    selector: 'lib-list-participant',
    templateUrl: './list-participant.component.html',
    styleUrls: ['./list-participant.component.less'],
})
export class ListParticipantComponent implements OnInit, OnDestroy {
    tabTitle: string = "";
    participantConnectedNumber = 1;
    listRemoteParticipant: RemoteParticipant[] = [];
    hasPermissionModerator = false;
    hasPermissionAdmin = false;
    micNumber = 0; 
    maxParticipant = 0;
    acceptDisable: boolean = true;
    acceptAllDisable: boolean = true;
    lengthList = 0;
    webcamNumber = 0;
    @ViewChild('vs', { static: false }) vs: AgVirtualSrollComponent;
    inputValue?: string = '';
    filteredOptions:  RemoteParticipant[] = this.listRemoteParticipant;
    searchKey = '';
    userSearchUpdate = new Subject<string>();
    keySort = 'role';
    keyFilter = 'all';
    lobbyLength = 0;
    isTyping = false;
    changeLangage = 'vi';
    selectedIndex = 0;
    isLock: boolean = false;
    visibility = {
        security: false,
    };
    @Output() triggerRightContainer = new EventEmitter();
    forceLayoutStatus = false;
    layoutId = 0;
    sortUserListBy = SORT_USERS_BY;
    unseenMessage = 0;
    constructor(
        // private cdRef: ChangeDetectorRef,
        private poolService : PoolService,
        private localParticipantService: LocalParticipantService,
        private deviceCountingService: DeviceCountingService,
        private reactionsService: ReactionsService,
        private notificationService: NotificationService,
        private mediasoupService: MediasoupService,
        private roomService: RoomService,
        private consumerService: ConsumerService,
        private i18nService: OneuiI18nService,
        private sortingService : SortingService,
        private lobbyService: LobbyService,
        private securityService: SecurityService,
        private settingService :SettingsService,
        private chattingService: ChattingService
    ) {
        this.userSearchUpdate.pipe(
            debounceTime(400),
            distinctUntilChanged())
            .subscribe(value => {
            this.filterAndSortBy(value, this.keySort, this.keyFilter);
            // this.filterPeer(value, this.keyFilter);
            });
        this.changeLangage =  this.i18nService.getActiveLang();
        this.i18nService.langChanges$.subscribe(lang =>{
            this.changeLangage =  this.i18nService.getActiveLang();
        })
    }

    ngOnInit(): void {
        this.updateListRemoteParticipant();
        this.onInitEvent();
    }

    ngAfterViewInit(): void {
        window.addEventListener('resize', 
            this.reloadList)
    }

    ngOnDestroy(): void {
        window.removeEventListener("showContainerRight", this.reloadList);
        window.removeEventListener("resize", this.reloadList);
    }

    trackById(index: number, item) {
        return index;
    }

    trackByPeerInfo(index: number, item: Participant) {
        return "" + index + item.displayName + item.audio.state + item.screen.state + item.webcam.state + item.roles.join();
    }

    onInitEvent(): void {
        window.addEventListener("showContainerRight", this.reloadList);

        this.maxParticipant = this.settingService.getMaxParticipants();
        this.roomService.onForceLayoutObservable().subscribe((layoutInfo: LayoutModel) => {
            if(layoutInfo && layoutInfo.layout != 0){
                this.forceLayoutStatus = true;
            } 
            else{
                this.forceLayoutStatus = false;
            }
            this.layoutId = layoutInfo.layout;
        });

        this.consumerService.onUpdateAudioConsumer().subscribe((audioConsumerMap) => {
            this.localParticipantService.getLocalParticipant().audio.state 
            ? this.micNumber = audioConsumerMap.size + 1 : this.micNumber = audioConsumerMap.size;
            this.filterAndSortBy(this.searchKey, this.keySort, this.keyFilter);
            // this.filterPeer(this.searchKey, this.keyFilter);
        });

        this.poolService.onUpdateListRemoteParticipantInfo.asObservable().subscribe((flag: boolean) =>{
            if (flag) {
                this.updateListRemoteParticipant();
            }
            this.filterAndSortBy(this.searchKey, this.keySort, this.keyFilter);
            // this.filterPeer(this.searchKey, this.keyFilter);
        });

        this.localParticipantService.onRoles().subscribe((roles: number[]) => {
            this.hasPermissionModerator = this.roomService.hasPermission(permissions.MODERATE_ROOM);
            this.hasPermissionAdmin = this.localParticipantService.getRolesSubject().includes(PERMISSIONS.ADMIN.id); 
        });

        // this.deviceCountingService.onMicNumber().subscribe((micNumber: number) => {
        //     this.micNumber = micNumber;
        // });

        this.poolService.onVideoConsumerMaps.asObservable().subscribe((flag: boolean) => {
            const videoConsumer = this.poolService.getVideoConsumerMapIsHaveWebcam();
            this.localParticipantService.getLocalParticipant().webcam.state 
            ? this.webcamNumber = videoConsumer.size + 1 : this.webcamNumber = videoConsumer.size;
            this.filterAndSortBy(this.searchKey, this.keySort, this.keyFilter);
            // this.filterPeer(this.searchKey, this.keyFilter);
        });

        this.reactionsService.onRemoteRaisedHand().subscribe((raisedHandInfo) => {
            if (raisedHandInfo) {
                const { peerId, raisedHand } = raisedHandInfo;
                this.popRaisedHandNotificationUp(peerId, raisedHand);
            }
            this.filterAndSortBy(this.searchKey, this.keySort, this.keyFilter);
            // this.filterPeer(this.searchKey, this.keyFilter);
        });

        this.poolService.onUpdateSearch().subscribe((flag)=>{
            this.listRemoteParticipant = this.poolService.getAllPeers();
            this.filterAndSortBy(this.searchKey, this.keySort, this.keyFilter);
            // this.filterPeer(this.searchKey, this.keyFilter);
        });
        //Bo sung tinh nang lobby trong list participant
        this.lobbyService.onChangeLobbyList().subscribe((isChange: boolean) => {
            const length = this.lobbyService.getLobbyList().size;
            this.lobbyLength = length;
        });

        this.securityService.onLockRoom().subscribe((isLock: boolean) => {
            //Bắt trạng thái khóa khi bấm nút khóa/mở phòng
            this.isLock = isLock;
          });

        this.poolService.onRemotesPin().subscribe((remotesPin)=>{
            this.filterAndSortBy(this.searchKey, this.keySort, this.keyFilter);
            // this.filterPeer(this.searchKey, this.keyFilter);
        })

        this.chattingService.newMessageBehavior.subscribe((msg: BaseMessage) => {
            // if (!msg || msg.topic != TOPIC_PUBLIC_CHAT || (this.chattingService.activeChat == TOPIC_PUBLIC_CHAT && this.roomService.rightPaneView == "chatting")) return;
            if (!msg || (this.chattingService.activeChat == msg.topic && this.roomService.rightPaneView == "chatting")) return;
            this.chattingService.getHistory(msg.topic).then((conversation: Conversation) => {
                this.unseenMessage = conversation.unreadMessageCount;
            });
        });

        this.chattingService.messageReadedBehavior.subscribe((topic: string) => {
            var activeChatList =  this.chattingService.getActiveList();   
            if (!activeChatList?.find(element => element.data.unreadMessageCount)) {
                this.unseenMessage = 0;
            } else { this.unseenMessage = 1; }
        });
    }

    reloadList = (evt: any) => {
        // console.log('reloadList ', evt.detail.screen);
        if(evt.type ==  'resize') {
            this.updateListRemoteParticipant();
            if(this.lobbyLength > 0) this.selectedIndex = 1;
            else this.selectedIndex = 0;
            return;
        }

        if (evt.detail.screen == 'listParticipant') {
            this.updateListRemoteParticipant();
            if(this.lobbyLength > 0) this.selectedIndex = 1;
            else this.selectedIndex = 0;
        }
    } 
    
    eventTab(index: number): void{
        if(index == 0){
            const event = {
                detail: {
                    screen:  'listParticipant'
                }
            }
            this.reloadList(event);
        }
    }

    updateListRemoteParticipant(): void {
        this.listRemoteParticipant = this.poolService.getAllPeers();
        // this.listRemoteParticipant = this.poolService.getInfoFullPeers();

        //update thong tin isPin neu user vao phong co trong danh sach pin (user f5)
        if(this.forceLayoutStatus){
            let remotes = this.poolService.getRemotesPin.map((element) => element.id);
            this.listRemoteParticipant.forEach(element => {
                if(remotes.includes(element.id) && !element.isPin){
                    let remote = this.poolService.getPeer(element.id)
                    remote.isPin = true;
                    element.isPin = true;
                }
            });
            
        }
        this.filterAndSortBy(this.searchKey, this.keySort, this.keyFilter);
        this.participantConnectedNumber = this.listRemoteParticipant.length + 1;
        // this.cdRef.detectChanges();
        this.tabTitle = this.getTabTitle('list-participant.tabTitle');
    }

    getTabTitle(info: string): string {
        if(this.participantConnectedNumber == 1){
            if(this.maxParticipant != 0 && this.hasPermissionModerator)
                return `${this.i18nService.translate(info,{},"Người tham gia")} (${this.participantConnectedNumber}/${this.maxParticipant})`
            
            return `${this.i18nService.translate(info,{},"Người tham gia")} (${this.participantConnectedNumber})`;
        }else{
            if(this.maxParticipant !=0 && this.hasPermissionModerator)
            return `${this.i18nService.translate(info,{s:"s"},"Người tham gia")} (${this.participantConnectedNumber}/${this.maxParticipant})`;
            return `${this.i18nService.translate(info,{s:"s"},"Người tham gia")} (${this.participantConnectedNumber})`;
        }
        
    }

    popRaisedHandNotificationUp(peerId: string, raisedHand: boolean): void {
        const raisedHandParticipant = this.listRemoteParticipant.find((participant) => participant.id === peerId);
        const action = raisedHand
            ? this.i18nService.translate('list-participant.notify.raisedHand', {}, 'đã giơ tay')
            : this.i18nService.translate('list-participant.notify.lowedHand', {}, 'đã bỏ tay xuống');
        this.notificationService.info(`${raisedHandParticipant.displayName} ${action}`, "");
    }

    onTriggerRightContainer(type): void {
        this.triggerRightContainer.emit(type);
    }

    removeAccents(str: string): string {
        return str.normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .replace(/đ/g, 'd').replace(/Đ/g, 'D');
    }

    filterAndSortBy(keySearch : string ,sortBy: string, filter: string = 'all'): void {
        if (keySearch.toString() == "") {
            this.isTyping = false;
        } else {
            this.isTyping = true;
        } 
        this.keySort = sortBy;
        this.searchKey = this.removeAccents(keySearch);
        this.keyFilter =filter;
        var searchListRemoteParticipant: RemoteParticipant[] =  this.listRemoteParticipant.filter(participant => this.removeAccents(participant.displayName.toString().toLowerCase()).indexOf(this.searchKey.toLowerCase()) !== -1);
        
        if(filter != 'all') {
            if(filter == "raisedHand") {
                searchListRemoteParticipant = searchListRemoteParticipant.filter(participant => !!(participant.isRaisedHand));
            }
    
            if(filter == "speaker") {
                searchListRemoteParticipant =  searchListRemoteParticipant.filter(participant => !!(participant.audio.state));
            }
            
            if(filter == "shareScreen") {
                searchListRemoteParticipant =  searchListRemoteParticipant.filter(participant => !!(participant.webcam.state));
            }
        }
        
        if (searchListRemoteParticipant) {
          const sort = SORT_USERS_BY.find((element) => element.name == sortBy);
          this.filteredOptions = SortingService.sort( searchListRemoteParticipant, sort.fn);
        } 

        // Lưu danh sách peer sort cho tính năng export
        if(keySearch === "" && filter === "all"){
            this.poolService.setPeerWithSort(this.filteredOptions);
        }

    }
    

    getNumberOrder(id: string): number{
        return this.filteredOptions.findIndex(x => x.id === id);
    }

    //Mo tab danh sach lobby
    // openLobby(type): void {
    //     // this.triggerRightContainer.emit({ type});
    //     this.roomService.rightPaneView = "lobby";
    // }

    clear(): void {
        this.inputValue = '';
        this.isTyping = false;
        this.userSearchUpdate.next('');
    }

    pooper(component, key): void {
        // if (component)
        this.visibility[key] = !component;
    }

    goBack(): void {
        this.roomService.rightPaneView = "chatting";
        this.chattingService.setActiveChat(TOPIC_PUBLIC_CHAT);
        this.chattingService.resetUnseenMessageCounting("chatting", true);
        // this.triggerRightContainer.emit({type: "listParticipant"});
    }

    export(): void{
        this.poolService.exportPeers();
    }
}
