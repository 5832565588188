<div vnpt-row vJustify="space-between" vAlign="middle" class="footer">
    <div vnpt-row vAlign="middle"></div>

    <div style="padding: 0 8px;">
        <button vnpt-button vType="primary" vDanger 
                vnpt-popover vPopoverPlacement="topRight"
                [(vPopoverVisible)]="endMeetingVisible"
                vPopoverTrigger="click"
                [vPopoverContent]="endMeetingTemplate">
            <b vI18n="exit"></b>
        </button>
    </div>
</div>

<ng-template #endMeetingTemplate>
    <div class="popconfirm-title">
        <i vnpt-icon vType="Info" vColor="warning"></i>
        <span vI18n="joining.endmeeting.title"></span>
    </div>

    <div class="popconfirm" vnpt-row vAlign="middle" vJustify="end">
        <button vnpt-button vType="default" (click)="endMeetingVisible=false" class="cancel-button" vI18n="cancel"></button>
        <button vnpt-button vType="primary" (click)="leaveRoom()" vI18n="ok"></button>
    </div>
</ng-template>