import {Injectable} from '@angular/core';
import {VNotificationService} from '@vnpt/oneui-ui/notification';
// import {
//     MediasoupBaseService,
//     ListRemoteParticipantService,
//     LocalParticipantService,
//     DeviceCountingService,
//     ConsumerService,
//     SettingsService,
//     ChattingService,
//     ProducerService,
//     ActiveSpeakerService,
//     WhiteboardService,
//     SecurityService,
//     RolesManagerService,
//     NotificationService,
//     ReactionsService,
//     QuizService,
//     SocketService,
//     RoomService,
//     VirtualBackgroundService,
// } from './';
import {MediasoupBaseService} from './mediasoup/mediasoup-base.service';
// import {ListRemoteParticipantService} from './list-remote-participant.service';
import {LocalParticipantService} from './local-participant.service';
import {DeviceCountingService} from './device-counting.service';
import {ConsumerService} from './consumer.service';
import {SettingsService} from './settings.service';
import {ChattingService} from './chatting.service';
import {ProducerService} from './producer.service';
import {ActiveSpeakerService} from './active-speaker.service';
import {WhiteboardService} from './whiteboard.service';
import {SecurityService} from './security.service';
import {RolesManagerService} from './roles-manager.service';
import {NotificationService} from './notification-service';
import {ReactionsService} from './reactions.service';
import {ShareDataService} from './share-data.service';
import {BreakRoomService} from './breakroom.service';
import {QuizService} from './quiz.service';
import {SocketService} from './socket.service';
import {RoomService} from './mediasoup/room.service';
import {VirtualBackgroundService} from './virtual-background.service';
import {JoinResponseModel} from '../models/join-response.model';
import {PoolService} from './mediasoup/pool.service';
import {MediaStreamService} from './mediasoup/media-stream.service';
import { LobbyService } from './lobby.service';
import { ListRequestRoleService } from './list-request-role.service';
import {AudioDeviceService} from './audio-device.service';
import { RecordService } from './record.service';
import { LatencyService } from './latency.service';
import { OneuiI18nService } from '@vnpt/oneui-i18n';
import { SpeakingService } from './speaking.service';

@Injectable({
    providedIn: 'root'
})
export class MediasoupService extends MediasoupBaseService {
    constructor(
        private poolServiceInjectable: PoolService,
        private mediaStreamServiceInjectable: MediaStreamService,
        private vNotificationServiceInjectable: VNotificationService,
        private socketServiceInjectable: SocketService,
        private roomServiceInjectable: RoomService,
        private localParticipantServiceInjectable: LocalParticipantService,
        // private listRemoteParticipantServiceInjectable: ListRemoteParticipantService,
        private deviceCountingServiceInjectable: DeviceCountingService,
        private consumerServiceInjectable: ConsumerService,
        private settingsServiceInjectable: SettingsService,
        private chattingServiceInjectable: ChattingService,
        private virtualBackgroundServiceInjectable: VirtualBackgroundService,
        private producerServiceInjectable: ProducerService,
        private activeSpeakerServiceInjectable: ActiveSpeakerService,
        private whiteboardServiceInjectable: WhiteboardService,
        private latencyServiceInjectable: LatencyService,
        private securityServiceInjectable: SecurityService,
        private userRolesServiceInjectable: RolesManagerService,
        private notificationInjectable: NotificationService,
        private reactionsServiceInjectable: ReactionsService,
        private quizServiceInjectable: QuizService,
        private shareDataServiceInjectable: ShareDataService,
        private lobbyServiceInjectable: LobbyService,
        private requestRoleServiceInjectable: ListRequestRoleService,
        private audioDeviceServiceInjectable: AudioDeviceService,
        private recordServiceInjectable: RecordService,
        private i18nServiceInjectable: OneuiI18nService,
        private speakingServiceInjectable: SpeakingService,
        ) {
        super(
            poolServiceInjectable,
            mediaStreamServiceInjectable,
            vNotificationServiceInjectable,
            socketServiceInjectable,
            roomServiceInjectable,
            localParticipantServiceInjectable,
            // listRemoteParticipantServiceInjectable,
            deviceCountingServiceInjectable,
            consumerServiceInjectable,
            settingsServiceInjectable,
            chattingServiceInjectable,
            virtualBackgroundServiceInjectable,
            producerServiceInjectable,
            activeSpeakerServiceInjectable,
            whiteboardServiceInjectable,
            latencyServiceInjectable,
            securityServiceInjectable,
            userRolesServiceInjectable,
            notificationInjectable,
            reactionsServiceInjectable,
            quizServiceInjectable,
            shareDataServiceInjectable,
            lobbyServiceInjectable,
            requestRoleServiceInjectable,
            audioDeviceServiceInjectable,
            recordServiceInjectable,
            i18nServiceInjectable,
            speakingServiceInjectable
        );
    }

    executeNewVideoProducer(peerId: string): Promise<void> {
        return;
    }

    executeBreakRoomNotificationEvent({groupMap, teacherId}: { groupMap: any; teacherId: any }): Promise<void> {
        return Promise.resolve(undefined);
    }

    executePeerClosed(peerId: string): void {
    }

    executeStopBreakRoom(): Promise<void> {
        return Promise.resolve(undefined);
    }

    executeJoin(joinData: JoinResponseModel): void {
    }

    executeChangeTeacherSpeakingInGroup(teacherSpeakingInGroup: string): void {
    }
}
