// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

class Environment {
    production: boolean = false;
    api: string = 'https://gomeetv3-dev.vnptit.vn';
    socket: string = 'https://gomeetv3-dev.vnptit.vn'
  constructor() {
    
  }
  
  setEnvironment(url) {
    console.log(">>>Domain: "+url);
    this.api = url;
    this.socket = url;
  }

  get domain() {
    return {
      api: this.api,
      socket: this.socket
    }
  }
}

export const environment = new Environment();

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
