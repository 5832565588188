/* tslint:disable:no-console */
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Participant} from '../models/participant.model';


@Injectable({
    providedIn: 'root'
})
export class ActiveSpeakerService {
    constructor() {
    }

    private activeSpeakerSubject = new BehaviorSubject<Participant>(null);
    private spotlightSubject = new BehaviorSubject<Participant>(null);
    private resumeScreenSubject = new BehaviorSubject<boolean>(false);
    // private newPeerJoinSubject = false;

    public setActiveSpeaker(participant: Participant): void {
        const preParticipant = this.activeSpeakerSubject.value;
        if (preParticipant) { preParticipant.isActivatingSpeaker = false; }
        if (!participant) { return; }

        participant.isActivatingSpeaker = true;
        this.activeSpeakerSubject.next(participant);
    }

    public getActiveSpeaker(): Participant {
        return this.activeSpeakerSubject.value;
    }

    public onActiveSpeaker(): Observable<Participant> {
        return this.activeSpeakerSubject.asObservable();
    }

    public isExistParticipant(participantId: string): boolean{
        const participant = this.getActiveSpeaker();
        if (!participant) { return false; }
        return participant.id === participantId;
    }

    public setSpotlight(participant: Participant): void{
        this.spotlightSubject.next(participant);
    }

    public getSpotlight(): Participant {
        return this.spotlightSubject.value;
    }

    public onSpotlight(): Observable<Participant> {
        return this.spotlightSubject.asObservable();
    }

    public setResumeScreen(flag: boolean): void {
        // if(!this.resumeScreenSubject.value)
        this.resumeScreenSubject.next(flag);
    }

    public onResumeScreen(): Observable<boolean> {
        return this.resumeScreenSubject.asObservable();
    }

    // public setNewPeerJoinSubject(flag: boolean): void {
    //     this.newPeerJoinSubject = flag;
    // }
    
    // public getnewPeerJoinSubject(): boolean {
    //     return this.newPeerJoinSubject;
    // }
}
