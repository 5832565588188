import { Component, OnInit, OnDestroy, Input, Output, EventEmitter  } from '@angular/core';
import { OneuiI18nService } from '@vnpt/oneui-i18n';
import { AudioDeviceService, NotificationService, SettingsService } from '../../services'; 
import { RoomService } from '../../services';
import deviceInfo from '../../deviceInfo';
@Component({
  selector: 'lib-audio-device',
  templateUrl: './audio-device.component.html',
  styleUrls: ['./audio-device.component.less']
})
export class AudioDeviceComponent implements OnInit, OnDestroy {

  listMic = [];
  listSpeaker = [];
  selectedSpeaker = this.audioDeviceService.getSpeaker();
  selectedMic = this.audioDeviceService.getMic();
  defaultSystem = this.i18nService.translate("device.label.default",{},"Mặc định hệ thống");
  @Input() changeTemplate: boolean;
  @Input() childMessage: boolean;
  @Input() isAudioMuted: boolean
  @Output() pooper = new EventEmitter<any>();
  noDevice = false;
  streamCheck: MediaStream;
  deviceInfo = deviceInfo();
  filterNoiseStatus: boolean;
  constructor(
    private audioDeviceService: AudioDeviceService,
    private roomService: RoomService,
    private settingsService: SettingsService,
    private i18nService: OneuiI18nService,
    private notification: NotificationService,
  ) {
    this.filterNoiseStatus =this.settingsService.enableFilterNoise();
   }

  ngOnInit(): void {
    
    this.getSpeakerDevices().then(()=> {
      this.getAudioDevices();
      if(this.selectedSpeaker == null) this.selectedSpeaker = this.listSpeaker[0];
    });

    this.audioDeviceService.onListMic().subscribe((listMic)=> {
      this.listMic = listMic;
    })

    this.audioDeviceService.onListSpeaker().subscribe((listSpeaker)=> {
      this.listSpeaker = listSpeaker;
    })

    this.audioDeviceService.onMicDevice().subscribe((flag)=> {
      this.selectedMic = this.audioDeviceService.getMic();
    })

    this.audioDeviceService.onSpeakerDevice().subscribe((flag)=> {
      this.selectedSpeaker = this.audioDeviceService.getSpeaker();
    })
    
    // navigator.mediaDevices.ondevicechange = event => {
    //   this.getAudioDevices();
    // }
  }

  ngOnDestroy(): void {
    if(this.streamCheck)
   this.streamCheck.getTracks().forEach(track=> {
    track.stop();
   })
  }

  async getSpeakerDevices(): Promise <void> {
    let list = await navigator.mediaDevices.enumerateDevices();
    this.listSpeaker = list.filter(item => item.kind == "audiooutput" && item.deviceId != "communications" && item.label != "");
    if(this.deviceInfo.flag == 'firefox') {
      this.listSpeaker = [];
    }
    if(this.listSpeaker.length === 0) {
      this.listSpeaker.push({ deviceId: 'default', label: this.defaultSystem });
    }
  }

  async getAudioDevices(): Promise <void> {
    try {
      // if(this.audioDeviceService.getMicList() && this.audioDeviceService.getMicList().length > 0){
      //   this.listMic = this.audioDeviceService.getMicList();
      //   if (this.selectedMic == null) {
      //     this.selectedMic = this.listMic[0];
      //     this.audioDeviceService.changeMicDevice(this.selectedMic);
      //   }
      // }else{
        this.streamCheck = await navigator.mediaDevices.getUserMedia({ audio: true });
      
        const devices = await navigator.mediaDevices.enumerateDevices();
        this.listMic = devices.filter(item => (item.kind === "audioinput" && item.deviceId !== "communications" && item.deviceId !== ""));
        this.audioDeviceService.changeListMic(this.listMic);
        if(this.listMic.length === 0) {
          this.noDevice = true;
        }else if (this.selectedMic == null) {
          this.selectedMic = this.listMic[0];
          this.audioDeviceService.changeMicDevice(this.selectedMic);
        }
      // }
      
    } catch (error) {
      this.noDevice = true;
    }
    
  }


  choseSpeaker(speaker): void {
    this.selectedSpeaker = speaker;
    this.audioDeviceService.changeSpeaker(speaker);
  }

  choseMic(mic): void {
    this.selectedMic = mic;
    this.audioDeviceService.changeMicDevice(mic);
    try {
      const isMute = this.settingsService.getIsAudioMuted();
      if(!isMute)
      this.roomService.changeAudioMic();
    } catch (error) {    
    }
  }

  openSetting(): void {
    this.settingsService.setAudioSetting(true);
    this.pooper.emit();
  }

  onFilterNoise():void{
    if(!this.isAudioMuted){
      this.notification.warning(this.i18nService.translate("audio-device.filterNoiseErrorTitle"),this.i18nService.translate("audio-device.filterNoiseError"));
      return;
    }
    this.filterNoiseStatus = !this.filterNoiseStatus;
    this.settingsService.setFilterNoise(this.filterNoiseStatus);
    
  }
}