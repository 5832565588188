import {Action} from '@ngrx/store';

export const ADD_PEER = 'add peer';
export const REMOVE_PEER = 'remove peer';
export const CLEAR_PEERS = 'clear peers';
export const SET_PEER_DISPLAY_NAME = 'set peer display name';
export const SET_PEER_VIDEO_IN_PROGRESS = 'set peer video in progress';
export const SET_PEER_AUDIO_IN_PROGRESS = 'set peer audio in progress';
export const SET_PEER_SCREEN_IN_PROGRESS = 'set peer screen in progress';
export const SET_PEER_RAISED_HAND = 'set peer raised hand';
export const SET_PEER_RAISED_HAND_IN_PROGRESS = 'set peer raised hand in progress';
export const SET_PEER_PICTURE = 'set peer picture';
export const ADD_PEER_ROLE = 'add peer role';
export const REMOVE_PEER_ROLE = 'remove peer role';
export const SET_PEER_MODIFY_ROLES_IN_PROGRESS = 'set peer modify roles in progress';
export const SET_PEER_KICK_IN_PROGRESS = 'set peer kick in progress';
export const STOP_PEER_AUDIO_IN_PROGRESS = 'stop peer audio in progress';
export const STOP_PEER_VIDEO_IN_PROGRESS = 'stop peer video in progress';
export const STOP_PEER_SCREEN_SHARING_IN_PROGRESS = 'stop peer screen sharing in progress';
export const SET_PEER_LOCAL_RECORDING_STATE = 'set peer local recording state';

export class AddPeer implements Action{
    readonly type = ADD_PEER;
    public payload: any;
    constructor(peer) {
        this.payload = {peer};
    }

}
export class RemovePeer implements Action{
    readonly type = REMOVE_PEER;
    public payload: any;
    constructor(peerId) {
        this.payload = {peerId};
    }
}
export class ClearPeers implements Action{
    readonly type = CLEAR_PEERS;
    public payload: any;
    constructor() {
    }

}
export class SetPeerDisplayName implements Action{
    readonly type = SET_PEER_DISPLAY_NAME;
    public payload: any;
    constructor(displayName, peerId) {
        this.payload = {displayName, peerId};
    }

}
export class SetPeerVideoInProgress implements Action{
    readonly type = SET_PEER_VIDEO_IN_PROGRESS;
    public payload: any;
    constructor(peerId, flag) {
        this.payload = {peerId, flag};
    }
}
export class SetPeerAudioInProgress implements Action{
    readonly type = SET_PEER_AUDIO_IN_PROGRESS;
    public payload: any;
    constructor(peerId, flag) {
        this.payload = {peerId, flag};
    }

}
export class SetPeerScreenInProgress implements Action{
    readonly type = SET_PEER_SCREEN_IN_PROGRESS;
    public payload: any;
    constructor(peerId, flag) {
        this.payload = {peerId, flag};
    }

}
export class SetPeerRaisedHand implements Action{
    readonly type = SET_PEER_RAISED_HAND;
    public payload: any;
    constructor(peerId, raisedHand, raisedHandTimestamp) {
        this.payload = {peerId, raisedHand, raisedHandTimestamp};
    }

}
export class SetPeerRaisedHandInProgress implements Action{
    readonly type = SET_PEER_RAISED_HAND_IN_PROGRESS;
    public payload: any;
    constructor(peerId, flag) {
        this.payload = {peerId, flag};
    }

}
export class SetPeerPicture implements Action{
    readonly type = SET_PEER_PICTURE;
    public payload: any;
    constructor(peerId, picture) {
        this.payload = {peerId, picture};
    }

}
export class AddPeerRole implements Action{
    readonly type = ADD_PEER_ROLE;
    public payload: any;
    constructor(peerId, roleId) {
        this.payload = {peerId, roleId};
    }

}
export class RemovePeerRole implements Action{
    readonly type = REMOVE_PEER_ROLE;
    public payload: any;
    constructor(peerId, roleId) {
        this.payload = {peerId, roleId};
    }

}
export class SetPeerModifyRolesInProgress implements Action{
    readonly type = SET_PEER_MODIFY_ROLES_IN_PROGRESS;
    public payload: any;
    constructor(peerId, flag) {
        this.payload = {peerId, flag};
    }

}
export class SetPeerKickInProgress implements Action{
    readonly type = SET_PEER_KICK_IN_PROGRESS;
    public payload: any;
    constructor(peerId, flag) {
        this.payload = {peerId, flag};
    }

}
export class SetMutePeerInProgress implements Action{
    readonly type = STOP_PEER_AUDIO_IN_PROGRESS;
    public payload: any;
    constructor(peerId, flag) {
        this.payload = {peerId, flag};
    }

}
export class SetStopPeerVideoInProgress implements Action{
    readonly type = STOP_PEER_VIDEO_IN_PROGRESS;
    public payload: any;
    constructor(peerId, flag) {
        this.payload = {peerId, flag};
    }

}
export class SetStopPeerScreenSharingInProgress implements Action{
    readonly type = STOP_PEER_SCREEN_SHARING_IN_PROGRESS;
    public payload: any;
    constructor(peerId, flag) {
        this.payload = {peerId, flag};
    }

}
export class SetPeerLocalRecordingState implements Action{
    readonly type = SET_PEER_LOCAL_RECORDING_STATE;
    public payload: any;
    constructor(peerId, localRecordingState) {
        this.payload = {peerId, localRecordingState};
    }

}

export type All =
    AddPeer |
    RemovePeer |
    ClearPeers |
    SetPeerDisplayName |
    SetPeerVideoInProgress |
    SetPeerAudioInProgress |
    SetPeerScreenInProgress |
    SetPeerRaisedHand |
    SetPeerRaisedHandInProgress |
    SetPeerPicture |
    AddPeerRole |
    RemovePeerRole |
    SetPeerModifyRolesInProgress |
    SetPeerKickInProgress |
    SetMutePeerInProgress |
    SetStopPeerVideoInProgress |
    SetStopPeerScreenSharingInProgress |
    SetPeerLocalRecordingState;
