import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { AudioComponent } from './audio/audio.component';
import {Subscription} from 'rxjs';
import {ConsumerService} from '../../services';

@Component({
    selector: 'lib-list-audio',
    templateUrl: './list-audio.component.html',
    styleUrls: ['./list-audio.component.css']
})
export class ListAudioComponent implements OnInit, AfterViewInit, OnDestroy {
    audioConsumers: Map<string, any> = new Map();
    private subscriptions: Array<Subscription> = [];
    @ViewChild('audioContainer', {read: ViewContainerRef}) container: ViewContainerRef;

    constructor(private consumerService: ConsumerService,
                private componentFactoryResolver: ComponentFactoryResolver) {}

    ngOnInit(): void {
        this.subscriptions.push(this.consumerService.onAddAudioConsumerMap().subscribe(data => {
            if (!data) { return; }
            const { peerId } = data;
            if (this.audioConsumers.has(peerId)) { return; }
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(AudioComponent);
            const component = this.container.createComponent(componentFactory);
            component.instance.elementId = peerId;
            component.instance.peerId = peerId;
            component.instance.isAudioScreen = false;
            this.container.insert(component.hostView)
            this.audioConsumers.set(peerId, {component});
            component.instance.audioStateChange.subscribe((state: string) => {
                if (state == "error") {
                    console.log("can not play audio", data);
                }
            });
        }));

        this.subscriptions.push(this.consumerService.onAddAudioScreenConsumerMap().subscribe(data => {
            if (!data) { return; }
            const { peerId } = data;
            if (this.audioConsumers.has(`audioscreen-${peerId}`)) { return; }
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(AudioComponent);
            const component = this.container.createComponent(componentFactory);
            component.instance.elementId = `audioscreen-${peerId}`;
            component.instance.peerId = peerId;
            component.instance.isAudioScreen = true;
            this.container.insert(component.hostView)
            this.audioConsumers.set(`audioscreen-${peerId}`, {component});
        }));


        this.subscriptions.push(this.consumerService.onRemoveAudioConsumerMap().subscribe(peerId => {
            if (!peerId) { return; }
            const component = this.audioConsumers.get(peerId);
            if (!component) { return; }
            const index = this.container.indexOf(component.component.hostView)
            if (index !== -1) {
                this.container.remove(index);
                this.audioConsumers.delete(peerId);
            }
        }));

        this.subscriptions.push(this.consumerService.onRemoveAudioScreenConsumerMap().subscribe(peerId => {
            if (!peerId) { return; }

            const component = this.audioConsumers.get(`audioscreen-${peerId}`);
            if (!component) { return; }
            const index = this.container.indexOf(component.component.hostView);
            if (index !== -1) {
                this.container.remove(index);
                this.audioConsumers.delete(`audioscreen-${peerId}`);
            }
        }));
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
        for (const subs of this.subscriptions) {
            subs.unsubscribe();
        }
    }
}
