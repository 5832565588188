import { Component, OnDestroy, OnInit } from '@angular/core';
import { OneuiI18nService } from '@vnpt/oneui-i18n';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../../main/src/environments/environment';
import { config } from '../../../../config';
import { LayoutModel } from '../../../models/layout.model';
import { VirtualBackgroundService, SettingsService, NotificationService, RoomService } from '../../../services';
import { PoolService } from '../../../services/mediasoup/pool.service';

@Component({
  selector: 'lib-general-setting',
  templateUrl: './general-setting.component.html',
  styleUrls: ['./general-setting.component.less']
})
export class GeneralSettingComponent implements OnInit, OnDestroy {
  locale: string;
  languages: Array<Object>;
  notiCount: string;
  isOptionPinned = this.settingsService.getTriggerOptionBar();
  pagination : string;
  isVisible = false;
  subscriptions: Subscription[] = [];
  pagingCameraDisable = false;
  constructor(
    private i18nService:OneuiI18nService,
    private notificationService: NotificationService,
    private virtualBackgroundService: VirtualBackgroundService,
    private settingsService: SettingsService,
    private poolService: PoolService,
    private notification: NotificationService,
    private roomService: RoomService,
  ) {
    this.pagination = poolService.maxItem.toString();
    this.locale = localStorage.getItem('locale') || 'vi';
    this.languages = Object.values(config.i18n).map(lang => ({label: lang.name, value: lang.code}));
    this.notiCount = this.notificationService.getMaxStack().toString();
  }

  ngOnInit(): void {
    this.settingsService.onTriggerOptionBar().subscribe((isOptionPinned: boolean) => {
      this.isOptionPinned = isOptionPinned;
    });

    // this.subscriptions.push(this.roomService.onForceLayoutObservable().subscribe((layoutInfo: LayoutModel)=>{
    //   if(layoutInfo.layout != 0 && this.settingsService.getLayoutMode() != 'gallery'){
    //     this.pagingCameraDisable = true;
    //   }else{
    //     this.pagingCameraDisable = false;
    //   }
    //   this.pagination = this.poolService.getMaxItem().toString() ;

    // }))
  }

  ngOnDestroy(): void {
    for (const subs of this.subscriptions) {
        subs.unsubscribe();
    }
  }

  triggerOpenVirtualBackground(): void {
    this.virtualBackgroundService.setVirtualBackgroundOpen(true);
  }

  pinOption(event): void {
    this.isOptionPinned = !this.isOptionPinned;
    this.settingsService.triggerOptionBar(Boolean(this.isOptionPinned));
  }

  changeLang(locale: string): void {
    if (!config.i18n[locale]) locale = "vi";
    localStorage.setItem("locale", locale);
    // this.i18nService.load(locale, `${environment.domain.api}/${config.i18n[locale].src}`);
    this.i18nService.load(locale, `${config.i18n[locale].src}`);
  }

  changeNotiCount(count: string): void {
    this.notificationService.setMaxStack(parseInt(count));
  }

  // changePagination(num: string): void {
  //   this.poolService.setMaxItem(Number(num));
  //   if(Number(num) > 5){
  //     this.notification.warning("", this.i18nService.translate("setting.general.numberCamera",{},""));
  //   }
  //   this.pagination = num.toString() ;
  // }
}
