import {Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MediasoupService, ReactionsService, LocalParticipantService } from '../../services';

@Component({
    selector: 'lib-reactions',
    templateUrl: './reactions.component.html',
    styleUrls: ['./reactions.component.less']
})

export class ReactionsComponent implements OnInit {
    isRaisedHand = false;
    checkSpamRaiseHand = false;

    constructor(private reactionsService: ReactionsService,
                private mediasoupService: MediasoupService,
                private localParticipantService: LocalParticipantService,
                private readonly changeDetector: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.reactionsService.onLocalRaisedHand().subscribe((isRaisedHand: boolean) => {
            this.isRaisedHand = isRaisedHand;
        });
    }

    raiseHand(): void {
        this.checkSpamRaiseHand = !this.checkSpamRaiseHand;
        this.isRaisedHand = !this.isRaisedHand;
        this.mediasoupService.setRaisedHand(this.isRaisedHand);
        this.reactionsService.setLocalRaisedHand(this.isRaisedHand);
        this.localParticipantService.setRaiseHand(this.isRaisedHand);
        this.changeDetector.detectChanges();
        setTimeout(async () => {
            this.checkSpamRaiseHand = !this.checkSpamRaiseHand;
        }, 2000);
    }
}
