export interface ShareScreenModel {
    autoGainControl: any;
    echoCancellation: any;
    noiseSuppression: any;
    screenSharingResolution: any;
    screenSharingFrameRate: any;
    aspectRatio: any;
    sampleRate: any;
    channelCount: any;
    sampleSize: any;
    opusStereo: any;
    opusDtx: any;
    opusFec: any;
    opusPtime: any;
    opusMaxPlaybackRate: any;
}

export class ScreenShareDefault implements ShareScreenModel{
    autoGainControl = null;
    echoCancellation = null;
    noiseSuppression = null;
    screenSharingResolution = null;
    screenSharingFrameRate = null;
    aspectRatio = null;
    sampleRate = null;
    channelCount = null;
    sampleSize = null;
    opusStereo = false;
    opusDtx = true;
    opusFec = true;
    opusPtime = 20;
    opusMaxPlaybackRate = 48000;
}
