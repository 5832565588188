/* tslint:disable:no-output-on-prefix prefer-const variable-name adjacent-overload-signatures */
import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnInit,
    Renderer2,
    ViewChild,
    OnDestroy, ChangeDetectorRef
} from '@angular/core';
import { createFadeAnimation } from '../../animation';
import {Subscription} from 'rxjs';
import {RemoteParticipant} from '../../models/remote-participant.model';
import {
    ActiveSpeakerService,
    MediasoupService,
    SettingsService,
    SocketService,
    ShareDataService,
    MediaStreamService,
    LocalParticipantService,
    RolesManagerService,
    RoomService,
    RightPaneViewType,
    ChattingService,
    
} from '../../services';
import { DISPLAY_LAYOUT, PERMISSIONS, VIDEO_FADE_DURATION, VIDEO_FADE_DURATION_WITH_NO_GLITCH_DELAY } from '../../constants';
import { Local } from 'protractor/built/driverProviders';
import { permissions } from '../../permissions';
import { PoolService } from '../../services/mediasoup/pool.service';
import { SpeakerModel } from '../../models/speaker.model';
import { SpeakingService } from '../../services/speaking.service';
import { OneuiI18nService } from '@vnpt/oneui-i18n';
import { VModalService } from '@vnpt/oneui-ui/modal';

@Component({
    selector: 'lib-remote-streaming',
    templateUrl: './remote-streaming.component.html',
    styleUrls: ['./remote-streaming.component.less'],
    animations: [ createFadeAnimation(VIDEO_FADE_DURATION, VIDEO_FADE_DURATION_WITH_NO_GLITCH_DELAY) ]
})
export class RemoteStreamingComponent implements OnInit, AfterViewInit, OnDestroy {
    participant: RemoteParticipant;
    isSpeaking: boolean = false;
    hasPermissionModerator = false;
    hasPermissionAdmin = false;
    participantIsAdmin = false;
    participantIsModerator = false;
    isShowOption = false;
    dropdownVisible = false;
    _participantId = '';
    subscriptions: Subscription[] = [];
    isScreenMuted = true;
    score = 10;
    coreColor: string = "";
    wrapperHeight = 'unset';
    followedByWidth = true;
    screenObserver: ResizeObserver;
    isBlockAllWebcam = false;
    isBlockAllAudio = false;
    randomColor : string ='#456BD9';
    layoutMode = this.settingsService.getLayoutMode();
    isHoldSpaceTitle: boolean = false;
    isFullScreen: boolean = false;
    micStatus: boolean = false;
    isJoinedAudio: boolean = false;
    micIconVisible: boolean = false;
    timeOut: any = null;
    spotLight: boolean = false;
    nameAcronym: string = '';
    isWebcamsOnlyForModerator: boolean = false;
    @Input() miniView = true;
    @Input() forceLayoutStatus =  false;
    @Input() set participantId(id: string) {
        if (id) {
            this._participantId = id;
            // this.participant = this.poolService.getPeer(id);
            this.isScreenMuted = true;
            this.updateViewingParticipant();
        }
    }

    get participantId(): string{
        return this._participantId;
    }
    @ViewChild('streamSector') streamSector: ElementRef;
    private remoteVideoRef: ElementRef;
    @ViewChild('remoteVideoRef') set content(content: ElementRef) {
       if (content) { // initially setter gets called with undefined
           this.remoteVideoRef = content;
        //    console.log("# set remoteViewRef #");
           this.checkJoinAudio(this.settingsService.getIsJoinedAudio());
       }
    }
    // @ViewChild('remoteVideoRef') remoteVideoRef: ElementRef;
    // @ViewChild('sharingScreenRef') sharingScreenRef: ElementRef;

    constructor(
        private poolService:  PoolService,
        private activeSpeakerService: ActiveSpeakerService,
        private mediasoupService: MediasoupService,
        private socketService: SocketService,
        private renderer: Renderer2,
        private cdRef: ChangeDetectorRef,
        private settingsService: SettingsService,
        private shareDataService: ShareDataService,
        private mediaStreamService: MediaStreamService,
        private localParticipant: LocalParticipantService,
        private rolesManagerService: RolesManagerService,
        private localParticipantService: LocalParticipantService,
        private roomService:  RoomService,
        private chattingService: ChattingService,
        private speakingService: SpeakingService,
        private i18nService:OneuiI18nService,
        private modalService: VModalService,
    ) {
        // this.participant = this.poolService.getPeer(this.participantId);//getRemoteParticipant(this.participantId);
    }

    ngOnInit(): void {
        this.participantIsAdmin =  this.participant.roles.includes(PERMISSIONS.ADMIN.id);
        this.participantIsModerator = this.participant.roles.includes(PERMISSIONS.MODERATOR.id);
        this.onInitEvent();
        this.nameAcronym = this.getNameAcronym();
    }

    ngAfterViewInit(): void {
        const remote = this.remoteVideoRef;
        this.renderer.setAttribute(remote.nativeElement, 'background-color', 'black');

        if (this.participant.webcam.consumerId) {
            this.updateWebcamConsumer();
        }
        //An view stream share screen
        // if (this.participant.screen.consumerId) {
        //     this.updateShareScreenConsumer();
        // }

        if (remote && remote.nativeElement) {
            remote.nativeElement.muted = true;
        }

        this.screenObserver.observe(remote.nativeElement);
        // this.onInitEvent();
        // this.checkJoinAudio(this.settingsService.getIsJoinedAudio());
    }

    ngOnDestroy(): void {
        if(this.participant.webcam.state && !this.remoteVideoRef.nativeElement.paused) {
            this.remoteVideoRef.nativeElement.pause();
        }
        for (const subs of this.subscriptions) {
            subs.unsubscribe();
        }
        if (this.screenObserver) { this.screenObserver.disconnect(); }
    }

    checkJoinAudio(isJoined: boolean) {
        // console.log("# checkJoinAudio", {isJoined, element: this.remoteVideoRef});
        this.isJoinedAudio = isJoined;

        const remote = this.remoteVideoRef;
        if(!isJoined || !remote?.nativeElement) { return; }
        let video =  remote.nativeElement;
        
        if (this.settingsService.isBrowseSafari() && remote.nativeElement.paused) {
            video.oncanplay = () => {
                video.play();
            };
            video.load();
        }
        // this.cdRef.detectChanges();
    }
    onInitEvent(): void {
        //Ktra trang thai WebcamsOnlyForModerator. True: disable button pin
        this.subscriptions.push(this.settingsService.onWebcamsOnlyForModerator().subscribe((flag: boolean) =>{
            this.isWebcamsOnlyForModerator = flag;
        }));
        
        this.subscriptions.push(this.settingsService.onJoinAudio().subscribe(this.checkJoinAudio.bind(this)));

        this.subscriptions.push(this.speakingService.activeSpeakerObserver.subscribe((speakingList: SpeakerModel[]) => {
            this.isSpeaking = speakingList.find(item => item.id == this.participant.id) != undefined;
        }));

        /* this.subscriptions.push(this.shareDataService.onUpdateStreamingParticipant().subscribe((peerId: string) => {
            if (peerId === this.participant.id) { this.updateViewingParticipant(); }
        })); */

        this.subscriptions.push(this.poolService.onUpdateSearch().subscribe(flag =>{
            this.participantIsModerator = this.participant.roles.includes(PERMISSIONS.MODERATOR.id);
        }));

        this.subscriptions.push(this.localParticipantService.onRoles().subscribe((roles: number[]) => {
            this.hasPermissionModerator = this.roomService.hasPermission(permissions.MODERATE_ROOM);
            this.hasPermissionAdmin = this.localParticipantService.getRolesSubject().includes(PERMISSIONS.ADMIN.id); 
        }));

        this.subscriptions.push(this.settingsService.onVideoBlocked().subscribe((isBlockAllVideo: boolean) => {
            this.isBlockAllWebcam = isBlockAllVideo;
        }));

        this.subscriptions.push(this.settingsService.onAudioBlocked().subscribe((isBlockAllAudio: boolean) => {
            this.isBlockAllAudio = isBlockAllAudio;
        }));

        this.subscriptions.push(this.mediaStreamService.onUpdateStream().subscribe(stream => {
            if (stream && stream.peerId === this.participant.id) {
                const {kind, mediaStream} = stream;
                if(kind != null && mediaStream != null){
                    this.updateViewingParticipant(kind, mediaStream);
                }
                
            }
        }));

        this.subscriptions.push(this.activeSpeakerService.onSpotlight().subscribe((spot: any) => {
            if(spot){
                this.spotLight = true;
            } 
            else {
                this.spotLight = false;
            }
        }))

        this.subscriptions.push(this.mediasoupService.onUpdateVideo().subscribe(async (consumerId: string) => {
            const remote = this.remoteVideoRef;
            if (!consumerId || consumerId !== this.participant.webcam.consumerId ||
                !remote || !remote.nativeElement) { return; }
            this.requestConsumerReferredLayer(this.participant);
            // await this.mediasoupService.adaptConsumerPreferredLayers(consumerId, clientWidth, clientHeight);
        }));

        this.subscriptions.push(this.mediasoupService.onUpdateRemoteVideoScore().subscribe(data => {
            if (!data) { return; }
            const { consumerId, score } = data;
            if (!consumerId || consumerId !== this.participant.webcam.consumerId || !score) { return; }
            this.score = score;
            this.coreColor = this.getScoreColor();
            // this.cdRef.detectChanges();
        }));

        this.subscriptions.push(this.settingsService.onChangeLayoutMode().subscribe(layout => {
            this.layoutMode = layout;
        }));

        // @ts-ignore
        this.screenObserver = new ResizeObserver((entries: any) => {
            const { height } = entries[0].contentRect;
            this.wrapperHeight = `${height}px`;
            this.requestConsumerReferredLayer(this.participant);            
            // this.cdRef.detectChanges();
        });

        this.subscriptions.push(this.settingsService.onIsSpaceHoldSubject().subscribe(flag => {
            this.micStatus = !flag;
            if(!this.micStatus) {
             this.micIconVisible = true;
             clearTimeout(this.timeOut);   
            }
            else {
              this.timeOut =  setTimeout(() => {
                    this.micIconVisible = false;
                    clearTimeout(this.timeOut);
                }, 2000);
            }
        }));
        
        this.subscriptions.push(this.poolService.onDisplayNameChange().subscribe((info)=>{
            if(this.participant.id == info?.id) {
                this.nameAcronym = this.getNameAcronym();
            }
        }));
    }

    isShowSpotlight(): boolean {
        return this.layoutMode === 'speaker' || this.layoutMode === 'sidebyside';
    }

    updateViewingParticipant(kind: 'audio' | 'webcam' | 'screen' | 'audioscreen' = "webcam", stream?: MediaStream): void{
        // this.participant.destroyIntervalStatusStreaming();
        this.participant = this.poolService.getPeer(this.participantId); // getRemoteParticipant(this.participantId);

        // const isWebcamConsumerClosed = remoteService.isRemoteContainWebcamClosed(this.participantId);
        // if (isWebcamConsumerClosed){
        //     this.socketService.createNewVideoConsumer(this.participantId);
        //     remoteService.removeRemoteContainWebcamClosed(this.participantId);
        // }

        if (this.participant.webcam.consumerId){
            if (kind === 'webcam') { this.updateWebcamConsumer(stream); }
            // if(!kind) {this.updateWebcamConsumer()};
        }
        //An view stream share screen
        // if (this.participant.screen.consumerId && this.isScreenMuted !== !participant.screen.state){
        //     if (kind && kind === 'screen') { this.updateShareScreenConsumer(stream); }
        //     else { this.updateShareScreenConsumer(); }
        // }
        this.isScreenMuted = !this.participant.screen.state;

        // this.checkingStatusVideo();
    }

    updateWebcamConsumer(stream?: MediaStream): void {
        const remote = this.remoteVideoRef;
        if (remote && remote.nativeElement) {
            this.playVideo(stream);
        }
    }
    //An view stream share screen
    // updateShareScreenConsumer(stream?: MediaStream): void {
    //     if (!stream) { stream = this.mediaStreamService.getMediaStream(this.participant.id, 'screen'); }
    //     if (!stream || !this.sharingScreenRef) { return; }
    //     this.sharingScreenRef.nativeElement.srcObject = stream;
    //     if (stream.getVideoTracks().length) {
    //         const { width, height } = stream.getVideoTracks()[0].getSettings();
    //         if (width < height) { this.followedByWidth = false; }
    //         this.sharingScreenRef.nativeElement.muted = true;
    //         this.sharingScreenRef.nativeElement.pause();
    //        this.sharingScreenRef.nativeElement.play().catch(error=>{
    //         console.log("updateShareScreenConsumer error")
    //        });
    //         this.screenObserver.observe(this.sharingScreenRef.nativeElement);
    //     } else {
    //         if (this.screenObserver) { this.screenObserver.disconnect(); }
    //     }
    // }

    requestConsumerReferredLayer(remote: RemoteParticipant) {
        if (!remote || !remote.webcam.state) return;
        
        const activeSpeaker = this.activeSpeakerService.getActiveSpeaker();
        if(activeSpeaker && activeSpeaker.id == this.participant.id) {return;}
        const { clientWidth, clientHeight } = this.remoteVideoRef.nativeElement;
        // const screenState = remote.screen.state;
        
        if (clientWidth && clientHeight) {
            // console.log("# requestConsumerReferredLayer");
            this.mediasoupService.adaptConsumerPreferredLayers(remote.webcam.consumerId, clientWidth, clientHeight);
        }
    }

    async expandVideo(): Promise<void> {
        const remote = this.streamSector;
        if (remote) {
            if (remote.nativeElement.requestFullscreen) {
                remote.nativeElement.requestFullscreen();
              } else if (remote.nativeElement.mozRequestFullScreen) {
                remote.nativeElement.mozRequestFullScreen();
              } else if (remote.nativeElement.webkitRequestFullscreen) {
                remote.nativeElement.webkitRequestFullscreen();
              } else if (remote.nativeElement.msRequestFullscreen) {
                remote.nativeElement.msRequestFullscreen();
              }
            remote.nativeElement.onfullscreenchange = ()=>  {
                // this.isFullScreen = document.fullscreenElement == null ? false : true;
                if(document.fullscreenElement == null) {
                    this.isFullScreen = false;
                }
                else {
                    this.isFullScreen = true;
                }
            }
            /* Chrome, Safari and Opera syntax */
            remote.nativeElement.onwebkitfullscreenchange = ()=>  {
                // this.isFullScreen = (document as any).webkitFullscreenElement == null ? false : true;
                if((document as any).webkitFullscreenElement == null) {
                    this.isFullScreen = false;
                }
                else {
                    this.isFullScreen = true;
                }
            }
            /* IE/Edge syntax */;
            remote.nativeElement.onmsfullscreenchange = ()=>  {
                // this.isFullScreen = (document as any).msFullscreenElement == null ? false : true;
                if((document as any).msFullscreenElement == null) {
                    this.isFullScreen = false;
                }
                else {
                    this.isFullScreen = true;
                }
            }
            navigator.mediaDevices.getUserMedia({audio: true}).then(async(stream) => {
                const listDevice = await navigator.mediaDevices.enumerateDevices();
                const listSpeaker = listDevice.filter(item => item.kind == "audioinput" && item.deviceId != "communications" && item.deviceId != "");
                this.isHoldSpaceTitle = this.settingsService.getIsAudioMuted() && !this.settingsService.getIsAudioBlocked() && !!(listSpeaker.length > 0);
                var timeout = setTimeout(() => {
                    this.isHoldSpaceTitle = false;
                    clearTimeout(timeout);
                }, 5000);
                stream.getTracks().forEach(track=> {
                    track.stop();
                })
            }).catch((reason) => {});
        }
    }

    closeFullScreen(): void {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if ((document as any).webkitExitFullscreen) {
            (document as any).webkitExitFullscreen();
        } else if ((document as any).mozCancelFullScreen) {
            (document as any).mozCancelFullScreen();
        } else if ((document as any).msExitFullscreen) {
            (document as any).msExitFullscreen();
        }
    }

    // toggleSpotLight(): void {
    //     if (this.participant.isActivatingSpeaker) {
    //         const setting = this.settingsService;
    //         const layoutMode = setting.getLayoutMode();
    //         if (layoutMode !== 'speaker' && layoutMode !== 'sidebyside'){
    //             setting.changeLayoutMode('sidebyside');
    //             return;
    //         }
            
    //         if(!this.activeSpeakerService.getSpotlight() || this.activeSpeakerService.getSpotlight().id != this.participant.id) {
    //             const remoteParticipant = this.poolService.getPeer(this.participant.id);
    //             this.activeSpeakerService.setSpotlight(remoteParticipant)
    //         }
    //         else {
    //             this.activeSpeakerService.setActiveSpeaker(this.localParticipant.getLocalParticipant());
    //             this.activeSpeakerService.setSpotlight(null);
    //         }
    //         return;
    //     }

    //     const listCurrentPage = this.poolService.getViewFullPeers(); //getListRemoteParticipantView();
    //     const activeSpeaker = this.activeSpeakerService.getActiveSpeaker();
    //     if(activeSpeaker){
    //         const remoteExists = listCurrentPage.some(remote => remote.id === activeSpeaker.id);
    //         const remoteCurentActive: RemoteParticipant = this.poolService.getPeer(activeSpeaker.id); //getRemoteParticipant(activeSpeaker.id);
    //         if(!remoteExists && remoteCurentActive) {
    //             remoteCurentActive.stopWebcam();
    //         }
    //     }

    //     const remoteParticipant = this.poolService.getPeer(this.participant.id); //getRemoteParticipant(this.participant.id);
    //     this.activeSpeakerService.setActiveSpeaker(remoteParticipant);
        
    //     this.activeSpeakerService.setSpotlight(remoteParticipant);
    // }

    addScreenToSpotlight(): void {
        //Ktra truong hop co force layout: User ko phai moderator se khong thuc hien dc thao tac
        if(!this.hasPermissionModerator && this.roomService.getForceLayout.layout != 0) return;

        const remoteParticipant = this.poolService.getPeer(this.participant.id);
        this.activeSpeakerService.setActiveSpeaker(remoteParticipant);
        const layoutMode = this.settingsService.getLayoutMode();
        if (layoutMode && layoutMode !== 'breakroom') {
            if(this.roomService.getForceLayout.layout != 0 ){
                let layoutId = DISPLAY_LAYOUT.find(layout => layout.name == 'sidebyside').id;
                let {pinners, hideNoneCamera } = this.roomService.getForceLayout;
                this.roomService.setForceLayout(layoutId, pinners, hideNoneCamera);
                this.roomService.changeForceLayout(layoutId, pinners, hideNoneCamera);
            }else{
                this.settingsService.changeLayoutMode('sidebyside');
            }
        }
    }

    playVideo(stream?: MediaStream): void {

        let cameraTrack: MediaStream;
        if (stream) { cameraTrack = stream; }
        else { cameraTrack = this.mediaStreamService.getMediaStream(this.participant.id, 'webcam'); }

        if (!cameraTrack) { return; }
        let video = this.remoteVideoRef.nativeElement;
        // video.pause();
        video.currentTime = 0;
        if(!video.srcObject) {
            video.load();
        }
        video.srcObject = cameraTrack;
        video.oncanplay = () => {
            video.play();
        };

    }

    // private checkingStatusVideo(): void {
    //     let isResetVideoConsumer = false;
    //     this.subscriptions.push(this.participant.onStatusStreamingVideo()
    //         .subscribe(async (status) => {
    //         if (this.remoteVideoRef) {
    //             if (status === 1 && this.remoteVideoRef.nativeElement.paused ) {
    //                 isResetVideoConsumer = true;
    //                 const {videoConsumerMap} =  await this.mediasoupService.requestPeerVideoConsumer(this.participant.id);
    //                 if (videoConsumerMap && videoConsumerMap.isHaveWebcam) {
    //                     await this.mediasoupService.emitServerCloseConsumer(this.participant.webcam.consumerId);
    //                     await this.mediasoupService.createNewVideoConsumer(this.participant.id);
    //                 }
    //                 isResetVideoConsumer = false;
    //             } else if (this.remoteVideoRef.nativeElement?.played?.length === 0 && !isResetVideoConsumer) {
    //                 isResetVideoConsumer = true;
    //                 const {videoConsumerMap} =  await this.mediasoupService.requestPeerVideoConsumer(this.participant.id);
    //                 if (videoConsumerMap && videoConsumerMap.isHaveWebcam) {
    //                     await this.mediasoupService.emitServerCloseConsumer(this.participant.webcam.consumerId);
    //                     await this.mediasoupService.createNewVideoConsumer(this.participant.id);
    //                 }
    //                 isResetVideoConsumer = false;
    //             } else if (this.remoteVideoRef.nativeElement.played && this.remoteVideoRef.nativeElement.currentTime === 0 ) {
    //                 isResetVideoConsumer = true;
    //                 const {videoConsumerMap} =  await this.mediasoupService.requestPeerVideoConsumer(this.participant.id);
    //                 if (videoConsumerMap && videoConsumerMap.isHaveWebcam) {
    //                     await this.mediasoupService.emitServerCloseConsumer(this.participant.webcam.consumerId);
    //                     await this.mediasoupService.createNewVideoConsumer(this.participant.id);
    //                 }
    //                 isResetVideoConsumer = false;
    //             }
    //         }

    //     }));
    // }

    getScoreColor(): string {
        switch (this.score) {
            case 0:
            case 1:
            case 2:
            case 3:
                return 'icon-error';
            case 4:
            case 5:
            case 6:
                return 'icon-orange';
            case 7:
            case 8:
            case 9:
                return 'icon-warning';
            case 10:
                return '';
        }
    }

    // toggleRemoteVideo(): void {
    //     if (!this.participant.webcam.consumerId) { return; }
    //     const status = this.participant.getLocallyPaused('webcam');
    //     this.mediasoupService.modifyPeerConsumer(this.participant.id, 'webcam', !status);
    // }

    // toggleRemoteAudio(): void {
    //     if (!this.participant.webcam.consumerId) { return; }
    //     const status = this.participant.getLocallyPaused('audio');
    //     this.mediasoupService.modifyPeerConsumer(this.participant.id, 'mic', !status);
    // }

    muteRemoteParticipant(): void {
        this.mediasoupService.muteRemoteParticipant(this.participant.id);
    }

    stopRemoteParticipantVideo(): void {
        this.mediasoupService.stopRemoteParticipantVideo(this.participant.id);
    }

    kickRemoteParticipant(): void {
        this.modalService.confirm({
            vTitle: this.i18nService.translate("list-participant.kickTitle",{name: this.participant.displayName},"Xóa khỏi cuộc họp"),
            vContent: this.i18nService.translate("list-participant.kickContent",{name: this.participant.displayName},"Bạn có thực sự muốn đóng cuộc họp không?"),
            vOkText: this.i18nService.translate("ok",{},"Đồng ý"),
            vCancelText: this.i18nService.translate("cancel",{},"Hủy"),
            vOnOk: ()=> this.mediasoupService.kickRemoteParticipant(this.participant.id),
            vOkDanger: true,
          });
    }

    stopRemoteParticipantScreenSharing(): void {
        this.mediasoupService.stopRemoteParticipantScreenSharing(this.participant.id);
    }

    openRolesManager(): void {
        this.rolesManagerService.setRoleManagerOpen(true);
        this.rolesManagerService.setRemoteParticipant(this.participant);
    }

    openPrivateChat(type): void {
        // this.triggerSelectedOption(type);
        let id = this.participantId;
        // this.chattingService.getPrivateChatMessage(id);
        this.poolService.getPeer(id).setWaitingMessage(0);
        // this.onShowContainerRight.emit({ type, id});
        // var evt = new CustomEvent("triggerRightContainer", {detail: {type: type, id: id}});
        // window.dispatchEvent(evt);

        this.chattingService.setActiveChat(id);
        this.roomService.rightPaneView = "chatting" as RightPaneViewType;
    }

    getNameAcronym(): string {
        const name = this.poolService.getPeer(this._participantId).displayName.toString();
        if(name.lastIndexOf(' ') < 0){
            return name.charAt(0)
        }
        return name.charAt(0) + name.charAt(name.lastIndexOf(' ')+1);
    }

    pinCameraHandler(): void {
        let isForceLayout : boolean = this.roomService.getForceLayout.layout != 0;
        if(isForceLayout){
            // this.poolService.setIsPin(this.participant.id, !this.participant.isPin, isForceLayout, this.roomService.getForceLayout.layout );
            if(this.roomService.getForceLayout && this.hasPermissionModerator){
                let pinners = Array.from(this.poolService.getRemotesPin.map((remote) => remote.id));
                if (!this.participant.isPin) {
                    pinners.push(this.participant.id)
                }else {
                    const index = pinners.indexOf(this.participant.id, 0);
                    if (index > -1) {
                        pinners.splice(index, 1);
                    }
                }
                this.roomService.moderatorPin(pinners).then((result) =>{
                    if(result){
                        this.poolService.setIsPin(this.participant.id, !this.participant.isPin, isForceLayout, this.roomService.getForceLayout.layout );
                        // this.roomService.moderatorPin(pinners);
                    }
                });
            } 
        }else{
            this.poolService.setIsPinNormal(this.participant.id, !this.participant.isPin );
        }

    }
}
