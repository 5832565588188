/* tslint:disable:no-redundant-jsdoc prefer-const */
declare var GoogleMeetSegmentationTFLiteWorker: any;
declare var generateDefaultGoogleMeetSegmentationTFLiteParams: any;
declare var generateGoogleMeetSegmentationTFLiteDefaultConfig: any;
declare var GoogleMeetSegmentationTFLiteWorkerManager: any;

// import {
//     generateDefaultGoogleMeetSegmentationTFLiteParams,
//     generateGoogleMeetSegmentationTFLiteDefaultConfig,
//     GoogleMeetSegmentationTFLiteWorkerManager
// } from '@dannadori/googlemeet-segmentation-tflite-worker-js';

const lightWrapping = 8;
const fps = 30;

export class Blur {
    private state: any;
    private processOnLocal: any;
    private modelPath: any;
    private enableSIMD: any;
    private kernelSize: any;
    private useSoftmax: any;
    private usePadding: any;
    private threshold: any;
    private useSIMD: any;
    private isStop: any;
    private renderRequestId = null;

    constructor() {
        this.state = {
            video: null,
            stream: null,
            manager: null,
            params: null,
            config: null,
            videoConstraints: {},
            image: document.createElement('img'), // background
        };
        this.processOnLocal = true;
        this.modelPath = 'assets/selfie_segmentation_landscape.tflite';
        this.enableSIMD = false;
        this.kernelSize = 0;
        this.useSoftmax = true;
        this.usePadding = false;
        this.threshold = 0.1;
        this.useSIMD = false;
        this.isStop = true;
    }

    async init(): Promise<any> {
        const m = new GoogleMeetSegmentationTFLiteWorkerManager();
        const c = generateGoogleMeetSegmentationTFLiteDefaultConfig();
        c.processOnLocal = this.processOnLocal;
        c.modelPath = this.modelPath;
        c.enableSIMD = this.enableSIMD;
        await m.init(c);

        const p = generateDefaultGoogleMeetSegmentationTFLiteParams();
        p.processWidth = 96;
        p.processHeight = 160;
        p.kernelSize = this.kernelSize;
        p.useSoftmax = this.useSoftmax;
        p.usePadding = this.usePadding;
        p.threshold = this.threshold;
        p.useSIMD = this.useSIMD;

        this.state.manager = m;
        this.state.config = c;
        this.state.params = p;
        this.isStop = false;
        return true;
    }

    async isReady(): Promise<any> {
        if (this.state.manager != null) {
            this.isStop = false;
            return true;
        }
        return await this.init();
    }

    Stop(): void {
        this.isStop = true;
    }

    Start(): void {
        this.isStop = false;
    }

    async _estimateSegmentation(sourceStream): Promise<any> {
        return await this.state.manager.predict(sourceStream, this.state.params);
    }

    // thay thế setTimeout bằng requestAnimationFrame 
    // Hạn chế số lần gọi và đồng bộ hóa với tần số làm mới của trình duyệt.
    segmentBodyInRealTime(canvas, sourceStream, callback): void {
        try {
            const tmp = document.createElement('canvas');
            const front = document.createElement('canvas');
            const dstCtx = canvas.getContext('2d');
            canvas.height = sourceStream.videoHeight;
            canvas.width = sourceStream.videoWidth;
            tmp.width = canvas.width;
            tmp.height = canvas.height;
            front.width = canvas.width;
            front.height = canvas.height;
            let stream = canvas.captureStream(fps);
            if (callback) {
                callback(stream);
            }
      
            const bodySegmentationFrame =async () => {
                try {
                    if (this.isStop) {
                        return;
                    }
                    if (sourceStream) {
                     await this.drawCanvas(front, tmp, sourceStream, dstCtx, canvas);
                    }
                    setTimeout(() => {
                        requestAnimationFrame(bodySegmentationFrame);
                    }, 1000 / 30);
                } catch (error) {
                    console.log(error);
                    console.log(">>Tạm dừng xử lý background ảo khi nào chọn lại hoặc kích hoạt lại sẽ chạy lại");
                    
                }
                
            };
      
            if (!this.isStop) {
                requestAnimationFrame(bodySegmentationFrame);
            }
        } catch (error) {
            console.log(error);
            throw error;
        }
      }
      

    setBackgroundPath(backgroundPath): void {
        if (backgroundPath !== 'none') {
            this.state.image.setAttribute('src', backgroundPath);
        } else {
            this.state.image.removeAttribute('src');
        }
    }

    setBackgroundSource(src: any): void{
        this.state.image.src = src;
    }

    haveBackgroundImage(): boolean {
        if (this.state.image.src !== '') {
            return true;
        }
        return false;
    }

    /**
     *
     * @param {HTMLCanvasElement} front
     * @param {HTMLCanvasElement} tmp
     * @param {HTMLVideoElement} sourceStream
     * @param {CanvasRenderingContext2D} dstCtx
     * @param {HTMLCanvasElement} canvas of dstCtx
     */
    async drawCanvas(front, tmp, sourceStream, dstCtx, canvas): Promise<any> {
        const prediction = await this._estimateSegmentation(sourceStream);

        const res = new ImageData(this.state.params.processWidth, this.state.params.processHeight);
        for (let i = 0; i < this.state.params.processWidth * this.state.params.processHeight; i++) {
            res.data[i * 4 + 0] = prediction[i];
            res.data[i * 4 + 1] = prediction[i];
            res.data[i * 4 + 2] = prediction[i];
            res.data[i * 4 + 3] = prediction[i];
        }

        tmp.width = this.state.params.processWidth;
        tmp.height = this.state.params.processHeight;
        tmp.getContext('2d').putImageData(res, 0, 0);

        // Quá trình truyền dẫn tiền cảnh
        const frontCtx = front.getContext('2d');
        frontCtx.clearRect(0, 0, front.width, front.height);
        frontCtx.drawImage(tmp, 0, 0, front.width, front.height);
        frontCtx.globalCompositeOperation = 'source-atop';
        frontCtx.drawImage(sourceStream, 0, 0, front.width, front.height);
        frontCtx.globalCompositeOperation = 'source-over';

        // Viết cuối cùng
        dstCtx.clearRect(0, 0, canvas.width, canvas.height);
        dstCtx.drawImage(this.state.image, 0, 0, canvas.width, canvas.height);
        if (lightWrapping > 0) {
            dstCtx.filter = `blur(${lightWrapping}px)`;
            dstCtx.drawImage(tmp, 0, 0, canvas.width, canvas.height);
            dstCtx.filter = 'none';
        }
        dstCtx.drawImage(front, 0, 0, canvas.width, canvas.height);
    }
}

const blur = new Blur();
export default blur;
