/* tslint:disable:one-variable-per-declaration */
import {ChangeDetectorRef, Component, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy} from '@angular/core';
import {RemoteParticipant} from '../../../models/remote-participant.model';
import {LocalParticipantService} from '../../../services/local-participant.service';
import {BreakRoomService} from '../../../services/breakroom.service';
import {
  RATIO_WIDTH,
  RATIO_HEIGHT,
  VIDEO_COLUMN_SIZE_FORMAT,
  COL_ONE_ROW,
} from '../../../constants';
// @ts-ignore
import {Subscription} from 'rxjs';
import { BreakroomData } from '../../../models/breakroom.model';
import { PoolService } from '../../../services/mediasoup/pool.service';

const ALL_GROUP_NAME = 'all';

@Component({
    selector: 'lib-breakroom',
    templateUrl: './breakroom.component.html',
    styleUrls: ['./breakroom.component.less'],
})
export class BreakRoomComponent implements OnInit, AfterViewInit, OnDestroy {
    constructor(
        private poolService: PoolService,
        private localParticipantService: LocalParticipantService,
        private breakRoomService: BreakRoomService,
        private cdRef: ChangeDetectorRef
    ) {
    }

    currentPageIndex = 1;
    listRemoteParticipant: RemoteParticipant[] = [];
    videoColumnSize = VIDEO_COLUMN_SIZE_FORMAT.ONE_COLUMN * 100;
    participantVideoBoxObserver: ResizeObserver;
    adminVideoBoxObserver: ResizeObserver;
    adminVideoBoxWidth = 100;
    boxSize = {width: 0, height: 0};
    miniView = false;
    meID = '';
    teacherID = '';
    myGroupId = '';
    listGroups: string[] = [];
    isSiteTeacher = true;
    subscriptions: Subscription[] = [];
    listViewHeight: number;
    listGroupHeight: number;
    listViewObserver: ResizeObserver;
    listGroupObserver: ResizeObserver;
    @ViewChild('adminVideoBox') adminVideoBox: ElementRef;
    @ViewChild('participantVideoBox') participantVideoBox: ElementRef;
    @ViewChild('listView') listView: ElementRef;
    @ViewChild('listGroup') listGroup: ElementRef;

    ngOnInit(): void {
        this.currentPageIndex = this.poolService.currentPage;
        this.onInitEvent();
        this.isTeacherLayout();
    }

    ngAfterViewInit(): void {
        this.participantVideoBoxObserver.observe(this.participantVideoBox.nativeElement);
        this.adminVideoBoxObserver.observe(this.adminVideoBox.nativeElement);
        if (this.isSiteTeacher) {
            this.listViewObserver.observe(this.listView.nativeElement);
            this.listGroupObserver.observe(this.listGroup.nativeElement);
        }
    }

    ngOnDestroy(): void {
        for (const subs of this.subscriptions) {
            subs.unsubscribe();
        }
        this.adminVideoBoxObserver.disconnect();
        this.participantVideoBoxObserver.disconnect();
        if (this.isSiteTeacher) {
            this.listViewObserver.disconnect();
            this.listGroupObserver.disconnect();
        }
    }

    onInitEvent(): void {
        this.subscriptions.push(this.breakRoomService.onTeacherID()
            .subscribe((teacherID: string) => {
            this.teacherID = teacherID;
            this.isTeacherLayout();
        }));

        this.subscriptions.push(this.breakRoomService.onTeacherChangeGroupView()
            .subscribe((groupName: string) => {
            if (groupName) { this.myGroupId = groupName; }
        }));

        this.subscriptions.push(this.poolService.onUpdateBreakroom.asObservable()
            .subscribe((flag: boolean) => {
                if (flag) { this.updateListRemoteParticipant(); }
            })
        );

        this.subscriptions.push(this.breakRoomService.onBreakRoomData().subscribe((breakRoomData: BreakroomData) => {
            if (breakRoomData && breakRoomData.groupMap) {
                this.myGroupId = breakRoomData.myGroupId;
                this.listGroups = Object.keys(breakRoomData.groupMap);
            }
        }));
        // @ts-ignore
        this.participantVideoBoxObserver = new ResizeObserver((entries: any) => {
            this.boxSize = {width: entries[0].contentRect.width, height: entries[0].contentRect.height};
            this.updateVideoGrid();
        });

        // @ts-ignore
        this.adminVideoBoxObserver = new ResizeObserver((entries: any) => {
            const { width, height } = entries[0].contentRect;
            this.adminVideoBoxWidth = this.calcOneVideoSize(width, height - 29) * 100;
            this.cdRef.detectChanges();
        });

        if (this.isSiteTeacher) {
            // @ts-ignore
            this.listGroupObserver = new ResizeObserver((entries: any) => {
                this.listViewHeight = this.listView.nativeElement.clientHeight;
                this.listGroupHeight = entries[0].contentRect.height;
                const isScrollbarShown = this.listViewHeight < this.listGroupHeight;
                this.triggerScrollbar(isScrollbarShown);
            });
            // @ts-ignore
            this.listViewObserver = new ResizeObserver((entries: any) => {
                this.listViewHeight = entries[0].contentRect.height;
                const isScrollbarShown = this.listViewHeight < this.listGroupHeight;
                this.triggerScrollbar(isScrollbarShown);
            });
        }

    }

    isTeacherLayout(): void{
        this.meID =  this.localParticipantService.getPeerId();
        this.isSiteTeacher = this.meID === this.teacherID;
    }

    getGroupName(group: string): string {
        return group === 'noGroup' ? 'Chưa có nhóm' : `Nhóm ${group}`;
    }

    joinGroupBreakroom(groupName: string): void {
        if (this.myGroupId !== groupName) {
            this.myGroupId = groupName;
            this.breakRoomService.teacherChangeGroupView(groupName);
            this.breakRoomService.doChangeTeacherSpeakingInGroup(groupName);
        }
    }

    updateListRemoteParticipant(): void {
        const remoteService = this.poolService;
        this.currentPageIndex = remoteService.getBreakroomCurrentPage();//getBreakroomCurrentPage();
        this.listRemoteParticipant = remoteService.getListFullBreakroomParticipant();//getListBreakroomParticipant();
        this.startListWebcamConsumer(this.listRemoteParticipant);
        this.updateVideoGrid();
        this.cdRef.detectChanges();
    }

    updateVideoGrid(): void {
        const newGridCol = this.calcVideoBoxSize();

        if (this.videoColumnSize !== newGridCol) {
            this.videoColumnSize = newGridCol * 100;
            this.cdRef.detectChanges();
        }
    }

    stopListWebcamConsumer(remotes: RemoteParticipant[]): void {
        for (const remote of remotes) {
            remote.stopWebcam();
        }
    }

    startListWebcamConsumer(remotes: RemoteParticipant[]): void {
        for (const remote of remotes) {
            remote.startWebcam();
        }
    }

    fillVideoInOneRow({minCol, maxCol}, numVideoInOneRow: number): number {
        let {height} = this.boxSize;
        const incRemote = this.isSiteTeacher ? 0 : 1;
        const totalVideo = this.listRemoteParticipant.length + incRemote;
        const rows = Math.ceil(totalVideo * 1.0 / numVideoInOneRow);
        height -= rows * 16;

        const minHeight = this.calcVideoHeight(minCol, rows);
        const maxHeight = this.calcVideoHeight(maxCol, rows);

        if (maxHeight <= height) { return maxCol; }
        else if (minHeight <= height) { return this.predictCol(height, rows); }
        else { return 0; }
    }

    calcVideoHeight(col: number, rows: number): number {
        const {width} = this.boxSize;
        const videoWidth = width * col;
        return videoWidth * RATIO_HEIGHT * rows / RATIO_WIDTH;
    }

    predictCol(height: number, rows: number): number {
        const {width} = this.boxSize;
        const calcWidth = (height * RATIO_WIDTH * 1.0) / (RATIO_HEIGHT * rows);
        return Math.floor(calcWidth * 100 / width) / 100;
    }

    calcVideoBoxSize(): number {
        for (const [index, colCondition] of COL_ONE_ROW.entries()) {
            const preCol = this.fillVideoInOneRow({
                minCol: colCondition.minCol,
                maxCol: colCondition.maxCol
            }, index + 1);
            if (preCol) {
                return preCol;
            }
        }
        return 0;
    }

    calcOneVideoSize(containerWidth?: number, containerHeight?: number): number {
        const {width, height} = containerWidth && containerHeight ?
                                { width: containerWidth, height: containerHeight } : this.boxSize;
        const ratioHeightTransform = width * RATIO_HEIGHT * 1.0 / RATIO_WIDTH;
        const ratioWidthTransform = height * RATIO_WIDTH * 1.0 / RATIO_HEIGHT;
        return ratioHeightTransform > height ?
            Math.round(ratioWidthTransform * 100 / width) / 100 :
            1;
    }

    triggerScrollbar(isShown: boolean): void {
        const listView = this.listView.nativeElement.classList;
        const listGroup = this.listGroup.nativeElement.classList;
        if (isShown) {
            listView.remove('hide-scrollbar');
            listGroup.remove('center-items');
        } else {
            listView.add('hide-scrollbar');
            listGroup.add('center-items');
        }
    }

    getTotalPageIndex(): number {
        return this.poolService.getBreakroomTotalPage();
    }

    nextPage(): void {
        this.poolService.setBreakroomCurrentPage(++this.currentPageIndex);
        this.pagination();
    }

    previousPage(): void {
        this.poolService.setBreakroomCurrentPage(--this.currentPageIndex);
        this.pagination();
    }

    pagination(): void {
        // TODO: change order of start/stop, should be start first and stop later for queue reprocessing
        // Hint: save old list current with new list avoid conflict common list.
        this.stopListWebcamConsumer(this.listRemoteParticipant);
        this.updateListRemoteParticipant();
        this.cdRef.detectChanges();
    }
}
