import {Component, OnInit} from '@angular/core';
import {MediasoupService, RolesManagerService} from '../../../services';

@Component({
    selector: 'lib-quiz',
    templateUrl: './quiz.component.html',
    styleUrls: ['./quiz.component.less']
})
export class QuizComponent implements OnInit {

    hasRoleAdmin = false;
    radioValue = 'A';
    isShowOptions: boolean;

    constructor(
        private mediasoupService: MediasoupService,
        private roleManagerService: RolesManagerService
    ) { }

    ngOnInit(): void {
        this.onInitEvent();
    }

    onInitEvent(): void {
        this.roleManagerService.onUserRoles().subscribe((userRoles) => {
            this.hasRoleAdmin = this.mediasoupService.hasRole('admin');
        });
    }
}
