import {Action} from '@ngrx/store';

export const ADD_FILE = 'add file';
export const ADD_FILE_HISTORY = 'add file history';
export const SET_FILE_ACTIVE = 'set file active';
export const SET_FILE_INACTIVE = 'set file inactive';
export const SET_FILE_PROGRESS = 'set file progress';
export const SET_FILE_DONE = 'set file done';
export const CLEAR_FILES = 'clear file';

export class AddFile implements Action{
    readonly type = ADD_FILE;
    public payload: any;
    constructor(file) {
        this.payload = {file};
    }

}

export class AddFileHistory implements Action{
    readonly type = ADD_FILE_HISTORY;
    public payload: any;
    constructor(fileHistory) {
        this.payload = {fileHistory};
    }

}

export class SetFileActive implements Action{
    readonly type = SET_FILE_ACTIVE;
    public payload: any;
    constructor(magnetUri) {
        this.payload = {magnetUri};
    }

}

export class SetFileInActive implements Action{
    readonly type = SET_FILE_INACTIVE;
    public payload: any;
    constructor(magnetUri) {
        this.payload = {magnetUri};
    }

}

export class SetFileProgress implements Action{
    readonly type = SET_FILE_PROGRESS;
    public payload: any;
    constructor(magnetUri, progress) {
        this.payload = {magnetUri, progress};
    }

}

export class SetFileDone implements Action{
    readonly type = SET_FILE_DONE;
    public payload: any;
    constructor(magnetUri, sharedFiles) {
        this.payload = {magnetUri, sharedFiles};
    }

}

export class ClearFiles implements Action{
    readonly type = CLEAR_FILES;
    public payload: any;
    constructor() {}

}

export type All =
    AddFile |
    AddFileHistory |
    SetFileActive |
    SetFileInActive |
    SetFileProgress |
    SetFileDone |
    ClearFiles;
