<div class="video-stream cringi" #streamSector [ngClass]="{'speaking': isSpeaking}">
    <!-- HIDDEN FEATURE: (BREAKOUT ROOM with SHARE SCREEN) -->
    <!-- Hide video and screen share swapping method  -->
    <!-- -----------------------------  -->
    <div #acronym [style]="{'background' :  participant.color}" class="acronym">
      <svg viewBox="0 0 40 40"  width="100%">
        <text x="50%" y="53%" dominant-baseline="middle" text-anchor="middle" fill= "white"
        stroke= "white">{{nameAcronym}}</text>
      </svg>
  </div>
    <video [style]="{'z-index' : participant.webcam.state ? '2' : '1'}" class="video-screen"
           [@fade]="participant.webcam.state ? 'appear': 'disappear'"
           [ngClass]="{ 'full-height': !followedByWidth, 'full-width': followedByWidth }"
           #localVideoRef autoplay playsinline>
          
    </video>
    <div  [ngClass]="{ 'd-none' : !(isHoldSpaceTitle && isFullScreen)}"  class="hold-space">
        <span>
            {{ "hold_space1" | vI18n:{}:"Bạn đang tắt mic" }}
        </span>
        <br>
        <span>
            {{ "hold_space2" | vI18n:{}:"giữ phím cách để nói chuyện" }}
        </span>
    </div>
    <!-- HIDDEN FEATURE: (BREAKOUT ROOM with SHARE SCREEN) -->
    <!-- Hide video and screen share swapping method  -->
    <!-- -----------------------------  -->

    <div class="left-icon-holder">
      <!-- <button vnpt-button vType="default" class="spotlight-box" [hidden]="!isShowSpotlight()" (click)="toggleSpotLight()"
              [ngClass]="'on-video'">
          <i class="spotlight-icon" vnpt-icon [vType]="participant.isActivatingSpeaker && spotLight ? 'PinnedSolid' : 'Pinned'"></i>
      </button> -->
      <!-- [vSize]="miniView ? 'sm' : 'xl'" -->
      <div class="raised-hand" [hidden]="!participant.isRaisedHand">
          <i class="icon-gomeet-raise-hand raise-icon" style="font-size: {{miniView ? '22px' : '52px'}};"></i>
      </div>
    </div>

    <button vnpt-button vType="default"  class="more-btn" 
            vnpt-dropdown [vDropdownMenu]="moreMenu" vTrigger="click" 
            [ngClass]="{ 'd-none' : isFullScreen, 'on-video': participant.webcam.state }"
            [vPlacement]="'bottomRight'" [(vVisible)]="dropdownVisible"
            vnpt-tooltip vTooltipTitle='{{ "morebutton.more" | vI18n:{}:"Đổi tên" }}'>
        <i vnpt-icon vType="MoreVertical" class="icon-white"></i>
    </button>
    <button vnpt-button vType="default" class="more-btn" 
            vTrigger="click" (click)="closeFullScreen()"
            [ngClass]="{ 'd-none' : !isFullScreen, 'on-video': participant.webcam.state}"
            title='{{ "close" | vI18n:{}:"close" }}'>
        <i vnpt-icon vType="Cancel" class="icon-white"></i>
    </button>

    <div vnpt-row vAlign="middle" class="participant-info">
      <div vnpt-row vJustify="center" vAlign="middle" class="info-wrapper">
        <div *ngIf="participant.isPin" class="icon-container">
          <i class="icon-gomeet-pin icon-primary iconPin" style="font-size: 16px;"></i>
        </div>
        <i vnpt-col vnpt-icon *ngIf="isJoinedAudio" 
            [ngClass]="participant.audio.state ? 'icon-gomeet-state-mic icon-success': 'icon-gomeet-state-micoff icon-error'"></i>
        <span *ngIf="!isTeacher" vnpt-col class="participant-name" title="{{participant.displayName}}" >{{ "me" | vI18n:{name: participant.displayName}:"Tôi" }}</span>
        <!-- <span *ngIf="isTeacher" vnpt-col class="participant-name" title="{{participant.displayName}}">{{participant.displayName}} (Giáo viên)</span> -->
      </div>
      <div *ngIf="score !== 10 && participant.webcam.state" class="info-wrapper">
        <i vnpt-icon vType="BarChart4" [class]="coreColor"></i>
      </div>
      <div *ngIf="(micIconVisible && isFullScreen)" vnpt-row vAlign="middle" vAlign="middle" class="participant-info2" [ngClass]="{micLocal: true}">
          <i  class="micIcon" [ngClass]="micStatus ? 'icon-gomeet-state-micoff' : 'icon-gomeet-state-mic'"></i>
      </div>
      <div [hidden]="!participant.screen.state" class=" icon-container info-wrapper" style="padding: 0px;">
        <a vnpt-button vType="text" (click)="expandShare()">
          <i class="icon-gomeet-sharescreen icon-success"></i>
        </a>
      </div>
    </div>

    
    <!-- <div [hidden]="!participant.screen.state" class="right-overlay icon-container" [style]="{ 'height': wrapperHeight }">
      <video #sharingScreenRef autoplay playsinline muted
             [ngClass]="{ 'full-height': !followedByWidth, 'full-width': followedByWidth }"
             (click)="expandShare()"></video>
    </div> -->
</div>

<vnpt-dropdown-menu #moreMenu>
    <ul vnpt-menu>
      <li vnpt-menu-item *ngIf="hasPermissionModerator && forceLayoutStatus && !isWebcamsOnlyForModerator" (click)="pinCameraHandler()">
          <i [ngClass]="participant.isPin ? 'icon-gomeet-pin' : 'icon-gomeet-pin'"></i>
          {{participant.isPin ? ('unpin' | vI18n:{}:'Bỏ ghim') : ('pin' | vI18n:{}:'Ghim')}}</li>
      <li vnpt-menu-item (click)="changeDisplayName()">
        <i vnpt-icon vType="Rename" [vSize]="'sm'"></i>
        <span>{{ "moremenu.changeName" | vI18n:{}:"Đổi tên" }}</span>
      </li>

      <li vnpt-menu-item *ngIf="!isBlockAllAudio" (click)="toggleMic()">
        <i vnpt-icon [vType]="participant.audio.state ? 'MicOff2' : 'Microphone'" [vSize]="'sm'"></i>
        <span [vI18n]='!participant.audio.state ? "moremenu.onMic" : "moremenu.offMic"'></span>
      </li>
  
      <li vnpt-menu-item *ngIf="!isBlockAllWebcam" (click)="toggleVideo()">
        <i vnpt-icon class="icon" [vType]="participant.webcam.state ? 'VideoOff2' : 'Video'" [vSize]="'sm'"></i>
        <span [vI18n]='!participant.webcam.state ? "moremenu.onVideo" : "moremenu.offVideo"'></span>
      </li>

      <li *ngIf="participant.webcam.state" vnpt-menu-item (click)="expandVideo()">
        <i vnpt-icon vType="FullView" [vSize]="'sm'"></i>
        <span>{{ "moremenu.fullscreen" | vI18n:{}:"Xem toàn màn hình" }}</span>
      </li>
    </ul>
  </vnpt-dropdown-menu>
