<vnpt-modal [(vVisible)]="isVisible" [vTitle]="joinTitle" class="joinForm" 
  [vStyle]="joinStyle" [vBodyStyle]="joinBodyStyle" [vFooter]="null" vClosable="false" vMaskClosable="false" vKeyboard="false">
  <div *vModalContent ngClass="joinContent">
      <div style="height: 100%;">
      <div vnpt-row [vGutter]="8" ngClass="left-content">
        <div style="border: 0px">
          <button [disabled]="isDisabledBtnJoin" id="join-audio-btn" vnpt-button vType="primary" (click)="handleJoinAudio()" [vLoading]="isConfirmLoading" 
          [ngClass]="'jButton'">
            {{ joinButtonTitle }}
          </button>
          <audio #audioStartRef src="assets/audio/start.mp3"></audio>
        </div>
        <div style="border: 0px">
          <a vnpt-button vType="text" (click)="settingAudio()" [ngClass]="testJoinAudio">
            {{ checkAudioTitle }}
          <i vnpt-icon class="icon" [vType]="isSetting ? 'ChevronLeft' : 'ChevronRight'"></i>
          </a>
        </div>      
      </div>
      </div>
      <div *ngIf="isSetting" style="margin-left: 2em">
              <div>
              <div>
            <div vnpt-row [vGutter]="12" ngClass="rowLabel">
              <label style="align-self: center;">Microphone</label>
              <label style="text-align: end">
                {{!labelMic ? ('settings.devices.default' | vI18n:{}: 'Mặc định hệ thống') : labelMic.label}}
                <a  vnpt-button vnpt-popover vPopoverTrigger="click" [vPopoverContent]="audioDeviceTemplates" [style.border]="'0px'">
                  <i vnpt-icon class="icon" [vType]="isDeviceMicro ? 'ChevronUp' : 'ChevronDown'" ngClass="iconCustom">
                  </i>
                </a>
              </label>
            </div>
            <div vnpt-row [vGutter]="8">
              <div  vnpt-col [vSpan]="24"  >
                <div class="slider-container">
                  <div class="bar-container">
                    <div class="bar-5" [style.width]="changeWidth"></div>
                  </div>
                  <audio id ="remic" #micCheckRef autoplay></audio>
                </div>
              </div>
            </div>
            <div vnpt-row [vGutter]="8" ngClass="rowButton">
                <button (click)="toggleTestMicLocal()" vnpt-button vType="primary" [vSize]="size" vShape="circle" name="s"  [disabled]="micLock" >
                  <i vnpt-icon  [vType]="isMicPlayed ? 'Pause' : 'Play'"></i>
                </button>
                <span style="margin-left: 30px">{{ 'settings.mic.test.label' | vI18n:{}:"Kiểm tra mic" }}</span>
            </div>
          </div>
        </div>      
        <div style="display: block; margin-top: 3em;">
          <div class="padAll" ngClass="drap2" >
            <div vnpt-row [vGutter]="12" ngClass="rowLabel">
              <label style="align-self: center;">Speaker</label>
                <label style="text-align: end">
                  {{!labelSpeaker ? ('settings.devices.default' | vI18n:{}: 'Mặc định hệ thống') : labelSpeaker.label}}
                  <a vnpt-button vnpt-popover vPopoverTrigger="click" [vPopoverContent]="audioDeviceTemplate2" [style.border]="'0px'" >
                    <i vnpt-icon class="icon" [vType]="'ChevronDown'" ngClass="iconCustom" ></i>
                  </a>
                </label>
            </div>
            <div vnpt-row [vGutter]="8">
              <div vnpt-col [vSpan]="24" style="margin-top: 10px">
                <vnpt-slider [vMarks]="marksHGutter" [vStep]="null" [vMin]="0" [vMax]="10" (vOnAfterChange)="changeSetting()" [(ngModel)]="volumeAudio"></vnpt-slider>
              </div>
            </div>
            <div vnpt-row [vGutter]="8" ngClass="rowButton">
                <button (click)="toggleTestAudio()" vnpt-button vType="primary" [vSize]="size" vShape="circle" name="s" >
                  <i vnpt-icon  [vType]="isAudioPlayed ? 'Pause' : 'Play'"></i>
                </button>
                <span style="margin-left: 30px">{{ 'settings.audio.test.label' | vI18n:{}:"Kiểm tra audio" }}</span>
            </div>
          </div>
        </div>
        <audio #audioRef class="audio-example" src="assets/audio/speaker-test.wav"
        ></audio>
      </div>
  </div>
</vnpt-modal>
<ng-template #audioDeviceTemplates>
  <lib-audio-device ></lib-audio-device>
</ng-template>
<ng-template #audioDeviceTemplate2>
  <lib-audio-device [childMessage]="true"></lib-audio-device>
</ng-template>
