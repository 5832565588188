/* tslint:disable:no-console */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ParticipantModel } from '../models/participant.model';
import { SpeakerModel } from '../models/speaker.model';


@Injectable({
    providedIn: 'root'
})
export class SpeakingService {
    private _speakingList: SpeakerModel[] = [];
    private _checkSpeakerInterval: ReturnType<typeof setInterval>;
    private _activeSpeakerSubject = new BehaviorSubject<SpeakerModel[]>([]);
    
    constructor() {
    }

    get activeSpeakerList(): SpeakerModel[] {
        return this._speakingList;
    }

    get activeSpeakerObserver(): Observable<SpeakerModel[]> {
        return this._activeSpeakerSubject.asObservable();
    }

    removeFromSpeakingList(peerId: string): void {
        let index = this._speakingList.findIndex(p => p.id == peerId);
        this._speakingList.splice(index, 1);
        this._activeSpeakerSubject.next(this._speakingList);
    }

    pushToSpeakingList(peer: ParticipantModel) {
        // console.log("pushToSpeakingList", peer)
        let index = this._speakingList.findIndex(p => p.id == peer.id);
        if (index != -1) {
        this._speakingList[index].activeTime = Date.now();
        } else {
        if (this._speakingList.length >= 3) {
            let index = this._speakingList.reduce((a, c, i) => {
            if (c.activeTime < a.activeTime) a = { activeTime: c.activeTime, index: i };
            return a;
            }, {activeTime: Number.MAX_SAFE_INTEGER, index: 0}).index;
            this._speakingList.splice(index, 1);
        }
        this._speakingList.push({id: peer.id, displayName: peer.displayName, activeTime: Date.now(), color: peer.color, opacity: 1});
        // console.log(">>> pushToList", this.speakingList, this.checkSpeakerInterval);
        clearInterval(this._checkSpeakerInterval);
        this._checkSpeakerInterval = null;
        }
        
        if (!this._checkSpeakerInterval) {
        this._checkSpeakerInterval = setInterval(this.validateSpeakerList.bind(this), 500);
        }
    }

    updateSpeakerList(peer: ParticipantModel){
        // console.log("pushToSpeakingList", peer)
        let index = this._speakingList.findIndex(p => p.id == peer.id);
        if(index == -1) return;
        this._speakingList[index].displayName = peer.displayName;
        // this._activeSpeakerSubject.next(this._speakingList);
    }
    
    validateSpeakerList(): void {
        this._speakingList.forEach((item, index) => {
            let time = new Date(item.activeTime);
            time.setSeconds(time.getSeconds() + 6);
            if (time < new Date()) {
                this._speakingList.splice(index, 1);
            }else {
                time = new Date(item.activeTime);
                time.setSeconds(time.getSeconds() + 3)
                if(time < new Date()) item.opacity = 0.6;
                else item.opacity = 1;
            }
        });
        // console.log("@@@ validateSpeakerList", this.speakingList);

        if (this._speakingList.length == 0) {
            clearInterval(this._checkSpeakerInterval);
            this._checkSpeakerInterval = null;
        }
        this._activeSpeakerSubject.next(this._speakingList);
    }
}
