import {Action} from '@ngrx/store';

export const SET_ME = 'set me';
export const SET_BROWSER = 'set browser';
export const LOGGED_IN = 'logged in';
export const ADD_ROLE = 'add role';
export const REMOVE_ROLE = 'remove role';
export const SET_PICTURE = 'set picture';
export const SET_MEDIA_CAPABILITIES = 'set media capabilities';
export const SET_AUDIO_DEVICES = 'set audio devices';
export const SET_AUDIO_OUTPUT_DEVICES = 'set audio output devices';
export const SET_WEBCAM_DEVICES = 'set webcam devices';
export const SET_RAISED_HAND = 'set raised hand';
export const SET_AUDIO_IN_PROGRESS = 'set audio in progress';
export const SET_AUDIO_OUTPUT_IN_PROGRESS = 'set audio output in progress';
export const SET_WEBCAM_IN_PROGRESS = 'set webcam in progress';
export const SET_SCREEN_SHARE_IN_PROGRESS = 'set screen share in progress';
export const SET_RAISED_HAND_IN_PROGRESS = 'set raised hand in progress';
export const SET_DISPLAY_NAME_IN_PROGRESS = 'set display name in progress';
export const SET_IS_SPEAKING = 'set is speaking';
export const SET_AUTO_MUTED = 'set auto muted';
export const SET_LOCAL_RECORDING_STATE = 'set local recording state';

export class SetMe implements Action{
    readonly type = SET_ME;
    public payload: any;
    constructor( peerId, loginEnabled ) {
        this.payload = { peerId, loginEnabled };
    }
}

export class SetBrowser implements Action{
    readonly type = SET_BROWSER;
    public payload: any;
    constructor( browser ) {
        this.payload = { browser };
    }
}

export class LoggedIn implements Action{
    readonly type = LOGGED_IN;
    public payload: any;
    constructor( flag ) {
        this.payload = { flag };
    }
}

export class AddRole implements Action{
    readonly type = ADD_ROLE;
    public payload: any;
    constructor( roleId ) {
        this.payload = { roleId };
    }
}

export class RemoveRole implements Action{
    readonly type = REMOVE_ROLE;
    public payload: any;
    constructor( roleId ) {
        this.payload = { roleId };
    }
}

export class SetPicture implements Action{
    readonly type = SET_PICTURE;
    public payload: any;
    constructor( picture ) {
        this.payload = { picture };
    }
}

export class SetMediaCapabilities implements Action{
    readonly type = SET_MEDIA_CAPABILITIES;
    public payload: any;
    constructor(
        {
            canSendMic,
            canSendWebcam,
            canShareScreen,
            canShareFiles
        }
    ) {
        this.payload = {
            canSendMic,
            canSendWebcam,
            canShareScreen,
            canShareFiles
        };
    }
}

export class SetAudioDevices implements Action{
    readonly type = SET_AUDIO_DEVICES;
    public payload: any;
    constructor( devices ) {
        this.payload = { devices };
    }
}

export class SetAudioOutputDevices implements Action{
    readonly type = SET_AUDIO_OUTPUT_DEVICES;
    public payload: any;
    constructor( devices ) {
        this.payload = { devices };
    }
}

export class SetWebcamDevices implements Action{
    readonly type = SET_WEBCAM_DEVICES;
    public payload: any;
    constructor( devices ) {
        this.payload = { devices };
    }
}

export class SetRaisedHand implements Action{
    readonly type = SET_RAISED_HAND;
    public payload: any;
    constructor( flag ) {
        this.payload = { flag };
    }
}

export class SetAudioInProgress implements Action{
    readonly type = SET_AUDIO_IN_PROGRESS;
    public payload: any;
    constructor( flag ) {
        this.payload = { flag };
    }
}

export class SetAudioOutputInProgress implements Action{
    readonly type = SET_AUDIO_OUTPUT_IN_PROGRESS;
    public payload: any;
    constructor( flag ) {
        this.payload = { flag };
    }
}

export class SetWebcamInProgress implements Action{
    readonly type = SET_WEBCAM_IN_PROGRESS;
    public payload: any;
    constructor( flag ) {
        this.payload = { flag };
    }
}

export class SetScreenShareInProgress implements Action{
    readonly type = SET_SCREEN_SHARE_IN_PROGRESS;
    public payload: any;
    constructor( flag ) {
        this.payload = { flag };
    }
}

export class SetRaisedHandInProgress implements Action{
    readonly type = SET_RAISED_HAND_IN_PROGRESS;
    public payload: any;
    constructor( flag ) {
        this.payload = { flag };
    }
}

export class SetDisplayNameInProgress implements Action{
    readonly type = SET_DISPLAY_NAME_IN_PROGRESS;
    public payload: any;
    constructor( flag ) {
        this.payload = { flag };
    }
}

export class SetIsSpeaking implements Action{
    readonly type = SET_IS_SPEAKING;
    public payload: any;
    constructor( flag ) {
        this.payload = { flag };
    }
}

export class SetAutoMuted implements Action{
    readonly type = SET_AUTO_MUTED;
    public payload: any;
    constructor( flag ) {
        this.payload = { flag };
    }
}

export class SetLocalRecordingState implements Action{
    readonly type = SET_LOCAL_RECORDING_STATE;
    public payload: any;
    constructor( localRecordingState ) {
        this.payload = { localRecordingState };
    }
}

export type All =
    SetMe |
    SetBrowser |
    LoggedIn |
    AddRole |
    RemoveRole |
    SetPicture |
    SetMediaCapabilities |
    SetAudioDevices |
    SetAudioOutputDevices |
    SetWebcamDevices |
    SetRaisedHand |
    SetAudioInProgress |
    SetAudioOutputInProgress |
    SetWebcamInProgress |
    SetScreenShareInProgress |
    SetRaisedHandInProgress |
    SetDisplayNameInProgress |
    SetIsSpeaking |
    SetAutoMuted |
    SetLocalRecordingState;
