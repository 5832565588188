import {Component, ElementRef, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {QuizService, MediasoupService, RoomService} from '../../../../services';
import {QuizModel, Examining, QuizStatus} from '../../../../models/api/quiz/quiz.model';
import {NotificationService} from '../../../../services/notification-service';
import {createFadeAnimation} from '../../../../animation';
import {Subscription} from 'rxjs';

const INIT_TIME = 1000 * 60 * 60 * 17;
const DEADLINE = INIT_TIME + 120 * 1000;
const COL_ESTIMATE = 500;

@Component({
    selector: 'lib-manager',
    templateUrl: './manager.component.html',
    styleUrls: ['./manager.component.less'],
    animations: [createFadeAnimation(300)]
})
export class ManagerComponent implements OnInit, AfterViewInit, OnDestroy {

    constructor(
        private quizService: QuizService,
        private roomService: RoomService,
        private mediasoupService: MediasoupService,
        private notificationService: NotificationService,
        private readonly changeDetector: ChangeDetectorRef
    ) {}

    isStartingButton: boolean[];
    isStoppingButton: boolean[];
    isExamining = false;
    deadline = DEADLINE;
    intervalDeadline: any;
    quizState = QuizStatus;
    startingQuizId = '';
    isQuizListLoading = true;
    quizHolderWidth = 8;
    quizHolderObserver: ResizeObserver;
    quizList: QuizModel[] = [];
    subscriptions: Subscription[] = [];

    @ViewChild('quizHolder', {static: true}) quizHolder: ElementRef;

    // tslint:disable-next-line:no-any
    compareFn = (o1: any, o2: any) => (o1 && o2 ? o1.value === o2.value : o1 === o2);


    ngOnInit(): void {
        this.onInitEvent();
        this.getListQuiz();
    }

    ngAfterViewInit(): void {
        this.quizHolderObserver.observe(this.quizHolder.nativeElement);
    }

    onInitEvent(): void{
        this.subscriptions.push(this.quizService.onExamining().subscribe((exam: Examining) => {
            this.startingQuizId = exam.quizId;
            this.isExamining = exam.quizStatus;
        }));

        // @ts-ignore
        this.quizHolderObserver = new ResizeObserver((entries: any) => {
            const { width } = entries[0].contentRect;
            this.quizHolderWidth = 100 / Math.ceil(width * 1.0 / COL_ESTIMATE);
            this.changeDetector.detectChanges();
        });
    }

    ngOnDestroy(): void {
        for (const subs of this.subscriptions) {
            subs.unsubscribe();
        }
        this.quizHolderObserver.disconnect();
    }

    getListQuiz(): void{
        this.subscriptions.push(this.quizService.getListQuiz().subscribe((listOfData: QuizModel[]) => {
            this.quizList = listOfData;
            this.isStartingButton = this.isStoppingButton = Array(listOfData.length).map(i => false);
            this.isQuizListLoading = false;
        }));
    }

    start(quizId: string): void {
        if (!this.isExamining) {
            const index = this.getIndexOfQuiz(quizId);
            this.isStartingButton[index] = true;
            const roomId = this.roomService.getRoomId();

            this.subscriptions.push(this.quizService.startQuiz(roomId, quizId).subscribe((success: boolean) => {
                this.isStartingButton[index] = false;
                this.isExamining = success;
                if (success) {
                    this.startingQuizId = quizId;
                    this.notificationService.success('Gửi bài tập thành công', '');
                    this.updateQuizStatus(quizId, 1);
                } else {
                    this.notificationService.error('Có lỗi khi gửi bài tập, vui lòng thử lại sau', '');
                }
            }));
        }
    }

    stop(quizId: string): void {
        if (this.isExamining) {
            const index = this.getIndexOfQuiz(quizId);
            this.isStoppingButton[index] = true;
            const roomId = this.roomService.getRoomId();

            this.subscriptions.push(this.quizService.stopQuiz(roomId, quizId).subscribe((success: boolean) => {
                this.isStoppingButton[index] = false;
                this.isExamining = false;
                if (success){
                    this.startingQuizId = '';
                    this.notificationService.success('Đã kết thúc bài tập', '');
                    this.updateQuizStatus(quizId, 0);
                    this.deadline = DEADLINE;
                } else{
                    this.notificationService.error('Có lỗi khi kết thúc bài tập, vui lòng thử lại sau', '');
                }
            }));
        }
    }

    getIndexOfQuiz(quizId: string): number {
        const quiz = this.quizList.find((q: QuizModel) => q._id === quizId);
        return this.quizList.indexOf(quiz);
    }

    updateQuizStatus(quizId: string, status: number): void {
        const quiz = this.quizList.find((q: QuizModel) => q._id === quizId);
        quiz.quizStatus = status;
    }
}
