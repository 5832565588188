import {Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {SecurityService} from '../../services/security.service';
import {MediasoupService} from '../../services/mediasoup.service';
import {Subscription} from 'rxjs';
import {permissions} from '../../permissions';
import {LocalParticipantService} from '../../services/local-participant.service';
import { SettingsService } from '../../services/settings.service';
import { VModalService } from '@vnpt/oneui-ui/modal';
import { NotificationService, RoomService } from '../../services';
import { OneuiI18nService } from '@vnpt/oneui-i18n';
import { LAYOUT_MODE } from '../../constants';

@Component({
  selector: 'lib-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.less']
})
export class SecurityComponent implements OnInit, OnDestroy {

  constructor(
      private mediasoupService: MediasoupService,
      private securityService: SecurityService,
      private localParticipantService: LocalParticipantService,
      private settingsService: SettingsService,
      private modalService: VModalService,
      private roomService: RoomService,
      private i18nService:OneuiI18nService,
      private notification: NotificationService
  ) { }
  // isMuteAll = false;
  // isStopAllWebcam = false;
  webcamUnblocked = true;
  audioUnblocked = true;
  // isStopAllScreenSharing = false;
  // isCloseAll = false;
  hasPermissionModerator = false;
  whiteboardLocked = true;
  isLock = false;
  //tilteLock = "Khóa phòng họp";
  subscriptions: Subscription[] = [];
  grantAllPeerScreenSharing = false;
  isWhiteboardOn: boolean = false;
  webcamsOnlyForModerator: boolean = false;
  checkLockRoomEvent =  false;
  checkBlockVideoEvent = false;
  checkBlockAudioEvent = false;
  checkBlockShareScreenEvent = false;
  checkWebcamForModeratorEvent = false;
  checkmuteAllExceptPresenterEvent = false;
  checkstopAllWebcamEvent = false;
  checkstopAllScreenSharingEvent = false;
  checkmuteAll = false;
  @Input() layoutMode: LAYOUT_MODE = 'gallery';
  @Output() triggerWhiteboardMode = new EventEmitter<LAYOUT_MODE>();
  @Output() pooper = new EventEmitter<any>();
  ngOnInit(): void {
    this.onInitEvent();
  }

  onInitEvent(): void{
    this.localParticipantService.onRoles().subscribe((roles: number[]) => {
      this.hasPermissionModerator = this.roomService.hasPermission(permissions.MODERATE_ROOM);
    });

    // this.subscriptions.push(this.securityService.onMuteAll().subscribe((isMuteAll: boolean) => {
    //   this.isMuteAll = isMuteAll;
    // }));

    // this.subscriptions.push(this.securityService.onStopAllPeerVideo().subscribe((isStopAllWebcam: boolean) => {
    //   this.isStopAllWebcam = isStopAllWebcam;
    // }));

    this.subscriptions.push(this.settingsService.onVideoUnblocked().subscribe((webcamUnblocked: boolean) => {
      this.webcamUnblocked = webcamUnblocked;
    }));

    this.subscriptions.push(this.settingsService.onAudioUnblocked().subscribe((audioUnblocked: boolean) => {
      this.audioUnblocked = audioUnblocked;
    }));

    // this.subscriptions.push(this.securityService.onStopAllPeerScreenSharing().subscribe((isStopAllScreenSharing: boolean) => {
    //   this.isStopAllScreenSharing = isStopAllScreenSharing;
    // }));

    // this.subscriptions.push(this.securityService.onCloseMeeting().subscribe((isCloseAll: boolean) => {
    //   this.isCloseAll = isCloseAll;
    // }));

    this.subscriptions.push(this.roomService.onLockAllWhiteboard().subscribe((lock: boolean) => {
      this.whiteboardLocked = lock;
    }));

    this.subscriptions.push(this.securityService.onLockRoom().subscribe((isLock: boolean) => {
      //Bắt trạng thái khóa khi bấm nút khóa/mở phòng
      this.isLock = isLock;
    }));

    this.subscriptions.push(this.settingsService.onIsGrantcreenSharingPermissionSubject().subscribe((allow:boolean)=>{
      this.grantAllPeerScreenSharing = allow;
    }));

    this.subscriptions.push(this.roomService.onIsWhiteboard().subscribe((isWhiteboard: boolean) => {
      this.isWhiteboardOn = isWhiteboard;
    }));

    this.subscriptions.push(this.settingsService.onWebcamsOnlyForModerator().subscribe((flag: boolean) =>{
      this.webcamsOnlyForModerator = flag;
    }))

    this.subscriptions.push(this.securityService.onProcessingLockRoom().subscribe((flag:boolean) => {
      this.checkLockRoomEvent = flag;
    }));

    this.subscriptions.push(this.securityService.onProcessingBlockAllAudio().subscribe((flag:boolean) => {
      this.checkBlockAudioEvent = flag;
    }));

    this.subscriptions.push(this.securityService.onProcessingBlockAllVideo().subscribe((flag:boolean) => {
      this.checkBlockVideoEvent = flag;
    }));

    this.subscriptions.push(this.securityService.onProcessingBlockAllShareScreen().subscribe((flag:boolean) => {
      this.checkBlockShareScreenEvent = flag;
    }));

    this.subscriptions.push(this.securityService.onProcessingWebcamsForModerator().subscribe((flag:boolean) => {
      this.checkWebcamForModeratorEvent = flag;
    }));

    this.subscriptions.push(this.securityService.onProcessingMuteAllExceptPresenter().subscribe((flag:boolean) => {
      this.checkmuteAllExceptPresenterEvent = flag;
    }));

    this.subscriptions.push(this.securityService.onProcessingModeratorStopAllAudio().subscribe((flag:boolean) => {
      this.checkmuteAll = flag;
    }));

    this.subscriptions.push(this.securityService.onProcessingModeratorStopAllShareScreen().subscribe((flag:boolean) => {
      this.checkstopAllScreenSharingEvent = flag;
    }));

    this.subscriptions.push(this.securityService.onProcessingModeratorStopAllWebcam().subscribe((flag:boolean) => {
      this.checkstopAllWebcamEvent = flag;
    }));
  }

  ngOnDestroy(): void{
    for (const sub of this.subscriptions){
      sub.unsubscribe();
    }
  }

  muteAll(): void{
    this.mediasoupService.muteAllPeers().then((result) => {
      if (result) {
        this.notification.info(this.i18nService.translate("security.muteAll.success", {},
          "Tắt tất cả mic thành công"), "");
      } else {
        this.notification.info(this.i18nService.translate("security.muteAll.failed", {},
          "Tắt tất cả mic thất bại"), "");
      }
    });
    // this.pooper.emit();
  }

  muteAllExceptPresenter():void{
   this.mediasoupService.muteAllPeersExceptPresenter().then((result)=>{
     if (result) {
       this.notification.info(this.i18nService.translate("security.muteAllExceptPresenter.success", {},
         "Tắt mic trừ người đang trình bày thành công"), "");
     } else {
       this.notification.info(this.i18nService.translate("security.muteAllExceptPresenter.failed", {},
         "Tắt mic trừ người đang trình bày thất bại"), "");
     }

   });
  }

  stopAllWebcam(): void{
    this.mediasoupService.stopAllPeerVideo().then((result) => {
      if (result) {
        this.notification.info(this.i18nService.translate("security.stopAllWebcam.success", {},
          "Tắt tất cả video thành công"), "");
      } else {
        this.notification.info(this.i18nService.translate("security.stopAllWebcam.failed", {},
          "Tắt tất cả video thất bại"), "");
      }
    });
    // this.pooper.emit();
  }

  blockAllWebcam(): void{
    this.webcamUnblocked ? this.mediasoupService.blockAllPeerVideo(true)
    : this.mediasoupService.blockAllPeerVideo(false);
  }

  blockAllAudio(): void{
    this.audioUnblocked ? this.mediasoupService.blockAllPeerAudio(true)
    : this.mediasoupService.blockAllPeerAudio(false);
  }

  stopAllScreenSharing(): void{
    this.mediasoupService.stopAllPeerScreenSharing().then((result)=>{
      if(result){
        this.notification.info(this.i18nService.translate("security.stopAllScreenSharing.success",{},
        "Tắt chia sẻ màn hình thành công"), "");
      }else{
        this.notification.info(this.i18nService.translate("security.stopAllScreenSharing.failed",{},
        "Tắt chia sẻ màn hình thất bại"), "");
      }

     });
     
    // this.pooper.emit();
  }

  //Cap/thu quyen sharescreen cho tat ca user (tru moderator-admin)
  grantScreenSharingPerrmission(): void{
    this.grantAllPeerScreenSharing ? this.mediasoupService.grantAllPeerScreenSharing(false) : 
    this.mediasoupService.grantAllPeerScreenSharing(true);
  }
  webcamsForModerator():void {
    this.webcamsOnlyForModerator ? this.mediasoupService.webcamsForModerator(false) : 
    this.mediasoupService.webcamsForModerator(true)
  }

  closeMeeting(): void{
    this.modalService.confirm({
      vTitle: this.i18nService.translate("security.closeMeetingTitle",{},"Kết thúc cuộc họp"),
      vContent: this.i18nService.translate("security.closeMeetingContent",{},"Bạn có thực sự muốn đóng cuộc họp không?"),
      vOkText: this.i18nService.translate("security.closeMeetingOk",{},"Đồng ý"),
      vCancelText: this.i18nService.translate("security.closeMeetingCancel",{},"Hủy"),
      vOnOk: ()=> this.mediasoupService.closeMeeting(),
    });
  }

  handleOk(): void {
    this.mediasoupService.closeMeeting();
  }
  
  lockAllWhiteboard(){
    this.roomService.lockAllWhiteboard(!this.whiteboardLocked);
  }

  clockRoom(): void {
    if (this.isLock) {
        this.roomService.unLockRoom();
    }
    else {
        this.roomService.lockRoom();
    }
  }

  triggerWhiteboards(): void {
    // this.layoutMode = this.layoutMode === 'whiteboard' ? 'gallery' : 'whiteboard';
    this.layoutMode == 'whiteboard' ? this.roomService.stopWhiteboard() : this.roomService.startWhiteboard();
    // this.layoutMode == 'whiteboard' ? this.roomService.startWhiteboard() : this.roomService.stopWhiteboard();

  }

}
