import {ResponsesUserParticipatedModel} from './quiz.model';
import {BaseResponseApi} from '../base-response-api.model';

export interface CheckRequestApi{
    quizId: string;
    userId: string;
    clientRespones: ResponsesUserParticipatedModel[];
    timeStarted: string;
    timeEnded: string;
}

export class CheckResponseModel{
    quizId = '';
    marks = 0;
    totalQuesion = 0;
    timeStarted = '0';
    timeEnded  = '0';
}

export interface CheckResponseApi extends BaseResponseApi<CheckResponseModel>{}
