<vnpt-modal [(vVisible)]="isVisible"
            [vTitle]="'Phông nền ảo'"
            (vOnOk)="handleOk()"
            (vOnCancel)="handleCancel()"
            [vCancelText]="null"
            vOkText="Đóng">
    <ng-container *vModalContent>

        <vnpt-card *ngIf="!imgSrc" vHoverable class="no-image-card" (click)="fileInput.click()">
            <div>
                <i vnpt-icon vType="FileImage" class="no-image-icon"></i>
                <span class="no-image-title">Chọn phông nền ảo</span>
            </div>
        </vnpt-card>
        <input hidden #fileInput type="file" accept=".png,.jpeg,.jpg" (change)="fileChange($event)">

        <div *ngIf="imgSrc" style="position: relative;">
            <img width="486px" height="274px" 
                [src]="srcTrustUrl">
            <button vnpt-button vType="primary" vDanger class="delete-icon" (click)="resetImgSrc()">
                <i vnpt-icon vType="Delete"></i>
            </button>
        </div>
    </ng-container>
</vnpt-modal>

<vnpt-modal [vVisible]="previewVisible" [vContent]="modalContent" [vFooter]="null" (vOnCancel)="previewVisible = false">
    <ng-template #modalContent>
        <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
    </ng-template>
</vnpt-modal>
