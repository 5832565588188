import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  TemplateRef,
  Renderer2,
  OnDestroy
} from "@angular/core";
import { OneuiI18nService } from '@vnpt/oneui-i18n';
import {
  SettingsService,
  DeviceCountingService,
  AudioDeviceService,
  LocalParticipantService
} from "../../services";
import { NotificationService } from "../../services/notification-service";
import { RoomService } from "../../services";
import browser from "browser-detect";
import { VMarks } from "@vnpt/oneui-ui/slider";
import { AudioDeviceComponent } from "../audio-device/audio-device.component";
import { TimeService, timeService } from "../../services/time.service";
import { RecordClientService } from "../../services/record-client.service";
@Component({
  selector: "lib-join-audio",
  templateUrl: "./join-audio.component.html",
  styleUrls: ["./join-audio.component.less"],
})
export class JoinAudioComponent
  implements OnInit, OnDestroy {
  @ViewChild("audioRef") audioCheckRef: ElementRef;
  @ViewChild(AudioDeviceComponent) child;
  @ViewChild("test") test: ElementRef;
  @ViewChild('micCheckRef') micCheckRef: ElementRef;
  private timeLog: TimeService = timeService;
  isDisabledBtnJoin = false;
  isVisible = false;
  isSetting = false;
  micLock = false;
  joinStyle = {
    maxWidth: "100%",
    width: "100%",
    // height: "360px",
    top: "auto",
    bottom: "0",
    position: "fixed",
    textAlign: "center",
    fontSize: "0.5em",
    padding: 0,
    /* transform-origin: 427.2px -67.2px 0px; */
    margin: 0,
  };
  joinBodyStyle = {
    paddingTop: "0px",
    paddingBottom: "32px",
  };
  BROWSER_RESULTS = browser();
  isMobileBrowser =
    this.BROWSER_RESULTS.mobile || this.BROWSER_RESULTS.os.includes("Android");
  joinTitle: string;
  joinButtonTitle: string;
  checkAudioTitle: string;

  marksHGutter: VMarks = {
    "0": {
      style: {
        color: "white",
        bottom: "15px",
      },
      label: "0",
    },
    "1": {
      style: {
        color: "white",
        bottom: "15px",
      },
      label: "1",
    },
    "2": {
      style: {
        color: "white",
        bottom: "15px",
      },
      label: "2",
    },
    "3": {
      style: {
        color: "white",
        bottom: "15px",
      },
      label: "3",
    },
    "4": {
      style: {
        color: "white",
        bottom: "15px",
      },
      label: "4",
    },
    "5": {
      style: {
        color: "white",
        bottom: "15px",
      },
      label: "5",
    },

    "6": {
      style: {
        color: "white",
        bottom: "15px",
      },
      label: "6",
    },
    "7": {
      style: {
        color: "white",
        bottom: "15px",
      },
      label: "7",
    },
    "8": {
      style: {
        color: "white",
        bottom: "15px",
      },
      label: "8",
    },
    "9": {
      style: {
        color: "white",
        bottom: "15px",
      },
      label: "9",
    },
    "10": {
      style: {
        color: "white",
        bottom: "15px",
      },
      label: "10",
    },
  };
  volumeAudio = 10;
  onCheckMic = false;
  volumeCallback = null;
  volumeInterval = null;
  audioSource = null;
  audioStream = null;
  changeWidth = "0%";
  isDeviceMicro = false;
  isAudioPlayed = false;
  isMicPlayed = false;
  audio = null;
  micAudio = null;
  microDevice = null;
  speakerDevice = null;
  audioContext = null;
  analyser = null;
  volumes = null;
  labelMic = null;
  labelSpeaker = null;


  @ViewChild("audioStartRef") audioStartRef: ElementRef;

  @ViewChild("audioDeviceTemplate", { static: true })
  audioDeviceTemplate: TemplateRef<any> | null = null;
  constructor(
    private i18nService: OneuiI18nService,
    private settingsService: SettingsService,
    private notification: NotificationService,
    private roomService: RoomService,
    private deviceCountingService: DeviceCountingService,
    private audioDeviceService: AudioDeviceService,
    private localParticipantService : LocalParticipantService,
    private renderer: Renderer2,
    private recordClientService: RecordClientService
  ) {
    this.joinTitle = this.isMobileBrowser
      ? this.i18nService.translate('settings.audio.mobile', {}, "Âm thanh điện thoại")
      : this.i18nService.translate('settings.audio.desktop', {}, "Âm thanh máy tính");
    this.joinButtonTitle = this.isMobileBrowser
    ? this.i18nService.translate('settings.audio.join.mobile', {}, "Kết nối âm thanh điện thoại")
    : this.i18nService.translate('settings.audio.join.desktop', {}, "Kết nối âm thanh máy tính");
    this.checkAudioTitle = this.isMobileBrowser
    ? this.i18nService.translate('settings.mic.check.mobile', {}, "Kiểm tra Mic và loa điện thoại")
    : this.i18nService.translate('settings.mic.check.desktop', {}, "Kiểm tra Mic và loa máy tính");
  }

  ngOnInit(): void {
    this.settingsService.onJoinAudio().subscribe((isJoined: boolean) => {
      
      if (!isJoined) {
        this.isVisible = true;
      } else if (
        !this.settingsService.getIsAudioMuted() &&
        !this.settingsService.getIsAudioBlocked()
      ) {
        this.roomService.producerAudioStart();
      }
    });

    this.audioDeviceService.onMicDevice().subscribe((isChange: boolean)=> {
      this.labelMic = this.audioDeviceService.getMic();
    });

    this.audioDeviceService.onSpeakerDevice().subscribe((isChange: boolean) => {
      this.labelSpeaker = this.audioDeviceService.getSpeaker();
      if(this.speakerDevice != this.audioDeviceService.getSpeaker()?.deviceId) {
        this.speakerDevice = this.audioDeviceService.getSpeaker().deviceId;
          if (this.audio !== null) {
            this.listenAudio();      
          }
      }
    });

    this.localParticipantService.onIsRU().subscribe((flag: boolean) => {
      if (flag) {
        this.handleJoinAudio();
      }
    });
  }

  //Ko su dung docheck để check liên tục volume và speakerDevice => Chỉ thay đổi khi 
  //user chọn mức volume và chỉ cập nhật speakerDevice khi user chọn speaker device
  // ngDoCheck(): void {
  //   this.logger.log("ngDoCheck");
  //   if (this.audio !== null) {
  //     if (this.audio.volume !== undefined) {
  //       this.audio.volume = this.volumeAudio / 10;
  //     }
  //   }
  //   if (
  //     this.audioDeviceService.getSpeaker() &&
  //     this.speakerDevice !== this.audioDeviceService.getSpeaker().deviceId
  //   ) {
  //     this.speakerDevice = this.audioDeviceService.getSpeaker().deviceId;
  //     if (this.audio !== null) {
  //       this.listenAudio();      
  //     }
  //   }
  // }

  ngOnDestroy(): void {
    this.audio = null;
    this.micAudio = null;
    this.isAudioPlayed = false;
    this.isDeviceMicro = false;
    this.isMicPlayed = false;
    this.isVisible = false;
    this.onCheckMic = false;
    if (this.audioStream && this.audioStream.getAudioTracks()[0] !== undefined) {
      for (const sub of this.audioStream.getAudioTracks()) {
        sub.stop();
      }
    }
  }
  

  // handleCancel(): void {
  //   this.audio = null;
  //   this.isAudioPlayed = false;
  //   this.isMicPlayed = false;
  //   this.isVisible = false;
  //   this.onCheckMic = false;
  //   this.isSetting = false;
  //   this.stopCheckMicro();
  // }

  handleJoinAudio() {
    this.isDisabledBtnJoin = true;
    //Join audio mới lưu volume audio
    this.recordClientService.setupAudioContext();
    this.deviceCountingService.setVolumeAudio(this.volumeAudio);
    const audioStart = this.audioStartRef.nativeElement;

    this.renderer.listen(audioStart, 'canplay', () => {
      audioStart.play();
    });
    audioStart.load();

    this.roomService.joinAudio().then(() => {
      this.timeLog.setEndTime('joinAudio')
      this.settingsService.setJoinAudio(true);
      navigator.mediaDevices.getUserMedia({audio: true}).then(async(stream)=>{
        stream.getTracks().forEach(track=> {
          track.stop();
          })
      }).catch((reason) => {});
      this.isVisible = false;
      this.stopCheckMicro();
      this.settingsService.setJoinAudio(true);
    });
  }

  settingAudio(): void {
    if (this.isSetting) {
      this.stopCheckMicro();
      this.isSetting = false;
    } else {
      this.audio = null;
      this.isAudioPlayed = false;
      this.toggleTestMic();
      this.isSetting = true;
    }
    
  }

  toggleTestAudio(): void {
    if (!this.isAudioPlayed) {
      this.listenAudio();
      this.isAudioPlayed = true;
    } else {
      this.audio.pause();
      this.isAudioPlayed = false;
    }
  }

  toggleTestMicLocal(): void {
    if (!this.isMicPlayed) {
      this.listenMic();
      this.isMicPlayed = true;
    } else {
      this.micAudio.pause();
      this.isMicPlayed = false;
    }
  }

  microAnalysis(): void {
    this.audioContext = new AudioContext();
    this.audioSource = this.audioContext.createMediaStreamSource(
      this.audioStream
    );
    this.analyser = this.audioContext.createAnalyser();
    this.analyser.fftSize = 512;
    this.analyser.minDecibels = -127;
    this.analyser.maxDecibels = 0;
    this.analyser.smoothingTimeConstant = 0.4;
    this.audioSource.connect(this.analyser);
    this.volumes = new Uint8Array(this.analyser.frequencyBinCount);
  }

  async toggleTestMic() {
    // if (!this.onCheckMic) {
    try {
      if (this.audioDeviceService.getMic()) {
        var constraints = {
          deviceId: { exact: this.audioDeviceService.getMic().deviceId },
        };
        this.audioStream = await navigator.mediaDevices.getUserMedia({
          audio: constraints,
        });
      } else {
        this.audioStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
      }
      this.microAnalysis();
      this.volumeCallback = async () => {
        if (
          this.audioDeviceService.getMic() &&
          this.microDevice !== this.audioDeviceService.getMic()
        ) {
          var constraints = {
            deviceId: { exact: this.audioDeviceService.getMic().deviceId },
          };
          for (const tracks of this.audioStream.getAudioTracks()) {
            tracks.stop();
          }
          this.audioStream = await navigator.mediaDevices.getUserMedia({
            audio: constraints,
          });
          this.microDevice = this.audioDeviceService.getMic();
          this.microAnalysis();
          if (this.isMicPlayed) {
            this.listenMic();
          }
        }
        this.analyser.getByteFrequencyData(this.volumes);
        let volumeSum = 0;
        for (const volume of this.volumes) volumeSum += volume;
        const averageVolume = volumeSum / this.volumes.length;
        this.changeWidth = (averageVolume * 100) / 127 + "%";
      };
      this.onCheckMic = true;
      this.micLock = false;
      this.volumeInterval = setInterval(this.volumeCallback, 100);
    } catch (e) {
      console.error(
        "Failed to initialize volume visualizer, simulating instead...",
        e
      );
      this.micLock = true;
      let lastVolume = 50;
      this.volumeCallback = () => {
        const volume = Math.min(
          Math.max(Math.random() * 100, 0.8 * lastVolume),
          1.2 * lastVolume
        );
        lastVolume = volume;
        this.changeWidth = (volume * 100) / 127 + "%";
        console.log("Status: Microphone is not working!");
      };
    }

    // this.onCheckMic = true;
    // this.volumeInterval = setInterval(this.volumeCallback, 100);
    // } else {
    //   this.stopCheckMicro();
    // }
  }



  listenAudio(): void {
    this.audio = this.audioCheckRef.nativeElement;
    if (this.speakerDevice !== null) {
      this.audio.setSinkId(this.speakerDevice);
    }
    this.audio.volume = this.volumeAudio / 10;
    this.audio.muted = true;
    this.renderer.listen(this.audio, 'canplay', () => {
      const audioPlayed = this.audio.play();
      if (audioPlayed !== undefined) {
        audioPlayed
          .then(() => {
            this.audio.muted = false;
            this.audio.loop = true;
          })
          .catch((error) => {
            this.notiPermission(error);
          });
      }
    });
    this.audio.load();
  }

  stopCheckMicro(): void {
    clearInterval(this.volumeInterval);
    this.volumeInterval = null;
    this.onCheckMic = false;
    this.changeWidth = "0%";
    if (this.audioStream !== null) {
      if (this.audioStream.getAudioTracks()) {
        for (const sub of this.audioStream.getAudioTracks()) {
          sub.stop();
        }
      }
    }
  }

  listenMic(): void {
    this.micAudio = this.micCheckRef.nativeElement;
    if(this.micAudio.sinkId){
      if (this.speakerDevice !== null) {
        this.micAudio.setSinkId(this.speakerDevice);
      }
      else {
        this.micAudio.setSinkId('default');
      }
    }
   
    this.micAudio.srcObject = this.audioStream;
    
    this.micAudio.muted = true;
    this.micAudio.oncanplay = () => {
      const tplay = this.micAudio.play().catch(console.warn);
      if (tplay !== undefined) {
        tplay
          .then(() => {
            this.micAudio.muted = false;
          })
          .catch((error) => {
            this.notiPermission(error);
          });
      }
    };
    
  }

  // Chỉ cập nhật audio.volume khi test âm thanh
  changeSetting(): void {
    if (this.audio !== null) {
      if (this.audio.volume !== undefined) {
        this.audio.volume = this.volumeAudio / 10;
      }
    }
  }

  notiPermission(error) {
    if (error.name === "NotAllowedError") {
      this.notification.error(
        this.i18nService.translate('settings.permission.require', {}, "Bạn hãy cấp quyền Sound trên trình duyệt này!!!"),
        ""
      );
    }
  }
}
