export class TimeService {
  // 'join', 'joinAudio', 'produce','leaveRoom','moderator:closeMeeting'

  startTime: Date;
  endTime: Date;

  setStartTime(event: string) {
    var time = new Date();
    console.log("==============****==============");
    switch (event) {
      case "join":
        console.log("### Chức năng Tham gia phòng họp ####");
        break;
      case "joinAudio":
        console.log("### Chức năng Tham gia âm thanh phòng họp ####");
        break;
      case "produce-mic":
        console.log("### Chức năng Mở micro trong phòng họp ####");
        break;
      case "produce-camera":
        console.log("### Chức năng Mở Camera trong phòng họp ####");
        break;
      case "produce-screen":
        console.log("### Chức năng Chia sẻ trong phòng họp ####");
        break;
      case "leaveRoom":
        console.log("### Chức năng Rời khỏi phòng họp ####");
        break;
      case "moderator:closeMeeting":
        console.log("### Chức năng Kết thúc khỏi phòng họp ####");
        break;
    }

    console.log("Thời gian request: ", time.getDate()+ '/'+ (time.getMonth()+1)+'/'+time.getFullYear()+' '+ time.getHours()+':'+time.getMinutes()+':'+ time.getSeconds()+':'+time.getMilliseconds())
    this.startTime = time;
  }
  setEndTime(event: string) {
    if(!this.startTime){
      return;
    }
    var time = new Date();
    console.log("Thời gian phản hồi từ server:",  time.getDate()+ '/'+ (time.getMonth()+1)+'/'+time.getFullYear()+' '+ time.getHours()+':'+time.getMinutes()+':'+ time.getSeconds()+':'+time.getMilliseconds());
    this.endTime = time;
    console.log("Tổng thời gian thực hiện: " + this.getTiming());
  }
  getTiming(): string {
    const milliDiff: number = this.endTime.getTime() - this.startTime.getTime();
    var ms_Min = 60 * 1000; // milliseconds in Minute
    var ms_Hour = ms_Min * 60; // milliseconds in Hour
    var ms_Day = ms_Hour * 24; // milliseconds in day
    var ms_Mon = ms_Day * 30; // milliseconds in Month
    var ms_Yr = ms_Day * 365; // milliseconds in Year
    // If the diff is less then milliseconds in a minute
    if (milliDiff < ms_Min) {
      if (Math.round(milliDiff / 1000) > 0)
        return Math.round(milliDiff / 1000) + " seconds";
      else {
        return Math.round(milliDiff) + " milliseconds";
      }
      // If the diff is less then milliseconds in a Hour
    } else if (milliDiff < ms_Hour) {
      return Math.round(milliDiff / ms_Min) + " minutes";

      // If the diff is less then milliseconds in a day
    } else if (milliDiff < ms_Day) {
      return Math.round(milliDiff / ms_Hour) + " hours";

      // If the diff is less then milliseconds in a Month
    } else if (milliDiff < ms_Mon) {
      return "Around " + Math.round(milliDiff / ms_Day) + " days";

      // If the diff is less then milliseconds in a year
    } else if (milliDiff < ms_Yr) {
      return "Around " + Math.round(milliDiff / ms_Mon) + " months";
    } else {
      return "Around " + Math.round(milliDiff / ms_Yr) + " years";
    }
  }
}

const timeService = new TimeService();
export { timeService };
