import { Component, OnInit, OnDestroy} from '@angular/core';
import { QuickMenuModel } from 'commonLibrary';
import { OneuiI18nService } from '@vnpt/oneui-i18n';
import { Subscription } from 'rxjs';
import { config } from 'projects/realtime-communication/src/config';
// import { environment } from '../environments/environment';
import { SettingsService } from 'projects/realtime-communication/src/projects';

@Component({
  selector: 'mainapp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'main';
  private subscription: Subscription = Subscription.EMPTY;
  constructor(private i18nService:OneuiI18nService, private settingsService: SettingsService) {
    this.subscription = this.i18nService.langChanges$.subscribe(evt => {
    })
  }

  async ngOnInit() {
    // const { useOriginApiURL } = await this.settingsService.getAppConfig();
    // if (useOriginApiURL) environment.setEnvironment(location.origin);
    var locale = localStorage.getItem("locale") || "vi";
    this.i18nService.load(locale, config.i18n[locale].src);
  }
     
  ngOnDestroy(){
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }
}
