<div vnpt-row vJustify="space-between" vAlign="middle"
    [ngClass]="{'hide': !isOptionShown}" class="footer"
    (mouseleave)="triggerVisibleChange(false)">
    <div vnpt-row vAlign="middle">

        <ng-template [ngIf]="!isJoinedAudio">
            <div class="item-holder" (click)="toggleJoinAudio()">
                <i vnpt-icon class="icon" [vSrc]="'assets/icons/join-audio.svg'" style="margin-top: 10px;margin-bottom: 0px;"></i>
                <span style="margin-bottom: 5px;" vI18n="joining.button.connectAudio"></span>
            </div>
        </ng-template>

        <ng-template [ngIf]="!isBlockAllAudio && isJoinedAudio">
            <div #micDiv
                [vTooltipTitle]='isAudioMuted ? holdSpaceTitle2 : ""'
                vTooltipPlacement="topLeft" vnpt-tooltip class="item-holder" (click)="toggleMic()"
                [ngClass]="{ 'item-holder--selected': isSelected && selectedOption === 'audio' }"
                id ="testmic"
            >
                <i class="icon" [ngClass]="{ 'icon-gomeet-mute-filled': isAudioMuted, 'icon-gomeet-unmute-filled': !isAudioMuted }"></i>
                <span [vI18n]="isAudioMuted ? 'joining.button.onMic' : 'joining.button.offMic'"></span>
            </div>
            <a vnpt-button vType="text"  class="selectAudio"
                [ngClass]="{ 'item-holder--selected': isSelected && selectedOption === 'listAudio' }"
                vnpt-popover
                (click) = "pooper($event, visibility.listAudio, 'listAudio')"
                [(vPopoverVisible)]="visibility.listAudio"
                (vPopoverVisibleChange)="triggerVisibleChange(visibility.listAudio, 'listAudio')"
                vPopoverTrigger="!visibility.listAudio ? 'click' : null"
                vPopoverOverlayClassName="pooper-height-max"
                [vPopoverContent]="audioDeviceTemplate"
            >
                <i class="icon-gomeet-chevron-up iconSelectAudio"></i>
            </a>
        </ng-template>

        <ng-template [ngIf]="isBlockAllAudio && isJoinedAudio">
            <div class="item-holder disabled" #micDiv id ="testmic">
                <i class="icon-gomeet-mute-filled icon block-status"></i>
                <span disabled vI18n="joining.button.locked" class="block-status"></span>
            </div>
        </ng-template>

        <ng-template [ngIf]="!isBlockAllWebcam">
            <div class="item-holder" #videoDiv (click)="toggleVideo()"
                [ngClass]="{ 'item-holder--selected': isSelected && selectedOption === 'video' }" id ="testcam">
                <i class="icon" [ngClass]="isVideoMuted ? 'icon-gomeet-video-stop' : 'icon-gomeet-video-start'"></i>
                <span [vI18n]="isVideoMuted ? 'joining.button.onVideo' : 'joining.button.offVideo'"></span>
            </div>
            <a vnpt-button vType="text" class="selectAudio"
            [ngClass]="{ 'item-holder--selected': isSelected && selectedOption === 'listVideo' }"
            vnpt-popover
            [(vPopoverVisible)]="visibility.listVideo"
            (click) = "pooper($event, visibility.listVideo, 'listVideo')"
            (vPopoverVisibleChange)="triggerVisibleChange(visibility.listVideo, 'listVideo')"
            vPopoverTrigger="!visibility.listVideo ? 'click' : null"
            vPopoverOverlayClassName="pooper-height-max"
            [vPopoverContent]="videoDeviceTemplate"
            >
                <i class="icon-gomeet-chevron-up iconSelectAudio"></i>
            </a>
        </ng-template>

        <ng-template [ngIf]="isBlockAllWebcam">
            <div class="item-holder disabled" #videoDiv id ="testcam">
                <i class="icon-gomeet-video-stop icon block-status"></i>
                <span vI18n="joining.button.locked" class="block-status"></span>
            </div>
        </ng-template>

    </div>

    <div vnpt-row vAlign="middle">
        <div class="item-holder"  #lobby
            [ngClass]="{ 'item-holder--selected': isSelected && selectedOption === 'listParticipant' }" (click)="showContainerRight($event, 'listParticipant')">
                <!-- <vnpt-badge [vDot]="lobbyLength" [vStyle]="{left: '11px', top: '6px !important'}">
                </vnpt-badge> -->
            <vnpt-badge [vCount]="participantLength" [vOverflowCount]="999"
                class="badge-security"
                [vStyle]="{background: 'transparent', left: '50%', fontSize: '10px', top: '24px', padding: '0 0 0 0.3em', width: 'fit-content', color: '#8f9091'}">
                <i class="icon-gomeet-participants icon" ></i>
            </vnpt-badge>
        
            <span vI18n="joining.button.participants"></span>
            <a vnpt-button vType="text" class="selectSecurity" *ngIf="hasPermissionModerator"
                [ngClass]="{ 'item-holder--selected': isSelected && selectedOption === 'security' }"
                vnpt-popover
                [(vPopoverVisible)]="visibility.security"
                (click) = "pooper($event, visibility.security, 'security')"
                (vPopoverVisibleChange)="triggerVisibleChange(visibility.security, 'security')"
                vPopoverTrigger="!visibility.security ? 'click' : null"
                vPopoverOverlayClassName="pooper-height-max"
                [vPopoverContent]="securityTemplate"
            >
                <i class="icon-gomeet-chevron-up iconSelectAudio"></i>
            </a>
        </div>

        <div class="item-holder" (click)="showContainerRight($event, 'chatting')"
            [ngClass]="{ 'item-holder--selected': isSelected && selectedOption === 'chatting' }">
            <vnpt-badge *ngIf="unseenMessage !== 0" [vDot]="unseenMessage" [vStyle]="{ backgroundColor: '#52C41A'}" class="badge">
            </vnpt-badge>
            <i class="icon-gomeet-chat icon"></i>
            <span vI18n='joining.button.chat'></span>
        </div>

        <div *ngIf="hasPermissionShareScreen" class="item-holder" (click)="openShareScreenModal()"
            [ngClass]="{ 'item-holder--selected': isSelected && selectedOption === 'shareScreen', 'status-on': !isMeShareScreen, 'status-off': isMeShareScreen }">
            <i class="icon" [ngClass]="{ 'icon-gomeet-stop-share': isMeShareScreen, 'icon-gomeet-sharescreen': !isMeShareScreen }"></i>
            <span [vI18n]="isMeShareScreen ? 'joining.button.stopShareScreen' : 'joining.button.shareScreen'"></span>
        </div>

        <div *ngIf="!hasPermissionShareScreen" class="item-holder" (click)="requestRole()"
            [ngClass]="{ 'item-holder--selected': isSelected && selectedOption === 'shareScreen', 'disabled': isRequestPresenter}">
            <i class="icon" [ngClass]="{ 'icon-gomeet-sharescreen': true }"></i>
            <span [vI18n]="!isRequestPresenter ? 'joining.button.request.shareScreen' : 'joining.button.requested.shareScreen'"></span>
        </div>


        <!--CHEDSO-->
        <!-- <div class="item-holder" *ngIf="hasPermissionModerator"
            [ngClass]="{ 'item-holder--selected': isSelected && selectedOption === 'reactions' }"
            vnpt-popover 
            [(vPopoverVisible)]="visibility.reactions"
            (click) = "pooper($event, visibility.reactions, 'reactions')"
            (vPopoverVisibleChange)="triggerVisibleChange(visibility.reactions, 'reactions')"
            vPopoverTrigger="!visibility.reactions ? 'click' : null"
            [vPopoverContent]="reactionTemplate">
            <span vI18n='joining.button.advance'></span>
        </div> -->

        <div *ngIf="hasPermissionModerator && record" class="item-holder record-btn" (click)="toggleRecord()"
            [ngClass]="{ 'item-holder--selected': isSelected && selectedOption === 'record' }"
            [style]="{'pointer-events': recordSpam ? 'none': ''}"
            >
            <i *ngIf="!isInitial" class ="icon {{recordingIconValue}}"></i>
            <i *ngIf="isInitial" vnpt-icon [vType]="'loading'" class="icon-loading"></i>
            <span>{{statusRecording}}</span>
        </div>

        <div *ngIf="!hasPermissionModerator"
            [ngClass]="{ 'item-holder--selected': isSelected}">            
            <lib-reactions></lib-reactions>
        </div>

        <div class="item-holder" [ngClass]="{ 'item-holder--selected': isSelected && selectedOption === 'more' }"
        vnpt-button vnpt-popover 
        [(vPopoverVisible)]="visibility.options"
        (click) = "pooper($event, visibility.options, 'options')"
        vPopoverTrigger="!visibility.options ? 'click' : null"
        vType="primary" 
        vPopoverOverlayClassName="pooper-height-max"
        [vPopoverContent]="optionsTemplate"

        >
            <i class="icon-gomeet-more-options icon"></i>
            <span vI18n="joining.button.more"></span>
        </div>
        <!-- HIDDEN FEATURE: (BREAKOUT ROOM) -->
        <!-- Hide `breakout room` button  -->
        <!-- -----------------------------  -->

    </div>

    <div style="padding: 0 8px; ">
        <button vnpt-button vType='danger'  style="width: 7em;"
                vnpt-popover vPopoverPlacement="topRight"
                [(vPopoverVisible)]="visibility.endMeeting"
                (click) = "hasPermissionModerator ? pooper($event, visibility.endMeeting, 'endMeeting') : endMeeting()"
                (vPopoverVisibleChange)="triggerVisibleChange(visibility.endMeeting)"
                vPopoverTrigger="!visibility.endMeeting ? 'click' : null"
                vPopoverOverlayClassName="pooper-height-max"
                [vPopoverContent]="endMeetingTemplate">
            <b style="font-size: 12px;" [vI18n]='hasPermissionModerator ? "end" : "exit"'></b>
        </button>
    </div>
</div>

<!-- <ng-template #reactionTemplate >

</ng-template> -->

<ng-template #securityTemplate>
    <lib-security (mouseenter)="triggerVisibleChange(true)" (mouseleave)="triggerVisibleChange(false)"
    (pooper)="pooper($event, visibility.security, 'security')"
    [layoutMode]="layoutMode"></lib-security>
</ng-template>

<ng-template #optionsTemplate>
    <lib-more-options (mouseenter)="triggerVisibleChange(true)" (mouseleave)="triggerVisibleChange(false)"
    (pooper)="pooper($event, visibility.options, 'options')"></lib-more-options>
</ng-template>

<!-- <ng-template #settingsTemplate>
    
</ng-template> -->

<ng-template #audioDeviceTemplate >
    <lib-audio-device [changeTemplate]="true" (mouseenter)="triggerVisibleChange(true)" (mouseleave)="triggerVisibleChange(false)"
    (pooper)="pooper($event, visibility.listAudio, 'listAudio')" [isAudioMuted]="isAudioMuted">
    </lib-audio-device>
</ng-template>


<ng-template #videoDeviceTemplate >
    <lib-video-device (mouseenter)="triggerVisibleChange(true)" (mouseleave)="triggerVisibleChange(false)"
    (pooper)="pooper($event, visibility.listVideo, 'listVideo')">
    </lib-video-device>
</ng-template>

<ng-template #endMeetingTemplate >

    <div class="popconfirm" vAlign="middle" vJustify="end" (mouseenter)="triggerVisibleChange(true)" (mouseleave)="triggerVisibleChange(false)">
        <!-- <button vnpt-button vType="default" (click)="visibility.endMeeting=false" class="cancel-button" vI18n="cancel"></button> -->
        <button style="font-size: 12px;" vnpt-button vType="danger" (click)="closeMeeting()" vI18n="joining.endmeeting.closeRoom"></button>
        <button style="font-size: 12px;" vnpt-button vType="default" (click)="endMeeting()" vI18n="joining.endmeeting.leaveRoom"></button>
    </div>
</ng-template>

<vnpt-modal [(vVisible)]="shareScreenVisible"
            vTitle='{{ "joining.button.shareScreen" | vI18n:{}:"Chia sẻ màn hình" }}'
            (vOnCancel)="handleCancel()"
            vCancelText="Đóng"
            [vFooter]="modalFooter"
            >
            
    <ng-container *vModalContent>
       <span innerHTML = "{{'joining.sharescreen.content' | vI18n:{name: shareScreenName}:'{{name}} khác sẽ ngừng chia sẻ nội dung ngay khi bạn bắt đầu chia sẻ'}}"></span>
    </ng-container>

<ng-template #modalFooter>
        <button vnpt-button vType="default" (click)="handleCancel()">{{'cancel' | vI18n:{}:'Hủy'}}</button>
        <button vnpt-button vType="primary" (click)="shareScreen('sidebyside')">{{'ok' | vI18n:{}:'Đồng ý'}}</button>
      </ng-template>
</vnpt-modal>
