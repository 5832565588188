import {Injectable} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

type KIND = 'audio' | 'webcam' | 'screen' | 'audioscreen';

export interface UpdateStream {
    peerId: string;
    kind: KIND;
    mediaStream: MediaStream;
}

class VideoStream extends MediaStream {
    constructor(track: MediaStreamTrack) {
        super();
        this.addTrack(track);
    }
}

class AudioStream extends MediaStream {
    constructor(track: MediaStreamTrack) {
        super();
        this.addTrack(track);
    }
}

class ShareScreenStream extends MediaStream {
    constructor(track: MediaStreamTrack) {
        super();
        this.addTrack(track);
    }
}

class AudioScreenStream extends MediaStream {
    constructor(track: MediaStreamTrack) {
        super();
        this.addTrack(track);
    }
}

const KINDS = {
    WEBCAM: 'webcam',
    AUDIO: 'audio',
    SHARE_SCREEN: 'screen',
    AUDIO_SCREEN: 'audioscreen'
};

class AppSourcesStream {
    private webcam: VideoStream;
    private audio: AudioStream;
    private screen: ShareScreenStream;
    private audioscreen: AudioScreenStream;
}

@Injectable({
    providedIn: 'root'
})
export class MediaStreamService {
    private mediaStreamMap: Map<string, AppSourcesStream> = new Map<string, AppSourcesStream>();
    private onUpdateStreamSubject: BehaviorSubject<UpdateStream> = new BehaviorSubject(null);

    private createNewMediaStream(kind: KIND, track: MediaStreamTrack): MediaStream {
        switch (kind) {
            case KINDS.AUDIO: return new AudioStream(track);
            case KINDS.WEBCAM: return new VideoStream(track);
            case KINDS.SHARE_SCREEN: return new ShareScreenStream(track);
            case KINDS.AUDIO_SCREEN: return new AudioScreenStream(track);
        }
    }

    public getMediaStream(peerId: string, kind: KIND): MediaStream {
        if (!peerId || !this.mediaStreamMap.has(peerId)) { return null; }
        return this.mediaStreamMap.get(peerId)[kind];
    }

    private setMediaStream(peerId: string, kind: KIND, mediaStream: MediaStream): void {
        let stream = this.mediaStreamMap.get(peerId);
        if (!stream) {
            stream = new AppSourcesStream();
            this.mediaStreamMap.set(peerId, stream);
        }
        stream[kind] = mediaStream;
        // this.mediaStreamMap.set(peerId, stream);
        this.updateStream(peerId, kind, mediaStream);
    }

    private removeOldTrack(peerId: string, kind: KIND, mediaStream: MediaStream): MediaStream {
        const tracks = mediaStream.getTracks();
        tracks.forEach((t) => {
            // t.stop();
            mediaStream.removeTrack(t);
        });
        // if (tracks.length > 0) { mediaStream.removeTrack(tracks[0]); }
        return mediaStream;
    }

    public addStream(peerId: string, kind: KIND, track: MediaStreamTrack): void {
        if (!peerId || !kind || !track) { return; }
        let mediaStream = this.getMediaStream(peerId, kind);
        if (!mediaStream) { mediaStream = this.createNewMediaStream(kind, track); }
        else {
            mediaStream = this.removeOldTrack(peerId, kind, mediaStream);
            mediaStream.addTrack(track);
        }
        this.setMediaStream(peerId, kind, mediaStream);
    }

    public replaceStream(peerId: string, kind: KIND, stream: MediaStream): void {
        if (!peerId || !kind || !stream) { return; }
        let mediaStream = this.getMediaStream(peerId, kind);
        if (mediaStream) {
            this.removeOldTrack(peerId, kind, mediaStream);
        }
        this.setMediaStream(peerId, kind, stream);
    }

    public removeStream(peerId: string, kind: KIND): void {
        if (!peerId || !kind) { return; }
        let mediaStream = this.getMediaStream(peerId, kind);
        if (!mediaStream) { return; }
        mediaStream = this.removeOldTrack(peerId, kind, mediaStream);
        this.setMediaStream(peerId, kind, mediaStream);
    }

    private updateStream(peerId: string, kind: KIND, mediaStream: MediaStream): void {
        this.onUpdateStreamSubject.next({peerId, kind, mediaStream});
    }

    public onUpdateStream(): Observable<UpdateStream> {
        return this.onUpdateStreamSubject.asObservable();
    }
}
