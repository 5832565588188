<vnpt-modal [(vVisible)]="isVisible"
            vTitle="{{title}}"
            [vFooter]="modalFooter"
            (vOnCancel)="handleCancel()"
            vCancelText="Đóng"
            [vStyle] = "style"
            [vBodyStyle] = "bodyStyle"
            >
            
    <ng-container style="{'height' : calc(100vh - 194px)}" *vModalContent>
        <iframe [src]="trustURL"
         class="feedback" allowFullScreen ></iframe>
    </ng-container>

<ng-template #modalFooter>
        <button vnpt-button vType="default" (click)="handleCancel()">{{'close' | vI18n:{}:'Đóng'}}</button>
        <!-- <button vnpt-button vType="primary" (click)="handleOk()">{{'list-participant.accept' | vI18n:{}:'Đồng ý'}}</button> -->
      </ng-template>
</vnpt-modal>