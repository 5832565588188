/* tslint:disable:prefer-const */
import { Injectable } from '@angular/core';
import { element } from 'protractor';
import { PERMISSIONS } from '../constants';
import { RemoteParticipant } from '../models/remote-participant.model';


// const getKeyValue = <T extends RemoteParticipant>(key: string[]) => (remote: T) => {
//     try {
//         let obj = remote[key[0]];
//         if(key.length > 1) {
//             const newKey = key.slice(1, key.length);
//             obj = getKeyValue<T>(newKey)(obj);
//         }
//         return obj;
//     } catch (error) {
//         console.log(error);
//     }
// }


// const sortBy = <T extends RemoteParticipant>(index: string[], UserA: T, UserB: T): number => {
//     const _a = getKeyValue<T>(index)(UserA);
//     const _b = getKeyValue<T>(index)(UserB);
//     if (_a < _b) return 1;
//     if (_a > _b) return -1;
//     return 0;
// }

 const getValue = (keys: string[], remote: RemoteParticipant): RemoteParticipant => {
    try {
        let obj = remote[keys[0]];
        if (keys.length > 1) {
            const newKey = keys.slice(1, keys.length);
            obj =  getValue(newKey, obj);
        }
        return obj;
    } catch (error) {
        console.log(error);
    }
}

const sortBy = (UserA, UserB: RemoteParticipant, keys: string[]): number => {
  const _a = getValue(keys, UserA);
  const _b = getValue(keys, UserB);
  if (_a < _b) return 1;
  if (_a > _b) return -1;
  return 0;
};

@Injectable({
    providedIn: 'root'
})
export class SortingService {
    constructor() {}


    //not used
    public static sortByList (index: string[], list: RemoteParticipant[]): RemoteParticipant[] {
        return list.sort((a, b): number => {
            const _a = getValue(index,a);
            const _b = getValue(index,b);
            if (_a < _b) return 1;
            if (_a > _b) return -1;
            return 0;
        })
    }

    public static sort<T extends RemoteParticipant>(list: Array<T>, comparators: Array<Function>) {
        return list.sort((a, b) => comparators.reduce((r, f) => {
            if (r != 0) return r;
            return f(a, b);
        }, 0))
    }

    public static pinCompare(user1: RemoteParticipant, user2: RemoteParticipant): number {
        return sortBy(user1, user2, ['isPin'] );
    }

    public static raisedHandCompare(user1: RemoteParticipant, user2: RemoteParticipant): number {
        return sortBy(user1, user2, ['isRaisedHand']);
    }

    public static audioCompare(user1: RemoteParticipant, user2: RemoteParticipant): number {
        return sortBy( user1, user2, ['audio','state']);
    }

    public static webcamCompare(user1: RemoteParticipant, user2: RemoteParticipant): number {
        return sortBy( user1, user2, ['webcam','state'] );
    }

    public static joinedTimestampCompare(user1: RemoteParticipant, user2: RemoteParticipant): number {
        return sortBy( user2, user1, ['joinedTimestamp']);
    }

    public static isSharingCompare(user1: RemoteParticipant, user2: RemoteParticipant): number {
        return sortBy(user1, user2, ['screen']);
    }

    public static alphabetCompare(user1: RemoteParticipant, user2: RemoteParticipant): number {
        return user1.displayName.toString().localeCompare(user2.displayName.toString(), 'vi');
    }

    public static roleCompare(user1: RemoteParticipant, user2: RemoteParticipant): number {
        if (user1.screen.state > user2.screen.state) return -1;
        else if (user1.screen.state < user2.screen.state) return 1;

        const stateAdminUser1 = user1.roles.includes(PERMISSIONS.ADMIN.id);
        const stateAdminUser2 = user2.roles.includes(PERMISSIONS.ADMIN.id);
        if (stateAdminUser1 > stateAdminUser2) return -1;
        else if (stateAdminUser1 < stateAdminUser2) return 1;

        const stateMorderatorUser1 = user1.roles.includes(PERMISSIONS.MODERATOR.id);
        const stateMorderatorUser2 = user2.roles.includes(PERMISSIONS.MODERATOR.id);
        if (stateMorderatorUser1 > stateMorderatorUser2) return -1;
        else if (stateMorderatorUser1 < stateMorderatorUser2) return 1;

        return 0;
    }

    //not used
    public static sortByStatus(listRemoteParticipant: RemoteParticipant[], compareStatus: string[]): RemoteParticipant[] {
        if (listRemoteParticipant) {
            var sortedList = this.sortByList(compareStatus, listRemoteParticipant);
            return sortedList;
        }
        return [...listRemoteParticipant];
    }

    //not used
    public sortByAlphabet(listRemoteParticipant : RemoteParticipant[]):RemoteParticipant[]{
        if(listRemoteParticipant){
            listRemoteParticipant.sort((a,b)=>{
                return a.displayName.toString().localeCompare(b.displayName.toString(),'vi');
            });
            return [...listRemoteParticipant];
        }
    }
    
    //not used
    public static sortByRaisedHand(listRemoteParticipant : RemoteParticipant[]):RemoteParticipant[]{
        if(listRemoteParticipant){
            return this.sortByStatus(listRemoteParticipant, ['isRaisedHand']);
        }
        return [...listRemoteParticipant];
    }

    //not used
    public sortByRoles(listRemoteParticipant : RemoteParticipant[]):RemoteParticipant[]{
        var resultSortByRoles, resultSortByMorderator, resultSortByUser, resultSortByAdmin : RemoteParticipant[] = [];
        if(listRemoteParticipant){
            resultSortByRoles = listRemoteParticipant.filter(participant => !!(participant.screen.state));

            resultSortByAdmin =  listRemoteParticipant.filter(participant => 
                (participant.roles.includes(PERMISSIONS.ADMIN.id))
                    &&  !participant.screen.state);

            resultSortByAdmin.forEach(element => {
                    resultSortByRoles.push(element)});

            resultSortByMorderator =  listRemoteParticipant.filter(participant => 
                                        !!(participant.roles.includes(PERMISSIONS.MODERATOR.id))
                                            && !(participant.roles.includes(PERMISSIONS.ADMIN.id))
                                            &&  !participant.screen.state);
            resultSortByMorderator.forEach(element => {
                resultSortByRoles.push(element)
            });
            resultSortByUser =  listRemoteParticipant.filter(participant => 
                                    (!participant.roles.includes(PERMISSIONS.MODERATOR.id))
                                        &&  !participant.screen.state);

            resultSortByUser.forEach(element => {
                resultSortByRoles.push(element)
            });
    
            return [...resultSortByRoles];
        }
        return listRemoteParticipant;
    }
    
    //not used
    public sortBySharing(listRemoteParticipant : RemoteParticipant[]):RemoteParticipant[]{

        if(listRemoteParticipant){
            listRemoteParticipant.sort((a,b)=>{
                if(a['screen']['state'] > b['screen']['state']){
    
                    return -1;
                }
                if(a['screen']['state'] < b['screen']['state']){
                    return 1;
                }
                return 0;
            });
            return [...listRemoteParticipant];
        }

    }

    //not used
    public sortByMorderator(listRemoteParticipant : RemoteParticipant[]):RemoteParticipant[]{

        if(listRemoteParticipant){
            listRemoteParticipant.sort((a,b)=>{
                if (a.roles.includes(PERMISSIONS.MODERATOR.id) && !b.roles.includes(PERMISSIONS.MODERATOR.id)) {
                    return -1;
                }
                if (!a.roles.includes(PERMISSIONS.MODERATOR.id) && b.roles.includes(PERMISSIONS.MODERATOR.id)) {
                    return 1;
                }
                return 0;
            });
            return [...listRemoteParticipant];
        }

    }

}
export const SORT_USERS_BY = [
    { name: 'alphabet', title: 'sortByAlphabet', fn: [SortingService.pinCompare, SortingService.alphabetCompare] },
    { name: 'isRaisedHand', title: 'sortByRaisedHand', fn:[SortingService.pinCompare, SortingService.raisedHandCompare, SortingService.alphabetCompare ] },
    { name: 'role', title: 'sortByRole', fn:[SortingService.pinCompare, SortingService.roleCompare, SortingService.alphabetCompare] },
    { name: 'audio', title: 'sortByMicro', state: true, fn:[SortingService.pinCompare, SortingService.audioCompare, SortingService.alphabetCompare] },
    { name: 'webcam', title: 'sortByWebcam', state: true, fn:[SortingService.pinCompare, SortingService.webcamCompare, SortingService.alphabetCompare]},
    { name: 'joinedTimestamp', title: 'sortByTime', fn:[SortingService.pinCompare, SortingService.joinedTimestampCompare, SortingService.alphabetCompare] }
]
