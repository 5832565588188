import * as LobbyPeersActions from './lobby-peers.actions';

export type Action = LobbyPeersActions.All;

export const LobbyPeerReducer = (state = {}, action: Action) =>
{
    switch (action.type)
    {
        case LobbyPeersActions.ADD_LOBBY_PEER:
            return { id: action.payload.peerId };

        case LobbyPeersActions.SET_LOBBY_PEER_DISPLAY_NAME:
            return { ...state, displayName: action.payload.displayName };
        case LobbyPeersActions.SET_LOBBY_PEER_PICTURE:
            return { ...state, picture: action.payload.picture };
        case LobbyPeersActions.SET_LOBBY_PEER_PROMOTION_IN_PROGRESS:
            return { ...state, promotionInProgress: action.payload.flag };

        default:
            return state;
    }
};

export const LobbyPeersReducer = (state = {}, action: Action) =>
{
    switch (action.type)
    {
        case LobbyPeersActions.ADD_LOBBY_PEER:
        {
            return { ...state, [action.payload.peerId]: LobbyPeerReducer(undefined, action) };
        }

        case LobbyPeersActions.REMOVE_LOBBY_PEER:
        {
            const { peerId } = action.payload;
            const newState = { ...state };

            delete newState[peerId];

            return newState;
        }

        case LobbyPeersActions.SET_LOBBY_PEER_DISPLAY_NAME:
        case LobbyPeersActions.SET_LOBBY_PEER_PICTURE:
        case LobbyPeersActions.SET_LOBBY_PEER_PROMOTION_IN_PROGRESS:
        {
            const oldLobbyPeer = state[action.payload.peerId];

            if (!oldLobbyPeer)
            {
                // Tried to update non-existent lobbyPeer. Has probably been promoted, or left.
                return state;
            }

            return { ...state, [oldLobbyPeer.id]: LobbyPeerReducer(oldLobbyPeer, action) };
        }

        default:
            return state;
    }
};
