import { Component, Input, OnInit } from '@angular/core';
import { RoomConfigModel } from '../../models/room-config.model';
import {
  SettingsService,
  RoomService,
  LocalParticipantService,
  SecurityService
} from '../../services';
// import { OneuiI18nService } from '@vnpt/oneui-i18n';
import { permissions } from '../../permissions';

@Component({
  selector: 'lib-room-info',
  templateUrl: './room-info.component.html',
  styleUrls: ['./room-info.component.less']
})
export class RoomInfoComponent implements OnInit {

  roomConfig: RoomConfigModel;
  @Input() roomName: any;
  roomId: string = "";
  startTime: Date;
  endTime: Date;
  isCopy: boolean = false;
  isConfigDuration: boolean = false;
  hasPermissionModerator: boolean = false;
  visible = false;

  constructor(private settingsService: SettingsService,
    // private i18nService: OneuiI18nService,
    private roomService: RoomService,
    private localParticipantService: LocalParticipantService,
    private securityService: SecurityService
  ) { }

   ngOnInit(): void {
    this.localParticipantService.onRoles().subscribe((roles: number[]) => {
      this.hasPermissionModerator = this.roomService.hasPermission(permissions.MODERATE_ROOM);
    });

    this.roomConfig = this.roomService.getRoomConfig();
    this.roomId = this.roomConfig.roomID;
    this.startTime =  new Date(this.roomConfig.startTime);
    this.endTime =  new Date(this.roomConfig.endTime);

    if(this.hasPermissionModerator && !this.roomService.getIsExtended() && this.roomService.getTimeLeftRoom() > 0){
      this.isConfigDuration = true;
    }

    this.roomService.onMeetingTimeout().subscribe((obj: {timeLeft: number, message: string}) => {
      if (!obj) return;
      if (obj.timeLeft > 0) {
        if(this.hasPermissionModerator && !this.roomService.getIsExtended() && this.roomService.getTimeLeftRoom() > 0){
          this.isConfigDuration = true;
        }
      } 
    });
    this.securityService.onInfoModal().subscribe((flag: boolean)=> {
        this.visible = flag;
    })
  }

  // async openSupport(): Promise<void> {
  //   const appConfig = await this.settingsService.getAppConfig();
  //   window.open(appConfig.supportLink, '_blank');
  // }

  // openFeedback(): void {
  //   let message = this.i18nService.translate("feedback.title", {},'Đóng góp ý kiến');
  //   this.settingsService.setIsFeedbackOpenSubject({visible: true, message, callback: null});
  // }

  // openReport(): void{
  //   let message = this.i18nService.translate("report.title", {},'Báo cáo sự cố');
  //   this.settingsService.setIsReportOpenSubject({visible: true, message});
  // }

  copyAccess(): void {
    this.isCopy = true;
    navigator.clipboard.writeText(this.roomConfig.inviteLink);
  }

  handleOk() {
    this.visible = false;
    this.isCopy = false;
  }

  changeRoomName():void{
    this.settingsService.setRoomNameChange(true);
  }
}
