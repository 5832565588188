import { Injectable } from '@angular/core';
import { VNotificationDataOptions, VNotificationService, VNotificationPlacement } from '@vnpt/oneui-ui/notification';
import { VConfigService } from '@vnpt/oneui-ui/core/config';
import { config } from '../../config';
import { LocalParticipantService } from './local-participant.service';
@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    private maxStack: number;
    private options: VNotificationDataOptions;
    private joinRoomNotificationId: string;
    private notiWindow: Notification[] = [];
    private action: any;

    constructor(private vNotificationService: VNotificationService,
         private readonly vConfigService: VConfigService,
         private localParticipantService: LocalParticipantService) {
        this.maxStack = config.notification.maxStack;
        this.options = {
            vPlacement: config.notification.placement as VNotificationPlacement,
        };

        this.vConfigService.set('notification', {
            vMaxStack: this.maxStack,
            vTop: '44px',
        });
        
        this.notifyMe() ;
    }

    public getMaxStack(): number {
        return this.maxStack;
    }

    public setMaxStack(num: number): void {
        this.maxStack = num;
        this.vConfigService.set('notification', {
            vMaxStack: this.maxStack,
            vTop: '44px'
        });
    }

    public info(title: string, content: string, options?: VNotificationDataOptions, onClickAction?: () => void): void {
        if (this.maxStack == 0 || this.localParticipantService.getIsRecordUser()) return;
        // Chuẩn title = Tên hoặc thông tin dạng name + nội dung prefix
        this.options.vClass = "rtl";
        const info = this.vNotificationService.info("&#x200E;" + title + "&#x200E;", content, { ...this.options, ...options });
        this.notificationWindow(title, content);

        if (onClickAction)
            info.onClick.subscribe(() => {
                onClickAction();
                this.vNotificationService.remove(info.messageId);
                info.onClick.unsubscribe();
            });
        info.onClose.subscribe(() => {
            info.onClick.complete();
            info.onClose.complete();
        })

    }

    public success(title: string, content: string, options?: VNotificationDataOptions): void {
        if (!this.maxStack || this.localParticipantService.getIsRecordUser()) return;
       this.vNotificationService.success(title, content, {
            ...this.options, ...options,
            vStyle: {
                backgroundColor: config.notification.colors.success
            }
        });
        this.notificationWindow(title, content);
    }

    public warning(title: string, content: string, options?: VNotificationDataOptions): void {
        if (!this.maxStack || this.localParticipantService.getIsRecordUser()) return;
        this.vNotificationService.warning(title, content, {
            ...this.options, ...options,
            vStyle: {
                backgroundColor: config.notification.colors.warning
            }
        });
        this.notificationWindow(title, content);
    }

    public error(title: string, content: string, options?: VNotificationDataOptions): void {
        if (!this.maxStack || this.localParticipantService.getIsRecordUser()) return;
        this.vNotificationService.error(title, content, {
            ...this.options, ...options,
            vStyle: {
                backgroundColor: config.notification.colors.error
            }
        });
        this.notificationWindow(title, content);
    }
    
    public notificationWindow(title: string, content: string):void{
        if (Notification.permission === "granted" && document.hidden)  {
            if(this.notiWindow.length == 0) {
                this.action = this.closeNotiHandle.bind(this);
                document.addEventListener("mouseenter", this.action);
            }
               var notif = new Notification(title,{body: content});
               notif.onclick = () =>{
                window.parent.parent.focus();
                this.closeNotiHandle();
               }
               this.notiWindow.push(notif);
        }
    }
    
    public closeNotiHandle(): void {
        this.notiWindow.forEach(noti => {
            noti.close();
        });
        
        this.notiWindow = [];
        document.removeEventListener("mouseenter", this.action);
    }
    

    public notifyMe() {

        if (!("Notification" in window)) {
          // Check if the browser supports notifications
          console.log("This browser does not support desktop notification");
        } else if (Notification.permission !== "denied") {
          Notification.requestPermission();
        }

    }

}
