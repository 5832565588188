/* tslint:disable:variable-name */
import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {VirtualBackgroundService, RoomService} from '../../services';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'lib-virtual-background',
    templateUrl: './virtual-background.component.html',
    styleUrls: ['./virtual-background.component.less']
})
export class VirtualBackgroundComponent implements OnInit, OnDestroy {

    isVisible = false;
    subscriptions: Subscription[] = [];
    loading = false;
    avatarUrl?: string;
    uploadFileName = '';
    backgroundSrc = null;
    imageDataAsURL = null;
    imgSrc = '';
    srcTrustUrl = null;
    notFoundImg = '/assets/not-found.svg';
    previewImage: string | undefined = '';
    previewVisible = false;
    @ViewChild('fileInput') fileInput: ElementRef;

    constructor(
        private virtualBackgroundService: VirtualBackgroundService,
        private roomService: RoomService,
        public domSanitizer: DomSanitizer,
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.onInitEvent();
    }

    ngOnDestroy(): void {
        for (const subs of this.subscriptions) {
            subs.unsubscribe();
        }
    }

    onInitEvent(): void {
        this.subscriptions.push(this.virtualBackgroundService.onVirtualBackgroundOpen().subscribe((isVisible: boolean) => {
            this.isVisible = isVisible;
        }));
    }

    handleOk(): void {
        this.virtualBackgroundService.setVirtualBackgroundOpen(false);
    }

    handleCancel(): void {
        this.virtualBackgroundService.setVirtualBackgroundOpen(false);
    }

    fileChange(e: any): void {
        const files = e.srcElement.files;
        if (!files.length) { return; }
        const firstFileIndex = 0;
        const file = files[firstFileIndex];

        const reader = new FileReader();

        reader.addEventListener('load', () =>
        {
            this.roomService.startVirtualBackground(reader.result);
        });

        const binaryData = [];
        binaryData.push(file);
        this.imgSrc = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/zip'}));
        this.srcTrustUrl = this.domSanitizer.bypassSecurityTrustUrl(this.imgSrc);

        reader.readAsDataURL(new Blob(binaryData, {type: 'application/zip'}));
    }

    resetImgSrc(): void {
        this.roomService.stopVirtualBackground();
        this.imgSrc = '';
    }
}
