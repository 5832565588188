import {BaseResponseApi} from '../base-response-api.model';

export interface Examining{
    quizStatus: boolean;
    quizId: string;
}

export interface ResponsesUserParticipatedModel{
    selectedOption: string;
    quesId: string;
}

export interface UsersParticipatedModel{
    userId: string;
    marks: number;
    responses: ResponsesUserParticipatedModel[];
    timeEnded: number;
    timeStarted: number;
    _id: string;
}

export interface QuizModel{
    _id: string;
    quizName: string;
    adminId: string;
    scheduledFor: string;
    quizDuration: string;
    quizStatus: number;
    quizRestart: number;
    reminderSent: boolean;
    usersParticipated: UsersParticipatedModel[];
    usersEnrolled: any[];
}

export const QuizStatus = {
    false: {
        text: 'Chưa bắt đầu',
        color: 'gray'
    },

    true: {
        text: 'Đang diễn ra',
        color: '#1363B9'
    }
};

export interface QuizResponseApi extends BaseResponseApi<QuizModel>{}
export interface ListQuizResponseApi extends BaseResponseApi<QuizModel[]>{}
